import React from 'react';
import { Prompt } from 'react-router-dom';
import { useAppState } from '../../providers/AppStateProvider';

export const PromptWrapper = () => {
  const { appState: { leavingPagePromptMessage } } = useAppState();
  return (
    <Prompt
      when={!!leavingPagePromptMessage}
      message={JSON.stringify(leavingPagePromptMessage) || {}}
    />
  );
};
