import { Tooltip } from 'ci-common-ui';
import isNil from 'lodash/isNil';
import React from 'react';
import useLocale from '../../../../../hooks/use-locale';
import { Wrapper, CommentIcon, Count, IconWrapper } from './StyledCommentsCountCell';

export const CommentsCountCell = ({ data: count }) => {
  const { tAbsDefault, tAbs } = useLocale();
  const isIconDisabled = count === 0;

  if (isNil(count)) {
    return null;
  }

  return (
    <Tooltip content={isIconDisabled && tAbs('common.no_comments')}>
      <Wrapper aria-label={`${count} ${tAbsDefault('common.comments')}`}>
        <IconWrapper>
          <CommentIcon isIconDisabled={isIconDisabled} iconName="Comment" />
        </IconWrapper>
        <Count>{count}</Count>
      </Wrapper>
    </Tooltip>
  );
};
