import React from 'react';
import DateTimeFilterContainer from '../../../components/DateTimeFilter/DateTimeFilterContainer';
import { PageHeaderContainer, PageTitle, TitleShimmer } from './StyledPageHeader';
import { getDefaultRoute, getRoutePageIdByPathname, routes } from '../../routing';
import { constantQueryParamsObj } from '../../../constants/urls';

const PageHeader = ({ title, fullTimeRange, isLoading, isErrorState }) => {
  const { mustParams } = routes[getRoutePageIdByPathname(window.location.pathname)] || getDefaultRoute();
  const showDateTimeFilter = (mustParams || []).some((p) => p === constantQueryParamsObj.startDate);
  return (
    <PageHeaderContainer>
      <TitleShimmer isDataLoaded={title || isErrorState}>
        {typeof title === 'string' ? <PageTitle>{title}</PageTitle> : title}
      </TitleShimmer>
      {
        !isErrorState && showDateTimeFilter && <DateTimeFilterContainer fullTimeRange={fullTimeRange} isLoading={isLoading} />
      }
    </PageHeaderContainer>
  );
};
export default PageHeader;
