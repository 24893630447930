import styled from 'styled-components';
import { FontSizes } from '@uifabric/fluent-theme';
import { rtl, StyledText } from 'ci-common-ui';

export const SectionDescription = styled.div`
  font-size: ${FontSizes.size16};
`;

export const ContentSection = styled.div`
  margin-bottom: 32px;
`;

export const ContentSectionTitle = styled(StyledText)`
  margin-bottom: 6px;
  font-weight: 600;
  display: block;
  font-size: ${FontSizes.size16};
`;

export const StyledBanner = styled.img`
  width: 292px;
  ${rtl`
    float: right;
    margin-left: 10px;
  `}
`;

export const BulletsContainer = styled.div`
  margin-top: 16px;
  &>*:not(:last-child) {
    margin-bottom: 20px;
  }
`;
