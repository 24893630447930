import React from 'react';
import size from 'lodash/size';
import { Persona, PersonaSize } from '@fluentui/react';
import {
  AddIcon,
  AddSeller,
  Container,
  navStyles,
  personaStyles,
  SellerNavItemActions,
  SellerNavItemContainer,
  StyledNav,
  UploadedFilesCountPill,
} from './StyledSidebar';
import useLocale from '../../../../hooks/use-locale';
import { FavoriteIcon } from '../../StyledUploadCallsPanel';

const MAX_SELLERS = 5;

const SellerLink = ({ name, isFavorite, filesCount }) => (
  <SellerNavItemContainer>
    <Persona
      text={name}
      size={PersonaSize.size24}
      styles={personaStyles}
    />
    <SellerNavItemActions>
      <UploadedFilesCountPill>{filesCount}</UploadedFilesCountPill>
      <FavoriteIcon isFavorite={isFavorite} />
    </SellerNavItemActions>
  </SellerNavItemContainer>
);

export default ({ sellers, selectedSeller, onSellerClicked, onAddSeller }) => {
  const { t } = useLocale('components.upload_calls_form');
  const onRenderLink = ({ name, isFavorite, files }) => (
    <SellerLink name={name} isFavorite={isFavorite} filesCount={size(files)} />
  );

  return (
    <Container>
      <StyledNav
        onLinkClick={onSellerClicked}
        groups={[{ links: sellers }]}
        onRenderLink={onRenderLink}
        selectedKey={selectedSeller.key}
        styles={navStyles}
      />
      {
        size(sellers) < MAX_SELLERS && (
          <AddSeller onClick={onAddSeller}>
            <AddIcon />
            <span>{t('add_seller')}</span>
          </AddSeller>
        )
      }
    </Container>
  );
};
