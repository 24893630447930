import React from 'react';
import size from 'lodash/size';
import useLocale from '../../../../../../../hooks/use-locale';
import { getSuggestionProps } from '../utils';
import { StyledPicker } from '../StyledViewAs';

const MAX_SELECTED_USERS_ALLOWED = 1;
const MIN_SEARCH_TEXT_LENGTH = 2;

export const Picker = ({ selectedUsers, setSelectedUsers, componentRef, searchUsers }) => {
  const { t } = useLocale('pages.settings.your_team.view_as');
  const [filterText, setFilterText] = React.useState('');
  const isfilterTextTooShort = size(filterText) < MIN_SEARCH_TEXT_LENGTH;

  const onFilterChanged = async () => {
    if (isfilterTextTooShort) {
      return null;
    }

    return searchUsers(filterText);
  };

  return (
    <StyledPicker
      itemLimit={MAX_SELECTED_USERS_ALLOWED}
      selectedItems={selectedUsers}
      onChange={setSelectedUsers}
      onResolveSuggestions={onFilterChanged}
      resolveDelay={500}
      pickerSuggestionsProps={getSuggestionProps(isfilterTextTooShort, t)}
      inputProps={{
        width: 180,
        'aria-label': 'People Picker',
        placeholder: t('enter_user_name'),
        value: filterText,
      }}
      componentRef={componentRef}
      onInputChange={setFilterText}
    />
  );
};
