import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { dayJS, dateFromServer, toStartOfTheDay, toEndOfTheDay } from 'utils/date/date';

export const transformServerDate = (data) => { // TODO: Remove all "oldData" references when @Ofri's change is in production
  const oldData = get(data, 'DateTimeRange') || get(data, 'dateTimeRange');
  const newData = get(data, 'DatesRange') || get(data, 'datesRange');
  const now = dayJS(); // fallback date

  if (isEmpty(oldData) && isEmpty(newData)) {
    return { start: now, end: now };
  }

  const oldDataLength = get(oldData, 'length');
  const oldDataStart = get(oldData, '[0].Date') || get(oldData, '[0].date');
  const oldDataEnd = get(oldData, `[${oldDataLength - 1}].Date`) || get(oldData, `[${oldDataLength - 1}].date`);

  const newDataStart = get(newData, '[0].MinDate') || get(newData, '[0].minDate') || get(newData, 'MinDate') || get(newData, 'minDate');
  const newDataEnd = get(newData, '[0].MaxDate') || get(newData, '[0].maxDate') || get(newData, 'MaxDate') || get(newData, 'maxDate');

  const serverStart = dateFromServer(newDataStart || oldDataStart);
  const serverEnd = dateFromServer(newDataEnd || oldDataEnd);
  const start = newDataStart ? serverStart : toStartOfTheDay(serverStart);
  const end = newDataEnd ? serverEnd : toEndOfTheDay(serverEnd);

  return {
    start: start || now,
    end: end || now
  };
};

export const transformTime = (time) => {
  if (!time) return null;
  const timeDetails = time.split(':');
  if (timeDetails.length !== 3) return null;

  const secondDetails = timeDetails[2].split('.');
  return {
    hours: timeDetails[0],
    minutes: timeDetails[1],
    seconds: secondDetails[0],
    ms: secondDetails.length === 2 ? secondDetails[1] : '00'
  };
};
