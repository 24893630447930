import * as React from 'react';
import { ShowMoreButton } from './StyledComments';

export default ({
  onClick,
  isExpanded,
  visibleItemsCount,
  totalItemsCount,
  showMoreTexts
}) => {
  const showMoreCount = totalItemsCount - visibleItemsCount;
  const showMore = showMoreCount > 1 ? showMoreTexts.showMorePlural : showMoreTexts.showMore;
  return (
    <ShowMoreButton onClick={onClick}>
      {
        isExpanded
          ? `- ${showMoreTexts.showLess}`
          : `+ ${showMoreCount} ${showMore}`
      }
    </ShowMoreButton>
  );
};
