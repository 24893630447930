import React from 'react';
import useLocale from 'hooks/use-locale';
import { Pivot, PivotItem } from '@fluentui/react';
import { FilterWrapper, filterPivotStyles } from '../StyledFilesTable';
import { STATUS_NAMES } from '../../../constants';

const filterItems = [
  { name: 'all_calls', value: '', totalKey: 'total' },
  { name: 'completed', value: STATUS_NAMES.completed, totalKey: STATUS_NAMES.completed },
  { name: 'inprogress', value: STATUS_NAMES.inProgress, totalKey: STATUS_NAMES.inProgress },
  { name: 'failed', value: STATUS_NAMES.failed, totalKey: STATUS_NAMES.failed },
  { name: 'ignored', value: STATUS_NAMES.ignored, totalKey: STATUS_NAMES.ignored },
];

const Filter = ({ selectedFilter, onSelectFilter, totals, error }) => {
  const { tAbs } = useLocale('pages.system_monitoring.files_table');

  if (!totals || error) {
    return null;
  }

  return (
    <FilterWrapper>
      <Pivot selectedKey={selectedFilter} onLinkClick={onSelectFilter} headersOnly styles={filterPivotStyles}>
        {filterItems.map(({ name, value, totalKey }) => <PivotItem key={value} itemKey={value} headerText={`${tAbs(`common.${name}`)} (${totals[totalKey] || 0})`} />)}
      </Pivot>
    </FilterWrapper>
  );
};

export default Filter;
