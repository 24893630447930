import * as feedbackService from '../rest-services/feedback-rest-service';

export const SURVEY_TYPES = {
  callSummary: 'callSummary',
  realTime: 'realTime',
  executiveSummary: 'executiveSummary'
};

const baseFeedbackRequestBody = {
  CallType: "triggerandvalidateeligibility",
  TeamName: 'salesinsight'
};

const feedbackRequestBody = {
  callSummary: {
    ...baseFeedbackRequestBody,
    EventName: 'conversationintelligenceevent',
    SurveyName: 'ConversationIntelligence'
  },
  realTime: {
    ...baseFeedbackRequestBody,
    EventName: 'conversationintelligenceeventrealtime',
    SurveyName: 'ConversationIntelligenceRealtime'
  },
  executiveSummary: {
    ...baseFeedbackRequestBody,
    EventName: 'executivesummaryevent',
    SurveyName: 'executivesummary'
  }
};

export const getFeedbackStatus = async ({ orgUrl, surveyType }) => {
  const body = feedbackRequestBody[surveyType];
  const { data } = await feedbackService.getFeedbackStatus({ orgUrl, body });
  const { IsSuccess, CESResult } = data;

  const isSuccess = IsSuccess === 'True';
  const cesResult = JSON.parse(CESResult || '{}');
  const { Eligibility, FormsProId, FormsProEligibilityId } = cesResult;

  const shouldShowFeedback = isSuccess && Eligibility && FormsProId;
  return { shouldShowFeedback, formsProId: FormsProId, formsProEligibilityId: FormsProEligibilityId };
};
