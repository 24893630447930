import styled, { css, keyframes } from 'styled-components';
import { Persona } from '@fluentui/react';

const subtleLoading = keyframes`
  0%, 2.5%, 97.5%, 100% {
    opacity: 0.4;
  }
  47.5%, 50%, 52.5% {
    opacity: 0.8;
  }
`;

export const StyledAvatarPersona = styled(Persona)`
  .ms-Persona-coin {
    ${({ isLoading }) => isLoading && css`
        animation: ${subtleLoading} 1.5s infinite ease-in-out;
    `};
  }
`;
