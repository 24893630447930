import styled from 'styled-components';
import { FlexRow, FlexColumn } from '../../../../../../utils/styles/StyledUtils';
import { rtl } from '../../../../../../utils/styles/rtl';
import { ResultTitle } from '../../StyledCrmRecordLookup';
import { colors } from '../../../../../../constants/colors';

export const Header = styled(FlexRow)`
  align-items: center;
`;

export const NewRecordResultTitle = styled(ResultTitle)`
  ${rtl`margin-left: 6px;`}
`;

export const ResultAndError = styled(FlexColumn)``;

export const Error = styled.span`
  color: ${colors.ScarletRed};
  font-size: 12px;
  background-color: ${colors.gray10};
  transform: translateY(-8px);
`;
