import styled from 'styled-components';
import capitalize from 'lodash/capitalize';
import { ActionButton } from '@fluentui/react';
import { FlexRow, FlexRowCentered, StyledText } from '../../../../utils/styles/StyledUtils';
import { rtl } from '../../../../utils/styles/rtl';
import { timelineLeftMargin } from '../Timeline/StyledTimeline';
import { AUTOMATION_SELECTORS, dataAutomation } from '../../../../constants/selectors';
import { AUDIO_PLAYER_SMALL_BREAK_POINT_WIDTH } from '../../StyledCallPage';
import { deviceSizes } from '../../../../utils/styles/device-sizes';

const SEGMENTS_CONTAINER_HEIGHT = '32px';
const HEIGHT_TRANSITION = 'height 0.3s ease';
const TIMELINES_CONTAINER_PADDING_TOP = 2;

export const DualTimelineContainer = styled.div.attrs({
  [dataAutomation]: AUTOMATION_SELECTORS.DUAL_TIMELINE
})`
  position: relative;

  ${({ isSmallPlayer }) => isSmallPlayer && `
    display: none;
  `}

  ${deviceSizes.bigMobile`
    display: none
  `}
`;

export const LegendsContainer = styled(FlexRow)`
  margin: 0 20px;
  margin-top: 30px;
  height: 42px;

  @media (max-width: ${AUDIO_PLAYER_SMALL_BREAK_POINT_WIDTH}px) {
    flex-direction: column;
    justify-content: center;
    margin: 0;
    position: absolute;
    ${({ shouldHideComments }) => shouldHideComments && `
      margin: 0 16px;
      margin-top: 4px;
    `}
`;

export const LegendItems = styled(FlexRowCentered)``;

export const LegendItem = styled(FlexRowCentered)`
  margin: 0 4px;
  @media (max-width: ${AUDIO_PLAYER_SMALL_BREAK_POINT_WIDTH}px) {
    ${({ shouldHideComments }) => !shouldHideComments && `
      display: none;
  `}
  }
`;

export const LegendDot = styled.div`
  height: 16px;
  width: 6px;
  margin: 0 auto;
  ${rtl`margin-right`}: 4px;
  background: ${({ theme, sentimentType }) => theme.colors[capitalize(sentimentType)]};
  border-radius: ${({ sentimentType }) => sentimentType === 'neutral' ? '4px' : sentimentType === 'negative' ? '0px 4px 4px 0px' : '4px 0px 0px 4px'};
`;

export const Segments = styled(FlexRowCentered)`
  height: ${SEGMENTS_CONTAINER_HEIGHT};
  color: ${({ theme }) => theme.colors.Gray130};
  position: relative;
  z-index: 1;
`;

export const SegmentsTimeline = styled(FlexRow)`
  flex: 1;
  height: 100%;
  position: relative;
  ${rtl`margin-left`}: ${timelineLeftMargin};
`;

export const SegmentItem = styled(FlexRow)`
  justify-content: center;
  position: absolute;
  border: 1px solid transparent;
  border-top: 2px solid transparent;
  
  ${({ height }) => height && `
    height: calc(${height}px + ${SEGMENTS_CONTAINER_HEIGHT});
  `}
    
  ${rtl`
    border-right-color: ${({ theme }) => theme.colors.Gray50};

    &:last-child {
      border-right-color: transparent;
    }
  `}

  ${rtl`left`}: calc(${({ pointStart }) => pointStart}%);
  ${rtl`right`}: calc(100% - ${({ pointEnd }) => pointEnd}%);

  ${rtl`
    ${({ isSelected, theme }) => isSelected ? `
      color: ${theme.colors.Gray160};
      font-weight: 600;
      border-color: ${theme.colors.Gray50};
      border-top-color: ${theme.colors.DYNPrimary};
      
        &:not(:first-child):not(:last-child) {
          border-left-color: transparent;
        }
        
        &:last-child {
          border-right-color: ${theme.colors.Gray50};
        }
   ` : ''}
 `}
 
 ${({ isGreyedOut }) => isGreyedOut && `
    background-color: ${({ theme }) => theme.colors.Gray20};
 `}
 
 ${({ isDisabled }) => isDisabled && `
    pointer-events: none;
 `}
`;

export const SegmentItemTitle = styled(ActionButton).attrs({
  styles: {
    textContainer: {
      overflow: 'hidden',
    },
    label: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      height: 16
    },
  }
})`
display: block;
padding: 5px;
cursor: pointer;
color: currentColor;
width: 100%;

&:hover {
  color: ${({ theme }) => theme.colors.DYNPrimary};
}`;

export const SegmentItemTooltipTitle = styled(FlexRowCentered)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
`;

export const SegmentItemTooltipTime = styled(FlexRowCentered)`
`;

export const SentimentLabel = styled(FlexRowCentered)`
  ${rtl`margin-right`}: 20px;
  @media (max-width: ${AUDIO_PLAYER_SMALL_BREAK_POINT_WIDTH}px) {
    padding-bottom: 2px;
    ${({ shouldHideComments }) => !shouldHideComments && `
    display: none;
`}
  }
`;

export const TooltipHostStyles = {
  root: {
    zIndex: 3,
    height: '12px'
  }
};

export const segmentTitleTooltipStyles = {
  root: {
    overflow: 'hidden',
  }
};

export const TimelinesContainer = styled.div`
  transition: ${HEIGHT_TRANSITION};
  overflow: hidden;
  padding-top: ${TIMELINES_CONTAINER_PADDING_TOP}px;

   ${({ height }) => height && `
    height: calc(${height}px + ${TIMELINES_CONTAINER_PADDING_TOP}px);
  `}
`;

export const SentimentText = styled(StyledText)`
  ${rtl`margin-right`}: 4px;
  @media (max-width: ${AUDIO_PLAYER_SMALL_BREAK_POINT_WIDTH}px) {
    margin: 0 6px;
  }
`;

export const CommentsInfoIconWrapper = styled.div`
  ${rtl`margin-left`}: 4px;
  margin-top: 4px;
`;

export const CommentsCheckBoxContainer = styled(FlexRowCentered)`
  pointer-events: all;

  ${({ shouldHideLegends }) => !shouldHideLegends && `
    margin: 0 20px;
  `}
`;
