import styled from 'styled-components';
import { StyledText } from '../../utils/styles/StyledUtils';

export const ToastContainer = styled.div`
  white-space: nowrap;
`;

export const Toast = styled(StyledText)`
  position: fixed;
  background: ${({ theme }) => theme.colors.Black };
  color: ${({ theme }) => theme.colors.White };
  z-index: 2;
  bottom: 20px;
  opacity: 0.9;
  padding: 6px 12px;
  font-size: 12px;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
`;
