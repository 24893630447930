import React, { useRef, useState, useEffect } from 'react';
import { isEmpty, sortBy } from 'lodash';
import { ENTER_KEY_CODE, ESCAPE_KEY_CODE } from '../../constants/key-codes';
import { RemoveButton, InlineList, StyledTag, Text, AddButton, ListContainer, Input, InputErrorMessage, ListErrorMessage, FocusableContainer } from './StyledTagList';
import { Tooltip } from '../Tooltip';

const AddOrInput = ({ isInputShown, textInputRef, setIsInputShownWrapper, addTag, addButtonAriaText, addButtonText, tags }) => {
  const shouldShowOnlyAddIcon = !isEmpty(tags);

  const onInputBlur = (e) => {
    if (e.target.value) {
      addTag(e.target.value);
      textInputRef.current.focus();
    } else {
      setIsInputShownWrapper(false);
    }
  };

  const onInputKeyDown = (e) => {
    if (e.key === ENTER_KEY_CODE) {
      e.preventDefault();
      const { value } = e.target;
      addTag(value);
    }
    if (e.key === ESCAPE_KEY_CODE) setIsInputShownWrapper(false);
  };

  return isInputShown ? (
    <Input
      ref={textInputRef}
      onBlur={onInputBlur}
      onKeyDown={onInputKeyDown}
    />
  ) : (
    <Tooltip content={shouldShowOnlyAddIcon && (addButtonText || 'Add')}>
      <AddButton
        styles={{ flexContainer: { flexDirection: 'row-reverse' } }}
        text={!shouldShowOnlyAddIcon && (addButtonText || 'Add')}
        iconProps={{ iconName: 'Add' }}
        onClick={() => setIsInputShownWrapper(true)}
        isListEmpty={isEmpty(tags)}
        shouldShowOnlyAddIcon={shouldShowOnlyAddIcon}
        ariaLabel={addButtonAriaText}
      />
    </Tooltip>
  );
};

export const TagList = ({
  valuesList, onChange, onAddTag, onRemoveTag, minLength, addButtonAriaText, addButtonText, isReadOnly, maxHeight, errorMessages, meta, serverError }) => {
  const [inputError, setError] = useState();
  const [isInputShown, setIsInputShown] = useState(false);
  const [tags, setTags] = useState(valuesList || []);
  const textInputRef = useRef();
  const ListContainerRef = useRef();
  const errorAlreadyExist = (errorMessages && errorMessages.errorAlreadyExist) || 'Value already exists';
  const errorMinLength = (errorMessages && errorMessages.errorMinLength) || (minLength && `Must contain at least ${minLength} characters`);
  const inputAdjacentErrorMessage = inputError || serverError;
  const listErrorMessage = meta && meta.dirty && meta.error && errorMessages[meta.error];

  const setIsInputShownWrapper = (shouldShow) => {
    setError(null);
    setIsInputShown(shouldShow);
  };

  useEffect(() => setTags(valuesList || []),
    [valuesList]);

  useEffect(() => {
    if (isInputShown) textInputRef.current.focus();
  }, [isInputShown]);

  useEffect(() => {
    // scrolling to bottom of TagsContainer, once scrollBar becomes visible
    if (maxHeight && ListContainerRef.current.clientHeight < ListContainerRef.current.scrollHeight) {
      ListContainerRef.current.scrollTop = ListContainerRef.current.scrollHeight;
    }
  }, [tags, isInputShown, inputAdjacentErrorMessage]);

  const clearText = () => {
    textInputRef.current.value = '';
  };

  const addTag = (untrimmedValue) => {
    const value = untrimmedValue?.trim();
    const alreadyExists = tags.some((tag) => tag.toLowerCase() === value.toLowerCase());
    const isInvalid = (minLength && value.length < minLength) || alreadyExists;

    if (isInvalid) {
      setError(alreadyExists ? errorAlreadyExist : errorMinLength);
    } else {
      setError(null);
    }

    if (value && !isInvalid) {
      const prevTags = [...tags];
      const newTags = [...tags, value];
      setTags(newTags);
      if (onChange) onChange(newTags);
      if (onAddTag) onAddTag(value, prevTags);
      clearText();
    }
  };

  const onDelete = (value) => {
    const prevTags = [...tags];
    const newTags = tags.filter((v) => v !== value);
    setTags(newTags);
    if (onChange) onChange(newTags);
    if (onRemoveTag) onRemoveTag(value, prevTags);
  };

  return (
    <>
      <ListContainer maxHeight={maxHeight} ref={ListContainerRef}>
        <InlineList>
          {sortBy(tags).map((item) => (
            <FocusableContainer role="button">
              <StyledTag ariaLabel={item} key={item}>
                <Text isReadOnly={isReadOnly}>{item}</Text>
                {!isReadOnly && (
                  <RemoveButton
                    styles={{ root: { height: '100%' } }}
                    iconProps={{ iconName: 'Cancel' }}
                    ariaLabel={`Delete ${item}`}
                    onClick={() => onDelete(item)}
                  />
                )}
              </StyledTag>
            </FocusableContainer>
          ))}
          {!isReadOnly && (
            <AddOrInput
              isReadOnly={isReadOnly}
              isInputShown={isInputShown}
              textInputRef={textInputRef}
              setIsInputShownWrapper={setIsInputShownWrapper}
              addButtonAriaText={addButtonAriaText}
              addTag={addTag}
              addButtonText={addButtonText}
              tags={tags}
            />
          )}
          {inputAdjacentErrorMessage && (
            <InputErrorMessage>{inputAdjacentErrorMessage}</InputErrorMessage>)}
        </InlineList>
      </ListContainer>
      {listErrorMessage && <ListErrorMessage role="alert">{listErrorMessage}</ListErrorMessage>}
    </>
  );
};
