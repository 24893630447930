import React from 'react';
import useLocale from 'hooks/use-locale';
import { Tooltip } from 'ci-common-ui';
import { PersonaSize } from '@fluentui/react';
import {
  StyledDropdown,
  DropdownTitle,
  DropdownTitleText,
  dropdownStyles,
  OptionContainer,
  StyledPersonaCoin,
  Star,
} from './StyledSellerBarDropdown';

const SellerDropdownOption = ({ option: { text, imageUrl } }) => (
  <OptionContainer>
    <StyledPersonaCoin size={PersonaSize.size24} imageUrl={imageUrl} text={text} />
    <div>{text}</div>
  </OptionContainer>
);

const OnRenderOption = (option) => <SellerDropdownOption option={option} />;

const OnRenderTitle = ({ selectedItems: [{ text, isTopPerformerAgent }], tooltipText }) => (
  <DropdownTitle>
    <DropdownTitleText>
      {text}
    </DropdownTitleText>
    {isTopPerformerAgent && (
    <Tooltip content={tooltipText}>
      <Star />
    </Tooltip>
    )}
  </DropdownTitle>
);

const SellerBarDropdown = ({ selectedKey, sellerOptions, onChangeSeller }) => {
  const { t } = useLocale('common');

  return (
    <StyledDropdown
      selectedKey={selectedKey}
      onChange={(event, selectedOption) => onChangeSeller(selectedOption.key)}
      options={sellerOptions}
      styles={dropdownStyles}
      onRenderOption={OnRenderOption}
      onRenderTitle={(selectedItems) => <OnRenderTitle selectedItems={selectedItems} tooltipText={t('top_performer')} />}
      ariaLabel="Seller selection"
    />
  );
};

export default SellerBarDropdown;
