import React from 'react';
import { DefaultButton, PrimaryButton, Modal, IconButton } from '@fluentui/react';
import { Container, Header, Title, Content, Footer, cancelButtonStyles, modalStyles, closeIconStyles } from './StyledConfirmationModal';

export const ConfirmationModal = ({ title, subtitle, onConfirm, onCancel, confirmButtonText, cancelButtonText, isOpen }) => (
  <Modal styles={modalStyles} isOpen={isOpen}>
    <Container>
      <Header>
        <Title>{title}</Title>
        <IconButton iconProps={{ iconName: 'Cancel', styles: closeIconStyles }} onClick={onCancel} />
      </Header>
      <Content>{subtitle}</Content>
      <Footer>
        <PrimaryButton onClick={onConfirm}>{confirmButtonText}</PrimaryButton>
        <DefaultButton styles={cancelButtonStyles} onClick={onCancel}>{cancelButtonText}</DefaultButton>
      </Footer>
    </Container>
  </Modal>
);
