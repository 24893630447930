// eslint-disable-next-line no-unused-vars
import React from 'react';
import styled from 'styled-components';
import { Shimmer } from '@fluentui/react';
import { FlexColumn, FlexRow } from 'ci-common-ui';

export const Avatar = styled(Shimmer)`
  width: 72px;
  margin-top: 16px;
  margin-right: 20px;
  .ms-Shimmer-shimmerWrapper {
    height: 72px;
    border-radius: 50%;
  }
`;

export const AvatarLines = styled(FlexColumn)`
  justify-content: center;
  margin-top: 16px;
`;

export const AvatarLineShimmer = styled(Shimmer)`
  .ms-Shimmer-shimmerWrapper {
    height: 14px;
  }
`;

export const AvatarLineShimmer1 = styled(AvatarLineShimmer)`
  width: 160px;
  margin-bottom: 16px;
`;

export const AvatarLineShimmer2 = styled(AvatarLineShimmer)`
  width: 200px;
`;

export const Kpis = styled(FlexRow)`
  flex-wrap: wrap;
  & > div:not(:last-child) {
    margin-right: 20px;
  }
`;

export const KpiShimmer1 = styled(Shimmer)`
  width: 100px;
  margin-top: 16px;
  margin-bottom: 12px;
  .ms-Shimmer-shimmerWrapper {
    height: 30px;
  }
`;

export const KpiShimmer2 = styled(Shimmer)`
  width: 80px;
  .ms-Shimmer-shimmerWrapper {
    height: 20px;
  }
`;
