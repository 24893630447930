import React, { useRef } from 'react';
import toNumber from 'lodash/toNumber';
import get from 'lodash/get';
import { Field } from 'react-final-form';
import { Calendar, Callout, Dropdown, FocusTrapZone } from '@fluentui/react';
import { datePickerDayTimes, datePickerDayTimesParts, defaultDatePickerStrings } from '../../../../../../../../components/CalendarPicker';
import { useToggler } from '../../../../../../../../hooks/use-toggler';
import { StyledIcon, TimePicker, timeSlotDropdownStyles } from './StyledDatePickerField';
import { EXTENDED_FORMAT, EXTENDED_FORMAT_WITH_TIME, TIME_FORMAT, formatDate, getDateObject, dayJS } from '../../../../../../../../utils/date/date';
import { ClearFieldButton, DetailsFieldContainer } from '../../StyledCommitForm';
import { useInfra } from '../../../../../../../../providers/InfraProvider';
import { CALL_STRINGS } from '../../../../../../strings';
import { TrackedLink } from '../../../../../../../../components/TrackedLink';

const DEFAULT_TIME_SLOT = datePickerDayTimes[datePickerDayTimesParts.timeSlots.length];
const DEFAULT_DATE = new Date();

export const DatePickerField = ({ name, isTimeEnabled, isDisabled }) => {
  const { t, trackEvent } = useInfra();
  const buttonRef = useRef();
  const [isCalendarShown, toggleCalendarVisibility] = useToggler(false);

  const onSelectDate = (input, newDate) => {
    const lastTime = input.value.date && formatDate(input.value.date, TIME_FORMAT);
    updateInputValue(input, newDate, lastTime);
  };

  const onTimeChanged = (input) => (event, selectedOption) => {
    updateInputValue(input, input.value.date, selectedOption.key);
  };

  const updateInputValue = (input, updatedDate, timeData) => {
    if (isTimeEnabled) {
      const updatedTimeSlot = timeData || DEFAULT_TIME_SLOT;
      const [hour, minuteAndPartOfDay] = !updatedTimeSlot ? DEFAULT_TIME_SLOT.split(':') : updatedTimeSlot.split(':');
      const [minute, partOfDay] = minuteAndPartOfDay.split(' ');

      const newDate = dayJS(updatedDate || new Date())
        .hour((toNumber(hour) % 12) + (partOfDay === 'PM' ? 12 : 0))
        .minute(toNumber(minute))
        .second(0);

      input.onChange({
        date: getDateObject(newDate),
      });
    } else {
      input.onChange({ date: getDateObject(updatedDate) });
    }
  };

  const dateFormat = isTimeEnabled ? EXTENDED_FORMAT_WITH_TIME : EXTENDED_FORMAT;

  const onClear = (input) => {
    input.onChange(null);
  };

  return (
    <Field
      name={name}
      render={({ input }) => {
        const date = get(input, 'value.date');
        const currentDate = date && formatDate(date, dateFormat);
        const currentTime = date && formatDate(date, TIME_FORMAT);

        return (
          <>
            <div ref={buttonRef}>
              <DetailsFieldContainer>
                <StyledIcon iconName="Calendar" />
                {isDisabled ? (
                  <div>{currentDate || t(CALL_STRINGS.SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_DATE_NOT_SET)}</div>
                ) : (
                  <>
                    <TrackedLink onClick={toggleCalendarVisibility} trackEvent={trackEvent}>
                      {currentDate || t(CALL_STRINGS.SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_SET_DATE)}
                    </TrackedLink>
                    {currentDate && <ClearFieldButton onClick={() => onClear(input)} />}
                  </>
                )}
              </DetailsFieldContainer>
            </div>
            {isCalendarShown && (
              <Callout
                isBeakVisible={false}
                className="ms-DatePicker-callout"
                gapSpace={0}
                doNotLayer={false}
                target={buttonRef.current}
                onDismiss={toggleCalendarVisibility}
              >
                <FocusTrapZone firstFocusableSelector="ms-DatePicker-day--today" isClickableOutsideFocusTrap>
                  <Calendar
                    onDismiss={toggleCalendarVisibility}
                    isMonthPickerVisible={false}
                    value={date || DEFAULT_DATE}
                    onSelectDate={(newDate) => onSelectDate(input, newDate)}
                    strings={defaultDatePickerStrings}
                    isDayPickerVisible
                    showGoToToday
                    showCloseButton
                  />
                  {
                    isTimeEnabled && (
                      <TimePicker>
                        <Dropdown
                          selectedKey={currentTime || DEFAULT_TIME_SLOT}
                          onChange={onTimeChanged(input)}
                          options={datePickerDayTimes.map((timeSlot) => ({ key: timeSlot, text: timeSlot }))}
                          styles={timeSlotDropdownStyles}
                        />
                      </TimePicker>
                    )
                  }
                </FocusTrapZone>
              </Callout>
            )}
          </>
        );
      }}
    />
  );
};
