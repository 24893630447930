import React from 'react';
import useLocale from 'hooks/use-locale';
import {
  PageErrorImage, PageErrorContainer, Subtitle, Title,
} from './StyledPageError';

const PageError = ({ title, subtitle }) => {
  const { t } = useLocale('errors.page');

  return (
    <PageErrorContainer>
      <PageErrorImage />
      <Title>{title || t('title')}</Title>
      <Subtitle>{subtitle || t('subtitle')}</Subtitle>
    </PageErrorContainer>
  );
};
export default PageError;
