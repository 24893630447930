import React, { useState, useEffect } from 'react';
import useLocale from 'hooks/use-locale';
import { useHistory, Prompt } from 'react-router';
import { PrimaryButton, DefaultButton, Dialog, DialogFooter } from '@fluentui/react';

const usePrompt = () => {
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation);
    }
  }, [confirmedNavigation, lastLocation]);

  const blockedNavigation = (nextLocation) => {
    if (!confirmedNavigation) {
      setIsOpen(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const onOk = () => {
    setIsOpen(false);
    setConfirmedNavigation(true);
  };

  const onCancel = () => {
    setIsOpen(false);
  };

  return { isOpen, onOk, onCancel, blockedNavigation };
};

const SettingsPrompt = ({ when }) => {
  const { t } = useLocale('common');
  const { isOpen, onOk, onCancel, blockedNavigation } = usePrompt();

  return (
    <>
      <Prompt when={when} message={blockedNavigation} />
      <Dialog
        hidden={!isOpen}
        dialogContentProps={{
          title: t('prompt_dialog_title'),
          subText: t('prompt_dialog_subtitle'),
        }}
      >
        <DialogFooter>
          <PrimaryButton onClick={onOk} text={t('ok')} />
          <DefaultButton onClick={onCancel} text={t('cancel')} />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default SettingsPrompt;
