import { ActionButton, NormalPeoplePicker, Persona, PersonaSize} from '@fluentui/react';
import styled from 'styled-components';
import { colors } from '../../../../constants/colors';
import { rtl } from '../../../../utils/styles/rtl';
import { FlexColumn, FlexRow, FlexRowCentered, StyledText } from '../../../../utils/styles/StyledUtils';

export const SelectedRecordContainer = styled(FlexRowCentered)`
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 30px;
`;

export const SelectedRecordDetails = styled(FlexColumn)`
`;

export const SelectedRecordType = styled(StyledText)`
  font-size: 12px;
`;

export const SuggestionItemContainer = styled(FlexRow)`
  padding: 8px;
  width: calc(100% - 8px);
  margin-bottom: 8px;
`;

export const SuggestionHeader = styled(FlexRow)`
  padding: 12px 16px;
  width: 100%;
  font-size: 12px;
  color: ${colors.Gray130};
  background-color: ${colors.White};
  z-index: 1;
  cursor: default;
  box-sizing: border-box;
`;

export const SuggestionItemDetails = styled(FlexColumn)`
  justify-content: flex-end;
  width: calc(100% - 40px);
  ${rtl`margin-left`}: 12px;
  flex: 1;
`;

export const SuggestionItemAvatar = styled(Persona).attrs({
  hidePersonaDetails: true,
  size: PersonaSize.size24
})`
  margin-top: 6px;
`;

export const SuggestionItemDetailsTitle = styled(FlexRowCentered)`
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const SuggestionDetailContainer = styled(FlexRow)`
  margin-top: 4px;
`;

export const SuggestionDetailLabel = styled(StyledText)`
  width: 56px;
  font-size: 12px;
  text-align: ${rtl`left`};
`;

export const SuggestionDetailValue = styled(StyledText)`
  ${rtl`margin-left`}: 10px;
  color: ${colors.Gray130};
  font-size: 12px;
`;

export const getExpandRecordIconStyles = () => ({
  root: {
    color: colors.Gray160,
    selectors: {
      ':hover': {
        color: colors.DYNPrimary
      }
    }
  }
});

export const SelectedRecordIconContainer = styled.div`
  ${rtl`margin-right`}: 12px;
  min-width: 16px;
`;

export const SearchInput = styled(NormalPeoplePicker).attrs(({ pickerSuggestionsProps }) => ({
  styles: {
    text: {
      border: 'none',
    },
    input: {
      height: 32,
      minWidth: 50,
      flex: 1,
      background: colors.White,
    },
  },
  pickerSuggestionsProps: {
    ...pickerSuggestionsProps,
    styles: {
      root: {
        minHeight: 40,
        minWidth: 0,
        width: 272,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '8px 0',
      },
      suggestionsContainer: {
        selectors: {
          '.ms-Suggestions-itemButton': {
            borderRadius: 0,
          },
        },
      },
    },
  },
}))`
  .ms-Persona-coin {
    display: none;
  }
  .ms-BasePicker-itemsWrapper {
    background-color: ${colors.White};
  }
  margin-top: 8px;
`;

export const ConnectRecordButton = styled(ActionButton).attrs({
  iconProps: { iconName: 'link_icon' },
  styles: {
    icon: {
      height: '20px',
    }
  }
})``;
