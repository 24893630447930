import React from 'react';
import noop from 'lodash/noop';
import { Field } from 'react-final-form';
import { requiredArrayValidate } from '../../validation';
import { TagList } from '../../../TagList/TagList';

export const TagListField = ({
  name, isRequired, minLength, errorMessages, addButtonAriaText, addButtonText, maxHeight
}) => (
  <Field
    name={name}
    validate={isRequired ? requiredArrayValidate : noop}
    render={({ input, meta }) => (
      <TagList
        valuesList={input.value || []}
        onChange={input.onChange}
        addButtonText={addButtonText}
        addButtonAriaText={addButtonAriaText}
        meta={meta}
        minLength={minLength}
        errorMessages={errorMessages}
        maxHeight={maxHeight}
      />
    )}
  />
);
