import styled from 'styled-components';
import { PrimaryButton, Spinner, SpinnerSize, Icon } from '@fluentui/react';
import { FlexColumnCentered, FlexRow, FlexRowCentered, StyledLink } from '../../../utils/styles/StyledUtils';
import imageProvisionSrc from '../../../assets/images/provision.png';
import imageProvisionTeamsSrc from '../../../assets/images/provision-teams.png';
import PageErrorState from '../../../assets/images/page-error-state.svg';

export const Container = styled(FlexColumnCentered)`
  height: 100%;
  color: ${({ theme }) => theme.colors.Gray160};
`;

export const ProvisionImage = styled.img.attrs(({ theme }) => ({
  alt: 'provision image',
  src: theme.name === 'dark' ? imageProvisionTeamsSrc : imageProvisionSrc,
}))`
  height: 100px;
`;

export const Text = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0;
  text-align: center;
`;

export const SubText = styled.div`
  font-size: 14px;
  margin-bottom: 15px;
  text-align: center;
`;

export const DetailsText = styled(StyledLink)`
`;

export const Button = styled(PrimaryButton)`
  color: white;
  margin: 20px 0;

  &.is-disabled {
    color: ${({ theme }) => theme.colors.Gray90};
  }
`;

export const StatusContainer = styled(FlexRow)`
  align-items: center;
`;

export const Loading = styled(Spinner).attrs(({ isSmall }) => ({
  size: isSmall ? SpinnerSize.xSmall : SpinnerSize.large
}))`
`;

export const LoadingContainer = styled(FlexRowCentered)`
  height: 100%;
  justify-content: center;
`;

export const StatusIcon = styled(Icon).attrs(({ isError }) => ({
  iconName: isError ? 'StatusCircleErrorX' : 'StatusCircleCheckmark'
}))`
  margin: 0 5px;
  border: 1px solid;
  border-radius: 50%;
  color: ${({ theme, isError }) => theme.colors[isError ? 'Error' : 'JadeGreen']};
`;

export const ErrorImage = styled(PageErrorState)`
  width: 160px;
  height: 160px;
`;

export const StatusText = styled.div`
`;

export const checkBoxStyle = {
  label: {
    alignItems: 'flex-start',
    fontSize: 12,
  },
  checkbox: {
    marginTop: 5,
    marginRight: 8,
    marginLeft: 8,
    height: 18,
    width: 18,
  }
};
