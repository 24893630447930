import React from 'react';
import { Form as RFForm } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { get } from 'lodash';

export { Field, FormSpy } from 'react-final-form';

export const Form = ({ onSubmit, onSubmitError, initialValues, render, initialValuesEqual }) => {
  const handleErrorOnSubmit = async (values) => {
    try {
      await onSubmit(values);
    } catch (err) {
      const serverErrorMessage = get(err, 'response.data.message');
      const error = serverErrorMessage || err.message || err;
      if (onSubmitError) {
        onSubmitError(error);
      }
      return { [FORM_ERROR]: error };
    }

    return null;
  };

  return (
    <RFForm onSubmit={handleErrorOnSubmit} initialValues={initialValues} render={render} initialValuesEqual={initialValuesEqual} />
  );
};
