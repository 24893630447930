import { chartConfigs } from 'ci-common-ui';
import { getOvertimeXAxisInterval, series, translateSeries } from 'utils/chart';
import { formatPercentage } from 'utils/numeric';
import { formatMonthDayDate } from 'utils/date/date';

const { combineConfigs, donut, donutKiloTitles, area, accessibilityConfig } = chartConfigs;

export const createOverallConfig = (data, tAbs) => combineConfigs(
  {
    data: data.series,
    seriesParams: translateSeries(series.sentimentParams, tAbs),
    dataLabelsFormatter: ({ percentage }) => formatPercentage(percentage),
    titleText: data.calls.toString(),
    subtitleText: tAbs('common.calls')
  },
  [
    donut,
    donutKiloTitles,
    {
      accessibility: {
        keyboardNavigation: {
          order: ['legend', 'series']
        },
      },
      plotOptions: {
        pie: {
          center: true
        }
      }
    },
  ],
);

export const createOvertimeConfig = (data, tAbs) => combineConfigs(
  {
    data,
    seriesParams: translateSeries(series.sentimentAreaParams, tAbs),
    categoryField: 'day',
    categoryFieldType: 'date',
    xTickInterval: getOvertimeXAxisInterval(data),
    yTickAmount: 3,
    xAxisFormatter: ({ value }) => formatMonthDayDate(value),
    yAxisFormatter: ({ value }) => formatPercentage(value),
  },
  [area, accessibilityConfig],
);
