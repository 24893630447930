import { ActionButton, MessageBar, MessageBarType, TextField, IconButton } from '@fluentui/react';
import React, { useEffect, useState, useRef } from 'react';
import { get, isEmpty, isNull, map, reduce } from 'lodash';
import { useInfra } from '../../../../../../providers/InfraProvider';
import { editUserSummary, getUserSummary } from '../../../../network/data-services/call-service';
import { Container, HeaderContainer, ContentContainer, textFieldStyles, LastEditorAvatar, LastEditorContainer, LastEditorTimestamp, MessageBarContainer, tooltipHostStyles, SummaryContainer, Summary, SummaryPlaceholderContainer, SummaryPlaceholderIcon, SummaryPlaceholderTitle, SummaryPlaceholderSubtitle } from './StyledSummaryBoard';
import { useFetch } from '../../../../../../hooks/use-fetch';
import { timeAgo, formatMonthDayYearDateAndTime } from '../../../../../../utils/date/date';
import { CALL_STRINGS } from '../../../../strings';
import { eventAction } from '../../../../../../services/telemetry-service';
import { TRACK_IDS } from '../../../../tracking';
import { getFeedbackStatus, SURVEY_TYPES } from '../../../../network/data-services/feedback-service';
import { loadFeedback } from '../../../../../../utils/feedback';
import { FocusableContainer } from '../../../../../../utils/styles/StyledUtils';
import { Tooltip } from '../../../../../../components/Tooltip';

export const MESSAGE_BAR_TYPES = {
  SAVE_ERROR: 'SAVE_ERROR',
  DISCARD_CHANGES_ERROR: 'DISCARD_CHANGES_ERROR',
  PERMISSION_ERROR: 'PERMISSION_ERROR',
};

const getMessagesTextMap = (texts) => ({
  [MESSAGE_BAR_TYPES.SAVE_ERROR]: texts.saveError,
  [MESSAGE_BAR_TYPES.PERMISSION_ERROR]: texts.permissionError,
  [MESSAGE_BAR_TYPES.DISCARD_CHANGES_ERROR]: texts.discardChangesError,
});

export const getSummaryEmailBody = (subject, participants, startDate, relatedEntities, notes, t, highlights, actionItems) => {
  const entitiesMap = {
    [t(CALL_STRINGS.CRM_ENTITY_TYPES_ACCOUNT)]: map(get(relatedEntities, 'account'), ({ name }) => name).join(', '),
    [t(CALL_STRINGS.CRM_ENTITY_TYPES_CONTACT)]: map(get(relatedEntities, 'contact'), ({ name }) => name).join(', '),
    [t(CALL_STRINGS.CRM_ENTITY_TYPES_LEAD)]: get(relatedEntities, 'lead.name'),
    [t(CALL_STRINGS.CRM_ENTITY_TYPES_OPPORTUNITY)]: get(relatedEntities, 'opportunity.name'),
    [t(CALL_STRINGS.CRM_ENTITY_TYPES_PHONECALL_ACTIVITY)]: get(relatedEntities, 'phonecall_activity.name')
  };

  const rawEntities = reduce(entitiesMap, (result, value, key) => value ? result.concat(`${key}: ${value}\n`) : result, '');

  const emailDetailsMap = {
    [t(CALL_STRINGS.PARTICIPANTS)]: participants.map(({ displayName }) => displayName || t(CALL_STRINGS.CUSTOMER)).join(', '),
    [t(CALL_STRINGS.EXEC_SUMMARY_BOARD_CALL_DATE_AND_TIME)]: formatMonthDayYearDateAndTime(startDate),
    [t(CALL_STRINGS.RELATED_RECORDS)]: rawEntities,
    [t(CALL_STRINGS.NOTES)]: notes,
    [t(CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_HIGHLIGHTS_TITLE)]: highlights?.length && highlights.map(({content}) => `• ${content}`).join('\n'),
    [t(CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_ACTION_ITEMS_TITLE)]: actionItems?.length && actionItems.map(({content}) => `• ${content}`).join('\n')
  };
  const subjectToCopy = subject ? `${subject}\n\n` : '';
  const body = reduce(emailDetailsMap, (result, value, key) => value ? result.concat(`${key}:\n\n${value}\n\n`) : result, subjectToCopy);
  return body;
};

const Toolbar = ({ isEditMode, isEditable, isSavingSummary, onSave, onDiscardChanges, onCopySummaryToClipboard, onToggleSuggestion, isExecutiveSummaryEnabled, summary, texts }) => {
  const { trackEvent } = useInfra();
  const { save, discardChanges, copyCallTooltip, execSummarySuggestionsTooltip } = texts;

  return (
    <HeaderContainer>
      <div>
        {isEditMode
            && (
              <>
                <ActionButton text={save} iconProps={{ iconName: 'Save' }} onClick={onSave} disabled={isSavingSummary} />
                <ActionButton text={discardChanges} iconProps={{ iconName: 'Cancel' }} onClick={onDiscardChanges} />
              </>
            ) }
        { (!isEditMode && isEditable) && (
            <ActionButton data-tour-index="2" text={copyCallTooltip} iconProps={{ iconName: 'Copy' }} disabled={!summary} onClick={onCopySummaryToClipboard} />
        )}
      </div>
      {isExecutiveSummaryEnabled && onToggleSuggestion && (
        <Tooltip
          content={execSummarySuggestionsTooltip}
          styles={tooltipHostStyles}
          onTooltipVisible={() => trackEvent({ action: eventAction.hover, actionOn: TRACK_IDS.EXEC_SUMMARY_TOGGLE_SUGGESTION_PANEL })}
        >
          <IconButton ariaLabel="suggestions" iconProps={{ iconName: 'LightBulb' }} onClick={onToggleSuggestion} />
        </Tooltip>
      )}
    </HeaderContainer>
  );
};

const Message = ({ onClose, type, texts }) => {
  const message = getMessagesTextMap(texts)[type];

  return (
    <MessageBarContainer>
      <MessageBar
        messageBarType={MessageBarType.error}
        isMultiline
        onDismiss={onClose}
        dismissButtonAriaLabel="Close"
      >
        {message}
      </MessageBar>
    </MessageBarContainer>
  );
};

const ViewSummary = ({ summary, isEditable, onEdit, texts }) => {
  const { readOnlyPlaceholderTitle, readOnlyPlaceholderSubtitle, execSummaryBoardReadWritePlaceholder } = texts;

  if (!isEditable && isEmpty(summary)) {
    return (
      <SummaryPlaceholderContainer>
        <SummaryPlaceholderIcon />
        <SummaryPlaceholderTitle multiline>{readOnlyPlaceholderTitle}</SummaryPlaceholderTitle>
        <SummaryPlaceholderSubtitle multiline>{readOnlyPlaceholderSubtitle}</SummaryPlaceholderSubtitle>
      </SummaryPlaceholderContainer>
    );
  }

  return (
    <SummaryContainer data-testid='summary-container' isEditable={isEditable} onClick={onEdit} data-is-empty={isEmpty(summary)} data-text={execSummaryBoardReadWritePlaceholder}>
      {!isEmpty(summary)
          && (<Summary multiline>{summary}</Summary>) }
    </SummaryContainer>
  );
};

export const SummaryBoardView = ({ summaryDetails, messageBarType, isEditMode, isEditable, isSavingSummary, isExecutiveSummaryEnabled, setSummaryDetails, setMessageBarType, onToggleSuggestion, onEdit, onSave, onCopySummaryToClipboard, onDiscardChanges, texts, counterAddSuggestions }) => {
  const textAreaElement = useRef();

  const lastEditUserName = get(summaryDetails, 'lastEditDetails.fullName');
  const lastEditTimestamp = timeAgo(get(summaryDetails, 'lastEditDetails.utcTimestamp'));

  const focusNotesSummary = () => {
    const currentTextAreaElement = textAreaElement?.current;
    if (currentTextAreaElement) {
      const valueLength = currentTextAreaElement.value?.length;
      if (valueLength) {
        currentTextAreaElement.setSelectionRange(valueLength, valueLength);
        currentTextAreaElement._textElement.current.scrollTo({
          top: textAreaElement.current._textElement.current.scrollHeight,
          behavior: 'smooth'
        });
      }
      currentTextAreaElement._textElement.current.focus({ preventScroll: true });
    }
  };

  useEffect(() => {
    if (isEditMode || counterAddSuggestions > 0) {
      focusNotesSummary();
    }
  }, [isEditMode, counterAddSuggestions]);

  return (
    <Container>
      <Toolbar
        isEditMode={isEditMode}
        isEditable={isEditable}
        isSavingSummary={isSavingSummary}
        isExecutiveSummaryEnabled={isExecutiveSummaryEnabled}
        onSave={onSave}
        onCopySummaryToClipboard={onCopySummaryToClipboard}
        onDiscardChanges={onDiscardChanges}
        onToggleSuggestion={onToggleSuggestion}
        summary={summaryDetails.content}
        texts={texts}
      />

      {messageBarType
        && (
        <Message
          type={messageBarType}
          onClose={() => setMessageBarType()}
          texts={texts}
        />
        )}
      <ContentContainer messageBarType={messageBarType}>
        {get(summaryDetails, 'lastEditDetails') && (
          <FocusableContainer aria-label={`${lastEditUserName} ${lastEditTimestamp}`} role="button">
            <LastEditorContainer>
              <Tooltip content={lastEditUserName} styles={tooltipHostStyles}>
                <LastEditorTimestamp>{lastEditTimestamp}</LastEditorTimestamp>
                <LastEditorAvatar
                  text={lastEditUserName}
                  hidePersonaDetails
                  imageUrl=""
                />
              </Tooltip>
            </LastEditorContainer>
          </FocusableContainer>
        )}
        {isEditMode
          ? (
            <TextField
              componentRef={textAreaElement}
              styles={textFieldStyles}
              value={summaryDetails.content}
              onChange={(e) => setSummaryDetails({ ...summaryDetails, content: e.target.value })}
              placeholder={texts.textPlaceholder}
              multiline
              disabled={isSavingSummary}
              resizable={false}
            />
          )
          : (
            <ViewSummary summary={summaryDetails.content} isEditable={isEditable} texts={texts} onEdit={onEdit} />
          )}
      </ContentContainer>
    </Container>
  );
};

export const SummaryBoard = ({ id, summaryDetails, setSummaryDetails, resetSummaryDetails, onToggleSuggestion, isEditMode, setIsEditMode, onEdit, onCopySummaryToClipboard, isExecutiveSummaryEnabled, counterAddSuggestions }) => {
  const { setLeavingPagePromptMessage, trackEvent, orgUrl, orgId, tenantId, orgLCID, isEditable, t } = useInfra();

  const [messageBarType, setMessageBarType] = useState();
  const { data: updatedSummaryDetails, fetchData: fetchGetSummary } = useFetch(() => getUserSummary(id));
  const { fetchData: saveSummary, isLoading: isSavingSummary, error: saveError } = useFetch(() => editUserSummary(id, summaryDetails.content || ''));

  useEffect(() => {
    if (isEditMode && !saveError && !isSavingSummary) {
      setIsEditMode(false);
      setLeavingPagePromptMessage(null);
      fetchGetSummary();
      setMessageBarType();
    }
    if (saveError) {
      const isPrivilegeError = get(saveError, 'response.status') === 403;
      if (isPrivilegeError) {
        setMessageBarType(MESSAGE_BAR_TYPES.PERMISSION_ERROR);
      } else {
        setMessageBarType(MESSAGE_BAR_TYPES.SAVE_ERROR);
      }
    }
  }, [saveError, isSavingSummary]);

  useEffect(() => {
    if (!isNull(updatedSummaryDetails)) {
      setSummaryDetails(updatedSummaryDetails);
    }
  }, [updatedSummaryDetails]);

  useEffect(() => () => setLeavingPagePromptMessage(null), []);

  const showFeedback = async () => {
    const { shouldShowFeedback, formsProId, formsProEligibilityId } = await getFeedbackStatus({ orgUrl, surveyType: SURVEY_TYPES.executiveSummary });
    if (shouldShowFeedback) {
      loadFeedback({ formsProId, formsProEligibilityId, orgLCID, tenantId, orgId, trackEvent });
    }
  };

  const onDiscardChanges = () => {
    resetSummaryDetails(updatedSummaryDetails);
    setIsEditMode(false);
    setLeavingPagePromptMessage(null);
    trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.EXEC_SUMMARY_DISCARD_CHANGES });
  };

  const onSave = () => {
    saveSummary(id, summaryDetails.content);
    showFeedback();
    trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.EXEC_SUMMARY_SAVE });
  };

  const texts = {
    textPlaceholder: t(CALL_STRINGS.EXEC_SUMMARY_BOARD_TEXT_PLACEHOLDER),
    readOnlyPlaceholderTitle: t(CALL_STRINGS.EXEC_SUMMARY_BOARD_READ_ONLY_PLACEHOLDER_TITLE),
    readOnlyPlaceholderSubtitle: t(CALL_STRINGS.EXEC_SUMMARY_BOARD_READ_ONLY_PLACEHOLDER_SUBTITLE),
    execSummaryBoardReadWritePlaceholder: t(CALL_STRINGS.EXEC_SUMMARY_BOARD_READ_WRITE_PLACEHOLDER),
    save: t(CALL_STRINGS.SAVE),
    discardChanges: t(CALL_STRINGS.DISCARD_CHANGES),
    edit: t(CALL_STRINGS.EDIT),
    copyCallTooltip: t(CALL_STRINGS.COPY_CALL_TOOLTIP),
    execSummarySuggestionsTooltip: t(CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_TOOLTIP),
    saveError: t(CALL_STRINGS.EXEC_SUMMARY_BOARD_SAVE_ERROR_MESSAGE),
    permissionError: t(CALL_STRINGS.EXEC_SUMMARY_BOARD_SAVE_PERMISSION_MESSAGE),
    discardChangesError: t(CALL_STRINGS.EXEC_SUMMARY_BOARD_DISCARD_ERROR_MESSAGE),
  };

  return (
    <SummaryBoardView
      summaryDetails={summaryDetails}
      messageBarType={messageBarType}
      isEditMode={isEditMode}
      isEditable={isEditable}
      isSavingSummary={isSavingSummary}
      isExecutiveSummaryEnabled={isExecutiveSummaryEnabled}
      setSummaryDetails={setSummaryDetails}
      resetSummaryDetails={resetSummaryDetails}
      setMessageBarType={setMessageBarType}
      onToggleSuggestion={onToggleSuggestion}
      onEdit={onEdit}
      onSave={onSave}
      onCopySummaryToClipboard={onCopySummaryToClipboard}
      onDiscardChanges={onDiscardChanges}
      texts={texts}
      counterAddSuggestions={counterAddSuggestions}
    />
  );
};
