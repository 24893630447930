export const jsonParse = (data, fallback) => {
  try {
    const parsedData = JSON.parse(data);
    return parsedData;
  } catch (e) {
    return fallback !== undefined ? fallback : data; // allowing fallback to be any falsy value like null
  }
};

export const mapObject = (obj, mapObjectEntryFunction) => Object.fromEntries(Object.entries(obj).map(([key, value]) => {
  const { key: mappedKey, value: mappedValue } = mapObjectEntryFunction(key, value);
  return [mappedKey, mappedValue];
}));