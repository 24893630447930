import { filter, map } from 'lodash';
import { isTimezoneDateString, toLocal } from '../../../../../utils/date/date';

export const transformActionItems = (actionItems, isStorageTypeDVOnly) => map(actionItems, (actionItem) => ({
  id: isStorageTypeDVOnly ? actionItem.crmId : actionItem.id,
  isCommitted: actionItem.isCommitted,
  template: actionItem.template,
  nextAvailableEntityId: actionItem.nextAvailableEntityId,
  offset: actionItem.offset,
  length: actionItem.length,
  fragmentId: actionItem.fragmentId,
  type: actionItem.linkedCrmActivityType ?? actionItem.type,
  linkedCrmActivityId: actionItem.linkedCrmActivityId,
  suggestedRegardingEntities: actionItem.suggestedRegardingEntities,
  entities: map(actionItem.entities, transformEntity),
  content: actionItem.defaultText,
}));

const transformEntity = ({
  type,
  templateId,
  currentValues,
  suggestions,
}) => ({
  type,
  templateId,
  currentValues: map(currentValues, (props) => transformEntityValue(props, type, false)),
  suggestions: map(suggestions, (props) => transformEntityValue(props, type, true)),
});

const transformEntityValue = ({
  text,
  email,
  crmId,
  crmEntityType,
}, type, removeQuotesFromText) => {
  let newText = text;

  if (removeQuotesFromText) {
    newText = text.replace(/"/g, '').replace(/\.\.\./g, '');
  }
  if (type === 'Date') {
    newText = isTimezoneDateString(text) ? text : toLocal(text).format();
  }

  return ({
    text: newText,
    email,
    crmId,
    crmEntityType
  });
};

export const transformCrmLookupResults = (results, shouldTransformType) => map(results, (result) => transformCrmLookupResult(result, shouldTransformType));

export const CrmEntityType = {
  systemuser: 'SystemUser',
  contact: 'Contact',
  account: 'Account',
  lead: 'Lead',
  unknown: 'Unknown',
};

export const convertToCrmEntityType = (type) => CrmEntityType[type] || type;

export const transformCrmLookupResult = ({ type, attributes, id, primaryName }, shouldTransformType) => ({
  type: shouldTransformType ? convertToCrmEntityType(type) : type,
  attributes: filter(map(attributes, transformAttribute)),
  id,
  key: id,
  primaryName
});

const SUPPORTED_ATTRIBUTES = ['subject', 'title', 'emailaddress1', 'internalemailaddress', 'companyname'];

const transformAttribute = (value, key) => SUPPORTED_ATTRIBUTES.includes(key) && value;
