import styled from 'styled-components';
import { DefaultButton, IconButton } from '@fluentui/react';
import { rtl } from '../../../utils/styles/rtl';
import { FlexRow, FlexSpaceBetween, StyledText } from '../../../utils/styles/StyledUtils';

const COLLAPSE_TIME = '0.3s';

export const getTextStyles = (isTextHidden) => {
  const height = isTextHidden && 0;
  const marginBottom = isTextHidden ? 0 : 12;
  return ({
    root: { height, marginBottom }
  });
};

const ControlButtonBase = styled(DefaultButton).attrs(({ theme, disabled }) => ({
  styles: getControlStyles(disabled, theme)
}))`
  :hover {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }

  &:after {
    border: solid 1px ${({ theme }) => theme.colors.Black};
    border-radius: 2px;
  }
`;

export const Container = styled.div`
  padding: 8px;
  ${rtl`padding-left: 4px`};
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  border-radius: ${({ isRenderedOnTeams }) => isRenderedOnTeams ? '4px' : '2px'};
`;

export const RecordingControls = styled(FlexRow)``;

export const Record = styled(ControlButtonBase)`
  border-color: ${({ theme }) => theme.colors.Gray140};;
`;

export const Pause = styled(ControlButtonBase)``;
export const Stop = styled(ControlButtonBase)``;
export const Resume = styled(ControlButtonBase)``;

export const ControlsContainer = styled(FlexSpaceBetween)`
  flex-wrap: wrap;
  padding: 0 8px;
`;

export const Text = styled(StyledText)`
  display: flex;
  padding: 0 8px;
  font-size: 12px;
  transition: margin ${COLLAPSE_TIME} ease-out;
  color: ${({ theme }) => theme.colors.Gray160};
  ${rtl`margin-right: 14px`};
  line-height: 16px;
`;

export const Chevron = styled(IconButton)`
  position: absolute;
  ${rtl`right: 0px`};
  top: 0;
  height: 20px;
  width: 16px;
  align-self: start;
  color: ${({ theme }) => theme.colors.Gray140};
  ${({ isExpanded }) => `transform: rotate(${!isExpanded ? '-180' : '0'}deg)`};
  transition: all ${COLLAPSE_TIME} ease;
  background-color: inherit;
  :hover {
    background-color: inherit;
  }
  i {
    font-size: 10px;
  }
`;

export const TextAndControlsContainer = styled(FlexSpaceBetween)`
  position: relative;
`;

export function getControlStyles(disabled, theme) {
  const commonConrtrolStyles = {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: theme.colors.Gray60,
    background: theme.colors.backgroundColor,
  };
  return {
    label: {
      color: disabled ? theme.colors.Gray60 : theme.colors.Gray160,
      lineHeight: 16,
      fontWeight: 400,
      margin: '0 8px'
    },
    root: {
      selectors: {
        ':hover': {
          borderColor: theme.colors.Neutral,
        },
        ':disabled': {
          borderColor: theme.colors.Gray40,
        },
        ':active, >:active': {
          ...commonConrtrolStyles,
          borderColor: theme.colors.Gray40
        }
      },
      ...commonConrtrolStyles,
      minWidth: 0,
      height: 24,
      marginRight: 8,
      padding: 0,
      fontSize: 12,
    }
  };
}
