import noAnimation from './configs/animation/noAnimation';
import dataLabels from './configs/dataLabels/dataLabels';
import area from './configs/commonCharts/area/area';
import bar from './configs/commonCharts/bar/bar';
import donut, { donutTitle, donutKiloTitles } from './configs/commonCharts/donut/donut';
import horizontalBar from './configs/commonCharts/horizontalBar/horizontalBar';
import dynamicLine from './configs/commonCharts/line/dynamicLine';
import line from './configs/commonCharts/line/line';
import splitBar from './configs/commonCharts/splitBar/splitBar';
import accessibilityConfig from './configs/accessibility/ariaLabelFormatter';
import click from './configs/events/click';
import gradient from './configs/gradient/gradient';
import legendVertical from './configs/legend/legendVertical';
import noLegend from './configs/legend/noLegend';
import noMargin from './configs/margin/noMargin';
import noMarker from './configs/marker/noMarker';
import noStickyTracking from './configs/mouse-tracking/noStickyTracking';
import crossedLines from './configs/plotLines/crossedLines';
import noPlotLines from './configs/plotLines/noPlotLines';
import scroll from './configs/scroll/scroll';
import dynamicSeriesMapper from './configs/series/dynamicSeriesMapper';
import seriesMapper from './configs/series/seriesMapper';
import firstAndLastDateTicks from './configs/ticks/firstAndLastDateTicks';
import centerSubtitle from './configs/title/centerSubtitle';
import centerTitle from './configs/title/centerTitle';
import noTooltip from './configs/tooltip/noTooltip';
import sharedTooltip from './configs/tooltip/sharedTooltip';
import tooltip from './configs/tooltip/tooltip';
import trend from './configs/trend/trend';
import noXAxis from './configs/xAxis/noXAxis';
import noXAxisTickBorders from './configs/xAxis/noXAxisTickBorders';
import xAxisCategoriesMap from './configs/xAxis/xAxisCategoriesMap';
import xAxisCategory from './configs/xAxis/xAxisCategory';
import xAxisCrosshair from './configs/xAxis/xAxisCrosshair';
import xAxisGridLine from './configs/xAxis/xAxisGridLine';
import xAxisLabels from './configs/xAxis/xAxisLabels';
import xAxisMinMax from './configs/xAxis/xAxisMinMax';
import noYAxis from './configs/yAxis/noYAxis';
import noYAxisLine from './configs/yAxis/noYAxisLine';
import noYAxisText from './configs/yAxis/noYAxisText';
import noYAxisTickBorders from './configs/yAxis/noYAxisTickBorders';
import yAxisAvgLine from './configs/yAxis/yAxisPlotLines';
import yAxisGridLine from './configs/yAxis/yAxisGridLine';
import yAxisLabels from './configs/yAxis/yAxisLabels';
import yAxisMinMax from './configs/yAxis/yAxisMinMax';
import yAxisTitle from './configs/yAxis/yAxisTitle';
import bothAxisZoom from './configs/zoom/bothAxisZoom';
import noZoom from './configs/zoom/noZoom';
import defaultConfig from './configs/defaultConfig';
import combineConfigs from './configs/combineConfigs';
import * as StyledChart from './StyledChart';

export { StyledChart };
export * from './Chart';

export const chartConfigs = {
  noAnimation,
  dataLabels,
  area,
  bar,
  donut,
  donutTitle,
  donutKiloTitles,
  horizontalBar,
  dynamicLine,
  line,
  splitBar,
  accessibilityConfig,
  click,
  gradient,
  legendVertical,
  noLegend,
  noMargin,
  noMarker,
  noStickyTracking,
  crossedLines,
  noPlotLines,
  scroll,
  dynamicSeriesMapper,
  seriesMapper,
  firstAndLastDateTicks,
  centerSubtitle,
  centerTitle,
  noTooltip,
  sharedTooltip,
  tooltip,
  trend,
  noXAxis,
  noXAxisTickBorders,
  xAxisCategoriesMap,
  xAxisCategory,
  xAxisCrosshair,
  xAxisGridLine,
  xAxisLabels,
  xAxisMinMax,
  noYAxis,
  noYAxisLine,
  noYAxisText,
  noYAxisTickBorders,
  yAxisAvgLine,
  yAxisGridLine,
  yAxisLabels,
  yAxisMinMax,
  yAxisTitle,
  bothAxisZoom,
  noZoom,
  defaultConfig,
  combineConfigs,
};
