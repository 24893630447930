import React from 'react';
import snakeCase from 'lodash/snakeCase';
import { ExportToCSVButton } from 'ci-common-ui';
import { StringParam, useQueryParams } from 'use-query-params';
import { joinTrackDelimiter } from '../../utils/string/string';
import { TRACK_IDS } from '../../constants/tracking';
import { eventAction, trackError, trackEvent } from '../../services/telemetry-service/telemetry-service';
import useLocale from '../../hooks/use-locale';

export const ExportToCSV = ({ data, trackId }) => {
  const { t } = useLocale('common');
  const [{ startDate, endDate }] = useQueryParams({ startDate: StringParam, endDate: StringParam });
  const actionOn = joinTrackDelimiter([TRACK_IDS.COMMON.EXPORT_TO_CSV, trackId]);

  const trackExportCSV = () => trackEvent({ action: eventAction.click, actionOn });
  const trackErrorExportCSV = () => trackError({ action: eventAction.click, actionOn });

  return (
    <ExportToCSVButton
      data={data}
      fileName={`${snakeCase(trackId)}_${startDate}_${endDate}`}
      tooltipText={t('export_to_csv')}
      onSuccess={trackExportCSV}
      onError={trackErrorExportCSV}
    />
  );
};
