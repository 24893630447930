import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { IconButton } from '@fluentui/react';
import { SROnly, breakPoints } from 'ci-common-ui';
import useLocale from 'hooks/use-locale';
import { trackEvent, eventAction } from '../../services/telemetry-service/telemetry-service';
import {
  CarouselContainer,
  CarouselControls,
  CarouselHeader,
  CarouselLabel,
} from './StyledMultiCarousel';
import { TRACK_IDS } from '../../constants/tracking';
import { joinTrackDelimiter } from '../../utils/string/string';

const CUSTOM_MIN_BREAK_POINT = 480;
const CUSTOM_MAX_BREAK_POINT = 3000;

const responsive = {
  small: {
    breakpoint: { max: CUSTOM_MIN_BREAK_POINT, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
  medium: {
    breakpoint: { max: breakPoints.mobile, min: CUSTOM_MIN_BREAK_POINT },
    items: 2,
    slidesToSlide: 2,
  },
  large: {
    breakpoint: { max: breakPoints.tablet, min: breakPoints.mobile },
    items: 3,
    slidesToSlide: 3,
  },
  xLarge: {
    breakpoint: { max: CUSTOM_MAX_BREAK_POINT, min: breakPoints.tablet },
    items: 4,
    slidesToSlide: 4,
  },
};

const MultiCarousel = (props) => {
  const { isRTL, t, tAbs } = useLocale('components.carousel');

  const getNextRangeStart = (carouselState) => carouselState.currentSlide + carouselState.slidesToShow;
  const getAriaLabelForDisabledButton = (buttonLabel) => [buttonLabel, tAbs('common.disabled')].join(', ');
  const getCarouselVisibleInterval = (carouselState) => (carouselState.currentSlide === null
    ? ''
    : t('interval', {
      start: carouselState.currentSlide + 1,
      end: Math.min(carouselState.totalItems, getNextRangeStart(carouselState)),
    }));

  function getCarouselLabel(carouselState) {
    const { currentSlide, slidesToShow, totalItems } = carouselState;
    if (totalItems && slidesToShow && totalItems > slidesToShow) {
      return t('label', {
        start: slidesToShow === 1 ? currentSlide + 1 : getCarouselVisibleInterval(carouselState),
        end: totalItems,
      });
    }
    return '';
  }

  function ButtonGroup({ next, previous, carouselState }) {
    const { currentSlide, totalItems } = carouselState;
    const prevAriaLabel = t('scroll_previous');
    const nextAriaLabel = t('scroll_next');
    const isPrevDisabled = currentSlide === 0;
    const isNextDisabled = getNextRangeStart(carouselState) >= totalItems;
    const carouselLabel = getCarouselLabel(carouselState);

    return (
      <CarouselHeader>
        <CarouselControls hide={isPrevDisabled && isNextDisabled} aria-hidden={isPrevDisabled && isNextDisabled}>
          <IconButton
            ariaLabel={
              isPrevDisabled ? getAriaLabelForDisabledButton(prevAriaLabel) : prevAriaLabel
            }
            disabled={isPrevDisabled}
            onClick={() => {
              trackEvent({ action: eventAction.click, actionOn: joinTrackDelimiter([props.trackId, TRACK_IDS.COMMON.CAROUSEL_PREV]) });
              previous();
            }}
            onMouseDown={(e) => e.preventDefault()}
            iconProps={{ iconName: isRTL ? 'ChevronRight' : 'ChevronLeft' }}
          />
          <CarouselLabel>{carouselLabel}</CarouselLabel>
          <IconButton
            ariaLabel={
              isNextDisabled ? getAriaLabelForDisabledButton(nextAriaLabel) : nextAriaLabel
            }
            disabled={isNextDisabled}
            onClick={() => {
              trackEvent({ action: eventAction.click, actionOn: joinTrackDelimiter([props.trackId, TRACK_IDS.COMMON.CAROUSEL_NEXT]) });
              next();
            }}
            onMouseDown={(e) => e.preventDefault()}
            iconProps={{ iconName: isRTL ? 'ChevronLeft' : 'ChevronRight' }}
          />
        </CarouselControls>
        <SROnly aria-live="polite">{tAbs('common.pages') + carouselLabel}</SROnly>
      </CarouselHeader>
    );
  }

  return (
    // eslint-disable-next-line react/destructuring-assignment
    <CarouselContainer role="region" aria-label={props.containerAriaLabel || 'List of items'}>
      <Carousel
        containerClass="carousel-container"
        responsive={responsive}
        keyBoardControl={false}
        arrows={false}
        draggable={false}
        renderButtonGroupOutside
        customButtonGroup={<ButtonGroup />}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
      />
    </CarouselContainer>
  );
};
export default MultiCarousel;
