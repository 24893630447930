import React, { useLayoutEffect, useRef, useState } from 'react';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import { Icon } from '@fluentui/react';
import { isNil } from 'lodash';
import { Pill, PILL_TYPES, FocusableContainer, Tooltip } from 'ci-common-ui';
import { PillsContainer, StyledPillsCell, showMoreIconStyles } from './StyledPillsCell';
import useLocale from '../../../../hooks/use-locale';

const PillsCell = ({ data }) => (
  <StyledPillsCell>
    <Pills data={data} />
  </StyledPillsCell>
);

export const Pills = ({ data }) => {
  const containerRef = useRef();
  const pillsRef = useRef([]);
  const [cutOffIndex, setCutOffIndex] = useState(null);
  const { tAbsDefault } = useLocale();

  useLayoutEffect(() => {
    const pillsDimensions = map(pillsRef.current, (elem) => elem && elem.getBoundingClientRect().width).filter((elem) => !isNil(elem));

    let calculatedCutOffIndex = 0;
    let accumulatedWidth = 0;
    forEach(pillsDimensions, (pillDimension) => {
      accumulatedWidth += pillDimension + 8;
      if (accumulatedWidth < containerRef.current.getBoundingClientRect().width + 10) {
        calculatedCutOffIndex++;
      }
    });
    if (cutOffIndex === null) {
      setCutOffIndex(calculatedCutOffIndex);
    }
  }, [pillsRef.current, containerRef.current]);

  if (!data) return null;

  const onRenderContent = () => <>{data.map((word) => <div key={word}>{word}</div>)}</>;
  const isEllipsis = cutOffIndex !== null && cutOffIndex !== data.length;
  const accessibleText = `${tAbsDefault('common.tags')}: ${data} ${tAbsDefault('common.readonly')}`;
  const isPillVisible = (index) => cutOffIndex === null || index < cutOffIndex;

  return (
    <Tooltip tooltipProps={isEllipsis && { onRenderContent }}>
      <PillsContainer ref={containerRef} aria-label={accessibleText}>
        <FocusableContainer>
          {data.map((pill, i) => isPillVisible(i) && (
            <Pill
              type={PILL_TYPES.TAG}
              key={pill}
              ref={(el) => { pillsRef.current[i] = el; }}
            >
              {pill}
            </Pill>
          ))}
          {isEllipsis && (
          <Icon
            iconName="More"
            styles={showMoreIconStyles}
          />
          )}
        </FocusableContainer>
      </PillsContainer>
    </Tooltip>
  );
};

export default PillsCell;
