import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { QueryParamProvider } from 'use-query-params';
import { BrowserRouter, Route } from 'react-router-dom';
import { AppSpinner, telemetryService, initializeIcons } from 'ci-common-ui';
import { AppStateProvider, useAppState } from 'providers/AppStateProvider';
import isEmpty from 'lodash/isEmpty';
import { getIsAuthenticated } from './constants/config';
import App from './App';
import { getIsCustomerServiceMode } from './utils/app-context/app-context';
import PageErrorBoundary from './components/ErrorBoundry/PageErrorBoundary';
import { initWithAuth } from './services/auth-service';
import { getSearchParam } from './utils/url/url';
import { sendEvents } from './network/data-services/app-service';
import { commonEventDetails, getPage } from './services/telemetry-service/utils';
import { setTelemetrySource } from './services/telemetry-service/telemetry-service';
import { initServerApi } from './network/server-api-utils';
import UserConfirmation from './components/UserConfirmation/UserConfirmation';
import { preventSessionClosing } from './utils';

const { loginHint } = getSearchParam();
initializeIcons();
initServerApi();
setTelemetrySource(getPage(window.location));
telemetryService.initTelemetry({ onSendEvents: sendEvents, commonEventDetails });

const RouterApp = () => {
  const { appState, setAppState } = useAppState();
  const { leavingPagePromptMessage } = appState;

  useEffect(() => {
    preventSessionClosing(!isEmpty(leavingPagePromptMessage));
  }, [leavingPagePromptMessage]);

  return (
    <BrowserRouter getUserConfirmation={(message, shouldBlockNavigation) => UserConfirmation(message, shouldBlockNavigation, setAppState)}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <App />
      </QueryParamProvider>
    </BrowserRouter>
  );
};

const app = (
  <AppStateProvider>
    <PageErrorBoundary>
      <Suspense fallback={<AppSpinner />}>
        <RouterApp />
      </Suspense>
    </PageErrorBoundary>
  </AppStateProvider>
);

if (!getIsAuthenticated() && !getIsCustomerServiceMode()) {
  ReactDOM.render(app, document.getElementById('app'));
} else {
  initWithAuth(() => {
    ReactDOM.render(app, document.getElementById('app'));
  }, loginHint);
}
