export const SHELL_URL = 'https://shellprod.msocdn.com/api/shellbootstrapper/business/oneshell';

export const QUERY_PARAMS = {
  PAGE_NUMBER: 'pageNumber',
  SCROLL_TO: 'scrollTo',
  SETTINGS: 'settings',
  QUERY: 'query',
};

export const constantQueryParamsObj = {
  embed: 'embed',
  customerService: 'customerService',
  locale: 'locale',
  startDate: 'startDate',
  endDate: 'endDate'
};

export const constantQueryParamsArray = [
  constantQueryParamsObj.embed,
  constantQueryParamsObj.customerService,
  constantQueryParamsObj.locale,
  constantQueryParamsObj.startDate,
  constantQueryParamsObj.endDate
];
