import styled from 'styled-components';
import { colors } from 'ci-common-ui';
import { FontSizes } from '@uifabric/fluent-theme';

export const Container = styled.div`
  flex: 1;
  padding: 12px 24px;
  background-color: ${colors.Gray10};
  overflow: hidden;
`;

export const Title = styled.div`
  font-size: ${FontSizes.size16};
  font-weight: 600;
`;
