import { colors } from '../../../../constants/colors';

export default ({ type, dataLabelsFormatter, textOutline }) => ({
  plotOptions: {
    [type]: {
      dataLabels: {
        enabled: true,
        style: {
          textShadow: textOutline && `1px 1px 0 ${colors.White}, -1px -1px 0 ${colors.White}, 1px -1px 0 ${colors.White}, -1px 1px 0 ${colors.White}, 1px 1px 0 ${colors.White}`
        },
        ...(dataLabelsFormatter
          ? {
            formatter() {
              return dataLabelsFormatter(this);
            },
          }
          : {}),
      },
    },
  },
});
