import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';

const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const CONN_STRING_PATTERN = /^(?=.*\bDefaultEndpointsProtocol=https\b)(?=.*\bAccountKey=\b)(?=.*\bEndpointSuffix=\b)(?=.*\bAccountName=\b).*$/;

const isStringValid = (string, pattern, errorMessageName) => pattern.test(string) ? null : errorMessageName;

export const composeValidators = (...validators) => (value) => (
  filter(validators).reduce((error, validator) => error || validator(value), undefined)
);

export const requiredValidate = (value) => (value ? null : 'required');

export const requiredArrayValidate = (arrValue) => (isEmpty(arrValue) ? 'required' : null);

export const isValidEmail = (email) => isStringValid(email, EMAIL_PATTERN, 'invalidEmail');

export const isConnectionStringValid = (connectionString) => isStringValid(connectionString, CONN_STRING_PATTERN, 'invalidConnectionString');
