import { addEmptyDataToStartAndEnd } from '../../../../../utils/chart';
import combineConfigs from '../../combineConfigs';
import tooltip from '../../tooltip/tooltip';
import seriesMapper from '../../series/seriesMapper';
import noLegend from '../../legend/noLegend';
import xAxisCategoriesMap from '../../xAxis/xAxisCategoriesMap';
import dataLabels from '../../dataLabels/dataLabels';
import xAxisLabels from '../../xAxis/xAxisLabels';
import yAxisLabels from '../../yAxis/yAxisLabels';
import yAxisPlotLines from '../../yAxis/yAxisPlotLines';

const type = 'column';

export default ({
  data,
  seriesParams,
  categoryField,
  plotLines,
  xAxisFormatter,
  yAxisFormatter,
  dataLabelsFormatter,
  textOutline
}) => combineConfigs(
  {
    type,
    data: addEmptyDataToStartAndEnd(data, categoryField),
    seriesParams,
    xAxisFormatter,
    yAxisFormatter,
    dataLabelsFormatter,
    plotLines,
    categoryField,
    textOutline,
    tooltipOptions: {
      getProps: ({ key: name, y }) => ({ name, value: dataLabelsFormatter({ y }) }),
    },
  },
  [
    xAxisLabels,
    yAxisLabels,
    yAxisPlotLines,
    xAxisCategoriesMap,
    noLegend,
    dataLabels,
    tooltip,
    seriesMapper,
    { type },
  ],
);
