import styled from 'styled-components';
import { rtl, colors } from 'ci-common-ui';

export const StepperRoot = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  flex: 1;
  max-width: 350px;
  ${rtl`border-right`}: 1px solid ${colors.Gray300};
  overflow-y: auto;
  `;

export const StepperItem = styled.div`
  min-height: 70px;
  display: flex;
  
`;

export const CircleItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${rtl`margin-right`}: 10px;
`;

export const Circle = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 2px solid ${colors.Gray30};

  ${({ selected }) => selected && `
    background: ${colors.DYNPrimary};
    border-color: ${colors.DYNPrimary}; 
  `}
`;

export const Line = styled.div`
  width: 2px;
  margin: 8px 0;
  flex: 1;
  background: ${colors.Gray30};

  ${({ selected }) => selected && `
    background: ${colors.DYNPrimary};
`}
`;

export const Text = styled.div`
  ${({ selected }) => `color: ${selected ? colors.Gray160 : colors.Gray90};`}
`;

export const DisplayValues = styled.div`
  color: ${colors.DYNPrimary};
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 10px;
`;
