/* eslint no-param-reassign: 0 */
import { dataViewClick, dataViewContent, pageScrollerSelector } from 'constants/selectors';

// for elements that are scrolled-to from the query param
export const getScrollViewSelectors = (viewContentName, viewClickName) => ({
  contentSelector: `[${dataViewContent}="${viewContentName}"]`,
  clickSelector: viewClickName ? `[${dataViewClick}="${viewClickName}"]` : undefined
});

export function scrollToPageElement(el, scrollerSelector = pageScrollerSelector) {
  const scrollerEl = document.querySelector(scrollerSelector);
  if (scrollerEl && el) {
    const elRect = el.getBoundingClientRect();
    scrollerEl.scrollTop = elRect.top + (elRect.height / 2) - (scrollerEl.getBoundingClientRect().height / 2);
  }
}

export function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function setMouseActiveOnBody() {
  const className = 'mousedown-enabled';
  document.addEventListener('mousedown', () => {
    if (!document.body.classList.contains(className)) {
      document.body.classList.add(className);
    }
  });
  document.addEventListener('keydown', () => {
    if (document.body.classList.contains(className)) {
      document.body.classList.remove(className);
    }
  });
}

export function highlightTemporarily(el, duration = 2500, transition = 1000) {
  if (el) {
    el.style.transition = `box-shadow ${transition}ms ease-out`;
    setTimeout(() => {
      el.style.boxShadow = '0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22)';
    }, 100);
    setTimeout(() => {
      el.style.boxShadow = null;
    }, duration);
    setTimeout(() => {
      el.style.transition = null;
    }, duration + transition);
  }
}
