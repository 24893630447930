import styled, { css } from 'styled-components';
import { rtl, colors, FlexRowCentered, StyledLink, StyledText, deviceSizes } from 'ci-common-ui';

export const navButtonStyle = {
  color: colors.DYNTint10,
};

const NAV_BAR_FULL_WIDTH = '220px';
const NAV_BAR_CONTRACTED_WIDTH = '50px';
const SHELL_MICROSOFT_APPS_BUTTON_Z_INDEX = 556; // from main.css

const border = `1px solid ${colors.Gray30}`;

export const StyledNavBar = styled.nav.attrs({
  'aria-label': 'Navigation side bar',
  role: 'navigation',
})`
  background: #f1f1f1;
  z-index: 1;
  position: relative;
  ${rtl`
    left: 0px
    border-right: ${border}
  `};
  flex-shrink: 0;
  width: ${(props) => (props.isExpanded ? NAV_BAR_FULL_WIDTH : NAV_BAR_CONTRACTED_WIDTH)};
  height: 100%;
  
  transition: width 0.2s ease-in;

  ${deviceSizes.mobile`
    transition: none;
    ${({ isExpanded }) => isExpanded && 'position: fixed;'}
  `}
`;

const SELECTOR_OFFSET = 7;
const SELECTOR_HEIGHT = 32;
const PAGE_ROW_HEIGHT = 46;

export const Selector = styled.div.attrs({
  role: 'listitem',
})`
  position: absolute;
  width: 4px;
  height: ${SELECTOR_HEIGHT}px;
  ${rtl`left: 4px`};
  top: ${({ tabIdx }) => `${SELECTOR_OFFSET + tabIdx * PAGE_ROW_HEIGHT}px`};
  border-radius: 2px;
  background: ${colors.DYNTint10};
  ${rtl`margin-right: 8px`};
  transition: top 200ms ease;
  z-index: 99999;
`;

export const StyledIcon = styled.div`
  font-size: 16px;
`;

export const LinkText = styled(StyledText)`
  ${rtl`margin-left: 8px`};
  text-transform: capitalize;
`;

export const TabsContainer = styled.div.attrs({
  role: 'list',
})`
  position: relative;
`;

export const NavIconContainer = styled.button`
  z-index: ${SHELL_MICROSOFT_APPS_BUTTON_Z_INDEX - 1};
  position: fixed;
  top: 0;
  ${rtl`left: 2px`};
  cursor: pointer;
`;

export const RowContainer = styled(FlexRowCentered).attrs({
  as: StyledLink
})`
  position: relative;
  padding: 12px 16px;
  cursor: pointer;
  transition: all 150ms ease;
  color: ${colors.Gray160};
  
  :hover {
    background-color: ${colors.Gray30};
  }
  :active {
    background-color: ${colors.Gray40};
  }

  ${deviceSizes.mobile`
    :hover {
      background: none;
    }
    :active {
      background: none;
    }
  `}
  
`;

export const ButtonRowContainer = styled(FlexRowCentered)`
  overflow: hidden;
`;

export const TabButtonRow = styled(RowContainer)`
  width: 100%;
  ${({ selected }) => selected && css`
    font-weight: bold;
    background-color: ${colors.White};
  `};
  :hover {
    text-decoration: none;
    color: initial;
  }
`;

export const PageOverlay = styled.div`
  position: fixed;
  ${rtl`
    right: 0;
    left: ${NAV_BAR_FULL_WIDTH};
  `};
  top: 50px;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
`;
