import React from 'react';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import isEmpty from 'lodash/isEmpty';
import useLocale from 'hooks/use-locale';
import { useAppNavigation } from 'hooks/use-app-navigation';
import { routes } from 'pages/routing';
import useEmptyState from 'utils/empty-state/use-empty-state';
import { colors, hexToRGBA, Trans } from 'ci-common-ui';
import ShowMore from '../../../../components/ShowMore/ShowMore';
import PageSection from '../../../components/PageSection/PageSection';
import InsightCard from '../InsightCard/InsightCard';
import { scrollViewNames } from '../../../../constants/selectors';
import {
  createCustomerEngagementChartConfig,
  createNegativeCustomerSentimentChartConfig,
  createSwitchRateChartConfig,
  createTalkToListenRatioChartConfig,
} from './chart-configs';
import CardErrorBoundary from '../../../../components/ErrorBoundry/CardErrorBoundry';
import { StyledInsightCard } from '../InsightCard/StyledInsightCard';
import { InsightCardSectionLoaders } from '../../../components/PageSection/SectionLoaders';
import { TRACK_IDS } from '../../../../constants/tracking';
import { joinTrackDelimiter } from '../../../../utils/string/string';
import { createTopTrendingChartConfig } from '../chart-configs';
import DelimitedInlineList from '../../../../components/DelimitedInlineList/DelimitedInlineList';
import { QUERY_PARAMS } from '../../../../constants/urls';
import { settingsModalTabs } from "../../../../constants/modals";

const emptyStateReasonType = {
  notEnoughData: "not_enough_data",
  noTopPerformers: "no_top_performers"
}

const localEmptyState = ({
  bestPracticeKeywords,
  negativeCustomerSentiment,
  talkToListenRatio,
  switchRate,
  customerEngagement,
}, getEmptyStateDetails, navigateTo, emptyStateReason) => {
  const navigateToConfigureTopPerformers = () => navigateTo({ params: { [QUERY_PARAMS.SETTINGS]: settingsModalTabs.yourteam.link } });

  const talkToListenRatioEmptyState = isEmpty(talkToListenRatio) && getEmptyStateDetails('insights.talk_to_listen_ratio', emptyStateReason, navigateToConfigureTopPerformers);
  const bestPracticeKeywordsEmptyState = isEmpty(bestPracticeKeywords.topData) && getEmptyStateDetails('insights.best_practice_keywords', emptyStateReason, navigateToConfigureTopPerformers);
  const isTextEmptyStateBestPracticeKeywords = isEmpty(bestPracticeKeywords.topDataDiff);
  const negativeCustomerSentimentEmptyState = isEmpty(negativeCustomerSentiment) && getEmptyStateDetails('insights.negative_customer_sentiment', emptyStateReason, navigateToConfigureTopPerformers);
  const switchRateEmptyState = isEmpty(switchRate) && getEmptyStateDetails('insights.switch_rate', emptyStateReason, navigateToConfigureTopPerformers);
  const customerEngagementEmptyState = isEmpty(customerEngagement) && getEmptyStateDetails('insights.customer_engagement', emptyStateReason, navigateToConfigureTopPerformers);

  return {
    talkToListenRatioEmptyState,
    bestPracticeKeywordsEmptyState,
    isTextEmptyStateBestPracticeKeywords,
    negativeCustomerSentimentEmptyState,
    switchRateEmptyState,
    customerEngagementEmptyState
  };
};

const SELLERS_LOCALIZATION_PREFIX = 'pages.home.sections.sellers';

const SellerInsights = ({ data, isLoading, error }) => {
  const { t, tAbs, appModeAwareTerms } = useLocale(SELLERS_LOCALIZATION_PREFIX);
  const { navigateTo } = useAppNavigation();
  const { getEmptyStateDetails } = useEmptyState(SELLERS_LOCALIZATION_PREFIX);
  const sectionTitle = t('title');
  const sectionSubtitle = t('subtitle');
  const emptyStateReason = isEmpty(data?.topPerformers) ? emptyStateReasonType.noTopPerformers : emptyStateReasonType.notEnoughData;

  if (isLoading) return <InsightCardSectionLoaders title={sectionTitle} subtitle={sectionSubtitle} count={3} />;

  const getCustomerEngagementTitle = () => {
    const [
      topEngagement,
      lowerEngagement1,
      lowerEngagement2
    ] = reverse(sortBy(customerEngagement, 'value'));
    return t('insights.customer_engagement.title', {
      topEngagement: get(topEngagement, 'name'),
      lowerEngagement1: get(lowerEngagement1, 'name'),
      lowerEngagement2: get(lowerEngagement2, 'name')
    });
  };

  const {
    talkToListenRatio,
    bestPracticeKeywords,
    negativeCustomerSentiment,
    switchRate,
    customerEngagement,
  } = data;

  const {
    talkToListenRatioEmptyState,
    bestPracticeKeywordsEmptyState,
    isTextEmptyStateBestPracticeKeywords,
    negativeCustomerSentimentEmptyState,
    switchRateEmptyState,
    customerEngagementEmptyState
  } = localEmptyState(data, getEmptyStateDetails, navigateTo, emptyStateReason);

  const isSameAvgTalkToListen = () => (!isEmpty(talkToListenRatio) && get(talkToListenRatio, 'restOfTeamListening') === get(talkToListenRatio, 'topSellerListening')
    && get(talkToListenRatio, 'restOfTeamTalking') === get(talkToListenRatio, 'topSellerTalking'));

  const getTalkToListenTitle = () => t(`insights.talk_to_listen_ratio.${isSameAvgTalkToListen() ? 'title_same_avg' : 'title'}`, {
    topSellerRatio: `${get(talkToListenRatio, 'topSellerTalking')}:${get(talkToListenRatio, 'topSellerListening')}`,
    direction: tAbs(`common.${get(talkToListenRatio, 'topSellerTalking') > get(talkToListenRatio, 'restOfTeamTalking') ? 'higher' : 'lower'}`),
    restOfTeamRatio: `${get(talkToListenRatio, 'restOfTeamTalking')}:${get(talkToListenRatio, 'restOfTeamListening')}`,
  });

  const cards = [{
    trackId: joinTrackDelimiter([TRACK_IDS.COMMON.SELLER_INSIGHTS, TRACK_IDS.COMMON.TALK_TO_LISTEN_RATIO]),
    csvData: [talkToListenRatio],
    cardName: t('insights.talk_to_listen_ratio.card_name'),
    title: getTalkToListenTitle(t, talkToListenRatio, tAbs),
    description: t('insights.talk_to_listen_ratio.subtitle'),
    chartConfig: () => createTalkToListenRatioChartConfig(talkToListenRatio, tAbs),
    onShowDetails: () => navigateTo({ path: routes.team.link, params: { [QUERY_PARAMS.SCROLL_TO]: scrollViewNames.talkToListenRatio } }),
    emptyStateDetails: talkToListenRatioEmptyState,
    hasError: get(error, 'talkToListenRatio'),
  }, {
    trackId: joinTrackDelimiter([TRACK_IDS.COMMON.SELLER_INSIGHTS, TRACK_IDS.BEST_PRACTICE_KEYWORDS]),
    csvData: get(bestPracticeKeywords, 'topData'),
    cardName: t('insights.best_practice_keywords.card_name'),
    title: (!isTextEmptyStateBestPracticeKeywords
      && (
      <Trans i18nKey={`${SELLERS_LOCALIZATION_PREFIX}.insights.best_practice_keywords.title`}>
        {{ _representative_plural: appModeAwareTerms._representative_plural }}
        <DelimitedInlineList boldItems data={get(data, 'bestPracticeKeywords.topDataDiff')} />
        {{ _representative_plural: appModeAwareTerms._representative_plural }}
      </Trans>
      )
    ),
    description: t(`insights.best_practice_keywords.${isTextEmptyStateBestPracticeKeywords ? 'only_text_empty_state' : 'subtitle'}`),
    chartConfig: () => createTopTrendingChartConfig(
      get(bestPracticeKeywords, 'topData'), hexToRGBA(colors.DYNPrimary, 0.7),
    ),
    onShowDetails: !isTextEmptyStateBestPracticeKeywords && (() => navigateTo({ path: routes.topSellersInsight.link })),
    emptyStateDetails: bestPracticeKeywordsEmptyState,
    hasError: get(error, 'bestPracticeKeywords'),
  }, {
    trackId: joinTrackDelimiter([TRACK_IDS.COMMON.SELLER_INSIGHTS, TRACK_IDS.COMMON.CUSTOMER_SENTIMENT]),
    csvData: [{ name: 'restOfTeam', ...get(negativeCustomerSentiment, 'restOfTeam', {}) }, { name: 'topSeller', ...get(negativeCustomerSentiment, 'topSeller', {}) }],
    cardName: t('insights.negative_customer_sentiment.card_name'),
    title: t('insights.negative_customer_sentiment.title',
      { direction: tAbs(`common.${get(negativeCustomerSentiment, 'topSeller.negative') > get(negativeCustomerSentiment, 'restOfTeam.negative') ? 'more' : 'less'}`) }),
    description: t('insights.negative_customer_sentiment.subtitle'),
    chartConfig: () => createNegativeCustomerSentimentChartConfig(negativeCustomerSentiment, tAbs, t),
    onShowDetails: () => navigateTo({ path: routes.team.link, params: { [QUERY_PARAMS.SCROLL_TO]: scrollViewNames.customerSentimentBySeller } }),
    emptyStateDetails: negativeCustomerSentimentEmptyState,
    hasError: get(error, 'negativeCustomerSentiment'),
  }, {
    trackId: joinTrackDelimiter([TRACK_IDS.COMMON.SELLER_INSIGHTS, TRACK_IDS.COMMON.SWITCH_RATE]),
    csvData: [switchRate],
    cardName: t('insights.switch_rate.card_name'),
    title: t('insights.switch_rate.title', {
      topSellerSwitchRate: get(switchRate, 'topSeller'),
      direction: tAbs(`common.${get(switchRate, 'topSeller') > get(switchRate, 'restOfTeam') ? 'higher' : 'lower'}`),
      restOfTeamSwitchRate: get(switchRate, 'restOfTeam')
    }),
    description: t('insights.switch_rate.subtitle'),
    chartConfig: () => createSwitchRateChartConfig(switchRate, tAbs, t),
    onShowDetails: () => navigateTo({ path: routes.team.link, params: { [QUERY_PARAMS.SCROLL_TO]: scrollViewNames.switchRate } }),
    emptyStateDetails: switchRateEmptyState,
    hasError: get(error, 'switchRate'),
  }, {
    trackId: joinTrackDelimiter([TRACK_IDS.COMMON.SELLER_INSIGHTS, TRACK_IDS.CUSTOMER_ENGAGEMENT]),
    csvData: customerEngagement,
    cardName: t('insights.customer_engagement.card_name'),
    title: getCustomerEngagementTitle(),
    description: t('insights.customer_engagement.subtitle'),
    chartConfig: () => createCustomerEngagementChartConfig(customerEngagement, tAbs, t),
    emptyStateDetails: customerEngagementEmptyState,
    hasError: get(error, 'customerEngagement'),
  }];

  return (
    <PageSection title={sectionTitle} subtitle={sectionSubtitle} isLoading={isLoading}>
      <ShowMore trackId={TRACK_IDS.COMMON.SELLER_INSIGHTS}>
        {
          cards.map(({
            trackId,
            csvData,
            title,
            description,
            chartConfig,
            emptyStateDetails,
            onShowDetails,
            cardName,
            hasError,
          }) => (
            <StyledInsightCard key={cardName} aria-label={`${sectionTitle} - ${cardName}`} role="region">
              <CardErrorBoundary cardName={cardName}>
                <InsightCard
                  trackId={trackId}
                  csvData={csvData}
                  cardName={cardName}
                  title={title}
                  emptyStateDetails={emptyStateDetails}
                  description={description}
                  getChartConfig={chartConfig}
                  onShowDetails={onShowDetails}
                  isErrorState={hasError}
                />
              </CardErrorBoundary>
            </StyledInsightCard>
          ))
        }
      </ShowMore>
    </PageSection>
  );
};
export default SellerInsights;
