import styled from 'styled-components';
import { PersonaSize } from '@fluentui/react';
import { FlexRowCentered, FlexRow, StyledText, FlexColumn } from '../../../utils/styles/StyledUtils';
import { rtl } from '../../../utils/styles/rtl';
import { ParticipantPersona } from '../../../pages/CallPage/StyledCallPage';

export const Avatar = styled(ParticipantPersona).attrs({
  size: PersonaSize.size56
})`
  ${rtl`margin-right`}: 16px;
`;

export const CallSummaryDetails = styled(FlexRowCentered)`
`;

export const ContactName = styled(StyledText)`
  font-size: 16px;
  color: ${({ isUnknown, theme }) => isUnknown ? theme.colors.Red : theme.colors.Gray160};
`;

export const Phone = styled(StyledText)`
  color: ${({ theme }) => theme.colors.Gray160};
  margin-top: 2px;
`;

export const TimeDuration = styled(FlexRow)`
`;

export const ContactContainer = styled(FlexRowCentered)`
`;

export const OnlyAgent = styled(FlexRowCentered)`
  align-self: flex-start;
  height: 18px;
  padding: 0 8px;
  margin-top: 4px;
  background-color: ${({ theme }) => theme.colors.Gray20};
  border-radius: 20px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.Gray130};
`;

export const StyledFlexColumn = styled(FlexColumn)`
  overflow: hidden;
`;

export const editButtonStyles = ({ theme }) => ({
  root: {
    marginLeft: '8px',
    color: theme.palette.neutralPrimary
  }
});

export const Date = styled(StyledText)`
  font-size: 12px;
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.Gray130};
`;

export const Time = styled(Date)`
  direction: ltr;
`;

export const Separator = styled(Time)`
  margin-left: 5px;
  margin-right: 5px;
`;

export const StyledTooltip = {
  root: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
};
