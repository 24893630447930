import React from 'react';
import useLocale from 'hooks/use-locale';
import { Action, SellerActionsContainer } from './StyledSellerBarActions';

const SellerActions = ({ onEmailClicked, onChatClicked }) => {
  const { t, isRTL } = useLocale('components.seller_bar.actions');
  return (
    <SellerActionsContainer dir={isRTL ? 'rtl' : 'ltr'}>
      <Action iconName="Mail" onClick={onEmailClicked} ariaLabel={t('email')}>{t('email')}</Action>
      <Action iconName="Chat" onClick={onChatClicked} ariaLabel={t('chat')}>{t('chat')}</Action>
    </SellerActionsContainer>
  );
};

export default SellerActions;
