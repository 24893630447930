import React, { useRef } from 'react';
import useLocale from '../../../../../../../hooks/use-locale';
import { getMappingId } from '../utils';
import { TimeLimitDropDown } from './TimeLimitDropDown';

export const UntilCell = ({ column, row, data: text }) => {
  const { t } = useLocale('pages.settings.your_team.view_as');
  const timelimitRef = useRef(null);

  const { onUpdateExpiryDate } = column;
  return (
    <TimeLimitDropDown
      text={text || t('forever')}
      onUpdateExpiryDate={onUpdateExpiryDate}
      mappingId={getMappingId(row)}
      timeLimitRef={timelimitRef}
      hideIcon
      noMargin
    />
  );
};
