import { get } from 'lodash';
import combineConfigs from '../../combineConfigs';
import tooltip from '../../tooltip/tooltip';
import seriesMapper from '../../series/seriesMapper';
import xAxisCategoriesMap from '../../xAxis/xAxisCategoriesMap';
import xAxisLabels from '../../xAxis/xAxisLabels';
import yAxisLabels from '../../yAxis/yAxisLabels';
import noYAxisLine from '../../yAxis/noYAxisLine';

const type = 'bar';
const getCallSubject = (points) => get(points, '[0].point.subject');

export default ({
  data,
  seriesParams,
  categoryField,
  yTickAmount,
  xAxisFormatter,
  yAxisFormatter,
}) => combineConfigs(
  {
    type,
    data,
    seriesParams,
    xAxisFormatter,
    yAxisFormatter,
    categoryField,
    tooltipOptions: {
      getProps: ({ series: { name }, y: value }) => ({ name, value: yAxisFormatter({ value }) }),
      getTitle: ({ points }) => getCallSubject(points),
      withHeader: true,
      withBullet: true,
    },
  },
  [
    xAxisLabels,
    yAxisLabels,
    noYAxisLine,
    xAxisCategoriesMap,
    tooltip,
    seriesMapper,
    {
      type,
      yAxis: {
        tickAmount: yTickAmount,
        labels: {
          style: {
            fontSize: '14px',
          },
        },
      },
      xAxis: {
        labels: {
          style: {
            fontSize: '14px',
          },
        },
      },
      legend: {
        reversed: true,
      },
      plotOptions: {
        series: {
          stacking: 'percent',
        },
      },
    },
  ],
);
