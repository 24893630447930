import React from 'react';
import { noop, size } from 'lodash';
import { TopicKeyword } from './StyledKeywordsPanel';

export const KeywordWrapper = ({
    actualWord,
    instances,
    type,
    onKeywordClicked,
    onKeywordHover,
    t,
    selectedKeywordId,
    selectedSection,
    staticKeywords,
    vivaTopicsId,
    Keyword,
    isInteractiveMode,
  }) => {
    const isSelected = actualWord === selectedKeywordId && selectedSection === type;
    const numberOfInstances = size(instances);
    const shouldHideKeyword = numberOfInstances === 0;
    const fragmentId = shouldHideKeyword ? undefined : instances[0]?.fragmentId;
    const isTopic = !!vivaTopicsId;

    const onClick = (timestamp) => {
      onKeywordClicked({ type, keywordId: actualWord, fragmentId, isTopicKeyword: isTopic, timestamp });
    };

    const onMouseEnter = () => isSelected ? noop : onKeywordHover(type, actualWord, fragmentId, false, true, isTopic);
    const onMouseLeave = () => isSelected ? noop : onKeywordHover(type, actualWord, fragmentId, true, false);

    const keywordText = staticKeywords ? t(`keywords_panel_${type}_keywords_${actualWord}`) : actualWord;
    const keywordTextWithInstanceLength =
      numberOfInstances > 1 ? `${keywordText} (${numberOfInstances})` : keywordText;

    if (vivaTopicsId) {
      return (
        <TopicKeywordWrapper
          vivaTopicsId={vivaTopicsId}
          displayedText={keywordText}
          isSelected={isSelected}
          shouldHide={shouldHideKeyword}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          Keyword={Keyword}
        />
      );
    }

    return (
      <Keyword
        key={actualWord || 'none'}
        checked={isSelected}
        hidden={shouldHideKeyword}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        instances={instances}
        displayedText={keywordTextWithInstanceLength}
        tooltipBodyText={keywordText}
        isInteractiveMode={isInteractiveMode}
        keywordType={type}
      />
    );
  };

// LPC hover for topic keywords.
const TopicKeywordWrapper = ({
    vivaTopicsId,
    displayedText,
    isSelected,
    shouldHide,
    onClick,
    onMouseEnter,
    onMouseLeave,
    Keyword,
  }) => {
    // Topic keywords have a special LivePersonaCard on hover (instead the regular tooltip).
    const TopicsLpcReactComponent = window.LivePersonaCardHoverTargetV2;
    const cardParameters = {
      personaInfo: {
        // For an explanation around identifiers, see http://aka.ms/lpcidentifiers
        identifiers: {
          TopicId: vivaTopicsId,
          PersonaType: 'Topic'
        },
      },
      behavior: {
        disableClick: true
      }
    };

    // Clarification:
    // The LPC component (fetched into window.LivePersonaCardHoverTargetV2 during runtime) uses
    // stopPropagation, which ignores the onClick handler of its child components (our Keyword).
    // Therefore we use onMouseUp instead.
    return (
      <TopicsLpcReactComponent key={vivaTopicsId} cardParameters={cardParameters}>
        <TopicKeyword data-testid="topic-keyword">
          <Keyword
            key={vivaTopicsId}
            displayedText={displayedText}
            checked={isSelected}
            hidden={shouldHide}
            onMouseUp={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            isTopic
          />
        </TopicKeyword>
      </TopicsLpcReactComponent>
    );
  };
