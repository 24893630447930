import React, { useState } from 'react';
import { SUPPORTED_CALL_LOCALES, Tooltip } from 'ci-common-ui';
import { Dropdown, Icon, TextField } from '@fluentui/react';
import useLocale from '../../../../../../hooks/use-locale';
import { PhoneIconCell } from '../../../../../Table/Cells/PhoneCell/PhoneCell';
import { tableLanguageDropdown, ErrorCellContainer } from './StyledFilesPanel';
import { TitleCellContainer, TitleCellFileName, TitleCellCallName, EditCallNameContainer, OkIcon, CancelIcon, EditIcon, DeleteIcon, StyledPhoneIconCellContainer } from './StyledTableCells';

export const PhoneIconCellContainer = ({ row: { error } }) => (
  <StyledPhoneIconCellContainer error={error}>
    <PhoneIconCell />
  </StyledPhoneIconCellContainer>
);

export const TitleCell = ({ data, row: { error, callName, editCallNameAction } }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(callName);

  const onOk = () => {
    editCallNameAction(value);
    setIsEdit(false);
  };

  const onCancel = () => {
    setValue(callName);
    setIsEdit(false);
  };

  if (isEdit) {
    return (
      <TitleCellContainer error={error}>
        <EditCallNameContainer>
          <TextField value={value} onChange={(e, v) => setValue(v)} />
          <OkIcon disabled={!value} onClick={onOk} />
          <CancelIcon onClick={onCancel} />
        </EditCallNameContainer>
      </TitleCellContainer>
    );
  }

  return (
    <TitleCellContainer error={error}>
      <TitleCellCallName>
        {callName}
        {!error && <EditIcon className="show-on-hover" onClick={() => setIsEdit(true)} />}
      </TitleCellCallName>
      <TitleCellFileName>{data}</TitleCellFileName>
    </TitleCellContainer>
  );
};

export const DeleteCallCell = ({ row: { deleteAction } }) => <DeleteIcon className="show-on-hover" onClick={deleteAction} />;

export const LanguageCell = ({ row: { language, changeCallLanguage, error } }) => {
  if (error) {
    return null;
  }

  return (
    <Dropdown
      selectedKey={language}
      onChange={(_event, selectedOption) => changeCallLanguage(selectedOption.key)}
      options={SUPPORTED_CALL_LOCALES}
      styles={tableLanguageDropdown}
    />
  );
};

export const ErrorCell = ({ row }) => {
  const { t } = useLocale(`${row.translationPrefix}.files_panel`);

  if (!row.error) {
    return null;
  }

  return (
    <ErrorCellContainer>
      <Tooltip content={t(row.error)}>
        <Icon iconName="Warning12" />
      </Tooltip>
    </ErrorCellContainer>
  );
};
