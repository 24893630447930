import styled from 'styled-components';

export const ShowMoreContainer = styled.div`
  .ms-Link:active {
    text-decoration: none;
  }
`;

export const itemsContainerStyles = ({ isExpanded, minHeight }) => ({
  maxHeight: isExpanded ? 'none' : `${minHeight}px`,
  overflow: 'hidden'
});

export const showMoreLabelStyles = ({ theme }) => ({ root: {
  display: 'flex', 
  marginTop: '4px', 
  alignItems: 'center', 
  userSelect: 'none', 
  textDecoration: 'none', 
  color: theme.palette.themePrimary, 
  ':hover': {
    textDecoration: 'none',
    color: theme.palette.themeDarkAlt,
  }}
});
