import React from 'react';
import { useLocation } from 'react-router';
import { getRoutePageIdByPathname, routes } from 'pages/routing';
import NavBar from './NavBar';
import { useAppNavigation } from '../../hooks/use-app-navigation';

const navBarLinks = [
  {
    ...routes.home,
    iconId: 'Home',
    label: 'home',
  },
  {
    ...routes.team,
    iconId: 'Group',
    label: 'team',
  },
  {
    ...routes.sellers,
    iconId: 'Contact',
    label: 'seller',
  },
  {
    ...routes.systemMonitoring,
    iconId: 'Medical',
    label: 'system_monitoring'
  }
];

const NavBarContainer = () => {
  const { navigateTo } = useAppNavigation();
  const location = useLocation();
  const pageId = getRoutePageIdByPathname(location.pathname);

  return (
    <NavBar
      currentTab={pageId}
      pages={navBarLinks}
      onTabChange={(newPageId) => {
        if (pageId !== newPageId) {
          const page = routes[newPageId];
          navigateTo({ path: page.link });
        }
      }}
    />
  );
};

export default NavBarContainer;
