import styled, { css } from 'styled-components';
import { PersonaSize } from '@fluentui/react';
import { Element } from 'react-scroll';
import { rtl } from '../../utils/styles/rtl';
import { StyledText, FlexColumn, FlexRow, FlexRowCentered, ellipsisCss } from '../../utils/styles/StyledUtils';
import { colors } from '../../constants/colors';
import { ParticipantPersona } from '../../pages/CallPage/StyledCallPage';

export const Avatar = styled(ParticipantPersona).attrs({
  size: PersonaSize.size24,
})`
  max-width: 24px;
`;

export const RowInfo = styled(FlexColumn)`
  flex: 0 0 40px;
  align-items: center;
  ${rtl`margin-right: 16px;`}
`;

export const Actions = styled(FlexRow)`
  white-space: nowrap;
  display: none;
  ${({ isRTL }) => isRTL ? 'left: 0px' : 'right: 0px'};
  height: 18px;
  & > button {
    height: 18px;
  }
`;

export const RowContent = styled(FlexRow).attrs(({ isRTL }) => ({
  dir: isRTL ? 'rtl' : 'ltr'
}))`
  position: relative;
  text-align: ${({ isRTL }) => isRTL ? 'right' : 'left'};
  align-items: flex-start;
  flex-shrink: 0;
  padding: ${({ rowPadding }) => rowPadding || '10px 16px'};
`;

export const RowHeader = styled(FlexRowCentered)`
  margin: 0 20px;
`;

export const RowHeaderLine = styled(FlexRow)`
  flex: 1;
  height: 1px;
  background: ${({ theme }) => theme.colors.Gray60};
`;

export const RowHeaderText = styled(FlexRow)`
  position: relative;
  padding: 2px 8px;
  color: ${({ theme }) => theme.colors.Gray130};
`;

export const RowTextContainer = styled.div`
  flex: 1;
  width: 85%;
`;

export const RowTextContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RowText = styled.div`
  margin-top: 6px;
`;

export const SpeakerContainer = styled.div`
  ${({ $isEditable }) => $isEditable && css`
    ${ellipsisCss}
  `}
`;

export const SpeakerName = styled(StyledText)`
  margin-bottom: 4px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.Gray130};
`;

export const TranscriptItemContainer = styled(Element)`
  transition: background-color 150ms ease;
  cursor: default;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  :hover {
    background-color: ${({ theme }) => theme.colors.Gray10};
  }

  :hover ${Actions} {
    display: inline-block;
  } 
  
  ${({ $isSelected, theme }) => $isSelected && `
    background-color: ${theme.colors.DYNTint40};
  `}
  && {
    @media screen and (-ms-high-contrast: active), (forced-colors: active){
      * {
        color: windowtext;
      }

      :hover {
        background-color: window;
      }
    }
  }
`;

export const StartTime = styled(StyledText)`
  font-size: 12px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.Gray130}
`;

export const StyledPlayback = styled(StyledText)`
  background-color: ${colors.PBITint30};
  border-radius: 2px;
`;

export const StyledCurrentSearch = styled(StyledText)`
  color: ${colors.White};
  background-color: ${({ theme }) => theme.colors.DYNPrimary};
  border-radius: 2px;
`;

export const StyledSearch = styled(StyledText)`
  background-color: ${({ theme }) => theme.colors.DYNTint40};
  border-radius: 2px;
`;

export const StyledSelectedKeyword = styled(StyledText)`
  color: ${({ theme }) => theme.colors.DYNPrimary};
  font-weight: bold;
`;

export const StyledKeyword = styled(StyledText)`
  font-weight: bold;
`;

export const StyledActionItems = styled(StyledText)`
  text-decoration: underline;
`;

export const StyledSelectedActionItems = styled(StyledText)`
  color: ${({ theme }) => theme.colors.DYNPrimary};
  font-weight: bold;
`;

export const StyledDefaultText = styled(StyledText)`
  color: ${({ theme }) => theme.colors.Gray160};
`;

export const Content = styled.div`
  font-size: 12px;
  margin-bottom: 8px;
  line-height: 18px;
`;
export const commentActionStyles = {
  label: {
    color: colors.Gray160,
    'font-size': '12px',
    selectors: {
      ':hover': {
        color: 'inherit'
      },
    }
  },
  icon: {
    color: colors.Gray160,
    'font-size': '14px'
  }
};
