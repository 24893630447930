import React, { useState } from 'react';
import noop from 'lodash/noop';
import { ActionButton, TooltipOverflowMode } from '@fluentui/react';
import { durationToTime } from '../../utils/date/date';
import {
  Actions,
  Avatar,
  commentActionStyles,
  RowHeader,
  RowHeaderLine,
  RowHeaderText,
  RowContent,
  RowTextContainer,
  RowTextContainerHeader,
  RowInfo,
  StartTime,
  TranscriptItemContainer,
  SpeakerName,
  SpeakerContainer
} from './StyledTranscriptItem';
import {  getHighlightSections } from './highlightHelper/highlight-helper';
import { Comments } from './components/Comments/Comments';
import { useInfra } from '../../providers/InfraProvider';
import { TRACK_IDS } from '../../pages/CallPage/tracking';
import { eventAction } from '../../services/telemetry-service';
import { Tooltip } from '../Tooltip';
import {TranscriptItemContent} from "./TranscriptItemContent";

export const SPEAKER_TYPES = {
  agent: 1,
  customer: 2
};


const stopPropagation = (e) => e.stopPropagation();

export const TranscriptItem = ({
  row,
  comments,
  keywords,
  actionItems,
  selectedKeyword,
  selectedActionItem,
  playback,
  search,
  currentSearchIndex,
  participantDetails = {},
  participantAvatar,
  onAddComment,
  onUpdateComment,
  onDeleteComment,
  commentTexts,
  addCommentText,
  showMoreTexts,
  onShowMoreClick,
  onTranscriptClick,
  isRTL,
  rowPadding,
  isSelected,
}) => {
  const [isNewCommentMode, setIsNewCommentMode] = useState(false);
  const [isCommentActionInProgress, setIsCommentActionInProgress] = useState(false);
  const [isCommentActionError, setCommentActionError] = useState(false);
  const { trackEvent, isEditable } = useInfra();

  const { primaryText, isUnknown, id: participantId, imageUrl } = participantDetails;

  const processAction = async (action) => {
    try {
      setIsCommentActionInProgress(true);
      await action();
    } catch (error) {
      setCommentActionError(true);
    } finally {
      setIsCommentActionInProgress(false);
    }
  };

  const addComment = (newCommentText) => {
    processAction(async () => {
      await onAddComment(row.id, newCommentText, row.index, row.offset, row.duration);
      setIsNewCommentMode(false);
    });
    trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.ADD_COMMENT, message: { fragmentId: row.id }, fragmentStart: row.index });
  };

  const updateComment = (commentId, newCommentText) => {
    processAction(async () => {
      await onUpdateComment(row.id, commentId, newCommentText, row.index);
    });
    trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.EDIT_COMMENT, message: { fragmentId: row.id, commentId, fragmentStart: row.index } });
  };

  const deleteComment = (commentId) => {
    processAction(async () => {
      await onDeleteComment(row.id, commentId, row.index);
    });
    trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.DELETE_COMMENT, message: { fragmentId: row.id, commentId, fragmentStart: row.index } });
  };

  const sections = getHighlightSections(row, keywords, actionItems, selectedKeyword, search, currentSearchIndex, playback, selectedActionItem);

  const onClick = onTranscriptClick ? () => onTranscriptClick(row) : noop;

  return (
    <TranscriptItemContainer data-testid='transcript-item-container' data-is-focusable="true" name={`row_${row.id}`} key={row.id} $isSelected={isSelected} onClick={onClick}>
      {row.header && (
      <RowHeader>
        <RowHeaderLine />
        <RowHeaderText>{row.header}</RowHeaderText>
        <RowHeaderLine />
      </RowHeader>
      )}
      <RowContent isRTL={isRTL} rowPadding={rowPadding}>
        <RowInfo>
          <Avatar
            text={primaryText}
            hidePersonaDetails
            isUnknown={isUnknown}
            imageUrl={participantAvatar || imageUrl}
            participantId={participantId}
          />
          <StartTime>{durationToTime(row.startInSeconds)}</StartTime>
        </RowInfo>
        <RowTextContainer>
          <RowTextContainerHeader>
            <SpeakerContainer $isEditable={isEditable}>
              <Tooltip content={primaryText} overflowMode={TooltipOverflowMode.Parent}>
                <SpeakerName>{primaryText}</SpeakerName>
              </Tooltip>
            </SpeakerContainer>
            {isEditable && !isNewCommentMode && (
              <Actions onClick={stopPropagation} isRTL={isRTL}>
                <ActionButton
                  iconProps={{ iconName: 'CommentAdd' }}
                  onClick={() => setIsNewCommentMode(true)}
                  styles={commentActionStyles}
                >
                  {addCommentText}
                </ActionButton>
              </Actions>
              )}
          </RowTextContainerHeader>
          <TranscriptItemContent sections={sections} rowId={row.id} />
        </RowTextContainer>

      </RowContent>
      <Comments
        commentTexts={commentTexts}
        showMoreTexts={showMoreTexts}
        onShowMoreClick={onShowMoreClick}
        comments={comments}
        isCommentActionError={isCommentActionError}
        onDismissAddCommentError={() => setCommentActionError(false)}
        onAddComment={addComment}
        onUpdateComment={updateComment}
        onDeleteComment={deleteComment}
        isNewCommentMode={isNewCommentMode}
        isCommentActionInProgress={isCommentActionInProgress}
        onCancelNewComment={() => setIsNewCommentMode(false)}
        isRTL={isRTL}
      />
    </TranscriptItemContainer>
  );
};
