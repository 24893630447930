import styled from 'styled-components';
import { colors } from '../../../../constants/colors';
import { FlexColumn, FlexRow, StyledText } from '../../../../utils/styles/StyledUtils';

export const Container = styled(FlexColumn)`
  padding: 12px 12px 0 12px;
  width: 320px;
  height: 340px;
`;

export const Label = styled(StyledText)`
  font-size: 12px;
  color: ${colors.Gray130};
  margin-bottom: 8px;
`;

export const NewRecordTitle = styled(StyledText)`
  font-size: 14px;
  color: ${colors.Gray130};
  margin-bottom: 1px;
  padding: 0px 4px;
`;

export const Subtitle = styled(StyledText)`
  font-size: 12px;
  color: ${colors.Gray130};
  margin-bottom: 8px;
`;

export const Results = styled.div`
  flex: 1;
  margin: 0px;
  border-bottom: 1px solid ${colors.Gray30};
  overflow-y: auto;
  max-height: 232px;
  ${({ hideCreateNewRecord }) => hideCreateNewRecord && `
  background-color: ${colors.White};
  box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
  border-radius: 2px;
  border: 1px solid ${colors.Gray30};
  `};
`;

export const ResultContainer = styled(FlexRow)`
  min-height: 50px;
  cursor: ${({ disabled }) => disabled ? 'auto' : 'pointer'};
  opacity: ${({ disabled }) => disabled ? '0.4' : '1'};
  border-radius: 2px;
  padding: 0px 6px;
  align-items: center;
  ${({ disabled }) => !disabled
  && `&:hover {
    background-color: ${colors.Gray10};
  }`
};
`;

export const ResultTitle = styled.div`
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.Gray160};
`;

export const ResultSecondaryText = styled.div`
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: ${colors.Gray130};
`;

export const TitleAndSecondayContainer = styled(FlexColumn)`
  justify-content: space-between;
`;

const FontIcon = styled.span`
  &:after {
    font-family: '${({ iconFontFamily }) => iconFontFamily}';
    content: '\\${({ iconCode }) => iconCode}';
  }
`;

export const StyledFontIcon = styled(FontIcon)`
    position: relative;
    top: 2px;
    font-size: 16px;
`;
