import React, { useEffect } from 'react';
import { useFetchNow } from 'ci-common-ui';
import Page from '../../components/Page/Page';
import { getInsightDetails } from '../../../network/data-services/insight-service';
import CustomerSentiment from './components/CustomerSentiment/CustomerSentiment';
import RelevantConversations from '../components/RelevantConversations/RelevantConversations';
import { useQueryDateChange } from '../../../hooks/use-query-date-change';
import { getDateParams } from '../../../utils/url/url';
import { callTypeColumn, dateAndDurationColumn, tagsColumn, commentsCountColumn, ownerColumn, sentimentColumn, callTopicColumn, crmEntitiesLinksColumn } from '../../../components/Table/default-columns';
import BackHomeButton from '../components/BackHomeButton';
import { getSpecificError } from '../../../utils/error-state/error-state';
import { TRACK_IDS } from '../../../constants/tracking';
import { componentLoadingTimeService } from '../../../services/component-loading-time-service/component-loading-time-service';

const type = 'CustomerSentiment';
const columns = [callTypeColumn, callTopicColumn, sentimentColumn, tagsColumn, commentsCountColumn, ownerColumn, dateAndDurationColumn, crmEntitiesLinksColumn];

const InsightCustomerSentimentPage = () => {
  const dateParams = getDateParams();
  const apiParams = { type, ...dateParams };
  const tableApiParams = { type, ...dateParams };
  const { data, isLoading, error, fetchData } = useFetchNow(() => getInsightDetails(apiParams, 'customerSentiment'), { getSpecificError });

  useEffect(() => {
    componentLoadingTimeService.start(TRACK_IDS.SOURCES.CUSTOMER_SENTIMENT_INSIGHT_PAGE);
  }, []);

  useQueryDateChange(fetchData);

  return (
    <Page isLoading={isLoading} title={<BackHomeButton />} fullTimeRange={data && data.dateTimeRange} error={error}>
      <CustomerSentiment isLoading={isLoading} data={data} error={error} />
      <RelevantConversations columns={columns} apiParams={tableApiParams} isPageDetailsLoading={isLoading} trackSource={TRACK_IDS.SOURCES.CUSTOMER_SENTIMENT_INSIGHT_PAGE} />
    </Page>
  );
};
export default InsightCustomerSentimentPage;
