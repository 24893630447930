import React, { useEffect } from 'react';
import useLocale from 'hooks/use-locale';
import { finishOnboardingProcess } from 'network/data-services/settings';
import { useFetch } from 'ci-common-ui';
import isEmpty from 'lodash/isEmpty';
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { get } from 'lodash';
import { Error, StepContainer, StepFooter, StepLoader, StepRoot } from '../../../../../components/Wizard/StyledWizard';
import { CheckIcon, Keyword, Keywords, Section, Title } from './StyledFinish';
import { Org } from '../SelectOrganization/SelectOrganization';
import { eventAction, trackEvent } from '../../../../../services/telemetry-service/telemetry-service';
import { TRACK_IDS } from '../../../../../constants/tracking';
import ConnectionStringDisplay from './ConnectionStringDisplay/ConnectionStringDisplay';
import { resetTheApp } from '../../../../../utils/app-context/app-context';
import { componentLoadingTimeService } from '../../../../../services/component-loading-time-service/component-loading-time-service';

const FinishStep = ({ settings, back, trackingSource }) => {
  const { t, tAbs } = useLocale('pages.onboarding.finish');
  const { data: isFinishSucceed, fetchData: finishOnboarding, isLoading, error } = useFetch(finishOnboardingProcess);

  useEffect(() => {
    if (isFinishSucceed) {
      resetTheApp();
    }
  }, [isFinishSucceed]);

  useEffect(() => {
    if (!isLoading) {
      componentLoadingTimeService.end(TRACK_IDS.SOURCES.ONBOARDING_MODAL);
    }
  }, [isLoading]);

  if (isLoading) {
    return <StepLoader label={t('loading')} />;
  }

  const finish = async () => {
    await finishOnboarding(settings);
    trackEvent({
      source: trackingSource,
      action: eventAction.click,
      actionOn: TRACK_IDS.COMMON.FINISH_BUTTON,
      message: `orgId: ${get(settings, 'org.id')}`
    });
  };

  const { org, dataSource, isEyesOnData, keywords } = settings;

  return (
    <StepRoot>
      <StepContainer>

        <Org item={org} />

        {dataSource
          && (
            <Section>
              <Title>{t('data_source')}:</Title>
              <ConnectionStringDisplay text={dataSource.connectionString} />
              <div>{t('container')}: {dataSource.containerName}</div>
            </Section>
          )}

        {isEyesOnData
          && (
            <Section>
              <Title><CheckIcon />{t('eyes_on_data')}</Title>
            </Section>
          )}

        {keywords
          && (
            <Section>
              {!isEmpty(keywords.trackers) && (
                <div>
                  <Title>{t('trackers')}:</Title>
                  <Keywords>{keywords.trackers.map((key) => (<Keyword key={key}>{key}</Keyword>))}</Keywords>
                </div>
              )}
              {!isEmpty(keywords.competitors) && (
                <div>
                  <Title>{t('competitors')}:</Title>
                  <Keywords>{keywords.competitors.map((key) => (<Keyword key={key}>{key}</Keyword>))}</Keywords>
                </div>
              )}
              {!isEmpty(keywords.languages) && (
                <div>
                  <Title>{t('languages')}:</Title>
                  <Keywords>{keywords.languages.map((key) => (<Keyword key={key}>{key}</Keyword>))}</Keywords>
                </div>
              )}
            </Section>
          )}
      </StepContainer>

      <StepFooter>
        {error && <Error>{t('error')}</Error>}
        <DefaultButton text={tAbs('common.back')} onClick={back} />
        <PrimaryButton text={tAbs('common.finish')} onClick={finish} />
      </StepFooter>
    </StepRoot>
  );
};

export default FinishStep;
