import React, { useEffect } from 'react';
import map from 'lodash/map';
import { LabelWithSubLabel, isValidEmail } from 'ci-common-ui';
import { StepRoot } from '../../../../../components/Wizard/StyledWizard';
import StepForm from '../../../../../components/Wizard/StepForm/StepForm';
import useLocale from '../../../../../hooks/use-locale';
import { SectionText, SectionTitle } from '../../../../../components/Modal/StyledModal';
import { StyledTextField, StyledDropdownField, Row } from './StyledOrganizationInfo';

import { INDUSTRIES, REGIONS } from './organization-fields-data';
import { appContext } from '../../../../../utils/app-context/app-context';
import { componentLoadingTimeService } from '../../../../../services/component-loading-time-service/component-loading-time-service';
import { TRACK_IDS } from '../../../../../constants/tracking';

export const OrganizationInfoDisplayValues = ({ org_name: orgName, email, industry, region }, translationPrefix) => {
  const { t } = useLocale(`${translationPrefix}.organization_info`);
  if (orgName) {
    return `${orgName}, ${email},  ${t(`industries.${industry}`)}, ${t(`regions.${region}`)}`;
  }
  return null;
};

const OrganizationInfoStep = ({ handleSettings, settings, next, back, translationPrefix }) => {
  const { t, tAbs } = useLocale(translationPrefix);

  useEffect(() => {
    handleSettings({ email: appContext.user.username });
    componentLoadingTimeService.end(TRACK_IDS.SOURCES.TRIAL_SETUP_MODAL);
  }, []);

  const onSubmit = (value) => {
    handleSettings(value);
    next();
  };

  return (
    <StepRoot>
      <StepForm onSubmit={onSubmit} initialValues={settings} back={back}>
        <SectionTitle>{t('main_title')}</SectionTitle>
        <SectionText>{t('main_subtitle')}</SectionText>
        <div>
          <Row>
            <StyledTextField
              label={t('organization_name_label')}
              name="org_name"
              subLabel={t('organization_name_sublabel')}
              onRenderLabel={LabelWithSubLabel}
              placeholder={t('organization_name_placeholder')}
              isRequired
              errorMessages={{ required: tAbs('common.required') }}
            />
            <StyledDropdownField
              label={t('industry_label')}
              subLabel={t('industry_sublabel')}
              onRenderLabel={LabelWithSubLabel}
              name="industry"
              isRequired
              options={map(INDUSTRIES, (industryId) => ({
                key: industryId,
                text: t(`industries.${industryId}`)
              }))}
              errorMessages={{ required: tAbs('common.required') }}
            />
          </Row>
          <Row>
            <StyledTextField
              label={t('email_label')}
              subLabel={t('email_sublabel')}
              onRenderLabel={LabelWithSubLabel}
              validate={isValidEmail}
              isRequired
              name="email"
              errorMessages={{ required: tAbs('common.required'), invalidEmail: tAbs('errors.validation.email') }}
            />
            <StyledDropdownField
              label={t('region_label')}
              subLabel={t('region_sublabel')}
              onRenderLabel={LabelWithSubLabel}
              name="region"
              isRequired
              options={map(REGIONS, (regionId) => ({
                key: regionId,
                text: t(`regions.${regionId}`)
              }))}
              errorMessages={{ required: tAbs('common.required') }}
            />
          </Row>
          <Row>
            <StyledTextField
              label={t('phone_label')}
              placeholder={t('phone_placeholder')}
              name="phone_number"
              errorMessages={{ required: tAbs('common.required') }}
            />
          </Row>
        </div>
      </StepForm>
    </StepRoot>
  );
};

export default OrganizationInfoStep;
