import React, { useState } from 'react';
import noop from 'lodash/noop';
import styled from 'styled-components';
import { Icon } from '@fluentui/react';
import { colors } from '../../constants/colors';
import { FocusableContainer } from '../../utils/styles/StyledUtils';
import { ENTER_KEY_CODE } from '../../constants/key-codes';
import { Tooltip } from '../Tooltip';

export const StyledIconToggle = styled(Icon).attrs((props) => ({
  'data-disabled': props.disabled
}))`
  cursor: pointer;
  ${({ disabled }) => disabled && `
    cursor: default;
    color: ${colors.Gray90};
  `};
`;

export const IconToggle = ({ iconNameSelected, iconNameUnselected, selected, disabled, onToggle, selectedText, unselectedText, disabledText }) => {
  const [isHovering, setIsHovering] = useState(false);
  const currentIcon = selected ? iconNameSelected : iconNameUnselected;
  const oppositeIcon = selected ? iconNameUnselected : iconNameSelected;
  const iconName = disabled ? iconNameUnselected : isHovering ? oppositeIcon : currentIcon;
  const handleEnterClick = (e) => {
    if (e.key === ENTER_KEY_CODE) {
      e.preventDefault();
      onToggle();
    }
  };

  return (
    <Tooltip content={disabled ? disabledText : selected ? selectedText : unselectedText} id="icon-toggle-tooltip">
      <FocusableContainer role="button" onKeyDown={handleEnterClick}>
        <StyledIconToggle
          iconName={iconName}
          onClick={disabled ? noop : onToggle}
          disabled={disabled}
          onMouseEnter={() => disabled ? noop() : setIsHovering(true)}
          onMouseLeave={() => disabled ? noop() : setIsHovering(false)}
        />
      </FocusableContainer>
    </Tooltip>
  );
};
