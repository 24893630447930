import { useLocale as commonUseLocale } from 'ci-common-ui';
import { appContext, appModes } from '../utils/app-context/app-context';

export const APP_MODE_TO_KEY = {
  [appModes.Default]: 'default',
  [appModes.CustomerService]: 'customer_service',
  [appModes.Embed]: 'default' // In Embed mode, texts should be default (because if CustomerService is on, we can't be in embed mode).
};

const useLocale = (prefix) => commonUseLocale(prefix, APP_MODE_TO_KEY[appContext.appMode]);

export default useLocale;
