// @flow
/* eslint-disable new-cap */
import { useEffect, useState, useCallback } from 'react';
import { Howl } from 'howler';
import { SEEK_INTERVAL, soundStatus, DEFAULT_VOLUME, SOUND_FORMATS } from '../constants/howler';

let timerInterval = null;

const loadHowler = (url, onload, onloaderror, onend, onplay, onpause, onstop, volume) => {
  const howler = new Howl({
    src: [url],
    volume,
    format: SOUND_FORMATS,
    onload,
    onloaderror,
    onend,
    onplay,
    onpause,
    html5: true
  });
  return howler;
};

export const useSound = (
  url,
  onLoad,
  onLoadError,
  initialCurrentTime,
) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayInLoading, setIsPlayInLoading] = useState(false);
  const [isPauseInLoading, setIsPauseInLoading] = useState(false);
  const [duration, setDuration] = useState(null);
  const [sound, setSound] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [status, setStatus] = useState(soundStatus.LOADING);

  function handleLoad() {
    if (onLoad) {
      onLoad();
    }
    setDuration(this.duration());
    setStatus(soundStatus.LOADED);
    this.on('end', () => {
      stopCurrentTimeInterval();
      setIsPlaying(false);
    });
  }

  function handleLoadError(id, err) {
    if (onLoadError) {
      onLoadError(err);
    }

    setStatus(soundStatus.FAILED);
  }

  function stopCurrentTimeInterval() {
    clearInterval(timerInterval);
  }

  const setCurrentTimeInterval = () => {
    timerInterval = setInterval(() => {
      if (!sound) { return; }
      const seek = sound.seek();
      if (typeof seek === 'object') return;
      setCurrentTime(seek);
    }, SEEK_INTERVAL);
  };

  // lazy load Howler
  useEffect(() => {
    if(!url) {
      setStatus(soundStatus.FAILED);
      return;
    };

    setStatus(soundStatus.LOADING);
    const newSound = loadHowler(
      url,
      handleLoad, handleLoadError,
      stopCurrentTimeInterval,
      () => {
        setIsPlayInLoading(false);
        setIsPlaying(true);
      },
      () => {
        setIsPauseInLoading(false);
        setIsPlaying(false);
      },
      () => setIsPlaying(false),
      sound ? sound.volume() : DEFAULT_VOLUME
    );
    if (sound) { // for token expired
      newSound.seek(currentTime);
    }
    setSound(newSound);
  }, [url]);

  useEffect(() => {
    if (sound && initialCurrentTime && initialCurrentTime !== currentTime) {
      moveTo(initialCurrentTime);
    }
  }, [sound, initialCurrentTime]);

  useEffect(() => destroySound, [sound]);

  const destroySound = () => {
    stopCurrentTimeInterval();
    if (sound) {
      sound.off();
      sound.stop();
    }
  };

  const setVolume = (volume) => {
    if (sound) {
      sound.volume(volume);
    }
  };

  const play = useCallback((id) => {
    if (!sound) { return; }
    setIsPlayInLoading(true);
    sound.play(id);
    setCurrentTimeInterval();
  },
  [sound]);

  const pause = useCallback((id) => {
    if (!sound) { return; }
    setIsPauseInLoading(true);
    sound.pause(id);
    stopCurrentTimeInterval();
  },
  [sound]);

  const stop = useCallback((id) => {
    if (!sound) { return; }
    sound.stop(id);
    setIsPlaying(false);
  },
  [sound]);

  const togglePlayPause = useCallback((id) => {
    if (!sound) return;
    if (sound.playing()) {
      pause(id);
    } else {
      play(id);
    }
  }, [sound]);

  const forward = useCallback((seconds) => {
    if (!sound) { return; }
    let newCurrentTime = currentTime + seconds;
    if (duration && newCurrentTime >= duration) {
      stop();
      return;
    }
    newCurrentTime = newCurrentTime < 0 ? 0 : newCurrentTime;
    sound.seek(newCurrentTime);
    setCurrentTime(newCurrentTime);
  }, [sound, currentTime, duration]);

  const moveTo = useCallback((newTime) => {
    setCurrentTime(newTime);
    if (!sound) { return; }
    sound.seek(newTime);
  }, [sound]);

  const returnedValue = {
    status,
    play,
    togglePlayPause,
    pause,
    forward,
    moveTo,
    isPlaying,
    isPlayInLoading,
    isPauseInLoading,
    duration,
    setVolume,
    currentTime
  };

  return returnedValue;
};
