import { pagesCountCalc, recordingTargetType } from 'ci-common-ui';
import map from 'lodash/map';
import omitBy from 'lodash/omitBy';
import get from 'lodash/get';
import find from 'lodash/find';
import toString from 'lodash/toString';
import isEmpty from 'lodash/isEmpty';
import { transformSentimentDataForPie, transformSentimentDataForPercentagesBar } from 'utils/chart';
import { formatRound } from 'utils/numeric';
import { transformServerDate } from '../dateFilters/date-filters';
import { dataTransformer } from '../../../utils/error-state/error-state';
import {
  calcTalkToListenRatio,
  calculateDiffPercentage,
  transformTags,
  transformCrm,
  SHOULD_PRESENT_INSIGHT,
  getContactsFromParticipantsObject
} from '../utils';
import { dateFromServer } from '../../../utils/date/date';

export const transformSellerList = (data) => map(data, ({ aadId, fullName, email, isTopPerformerAgent }) => ({
  key: aadId,
  email,
  text: fullName,
  isTopPerformerAgent
}));

export const transformSellerListTopPerformer = (data, topPerformers) => data.map((item) => ({
  ...item,
  isTopPerformerAgent: topPerformers.some(({ aadId }) => aadId === item.aadId || aadId === item.aadUserId)
}));

const sellerDataTransformers = {
  dateTimeRange: (data) => transformServerDate(data),
  id: (data) => !isEmpty(data.agentDetails) && data.agentDetails[0].aadId,
  agentName: (data) => data.agentDetails[0].agentFullName,
  overallCustomerSentiment: (data) => transformSentimentDataForPie(data.overallCustomerSentiment),
  customerSentimentOverTime: (data) => transformSentimentDataForPercentagesBar(data.customerSentimentOverTime),
  insights: (data) => transformInsights(data.insights),
  kpis: ({
    talkToListenRatio,
    avgWordsForMinute,
    avgCustomersLongestMonologueInMs,
    avgPauseBeforeSpeakingInMs,
    avgSwitchRate
  }) => (
    [
      {
        id: 'talk_to_listen_ratio',
        value: calcTalkToListenRatio(get(talkToListenRatio, [0, 'agentAvgTalkToListenRatio'])),
        format: '',
        teamAvgValue: calcTalkToListenRatio(get(talkToListenRatio, [0, 'teamAvgTalkToListenRatio'])),
        teamAvgTrend: !isEmpty(talkToListenRatio) && calculateDiffPercentage(
          talkToListenRatio[0].teamAvgTalkToListenRatio, talkToListenRatio[0].agentAvgTalkToListenRatio
        ),
      },
      {
        id: 'avg_talking_speed',
        value: !isEmpty(avgWordsForMinute) && formatRound(avgWordsForMinute[0].agentAvgWordsPerMin),
        format: 'wpm',
        teamAvgValue: !isEmpty(avgWordsForMinute) && formatRound(avgWordsForMinute[0].teamAvgWordsPerMin),
        teamAvgTrend: !isEmpty(avgWordsForMinute) && calculateDiffPercentage(
          avgWordsForMinute[0].teamAvgWordsPerMin, avgWordsForMinute[0].agentAvgWordsPerMin
        ),
      },
      {
        id: 'switch_rate',
        value: !isEmpty(avgSwitchRate) ? formatRound(avgSwitchRate[0].agentAvgSwitchRate) : 0,
        format: '',
        teamAvgValue: !isEmpty(avgSwitchRate) && formatRound(avgSwitchRate[0].teamAvgSwitchRate),
        teamAvgTrend: !isEmpty(avgSwitchRate) && calculateDiffPercentage(
          avgSwitchRate[0].teamavgSwitchRate, avgSwitchRate[0].agentAvgSwitchRate
        ),
      },
      {
        id: 'pause_before_speaking',
        value: !isEmpty(avgPauseBeforeSpeakingInMs) ? formatRound(avgPauseBeforeSpeakingInMs[0].agentAvgPauseBeforeSpeakingInMs / 1000) : 0,
        format: 'secs',
        teamAvgValue: !isEmpty(avgPauseBeforeSpeakingInMs) && formatRound(avgPauseBeforeSpeakingInMs[0].teamAvgPauseBeforeSpeakingInMs / 1000),
        teamAvgTrend: !isEmpty(avgPauseBeforeSpeakingInMs) && calculateDiffPercentage(
          formatRound(avgPauseBeforeSpeakingInMs[0].teamAvgPauseBeforeSpeakingInMs / 1000), formatRound(avgPauseBeforeSpeakingInMs[0].agentAvgPauseBeforeSpeakingInMs / 1000)
        ),
      },
      {
        id: 'longest_customer_monologue',
        value: !isEmpty(avgCustomersLongestMonologueInMs) ? formatRound(avgCustomersLongestMonologueInMs[0].agentAvgCustomerLongestMonologInMs / 1000) : 0,
        format: 'secs',
        teamAvgValue: !isEmpty(avgCustomersLongestMonologueInMs) && formatRound(avgCustomersLongestMonologueInMs[0].teamAvgCustomerLongestMonologInMs / 1000),
        teamAvgTrend: !isEmpty(avgCustomersLongestMonologueInMs) && calculateDiffPercentage(
          formatRound(avgCustomersLongestMonologueInMs[0].teamAvgCustomerLongestMonologInMs / 1000), formatRound(avgCustomersLongestMonologueInMs[0].agentAvgCustomerLongestMonologInMs / 1000)
        ),
      },
    ]
  ),
};

const transformInsights = ({
  percentageOfAgentCallsWithPositiveCustomerSentimentAboveAvg,
  agentAndTopSellersTalkToListenRatio,
  agentAndTopSellersWordsPerMinute,
  topKeywordsMissingFromAgentCalls,
}) => (
  omitBy(({
    percentageOfAgentCallsWithPositiveCustomerSentimentAboveAvg: {
      isHidden: get(percentageOfAgentCallsWithPositiveCustomerSentimentAboveAvg, [0, SHOULD_PRESENT_INSIGHT]) === 0,
      value: formatRound(get(percentageOfAgentCallsWithPositiveCustomerSentimentAboveAvg, [0, 'percentageOfAgentCallsWithPositiveSentimentAboveAvg']) * 100)
    },
    agentAndTopSellersTalkToListenRatio: {
      isHidden: get(agentAndTopSellersTalkToListenRatio, [0, SHOULD_PRESENT_INSIGHT]) === 0,
      agentValue: calcTalkToListenRatio(get(agentAndTopSellersTalkToListenRatio, [0, 'agentAvgTalkToListenRatio'])),
      topSellersValue: calcTalkToListenRatio(get(agentAndTopSellersTalkToListenRatio, [0, 'topSellersAvgTalkToListenRatio'])),
      trend: Math.abs(calculateDiffPercentage(
        get(agentAndTopSellersTalkToListenRatio, [0, 'agentAvgTalkToListenRatio']),
        get(agentAndTopSellersTalkToListenRatio, [0, 'topSellersAvgTalkToListenRatio'])
      ))
    },
    agentAndTopSellersWordsPerMinute: {
      isHidden: get(agentAndTopSellersWordsPerMinute, [0, SHOULD_PRESENT_INSIGHT]) === 0,
      agentValue: formatRound(get(agentAndTopSellersWordsPerMinute, [0, 'agentAvgWordsPerMin'])),
      topSellersValue: formatRound(get(agentAndTopSellersWordsPerMinute, [0, 'topSellersAvgWordsPerMin'])),
      trend: calculateDiffPercentage(
        get(agentAndTopSellersWordsPerMinute, [0, 'agentAvgWordsPerMin']),
        get(agentAndTopSellersWordsPerMinute, [0, 'topSellersAvgWordsPerMin']),
      )
    },
    topKeywordsMissingFromAgentCalls: {
      isHidden: get(topKeywordsMissingFromAgentCalls, SHOULD_PRESENT_INSIGHT) === 0,
      keywords: map(get(topKeywordsMissingFromAgentCalls, 'keywords'), 'keyword')
    }
  }), ({ isHidden }) => isHidden)
);

export const transformSellerData = (data) => (
  dataTransformer('sellerDetails', sellerDataTransformers, data)
);

export const transformConversations = (data) => (
  {
    conversations: map(data.conversations, transformConversation),
    pagesCount: pagesCountCalc(data.totalRecordCount)
  });

export const transformConversation = ({
  conversationId,
  commentsCount,
  direction,
  duration,
  id,
  crm,
  sentiment,
  participants,
  scope,
  startTime,
  subject,
  tags,
  consentMode
}) => ({
  id: toString(id),
  callTopic: subject,
  startTime: dateFromServer(startTime),
  duration,
  commentsCount,
  sentiment,
  customerPhoneNumber: get(find(participants, (role) => role === 'customer'), 'phoneNumber', ''),
  callScope: scope,
  callDirection: direction,
  crm: transformCrm({ ...crm, contacts: getContactsFromParticipantsObject(participants) }),
  conversationId,
  tags: transformTags(tags),
  // supporting currentUserOnly only for past calls that were made under this type, and referring currentUserOnly as OnlyAgents
  consentMode: consentMode === 'currentUserOnly' ? recordingTargetType.OnlyAgents : consentMode,
});
