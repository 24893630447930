import React from 'react';
import numeral from 'numeral';
import every from 'lodash/every';
import isNil from 'lodash/isNil';
import { colors } from '../../constants/colors';
import { PartsContainer, Part, TooltipContainer, PartRow, Wrapper, Bullet, Text, Value, tooltipStyles, LegendContainer } from './StyledPercentageBar';
import { FlexColumn, FocusableContainer } from '../../utils/styles/StyledUtils';
import { Tooltip } from '../Tooltip';

function getPercentageStr(value) {
  return `${numeral(value * 100).format('0.[00]')}%`;
}

const TooltipContent = ({ parts }) => (
  <TooltipContainer>
    {parts.map((part) => (
      <PartRow key={part.name}>
        <Wrapper>
          <Bullet color={part.color} />
          <Text>{part.name}</Text>
        </Wrapper>
        <Value>{getPercentageStr(part.value)}</Value>
      </PartRow>
    ))}
  </TooltipContainer>
);

export const PercentageBar = ({ parts, showTooltip = false, showLegend = false }) => {
  if (!parts || every([parts[0], parts[1], parts[2]], isNil)) {
    return <PartsContainer style={{ backgroundColor: colors.Neutral }} aria-label="Empty Percentage Bar" />;
  }

  return (
    <FlexColumn>
      <Tooltip content={showTooltip && <TooltipContent parts={parts} />} styles={tooltipStyles}>
        <FocusableContainer aria-label={`Percentage Bar, ${parts.map((p) => `${p.name} ${p.value}`).join(',')}`}>
          <PartsContainer>
            { parts.map((part) => <Part key={part.name + part.value} color={part.color} width={getPercentageStr(part.value)} />) }
          </PartsContainer>
        </FocusableContainer>
      </Tooltip>
      { showLegend && <LegendContainer>{parts.map((part) => <Wrapper><Bullet key={part.name + part.value} color={part.color} /><Text>{part.name}</Text></Wrapper>)}</LegendContainer> }
    </FlexColumn>
  );
};
