import React, { useRef } from 'react';
import useLocale from '../../../../../../../hooks/use-locale';
import { TimeLimit, CustomDatePicker, customDatePickerStyles } from '../StyledViewAs';
import { getFormattedExpiryDate } from '../utils';
import { dayJS } from '../../../../../../../utils/date/date';

const renderDatePicker = (onUpdateExpiryDate, mappingId, dismissMenu, datePickerRef, t, tDefault) => {
  const onSelectedDate = (date) => {
    dismissMenu();
    const formattedDate = getFormattedExpiryDate(date);
    onUpdateExpiryDate({ key: 'select_a_date', text: formattedDate, value: formattedDate, unit: null }, mappingId);
  };

  const today = dayJS().toDate();

  return (
    <CustomDatePicker
      styles={customDatePickerStyles}
      showMonthPickerAsOverlay
      ariaLabel={tDefault('select_a_date')}
      onSelectDate={onSelectedDate}
      minDate={today}
      textField={{ borderless: true, value: t('select_a_date'), readOnly: true }}
      componentRef={datePickerRef}
      onClick={() => datePickerRef?.current?.showDatePickerPopup()}
    />
  );
};

export const TimeLimitDropDown = ({ selectedTimeLimit, onUpdateExpiryDate, mappingId, text, timeLimitRef, hideIcon, noMargin }) => {
  const { t, tDefault } = useLocale('pages.settings.your_team.view_as');
  const datePickerRef = useRef(null);

  const timeLimitOptions = {
    items: [
      { key: 'forever', text: t('forever'), value: null, unit: null },
      { key: '1_day', text: t('1_day'), value: 1, unit: 'day' },
      { key: '1_week', text: t('1_week'), value: 7, unit: 'day' },
      { key: '1_month', text: t('1_month'), value: 1, unit: 'month' },
      { onRender: (_, dismissMenu) => renderDatePicker(onUpdateExpiryDate, mappingId, dismissMenu, datePickerRef, t, tDefault) }
    ],
    onItemClick: (_, item) => onUpdateExpiryDate(item, mappingId)
  };

  return (
    <TimeLimit
      menuProps={timeLimitOptions}
      isOptionSelected={selectedTimeLimit}
      componentRef={timeLimitRef}
      text={text}
      hideIcon={hideIcon}
      noMargin={noMargin}
    />
  );
};
