import styled from 'styled-components';
import { rtl } from '../../utils/styles/rtl';
import FeedbackStarEmptySVG from './assets/feedback_star_empty.svg';
import FeedbackStarFullSVG from './assets/feedback_star_full.svg';

export const FeedbackEmptyIcon = styled(FeedbackStarEmptySVG).attrs({
  alt: 'Empty-Star'
})`
      height: 20px;
      width: 20px;
      ${rtl`margin-right`}: 4px;
      cursor: pointer;
  `;

export const FeedbackFullIcon = styled(FeedbackStarFullSVG).attrs({
  alt: 'Full-Star'
})`
      height: 20px;
      width: 20px;
      ${rtl`margin-right`}: 4px;
      cursor: pointer;
  `;
