import get from 'lodash/get';
import find from 'lodash/find';
import join from 'lodash/join';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import uniqBy from 'lodash/fp/uniqBy';
import reverse from 'lodash/fp/reverse';
import sortBy from 'lodash/fp/sortBy';
import ffilter from 'lodash/fp/filter';
import fmap from 'lodash/fp/map';
import flow from 'lodash/fp/flow';
import { EntityType, ActionItem, ContactEntity, DateEntity, QuoteEntity, Language } from '@sci/common';
import { EMPTY_GUID } from '../../../../constants/api';
import { formatDate, EXTENDED_FORMAT_WITH_TIME } from '../../../../utils/date/date';
import { download } from '../../../../utils/browser';
import { generateICSEvent } from './generateICSEvent';

export const ActionItemEntitiyTypes = {
  RECIPIENT: 'recipient',
  OWNER: 'owner',
  ABOUT: 'about',
  DATE: 'date',
};

export const ACTION_ITEM_TYPES = {
  TASK: 'task',
  PHONE_CALL: 'phoneCallActivity',
  MEETING: 'meeting',
  EMAIL: 'email',
};

const TEMPLATE_BODY = {
  [ACTION_ITEM_TYPES.EMAIL]: 'send email',
  [ACTION_ITEM_TYPES.MEETING]: 'schedule a meeting',
  [ACTION_ITEM_TYPES.PHONE_CALL]: 'make a call',
};

export const getTextFromTemplate = async (type, entities) => {
  const transformEntity = (entity) => {
    switch (entity.type) {
      case ActionItemEntitiyTypes.OWNER:
        return new ContactEntity(
          entity.currentValues[0].text,
          entity.currentValues[0].email,
          EntityType.OWNER
        );
      case ActionItemEntitiyTypes.RECIPIENT:
        return new ContactEntity(
          entity.currentValues[0].text,
          entity.currentValues[0].email,
          EntityType.RECIPIENT
        );
      case ActionItemEntitiyTypes.DATE:
        return new DateEntity(
          formatDate(entity.currentValues[0].text, `${EXTENDED_FORMAT_WITH_TIME}`)
        );
      case ActionItemEntitiyTypes.ABOUT:
        return new QuoteEntity(entity.currentValues[0].text);
      default:
        return {};
    }
  };

  const newEntities = entities.map(transformEntity);
  const ai = new ActionItem(type, newEntities, Language.EN, true);
  const res = await ai.createStringFromTemplate();
  return res.defaultText;
};

export const getActionItemValues = (actionItem, entityType) => get(find(
  actionItem.entities, ({ type }) => type === entityType,
), 'currentValues');

const flatValues = (entities) => concat(
  map(flatten(map(entities, 'suggestions')), (suggestion) => ({
    ...suggestion,
    isSelectedValue: false,
  })),
  map(flatten(map(entities, 'currentValues')), (value) => ({
    ...value,
    isSelectedValue: true,
  })),
);

const getIsCrmUser = ({ crmId }) => crmId && crmId !== EMPTY_GUID;

const addCrmUserData = (isCrmEntityRequired) => fmap((value) => ({
  ...value,
  key: value.crmId || value.text,
  isCrmUser: getIsCrmUser(value),
  isSelectedValue: (isCrmEntityRequired && getIsCrmUser(value) && value.isSelectedValue) || (!isCrmEntityRequired && value.isSelectedValue)
}));

const dedupUsers = flow([
  sortBy('isSelectedValue'),
  reverse,
  uniqBy('text'),
]);

export const getPersonValues = (actionItem, isCrmEntityRequired, entityType) => flow([
  ffilter(({ type }) => type === entityType),
  flatValues,
  addCrmUserData(isCrmEntityRequired),
  dedupUsers,
])(actionItem.entities);

const adaptUserEntityValue = (userEntityValue) => {
  const {
    text,
    email,
    crmId,
    crmEntityType
  } = userEntityValue;
  return ({
    text,
    email,
    crmId,
    crmEntityType
  });
};

const entityTypeToAdaptation = {
  [ActionItemEntitiyTypes.ABOUT]: ({ subject }) => [{ text: subject.name }],
  [ActionItemEntitiyTypes.DATE]: ({ date: { date } }) => [{ text: formatDate(date, `${EXTENDED_FORMAT_WITH_TIME} ZZ`) }],
  [ActionItemEntitiyTypes.OWNER]: ({ owner, from }) => map(owner || from, adaptUserEntityValue),
  [ActionItemEntitiyTypes.RECIPIENT]: ({ to }) => map(to, adaptUserEntityValue),
};

const getTemplateAndEntities = (actionItem, formData) => {
  const subject = formData.subject.name;
  const { date } = formData.date || {};
  const { to } = formData;
  const isTask = actionItem.type === ACTION_ITEM_TYPES.TASK;
  const isMeeting = actionItem.type === ACTION_ITEM_TYPES.MEETING;
  const { nextAvailableEntityId } = actionItem;

  const ownerTemplateId = nextAvailableEntityId;
  let templateBody = isTask
    ? `Action item for <,${ownerTemplateId},>:`
    : `<,${ownerTemplateId}, to >${TEMPLATE_BODY[actionItem.type]}`;
  const entities = [{
    type: ActionItemEntitiyTypes.OWNER,
    templateId: ownerTemplateId,
    currentValues: entityTypeToAdaptation[ActionItemEntitiyTypes.OWNER](formData),
  }];
  if (!isEmpty(to)) {
    const recipientTemplateId = nextAvailableEntityId + 1;
    templateBody += `< ${isMeeting ? 'with' : 'to'} ,${recipientTemplateId},>`;
    entities.push({
      type: ActionItemEntitiyTypes.RECIPIENT,
      templateId: recipientTemplateId,
      currentValues: entityTypeToAdaptation[ActionItemEntitiyTypes.RECIPIENT](formData),
    });
  }
  if (!isEmpty(subject)) {
    const subjectTemplateId = nextAvailableEntityId + 2;
    templateBody += `< about ,${subjectTemplateId},>`;
    entities.push({
      type: ActionItemEntitiyTypes.ABOUT,
      templateId: subjectTemplateId,
      currentValues: entityTypeToAdaptation[ActionItemEntitiyTypes.ABOUT](formData),
    });
  }
  if (date) {
    const dateTemplateId = nextAvailableEntityId + 3;
    templateBody += `< on ,${dateTemplateId},>`;
    entities.push({
      type: ActionItemEntitiyTypes.DATE,
      templateId: dateTemplateId,
      currentValues: entityTypeToAdaptation[ActionItemEntitiyTypes.DATE](formData),
    });
  }

  return { template: templateBody, entities };
};

export const getPreparedActionItemForCommit = (actionItem, formData) => ({
  type: actionItem.type,
  template: getTemplateAndEntities(actionItem, formData).template,
  entities: getTemplateAndEntities(actionItem, formData).entities,
});

const getConvertedToTaskSubject = (actionItem, formData) => {
  const subject = formData.subject.name;
  const { to } = formData;
  let templateBody = TEMPLATE_BODY[actionItem.type];
  if (!isEmpty(to)) {
    templateBody += ` to ${join(map(to, 'text'), ', ')}`;
  }
  if (!isEmpty(subject)) {
    templateBody += ` about ${subject}`;
  }
  return templateBody;
};

const getTemplateAndEntitiesForTask = (actionItem, formData) => {
  const { nextAvailableEntityId } = actionItem;
  const date = get(formData, 'date.date');

  let templateBody = `<Action item for ,${nextAvailableEntityId + 1},: ><",${nextAvailableEntityId + 2},">`;
  const entities = [{
    type: ActionItemEntitiyTypes.OWNER,
    templateId: nextAvailableEntityId + 1,
    currentValues: [{
      text: get(formData, 'owner.0.text') || get(formData, 'from.0.text'),
      crmId: get(formData, 'owner.0.crmId') || get(formData, 'from.0.crmId'),
      crmEntityType: get(formData, 'owner.0.crmEntityType') || get(formData, 'from.0.crmEntityType'),
      email: get(formData, 'owner.0.email') || get(formData, 'from.0.email'),
    }],
  }, {
    type: ActionItemEntitiyTypes.ABOUT,
    templateId: nextAvailableEntityId + 2,
    currentValues: [{
      text: getConvertedToTaskSubject(actionItem, formData),
    }],
  }];

  if (date) {
    const dateTemplateId = nextAvailableEntityId + 3;
    templateBody += `< on ,${dateTemplateId},>`;
    entities.push({
      type: ActionItemEntitiyTypes.DATE,
      templateId: dateTemplateId,
      currentValues: entityTypeToAdaptation[ActionItemEntitiyTypes.DATE](formData),
    });
  }

  return { template: templateBody, entities };
};

export const getTaskConvertedActionItemForCommit = (actionItem, formData) => ({
  type: ACTION_ITEM_TYPES.TASK,
  template: getTemplateAndEntitiesForTask(actionItem, formData).template,
  entities: getTemplateAndEntitiesForTask(actionItem, formData).entities,
});

export const downloadMeetingEvent = (formData) => {
  const { subject: { name } } = formData;
  const icsContent = generateICSEvent(formData);
  download(`${name}.ics`, icsContent);
};

export const getActionOrDefault = (predicate, action) => predicate ? [action] : [];
