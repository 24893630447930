/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { getIsCustomerServiceMode } from 'utils/app-context/app-context';
import { EMPTY_GUID, callService, CRM_ENTITY_TYPES, useToggler, Tooltip } from 'ci-common-ui';
import { filter, get, map, isEqual } from 'lodash';
import DataCacheService from 'services/DataCacheService';
import { trackEvent, eventAction } from '../../../../services/telemetry-service/telemetry-service';
import { Section, EditIcon, RelatedRecordsTitle, ErrorMessage } from './StyledEditableCrmEntitiesLinks';
import CrmEntitiesLinks from '../../../../components/CrmEntitiesLinks/CrmEntitiesLinks';
import SearchEntity from './SearchEntity/SearchEntity';
import useLocale from '../../../../hooks/use-locale';
import { TRACK_IDS } from '../../../../constants/tracking';
import { Link } from '../../../../components/Link/Link';

const { relateEntityToCall, removeEntityFromCall, createNewEntityAndRelateToCall } = callService;
const emptyEntity = { id: EMPTY_GUID, name: null };
const ACTIVITY_RELATED_TO_ANOTHER_CONVERSATION = 'ACTIVITY_RELATED_TO_ANOTHER_CONVERSATION';
const typesToSearch = [CRM_ENTITY_TYPES.ACCOUNT, CRM_ENTITY_TYPES.OPPORTUNITY, CRM_ENTITY_TYPES.LEAD, CRM_ENTITY_TYPES.PHONECALL_ACTIVITY, CRM_ENTITY_TYPES.APPOINTMENT_ACTIVITY];

export default ({ conversationId, data, callSubject, isEditable }) => {
  const [isEditMode, toggleIsEditMode] = useToggler(false);
  const [crmEntites, setCrmEntites] = useState(data || {});
  const [errorMessage, setErrorMessage] = useState(null);
  const prevEntites = useRef({});
  const { t, tAbs } = useLocale('pages.call.sidebar.related_entity');
  const isEditRelatedEntitiesEnabled = isEditable && !getIsCustomerServiceMode();
  const getIsEmpty = (id) => !id || id === EMPTY_GUID;
  const alreadyRelatedTypes = filter(map(crmEntites, (val, type) => ((!getIsEmpty(get(val, '[0].id'))) || !getIsEmpty(get(val, 'id'))) ? type : null));
  const isActivity = (entityType) => [CRM_ENTITY_TYPES.PHONECALL_ACTIVITY, CRM_ENTITY_TYPES.APPOINTMENT_ACTIVITY].includes(entityType);
  const toggleIsEditModeWrapper = () => {
    setErrorMessage(null);
    toggleIsEditMode();
  };

  useEffect(() => {
    // if prev !== current, it means we haven't reverted current to become prev which means we are not error state, hence error message is not relevant anymore
    if (!isEqual(prevEntites.current, crmEntites)) {
      setErrorMessage(null);
    }
  }, [crmEntites]);

  const onError = (e, isAddingEntity) => {
    setCrmEntites(prevEntites.current);
    const errorType = get(e, 'response.data.ErrorType');
    setErrorMessage(!errorType || errorType !== ACTIVITY_RELATED_TO_ANOTHER_CONVERSATION ? t(`${isAddingEntity ? 'add' : 'remove'}_error`) : get(e, 'response.data.Message'));
  };

  const updateLocalEntitiesStateAndClearCache = (name, entityId, entityType, entitiesFromExistingActivity, isCreatingNewPhonecall = false) => {
    prevEntites.current = crmEntites;
    const entityToUpdate = { name, id: entityId };

    if (isActivity(entityType) && !isCreatingNewPhonecall) {
      if (getIsEmpty(entityId)) {
        // Removing activity
        setCrmEntites({
          [CRM_ENTITY_TYPES.CONTACT]: [emptyEntity],
          [CRM_ENTITY_TYPES.ACCOUNT]: [emptyEntity],
          [CRM_ENTITY_TYPES.OPPORTUNITY]: emptyEntity,
          [CRM_ENTITY_TYPES.LEAD]: emptyEntity,
          [CRM_ENTITY_TYPES.PHONECALL_ACTIVITY]: emptyEntity // when no activiy is related type is phonecall by default
        });
      } else {
        // Relating existing activity
        const { account, contact, lead, opportunity } = entitiesFromExistingActivity || {};
        setCrmEntites({
          [CRM_ENTITY_TYPES.CONTACT]: [contact || emptyEntity],
          [CRM_ENTITY_TYPES.ACCOUNT]: [account || emptyEntity],
          [CRM_ENTITY_TYPES.OPPORTUNITY]: opportunity || emptyEntity,
          [CRM_ENTITY_TYPES.LEAD]: lead || emptyEntity,
          [entityType === 'appointment_activity' ? CRM_ENTITY_TYPES.APPOINTMENT_ACTIVITY : CRM_ENTITY_TYPES.PHONECALL_ACTIVITY]: entityToUpdate
        });
      }
    } else {
      setCrmEntites({
        ...crmEntites,
        [entityType]: Array.isArray(crmEntites[entityType]) ? [entityToUpdate] : entityToUpdate
      });
    }

    // Removing cache so if user leaves this page and returns back, they will see the correct data from Db.
    DataCacheService.removeAll();
    trackEntityChangeEvent(entityId, entityType, isCreatingNewPhonecall);
  };

  const relateEntity = async ({ entityType, entityId, name, relatedEntities }) => {
    updateLocalEntitiesStateAndClearCache(name, entityId, entityType, relatedEntities);
    relateEntityToCall(conversationId, entityType, entityId, name)
      .catch((e) => onError(e, true));
  };

  const removeEntity = (entityType, entityId) => {
    updateLocalEntitiesStateAndClearCache(null, null, entityType);
    removeEntityFromCall(conversationId, entityId)
      .catch((e) => onError(e, false));
  };

  const createNewPhonecallAndRelateExistingCola = async () => {
    // Passing callSubject for the phonecall name, because this is how the server is naming it. if the server changes the naming logics - we'll have to change here as well.
    const newPhoneCallName = callSubject;
    const newlyCreatedPhonecallId = await createNewEntityAndRelateToCall(conversationId, CRM_ENTITY_TYPES.PHONECALL_ACTIVITY, newPhoneCallName);
    const CREATING_NEW_PHONECALL = true;
    updateLocalEntitiesStateAndClearCache(newPhoneCallName, newlyCreatedPhonecallId, CRM_ENTITY_TYPES.PHONECALL_ACTIVITY, null, CREATING_NEW_PHONECALL);
    return newlyCreatedPhonecallId;
  };

  return (
    <Section isEditMode={isEditMode}>
      <RelatedRecordsTitle isEditMode={isEditMode}>
        {tAbs('common.related_records')}
        {isEditRelatedEntitiesEnabled && (
          isEditMode
            ? <Link onClick={toggleIsEditModeWrapper}>{t('exit_edit_mode')}</Link>
            : (
              <Tooltip content={tAbs('common.edit')}>
                <EditIcon onClick={toggleIsEditModeWrapper} />
              </Tooltip>
            ))}
      </RelatedRecordsTitle>
      {isEditMode && (
        <SearchEntity
          conversationId={conversationId}
          alreadyRelatedTypes={alreadyRelatedTypes}
          relateEntity={relateEntity}
          createNewPhonecallAndRelateExistingCola={createNewPhonecallAndRelateExistingCola}
          types={typesToSearch.join(',')}
        />
      )}
      <CrmEntitiesLinks
        data={crmEntites}
        isEditMode={isEditMode}
        removeEntity={removeEntity}
        isNewSchema
        hideContact
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Section>
  );
};

function trackEntityChangeEvent(entityId, entityType, isCreatingNewPhonecall) {
  trackEvent({
    action: eventAction.click,
    actionOn: entityId ? TRACK_IDS.ADDING_CRM_RELATED_ENTITY : TRACK_IDS.REMOVING_CRM_RELATED_ENTITY,
    message: { entityId, entityType, isCreatingNewPhonecall }
  });
}
