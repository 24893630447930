import { PrimaryButton } from '@fluentui/react';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';
import { FileDrop } from 'react-file-drop';
import React, { useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import map from 'lodash/map';
import { Table } from '../../../../../Table';
import useLocale from '../../../../../../hooks/use-locale';
import { iconColumn } from '../../../../../Table/default-columns';
import { fileDropStyles, DragTitle, FileConstraints, HiddenFileInput, UploadContainer, UploadIcon } from './StyledFilesPanel';
import { PhoneIconCellContainer, TitleCell, LanguageCell, ErrorCell, DeleteCallCell } from './TableCells';
import { validateAudioFile, getFileNameWithoutExtension, ACCEPT_FORMAT } from './utils';

const setColumns = (selectedSeller, translationPrefix) => [{
  ...iconColumn,
  CellComponent: PhoneIconCellContainer,
}, {
  key: 'name',
  name: `${translationPrefix}.files_panel.selected_calls_title`,
  nameProps: { filesCount: size(selectedSeller.files) },
  fieldName: 'name',
  CellComponent: TitleCell,
}, {
  key: 'language',
  name: 'language',
  minWidth: 180,
  CellComponent: LanguageCell,
}, {
  key: 'status',
  name: '',
  minWidth: 40,
  CellComponent: ErrorCell,
}, {
  key: 'delete',
  name: '',
  CellComponent: DeleteCallCell,
  minWidth: 40,
}];

export default ({
  translationPrefix,
  selectedSeller,
  onFilesAdded,
  onFileDeleteClicked,
  onEditCallName,
  onChangeFileLanguage,
}) => {
  const { t } = useLocale(`${translationPrefix}.files_panel`);
  const [isDragActive, setIsDragActive] = useState(false);
  const fileInputRef = useRef();
  useDropzone();
  const saveFiles = async (files) => {
    setIsDragActive(false);

    const allFiles = await Promise.all(Array.from(files).map(async (file) => {
      const error = await validateAudioFile(file);
      return {
        file,
        name: file.name,
        callName: getFileNameWithoutExtension(file),
        language: selectedSeller.defaultCallLanguage,
        error,
      };
    }));

    onFilesAdded(allFiles);
  };

  const files = map(selectedSeller.files, (file) => ({
    ...file,
    deleteAction: () => onFileDeleteClicked(file),
    editCallNameAction: (name) => onEditCallName(file, name),
    changeCallLanguage: (newLanguage) => onChangeFileLanguage(file, newLanguage),
    translationPrefix,
  }));

  const columns = setColumns(selectedSeller, translationPrefix);

  return (
    <FileDrop
      style={fileDropStyles}
      onFrameDragEnter={() => setIsDragActive(true)}
      onFrameDragLeave={() => setIsDragActive(false)}
      onDrop={saveFiles}
    >
      <UploadContainer>
        <HiddenFileInput
          ref={fileInputRef}
          accept={ACCEPT_FORMAT.join(',')}
          onChange={() => {
            saveFiles(fileInputRef.current.files);
          }}
        />
        {
          isDragActive
            ? (
              <>
                <UploadIcon />
                <DragTitle>{t('drag_title_drag_active')}</DragTitle>
              </>
            )
            : (
              <>
                <PrimaryButton text={t('choose_files')} onClick={() => fileInputRef.current.click()} />
                <DragTitle>{t('drag_title')}</DragTitle>
                <FileConstraints>{t('file_constraints')}</FileConstraints>
              </>
            )
        }
      </UploadContainer>

      {!isEmpty(files) && <Table items={files} columns={columns} />}

    </FileDrop>
  );
};
