export enum EntityType {
    OWNER = "owner",
    RECIPIENT = "recipient",
    SUBJECT = "subject",
    NOUN = "noun",
    DATE = "date",
    QUOTE = "quote"
}

export enum Language {
    EN = "en",
    FR = "fr"
}

export enum AIType {
    TASK = "task",
    EMAIL = "email",
    MEETING = "meeting",
    PHONE_CALL_ACTIVITY = "phonecallactivity"
}