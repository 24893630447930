export default ({ onClick }) => ({
  plotOptions: {
    series: {
      cursor: 'pointer',
      events: {
        click(event) {
          onClick(event);
        }
      }
    }
  }
});
