import React, { useRef, useEffect } from 'react';
import useLocale from 'hooks/use-locale';
import { flatten, groupBy, map } from 'lodash';
import { CRM_ENTITY_TYPES, LEAD_AND_OPPORTUNITY, callService, useToggler, callComponents, callFilters, Tooltip } from 'ci-common-ui';
import { Container, Content, Title, IconWrapper, ChevronIconDown } from './StyledSearchEntity';

const { CrmRecordLookup } = callComponents;
const { transformFromLookupSchema, transformToLookupSchema } = callFilters;

const { getAllColaEntitiesOptions } = callService;
const typesAllowedToBeCreated = [CRM_ENTITY_TYPES.PHONECALL_ACTIVITY];

const SearchEntity = ({ alreadyRelatedTypes, relateEntity, createNewPhonecallAndRelateExistingCola, types = null }) => {
  const { t, tAbs } = useLocale('pages.call.sidebar.related_entity');
  const [showLookUp, toggleShowLookup] = useToggler(false);
  const lookupTargetRef = useRef();

  useEffect(() => { setTimeout(() => toggleShowLookup(true), 200); }, []);

  const isFieldAlreadyRelated = (type) => alreadyRelatedTypes.includes(type) || (LEAD_AND_OPPORTUNITY.includes(type) && LEAD_AND_OPPORTUNITY.some((entityType) => alreadyRelatedTypes.includes(entityType)));
  const typesToCreate = map(typesAllowedToBeCreated, (type) => ({
    type,
    disabled: isFieldAlreadyRelated(type),
    disabledTooltipContent: t('cant_create_already_related', { type: tAbs(`common.crm_entity_types.${type}`) })
  }));
  const getOptions = async ({ searchTerm: text }) => {
    const allCola = await getAllColaEntitiesOptions(text, types);
    const colaGroupedByType = groupBy(allCola, 'entityType');
    const arraysPerType = map(colaGroupedByType, (allColaOfType, type) => map(allColaOfType,
      (data) => ({
        ...transformToLookupSchema(data),
        ...(isFieldAlreadyRelated(type) && {
          disabled: true,
          disabledTooltipContent: t(`already_related_msg${LEAD_AND_OPPORTUNITY.includes(type) ? '_lead_and_opp' : ''}`, { type: tAbs(`common.crm_entity_types.${type}`).toLowerCase() })
        })
      })));

    return flatten(arraysPerType);
  };

  const createPhonecallAndCloseLookup = async () => {
    const id = await createNewPhonecallAndRelateExistingCola();
    toggleShowLookup(false);
    return id;
  };

  return (
    <Container>
      <Content>
        <Title>
          <Tooltip content={t('subtitle')}>
            {t('title')}
          </Tooltip>
        </Title>
        <IconWrapper onClick={toggleShowLookup} ref={lookupTargetRef}>
          <ChevronIconDown isUp={!showLookUp} />
        </IconWrapper>

        {showLookUp && (
          <CrmRecordLookup
            targetRef={lookupTargetRef}
            onRecordChosen={(data) => { toggleShowLookup(false); relateEntity(transformFromLookupSchema(data)); }}
            createNewPhonecallAndRelateExistingCola={createPhonecallAndCloseLookup}
            onDismiss={() => toggleShowLookup(false)}
            getSearchResults={getOptions}
            searchTermMinLength={0}
            typesAllowedToBeCreated={typesToCreate}
          />
        )}
      </Content>
    </Container>
  );
};

export default SearchEntity;
