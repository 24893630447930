import React from 'react';
import styled, { css } from 'styled-components';
import { Icon, IconButton, Persona } from '@fluentui/react';
import { rtl } from '../../utils/styles/rtl';
import { FlexColumn, FlexRow, FlexRowCentered } from '../../utils/styles/StyledUtils';
import { colors } from '../../constants/colors';
import { deviceSizes } from '../../utils/styles/device-sizes';
import { dataAutomationParticipantId } from '../../constants/selectors';

const MARGIN = '6px';
const MAIN_CONTENT_HEIGHT_OFFSET = '70px';
const PLAYER_HEIGHT_WITHOUT_TIMELINE = '120px';
const PLAYER_HEIGHT_SMALL = '96px';
const STATUS_BAR_HEIGHT = '32px';
const HEIGHT_TRANSITION = 'height 0.3s ease';
const TRANSCRIPT_WIDTH_BIG = '480px';
const TRANSCRIPT_WIDTH_SMALL = '320px';
export const AUDIO_PLAYER_SMALL_BREAK_POINT_WIDTH = 1024;

export const CallPageContainer = styled(FlexColumn)`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  flex: 1;
  overflow: hidden;
  max-width: ${({ theme }) => theme.sizes.maxWidthCallPage};
  background-color: ${({ theme }) => theme.colors.Gray10};
  white-space: break-spaces;

  ${deviceSizes.bigMobile`
    display: block;
    overflow: auto;
  `}
  `;

export const MainContent = styled.div`
  height: ${({ shouldHideMainHeader }) => shouldHideMainHeader ? '100%' : `calc(100% - ${MAIN_CONTENT_HEIGHT_OFFSET})`};
  overflow-y: hidden;
  padding: ${({ theme }) => theme.sizes.paddingMainCallPage};
  min-height: 300px;
  
  ${deviceSizes.bigMobile`
    overflow: auto;
    display: flex;
    flex-direction: column;
  `}
  `;

export const ContentContainer = styled(FlexRow)`
    ${({ timelinesHeight }) => `
    height: calc(100% - ${PLAYER_HEIGHT_WITHOUT_TIMELINE} - ${timelinesHeight}px - ${MARGIN});
  `}

  ${({ isStatusBarDisplayed, isInfoMessageBarDisplayed, timelinesHeight }) => (isStatusBarDisplayed || isInfoMessageBarDisplayed) && `
    height: calc(100% - ${PLAYER_HEIGHT_WITHOUT_TIMELINE} - ${timelinesHeight}px - ${MARGIN} - ${STATUS_BAR_HEIGHT});
  `}
  
  ${({ isStatusBarDisplayed, isInfoMessageBarDisplayed, timelinesHeight }) => (isStatusBarDisplayed && isInfoMessageBarDisplayed) && `
    height: calc(100% - ${PLAYER_HEIGHT_WITHOUT_TIMELINE} - ${timelinesHeight}px - ${MARGIN} - ${STATUS_BAR_HEIGHT} - ${STATUS_BAR_HEIGHT});
  `}
  
  transition: ${HEIGHT_TRANSITION};
  
  ${({ isSmallPlayer, isStatusBarDisplayed, isInfoMessageBarDisplayed }) => isSmallPlayer && `
    height: calc(100% - ${PLAYER_HEIGHT_SMALL} - ${MARGIN});
    
    ${isStatusBarDisplayed || isInfoMessageBarDisplayed ? `
      height: calc(100% - ${PLAYER_HEIGHT_SMALL} - ${MARGIN} - ${STATUS_BAR_HEIGHT});
    `: '' }
  
    ${isStatusBarDisplayed && isInfoMessageBarDisplayed ? `
      height: calc(100% - ${PLAYER_HEIGHT_SMALL} - ${MARGIN} - ${STATUS_BAR_HEIGHT} - ${STATUS_BAR_HEIGHT});
    `: '' }
  `}
  
  ${deviceSizes.bigMobile`
    display: block;
    height: auto;
    order: 2;
  `}
`;

export const SidebarContainer = styled.div`
  flex: 1;
  ${rtl`margin-right`}: ${MARGIN};
  
  ${deviceSizes.bigMobile`
    height: 600px;
    width: 100%;
    margin-right: 0px;
  `}
`;

export const TranscriptPanelContainer = styled.div`
  width: ${TRANSCRIPT_WIDTH_BIG};

  ${deviceSizes.tablet`
    width: ${TRANSCRIPT_WIDTH_SMALL}
  `}

  ${deviceSizes.bigMobile`
    height: 600px;
    width: 100%;
    margin-top: 20px;
  `}
`;

export const PlayerContainer = styled(FlexColumn)`
  position: relative;
  box-sizing: border-box;
  margin-top: ${MARGIN};
  background-color: ${({ theme }) => theme.colors.White};
  border: ${({ theme }) => `1px solid ${ theme.colors.Gray30 }`};
  box-shadow: rgba(0, 0, 0, 0.067) 0px 1px 4px;
  border-radius: 2px;
  ${rtl`padding-right`}: 40px;
  width: 100%;
  transition: ${HEIGHT_TRANSITION};

  ${({ timelinesHeight }) => `
  height: calc(${PLAYER_HEIGHT_WITHOUT_TIMELINE} + ${timelinesHeight}px) ;
  `}
  ${({ isSmallPlayer }) => isSmallPlayer && css`
    height: ${PLAYER_HEIGHT_SMALL};
    
    ${rtl`padding-left`}: 20px;
  `}
  
  ${deviceSizes.bigMobile`
    flex: 0 0 38px;
    position: relative;
    order: 1;
    margin-top: 20px;
  `}
`;

export const TogglePlayerSize = styled.div`
  position: absolute;
  ${rtl`right`}: 0;
  z-index: 2;
  padding: 4px;
`;

export const TogglePlayerSizeIcon = styled(IconButton).attrs(({ isSmallPlayer }) => ({
      iconProps: { iconName: isSmallPlayer ? 'FullScreen' : 'BackToWindow' },
  }))`
  background: ${({ theme }) => theme.colors.White};
  height: 32px;
  width: 32px;

  i {
    font-weight: bold;
    margin-top: 2px;
  }
`;

export const MainCallPanel = styled.div`
  flex: 1;
  overflow: auto;
`;

export const MessageBarContent = styled(FlexRowCentered)`
  font-size: 12px;
  height: ${STATUS_BAR_HEIGHT};
  justify-content: flex-start;
  background-color: ${colors.DYNTint50};
  padding: 2px 12px;
  >.ms-Spinner {
    ${rtl`margin-right`}: 8px;
  }
`;

// In the future, use AvatarPersona that accepts aadid and fetches avatar from Graph
export const ParticipantPersona = styled(Persona).attrs(({ isUnknown, participantId, imageUrl, text}) => ({
  onRenderInitials: isUnknown ? () => <Icon iconName="UnknownCall" /> : undefined,
  [dataAutomationParticipantId]: participantId,
  imageUrl,
  imageAlt : text,
}))`
  ${({ isUnknown }) => isUnknown && `
    .ms-Persona-initials {
      color: ${colors.Gray130};
      background-color: ${colors.Gray20};
    }

    i {
      font-weight: 400;
    }
  `};
`;