import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { AppError } from '../AppError';

// eslint-disable-next-line no-console
const logError = (error, errorInfo) => console.error(error, errorInfo);

export const ErrorBoundary = ({ fallback, onError, children }) => (
  <ReactErrorBoundary
    fallback={fallback || <AppError title="Oops... something happened" />}
    onError={onError || logError}
  >
    {children}
  </ReactErrorBoundary>
);
