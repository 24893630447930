import styled from 'styled-components';
import { FlexColumnCentered, StyledText } from '../../../../utils/styles/StyledUtils';
import LoadingSVG from '../../../../assets/images/preparing.svg';
import { colors } from '../../../../constants/colors';

export const Container = styled(FlexColumnCentered)`
  margin-top: 120px;  
  text-align: center;
`;

export const Icon = styled(LoadingSVG)`
  height: 100px;
  width: 100px;
`;

export const Title = styled(StyledText)`
  font-size: 18px;
  margin-top: 28px;
`;

export const Subtitle = styled(StyledText)`
  color: ${colors.Gray130};
  margin-top: 20px;
`;
