import React from 'react';
import { Chart as CommonChart, chartConfigs } from 'ci-common-ui';
import { trackEvent } from '../../services/telemetry-service/telemetry-service';
import { eventAction } from '../../services/telemetry-service/consts';
import { joinTrackDelimiter } from '../../utils/string/string';
import { TRACK_IDS } from '../../constants/tracking';
import commonConfig from './commonConfig';
import { dayJS } from '../../utils/date/date';

const { combineConfigs } = chartConfigs;

const MIN_VISIT_CHART_DURATION = 5;

const Chart = ({ config, trackId, keepRefreshing }) => {
  let mouseEnterTime;

  const onMouseEnter = () => {
    mouseEnterTime = dayJS();
  };

  const onMouseLeave = () => {
    const visitDuration = dayJS().diff(mouseEnterTime, 'seconds');
    if (visitDuration >= MIN_VISIT_CHART_DURATION) {
      trackEvent({ action: eventAction.hover, actionOn: joinTrackDelimiter([trackId, TRACK_IDS.COMMON.CHART_VISIT]), message: visitDuration });
    }
  };

  const finalConfig = combineConfigs({ trackId }, [config, commonConfig]);

  return <CommonChart config={finalConfig} keepRefreshing={keepRefreshing} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />;
};
export default Chart;
