import React, { useLayoutEffect, useRef, useState } from 'react';
import { useId } from '@fluentui/react-hooks';
import { useToggler } from '../../../hooks/use-toggler';
import { TextAndControlsContainer, Container, Text, Chevron as StyledChevron, ControlsContainer, getTextStyles/* , TeachingBubble */ } from './StyledRecordingSection';
import SelectLanguage from '../SelectLanguage/SelectLanguage';
import { FlexRow } from '../../../utils/styles/StyledUtils';
import { useGetContent } from './utils';
import { getLocalStorageItemOrDefault, storageService } from '../../../services/storage-service/storage-service';
import { TeachingBubble, TEACHING_BUBBLE_CLOSING_TRIGGER } from '../../TeachingBubble';

const HIDE_RECORDING_SECTION_TEACHING_BUBBLE = 'HIDE_RECORDING_SECTION_TEACHING_BUBBLE';

const RecordingSection = ({
  isOnlyAgentsRecordingCall,
  isRecordingButtonHidden,
  isRecordingStatusOnProcess,
  isRecordingReady,
  recordingStatus,
  recordingStatusType,
  startRecording,
  resumeRecording,
  pauseRecording,
  stopRecording,
  recordButtonTexts: texts,
  isRecordingButtonDisabled,
  isUserConsentButtonHidden,
  recordingTarget,
  updateRecordingTarget,
  showLanguageSelection,
  language,
  languageOptions,
  onLanguageChanged,
  isLanguageDropdownDisabled,
  setRecordingSectionHeight,
  trackTeachingBubbleEvent,
  isAutoRecording,
  isRenderedOnTeams = false,
  isBeforeStartRecordingError
}) => {
  const [isTextHidden, toggleTextHidden] = useToggler(false);
  const recordingSectionRef = useRef();
  const recordingSectionId = useId('recordingSection');
  const [shouldShowTeachingBubble, setShouldShowTeachingBubble] = useState(!isAutoRecording && !getLocalStorageItemOrDefault(HIDE_RECORDING_SECTION_TEACHING_BUBBLE, true));
  const teachingBubbleTarget = `#${recordingSectionId}`;

  const onStartRecordingClicked = () => {
    startRecording();
    setShouldShowTeachingBubble(false);
    storageService.localStorage.setItem(HIDE_RECORDING_SECTION_TEACHING_BUBBLE, true);
  };

  const onTeachingBubbleDismiss = (closingTrigger) => {
    trackTeachingBubbleEvent(closingTrigger);
    setShouldShowTeachingBubble(false);
    if (closingTrigger === TEACHING_BUBBLE_CLOSING_TRIGGER.GOT_IT_BUTTON) {
      storageService.localStorage.setItem(HIDE_RECORDING_SECTION_TEACHING_BUBBLE, true);
    }
  };

  const { text, ActionComponent } = useGetContent({
    onStartRecordingClicked,
    resumeRecording,
    pauseRecording,
    stopRecording,
    isOnlyAgentsRecordingCall,
    isRecordingButtonDisabled,
    isRecordingStatusOnProcess,
    isRecordingReady,
    recordingStatus,
    recordingStatusType,
    recordingTarget,
    updateRecordingTarget,
    isRecordingButtonHidden,
    texts,
    isUserConsentButtonHidden,
  });

  useLayoutEffect(() => {
    if (setRecordingSectionHeight) {
      setRecordingSectionHeight(recordingSectionRef?.current?.clientHeight);
    }
  }, [text, isTextHidden]);

  if (!ActionComponent && !showLanguageSelection) {
    return null;
  }

  const isExpanded = !isTextHidden;

  return (
    <>
      <Container id={recordingSectionId} ref={recordingSectionRef} isRenderedOnTeams={isRenderedOnTeams}>
        <TextAndControlsContainer>
          <Text aria-live="polite" styles={getTextStyles(isTextHidden)} multiline>{isBeforeStartRecordingError ? '' : text}</Text>
          <StyledChevron onClick={toggleTextHidden} isExpanded={isExpanded} iconProps={{ iconName: 'DoubleChevronUp' }} aria-label={isExpanded ? 'Collapse' : 'Expand'}/>
        </TextAndControlsContainer>
        <ControlsContainer>
          <FlexRow>
            {ActionComponent}
            {showLanguageSelection && <SelectLanguage value={language} options={languageOptions} onChange={onLanguageChanged} isDisabled={isLanguageDropdownDisabled || isRecordingButtonDisabled} />}
          </FlexRow>
        </ControlsContainer>
      </Container>
      {shouldShowTeachingBubble && (
      <TeachingBubble
        target={teachingBubbleTarget}
        onTeachingBubbleDismiss={onTeachingBubbleDismiss}
        texts={texts}
        isWide
      />
      )}
    </>
  );
};

export { RecordingSection };
