import React from 'react';
import { useInfra } from '../../../../../../providers/InfraProvider';
import { Disclaimer, Container, LearnMoreLink } from './StyledHighlightsCallDurationEmptyState';
import { CALL_STRINGS } from '../../../../strings';

export function HighlightsCallDurationEmptyState() {
    const { t, trackEvent, highlightsInternalMeetingLearnMoreLink } = useInfra();

    return (
        <Container>
            <Disclaimer
                data-testid="highlights-call-duration-empty-state">
                {`${t(CALL_STRINGS.EXEC_SUMMARY_CALL_DURATION_HIGHLIGHTS_DISCLAIMER)} `}
                <LearnMoreLink
                    href={highlightsInternalMeetingLearnMoreLink}
                    target="_blank"
                    trackEvent={trackEvent}>
                    {t(CALL_STRINGS.LEARN_MORE)}
                </LearnMoreLink>
            </Disclaimer>
        </Container>
    );
}
