import { IconButton } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useInfra } from '../../../../../../providers/InfraProvider';
import { CALL_STRINGS } from '../../../../strings';
import { SuggestionGroup, SUGGESTION_GROUP_TYPES } from '../SuggestionGroup';
import {
  Container,
  TitleContainer,
  SuggestionGroups,
  closeIconStyles,
  Title,
  PANEL_ANIMATION_MS,
  SuggestionPanelHeaderContainer,
  SuggestionHeader,
} from './StyledSuggestionPanel';
import { FlexRowCentered } from '../../../../../../utils/styles/StyledUtils';
import { ButtonWithToast } from '../../../../../../components/ButtonWithToast/ButtonWithToast';
import { Tooltip } from '../../../../../../components/Tooltip/Tooltip';
import { ScalableCopyButton } from './ScalableCopyButton';
import { VIVA_MEETING_SUMMARY_LEARN_MORE_LINK } from "../../../../../../constants/links";
import { NoDataErrorWithImage } from '../../../../../../components/NoDataError';

export const SuggestionPanel = ({
  isInternalMeeting,
  isEmptySuggestions,
  insightsProcessingStatus,
  suggestions,
  actionItems,
  partnerHighlights,
  onClose,
  onAddClicked,
  onSelectSuggestion,
  onSelectActionItem,
  onEdit,
  isSuggestionsOpened,
  isFullSize,
  onCopySummaryToClipboard,
}) => {
  const { t, isViva } = useInfra();
  const [isContentDisplayed, setIsContentDisplayed] = useState(isSuggestionsOpened);

  const isCopyButtonDisabled = !actionItems?.length && !suggestions?.length && !partnerHighlights?.length;

  useEffect(() => {
    setTimeout(() => setIsContentDisplayed(isSuggestionsOpened), PANEL_ANIMATION_MS);
  }, [isSuggestionsOpened]);

  return (
    <Container
      isSuggestionsOpened={isSuggestionsOpened}
      isFullSize={isFullSize}
      data-testid="suggestion-panel-container"
    >
      {isFullSize && (
        <SuggestionPanelHeaderContainer>
          <SuggestionHeader>
            {!isEmptySuggestions && (
              <span>{t(CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_HEADER)}</span>
            )}
          </SuggestionHeader>
          <Tooltip content={t(CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_COPY_BUTTON_TOOLTIP)}>
            <ButtonWithToast
              button={
                <ScalableCopyButton
                  onCopySummaryToClipboard={onCopySummaryToClipboard}
                  isDisabled={isCopyButtonDisabled}
                />
              }
              toastText={t(CALL_STRINGS.COPIED)}
            />
          </Tooltip>
        </SuggestionPanelHeaderContainer>
      )}
      {isContentDisplayed && (
        <>
          {!isFullSize && (
            <TitleContainer data-testid="title-container">
              <>
                <FlexRowCentered>
                  <Title multiline>{t(CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_TITLE)}</Title>
                </FlexRowCentered>
                <IconButton
                  ariaLabel="close"
                  iconProps={{ iconName: 'Cancel', styles: closeIconStyles }}
                  onClick={onClose}
                />
              </>
            </TitleContainer>
          )}
          {isEmptySuggestions ? (
            <NoDataErrorWithImage
                title={t(isViva ? CALL_STRINGS.EXEC_SUMMARY_RECAP_EMPTY_STATE_TITLE : CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_NO_SUGGESTIONS)}
                subtitle={isViva ? t(CALL_STRINGS.EXEC_SUMMARY_RECAP_EMPTY_STATE_SUBTITLE) : null}
                link={isViva ? {
                  url: VIVA_MEETING_SUMMARY_LEARN_MORE_LINK,
                  text: t('learn_more'),
                } : null}
            />
          ) : (
            <SuggestionGroups isFullSize={isFullSize} data-testid="suggestion-groups">
              <SuggestionGroup
                isInternalMeeting={isInternalMeeting}
                type={SUGGESTION_GROUP_TYPES.HIGHLIGHTS}
                suggestions={suggestions}
                title={t(CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_HIGHLIGHTS_TITLE)}
                onAddClicked={onAddClicked}
                onSelectSuggestion={onSelectSuggestion}
                onEdit={onEdit}
                infoIconText={t(CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_TITLE_TOOLTIP)}
                isFullSize={isFullSize}
                insightsProcessingStatus={insightsProcessingStatus}
              />
              <SuggestionGroup
                type={SUGGESTION_GROUP_TYPES.ACTION_ITEMS}
                suggestions={actionItems}
                title={t(CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_ACTION_ITEMS_TITLE)}
                infoIconText={t(
                  CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_ACTION_ITEMS_TITLE_TOOLTIP
                )}
                onAddClicked={onAddClicked}
                onSelectSuggestion={onSelectSuggestion}
                onSelectActionItem={onSelectActionItem}
                onEdit={onEdit}
                isFullSize={isFullSize}
              />
              {!!partnerHighlights && (
                <SuggestionGroup
                  type={SUGGESTION_GROUP_TYPES.PARTNER_HIGHLIGHTS}
                  suggestions={partnerHighlights}
                  title={t(CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_PARTNER_HIGHLIGHTS_TITLE)}
                  infoIconText={t(
                    CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_ACTION_ITEMS_TITLE_TOOLTIP
                  )}
                  onAddClicked={onAddClicked}
                  onSelectSuggestion={onSelectSuggestion}
                  onSelectActionItem={onSelectActionItem}
                  onEdit={onEdit}
                  isFullSize={isFullSize}
                />
              )}
            </SuggestionGroups>
          )}
        </>
      )}
    </Container>
  );
};
