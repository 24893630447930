import { chartConfigs, colors } from 'ci-common-ui';
import { formatPercentage, formatRound, formatKiloMillionBillion, numberWithCommas } from 'utils/numeric';

const { combineConfigs, donut, donutTitle, legendVertical } = chartConfigs;

const usedKey = 'used';
const availableKey = 'available';

export const chartConfig = ({ capacityHours, usageHours, availableHours }, tAbs, isRTL) => (
  combineConfigs(
    {
      data: [{ name: usedKey, y: (usageHours / 100) }, { name: availableKey, y: (availableHours / 100) }],
      seriesParams: [
        {
          key: usedKey,
          text: tAbs(`common.${usedKey}`),
          color: colors.DYNPrimary
        },
        {
          key: availableKey,
          text: tAbs(`common.${availableKey}`),
          color: colors.Gray110
        }
      ],
      dataLabelsFormatter: ({ percentage }) => formatPercentage(formatRound(percentage)),
      dataLabelsDistance: 10,
    },
    [
      donut,
      donutTitle,
      legendVertical,
      {
        title: {
          text: `<div title="${`${numberWithCommas(capacityHours)} ${tAbs('common.hrsPerMonth')}`}">${formatKiloMillionBillion(capacityHours)}</div>`,
          useHTML: true,
          y: 16,
          x: isRTL ? 50 : -50,
        },
        subtitle: {
          text: tAbs('common.hrsPerMonth'),
          y: 24,
          x: isRTL ? 50 : -50,
        },
        legend: {
          align: isRTL ? 'left' : 'right',
        },
      },
    ],
  )
);
