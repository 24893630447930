import React from 'react';
import { Tooltip } from 'ci-common-ui';
import { Title } from './StyledCallTopicCell';
import { useAppNavigation } from '../../../../hooks/use-app-navigation';
import { routes } from '../../../../pages/routing';
import { QUERY_PARAMS } from '../../../../constants/urls';

export const CallTopicCell = ({ data, row }) => {
  const { navigateTo } = useAppNavigation();
  const { conversationId, ConversationId, currentPage, trackCallTopicClickEvent } = row;

  const onTitleClick = () => {
    const id = conversationId || ConversationId;
    trackCallTopicClickEvent(id);
    navigateTo({ params: { [QUERY_PARAMS.SCROLL_TO]: id, [QUERY_PARAMS.PAGE_NUMBER]: currentPage } });
    navigateTo({ path: `${routes.call.link}/${id}`, params: { [QUERY_PARAMS.SCROLL_TO]: null, [QUERY_PARAMS.PAGE_NUMBER]: null } });
  };

  return (
    <Tooltip content={data} styles={{ root: { minWidth: 0 } }}>
      <Title role="link" onClick={onTitleClick}>{data}</Title>
    </Tooltip>
  );
};
