import { useLocation } from 'react-router';
import { useEffect } from 'react';
import { usePrevious } from 'ci-common-ui';
import { trackPage } from '../services/telemetry-service/telemetry-service';
import { dayJS } from '../utils/date/date';

const usePageViews = () => {
  const location = useLocation();
  let startTime = usePrevious(dayJS());

  useEffect(() => {
    const timeSpent = dayJS().diff(startTime, 'seconds');
    startTime = dayJS();
    trackPage(location, timeSpent);
  }, [location]);
};
export default usePageViews;
