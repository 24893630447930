/* eslint-disable no-mixed-operators */
import styled from 'styled-components';
import { Icon as FabricIcon } from '@fluentui/react';
import { colors } from '../../../../constants/colors';

const iconSize = '22px';

export const Wrapper = styled.div`
  position: relative;
  top: -16px;
  height: ${iconSize};
  transition: all .2s ease-in-out;
  :hover {
    cursor: pointer;
    transform: scale(1.2);
  }
`;

export const Icon = styled(FabricIcon).attrs({
  iconName: 'MessageFill',
  ariaLabel: 'Comments',
})`
  height: ${iconSize};
  width: ${iconSize};
  font-size: ${iconSize};
  color: ${colors.DYNPrimary};
`;

export const Count = styled.div`
  position: absolute;
  font-size: 16px;
  top: 2px;
  text-align: center;
  width: 100%;
  font-size: 10px;
  color: ${colors.White};
`;
