import styled from 'styled-components';
import { FlexRow } from '../../../../utils/styles/StyledUtils';

export const Container = styled(FlexRow)`
  align-items: center;
`;

export const dropdownStyles = {
  dropdown: {
    width: '70px',
  },
  dropdownItem: {
    justifyContent: 'center',
  },
  dropdownItemSelected: {
    justifyContent: 'center',
  },
  dropdownItemDisabled: {
    justifyContent: 'center',
  },
};

export const TotalPagesText = styled.div`
  margin: 0 10px;
`;
