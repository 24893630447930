import React, { useState } from 'react';
import { Dialog, DialogFooter, PrimaryButton, DefaultButton, Spinner, SpinnerSize } from '@fluentui/react';
import { useInfra } from '../../../../providers/InfraProvider';
import { CALL_STRINGS } from '../../strings';

export const DeleteInsightsDialog = ({ onDeleteInsightsClick, hideDialog }) => {
  const { t } = useInfra();
  const [isDeleting, setIsDeleting] = useState(false);
  const buttonStyles = { boxShadow: 'none', borderRadius: 4 };

  const dialogContentProps = {
    title: t(CALL_STRINGS.DELETE_INSIGHTS_TITLE),
    titleProps: { style: { paddingRight: 24, fontWeight: 600 }},
    style: { fontWeight: 400 },
    subText: t(CALL_STRINGS.DELETE_INSIGHTS_MESSAGE),
  };

  const onDeleteClick = async () => {
    try {
        setIsDeleting(true);
        await onDeleteInsightsClick();
    } catch {
      // Ignore error and close the dialog
    } 

    setIsDeleting(false);
    hideDialog();
  }

  return (
    <Dialog 
        hidden={false}
        dialogContentProps={dialogContentProps}
        minWidth={400}
        styles={{ main: { padding: 10, borderRadius: 8 } }}
        onDismiss={hideDialog}
    >
        <DialogFooter>
            <DefaultButton style={buttonStyles} disabled={isDeleting} onClick={hideDialog} text={t(CALL_STRINGS.CANCEL)} />
            <PrimaryButton 
                disabled={isDeleting} 
                styles={{ flexContainer: { flexDirection: 'row-reverse' }}} 
                style={buttonStyles}
                onClick={onDeleteClick} 
                text={isDeleting ? t(CALL_STRINGS.DELETING) : t(CALL_STRINGS.DELETE)} 
            >
                {isDeleting && <Spinner size={SpinnerSize.xSmall} />}
            </PrimaryButton>
        </DialogFooter>
    </Dialog>
  );
};
