import styled from 'styled-components';
import { FontSizes } from '@uifabric/fluent-theme';
import { colors, FlexRowCentered } from 'ci-common-ui';
import { ActionButton } from '@fluentui/react';

const ROW_HEIGHT = '32px';

export const StyledPhoneIconCellContainer = styled.div`
  line-height: ${ROW_HEIGHT};
  
  ${({ error }) => error && `
      i {
        color: ${colors.Gray90};
      }
  `}
`;

export const TitleCellContainer = styled.div`
  font-size: ${FontSizes.size12};
  color: ${colors.Gray130};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
  height: 100%;

  ${({ error }) => error && `
    & > div {
      color: ${colors.Gray90};
    }
  `}
`;

export const TitleCellCallName = styled(FlexRowCentered)`
  height: 16px;
  font-size: ${FontSizes.size14};
  color: ${colors.Gray160};
`;

export const TitleCellFileName = styled.div`
  font-size: ${FontSizes.size12};
  color: ${colors.Gray130};
`;

export const EditCallNameContainer = styled(FlexRowCentered)`
`;

export const EditIcon = styled(ActionButton).attrs({
  iconProps: { iconName: 'Edit' },
})`
  height: 100%;
  i {
    font-size: ${FontSizes.size14};
  }
`;

export const OkIcon = styled(ActionButton).attrs({
  iconProps: { iconName: 'CheckMark' },
})`
  height: 100%;
  i {
    color: ${colors.DynamicsGreen};
  }
`;

export const CancelIcon = styled(ActionButton).attrs({
  iconProps: { iconName: 'Cancel' },
})`
  height: 100%;
  i {
    color: ${colors.DynamicsRed};
  }
`;

export const DeleteIcon = styled(ActionButton).attrs({
  iconProps: {
    iconName: 'Cancel'
  },
})`
  height: 100%;
`;
