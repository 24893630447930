import React, { useState } from 'react';
import { CommandBarButton } from '@fluentui/react';
import { useInfra } from '../../../../providers/InfraProvider';
import { DeleteInsightsDialog } from './DeleteInsightsDialog';
import { CALL_STRINGS } from '../../strings';

export const CallPageContextMenu = ({ onDeleteInsightsClick }) => {
  const { t } = useInfra();
  const [isDialogHidden, setIsDialogHidden] = useState(true);

  const menuItems = [{ 
    key: 'delete', 
    iconProps: { iconName: 'Delete', style: { color: 'inherit' }}, 
    name: t(CALL_STRINGS.DELETE_INSIGHTS), 
    style: { padding: 0, boxShadow: 'none' },
    onClick: () => setIsDialogHidden(false)
  }];

  return (
    <>
      {!isDialogHidden && <DeleteInsightsDialog hideDialog={() => setIsDialogHidden(true)} onDeleteInsightsClick={onDeleteInsightsClick} />}
      <CommandBarButton 
        menuIconProps={{ iconName: 'More', style: { fontWeight: 800, fontSize: 16, backgroundColor: 'inherit' }}} 
        style={{ backgroundColor: 'inherit', padding: 0, maxWidth: 10 }} 
        menuProps={{ items: menuItems }}
        data-testid="more-button"
      />
    </>
  );
};
