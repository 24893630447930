import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, Trans as i18nextTrans } from 'react-i18next';
import numeral from 'numeral';
import { LOCALES_MAP } from '../../constants/locales';
import * as dateLibUtils from '../../utils/date/date';

export const i18next = i18n;
export const Trans = i18nextTrans;
export const defaultLocale = 'en';

const setExternalLibraryLocale = (locale, isLocaleImported, switchLocale, importLocale) => {
  if (locale) {
    if (isLocaleImported) {
      switchLocale(locale); // Update library to switch locale
    } else {
      try {
        importLocale(locale); // Specific locale is not yet imported, import it (required by external libraries)
        switchLocale(locale); // Update library to switch locale
      } catch (e) {
        switchLocale(defaultLocale); // Update library to switch to default language
      }
    }
  } else {
    switchLocale(defaultLocale); // Update library to switch to default language
  }
};

/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
export const setExternalLibrariesLocale = (externalLibLocale) => {
  setExternalLibraryLocale(externalLibLocale, externalLibLocale in numeral.locales, numeral.locale, (locale) => require(`numeral/locales/${locale}`));
  setExternalLibraryLocale(externalLibLocale, dateLibUtils.getIsLocaleImported(externalLibLocale), dateLibUtils.switchLocale, dateLibUtils.importLocale);
};
/* eslint-enable import/no-dynamic-require */
/* eslint-enable global-require */

export const initI18nService = (options, onTranslationFileLoadedCallback, onMissingKey) => {
  i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(HttpApi)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
    .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      lng: options.lng,
      fallbackLng: (fallbackLng) => {
        if (fallbackLng?.startsWith(defaultLocale)) {
          return false;
        }
        return defaultLocale;
      },
      debug: options.debug || false,
      detection: options.detection,
      saveMissing: true, // must be true in order for missingKeyHandler() to work
      missingKeyHandler: (lngs, ns, key, fallbackValue, updateMissing, keyOptions) => {
        if (onMissingKey && !keyOptions.returnObjects) { // ignore obj "translations" like tObj(key)
          onMissingKey(key);
        }
      },
      parseMissingKeyHandler: () => ' ',
      backend: {
        loadPath: (lng) => {
          const lang = lng.toString();
          const externalLibraryLocale = lang.indexOf('-') !== -1 ? lang.slice(0, 2).toLowerCase() : lang.toLowerCase();
          if (externalLibraryLocale !== defaultLocale) {
            setExternalLibrariesLocale(externalLibraryLocale);
          }

          const resourceDefaultKey = LOCALES_MAP[defaultLocale].key;
          const key = LOCALES_MAP[lang] && LOCALES_MAP[lang].key;
          return key && key !== resourceDefaultKey ? `${options.localesPath}/${key}/resources.json` : `${options.localesPath}/resources.json`; // default en
        },
        allowMultiLoading: true
      },
      load: 'currentOnly',
      interpolation: {
        escapeValue: false // not needed for react as it escapes by default
      },
      react: {
        useSuspense: false
      }
    }, onTranslationFileLoadedCallback);
};
