import React from 'react';
import isNil from 'lodash/isNil';
import { Container, Header, Name, TooltipBullet, TooltipRow, TrendContainer, Value } from './StyledTooltip';
import { Trend } from '../../../Trend/Trend';
import { getComponentHtml } from '../../../../utils/dom/dom';
import { colors } from '../../../../constants/colors';

const bullet = (color, bulletStyle) => (<TooltipBullet style={bulletStyle} color={color} />);

const createRow = ({ name, value, trend }, color, withBullet, bulletStyle) => (
  <TooltipRow data-testid="ChartTooltip" key={name}>
    {withBullet && bullet(color, bulletStyle)}
    <Name>{isNil(name) ? '' : name}</Name>
    <Value>{isNil(value) ? '' : value}</Value>
    {!isNil(trend) && (
    <TrendContainer>
      (<Trend value={trend} emptyText="N/A" />)
    </TrendContainer>
    )}
  </TooltipRow>
);

export default ({
  tooltipOptions: {
    getProps,
    CustomTooltip,
    getTitle = ({ x }) => x,
    withHeader,
    withBullet,
    bulletStyle,
    shared = true,
  },
}) => ({
  tooltip: {
    backgroundColor: colors.White,
    borderRadius: 0,
    borderWidth: 0,
    useHTML: true,
    outside: true,
    formatter() {
      const { points, point } = this;
      const pointsList = points || [point];

      if (CustomTooltip) {
        return getComponentHtml(
          <Container>
            <CustomTooltip point={this.point} />
          </Container>
        );
      }
      const Tooltip = (
        <Container>
          {withHeader && <Header>{getTitle(this)}</Header>}
          <table>
            <tbody>
              {pointsList.map((pointForRow) => createRow(getProps(pointForRow), pointForRow.color, withBullet, bulletStyle))}
            </tbody>
          </table>
        </Container>
      );

      return getComponentHtml(Tooltip);
    },
    followPointer: true,
    shared,
  },
});
