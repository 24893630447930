import React from 'react';
import { get } from 'lodash';
import { PivotItem } from '@fluentui/react';
import { ExecSummary } from '../../../ExecSummary';
import { CALL_STRINGS } from '../../../../strings';
import { PivotItemContainer } from '../PivotItemContainer/PivotItemContainer';

const EXEC_SUMMARY_TAB = 'Notes';

export const NotesPivotItem = ({
  conversationId,
  data,
  isExecutiveSummaryEnabled,
  onSelectSuggestion,
  onSelectActionItem,
  selectedSegment,
  isLoading,
  isViva,
  clearSelectedSegment,
  onRenderItemLink,
  t,
}) => (
    <PivotItem
        itemKey={EXEC_SUMMARY_TAB}
        headerText={t(isViva ? CALL_STRINGS.RECAP : CALL_STRINGS.NOTES)}
        onRenderItemLink={(props) => onRenderItemLink({ ...props, dataTourIndex: 0 })}
        alwaysRender
    >
      <PivotItemContainer
          selectedSegment={selectedSegment}
          clearSelectedSegment={clearSelectedSegment}
      >
        <ExecSummary
            id={conversationId}
            isExecutiveSummaryEnabled={isExecutiveSummaryEnabled}
            subject={get(data, 'summary.subject')}
            startDate={get(data, 'summary.startDate')}
            participants={get(data, 'participants')}
            relatedEntities={get(data, 'summary.crm')}
            isInternalMeeting={get(data, 'isInternalMeeting')}
            data={get(data, 'executiveSummary')}
            insightsProcessingStatus={data.insightsProcessingStatus}
            onSelectSuggestion={onSelectSuggestion}
            onSelectActionItem={onSelectActionItem}
            isLoading={isLoading}
        />
      </PivotItemContainer>
    </PivotItem>
);
