import styled from 'styled-components';
import { FlexColumn, FlexRow } from '../../utils/styles/StyledUtils';
import { colors } from '../../constants/colors';
import { rtl } from '../../utils/styles/rtl';

export const PartsContainer = styled(FlexRow)`
  height: 12px;
  width: 100%;
`;

export const Part = styled.div`
  width: ${({ width }) => width};
  background: ${({ color }) => color};
`;

export const TooltipContainer = styled(FlexColumn).attrs({
  as: 'span'
})`
  padding: 0 8px;
  font-size: 14px;
`;

export const PartRow = styled(FlexRow).attrs({
  as: 'span'
})`
  justify-content: space-between;
  align-items: center;
`;

export const Bullet = styled.span`
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  vertical-align: middle;
  ${rtl`margin-right`}: 4px;
  background: ${({ color }) => color};
`;

export const Text = styled.span`
  color: ${colors.Gray130};
  ${rtl`margin-right`}: 12px;
  font-size: 12px;
`;

export const Value = styled.span`
  color: ${colors.Gray160};
  font-size: 12px;
`;

export const Wrapper = styled.span``;

export const tooltipStyles = { root: { display: 'inline-block', width: '100%' } };

export const LegendContainer = styled(FlexRow)`
  margin-top: 4px;
`;
