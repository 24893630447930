/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import useLocale from 'hooks/use-locale';
import { Trans } from 'ci-common-ui';
import { EmptyPageImage, EmptyPageContainer, Subtitle, Title } from './StyledEmptyPage';
import { useAppNavigation } from '../../../hooks/use-app-navigation';
import { routes } from '../../routing';
import { getIsAdminUser } from '../../../utils/app-context/app-context';
import { Link } from '../../../components/Link/Link';

const LOCALIZATION_PREFIX = 'empty_states.page';

const EmptyPage = ({ titleText, subtitleText } = {}) => {
  const { t } = useLocale(LOCALIZATION_PREFIX);
  const { navigateTo } = useAppNavigation();

  return (
    <EmptyPageContainer>
      <Title>{titleText || t('title')}</Title>
      <Subtitle>
        {subtitleText || (
        <Trans i18nKey={`${LOCALIZATION_PREFIX}.subtitle${getIsAdminUser() ? '_admin' : ''}`}>
          <br /><Link onClick={() => navigateTo({ path: routes.systemMonitoring.link })}>fragment</Link>
        </Trans>
        )}
      </Subtitle>
      <EmptyPageImage />
    </EmptyPageContainer>
  );
};

export default EmptyPage;
