// @flow
import React, { useState, useRef, useEffect } from 'react';
import { isEmpty, debounce, get } from 'lodash';
import { Field } from 'react-final-form';
import { ComboBox, Label } from '@fluentui/react';
import { ComboBoxContainer, StyledSpinner } from './StyledAutoCompleteField';

const DEBOUNCE_TIME = 500;

const hideStyle = { root: { display: 'none' } };

export const useGetOptions = ((getOptionsFunc) => {
  const [{ options, isLoading, error }, setState] = useState({ options: [], isLoading: false, error: null });

  const getOptions = async (text) => {
    setState({ options: [], isLoading: true, error: null });
    try {
      const data = await getOptionsFunc(text);
      setState({ options: data, isLoading: false, error: null });
    } catch (err) {
      setState({ options: [], isLoading: false, error: err });
    }
  };

  return {
    options, isLoading, error, getOptions
  };
});

export const Search = ({ selected = {}, setSelected, handleGetOptions, searchErrorMessage, errorMessage, placeholder, onRenderOption, isFreetextAvailable, isDisabled, ariaLabel = 'search area' }) => {
  const el = useRef();
  const { options, getOptions, isLoading, error: searchError } = useGetOptions(handleGetOptions);
  const [text, setText] = useState(selected.name);

  const mappedOptions = options && !isLoading && !searchError ? options.map(({ name, key }) => ({ key: key || name, text: name })) : [];

  useEffect(() => {
    setText(selected.name);
  }, [selected]);

  const onSelect = (e, option) => {
    if (option) {
      setSelected(options.find(({ name }) => name === option.key));
    } else if (isFreetextAvailable) {
      setSelected({ name: e.target.value });
    }
  };

  const clear = () => {
    setSelected(undefined);
  };

  const onMenuDismissed = () => {
    if (!isFreetextAvailable) {
      setText(selected.name || null);
    }
  };

  const onSearchChange = (inputText) => {
    if (inputText === undefined) {
      return;
    }
    if (isFreetextAvailable) {
      setSelected({ name: inputText });
    }

    setText(inputText);
    if (el.current) {
      el.current.focus(true);
    }

    getOptions(inputText);
  };

  return (
    <ComboBoxContainer>
      <ComboBox
        componentRef={el}
        onMenuDismissed={onMenuDismissed}
        placeholder={placeholder}
        buttonIconProps={{ iconName: 'Cancel', styles: isLoading && hideStyle }}
        iconButtonProps={{ onClick: clear }}
        allowFreeform
        autoComplete="off"
        useComboBoxAsMenuWidth
        shouldRestoreFocus={false}
        options={mappedOptions}
        ariaLabel={ariaLabel}
        text={text}
        errorMessage={(searchError && searchErrorMessage) || errorMessage}
        onInputValueChange={onSearchChange}
        onChange={onSelect}
        onRenderOption={onRenderOption}
        disabled={isDisabled}
      />
      {isLoading && <StyledSpinner />}
    </ComboBoxContainer>
  );
};

const debounceGetOptions = debounce(async (text, getOptions, resolve, reject) => {
  if (text) {
    try {
      const res = await getOptions(text);
      resolve(res);
    } catch (e) {
      reject(e);
    }
  }
  resolve([]);
}, DEBOUNCE_TIME);

const asyncDebounceGetOptions = (text, getOptions) => new Promise((resolve, reject) => {
  debounceGetOptions(text, getOptions, resolve, reject);
});

const validate = ({ isRequired, maxLength }) => ({ name } = {}) => {
  if (isRequired && isEmpty(name)) {
    return 'required';
  }
  if (maxLength && get(name, 'length', 0) > maxLength) {
    return 'maxLength';
  }
  return null;
};

export const AutoCompleteField = ({
  name, label, placeholder, isRequired, isDisabled, maxLength, getOptions, onRenderOption, errorMessages, isFreetextAvailable
}) => {
  const handleGetOptions = (text) => asyncDebounceGetOptions(text, getOptions);

  return (
    <Field
      name={name}
      validate={validate({ isRequired, maxLength })}
      render={({ input, meta }) => (
        <div>
          { label && <Label disabled={isDisabled} required={isRequired}>{label}</Label> }
          <Search
            isFreetextAvailable={isFreetextAvailable}
            isDisabled={isDisabled}
            selected={input.value}
            setSelected={input.onChange}
            searchErrorMessage={errorMessages.search}
            errorMessage={meta.modified && meta.error && errorMessages[meta.error]}
            handleGetOptions={handleGetOptions}
            placeholder={placeholder}
            onRenderOption={onRenderOption}
          />
        </div>
      )}
    />
  );
};
