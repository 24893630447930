import styled from 'styled-components';
import { colors } from '../../../../../../constants/colors';
import { FlexColumn, FlexRowCentered, StyledText, FlexRow } from '../../../../../../utils/styles/StyledUtils';
import { rtl } from '../../../../../../utils/styles/rtl';
import { deviceSizes } from '../../../../../../utils/styles/device-sizes';

export const PANEL_ANIMATION_MS = 150;

export const Container = styled(FlexColumn)`
  width: ${({ isSuggestionsOpened }) => isSuggestionsOpened ? '360px' : 0};
  overflow: hidden;
  background-color: ${colors.Gray10};
  transition: width ${PANEL_ANIMATION_MS}ms ease;
  > div {
    display: ${({ isSuggestionsOpened }) => isSuggestionsOpened ? 'visible' : 'none'};
  }
  
  ${({isFullSize, theme}) => isFullSize && `
    width: 100%;
    background-color: ${theme.colors.White};
  `}

  ${deviceSizes.bigMobile`
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    box-shadow: 0 0 12px #bebebe;
  `}
`;

export const SuggestionGroups = styled.div`
  overflow-y: auto;
  ${({isFullSize}) => isFullSize && `
    padding: 14px 20px 20px 20px;
  `}
`;

export const SuggestionPanelHeaderContainer = styled(FlexRow)`
  justify-content: space-between;
  padding: 0 8px;
`;

export const copyButtonStyles = {
  root: {
    border: 'none',
    height: '44px',
    width: 'auto'
  },
  label: {
    fontWeight: '500'
  }
};

export const SuggestionHeader = styled.div`
  padding: 10px 10px 0 10px;
  line-height: 20px;
`;

export const PreviewLabelContainer = styled(FlexRowCentered)`
  padding: 4px 8px;
  border-radius: 2px;
  border: 1px solid ${colors.Gray40};
  i{
    transform:translateY(2px);
  }
`;

export const PreviewLabelTitle = styled(StyledText)`
  font-size: 12px;
`;

export const TitleContainer = styled(FlexRowCentered)`
  padding: 10px 18px 0 18px;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const Title = styled(StyledText)`
font-size: 18px;
${rtl`margin-right`}: 8px;
`;

export const closeIconStyles = {
  root: {
    color: colors.Gray160,
    selectors: {
      ':hover': {
        color: colors.DYNPrimary
      }
    }
  }
};
