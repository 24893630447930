import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import useLocale from 'hooks/use-locale';
import { useAppNavigation } from 'hooks/use-app-navigation';
import get from 'lodash/get';
import size from 'lodash/size';
import { useFetchNow, Trans } from 'ci-common-ui';
import Page from '../../components/Page/Page';
import { getInsightDetails } from '../../../network/data-services/insight-service';
import KeywordsSection from './components/KeywordsSection/KeywordsSection';
import RelevantConversations from '../components/RelevantConversations/RelevantConversations';
import { useQueryDateChange } from '../../../hooks/use-query-date-change';
import { getDateParams } from '../../../utils/url/url';
import { callTypeColumn, dateAndDurationColumn, keywordsColumn, tagsColumn, commentsCountColumn, ownerColumn, callTopicColumn, crmEntitiesLinksColumn } from '../../../components/Table/default-columns';
import BackHomeButton from '../components/BackHomeButton';
import { settingsModalTabs } from '../../../constants/modals';
import DelimitedInlineList from '../../../components/DelimitedInlineList/DelimitedInlineList';
import { getSpecificError } from '../../../utils/error-state/error-state';
import { TRACK_IDS } from '../../../constants/tracking';
import { QUERY_PARAMS } from '../../../constants/urls';
import { Link } from '../../../components/Link/Link';
import { componentLoadingTimeService } from '../../../services/component-loading-time-service/component-loading-time-service';

const LOCALIZATION_PREFIX = 'pages.insight.competitors';

const type = 'TrendingCompetitors';
const columns = [callTypeColumn, callTopicColumn, { ...keywordsColumn, name: `${LOCALIZATION_PREFIX}.table_col_competitors` }, tagsColumn, commentsCountColumn, ownerColumn, dateAndDurationColumn, crmEntitiesLinksColumn];

const InsightCompetitorsPage = () => {
  const { appModeAwareTerms } = useLocale();
  const { navigateTo } = useAppNavigation();
  const dateParams = getDateParams();
  const apiParams = { type, ...dateParams };
  const tableApiParams = { type, ...dateParams };
  const { data, isLoading, error, fetchData } = useFetchNow(() => getInsightDetails(apiParams, 'competitors'), { getSpecificError });
  const keywords = get(data, 'topTrendingKeywords');
  /* eslint-disable */
  const keywordsSectionTitle = !isEmpty(keywords) && !isLoading ? (
    <Trans i18nKey={`${LOCALIZATION_PREFIX}.title`} count={size(keywords)}>
      fragment1 {{ _department: appModeAwareTerms._department }} fragment3 <DelimitedInlineList data={keywords} />
    </Trans>
  ) : null;
  /* eslint-enable */
  /* eslint-disable jsx-a11y/anchor-is-valid */
  const keywordsSectionSubtitle = (
    <Trans i18nKey={`${LOCALIZATION_PREFIX}.subtitle`}>
      first line<Link onClick={() => navigateTo({ params: { [QUERY_PARAMS.SETTINGS]: settingsModalTabs.conversationcontent.link } })}>text</Link><br />second line
    </Trans>
  );

  useQueryDateChange(fetchData);

  useEffect(() => {
    componentLoadingTimeService.start(TRACK_IDS.SOURCES.COMPETITORS_INSIGHT_PAGE);
  }, []);

  return (
    <Page isLoading={isLoading} title={<BackHomeButton />} fullTimeRange={data && data.dateTimeRange} error={error}>
      <KeywordsSection isLoading={isLoading} data={data} title={keywordsSectionTitle} subtitle={keywordsSectionSubtitle} error={error} />
      <RelevantConversations columns={columns} apiParams={tableApiParams} isPageDetailsLoading={isLoading} trackSource={TRACK_IDS.SOURCES.COMPETITORS_INSIGHT_PAGE} />
    </Page>
  );
};
export default InsightCompetitorsPage;
