import React, { useEffect } from 'react';
import BannerImage from 'assets/images/provision-banner.png';
import { useFetch } from 'ci-common-ui';
import { getUploadingCallsStatus } from 'network/data-services/trial-service';
import { getIsTrialMode } from 'utils/app-context/app-context';
import { MODALS } from '../../../../constants/modals';
import { useAppState } from '../../../../providers/AppStateProvider';
import { TRACK_IDS } from '../../../../constants/tracking';
import { eventAction } from '../../../../services/telemetry-service/consts';
import { trackEvent } from '../../../../services/telemetry-service/telemetry-service';
import { BannerCancelIcon, BannerContainer, BannerInnerContainer, StyledBannerImage } from './StyledProvisionBanner';

import ProcessingTrial from './ProcessingTrial';
import StartTrial from './StartTrial';

const ProvisionBanner = ({ onCloseClicked }) => {
  const { setAppState, appState: { isEnabledBannerProcessing } } = useAppState();
  const { data: processingData, fetchData: fetchProcessing, isLoading } = useFetch(getUploadingCallsStatus);

  const isProcessingFinished = processingData && processingData.isFinished;

  const isTrialCreated = getIsTrialMode();

  useEffect(() => {
    if (isTrialCreated && isEnabledBannerProcessing) {
      fetchProcessing();
    }
  }, [isTrialCreated, isEnabledBannerProcessing]);

  useEffect(() => {
    if (isTrialCreated && isProcessingFinished) {
      setAppState({ isEnabledBannerProcessing: false });
    }
  }, [isTrialCreated, isProcessingFinished]);

  if ((isLoading && !processingData) || !isEnabledBannerProcessing) {
    return null;
  }

  const onGetStartedClicked = () => {
    trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.PROVISION_BANNER_GET_STARTED });
    setAppState({ modalName: MODALS.Onboarding });
  };

  const onSetupClicked = () => {
    trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.PROVISION_BANNER_SETUP });
    setAppState({ modalName: MODALS.TrialSetup });
  };

  return (
    <BannerContainer>
      <BannerCancelIcon onClick={onCloseClicked} />
      <BannerInnerContainer>
        <StyledBannerImage src={BannerImage} />
        {isTrialCreated
          ? <ProcessingTrial processingData={processingData} fetchProcessing={fetchProcessing} />
          : <StartTrial onGetStartedClicked={onGetStartedClicked} onSetupClicked={onSetupClicked} />}
      </BannerInnerContainer>
    </BannerContainer>
  );
};

export default ProvisionBanner;
