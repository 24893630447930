import styled from 'styled-components';
import { StyledFontIcon, colors } from 'ci-common-ui';

export const PhoneIconCell = styled(StyledFontIcon).attrs({
  iconName: 'Phone',
})`
  font-size: 16px;
  height: 16px;
  width: 16px;
  color: ${colors.Gray160};
`;
