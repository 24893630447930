import React from 'react';
import { Tooltip } from 'ci-common-ui';
import { Icon, ProgressIndicator } from '@fluentui/react';
import useLocale from 'hooks/use-locale';
import { errorsMap, DEFAULT_ERROR_CODE_TRANSLATION_KEY } from './ErrorPanel/errorsMap';
import { Progress, StatusCellContainer, Circle } from '../StyledFilesTable';
import { STATUS_NAMES } from '../../../constants';
import { trackError } from '../../../../../services/telemetry-service/telemetry-service';
import { TRACK_IDS } from '../../../../../constants/tracking';

const iconsMap = {
  [STATUS_NAMES.completed]: 'StatusCircleCheckmark',
  [STATUS_NAMES.failed]: 'StatusCircleInfo',
  [STATUS_NAMES.ignored]: 'StatusCircleCheckmark',
};

const getStatus = (fileStatus) => {
  if (!fileStatus) {
    return STATUS_NAMES.failed;
  }
  if (fileStatus === -1) {
    return STATUS_NAMES.ignored;
  }
  return fileStatus === 100 || fileStatus === 90 ? STATUS_NAMES.completed : STATUS_NAMES.inProgress;
};

const StatusCell = ({ data: fileStatus, row: { errorCode }, onClick }) => {
  const { t, tAbs } = useLocale('pages.system_monitoring');

  const status = getStatus(fileStatus);

  if (status === STATUS_NAMES.inProgress) {
    return (
      <Progress onClick={onClick}>
        <ProgressIndicator percentComplete={fileStatus / 100} barHeight={4} />
        <span>{fileStatus}%</span>
      </Progress>
    );
  }

  const hasErrorCodeMatch = errorCode && errorsMap[errorCode];
  const errorCodeTranslationKey = hasErrorCodeMatch ? errorsMap[errorCode].translationKey : DEFAULT_ERROR_CODE_TRANSLATION_KEY;
  const isStatusFailedOrIgnored = status === STATUS_NAMES.failed || status === STATUS_NAMES.ignored;
  const statusText = isStatusFailedOrIgnored ? t(`error_panel.${errorCodeTranslationKey}.name`) : tAbs(`common.${status}`);

  if (isStatusFailedOrIgnored && !hasErrorCodeMatch && errorCode) {
    trackError({ actionOn: TRACK_IDS.COMMON.MISSING_ERROR_CODE, message: errorCode });
  }

  return (
    <Tooltip content={status === STATUS_NAMES.ignored && t('files_table.ignored_file_tooltip')}>
      <StatusCellContainer onClick={onClick} clickable={isStatusFailedOrIgnored}>
        <Circle status={status}>
          <Icon iconName={iconsMap[status]} />
        </Circle>
        <span>{statusText}</span>
      </StatusCellContainer>
    </Tooltip>
  );
};

export default StatusCell;
