import styled from 'styled-components';
import { colors, FlexRow, rtl } from 'ci-common-ui';

export const BulletContainer = styled(FlexRow)`
  align-items: center;
`;

export const ContentBullet = styled.div`
  background-color: ${colors.Gray10};
  padding: 5px;
  ${rtl`margin-right: 16px;`}
  height: 16px;
  box-sizing: content-box;
`;

export const ContentBulletDescription = styled.div`

`;
