import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Tooltip } from 'ci-common-ui';
import { MatchesContainer, Matches, EntityName, TooltipContainer, TooltipMatchesContainer, TooltipMatches } from './StyledSearchMatchesCell';
import { order, getEntityMatches } from './search-matches-utils';
import useLocale from '../../../../hooks/use-locale';
import { eventAction, trackEvent } from '../../../../services/telemetry-service/telemetry-service';
import { TRACK_IDS, TRACK_SUFFIX } from '../../../../constants/tracking';
import { joinTrackDelimiter } from '../../../../utils/string/string';

const trackViewEvent = () => trackEvent({ action: eventAction.hover, actionOn: joinTrackDelimiter([TRACK_IDS.SEARCH_TABLE, TRACK_SUFFIX.TABLE.CELL, TRACK_IDS.COMMON.VIEWED, TRACK_IDS.MATCHES]) });

const TooltipContent = ({ query, data, matches }) => {
  const { tAbs } = useLocale();
  return (
    <TooltipContainer>
      { matches.map(({ arrKey, tKey }) => (
        <TooltipMatchesContainer key={`tooltip_${arrKey}`}>
          <EntityName>{tAbs(tKey)}</EntityName>
          <TooltipMatches>{getEntityMatches(query, data[arrKey])}</TooltipMatches>
        </TooltipMatchesContainer>
      ))}
    </TooltipContainer>
  );
};

const SearchMatchesCell = ({ data = {}, column }) => {
  const { tAbs } = useLocale();
  const query = column.query.replace(/[^a-zA-Z@0-9]/g, ''); // allowed chars
  const matches = order.filter((it1) => !isEmpty(data[it1.arrKey]) && data[it1.arrKey].some((it2) => !!it2.match(new RegExp(query, 'gi'))));

  return (
    <Tooltip content={<TooltipContent query={query} data={data} matches={matches} />}>
      <MatchesContainer>
        { matches.map(({ arrKey, tKey }) => (
          <Matches key={arrKey} onMouseEnter={trackViewEvent}>
            <EntityName>{tAbs(tKey)}</EntityName>
            {getEntityMatches(query, data[arrKey])}
          </Matches>
        ))}
      </MatchesContainer>
    </Tooltip>
  );
};
export default SearchMatchesCell;
