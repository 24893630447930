import styled from 'styled-components';
import { Icon } from '@fluentui/react';
import { colors } from '../../../../constants/colors';
import { FlexRowCentered } from '../../../../utils/styles/StyledUtils';

export const PersonaContainer = styled(FlexRowCentered)`
  min-width: 0;
  
  ${({ isClickAble }) => isClickAble && `
    &:hover {
      cursor:  pointer;
      .ms-Persona-details {
        text-decoration: underline;
      }
    }  
  `}
`;

export const IconStyled = styled(Icon)`
  margin: 0 4px;
  color: ${({ color }) => color || colors.DYNPrimary};
  font-size: 14px;
`;
