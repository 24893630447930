import styled from 'styled-components';
import { colors, Pill } from 'ci-common-ui';
import { FontSizes } from '@uifabric/fluent-theme';

export const TagsContainer = styled.div`
  max-width: 100%;
  ${Pill} {
    font-size: ${FontSizes.size12};
    background-color: ${colors.AliceBlue};
    color: ${colors.Gray160};
    border-radius: 2px;
    padding: 5px 8px;
  }
`;
