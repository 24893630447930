import React from 'react';
import { Tooltip } from 'ci-common-ui';
import useLocale from 'hooks/use-locale';
import { CallScope, CallTypeContainer, getPhoneIcon } from './StyledCallTypeCell';

const CallTypeCell = ({ data }) => {
  const { t } = useLocale();
  if (!data) return null;
  const { callScope, callDirection } = data;
  const direction = callDirection?.toLowerCase();
  const tooltipContent = t(`common.${direction === 'inbound' ? 'incoming' : 'outgoing'}_call`);
  const PhoneIcon = getPhoneIcon(direction);

  return (
    <CallTypeContainer>
      <Tooltip content={tooltipContent}>
        <PhoneIcon />
      </Tooltip>
      <CallScope>{callScope}</CallScope>
    </CallTypeContainer>
  );
};
export default CallTypeCell;
