// @flow

const sizes = {
  maxWidthCallPage: '1352px',
  paddingMainCallPage: '0 6px'
};

const regularColors = {
  AzulBlue: '#118DFF',
  GrapePurple: '#750985',
  BlushPink: '#C83D95',
  MangoOrange: '#E66C37',
  PacificBlue: '#009EB3',
  JadeGreen: '#18A03C',
  DynamicsGreen: '#107C10',
  AtlanticBlue: '#3A4BC6',
  ScarletRed: '#E04854',
  DynamicsRed: '#D83B01',
  CandyPink: '#835DD0',
  SaharaYellow: '#BD8608',
  Red: '#a4262c',
  Red10: '#C6231D',
  TeamsPurple: '#744EC2',
  TeamsGreen: '#1AAB40',
  TeamsPink: '#E044A7',
  Excel: '#24794a',
  AliceBlue: '#f0f8ff',
  SearchMatch: '#fff087',
  PBITint30: '#FBEEB5',
  Positive: '#009EB3',
  Neutral: '#8a8886',
  Negative: '#E04854',
  Error: '#a80000',
  Warning: '#FFB900',
};

export const SENTIMENT_COLORS = {
  negative: regularColors.Negative,
  neutral: regularColors.Neutral,
  positive: regularColors.Positive
};

export const lightTheme = {
  name: 'light',
  colors: {
    DYNPrimary: '#2266E3',
    DYNTint50: '#f0f5ff',
    DYNTint40: '#e7efff',
    DYNTint30: '#ccdeff',
    DYNTint20: '#9cbeff',
    DYNTint10: '#1f6cf9',
    DYNShade10: '#0b53ce',
    DYNShade20: '#0742ab',
    DYNShade30: '#053385',
    Gray10: '#faf9f8',
    Gray20: '#f3f2f1',
    Gray26: '#424242',
    Gray30: '#edebe9',
    Gray40: '#e1dfdd',
    Gray50: '#d2d0ce',
    Gray60: '#c8c6c4',
    Gray70: '#BEBBB8',
    Gray90: '#a19f9d',
    Gray100: '#979593',
    Gray110: regularColors.Neutral,
    Gray130: '#605e5c',
    Gray140: '#484644',
    Gray150: '#3b3a39',
    Gray160: '#323130',
    Gray190: '#201f1e',
    Gray200: '#eeeeee',
    Gray300: '#E0E0E0',
    Black: '#000000',
    White: '#ffffff',
    backgroundColor: '#e7efff', // this is temporary fix, the whole theme should change to match the light theme
    ...regularColors
  },
  sizes
};

export const teamsTheme = {
  name: 'dark',
  colors: {
    DYNPrimary: '#6264a7',
    DYNTint50: '#040407',
    DYNTint40: '#10101b',
    DYNTint30: '#1d1e32',
    DYNTint20: '#3b3c63',
    DYNTint10: '#565892',
    DYNShade10: '#6e70af',
    DYNShade20: '#8183bb',
    DYNShade30: '#9ea0cd',
    Gray10: '#2b2929',
    Gray20: '#333232',
    Gray26: '#424242',
    Gray30: '#414040',
    Gray40: '#4a4848',
    Gray50: '#514f4f',
    Gray60: '#6f6c6c',
    Gray90: '#c8c8c8',
    Gray100: '#979593',
    Gray110: regularColors.Neutral,
    Gray130: '#d0d0d0',
    Gray140: '#484644',
    Gray150: '#dadada',
    Gray160: '#ffffff',
    Gray190: '#f4f4f4',
    Gray200: '#eeeeee',
    Gray300: '#E0E0E0',
    Black: '#f8f8f8',
    White: '#201F1F',
    Error: '#F9526B',
    BGError: '#3E1F25',
    backgroundColor: '#3b3c63', // this is temporary fix, the whole theme should change to match the light theme
    ...regularColors,
  },
  sizes
};

export const teamsLightTheme = {
  name: 'light-teams',
  colors: {
    DYNPrimary: '#4F52B2',
    DYNTint50: '#040407',
    DYNTint40: '#E8EBFA',
    DYNTint30: '#C5CBFA',
    DYNTint20: '#7277DB',
    DYNTint10: '#5B5FC7',
    DYNShade10: '#444791',
    DYNShade20: '#3D3E78',
    DYNShade30: '#2F2F4A',
    TMSPrimary: '#6264A7',
    TMSTint40: '#E2E2F6',
    TMSTint50: '#F4F4FC',
    Gray10: '#F5F5F5',
    Gray20: '#f3f2f1',
    Gray26: '#424242',
    Gray30: '#F0F0F0',
    Gray40: '#E0E0E0',
    Gray50: '#D1D1D1',
    Gray60: '#c8c6c4',
    Gray70: '#BEBBB8',
    Gray90: '#a19f9d',
    Gray100: '#979593',
    Gray110: regularColors.Neutral,
    Gray130: '#616161',
    Gray140: '#484644',
    Gray150: '#3b3a39',
    Gray160: '#242424',
    Gray190: '#201f1e',
    Gray200: '#eeeeee',
    Gray300: '#E0E0E0',
    Black: '#000000',
    White: '#ffffff',
    Error: '#F9526B',
    BGError: '#3E1F25',
    backgroundColor: '#3b3c63', // this is temporary fix, the whole theme should change to match the light theme
    ...regularColors,
  },
  sizes
};

export const teamsDarkTheme = {
  name: 'dark-teams',
  colors: {
    DYNPrimary: '#7F85F5',
    DYNTint50: '#040407',
    DYNTint40: '#2F2F4A',
    DYNTint30: '#444791',
    DYNTint20: '#444791',
    DYNTint10: '#7F85F5',
    DYNShade10: '#9299F7',
    DYNShade20: '#C5CBFA',
    DYNShade30: '#E8EBFA',
    TMSPrimary: '#7A7FEA',
    TMSTint40: '#3F3E5D',
    TMSTint50: '#373644',
    Gray10: '#1F1F1F',
    Gray20: '#141414',
    Gray26: '#424242',
    Gray30: '#3D3D3D',
    Gray40: '#3D3D3D',
    Gray50: '#616161',
    Gray60: '#616161',
    Gray70: '#707070',
    Gray90: '#707070',
    Gray100: '#707070',
    Gray110: '#ADADAD',
    Gray130: '#D6D6D6',
    Gray140: '#D6D6D6',
    Gray150: '#D6D6D6',
    Gray160: '#FFFFFF',
    Gray190: '#FFFFFF',
    Gray200: '#FFFFFF',
    Gray300: '#FFFFFF',
    Black: '#FFFFFF',
    White: '#292929',
    Error: '#F9526B',
    BGError: '#3E1F25',
    backgroundColor: '#3b3c63', // this is temporary fix, the whole theme should change to match the light theme
    ...regularColors,
  },
  sizes
};

export const teamsHighContrastTheme = {
  name: 'high-contrast-teams',
  colors: {
    DYNPrimary: '#FFFF01',
    DYNTint50: '#040407',
    DYNTint40: '#000000',
    DYNTint30: '#1AEBFF',
    DYNTint20: '#1AEBFF',
    DYNTint10: '#FFFF01',
    DYNShade10: '#1AEBFF',
    DYNShade20: '#FFFFFF',
    DYNShade30: '#FFFFFF',
    TMSPrimary: '#0086F0',
    TMSTint40: '#000000',
    TMSTint50: '#000000',
    Gray10: '#000000',
    Gray20: '#000000',
    Gray26: '#424242',
    Gray30: '#000000',
    Gray40: '#FFFFFF',
    Gray50: '#FFFFFF',
    Gray60: '#FFFFFF',
    Gray70: '#1AEBFF',
    Gray90: '#1AEBFF',
    Gray100: '#1AEBFF',
    Gray110: '#FFFFFF',
    Gray130: '#FFFF01',
    Gray140: '#FFFF01',
    Gray150: '#FFFF01',
    Gray160: '#FFFFFF',
    Gray190: '#FFFFFF',
    Gray200: '#FFFFFF',
    Gray300: '#FFFFFF',
    Black: '#FFFFFF',
    White: '#000000',
    Error: '#FFFFFF',
    BGError: '#000000',
    backgroundColor: '#3b3c63', // this is temporary fix, the whole theme should change to match the light theme
    ...regularColors,
    Negative: '#FFFF01',
    Neutral: '#1AEBFF',
    Positive: '#FFFFFF',
  },
  sizes
};

export const { colors } = lightTheme; // temp fallback

function hexToRGB(hex) {
  let r = 0;
  let g = 0;
  let b = 0;

  // 3 digits
  if (hex && hex.length === 4) {
    r = `0x${hex[1] + hex[1]}`;
    g = `0x${hex[2] + hex[2]}`;
    b = `0x${hex[3] + hex[3]}`;

    // 6 digits
  } else if (hex && hex.length === 7) {
    r = `0x${hex[1] + hex[2]}`;
    g = `0x${hex[3] + hex[4]}`;
    b = `0x${hex[5] + hex[6]}`;
  }

  return { r: +r, g: +g, b: +b };
}

export function hexToRGBA(hex, alpha) { // alpha between 0 - 1
  const { r, g, b } = hexToRGB(hex);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
