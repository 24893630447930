/* eslint-disable no-unused-vars */
/* These stubs are used by pages that are not in use, the outdated API was removed */

export const getDefaultsKeywords = async (industry) => { };

export const createTrialOrg = async (config) => { };

export const uploadCall = async (call) => { };

export const getUploadingCallsStatus = async (params) => { };
