import styled from 'styled-components';
import { dataAutomation, AUTOMATION_SELECTORS } from '../../constants/selectors';

export const ChartContainer = styled.div.attrs({
  [dataAutomation]: AUTOMATION_SELECTORS.CHART
})`
  position: relative;
  height: 100%;
  .highcharts-legend-item {
    .highcharts-point {
      transform: translateY(-3px);
    }
  }
`;
