import React, { useEffect } from 'react';
import useLocale from 'hooks/use-locale';
import { ProgressIndicator } from '@fluentui/react';
import { usePolling, Trans } from 'ci-common-ui';
import { useAppNavigation } from 'hooks/use-app-navigation';
import { routes } from 'pages/routing';
import { Link } from '../../../../components/Link/Link';
import { CallToActionContainer, ExplanationContainer, Subtitle, Title, progressIndicatorStyles } from './StyledProvisionBanner';

const LOCALIZATION_PREFIX = 'components.provision_banner';

const POLLING_INTERVAL = 10000;

const ProcessingTrial = ({ processingData, fetchProcessing }) => {
  const { t } = useLocale(LOCALIZATION_PREFIX);
  const { navigateTo } = useAppNavigation();
  const { startPolling } = usePolling(fetchProcessing, POLLING_INTERVAL);

  useEffect(() => {
    if (processingData) {
      startPolling();
    }
  }, []);

  if (!processingData) {
    return null;
  }

  const goSystemMonitoring = () => {
    navigateTo({ path: routes.systemMonitoring.link });
  };

  const { completed, total } = processingData;

  return (
    <>
      <ExplanationContainer>
        <Title>{t('processing_title')}</Title>
        <Subtitle>
          <Trans i18nKey={`${LOCALIZATION_PREFIX}.processing_subtitle`}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            fragment1 <Link role="link" onClick={goSystemMonitoring} target="_blank">link text</Link>
          </Trans>
        </Subtitle>
      </ExplanationContainer>
      <CallToActionContainer>
        <ProgressIndicator
          label={t('processing_label')}
          description={t('processing_description', { completed, total })}
          percentComplete={completed / total}
          styles={progressIndicatorStyles}
        />
      </CallToActionContainer>
    </>
  );
};

export default ProcessingTrial;
