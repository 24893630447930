import { chartConfigs, colors, hexToRGBA, LISTENING_AND_TALKING_NAMES } from 'ci-common-ui';
import get from 'lodash/get';
import map from 'lodash/map';
import upperFirst from 'lodash/upperFirst';
import { formatPercentage } from '../../../../utils/numeric';
import { series, translateSeries } from '../../../../utils/chart';

const { combineConfigs, splitBar, legendVertical, horizontalBar, dataLabels, bar, yAxisTitle, noYAxisText } = chartConfigs;

const keyNames = ['common.top_performers', 'common.rest_of_team_avg'];

export const createTalkToListenRatioChartConfig = (data, tAbs) => (
  combineConfigs(
    {
      data: [
        {
          [LISTENING_AND_TALKING_NAMES[0]]: data.topSellerListening,
          [LISTENING_AND_TALKING_NAMES[1]]: data.topSellerTalking,
          name: tAbs(keyNames[0])
        }, {
          [LISTENING_AND_TALKING_NAMES[0]]: data.restOfTeamListening,
          [LISTENING_AND_TALKING_NAMES[1]]: data.restOfTeamTalking,
          name: tAbs(keyNames[1])
        }
      ],
      seriesParams: translateSeries(series.listeningAndTalkingParams, tAbs),
      categoryField: 'name',
      dataLabelsFormatter: ({ percentage }) => formatPercentage(percentage),
      shouldAddEmptyDataToStartAndEnd: true
    },
    [
      splitBar,
      noYAxisText,
      legendVertical,
      {
        plotOptions: {
          series: {
            pointWidth: 40,

          },
          column: {
            dataLabels: {
              style: {
                fontSize: '14px'
              }
            }

          }
        },
        xAxis: {
          labels: {
            autoRotation: false,
            style: {
              fontSize: '14px'
            }
          }
        }
      }
    ],
  )
);

export const createNegativeCustomerSentimentChartConfig = (data, tAbs) => (
  combineConfigs(
    {
      data: [{
        name: tAbs(keyNames[0]),
        subject: tAbs(keyNames[0]),
        ...data.topSeller || {},
      }, {
        name: tAbs(keyNames[1]),
        subject: tAbs(keyNames[1]),
        ...data.restOfTeam || {},
      }],
      seriesParams: translateSeries(series.sentimentParams, tAbs),
      categoryField: 'name',
      yTickAmount: 3,
      yAxisFormatter: ({ value }) => formatPercentage(value),
      dataLabelsFormatter: ({ percentage }) => formatPercentage(percentage),
      tAbs,
      type: 'bar'
    },
    [
      horizontalBar,
      dataLabels,
      {
        accessibility: {
          keyboardNavigation: {
            order: ['legend', 'series']
          },
          point: {
            descriptionFormatter: ({ index, name, negative, positive, percentage }) => {
              let trendString = '';
              trendString = `, ${percentage === positive ? 'Positive' : percentage === negative ? 'Negative' : 'Neutral'}`;
              return `${index + 1}. ${name}, ${trendString} ${percentage}%.`;
            }
          }
        }
      }
    ],
  )
);

export const createSwitchRateChartConfig = (data, tAbs, t) => (
  combineConfigs(
    {
      data: [{
        name: tAbs('common.top_performers'),
        value: get(data, 'topSeller'),
      }, {
        name: tAbs('common.rest_of_team_avg'),
        value: get(data, 'restOfTeam'),
      }],
      seriesParams: [{ key: 'value', text: 'value', color: hexToRGBA(colors.DYNPrimary, 0.7) }],
      categoryField: 'name',
      yTickAmount: 3,
      yAxisFormatter: ({ y }) => y,
      dataLabelsFormatter: ({ y }) => y,
      yAxisTitle: upperFirst(t('insights.switch_rate.switch_rate')),
      tAbs,
      type: 'bar'
    },
    [
      bar,
      dataLabels,
      yAxisTitle
    ],
  )
);

export const createCustomerEngagementChartConfig = (data, tAbs, t) => (
  combineConfigs(
    {
      data: map(data, ({ name, value }) => ({ name: upperFirst(t(`insights.customer_engagement.${name}`)), value })),
      seriesParams: [{ key: 'value', text: 'value', color: hexToRGBA(colors.DYNPrimary, 0.7) }],
      categoryField: 'name',
      yTickAmount: 3,
      yAxisFormatter: ({ y }) => y,
      yAxisTitle: upperFirst(tAbs('common.time.hours')),
      dataLabelsFormatter: ({ y }) => y,
      tAbs,
      type: 'bar'
    },
    [
      bar,
      dataLabels,
      yAxisTitle,
    ],
  )
);
