import React from 'react';
import noop from 'lodash/noop';
import { Field } from 'react-final-form';
import { ChoiceGroup, Dropdown } from '@fluentui/react';
import { requiredValidate } from '../../validation';
import { Container, OptionLabelContainer, Title, Description, DropdownContainer, dropdownStyle } from './StyledRadioButtonWithDropdownField';
import { getAriaLabelForDropdown } from '../utils';

const OptionLabel = ({ description, text }) => (
  <OptionLabelContainer>
    <Title>{text}</Title>
    <Description>{description}</Description>
  </OptionLabelContainer>
);

export const RadioButtonWithDropdownField = ({
  name, label, isRequired, isDisabled, isLoading, customError, errorMessages, options, dropdownOptions, ariaLabel
}) => (
  <Field
    name={name}
    validate={isRequired ? requiredValidate : noop}
    render={({ input, meta }) => {
      const onChange = (e, { key, withDropDown }) => {
        input.onChange(withDropDown ? dropdownOptions[0].key : key);
      };
      const onChangeDropdown = (e, { key }) => {
        input.onChange(key);
      };

      const customizeOptions = options.map((option) => ({ ...option, onRenderLabel: OptionLabel }));

      const currentOption = options.find(({ key }) => input.value === key);
      const currentOptionWithDropDown = options.find(({ withDropDown }) => withDropDown);

      return (
        <Container>
          <ChoiceGroup
            label={label}
            name={input.name}
            selectedKey={currentOption ? input.value : currentOptionWithDropDown.key}
            options={customizeOptions}
            onChange={onChange}
            required={isRequired}
            disabled={isDisabled || isLoading}
            errorMessage={customError || (meta.touched && meta.error && errorMessages[meta.error])}
          />
          <DropdownContainer>
            <Dropdown
              disabled={currentOption || isLoading}
              defaultSelectedKey={input.value}
              selectedKey={input.value}
              onChange={onChangeDropdown}
              onBlur={input.onBlur}
              onFocus={input.onFocus}
              options={dropdownOptions}
              styles={dropdownStyle}
              aria-label={ariaLabel || getAriaLabelForDropdown(dropdownOptions, input)}
            />
          </DropdownContainer>
        </Container>
      );
    }}
  />
);
