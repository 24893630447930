import map from 'lodash/map';
import { colors } from '../../../../constants/colors';
import { isDirectionRTL } from '../../../../utils/dom/dom';

const LABEL_OFFSET_FROM_PLOTLINE = 15;
const getPlotLineVerticalOffset = (plotLines) => {
  if (plotLines.length === 1) {
    return [0];
  }
  return plotLines[0].value > plotLines[1].value
    ? [-LABEL_OFFSET_FROM_PLOTLINE - 2, LABEL_OFFSET_FROM_PLOTLINE]
    : [LABEL_OFFSET_FROM_PLOTLINE, -LABEL_OFFSET_FROM_PLOTLINE - 2];
};

const getPlotLineLabelHtml = (text, tooltipLabelText, color) => (
  `<div 
      title="${tooltipLabelText}" 
      style="
      background-color: ${color};
      padding: 5px;
      border-color: ${colors.Gray20};
      border-radius: 2px;
      color: ${colors.White};"
   >
      ${text}
  </div>`
);

export default ({
  plotLines,
}) => ({
  yAxis: {
    plotLines:
      map(plotLines, ({
        color, value, labelText, tooltipLabelText
      }, index) => ({
        color,
        dashStyle: 'dash',
        width: 1,
        value,
        label: {
          align: isDirectionRTL() ? 'left' : 'right',
          y: getPlotLineVerticalOffset(plotLines)[index],
          text: getPlotLineLabelHtml(labelText, tooltipLabelText, color),
          x: 0,
          useHTML: true,
        },
        zIndex: 4,
      }))
  },
});
