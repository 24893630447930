import { SERVER_URL } from 'constants/config';
import { urlUtils, getMediaRecordingServerUrl as getMediaRecordingServerUrlCommon, storageService } from 'ci-common-ui';
import get from 'lodash/get';
import toNumber from 'lodash/toNumber';
import qs from 'query-string';
import { dateToServer, toEndOfTheDay, toStartOfTheDay } from '../date/date';
import { redirectRoutes } from '../../pages/routing';
import { appContext } from '../app-context/app-context';
import { QUERY_PARAMS } from '../../constants/urls';
import { IS_PE } from '../../constants/browser-storage';

export const { getSearchParam, setSearchParam } = urlUtils;

export const getUtcStartEndDate = (localStartDate, localEndDate) => ({
  ...(localStartDate !== 'all') && { startDate: dateToServer(toStartOfTheDay(localStartDate)) },
  ...(localEndDate !== 'all') && { endDate: dateToServer(toEndOfTheDay(localEndDate)) }
});

export const getDateParams = (history) => {
  const { startDate: startDateFromUrl, endDate: endDateFromUrl } = getSearchParam(history);
  return getUtcStartEndDate(startDateFromUrl, endDateFromUrl);
};

export const redirectOldRoutes = (history) => {
  const delimiter = '#';
  let url = window.location.hash;
  if (url && url.startsWith(delimiter)) {
    url = url.replace(delimiter, '');
    if (url.length > 1) {
      const route = Object.entries(redirectRoutes).find(([key]) => url.toLowerCase().includes(key.toLowerCase()));
      if (route) {
        url = url.replace(new RegExp(route[0], 'i'), route[1]);
      }
    }
    history.replace(url);
  }
};

export const extractCrmNumberFromOrgUrl = (orgUrl) => {
  const crmNumber = orgUrl && orgUrl.match(/.crm(\d+).dynamics.com/);
  return crmNumber ? crmNumber[1] : '';
};

const USE_TOP_WINDOW = true;
export const getMediaRecordingServerUrl = (orgUrl) => getMediaRecordingServerUrlCommon(appContext.clusterRegion, USE_TOP_WINDOW, orgUrl);

export const getNewApiServerUrl = () => toNewBackendUrl(appContext.clusterRegion || SERVER_URL);

export const getWsUrl = () => {
  const url = getNewApiServerUrl();
  return toWebSocketUrl(url);
};

export const toUIHost = (clusterRegion) => {
  if (!clusterRegion) { return null; }
  const urlPrefix = 'https://';
  const uiPrefix = 'ui-';

  return clusterRegion.toLowerCase().replace(urlPrefix, uiPrefix);
};

export const toNewBackendUrl = (url) => {
  if (!url) { return null; }
  const urlPrefix = 'https://';
  // Adding private endpoint support - all url which contains pe-dev will use https://api-pe- for backend calls.
  const regex = new RegExp('pe-dev*');
  const isPE = storageService.localStorage.getItem(IS_PE) === 'true';
  const newBackendPrefix = regex.test(window.location.hostname) || isPE ? 'https://api-pe-' : 'https://api-';
  return url.toLowerCase().replace(urlPrefix, newBackendPrefix);
};

const toWebSocketUrl = (url) => {
  if (!url) { return null; }
  const urlPrefix = 'https://';
  return url.toLowerCase().replace(urlPrefix, 'wss://');
};

export const getPageNumberFromUrlOrDefault = (defaultPageNumber = 1) => {
  const pageNumberFromUrl = get(qs.parse(window.location.search), QUERY_PARAMS.PAGE_NUMBER);
  return pageNumberFromUrl ? toNumber(pageNumberFromUrl) : defaultPageNumber;
};
