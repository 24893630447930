import styled from 'styled-components';
import { IconButton } from '@fluentui/react';

export const CopyIcon = styled(IconButton).attrs({
  iconProps: { iconName: 'Copy' },
  ariaLabel: 'Copy'
})`
  margin: 0 10px;
  margin-top: 5px;
`;
