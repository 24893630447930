// common data attributes
export const pageScrollerAttr = 'data-page-scroller';

export const dataAutomation = 'data-automation';
export const dataAutomationIsOnboarded = `${dataAutomation}-is-onboarded`;
export const dataAutomationIsMediaRecordingOnboarded = `${dataAutomation}-is-media-recording-onboarded`;
export const dataAutomationUserRoles = `${dataAutomation}-user-roles`;
export const dataAutomationIsCICallPaneEnabled = `${dataAutomation}-is-ci-call-pane-enabled`;
export const dataAutomationIsLoading = `${dataAutomation}-is-loading`;
export const dataAutomationHighlightSection = `${dataAutomation}-highlight-section`;
export const dataAutomationParticipantId = `${dataAutomation}-participant-id`;

// data-automation values
export const AUTOMATION_SELECTORS = {
  PAGE_SECTION: 'page-section',
  PAGE_HEADER: 'page-header',
  PAGE_CARD: 'page-card',
  PAGE_INSIGHT: 'page-insight',
  CHART: 'chart',
  KPI: 'kpi',
  EXPANDABLE_CARD: 'expandable-card'
};

// selectors
export const pageScrollerSelector = `[${pageScrollerAttr}="true"]`;
