import React, { useState } from 'react';
import { forEach, get, map, slice, toLower } from 'lodash';
import { ActionButton, IconButton, Image } from '@fluentui/react';
import { useInfra } from '../../../../../../providers/InfraProvider';
import {
  Container,
  HeaderContainer,
  TitleContainer,
  Title,
  Content,
  chevronButtonStyles,
  getActionButtonStyles,
  SuggestionContainer,
  FooterSuggestionGroup,
  getShowAllStyles,
  SuggestionActions,
  timestampStyles,
  ActionMenuButton,
  dividerStyles,
  addSuggestionIconProps,
  addedSuggestionIconProps,
  moreIconProps,
  getChevronIconProps,
  createTaskDividerStyles,
  PartnerHighlightContainer,
  partnerImageStyles,
} from './StyledSuggestionGroup';
import {
  FlexRowCentered,
  StyledLink,
  StyledText,
} from '../../../../../../utils/styles/StyledUtils';
import { useToggler } from '../../../../../../hooks/use-toggler';
import { durationToTime } from '../../../../../../utils/date/date';
import { CALL_STRINGS } from '../../../../strings';
import { eventAction } from '../../../../../../services/telemetry-service';
import { TRACK_IDS } from '../../../../tracking';
import { TrackedLink } from '../../../../../../components/TrackedLink';
import { InfoIcon } from '../../../../../../components/InfoIcon/InfoIcon';
import { Tooltip } from '../../../../../../components/Tooltip';
import { SanitizedRichText } from '../../../../../../components/SanitizedRichText';
import { InternalMeetingHighlightsDisclaimer } from '../InternalMeetingHighlightsDisclaimer/InternalMeetingHighlightsDisclaimer';
import { HighlightsCallDurationEmptyState } from '../HighlightsCallDurationEmptyState/HighlightsCallDurationEmptyState';

const ADDED_LABEL_INTERVAL = 4000;
const DEFAULT_DISPLAYED_SUGGESTION_NUMBER = 2;
const status = {
  Requested: 'Requested',
};

export const SUGGESTION_GROUP_TYPES = {
  HIGHLIGHTS: 'HIGHLIGHTS',
  ACTION_ITEMS: 'ACTION_ITEMS',
  PARTNER_HIGHLIGHTS: 'PARTNER_HIGHLIGHTS',
};

export const PartnerHighlight = ({ shouldShowDivider, partnerImage, partnerName }) => {
  const { t } = useInfra();
  return (
    <PartnerHighlightContainer data-testid="partner-highlight">
      {shouldShowDivider && <StyledText styles={createTaskDividerStyles}>·</StyledText>}
      {(partnerImage || partnerName) && (
        <StyledText styles={createTaskDividerStyles}>
          {t(CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_PARTNER_HIGHLIGHTS_SOURCE)}
        </StyledText>
      )}
      {partnerImage && (
        <Image styles={partnerImageStyles} src={`data:image/jpeg;base64,${partnerImage}`} />
      )}
      {partnerName && <StyledText>{partnerName}</StyledText>}
    </PartnerHighlightContainer>
  );
};

export const Timestamp = ({ startInSeconds, endInSeconds, t }) => (
  <StyledLink
    data-testid="highlight-timestamp"
    aria-label={t(CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_TIMESTAMP_TOOLTIP)}
    styles={timestampStyles}
  >
    {`${durationToTime(startInSeconds)}${endInSeconds ? ` - ${durationToTime(endInSeconds)}` : ''}`}
  </StyledLink>
);

export const SuggestionItem = ({
  suggestion,
  onAddClicked,
  onSuggestionClicked,
  onSelectActionItem,
  type,
  index,
  isFullSize,
}) => {
  const { t, isEditable, trackEvent, CreateTaskComponent } = useInfra();
  const {
    content,
    startInSeconds,
    endInSeconds,
    fragmentStartIndex,
    speakerId,
    partnerName,
    partnerImage,
  } = suggestion;

  const [isAdded, setIsAdded] = useState(false);
  const isFirstSuggesionItem = index === 0;
  const isActionItem = type === SUGGESTION_GROUP_TYPES.ACTION_ITEMS;

  const onAddSuggestion = () => {
    if (isAdded) return;
    onAddClicked([suggestion]);
    setIsAdded(true);
    setTimeout(() => setIsAdded(false), ADDED_LABEL_INTERVAL);
  };

  const addTooltipText = t(CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_ADD_ENABLED_TOOLTIP);
  const shouldShowTimestamp = startInSeconds !== undefined;
  const isValidFragmentStartIndex = fragmentStartIndex >= 0;
  return (
    <Tooltip
      content={!partnerName && t(CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_TIMESTAMP_TOOLTIP)}
      onTooltipVisible={() =>
        trackEvent({
          action: eventAction.hover,
          actionOn: TRACK_IDS.EXEC_SUMMARY_SUGGESTION_TIMESTAMP,
          message: { type, speakerId, fragmentIndex: fragmentStartIndex },
        })
      }
    >
      <SuggestionContainer
        isFullSize={isFullSize}
        data-testid="suggestion-container"
        onClick={() => {
          if (onSelectActionItem) {
            onSelectActionItem(suggestion);
          }
          if (isValidFragmentStartIndex) {
            onSuggestionClicked(suggestion);
          }
        }}
      >
        <Content multiline>{partnerName ? <SanitizedRichText html={content} /> : content}</Content>
        <SuggestionActions>
          {isEditable && !partnerName && (
            <>
              <Tooltip
                content={addTooltipText}
                onTooltipVisible={() =>
                  trackEvent({
                    action: eventAction.hover,
                    actionOn: TRACK_IDS.EXEC_SUMMARY_ADD_SUGGESTION_TO_RECAP,
                    message: { type, speakerId, mode: 'single' },
                  })
                }
              >
                <ActionButton
                  ariaLabel={addTooltipText}
                  styles={getActionButtonStyles(isAdded)}
                  text={t(isAdded ? CALL_STRINGS.ADDED : CALL_STRINGS.ADD)}
                  onClick={onAddSuggestion}
                  iconProps={isAdded ? addedSuggestionIconProps : addSuggestionIconProps}
                  data-tour-index={isFirstSuggesionItem ? '1' : null}
                  data-testid="add-suggestion-button"
                />
              </Tooltip>
              <StyledText styles={dividerStyles}>·</StyledText>
            </>
          )}
          <FlexRowCentered>
            {shouldShowTimestamp && (
              <Timestamp t={t} startInSeconds={startInSeconds} endInSeconds={endInSeconds} />
            )}
            {CreateTaskComponent && isActionItem && (
              <>
                <StyledText styles={createTaskDividerStyles}>·</StyledText>
                <CreateTaskComponent actionItem={suggestion} />
              </>
            )}
            {!!partnerName && (
              <PartnerHighlight
                shouldShowDivider={shouldShowTimestamp}
                partnerName={partnerName}
                partnerImage={partnerImage}
              />
            )}
          </FlexRowCentered>
        </SuggestionActions>
      </SuggestionContainer>
    </Tooltip>
  );
};

export const SuggestionGroup = ({
  insightsProcessingStatus,
  isInternalMeeting,
  suggestions,
  title,
  onAddClicked,
  onSelectSuggestion,
  onSelectActionItem,
  type,
  onEdit,
  infoIconText,
  isFullSize,
}) => {
  const isHighlightsGroup = type === SUGGESTION_GROUP_TYPES.HIGHLIGHTS;
  const { t, trackEvent, isEditable, CreateFeedbackComponent } = useInfra();
  const [isShowingAll, toggleIsShowingAll] = useToggler();
  const [isCollapsed, toggleIsCollapsed] = useToggler();
  const displayedSuggestions = isShowingAll
    ? suggestions
    : slice(suggestions, 0, DEFAULT_DISPLAYED_SUGGESTION_NUMBER);
  const highlightsProcessingStatus = get(insightsProcessingStatus, 'executiveSummary') || status.Requested;
  const shouldRenderHighlightsEmptyState = (isHighlightsGroup && highlightsProcessingStatus !== status.Requested);
  const isShowAllLinkHidden = get(suggestions, 'length') <= DEFAULT_DISPLAYED_SUGGESTION_NUMBER;

  const add = (suggestionsToAdd, isAll) => {
    onEdit();
    onAddClicked(suggestionsToAdd);
    forEach(suggestionsToAdd, ({ speakerId }) =>
      trackEvent({
        action: eventAction.click,
        actionOn: TRACK_IDS.EXEC_SUMMARY_ADD_SUGGESTION_TO_RECAP,
        message: { type, speakerId, mode: isAll ? 'all' : 'single' },
      })
    );
  };

  const showAll = () => {
    toggleIsShowingAll();
    trackEvent({
      action: eventAction.click,
      actionOn: TRACK_IDS.EXEC_SUMMARY_SEE_ALL_SUGGESTIONS,
      message: { type, isShowingAll },
    });
  };

  const selectSuggestion = (suggestion) => {
    onSelectSuggestion(suggestion);

    const { speakerId, fragmentIndex } = suggestion || {};
    trackEvent({
      action: eventAction.click,
      actionOn: TRACK_IDS.EXEC_SUMMARY_SUGGESTION_TIMESTAMP,
      message: { type, speakerId, fragmentIndex },
    });
  };

  const moreActionsProps = {
    onItemClick: () => add(suggestions, true),
    items: [
      {
        key: 'addAll',
        text: `${t(CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_ADD_ALL)} ${toLower(title)}`,
      },
    ],
  };

  if (!get(suggestions, 'length')) {
    return null;
  }

  return (
    <Container isFullSize={isFullSize} data-testid={`suggestion-group-container-${type}`}>
      <HeaderContainer isFullSize={isFullSize} data-testid="header-container">
        <TitleContainer>
          <Title>{title}</Title>
          {infoIconText && (
            <InfoIcon
              tooltipContent={infoIconText}
              onTooltipVisible={() =>
                trackEvent({
                  action: eventAction.hover,
                  actionOn: TRACK_IDS.EXEC_SUMMARY_SUGGESTION_GROUP_INFO_TOOLTIP,
                  message: type,
                })
              }
            />
          )}
        </TitleContainer>
        <div>
          {isEditable && (
            <ActionMenuButton
              menuProps={moreActionsProps}
              iconProps={moreIconProps}
              ariaLabel="More actions"
              onMenuClick={() =>
                trackEvent({
                  action: eventAction.click,
                  actionOn: TRACK_IDS.EXEC_SUMMARY_SUGGESTIONS_MORE_ACTIONS_MENU,
                  message: type,
                })
              }
            />
          )}
          <IconButton
            styles={chevronButtonStyles}
            ariaLabel={isCollapsed ? 'extend' : 'collapse'}
            iconProps={getChevronIconProps(isCollapsed)}
            onClick={toggleIsCollapsed}
          />
        </div>
      </HeaderContainer>
      {!isCollapsed && (
        <>
          {isInternalMeeting && isHighlightsGroup && <InternalMeetingHighlightsDisclaimer />}
          {shouldRenderHighlightsEmptyState ?
            <HighlightsCallDurationEmptyState /> :
            (map(displayedSuggestions, (suggestion, index) => (
            <SuggestionItem
              key={suggestion?.id}
              suggestion={suggestion}
              onAddClicked={add}
              onSuggestionClicked={selectSuggestion}
              onSelectActionItem={onSelectActionItem}
              type={type}
              index={index}
              isFullSize={isFullSize}
            />
            )))}
      <FooterSuggestionGroup>
        <TrackedLink
          styles={getShowAllStyles(isShowAllLinkHidden)}
          onClick={showAll}
          trackEvent={trackEvent}
        >
          {t(
            isShowingAll
              ? CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_SEE_LESS
              : CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_SEE_ALL
          )}
        </TrackedLink>
            {!shouldRenderHighlightsEmptyState && CreateFeedbackComponent && (<CreateFeedbackComponent id={type} />)}
      </FooterSuggestionGroup>
        </>
      )}
    </Container>
  );
};
