export const CLIENT_ACTIVITY_ID = 'client-activity-id';
export const ERROR_CLIENT_ACTIVITY_ID = 'error_client-activity-id';
export const ORG_ID_HEADER_NAME = 'x-ms-client-org-id';
export const SESSION_ID_HEADER_NAME = 'client-session-id';

export const BASE_URLS = {
  DEFAULT_API_SERVER: 'DEFAULT_API_SERVER',
  NEW_API_SERVER: 'NEW_API_SERVER',
  MEDIA_RECORDING_SERVER: 'MEDIA_RECORDING_SERVER',
  MICROSOFT_GRAPH_SERVER: 'MICROSOFT_GRAPH_SERVER',
  CRM_SERVER: 'CRM_SERVER'
};

export const TELEMETRY_EVENTS_API_PATH = '/user/v1.0-preview/telemetry/events';

const HOUR = 60 * 60000; // 1 hour
export const CACHE_OPTIONS_WINDOW_HOUR = { storage: window, maxAge: HOUR };

export const NETWORK_ERROR_TYPE = {
  NOT_FOUND: 'NOT_FOUND',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR'
};

export const NETWORK_ERROR_STATUS = {
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500
};
