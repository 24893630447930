import React, { useState, useMemo } from 'react';
import useLocale from 'hooks/use-locale';
import { Field, FlexRowCentered, useFetch, Tooltip } from 'ci-common-ui';
import { deleteSellerData } from 'network/data-services/settings';
import { SearchBox, ActionButton } from '@fluentui/react';
import { Table } from '../../../../../../components/Table';
import { PersonaCell } from '../../../../../../components/Table/default-columns';
import TopPerformerCell from '../TopPerformerCell/TopPerformerCell';
import DeleteSellerCell from '../DeleteSellerCell/DeleteSellerCell';
import { Error, INPUT_WIDTH, INPUT_MIN_WIDTH } from '../../../../StyledSettingsModal';

const DeleteColumnTooltip = () => {
  const { t } = useLocale('pages.settings.your_team');

  return (
    <>
      <span>{t('sellers_delete_description')}</span>
    </>
  );
};

const getColumns = ({ topPerformers, deletedSellers, toggleTopPerformer, deleteSeller, isAdmin }, tAbsDefault) => {
  const columns = [{
    key: 'name',
    name: 'common.name',
    fieldName: 'fullName',
    CellComponent: PersonaCell,
  }];

  if (isAdmin) {
    columns.push(
      {
        key: 'delete',
        onTooltipRender: DeleteColumnTooltip,
        minWidth: 150,
        name: 'common.deleted_seller_data',
        fieldName: 'aadId',
        CellComponent: ({ data }) => {
          const deleted = deletedSellers.includes(data);
          return <DeleteSellerCell data={data} deleted={deleted} deleteAction={deleteSeller} />;
        },
      }
    );
  }

  columns.push(
    {
      key: 'topPerformer',
      name: 'common.top_performer',
      fieldName: 'isTopPerformerAgent',
      ariaLabel: tAbsDefault('common.top_performer'),
      hide: true,
      CellComponent: ({ row }) => {
        const isTopPerformer = topPerformers.includes(row.aadId);
        const isDisabled = !row.isOnUserTeam;
        return <TopPerformerCell data={isTopPerformer} row={row} isDisabled={isDisabled} toggleTopPerformer={toggleTopPerformer} />;
      },
    }
  );

  return columns;
};

const TableFieldContent = ({ items, isLoading, error, isAdmin, input, onDeleteSeller, deleteSellerError }) => {
  const { t, tAbsDefault, tDefault } = useLocale('pages.settings.your_team');
  const [deletedSellers, setDeletedSellers] = useState([]);

  const topPerformers = input.value || [];

  const toggleTopPerformer = ({ id, value }) => {
    input.onChange(value ? [...topPerformers, id] : topPerformers.filter((currentId) => currentId !== id));
  };

  const deleteSeller = (id) => {
    setDeletedSellers([...deletedSellers, id]);
    onDeleteSeller(id);
  };

  const columns = useMemo(() => getColumns({ topPerformers, deletedSellers, deleteSeller, toggleTopPerformer, isAdmin }, tAbsDefault), [topPerformers, deletedSellers, isAdmin]);

  return (
    <div>
      {deleteSellerError && <Error>{t('delete_seller.seller_data_deleted_error')}</Error>}
      <Table
        items={items}
        columns={columns}
        emptyStateTitle={t('sellers_empty')}
        emptyStateSubtitle={null}
        isLoading={isLoading}
        error={error && t('sellers_error')}
        ariaLabel={tDefault('grid_aria_label')}
      />
    </div>
  );
};

const SellersTable = ({ fieldName, sellers, isLoading, error, isAdmin, onSearchChange, onRefresh }) => {
  const { t } = useLocale('pages.settings.your_team');
  const tooltipId = 'refreshButtonTooltip';
  const { fetchData: onDeleteSeller, error: deleteSellerError } = useFetch((id) => deleteSellerData(id));

  return (
    <div>
      <FlexRowCentered>
        <SearchBox
          placeholder={t('search')}
          onChange={(e, v) => onSearchChange(v)}
          ariaLabel="Search for a user"
          styles={{ root: { width: INPUT_WIDTH, minWidth: INPUT_MIN_WIDTH } }}
        />
        <Tooltip content={t('refresh_now_label')} id={tooltipId}>
          <ActionButton iconProps={{ iconName: 'Refresh' }} onClick={onRefresh} aria-describedby={tooltipId}>{t('refresh_now')}</ActionButton>
        </Tooltip>
      </FlexRowCentered>

      <Field
        name={fieldName}
        render={({ input }) => (
          <TableFieldContent
            items={sellers}
            isLoading={isLoading}
            error={error && t('sellers_error')}
            input={input}
            isAdmin={isAdmin}
            onDeleteSeller={onDeleteSeller}
            deleteSellerError={deleteSellerError}
          />
        )}
      />

    </div>
  );
};

export default SellersTable;
