import React, { createContext, useState, useContext } from 'react';

export const initialAppState = {
  isLoading: true,
  isProvisioningInProgress: false,
  isSoftError: false,
  modalName: null,
  notificationBars: [],
  isEnabledBannerProcessing: true,
  leavingPagePromptMessage: null
};

const StateContext = createContext({});

export const AppStateProvider = ({ children }) => {
  const [appState, setState] = useState(initialAppState);

  const setAppState = (state) => setState((currentAppState) => ({ ...currentAppState, ...state }));

  return <StateContext.Provider value={{ appState, setAppState }}>{children}</StateContext.Provider>;
};
export const useAppState = () => useContext(StateContext);
