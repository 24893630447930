import styled from 'styled-components';
import { IconButton, PrimaryButton, MessageBar } from '@fluentui/react';
import { colors } from '../../../../../../constants/colors';
import { FlexRow, FlexColumn } from '../../../../../../utils/styles/StyledUtils';
import { rtl } from '../../../../../../utils/styles/rtl';

export const Container = styled(FlexColumn)`
  justify-content: center;
  margin-top: 16px;
`;

export const Title = styled(FlexColumn)`
  
`;

export const ActionItemText = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

export const ActionItemDescription = styled.div`
  font-size: 12px;
  color: ${colors.Gray130};
  font-weight: 600;
`;

export const FieldLabel = styled.div`
  font-size: 12px;
  color: ${colors.Gray130};
`;

export const AutoCompleteContainer = styled.div`
  max-width: 400px;
  margin-top: 4px;
  margin-bottom: 8px;
`;

export const PeoplesFieldContainer = styled.div`
  max-width: 400px;
  margin-top: 4px;
  margin-bottom: 8px;
`;

export const MessageBarStyled = styled(MessageBar)`
  margin-top: 10px;
`;

export const FormActions = styled(FlexRow)`
  &>* {
    ${rtl`margin-right: 8px;`}  
  }
  margin-top: 16px;
`;

export const SaveButton = styled(PrimaryButton).attrs({
  type: 'submit',
  iconProps: { iconName: 'AddEvent' }
})``;

export const deleteButtonStyles = {
  root: {
    height: '32px'
  }
};

export const ClearFieldButton = styled(IconButton).attrs({
  iconProps: { iconName: 'Cancel' },
  ariaLabel: 'Clear',
  styles: { root: { marginLeft: '4px', color: colors.DynamicsRed } }
})`
`;

export const DetailsFieldContainer = styled(FlexRow)`
  align-items: center;
  height: 32px;
`;
