import { useEffect, useRef, useState } from 'react';
import * as uuid from 'uuid';
import { CallProcessingProxyService } from '../services/CallProcessingProxyService';

const useMessage = () => {
  const [message, setMessage] = useState(null);
  const ref = useRef(null);
  ref.current = message;
  return [message, setMessage, ref];
};

let connectionTimeoutId = null;

export const callProcessingTimeoutTypes = {
  CONNECTION_TIMEOUT: 'CONNECTION_TIMEOUT',
  PROCESSING_ENDED_TIMEOUT: 'PROCESSING_ENDED_TIMEOUT'
};

export const useCallProcessing = (url, shouldOpenConnection, askMode, connectionTimeout, userEmail, socketIframeSrc, id = uuid.v4()) => {
  const [lastTranscriptMessage, setLastTranscriptMessage, lastTranscriptMessageRef] = useMessage(null);
  const [lastMRStateChangeMessage, setLastMRStateChangeMessage, lastStateChangeMessageRef] = useMessage(null);
  const [lastHighlightMessage, setLastHighlightMessage, lastHighlightMessageRef] = useMessage(null);
  const [lastCallSummaryMessage, setLastCallSummaryMessage, lastCallSummaryMessageRef] = useMessage(null);
  const [mode, setMode] = useState(null);
  const [error, setError] = useState(null);

  const messageHandlers = {
    onModeChanged: setMode,
    onError: setError,
    onMRStateChangeMessage: setLastMRStateChangeMessage,
    onTranscriptMessage: setLastTranscriptMessage,
    onHighlightMessage: setLastHighlightMessage,
    onCallSummaryMessage: setLastCallSummaryMessage
  };

  const service = useRef(new CallProcessingProxyService({ userEmail, iframeSrc: socketIframeSrc, id, messageHandlers }));

  const { startConnection, closeConnection } = service.current;

  useEffect(() => closeConnection, []);

  const clearTimeoutConnectionCheck = () => {
    if (connectionTimeoutId) {
      clearTimeout(connectionTimeoutId);
    }
  };

  useEffect(() => {
    if (error) {
      closeConnection();
      clearTimeoutConnectionCheck();
    }
  }, [error]);

  useEffect(() => {
    if (url) {
      const start = async () => {
        startConnection(url, askMode);
        clearTimeoutConnectionCheck();

        if (connectionTimeout) {
          connectionTimeoutId = setTimeout(() => {
            if (!lastStateChangeMessageRef.current && !lastTranscriptMessageRef.current && !lastHighlightMessageRef.current && !lastCallSummaryMessageRef.current) {
              setError(callProcessingTimeoutTypes.CONNECTION_TIMEOUT);
            }
          }, connectionTimeout);
        }
      };

      if (shouldOpenConnection) {
        start();
      } else {
        closeConnection();
      }
    }
  }, [url, shouldOpenConnection]);

  return {
    lastMRStateChangeMessage,
    lastTranscriptMessage,
    lastHighlightMessage,
    lastCallSummaryMessage,
    closeConnection,
    error,
    mode
  };
};
