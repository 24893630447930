import styled from 'styled-components';
import { FlexRow, rtl } from 'ci-common-ui';

export const SubtitleContainer = styled(FlexRow)`
  align-items: center;
  margin-bottom: 52px;
  &>div{
    width: 30%;
    ${rtl`margin-right: 10px;`}
  }
`;
