import noop from 'lodash/noop';
import { CALL_PROCESSING_MESSAGE_TYPES } from '../constants/socket-messages';
import { CRM_POST_MESSAGE_TYPES } from '../constants/post-messages';
import { createIframe } from '../utils/dom/dom';
import { jsonParse } from '../utils/object';

export const CI_IFRAME_TITLE = 'ci-auth-app-iframe';

const transformFragment = (message) => {
  const newMessage = { ...message };
  const { position } = message.data;
  newMessage.data.instances = [{
    startInSeconds: position.offset / 1000,
    endInSeconds: (position.offset + position.duration) / 1000
  }];
  return newMessage;
};

const requestSocketDataFromIframe = ({ src, userEmail, title, appendTo = document.body, extraData = {} }) => {
  const onLoad = async (iframe) => {
    iframe.contentWindow.postMessage({ msg: CRM_POST_MESSAGE_TYPES.PROXY_SOCKET_REQUEST, loginHint: userEmail, ...extraData }, '*');
  };
  createIframe({ src, title, appendTo, onLoad });
};

export class CallProcessingProxyService {
  id;
  url;
  mode;
  iframeSrc;
  userEmail;
  onError = noop;
  socketMessageHandlers;

  constructor({ userEmail, iframeSrc, id, messageHandlers }) {
    this.iframeSrc = iframeSrc;
    this.id = id;
    this.userEmail = userEmail;
    this.initMessageHandlers(messageHandlers);
    window.addEventListener('message', this.handleMessage, false);
  }

  updateMode = (message, onChangeMode) => {
    const { currentMode } = message;
    onChangeMode(currentMode);
  };

  initTranscript = (message, onHighlightMessage, onTranscriptMessage) => {
    const { data: { fragments, highlights } } = message;

    fragments.forEach((fragment) => onTranscriptMessage({ data: fragment }));
    onHighlightMessage({ data: highlights });
  };

  initMessageHandlers = (messageHandlers) => {
    const onTranscriptMessage = (message) => messageHandlers.onTranscriptMessage(transformFragment(message));

    const onMRStateChangeMessage = (message) => messageHandlers.onMRStateChangeMessage(message);

    this.socketMessageHandlers = {
      [CALL_PROCESSING_MESSAGE_TYPES.CHANGE_MODE]: (message) => this.updateMode(message, messageHandlers.onModeChanged),
      [CALL_PROCESSING_MESSAGE_TYPES.INIT_TRANSCRIPT]: (message) => this.initTranscript(message, messageHandlers.onHighlightMessage, onTranscriptMessage),
      [CALL_PROCESSING_MESSAGE_TYPES.FRAGMENT]: onTranscriptMessage,
      [CALL_PROCESSING_MESSAGE_TYPES.MEDIA_RECORDING_STATE]: onMRStateChangeMessage,
      [CALL_PROCESSING_MESSAGE_TYPES.FRAGMENT_INSIGHT]: messageHandlers.onHighlightMessage,
      [CALL_PROCESSING_MESSAGE_TYPES.CALL_SUMMARY]: messageHandlers.onCallSummaryMessage,
    };

    this.onError = messageHandlers.onError;
  }

  handleMessageByType = (message) => {
    const messageHandler = this.socketMessageHandlers[message.type];
    if (!messageHandler) {
      // eslint-disable-next-line no-console
      console.error('Unknown message', message);
    }
    messageHandler(message);
  }

  handleMessage = ({ data }) => {
    if(data.id !== this.id || data.msg !== CRM_POST_MESSAGE_TYPES.PROXY_SOCKET_MESSAGE_RECEIVED){
      return;
    }

    const message = jsonParse(data.data);
    if (message.error) {
      this.onError(message.error);
      return;
    }

    this.handleMessageByType(message);
  };

  startConnection = (url, mode) => {
    this.url = url || this.url;
    this.mode = mode || this.mode;
    requestSocketDataFromIframe({ src: this.iframeSrc, userEmail: this.userEmail, title: CI_IFRAME_TITLE, extraData: { id: this.id, type: CALL_PROCESSING_MESSAGE_TYPES.START_CONNECTION, url, mode } });
  };

  closeConnection = () => {
    requestSocketDataFromIframe({ src: this.iframeSrc, userEmail: this.userEmail, title: CI_IFRAME_TITLE, extraData: { id: this.id, type: CALL_PROCESSING_MESSAGE_TYPES.CLOSE_CONNECTION } });
  };
}
