import isNil from 'lodash/isNil';
import { filter, find, get, uniq } from 'lodash';
import { formatRound } from '../../../utils/numeric/numeric';
import { CRM_ENTITY_TYPES } from '../../../constants/crm-entities';
import { EMPTY_GUID } from '../../../constants/api';
import { PARTICIPANTS_ROLES } from '../../../constants/filters';

// Diff percentage of value1 *from* value2 - can be negative if value1 is smaller from value2. (i.e 6 is different from 8 by 2 => and 2 is 25% from 8 => 6 is different from 8 by -25%)
export const calculateDiffPercentage = (value1, fromValue2) => !value1 || !fromValue2 ? 0 : formatRound(((value1 - fromValue2) / fromValue2) * 100);

export const calcTalkToListenRatio = (talkPercentage) => (
  !isNil(talkPercentage) ? `${formatRound(talkPercentage * 100)}:${formatRound((1 - talkPercentage) * 100)}` : '0:0'
);

export const transformTags = (tags) => {
  const parsedTags = typeof tags === 'string' ? JSON.parse(tags) : tags;
  const transformedTags = parsedTags && parsedTags.map(({ tag, Tag }) => Tag || tag);
  return uniq(transformedTags);
};

export const transformCrm = (crm) => crm && {
  [CRM_ENTITY_TYPES.CONTACT]: crm.contacts,
  [CRM_ENTITY_TYPES.OPPORTUNITY]: crm.opportunity,
  [CRM_ENTITY_TYPES.LEAD]: crm.lead,
  [CRM_ENTITY_TYPES.ACCOUNT]: crm.accounts,
  [CRM_ENTITY_TYPES[`${get(crm, 'activity.type') === 'appointment' ? 'APPOINTMENT' : 'PHONECALL'}_ACTIVITY`]]: crm.activity,
};

export const isEmptyOrFalsy = (guid) => !guid || guid === EMPTY_GUID;

const getNameFromCrmObject = (crm, crmIdFromParticipants) => {
  const idFromCrmObject = get(crm, 'contacts[0].id');
  return idFromCrmObject === crmIdFromParticipants && get(crm, 'contacts[0].name');
};

export const getFirstNonEmptyCustomerDetails = (participants, crm) => {
  // For demo data we return also customer that has only displayName (if no customers have crmId)
  const allCustomers = filter(participants, ({ typeId, role }) => [typeId, role].includes(PARTICIPANTS_ROLES.customer));
  const firstNonEmptyCustomer = find(allCustomers, ({ crmId }) => !isEmptyOrFalsy(crmId)) || find(allCustomers, ({ displayName }) => displayName);
  const crmIdFromParticipants = get(firstNonEmptyCustomer, 'crmId');
  const displayNameFromParticipants = get(firstNonEmptyCustomer, 'displayName');
  return firstNonEmptyCustomer && [{ id: crmIdFromParticipants, name: displayNameFromParticipants || getNameFromCrmObject(crm, crmIdFromParticipants) }];
};
