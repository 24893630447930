import React from 'react';
import { Container, Title, Subtitle, Icon } from './StyledTabLoading';

export const TabLoading = ({ title, subtitle }) => (
  <Container>
    <Icon />
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
  </Container>
);
