import styled from 'styled-components';
import { PrimaryButton } from '@fluentui/react';
import { FlexRowCentered, colors } from 'ci-common-ui';

export const panelStyles = {
  headerText: {
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 40,
    marginBottom: 30
  }
};

export const Content = styled.div`
  line-height: 22px;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 2px;
`;

export const Text = styled.div`
  margin-bottom: 20px;

  a {
    display: block;
  }
`;
export const DownloadContainer = styled(FlexRowCentered)`
  margin: 10px 0;
`;

export const CopyBtn = styled(PrimaryButton)`
  margin: 0 -11px;
`;

export const DownloadBtn = styled(FlexRowCentered)`
  margin: 0 4px;
  
  i {
     margin: 0 4px;
  }
`;

export const Preview = styled.div`
  background-color: ${colors.Gray20};
  padding: 1px 15px;
  pre {
   overflow: auto;
  }
`;
