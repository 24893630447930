export default ({ yAxisFormatter }) => ({
  yAxis: {
    labels: {
      ...(yAxisFormatter
        ? {
          formatter() {
            return yAxisFormatter(this);
          },
        }
        : {}),
    },
  },
});
