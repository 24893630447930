import React from 'react';
import useLocale from 'hooks/use-locale';
import { TextField } from '@fluentui/react';
import { domUtils } from 'ci-common-ui';
import { CopyBtn } from '../StyledErrorPanel';

const { copyToClipboardByElement } = domUtils;

const copyActivityIdInputSelector = 'copy-activity-id-input';

const copy = () => {
  copyToClipboardByElement(copyActivityIdInputSelector);
};

const ActivityId = ({ id }) => {
  const { t, tAbs } = useLocale('pages.system_monitoring.error_panel');

  return (
    <TextField
      id={copyActivityIdInputSelector}
      label={t('activity_id')}
      value={id}
      readOnly
      onRenderSuffix={() => <CopyBtn onClick={copy}>{tAbs('common.copy')}</CopyBtn>}
      errorMessages={{ required: tAbs('common.required') }}
    />
  );
};

export default ActivityId;
