import styled from 'styled-components';
import { Icon, Nav } from '@fluentui/react';
import { colors, FlexRow, rtl } from 'ci-common-ui';
import { FontSizes } from '@uifabric/fluent-theme';

export const Container = styled.div`
  width: 250px;
`;

export const StyledNav = styled(Nav)`
`;

export const navStyles = {
  groupContent: {
    marginBottom: '0'
  },
  compositeLink: {
    selectors: {
      '&:active, &:focus-within, &.is-selected': {
        backgroundColor: colors.Gray10
      },
      '.ms-Nav-link': {
        backgroundColor: 'transparent'
      },
    },
  }
};
export const personaStyles = {
  primaryText: {
    color: colors.Gray160
  }
};

export const SellerNavItemContainer = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${colors.Gray160};
`;

export const SellerNavItemActions = styled(FlexRow)`
  align-items: center;
`;

export const UploadedFilesCountPill = styled.span`
  background-color: ${colors.Gray20};
  padding: 0 5px;
  border-radius: 50%;
  font-size: ${FontSizes.size10};
  line-height: normal;
  ${rtl`margin-right: 8px;`}
`;

export const AddSeller = styled(FlexRow)`
  align-items: center;
  cursor: pointer;
  height: 44px;
  color: ${colors.Gray130};
  ${rtl`margin-left: 29px;`}
  &:hover {
    color: ${colors.Black};
  }
`;

export const AddIcon = styled(Icon).attrs({
  iconName: 'Add'
})`
  ${rtl`margin-right: 8px;`}
  border: 1px dashed ${colors.Gray130};
  border-radius: 50%;
  padding: 3px;
`;
