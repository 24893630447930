import React, { useState } from 'react';
import { storageService, FEATURES } from 'ci-common-ui';
import { getIsOnboarded, getIsTrialMode } from '../../../../utils/app-context/app-context';
import SetupAppBanner from './SetupAppBanner';
import { isFeatureEnabled } from '../../../../utils/app-context/feature-flighting';
import ProvisionBanner from './ProvisionBanner';

const DEMO_APP_BANNER_DISMISSED = 'DEMO_APP_BANNER_DISMISSED';

const SetupAppBannerContainer = () => {
  const [isBannerShown, setIsBannerShown] = useState(
    !storageService.localStorage.getItem(DEMO_APP_BANNER_DISMISSED) && (!getIsOnboarded() || getIsTrialMode()),
  );

  if (!isBannerShown) {
    return null;
  }

  const onBannerCloseClicked = () => {
    storageService.localStorage.setItem(DEMO_APP_BANNER_DISMISSED, true);
    setIsBannerShown(false);
  };

  return isFeatureEnabled(FEATURES.TRY_AND_BUY)
    ? <ProvisionBanner onCloseClicked={onBannerCloseClicked} />
    : <SetupAppBanner onCloseClicked={onBannerCloseClicked} />;
};

export default SetupAppBannerContainer;
