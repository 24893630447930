import React from 'react';
import deepEquals from 'lodash/isEqual';
import { Field } from 'ci-common-ui';
import { MappingsTable } from './components/MappingsTable';
import { AddNewMapping } from './components/AddNewMapping';
import { getAllMappedSourceUsers } from './utils';

export const ViewAs = ({ isLoading }) => (
  <Field
    name="viewAs"
    isEqual={deepEquals}
    render={({ input }) => {
      const allMappings = input.value;
      const onAddMapping = (newMapping) => input.onChange([newMapping, ...allMappings]);

      return (
        <div>
          <AddNewMapping onAddMapping={onAddMapping} allMappedSourceUsers={getAllMappedSourceUsers(allMappings)} isLoading={isLoading} />
          <MappingsTable allMappings={allMappings} onInputChange={input.onChange} isLoading={isLoading} />
        </div>
      );
    }}
  />
);
