import ReactDOM from 'react-dom';
import { scroller } from 'react-scroll';
import DOMPurify from 'dompurify';

const DEFAULT_SCROLL_DURATION = 400;
const DEFAULT_SCROLL_DELAY = 100;
// eslint-disable-next-line no-underscore-dangle
let _isRTL;

export const sanitize = (dirty, options = {}) => ({ __html: DOMPurify.sanitize(dirty, options) });

export const getHTMLDirection = () => {
  if (_isRTL !== undefined) {
    return _isRTL ? 'rtl' : 'ltr';
  }
  const html = document.querySelector('html').getAttribute('dir');
  const body = document.querySelector('body').getAttribute('dir');
  const RTL_DIRECTION = html || body || 'ltr';
  _isRTL = RTL_DIRECTION === 'rtl';

  return RTL_DIRECTION;
};

export const isDirectionRTL = () => {
  if (_isRTL !== undefined) {
    return _isRTL;
  }

  const RTL_BOOLEAN = getHTMLDirection() === 'rtl';
  _isRTL = RTL_BOOLEAN;

  return RTL_BOOLEAN;
};

export const setDirectionRTL = (dir) => {
  const html = document.querySelector('html');
  html.setAttribute('dir', dir);

  _isRTL = dir === 'rtl';
};

export const isTextRTL = (t) => {
  // If the browser doesn’t support this, it probably doesn’t support Unicode 5.2
  if (!('getBoundingClientRect' in document.documentElement)) return false;

  // Set up a testing DIV
  const d = document.createElement('div');
  d.style.position = 'absolute';
  d.style.visibility = 'hidden';
  d.style.width = '2000px';
  d.style.height = 'auto';
  d.style.fontSize = '10px';
  d.style.fontFamily = "'Ahuramzda'";
  d.appendChild(document.createTextNode(t));
  const s1 = document.createElement('span');
  s1.appendChild(document.createTextNode(t));
  d.appendChild(s1);
  const s2 = document.createElement('span');
  s2.appendChild(document.createTextNode(t));
  d.appendChild(s2);
  d.appendChild(document.createTextNode(t));
  const bodies = document.getElementsByTagName('body');
  if (bodies) {
    const body = bodies[0];
    body.appendChild(d);
    const r1 = s1.getBoundingClientRect();
    const r2 = s2.getBoundingClientRect();
    body.removeChild(d);
    return r1.left > r2.left;
  }
  return false;
};

export function scrollTo(elementName, scrollerContainerId) {
  scroller.scrollTo(elementName, {
    duration: DEFAULT_SCROLL_DURATION,
    delay: DEFAULT_SCROLL_DELAY,
    smooth: true,
    spy: true,
    containerId: scrollerContainerId,
    offset: 0,
    ignoreCancelEvents: true,
  });
}

export const getComponentHtml = (Component) => {
  const container = document.createElement('div');
  ReactDOM.render(Component, container);
  return container.innerHTML;
};

export const copyToClipboardByElement = (elementId) => {
  const copyText = document.getElementById(elementId);
  copyText.select();
  copyText.setSelectionRange(0, 99999);
  document.execCommand('copy');
};

export const copyToClipboardAsText = (htmlText) => {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = htmlText;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
};

export const copyToClipboardAsHtml = (htmlText) => {
  const el = document.createElement('div');
  el.innerHTML = htmlText;
  document.body.appendChild(el);
  const range = document.createRange();
  const selection = window.getSelection();
  selection.removeAllRanges();
  range.selectNodeContents(el);
  selection.addRange(range);
  document.execCommand('copy');
  document.body.removeChild(el);
  selection.removeAllRanges();
};

const IS_LOADED_CLASS_NAME = 'content_loaded';

export const createIframe = ({
  src,
  title,
  id,
  appendTo = document.body,
  allow = 'autoplay; camera; microphone',
  onLoad,
  isHidden = true,
}) => {
  const existingIframe =
    document.querySelector(`iframe[src="${src}"]`) || document.getElementById(id);
  if (existingIframe && onLoad) {
    if (existingIframe.classList.contains(IS_LOADED_CLASS_NAME)) {
      onLoad(existingIframe);
    } else {
      const intervalId = setInterval(() => {
        if (existingIframe.classList.contains(IS_LOADED_CLASS_NAME)) {
          onLoad(existingIframe);
          clearInterval(intervalId);
        }
      }, 100);
    }
  }
  if (!existingIframe) {
    const i = document.createElement('iframe');
    if (isHidden) {
      i.style.display = 'none';
    }
    i.src = src;
    if (title) {
      i.title = title;
    }
    if (id) {
      i.id = id;
      i.name = id;
    }
    if (allow) {
      i.allow = allow; // 'autoplay' to allow playing sounds from iframe without user interaction first
    }
    if (onLoad) {
      i.onload = () => {
        onLoad(i);
        i.classList.add(IS_LOADED_CLASS_NAME);
      };
    }
    appendTo.appendChild(i);
  }
};
