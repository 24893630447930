import React from 'react';
import useLocale from 'hooks/use-locale';
import { Link } from '../../../../../../../components/Link/Link';
import { errorsMap } from '../errorsMap';
import { Text } from '../StyledErrorPanel';

const FixLinks = ({ errorCode }) => {
  const { t, tAbs } = useLocale('pages.system_monitoring.error_panel');
  const { fixLink, secondFixLink, customLinkName, customSecondLinkName, translationKey } = errorsMap[errorCode];

  return (
    <Text>
      {t(`${translationKey}.fix`)}
      {fixLink
      && (
        <Link href={fixLink} target="_blank">
          {customLinkName ? t(`${translationKey}.fix_link_name`) : tAbs('common.learn_more')}
        </Link>
      )}
      {secondFixLink
      && (
        <Link href={secondFixLink} target="_blank">
          {customSecondLinkName ? t(`${translationKey}.second_fix_link_name`) : tAbs('common.learn_more')}
        </Link>
      )}
    </Text>
  );
};

export default FixLinks;
