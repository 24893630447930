import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { FlexRowCentered, StyledText } from '../../utils/styles/StyledUtils';

export const modalStyles = {
  main: {
    minHeight: 'auto'
  }
};

export const Container = styled.div`
  padding: 16px 24px 24px;
  min-width: 560px;
`;

export const Header = styled(FlexRowCentered)`
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Title = styled(StyledText)`
  color: ${colors.Gray160};
  font-size: 20px;
`;

export const Content = styled(StyledText)`
  color: ${colors.Gray130};
`;

export const Footer = styled(FlexRowCentered)`
  justify-content: flex-end;
  margin-top: 16px;
`;

export const cancelButtonStyles = {
  root: {
    marginLeft: '8px'
  }
};

export const closeIconStyles = {
  root: {
    color: colors.Gray160,
    selectors: {
      ':hover': {
        color: colors.DYNPrimary
      }
    }
  }
};
