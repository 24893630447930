import React, { useEffect } from 'react';
import { StepRoot } from '../../../../../components/Wizard/StyledWizard';
import StepForm from '../../../../../components/Wizard/StepForm/StepForm';
import useLocale from '../../../../../hooks/use-locale';
import { SectionTitle } from '../../../../../components/Modal/StyledModal';
import { SubtitleContainer } from './StyledUploadCalls';
import Bullet from '../../../../../components/Modal/components/Bullet/Bullet';
import UploadCallsPanel from '../../../../../components/UploadCallsPanel/UploadCallsPanel';
import { componentLoadingTimeService } from '../../../../../services/component-loading-time-service/component-loading-time-service';
import { TRACK_IDS } from '../../../../../constants/tracking';

const UploadCallsStep = ({ handleSettings, settings, next, back, translationPrefix }) => {
  const { t } = useLocale(translationPrefix);

  useEffect(() => {
    componentLoadingTimeService.end(TRACK_IDS.SOURCES.TRIAL_SETUP_MODAL);
  }, []);

  const onSubmit = (value) => {
    handleSettings(value);
    next();
  };

  return (
    <StepRoot>
      <StepForm
        onSubmit={onSubmit}
        initialValues={settings}
        back={back}
        nextButtonText={t('upload_calls')}
        submitNote={t('submit_note')}
      >
        <SectionTitle>{t('main_title')}</SectionTitle>
        <SubtitleContainer>
          <Bullet iconName="Phone">{t('subtitle_min_calls')}</Bullet>
          <Bullet iconName="LocaleLanguage">{t('subtitle_default_language')}</Bullet>
          <Bullet iconName="FavoriteStar">{t('subtitle_top_performers')}</Bullet>
        </SubtitleContainer>
        <UploadCallsPanel
          settings={settings}
          handleSettings={handleSettings}
        />
      </StepForm>
    </StepRoot>
  );
};

export default UploadCallsStep;
