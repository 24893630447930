/* eslint-disable react/no-danger */
import React from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { StyledLink, StyledText } from '../../utils/styles/StyledUtils';
import { BoldText, UnderlineText } from './StyledSanitiziedRichText';

export const defaultOptions = {
  ALLOWED_TAGS: ['b', 'a', 'u', 'strong', 'span'],
  ALLOWED_ATTR: ['href'],
};

const Text = ({ children }) => <StyledText multiline>{children}</StyledText>;

export const Link = ({ href, children }) => {
  const Component = href ? StyledLink : Text;
  return (
    <Component href={href} target="_blank">
      {children}
    </Component>
  );
};

const mapTagToComponent = {
  a: Link,
  span: Text,
  b: BoldText,
  strong: BoldText,
  u: UnderlineText,
};

export const getSanitizedHtmlElements = (html, options, onSanitizeFailed) => {
  try {
    const sanitizedHtml = DOMPurify.sanitize(html, { ...defaultOptions, ...options });
    const parsedHtml = parse(sanitizedHtml);
    return Array.isArray(parsedHtml) ? parsedHtml : [parsedHtml];
  } catch (e) {
    if (onSanitizeFailed) {
      onSanitizeFailed(e);
    }
    return [];
  }
};

export const SanitizedRichText = ({ html, options, onSanitizeFailed }) => {
  const elements = getSanitizedHtmlElements(html, options, onSanitizeFailed);

  return (
    <>
      {elements?.map((element) => {
        if (typeof element === 'string') {
          return <Text>{element}</Text>;
        }

        const {
          type: tag,
          props: { children, href },
        } = element;

        const Component = mapTagToComponent[tag] || Text;
        return <Component href={href}>{children}</Component>;
      })}
    </>
  );
};
