/* eslint-disable consistent-return */
import sortBy from 'lodash/fp/sortBy';
import flow from 'lodash/flow';
import filter from 'lodash/filter';
import partialRight from 'lodash/partialRight';
import isEmpty from 'lodash/isEmpty';
import { matchAll } from '../../../utils/string/string';

export const highlightTypes = {
  PLAYBACK: 0,
  CURRENT_SEARCH: 1,
  SEARCH: 2,
  SELECTED_KEYWORD: 3,
  SELECTED_ACTION_ITEMS: 4,
  KEYWORD: 5,
  ACTION_ITEMS: 6,
  NONE: 7
};

export function getKeywordSections(row, keywords, selectedKeyword) {
  let sections = [];
  if (!keywords) return sections;
  keywords.forEach((keywordType) => {
    keywordType.keywords.forEach((keyword) => {
      const hasInstancesByRowId = filter(keyword.instances, ({ fragmentId }) => !fragmentId || fragmentId === row.id).length;
      const isSelectedKeyword = selectedKeyword && keyword.actualWord === selectedKeyword.actualWord;
      if (!hasInstancesByRowId || isSelectedKeyword) return;

      const keywordSections = matchAll(row.text, keyword.actualWord, true).map((occurence) => ({
        ...occurence,
        type: highlightTypes.KEYWORD
      }));
      sections = sections.concat(keywordSections);
    });
  });
  return sections;
}

export function getSelectedKeywordSections(row, selectedKeyword) {
  const hasInstancesByRowId = selectedKeyword && filter(selectedKeyword.instances, ({ fragmentId }) => !fragmentId || fragmentId === row.id).length;
  if (!selectedKeyword || !selectedKeyword.actualWord || !hasInstancesByRowId) return [];

  return matchAll(row.text, selectedKeyword.actualWord, true).map((occurence, i) => ({
    ...occurence,
    type: i === 0 ? highlightTypes.SELECTED_KEYWORD : highlightTypes.KEYWORD
  }));
}
export function getActionItemsSections(row, actionItems, type) {
  if (isEmpty(actionItems)) return [];

  return actionItems.map((selectedActionItem) => {
    if (isEmpty(selectedActionItem) || selectedActionItem.fragmentId !== row.id) {
      return null;
    }
    const { length, offset } = selectedActionItem;
    return { start: offset, end: offset + length, type };
  }).filter((item) => item);
}

export function getPlaybackSections(playback) {
  if (!playback) return [];
  return [{ start: 0, end: playback.length - 1, type: highlightTypes.PLAYBACK }];
}

export function getSearchSections(row, search, currentSearchIndex) {
  if (!search) return [];
  return matchAll(row.text, search).map((occurence, i) => ({
    ...occurence,
    type: i === currentSearchIndex ? highlightTypes.CURRENT_SEARCH : highlightTypes.SEARCH
  }));
}

export function getRowsSearchIndex(transcript, search) {
  if (!search) return [];
  return transcript.map((row) => matchAll(row.text, search).length);
}

export function getOverlappingSections(row, keywords, actionItems, selectedKeywords, search, currentSearchIndex, playback, selectedActionItem) {
  return [
    ...getKeywordSections(row, keywords, selectedKeywords),
    ...getSelectedKeywordSections(row, selectedKeywords),
    ...getActionItemsSections(row, actionItems, highlightTypes.ACTION_ITEMS),
    ...getActionItemsSections(row, [selectedActionItem], highlightTypes.SELECTED_ACTION_ITEMS),
    ...getPlaybackSections(playback),
    ...getSearchSections(row, search, currentSearchIndex),
  ];
}

export function cutOverlappingSections(indexes, rowText) {
  let sections = [{ text: rowText, start: 0, end: rowText.length - 1, type: highlightTypes.NONE }];
  indexes.forEach(({ start, end, type }) => {
    const newSection = [];
    sections.forEach((section) => {
      if (start > section.end || end < section.start) {
        newSection.push(section);
        return;
      }

      if (start > section.start) {
        newSection.push({
          text: rowText.substring(section.start, start),
          start: section.start,
          end: start - 1,
          type: section.type
        });
      }

      newSection.push({
        text: rowText.substring(Math.max(start, section.start), Math.min(end, section.end) + 1),
        start: Math.max(start, section.start),
        end: Math.min(end, section.end),
        type: Math.min(section.type, type)
      });

      if (end < section.end) {
        newSection.push({
          text: rowText.substring(end + 1, section.end + 1),
          start: end + 1,
          end: section.end,
          type: section.type
        });
      }
    });
    sections = [...newSection];
  });
  return sections;
}

export function unifySectionsByType(sections) {
  const result = [];
  sections.forEach((sec) => {
    if (!result.length) return result.push(sec);
    const lastElement = result[result.length - 1];

    if (lastElement.type !== sec.type) return result.push(sec);
    result[result.length - 1] = { ...lastElement, end: sec.end, text: lastElement.text + sec.text };
  });
  return result;
}

export const getHighlightSections = (
  row,
  keywords,
  actionItems,
  selectedKeywords,
  search,
  currentSearchIndex,
  playback,
  selectedActionItem
) => flow([
  getOverlappingSections,
  sortBy('start'),
  partialRight(cutOverlappingSections, row.text),
  unifySectionsByType
])(row, keywords, actionItems, selectedKeywords, search, currentSearchIndex, playback, selectedActionItem);
