import React from 'react';
import { Tooltip } from 'ci-common-ui';
import { TooltipOverflowMode } from '@fluentui/react';
import useLocale from 'hooks/use-locale';
import {
  ChartContainer,
  InsightDetails,
  Description,
  Title,
  InsightCardContainer,
  ShowDetailsLink,
  CardName,
  StyledInsightCardLoader,
  TitleAndChart,
  CardNameText
} from './StyledInsightCard';
import Chart from '../../../../components/Chart/Chart';
import withEmptyState from '../../../../components/WithEmptyState/with-empty-state';
import { ExportToCSV } from '../../../../components/ExportToCSV/ExportToCSV';

const InsightCard = ({
  key, trackId, csvData, title, description, getChartConfig, onShowDetails, errorComponent, cardName, isLoading,
}) => {
  const { t } = useLocale('common');
  const hasShowDetails = !!onShowDetails;

  return (
    errorComponent || (
      <InsightCardContainer key={key}>
        {
          isLoading
            ? <StyledInsightCardLoader />
            : (
              <>
                <TitleAndChart>
                  <CardName>
                    <CardNameText>
                      <Tooltip content={cardName} overflowMode={TooltipOverflowMode.Parent}>{cardName}</Tooltip>
                    </CardNameText>
                    <ExportToCSV data={csvData} trackId={trackId} />
                  </CardName>
                  <ChartContainer>
                    <Chart config={getChartConfig()} trackId={trackId} keepRefreshing />
                  </ChartContainer>
                </TitleAndChart>
                <InsightDetails hasShowDetails={hasShowDetails}>
                  <Title multiline>{title}</Title>
                  <Description multiline>{description}</Description>
                  {
                    hasShowDetails
                      ? <ShowDetailsLink onClick={onShowDetails} role="link">{t('show_details')}</ShowDetailsLink>
                      : <div>&nbsp;</div>
                  }
                </InsightDetails>
              </>
            )
        }
      </InsightCardContainer>
    )
  );
};

export default withEmptyState(InsightCard);
