export const MEASUREMENT_UNIT = {
  percent: 'percent',
  ms: 'ms',
  wpm: 'wpm',
  secs: 'secs'
};

export const PARTICIPANTS_ROLES = {
  agent: 'agent',
  customer: 'customer'
};

export const TIMELINE_BLOCKS_COUNT = 100;
