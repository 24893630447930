import styled from 'styled-components';
import { FontSizes, Icon } from '@fluentui/react';
import { FlexRow, colors } from 'ci-common-ui';

export const RelatedRecordsTitle = styled(FlexRow)`
justify-content: ${({ isEditMode }) => isEditMode ? 'space-between' : 'flex-start'};
color: ${colors.Black};
margin-bottom: 10px;
`;

export const EditIcon = styled(Icon).attrs({
  iconName: 'Edit'
})`
color: ${colors.DYNPrimary};
padding: 0px 10px;
transform: translateY(3px);
font-size: ${FontSizes.size14};
:hover { cursor: pointer; }
`;

export const Section = styled.div`
border-radius: 25px;
${({ isEditMode }) => isEditMode && `
    background-color: ${colors.Gray10};
    padding: 8px;
    border-radius: 5px;
    transition: background-color 1.5s, border-radius 1.5s;
    `};
margin-bottom: ${({ marginBottom }) => marginBottom || 25}px;
`;

export const ErrorMessage = styled.div`
  color: red;
`;
