import styled from 'styled-components';
import { DefaultButton, Icon } from '@fluentui/react';
import { TIMELINE_GROUP_MARGIN } from '../../utils';

export const TimelinesContainer = styled.div`
   ${({ height }) => height && `
    height: ${height}px;
  `}
`;

export const TimelineHeaderList = styled.div`
  display: none;
  ${({ isOpen }) => isOpen && `
    background-color: ${({ theme }) => theme.colors.Gray10};
    display: block;
  `}
  ${({ isSingle }) => isSingle && `
    display: block;
  `}
  ${({ isWithMargin, isSingle }) => isWithMargin && !isSingle && `
    margin-bottom: ${TIMELINE_GROUP_MARGIN}px;
  `}
`;

export const TimelineCustomHeaderContainer = styled(DefaultButton)`
  cursor: pointer;
  line-height: 14px;
  padding: 0;
  border: none;
  width: auto;

  &:hover {
    color: ${({ theme }) => theme.colors.DYNPrimary};
  }
`;

export const TimelineCustomHeaderCount = styled.div`
  margin: 0 5px;
`;

export const TimelineCustomHeaderClose = styled(Icon).attrs({
  iconName: 'ChevronDown'
})`
  ${({ isOpen }) => isOpen && `
    transform: rotate(180deg);
  `}
`;
