import React from 'react';
import SectionCard from '../SectionCard/SectionCard';
import PageSection from './PageSection';
import { CardsRow } from '../../StyledPages';
import InsightCard from '../../HomePage/components/InsightCard/InsightCard';

export const SectionLoaders = ({ title, subtitle, count }) => (
  <PageSection title={title} subtitle={subtitle}>
    <CardsRow>
      {Array(count)
        .fill(0)
        .map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <SectionCard key={index} isLoading />
        ))}
    </CardsRow>
  </PageSection>
);

export const InsightCardSectionLoaders = ({ title, subtitle, count }) => (
  <PageSection title={title} subtitle={subtitle}>
    {Array(count)
      .fill(0)
      .map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <InsightCard key={index} isLoading />
      ))}
  </PageSection>
);
