/* eslint-disable no-param-reassign */
import { get, isEmpty, map, orderBy, find, forOwn } from 'lodash';
import { PARTICIPANTS_ROLES } from '../../constants/filters';
import { CALL_STRINGS } from './strings';

export const TIMELINE_ITEM_HEIGHT = 40;
export const TIMELINE_GROUP_MARGIN = 10;

export const SEGMENT_TYPES = {
  untitled: 'UNTITLED',
  greetingsAndIntroduction: 'GREETINGS_AND_INTRODUCTION',
  refund: 'REFUND',
  scheduleMeeting: 'SCHEDULE_MEETING',
  eLearningPlatform: 'E_LEARNING_PLATFORM',
  assistanceRequestAndComplaint: 'ASSISTANCE_REQUEST_AND_COMPLAINT',
  callClosingOrBye: 'CALL_CLOSING_OR_BYE',
  customerIdentificationDetails: 'CUSTOMER_IDENTIFICATION_DETAILS',
  priceQuote: 'PRICE_REQUEST',
  discussion: 'DISCUSSION',
  voiceMail: 'VOICE_MAIL'
};

export const getLast6DigitsFromPhoneNumber = (phoneNumber) => {
  const END_INDEX = 6;
  const START_INDEX = 0;
  if (phoneNumber) {
    phoneNumber = phoneNumber.replaceAll(' ', '').replaceAll('-', '');
    const end = phoneNumber.length;
    const start = end - END_INDEX;
    if (start < START_INDEX) {
      return phoneNumber;
    }
    return phoneNumber.substring(start, end);
  }
  return '';
};

const comparePhoneNumbers = (phoneNumber1, phonenumber2) => getLast6DigitsFromPhoneNumber(phoneNumber1) === getLast6DigitsFromPhoneNumber(phonenumber2);

export const extractParticipantDetails = ({ role, displayName, phoneNumber, crmId, id, aadId }, unknownStr, contactsWithCurrentUserAdditionalData = []) => {
  const isContactRelated = !isEmpty(crmId);
  const isAgent = role === PARTICIPANTS_ROLES.agent;
  const isCustomer = role === PARTICIPANTS_ROLES.customer;
  const currentContact = contactsWithCurrentUserAdditionalData.find(item => comparePhoneNumbers(item.phoneNumber, phoneNumber));
  const { text: contactName, imageUrl } = currentContact || {};
  const primaryText = displayName || phoneNumber || unknownStr;
  const isDisplayNameEqualPhoneNumber = displayName === phoneNumber;

  // This is fix is temporary until the server will return the right participant name
  const isKnownContactName = isCustomer && isDisplayNameEqualPhoneNumber && contactName;

  return {
    primaryText: isKnownContactName ? contactName : primaryText,
    secondaryText: isAgent ? null : phoneNumber && primaryText !== phoneNumber ? phoneNumber : unknownStr,
    imageUrl,
    isContactRelated,
    isAgent,
    isCustomer,
    isUnknown: isCustomer && !isContactRelated && !contactName,
    id,
    aadId
  };
};

export const addToAllUpdatedParticipants = (allUpdatedParticipants, updatedParticipant) => {
  const withoutUpdatedParticipant = allUpdatedParticipants.filter(({ id }) => id !== updatedParticipant.id);
  return [...withoutUpdatedParticipant, updatedParticipant];
};

export const mergeParticipants = (allParticipants, allUpdatedParticipants) => (
  allParticipants.map((participant) => {
    const updatedParticipant = allUpdatedParticipants.find(({ id }) => id === participant.id) || {};
    return { ...participant, ...updatedParticipant };
  })
);

export const setSegmentTitle = (segmentation, t) => segmentation.map((segment) => ({
  ...segment,
  title: (isEmpty(segment.topics) ? [''] : segment.topics).map((topic) => !topic ? '' : t(CALL_STRINGS[`CALL_SEGMENT_TYPES_${SEGMENT_TYPES[topic] || SEGMENT_TYPES.untitled}`])).join(' + ')
}));

export const checkIsOnSegmentRange = (row, selectedSegment) => row.start >= selectedSegment.start && row.end <= selectedSegment.end;

export const setSegmentTime = (segmentation, transcript, durationInSecond) => segmentation.map((segment) => {
  const rowStart = transcript[segment.fragmentStartIndex];
  const rowNext = transcript[segment.fragmentEndIndex + 1];

  const isFirstRow = segment.fragmentStartIndex === 0;
  const isLastRow = get(orderBy(transcript, 'id'), 'length') - 1 === segment.fragmentEndIndex;

  return {
    ...segment,
    start: isFirstRow || !rowStart ? 0 : rowStart.startInSeconds,
    end: isLastRow || !rowNext ? durationInSecond : rowNext.startInSeconds,
  };
});

export const filterKeywordsBySegmentation = (keywords, selectedSegment) => keywords.map((keywordsItem) => ({
  ...keywordsItem,
  keywords: keywordsItem.keywords.map((row) => ({
    ...row,
    instances: row.instances.filter((instance) => isEmpty(selectedSegment)
    || checkIsOnSegmentRange({ start: instance.startInSeconds, end: instance.endInSeconds }, selectedSegment))
  }))
}));

const getFirstRowSegmentHeader = (row, segmentation) => get(segmentation.find(({ start }) => row.startInSeconds === start), 'title');

export const decorateTranscriptBySegment = (transcript, segmentation) => map(transcript, (row) => ({
  ...row,
  header: getFirstRowSegmentHeader(row, segmentation),
}));

export const getParticipantsByRole = (participants, requestedRole) => participants.filter(({ role }) => role === requestedRole);

export const getParticipantById = (participants, requestedId) => find(participants, ({ id }) => id === requestedId);

export const calcTimelinesHeight = (timelineData, isAgentsTimelinesOpen, isCustomersTimelinesOpen, isOnlyAgentsRecorded) => {
  const baseLinesCount = isOnlyAgentsRecorded ? 1 : 2;
  const allAgentsLength = timelineData.agents.detailsTimelines.length;
  const allCustomersLength = timelineData.customers.detailsTimelines.length;
  const showExtraTimelinesCount = (isAgentsTimelinesOpen ? allAgentsLength : 0) + (isCustomersTimelinesOpen ? allCustomersLength : 0);
  const margin = isAgentsTimelinesOpen ? TIMELINE_GROUP_MARGIN : 0;
  return ((showExtraTimelinesCount + baseLinesCount) * TIMELINE_ITEM_HEIGHT) + margin;
};

export const generateContactsTexts = (contacts, unknownText) => {
  if(isEmpty(contacts)) {
    return [unknownText];
  }
  return contacts.map(({text, phoneNumber}) => text?.trim() || phoneNumber || unknownText);
}

const getText = (text, params) => {
  let currentText = text;
  if (params) {
    forOwn(params, (value, paramKey) => {
      currentText = currentText.replace(new RegExp(`%${paramKey}%`, 'g'), value);
    });
  }
  return currentText;
}

export const generateParticipantsIndication = (contacts, unknownText, isCurrentUserIncludedInContactsList) => {
  const contactsCount = isCurrentUserIncludedInContactsList ? contacts?.length - 1 : contacts?.length;
  const contactsTexts = generateContactsTexts(contacts, unknownText);
  if (!contacts || isEmpty(contacts[0]) || contactsCount === 0) {
    return unknownText;
  }
  if (contactsCount === 1) {
    return contactsTexts[0];
  }
  if (contactsCount === 2) {
    return getText(CALL_STRINGS.TWO_PARTICIPANTS_SUBJECT, { identifier1: contactsTexts[0], identifier2: contactsTexts[1] });
  }
  return getText(CALL_STRINGS.MULTIPLE_PARTICIPANTS_SUBJECT, { identifier: contactsTexts[0], number: contactsCount - 1});
}

export const moveTopicsToTopOfKeywordsList = (keywords) => {
  const keywordsWithTopicId = [];
  const keywordsWithoutTopicId = [];
  
  // original order of keywords within keywordsWithTopicId and keywordsWithoutTopicId must be kept
  keywords.forEach(keyword => {
    if (keyword.vivaTopicsId) {
      keywordsWithTopicId.push(keyword);
    }
    else {
      keywordsWithoutTopicId.push(keyword);
    }
  });

  if(keywordsWithTopicId.length === 0 || keywordsWithoutTopicId.length === 0) return keywords;

  const stablePartitionedKeywords  = keywordsWithTopicId.concat(keywordsWithoutTopicId);

  return stablePartitionedKeywords;
}
