import React, { useState, useRef } from 'react';
import { sum, findIndex, take, debounce } from 'lodash';
import { IconButton, SearchBox } from '@fluentui/react';
import { SearchBoxContainer, Actions, Result, Line, searchBoxStyle } from './StyledTranscriptSearch';
import { useInfra } from '../../../../providers/InfraProvider';
import { matchAll } from '../../../../utils/string/string';
import { CALL_STRINGS } from '../../strings';
import { TRACK_IDS } from '../../tracking';
import { eventAction } from '../../../../services/telemetry-service';
import { ARIA_ROLES } from '../../../../constants/roles-list';

const getRowIndex = (rows, index) => {
  let rowIndex = 0;

  return findIndex(rows, (row) => {
    rowIndex += row;
    return rowIndex >= index;
  });
};

function getRowsSearchIndex(transcript, search) {
  if (!search) return [];
  return transcript.map((row) => matchAll(row.text, search).length);
}

const DEBOUNCE_DELAY = 400;

export const useTranscriptSearch = (transcript) => {
  const [search, setSearch] = useState('');
  const [searchIndex, setSearchIndex] = useState(1);
  const rowsIndex = getRowsSearchIndex(transcript, search);
  const rowIndex = getRowIndex(rowsIndex, searchIndex);
  const alreadySeenIndex = sum(take(rowsIndex, rowIndex));
  const total = sum(rowsIndex);

  return {
    search,
    setSearch,
    searchIndex,
    setSearchIndex,
    rowIndex,
    rowSearchIndex: searchIndex - alreadySeenIndex - 1,
    total
  };
};

export const TranscriptSearch = ({ search, searchIndex, total, setSearch, setSearchIndex }) => {
  const { t, trackEvent } = useInfra();
  const shouldShowActions = search && !!total;

  const next = () => {
    const newIndex = searchIndex === total ? 1 : searchIndex + 1;
    trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.TRANSCRIPT_SEARCH_NEXT, message: newIndex });
    return setSearchIndex(newIndex);
  };

  const back = () => {
    const newIndex = searchIndex === 1 ? total : searchIndex - 1;
    trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.TRANSCRIPT_SEARCH_BACK, message: newIndex });
    return setSearchIndex(newIndex);
  };

  const handleChange = (value) => {
    setSearchIndex(1);
    setSearch(value);
    trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.TRANSCRIPT_SEARCH });
  };

  const debounceOnNotesChanged = useRef(debounce((e, value) => {
    handleChange(value);
  }, DEBOUNCE_DELAY));

  return (
    <SearchBoxContainer>
      <SearchBox
        placeholder={t(CALL_STRINGS.SEARCH)}
        onChange={debounceOnNotesChanged.current}
        ariaLabel="Search in the conversation"
        onSearch={next}
        styles={searchBoxStyle}
      />
      {shouldShowActions && (
        <Actions>
          <Result role={ARIA_ROLES.LINK} aria-label={`Search result ${searchIndex} of ${total}`}>
            <span aria-hidden="true">{`${searchIndex}/${total}`}</span>
          </Result>
          <Line />
          <IconButton onClick={back} iconProps={{ iconName: 'ChevronUp' }} aria-label="Previous" />
          <IconButton onClick={next} iconProps={{ iconName: 'ChevronDown' }} aria-label="Next" />
        </Actions>
      )}
    </SearchBoxContainer>
  );
};
