import React from 'react';
import map from 'lodash/map';
import { CardsRow, ChartCardsRowContainer } from '../../StyledPages';
import SectionCard from '../SectionCard/SectionCard';
import Chart from '../../../components/Chart/Chart';
import CardErrorBoundary from '../../../components/ErrorBoundry/CardErrorBoundry';

const ChartCardsRow = ({ isLoading, cards, shimmerHeight }) => (
  <CardsRow>
    {
      map(cards, ({ trackId, header, csvData, getChartConfig, keepRefreshing, scrollViewName, emptyStateDetails, cardStyle, hasError }) => (
        <ChartCardsRowContainer key={header} role="region" aria-label={header} style={cardStyle}>
          <CardErrorBoundary cardName={header}>
            <SectionCard
              trackId={trackId}
              header={header}
              csvData={csvData}
              cardName={header}
              isLoading={isLoading}
              scrollViewName={scrollViewName}
              renderContent={() => (<Chart config={getChartConfig()} trackId={trackId} keepRefreshing={keepRefreshing} />)}
              emptyStateDetails={emptyStateDetails}
              isErrorState={hasError}
              shimmerHeight={shimmerHeight}
            />
          </CardErrorBoundary>
        </ChartCardsRowContainer>
      ))
    }
  </CardsRow>
);

export default ChartCardsRow;
