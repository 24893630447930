export const userRoles = {
  SystemAdministrator: 'SystemAdministrator',
  SalesManager: 'SalesManager',
  SalesPerson: 'SalesPerson'
};

export const SENTIMENT_NAMES = {
  positive: 'positive',
  neutral: 'neutral',
  negative: 'negative'
};

export const LISTENING_AND_TALKING_NAMES = ['listening', 'talking'];

export const LICENSE_USAGE_RESPONSE_TYPES = {
  CapacityUsageExceeded: 'CapacityUsageExceeded',
  Invalid: 'Invalid',
  CapacityUsageOver70Precent: 'CapacityUsageOver70Precent',
  CapacityUsageOver90Precent: 'CapacityUsageOver90Precent',
  Enabled: 'Enabled'
};

export const PROVISION_STATES = {
  None: 0,
  ProvisionInProgress: 1,
  Provisioned: 2,
  ProvisionFailed: 3,
  DeprovisionInProgress: 4,
  Deprovisioned: 5,
  DeprovisionFailed: 6,
  Disconnected: 7
};

export const MEDIA_RECORDING_PROVISION_STATES = {
  NotProvisioned: 'notProvisioned',
  ProvisionInProgress: 'inProgress',
  Provisioned: 'provisioned',
};

export const updateTargetPauseTypes = {
  Pause: 'pause',
  Stop: 'stop'
};

export const recordingTargetType = {
  All: 'all',
  OnlyAgent: 'currentUserOnly',
  OnlyAgents: 'agentsOnly'
};

export const getIsOnlyAgentsOrOnlyAgent = (recordingTarget) => [recordingTargetType.OnlyAgents, recordingTargetType.OnlyAgent].includes(recordingTarget);

export const recordingActionType = {
  Start: 'start',
  Pause: 'pause',
  Stop: 'stop',
  Resume: 'resume',
  UpdateRecordingTarget: 'updateRecordingTarget'
};

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export const DEFAULT_GRAPH_API_IMAGE_SIZE = '48';
