import styled from 'styled-components';
import { Link } from '@fluentui/react';
import { colors } from '../../constants/colors';
import {FlexColumn, StyledFontIcon, StyledText} from '../../utils/styles/StyledUtils';
import EmptyStatePNG from '../../assets/images/empty-state-img.png';

export const NoDataErrorContainer = styled(FlexColumn)`
  height: 100%;
  box-sizing: content-box;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

export const PlugIcon = styled(StyledFontIcon).attrs({
  iconName: 'PlugDisconnected',
})`
  font-size: 48px;
  height: 48px;
  width: 48px;
  color: ${colors.Gray90};
`;

const Text = styled(StyledText).attrs({
  multiline: true,
  variant: 'medium',
})`
  text-align: center;
`;

export const NoDataTitle = styled(Text)`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.Gray160};
  margin-top: 12px;
`;

export const NoDataSubtitle = styled(Text)`
  margin-top: 12px;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.Gray130};
`;

export const EmptyMessageIcon = styled.div`
  height: 60px;
  width: 60px;
  background-image: url(${EmptyStatePNG});
  background-repeat: no-repeat;
`;

export const StyledLink = styled(Link)`
  margin-top: 12px;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.DYNPrimary};
  text-align: center;
`;

export const Container = styled(FlexColumn)`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
`;

export const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
`;