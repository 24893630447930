import React, { useState } from 'react';
import { get, size, sumBy } from 'lodash';
import { Container, tooltipStyles, pivotStyles, PivotContainer } from './StyledSidePivot';
import { useInfra } from '../../../../providers/InfraProvider';
import { TRACK_IDS } from '../../tracking';
import { eventAction } from '../../../../services/telemetry-service';
import { Tooltip } from '../../../../components/Tooltip';
import { ExecSummaryFeatureTour } from '../ExecSummary/ExecSummaryFeatureTour';
import { OverviewPivotItem } from './components/OverviewPivotItem/OverviewPivotItem';
import { NotesPivotItem } from './components/NotesPivotItem/NotesPivotItem';
import { HighlightsPivotItem } from './components/HighlightsPivotItem/HighlightsPivotItem';

export const pivotKeys = {
  Overview: 'Overview',
  Notes: 'Notes',
  Highlights: 'Highlights',
};
const pivotItemsMap = {
  [pivotKeys.Overview]: OverviewPivotItem,
  [pivotKeys.Notes]: NotesPivotItem,
  [pivotKeys.Highlights]: HighlightsPivotItem,
};

const pivotItemsDefaultOrder = [
  pivotKeys.Overview,
  pivotKeys.Notes,
  pivotKeys.Highlights,
];

const onRenderItemLink = ({ headerText, dataTourIndex }) => (
  <Tooltip styles={tooltipStyles} content={headerText}>
    <div data-tour-index={dataTourIndex}>{headerText}</div>
  </Tooltip>
);

const tabsGetMessageData = {
  [pivotKeys.highlights]: ({ keywords }) => ({
    items: sumBy(keywords, ({ keywords: keywordsList }) => size(keywordsList)),
  }),
  [pivotKeys.Notes]: ({ data }) => ({
    isInternalMeeting: data?.isInternalMeeting
  })
};

const getTrackingMessage = ({ itemKey, keywords, data }) => ({
  tabName: itemKey,
  data: tabsGetMessageData[itemKey]?.({ keywords, data }),
});

export const SidePivot = ({
  data,
  conversationId,
  keywords,
  isTopicsLpcComponentReady,
  selectedSegment,
  isLoading,
  selectedKeyword,
  onKeywordClicked,
  onKeywordHover,
  onSelectActionItem,
  onHoverActionItem,
  clearSelectedSegment,
  shouldHideMainHeader,
  duration,
  isExecutiveSummaryEnabled,
  onSelectSuggestion,
  setNewParticipantDetails,
  isOnlyAgentsRecorded,
  pivotItemsOrder,
  avatars
}) => {
  const items = pivotItemsOrder || pivotItemsDefaultOrder;
  const { trackEvent, t, isViva } = useInfra();
  const [selectedTabKey, setSelectedTabKey] = useState(items[0]);

  const onLinkClick = ({ props: { itemKey } }) => {
    setSelectedTabKey(itemKey);
    onSelectActionItem(null);
    onKeywordClicked({});

    const message = getTrackingMessage({ itemKey, keywords, data });
    trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.TAB_CHANGED, message });
  };

  const props = {
    conversationId,
    data,
    shouldHideMainHeader,
    duration,
    setNewParticipantDetails,
    isOnlyAgentsRecorded,
    selectedSegment,
    clearSelectedSegment,
    onRenderItemLink,
    isExecutiveSummaryEnabled,
    onSelectSuggestion,
    isLoading,
    isViva,
    onSelectActionItem,
    onHoverActionItem,
    keywords,
    isTopicsLpcComponentReady,
    selectedKeyword,
    onKeywordClicked,
    onKeywordHover,
    avatars,
    t,
  };

  return (
    <Container>
      <ExecSummaryFeatureTour
        executiveSummarySuggestionsLength={get(data, 'executiveSummary.suggestions.length')}
        goToExecSummaryTab={() => setSelectedTabKey(pivotKeys.Notes)}
        goToOverviewTab={() => setSelectedTabKey(pivotKeys.Overview)}
      />
      <PivotContainer selectedKey={selectedTabKey} onLinkClick={onLinkClick} styles={pivotStyles}>
        {items.map((itemKey) => pivotItemsMap[itemKey](props))}
      </PivotContainer>
    </Container>
  );
};
