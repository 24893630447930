/* eslint-disable no-cond-assign */
import toLower from 'lodash/toLower';
import { DEFAULT_TRACK_DELIMITER } from '../../services/telemetry-service';
import { RTL_LOCALES } from '../../constants/locales';
import { EMPTY_GUID } from '../../constants/api';

const ALLOWED_CHARS_NEXT_TO_WORD = '&?!.,"\'';

export function escapeRegex(str) {
  return String(str).replace(/[\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function matchAll(originString, stringToSearch, isFullWordOnly) {
  const result = [];
  try {
    const escapedRegExp = escapeRegex(stringToSearch.trim());
    const regex = new RegExp(
      isFullWordOnly
        ? `(?<=[\\s,.:;"']|[*${ALLOWED_CHARS_NEXT_TO_WORD}]|^)${escapedRegExp}(?=[\\s,.:;"']|[*${ALLOWED_CHARS_NEXT_TO_WORD}]|$)`
        : escapedRegExp,
      'gi'
    );
    let match = regex.exec(originString);
    while (match) {
      result.push({
        start: match.index,
        end: match.index + stringToSearch.length - 1,
      });
      match = regex.exec(originString);
    }
    return result;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error: matchAll failed. ', e);
    return result;
  }
}

export const joinDelimiter = (stringArr, delimiter) =>
  stringArr.filter((str) => !!str).join(delimiter);

export const joinTrackDelimiter = (stringArr, trackDelimiter = DEFAULT_TRACK_DELIMITER) =>
  joinDelimiter(stringArr, trackDelimiter);

export const isLocaleRTL = (locale = '') => RTL_LOCALES.some((l) => toLower(l) === toLower(locale));

export const blobToBase64 = async (blob) =>
  new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = () => reject();
  });

export const pluralize = (name) => {
  if (!name) return name;
  if (name.endsWith('y')) return `${name.substring(0, name.length - 1)}ies`;
  if (name.endsWith('s') || name.endsWith('x')) return `${name}es`;
  return `${name}s`;
};

export const isGuidEmpty = (guid) => !guid || guid === EMPTY_GUID;