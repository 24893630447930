import map from 'lodash/map';
import { chartConfigs } from 'ci-common-ui';
import { series, translateSeries, plotLineColors } from 'utils/chart';
import { formatNumber, formatPercentage, formatRound } from 'utils/numeric';

const { combineConfigs, splitBar, bar, noStickyTracking, noYAxisText } = chartConfigs;

const AVERAGES_TRANSLATION_KEYS = {
  sellers: 'common.team_avg',
  topSellers: 'common.top_performers_avg',
};

const xAxisFormatterTopPerformer = (value, data) => {
  const agent = data.find(({ agentFullName }) => agentFullName === value);
  return agent && `${value} ${agent.isTopPerformerAgent ? '★' : ''}`;
};

export const createListeningAndTalkingConfig = (data, tAbs) => (
  combineConfigs(
    {
      data: data.data,
      seriesParams: translateSeries(series.listeningAndTalkingParams, tAbs),
      plotLines: map(data.averages, ({ id, value }, index) => ({
        color: plotLineColors[index],
        value,
        labelText: tAbs(AVERAGES_TRANSLATION_KEYS[id]),
        tooltipLabelText: `${tAbs(AVERAGES_TRANSLATION_KEYS[id])} (${formatPercentage(value)})`,
      })),
      categoryField: 'agentFullName',
      xAxisFormatter: ({ value }) => xAxisFormatterTopPerformer(value, data.data),
      dataLabelsFormatter: ({ percentage }) => formatPercentage(percentage),
      shouldAddEmptyDataToStartAndEnd: true
    },
    [splitBar, noYAxisText, noStickyTracking],
  )
);

export const createTalkingSpeedConfig = (data, tAbs) => (
  createBarConfig(
    data,
    'avgWordsPerMin',
    {
      pointFormatter: (value) => tAbs('common.words_per_minute', { num: formatRound(value) }),
      xAxisFormatter: ({ value }) => xAxisFormatterTopPerformer(value, data.data),
    },
    tAbs,
  )
);

export const createSwitchRateConfig = (data, tAbs) => createBarConfig(
  data,
  'avgSwitchRate',
  {
    pointFormatter: (value) => formatRound(value),
    xAxisFormatter: ({ value }) => xAxisFormatterTopPerformer(value, data.data),
  },
  tAbs,
);

export const createPauseSpeakingConfig = (data, tAbs) => createBarConfig(data, 'avgAgentAvgPauseBeforeSpeakingInMs', {
  pointFormatter: (value) => tAbs('common.num_of_seconds', { num: formatNumber(value / 1000, '0.[00]') }),
  xAxisFormatter: ({ value }) => xAxisFormatterTopPerformer(value, data.data),
}, tAbs);

export const createMonologueConfig = (data, tAbs) => createBarConfig(
  data,
  'avgCustomerLongestMonologInMs', {
    pointFormatter: (value) => tAbs('common.num_of_seconds', { num: formatRound(value / 1000) }),
    xAxisFormatter: ({ value }) => xAxisFormatterTopPerformer(value, data.data),
  },
  tAbs,
);

const createBarConfig = (
  data,
  valueField, {
    pointFormatter,
    xAxisFormatter,
  },
  tAbs,
) => (
  combineConfigs(
    {
      data: data.data,
      seriesParams: [{
        key: valueField,
        text: valueField,
        color: series.listeningAndTalkingParams[0].color,
        labelColor: series.listeningAndTalkingParams[0].labelColor
      }],
      plotLines: map(data.averages, ({ id, value }, index) => ({
        color: plotLineColors[index],
        value,
        labelText: tAbs(AVERAGES_TRANSLATION_KEYS[id]),
        tooltipLabelText: `${tAbs(AVERAGES_TRANSLATION_KEYS[id])} (${pointFormatter(value)})`,
      })),
      yAxisFormatter: ({ value }) => pointFormatter(value),
      xAxisFormatter,
      categoryField: 'agentFullName',
      dataLabelsFormatter: ({ y }) => pointFormatter(y),
      textOutline: true
    },
    [bar, noStickyTracking],
  )
);
