import React from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { useFetchNow, FEATURES, useResize } from 'ci-common-ui';
import useLocale from 'hooks/use-locale';
import { getTimeOfTheDay } from 'utils/date/date';
import { getHome } from 'network/data-services/home-service';
import { useQueryDateChange } from 'hooks/use-query-date-change';
import { getDateParams } from 'utils/url/url';
import { usePageLoadTime } from '../../hooks/use-page-load-time';
import { DemoDataPill, HomePageContainer, HomePageContentContainer, Title } from './StyledHomePage';
import SellerInsights from './components/SellerInsights/SellerInsights';
import { CenterContent } from '../../StyledApp';
import OpportunityKpis from './components/OpportunityKpis/OpportunityKpis';
import MomKpis from './components/MomKpis/MomKpis';
import { appContext, getIsOnboarded } from '../../utils/app-context/app-context';
import { isFeatureEnabled } from '../../utils/app-context/feature-flighting';
import CustomerInsights from './components/CustomerInsights/CustomerInsights';
import SetupAppBannerContainer from './components/SetupAppBanner/SetupAppBannerContainer';
import { getIsEmptyPage } from '../components/Page/Page';
import { getSpecificError } from '../../utils/error-state/error-state';
import { TRACK_IDS } from '../../constants/tracking';
import { useAppState } from '../../providers/AppStateProvider';
import PageError from '../components/PageError/PageError';

const HomePage = () => {
  useResize();

  const { t } = useLocale('pages.home');
  const { user, orgId } = appContext;
  const {
    appState: { isSoftError },
  } = useAppState();
  const { data, isLoading, error, fetchData } = useFetchNow(
    (newParams) => getHome({ ...getDateParams(), ...newParams }, orgId),
    { getSpecificError }
  );

  usePageLoadTime(TRACK_IDS.SOURCES.HOME_PAGE, !isLoading);
  useQueryDateChange(fetchData);

  let title;
  if (isFeatureEnabled(FEATURES.TRY_AND_BUY) && !getIsOnboarded()) {
    title = (
      <div>
        <Title>{t('title.not_onboarded')}</Title>
        <DemoDataPill>{t('title.demo_data')}</DemoDataPill>
      </div>
    );
  } else {
    title = !isEmpty(data) && (
      <Title>{t(`title.${getTimeOfTheDay()}_title`, { name: user.name.split(' ')[0] })}</Title>
    );
  }

  const fullTimeRange = data && data.dateTimeRange;
  const isEmptyPage = getIsEmptyPage(fullTimeRange, isLoading);

  return (
    <HomePageContainer data-testid="home-page">
      {isSoftError ? (
        <PageError />
      ) : (
        <>
          {!isFeatureEnabled(FEATURES.TRY_AND_BUY) && <SetupAppBannerContainer />}
          <CenterContent
            isEmptyPage={isEmptyPage}
            autoHeight={!isFeatureEnabled(FEATURES.TRY_AND_BUY)}
          >
            <HomePageContentContainer
              title={title}
              fullTimeRange={fullTimeRange}
              isLoading={isLoading}
              error={error}
            >
              <OpportunityKpis data={get(data, 'opportunityKpis')} isLoading={isLoading} />
              <CustomerInsights data={data} isLoading={isLoading} error={error} />
              <SellerInsights data={data} isLoading={isLoading} error={error} />
              <MomKpis data={get(data, 'momKpis')} />
            </HomePageContentContainer>
          </CenterContent>
        </>
      )}
    </HomePageContainer>
  );
};

export default HomePage;
