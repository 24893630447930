import { useState, useEffect, useRef } from 'react';
import { debounce, get, noop } from 'lodash';
import { useCallProcessing } from '../../hooks/use-call-processing';
import { highlightsFilter, PIVOT_ITEM_IDS } from './utils';
import { CALL_PROCESSING_MODE_TYPES } from '../../constants/socket-messages';

export const callStatusType = {
  CallNotStarted: 'CallNotStarted',
  CallStarting: 'CallStarting',
  CallStarted: 'CallStarted',
  CallCompleted: 'CallCompleted'
};

export const recordingStatusType = {
  RecordingNotStarted: 'RecordingNotStarted',
  RecordingStarted: 'RecordingStarted',
  RecordingPaused: 'RecordingPaused',
  RecordingStopped: 'RecordingStopped',
  InProgress: 'InProgress',
};

// this is left as a sepearte state and not changing recordingStatusType to make sure we dont break any changes to some consumer
const wsRecordingStatus = {
  Stopped: 'stopped',
  NotStarted: 'notStarted',
  Starting: 'starting',
  Started: 'started',
  Stopping: 'stopping',
  Paused: 'paused',
  Pausing: 'pausing',
};

const wsRecordingStatusMapping = {
  [wsRecordingStatus.Stopped]: recordingStatusType.RecordingStopped,
  [wsRecordingStatus.NotStarted]: recordingStatusType.RecordingNotStarted,
  [wsRecordingStatus.Starting]: recordingStatusType.InProgress,
  [wsRecordingStatus.Started]: recordingStatusType.RecordingStarted,
  [wsRecordingStatus.Stopping]: recordingStatusType.InProgress,
  [wsRecordingStatus.Paused]: recordingStatusType.RecordingPaused,
  [wsRecordingStatus.Pausing]: recordingStatusType.InProgress
};

const DEBOUNCE_TIME = 2000;
const CLOSE_WS_TIMEOUT = 5000;

export const useCallRealTimeData = ({
  detailsTabComponent,
  contactsWithCurrentUserAdditionalData,
  isRecordingReady,
  contactRecordId,
  contactRecordType,
  userEmail,
  socketIframeSrc,
  callDuration,
  timestamp,
  error,
  onCallProcessingError,
  clearError,
  callStatus = callStatusType.CallStarted,
  recordingStatus = recordingStatusType.RecordingNotStarted,
  recordingTarget,
  processingUrl,
  onEditContact,
  initialNotes = {},
  notesSaveStatus,
  onNotesChanged,
  onClose,
  onRecordingStatusChanged,
  onRecordingIdChanged,
  onRecordingTargetChanged,
  startRecording,
  resumeRecording,
  pauseRecording,
  stopRecording,
  updateRecordingTarget,
  language,
  languageOptions,
  onLanguageChanged,
  setLanguage,
  recordButtonTexts,
  userConsentButtonTexts,
  entityTexts,
  detailsTexts,
  notesTexts,
  transcriptTexts,
  postCallText,
  errorOpenDetailsText,
  openErrorDetails,
  renderCallPage,
  isRecordingEnabled,
  isRecordingButtonDisabled,
  isLanguageDropdownDisabled,
  isRecordingButtonHidden,
  showLanguageSelection,
  isUserConsentButtonHidden,
  isOnlyAgentsRecording,
  isOnlyAgentsRecordingCall,
  appClassName,
  onFullSummaryButtonClick = noop,
  hasNotesPermission,
  leavingPagePromptMessage,
  onConfirmationLeavingModalOpen = noop,
  onCallRealtimeMessageRecieved = noop,
  isRecordingStatusOnProcess,
  onSetRegardingRecord,
  onExpandRegardingSuggestedRecord,
  onLoadRegardingRecords,
  injectComponentToEntryPoint,
  shouldShowDetailsTab,
  trackTeachingBubbleEvent,
  isAutoRecording,
  context,
  isDialerFormCustomizationsEnabled,
  provisionProps: {
    org,
    shouldShowProvision,
    shouldShowProvisioningHeader,
    getProvisioningStatus,
    onProvisionOrg,
    provisionTexts
  },
  activityId,
  activityType,
  isBeforeStartRecordingError
}) => {
  const [selectedTabKey, setSelectedTabKey] = useState(hasNotesPermission ? PIVOT_ITEM_IDS.NOTES : PIVOT_ITEM_IDS.TRANSCRIPT);
  const [notes, setNotes] = useState(initialNotes.text || '');
  const [transcript, setTranscript] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const shouldOpenWSConnection = isRecordingReady;
  const { lastMRStateChangeMessage, lastTranscriptMessage, lastHighlightMessage, error: callProcessingError, closeConnection: closeWSConnection } = useCallProcessing(processingUrl, shouldOpenWSConnection, CALL_PROCESSING_MODE_TYPES.IN_CALL, null, userEmail, socketIframeSrc);
  const debounceOnNotesChanged = useRef(debounce((newNotes) => {
    onNotesChanged(newNotes);
  }, DEBOUNCE_TIME));

  const isCallCompleted = callStatus === callStatusType.CallCompleted;
  const isRecordingStarted = recordingStatus !== recordingStatusType.RecordingNotStarted;

  useEffect(() => {
    if (isCallCompleted) {
      setTimeout(closeWSConnection, CLOSE_WS_TIMEOUT);
    }
  }, [isCallCompleted]);

  const onNotesChange = (newNotes) => {
    setNotes(newNotes);
    debounceOnNotesChanged.current(newNotes);
  };

  const lastTranscriptMessageFragmentId = get(lastTranscriptMessage, 'data.id');
  const lastMRStateChangeMessageConversationId = get(lastMRStateChangeMessage, 'id');
  const { status: lastMRStateChangeMessageRecordingStatus, language: lastMRStateChangeMessageLanguage, targets: lastMRStateChangeMessageTargets } = get(lastMRStateChangeMessage, 'data') || {};

  useEffect(() => {
    if (lastTranscriptMessage) {
      const { data } = lastTranscriptMessage;
      const transcriptItem = {
        ...data,
        startInSeconds: data.position.offset / 1000,
      };
      setTranscript([...transcript, transcriptItem]);
      onCallRealtimeMessageRecieved({ type: lastTranscriptMessage.type, fragmentId: data.id, id: lastTranscriptMessage.id });
    }
  }, [lastTranscriptMessageFragmentId]);

  useEffect(() => {
    if (lastMRStateChangeMessageConversationId) {
      onRecordingIdChanged(lastMRStateChangeMessageConversationId);
    }
  }, [lastMRStateChangeMessageConversationId]);

  useEffect(() => {
    const newRecordingStatus = wsRecordingStatusMapping[lastMRStateChangeMessageRecordingStatus];
    if (lastMRStateChangeMessageRecordingStatus) {
      onRecordingStatusChanged(newRecordingStatus);
    }
  }, [lastMRStateChangeMessageRecordingStatus]);

  useEffect(() => {
    if (lastMRStateChangeMessageLanguage) {
      setLanguage(lastMRStateChangeMessageLanguage);
    }
  }, [lastMRStateChangeMessageLanguage]);

  useEffect(() => {
    if (lastMRStateChangeMessageTargets) {
      onRecordingTargetChanged(lastMRStateChangeMessageTargets);
    }
  }, [lastMRStateChangeMessageTargets]);

  useEffect(() => {
    if (lastHighlightMessage) {
      const highlights = highlightsFilter(lastHighlightMessage.data);
      setKeywords(highlights.keywords);
      onCallRealtimeMessageRecieved({ type: lastHighlightMessage.type, fragmentId: lastHighlightMessage.fragmentId, id: lastHighlightMessage.id });
    }
  }, [lastHighlightMessage]);

  useEffect(() => {
    if (callProcessingError && onCallProcessingError) {
      onCallProcessingError(callProcessingError);
    }
  }, [callProcessingError]);

  const onSaveAndClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const onTabChanged = ({ props: { itemKey } }) => {
    setSelectedTabKey(itemKey);
  };

  return {
    context,
    isDialerFormCustomizationsEnabled,
    isCallCompleted,
    detailsTabComponent,
    error,
    isRecordingButtonHidden,
    isOnlyAgentsRecording,
    shouldShowProvision,
    shouldShowProvisioningHeader,
    contactsWithCurrentUserAdditionalData,
    contactRecordId,
    contactRecordType,
    callDuration,
    timestamp,
    onEditContact,
    entityTexts,
    detailsTexts,
    notesTexts,
    isOnlyAgentsRecordingCall,
    postCallText,
    isRecordingButtonDisabled,
    isLanguageDropdownDisabled,
    recordingStatus,
    recordingTarget,
    recordingStatusType,
    startRecording,
    resumeRecording,
    pauseRecording,
    stopRecording,
    updateRecordingTarget,
    language,
    languageOptions,
    onLanguageChanged,
    clearError,
    openErrorDetails,
    recordButtonTexts,
    errorOpenDetailsText,
    userConsentButtonTexts,
    showLanguageSelection,
    isUserConsentButtonHidden,
    selectedTabKey,
    onTabChanged,
    notes,
    notesSaveStatus,
    onNotesChange,
    isRecordingEnabled,
    isRecordingStarted,
    hasNotesPermission,
    transcriptTexts,
    transcript,
    keywords,
    org,
    getProvisioningStatus,
    onProvisionOrg,
    provisionTexts,
    leavingPagePromptMessage,
    onConfirmationLeavingModalOpen,
    renderCallPage,
    appClassName,
    onSaveAndClose,
    isRecordingStatusOnProcess,
    isRecordingReady,
    onFullSummaryButtonClick,
    onSetRegardingRecord,
    onLoadRegardingRecords,
    injectComponentToEntryPoint,
    shouldShowDetailsTab,
    trackTeachingBubbleEvent,
    isAutoRecording,
    onExpandRegardingSuggestedRecord,
    activityId,
    activityType,
    isBeforeStartRecordingError
  };
};
