import styled from 'styled-components';
import { FlexRow, TextField, DropdownField, rtl } from 'ci-common-ui';

const FIELD_WIDTH = '380px';

export const Row = styled(FlexRow)`
  justify-content: space-between;
  height: 100px;
  
  & > div {
    ${rtl`margin-right: 20px`}; 
  }
`;

export const StyledTextField = styled(TextField).attrs({
  width: FIELD_WIDTH
})``;

export const StyledDropdownField = styled(DropdownField).attrs({
  width: FIELD_WIDTH
})``;
