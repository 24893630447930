import styled from 'styled-components';
import { Icon } from '@fluentui/react';
import { colors } from '../../../../../../constants/colors';
import { FlexRow, StyledText, StyledLink, FlexColumn } from '../../../../../../utils/styles/StyledUtils';
import { rtl } from '../../../../../../utils/styles/rtl';

export const Container = styled(FlexColumn)`
  justify-content: center;
  border-bottom: 1px solid ${colors.Gray30};
  border-radius: 2px;
  background-color: ${colors.White};
  padding: 16px;
`;

export const TextContainer = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;
  min-height: 32px;
`;

export const Description = styled(StyledText)`
  ${rtl`margin-left: 10px`};
  color: ${colors.Gray130};
  font-size: 12px;

  ${({ isCommitted }) => isCommitted && `
    color: ${colors.DYNPrimary};
    cursor: pointer;
    &:hover{
      color: ${colors.DYNShade30};
    }
  `}
`;

export const DescriptionContainer = styled(FlexRow)`
  align-items: center;
  margin-bottom: 4px;
  height: 16px;
`;

export const Timestamp = styled.div`
  width: 48px;
  font-size: 12px;
  color: ${colors.Gray160};
`;

export const Text = styled(StyledLink)`
  font-weight: ${(props) => props['data-committed'] ? 'normal' : '600'};

  && {
    max-width: 460px;
    color: ${colors.Black};
  }

  &:hover {
    && {
      color: ${colors.DYNPrimary};
      text-decoration: none;
    }
  }
  && {
    @media (forced-colors: active) {
      color: ${colors.White};
     && :hover,
      :focus {
        color: ${colors.MangoOrange};
      }
    }
  }
`;

export const MenuButtonContainer = styled.div`
  width: 30px;
`;

export const ActionItemCommitMessage = styled.div`
  color: ${colors.DYNPrimary};
  ${rtl`margin-left: 44px;`}
`;

export const DirtyFormIcon = styled(Icon).attrs({
  iconName: 'Edit'
})`
  ${rtl`margin-left: 4px;`}
  color: ${colors.DynamicsRed};
  position: relative;
  top: 2px;
`;
