import map from 'lodash/map';
import { dayJS } from '../../../../utils/date/date';

export default ({ data, categoryField, categoryFieldType }) => ({
  xAxis: {
    categories: map(data, (item) => {
      if (categoryFieldType === 'date') {
        return dayJS(item[categoryField]);
      }

      return item[categoryField];
    }),
  },
});
