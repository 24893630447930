const MAX_FILE_DURATION = 10;
export const ACCEPT_FORMAT = ['.mp3', '.wav', '.ogg'];

const checkFileData = (file) => new Promise((res) => {
  const AudioContext = window.AudioContext || window.webkitAudioContext;
  const audioCtx = new AudioContext();
  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  reader.onload = (ev) => {
    audioCtx.decodeAudioData(ev.target.result).then((buffer) => res({
      duration: buffer.duration / 60,
      isStereo: buffer.numberOfChannels === 2,
    }));
  };
  reader.readAsArrayBuffer(file);
});

const getFileNameExtension = (file) => file.name.split('.').pop();

export const getFileNameWithoutExtension = (file) => file.name.split('.').slice(0, -1).join('.');

export const validateAudioFile = async (file) => {
  const extension = getFileNameExtension(file);

  if (!ACCEPT_FORMAT.includes(`.${extension}`)) {
    return 'file_type_error';
  }

  const { isStereo, duration } = await checkFileData(file);

  if (duration > MAX_FILE_DURATION) {
    return 'duration_error';
  }

  if (!isStereo) {
    return 'mono_error';
  }

  return null;
};
