import React, { useEffect, useRef } from 'react';

export const ClickOutside = ({ onClickOutside, children }) => {
  const ref = useRef();

  useEffect(() => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target) && onClickOutside) {
        onClickOutside(e);
      }
    };
    const handleEsc = (e) => {
      if (e.key === 'Escape') {
        onClickOutside(e);
      }
    };

    document.body.addEventListener('mousedown', handleClick);
    document.body.addEventListener('keydown', handleEsc);
    return () => {
      document.body.removeEventListener('mousedown', handleClick);
      document.body.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return <div ref={ref}>{children}</div>;
};
