import React from 'react';
import { renderToString } from 'react-dom/server';
import { chartConfigs, Trend, StyledText, arrayUtils, getIsSmallMobile } from 'ci-common-ui';
import map from 'lodash/map';
import maxBy from 'lodash/maxBy';

const { combineConfigs, yAxisLabels, xAxisLabels, dataLabels, tooltip, noLegend } = chartConfigs;
const { immutableReverse } = arrayUtils;

const dataLabelsFormatter = (pointObject) => {
  if (getIsSmallMobile()) {
    return renderToString(<div>{pointObject.point.value}</div>);
  }
  const BubbleTitle = (
    <div>
      <StyledText>{pointObject.point.value}</StyledText>
      {
        pointObject.point.trend ? <>&nbsp;<Trend value={pointObject.point.trend} /></> : null
      }
    </div>
  );
  return renderToString(BubbleTitle);
};

export const createTopTrendingChartConfig = (originalData, color) => {
  const data = immutableReverse(originalData);
  return combineConfigs(
    {
      data,
      dataLabelsFormatter,
      type: 'bar',
      tooltipOptions: {
        getProps: ({ point: { name, value, trend } }) => ({ name, value, trend }),
      },
    },
    [
      yAxisLabels,
      xAxisLabels,
      dataLabels,
      tooltip,
      noLegend,
      {
        accessibility: {
          point: {
            descriptionFormatter: ({ index, name, value, trend }) => {
              let trendString = '';
              if (trend) {
                trendString = `, ${Math.floor(trend * 100)}% ${trend > 0 ? 'in' : 'de'}crease`;
              }
              return `${index + 1}. ${name}, ${value}${trendString}.`;
            }
          }
        },
        type: 'bar',
        xAxis: {
          categories: map(data, 'name'),
          reversed: true
        },
        yAxis: {
          max: maxBy(data, 'value').value * 1.8,
          opposite: false
        },
        series: [{
          data: map(data, (item) => ({ ...item, y: item.value })),
          color
        }],
        plotOptions: {
          series: {
            pointWidth: 20,
            dataLabels: {
              inside: false,
            },
          },
          bar: {
            dataLabels: {
              useHTML: true,
            },
          },
        },
      },
    ],
  );
};
