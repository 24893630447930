import styled from 'styled-components';
import { StyledText } from '../../utils/styles/StyledUtils';

export const UnderlineText = styled(StyledText)`
  text-decoration-line: underline;
`;

export const BoldText = styled(StyledText)`
  font-weight: bold;
`;
