import styled from 'styled-components';
import { rtl } from '../../../../utils/styles/rtl';
import { colors } from '../../../../constants/colors';

export const Container = styled.div`
  padding: 10px 0;
  margin-bottom: 20px;
`;

export const OptionLabelContainer = styled.div`
  ${rtl`padding-left: 30px;`}
   margin-bottom: 10px;
`;

export const Title = styled.div`
  font-weight: 600;
`;

export const Description = styled.div`
  color: ${colors.Gray130};
`;

export const DropdownContainer = styled.div`
  ${rtl`padding-left: 30px;`}
`;

export const dropdownStyle = {
  root: { width: 200 }
};
