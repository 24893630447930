import { Dropdown } from '@fluentui/react';
import React from 'react';
import { SUPPORTED_CALL_LOCALES } from 'ci-common-ui';
import { dropdownStyles, DropdownTitle, FormContainer, LanguageValue, LocaleIcon, Mask, StyledTextField } from './StyledSellerBasicDataForm';
import useLocale from '../../../../../../hooks/use-locale';
import { FavoriteIcon, DeleteIcon } from '../../../../StyledUploadCallsPanel';

export default ({
  translationPrefix,
  selectedSeller,
  onSellerNameChanged,
  onSellerLanguageChanged,
  onFavoriteClicked,
  onDeleteSeller,
}) => {
  const { t, tAbs } = useLocale(`${translationPrefix}.seller_basic_data_form`);

  const onRenderTitle = () => (
    <DropdownTitle>
      <LocaleIcon iconName="LocaleLanguage" />
      <Mask>{t('default_call_language')}: </Mask>
      <LanguageValue>{selectedSeller.defaultCallLanguage}</LanguageValue>
    </DropdownTitle>
  );

  return (
    (
      <FormContainer>
        <StyledTextField
          value={selectedSeller.name}
          onChange={(event) => onSellerNameChanged(event.target.value)}
        />
        <Dropdown
          selectedKey={selectedSeller.defaultCallLanguage}
          onChange={(event, selectedOption) => onSellerLanguageChanged(selectedOption.key)}
          options={SUPPORTED_CALL_LOCALES}
          onRenderTitle={onRenderTitle}
          styles={dropdownStyles}
        />
        <FavoriteIcon
          isFavorite={selectedSeller.isFavorite}
          onClick={onFavoriteClicked}
        >
          {t(selectedSeller.isFavorite ? 'unmark_top_performer' : 'mark_top_performer')}
        </FavoriteIcon>
        <DeleteIcon onClick={onDeleteSeller}> {tAbs('common.delete')} </DeleteIcon>
      </FormContainer>
    )
  );
};
