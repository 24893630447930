import React, { useEffect } from 'react';
import useLocale from 'hooks/use-locale';
import { useFetchNow, Trans } from 'ci-common-ui';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import Page from '../../components/Page/Page';
import { getInsightDetails } from '../../../network/data-services/insight-service';
import KeywordsSection from './components/KeywordsSection/KeywordsSection';
import RelevantConversations from '../components/RelevantConversations/RelevantConversations';
import { useQueryDateChange } from '../../../hooks/use-query-date-change';
import { getDateParams } from '../../../utils/url/url';
import { callTypeColumn, dateAndDurationColumn, keywordsColumn, tagsColumn, commentsCountColumn, ownerColumn, callTopicColumn, crmEntitiesLinksColumn } from '../../../components/Table/default-columns';
import BackHomeButton from '../components/BackHomeButton';
import { getSpecificError } from '../../../utils/error-state/error-state';
import { TRACK_IDS } from '../../../constants/tracking';
import { componentLoadingTimeService } from '../../../services/component-loading-time-service/component-loading-time-service';

const LOCALIZATION_PREFIX = 'pages.insight.brands';

const type = 'NewBrands';
const columns = [callTypeColumn, callTopicColumn, { ...keywordsColumn, name: 'common.brands' }, tagsColumn, commentsCountColumn, ownerColumn, dateAndDurationColumn, crmEntitiesLinksColumn];

const InsightBrandsPage = () => {
  const { appModeAwareTerms } = useLocale();
  const dateParams = getDateParams();
  const apiParams = { type, ...dateParams };
  const tableApiParams = { type, ...dateParams };
  const { data, isLoading, error, fetchData } = useFetchNow(() => getInsightDetails(apiParams, 'brands'), { getSpecificError });

  const keywords = get(data, 'topTrendingKeywords');

  /* eslint-disable */
  const keywordsSectionSubtitle = (
    <Trans i18nKey={`${LOCALIZATION_PREFIX}.subtitle`}>first line<br />
        {{ _competitor_plural: appModeAwareTerms._competitor_plural }}
        {{ _department: appModeAwareTerms._department }}
    </Trans>);

  /* eslint-disable */
  const keywordsSectionTitle = !isEmpty(keywords) && !isLoading ? (
    <Trans i18nKey={`${LOCALIZATION_PREFIX}.title`} count={size(keywords)}>
      {{ count: size(keywords) }} fragment1 {{ _department: appModeAwareTerms._department }} fragment3
    </Trans>
  ) : null;
  /* eslint-enable */
  useQueryDateChange(fetchData);

  useEffect(() => {
    componentLoadingTimeService.start(TRACK_IDS.SOURCES.BRANDS_INSIGHT_PAGE);
  }, []);

  return (
    <Page isLoading={isLoading} title={<BackHomeButton />} fullTimeRange={data && data.dateTimeRange} error={error}>
      <KeywordsSection isLoading={isLoading} data={data} title={keywordsSectionTitle} subtitle={keywordsSectionSubtitle} error={error} />
      <RelevantConversations columns={columns} apiParams={tableApiParams} isPageDetailsLoading={isLoading} trackSource={TRACK_IDS.SOURCES.BRANDS_INSIGHT_PAGE} />
    </Page>
  );
};
export default InsightBrandsPage;
