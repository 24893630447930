import React from 'react';
import { PersonaSize } from '@fluentui/react';
import { AvatarPersona } from '../../../../../../../components/AvatarPersona/AvatarPersona';

export const ViewAsPersonaCell = ({ data: { aadId, fullName, email } }) => (
  <AvatarPersona
    aadId={aadId}
    size={PersonaSize.size24}
    text={fullName}
    secondaryText={email}
    showSecondaryText
  />
);
