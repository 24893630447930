import styled from 'styled-components';
import { DefaultButton, PrimaryButton, MessageBar, MessageBarType } from '@fluentui/react';
import { FlexRow, FlexRowCentered, colors, rtl } from 'ci-common-ui';
import { Section, SectionTitle, SectionDescription, INPUT_WIDTH, INPUT_MIN_WIDTH } from '../../components/Modal/StyledModal';
import { AUTOMATION_SELECTORS, dataAutomation } from '../../constants/selectors';

const HEADER_HEIGHT = 70;

export const Root = styled(FlexRow).attrs({
  [dataAutomation]: AUTOMATION_SELECTORS.SETTINGS_MODAL
})`
  width: 100%;
  height: 100%;
`;

export const NavContainer = styled.div`
  background: #F8F8F8;
  width: 200px;
`;

export const NavHeader = styled(FlexRowCentered)`
  height: 70px;
  font-size: 24px;
  border-bottom: 1px solid transparent;
  
  padding: 0 16px;
`;

export const TabContainer = styled.div.attrs({
  role: 'list',
})`
  flex: 1;
  overflow: hidden;
`;

export const TabHeader = styled(FlexRowCentered)`
  height: ${HEADER_HEIGHT}px;
  padding: 0 24px;
  font-size: 24px;
  border-bottom: 1px solid ${colors.Gray300};
  justify-content: space-between;
`;

export const SaveButton = styled(FlexRowCentered)`
  height: ${HEADER_HEIGHT}px;
  padding: 0 24px;
  font-size: 24px;
  border-bottom: 1px solid ${colors.Gray300};
  justify-content: space-between;
`;

export const TabBody = styled.div.attrs({
  'data-is-scrollable': true,
  role: 'listitem',
})`
  padding: 24px;
  height: calc(100% - ${HEADER_HEIGHT}px);
  overflow: auto;
`;

export const Actions = styled(FlexRow)`
  align-items: center;
`;

export const FormStyled = styled.form`
  height: 100%;
`;

export const FormAction = styled(FlexRow)``;

export const FormError = styled(FlexRowCentered)`
  color: ${colors.ScarletRed};
  font-size: 12px;
`;

export const PrimaryButtonStyled = styled(PrimaryButton)`
  margin: 0 8px;
`;

export const DefaultButtonStyled = styled(DefaultButton)`
  margin: 0 8px;
`;

export const TabSection = styled(Section)`
`;

export const TabSectionTitle = styled(SectionTitle)`
`;

export const TabSectionDescription = styled(SectionDescription)`
  ${rtl`margin-right: 20px`};
`;

export const TabTitleText = styled.div.attrs({ as: 'h1' })`
  font-size: 28px;
  font-weight: 400; 
`;

export const StyledTabSubTitle = styled.div.attrs({ as: 'h2' })`
  font-size: 20px;
  font-weight: 600; 
`;

export const Error = styled(MessageBar).attrs({
  messageBarType: MessageBarType.error,
})`
  margin-top: 16px;
  margin-bottom: -16px;
`;

export {
  INPUT_WIDTH, INPUT_MIN_WIDTH
};
