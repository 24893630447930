import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { multiplyBy100, transformSentimentDataForPie, transformSentimentDataForPercentagesBar } from 'utils/chart';
import { transformServerDate } from './dateFilters/date-filters';
import { dataTransformer } from '../../utils/error-state/error-state';
import {
  calcTalkToListenRatio,
  calculateDiffPercentage,
  SHOULD_PRESENT_INSIGHT
} from './utils';
import { formatRound } from '../../utils/numeric';
import { transformSellerListTopPerformer } from './sellerFilters/seller-filters';

export const teamTransformers = {
  dateTimeRange: ({ data }) => transformServerDate(data),
  overallCustomerSentiment: ({ data }) => transformSentimentDataForPie(data.overallCustomerSentiment),
  customerSentimentGeneratedByAgent: ({ data }) => transformSentimentDataForPercentagesBar(data.customerSentimentGeneratedByAgent),
  sentimentOverTime: ({ data }) => transformSentimentDataForPercentagesBar(data.sentimentOverTime),
  talkToListenRatioByAgent: ({ data, topPerformers }) => ({
    averages: filter([{
      id: 'sellers',
      value: isNil(get(data.teamAvgKpis, [0, 'teamAvgTalkToListenRatio'])) ? null : get(data.teamAvgKpis, [0, 'teamAvgTalkToListenRatio']) * 100
    }, {
      id: 'topSellers',
      value: isNil(get(data.topSellersAvgKpis, [0, 'topSellersAvgTalkToListenRatio'])) ? null : get(data.topSellersAvgKpis, [0, 'topSellersAvgTalkToListenRatio']) * 100
    }], ({ value }) => !isNil(value)),
    data: transformSellerListTopPerformer(multiplyBy100(data.talkToListenRatioByAgent), topPerformers)
  }),
  avgWordsPerMinuteByAgent: ({ data, topPerformers }) => transformByAgent(transformSellerListTopPerformer(data.avgWordsPerMinuteByAgent, topPerformers), get(data.teamAvgKpis, [0, 'teamAvgWordsPerMin']), get(data.topSellersAvgKpis, [0, 'topSellersAvgWordsPerMin'])),
  avgSwitchRateByAgent: ({ data, topPerformers }) => transformByAgent(transformSellerListTopPerformer(data.avgSwitchRateByAgent, topPerformers), get(data.teamAvgKpis, [0, 'teamAvgSwitchRate']), get(data.topSellersAvgKpis, [0, 'topSellersAvgSwitchRate'])),
  avgAgentPauseBeforeSpeakingInMsByAgent: ({ data, topPerformers }) => transformByAgent(transformSellerListTopPerformer(data.avgAgentPauseBeforeSpeakingInMsByAgent, topPerformers), get(data.teamAvgKpis, [0, 'teamAvgPauseBeforeSpeakingInMs']), get(data.topSellersAvgKpis, [0, 'topSellersAvgPauseBeforeSpeakingInMs'])),
  avgCustomerLongestMonologueInMsByAgent: ({ data, topPerformers }) => transformByAgent(transformSellerListTopPerformer(data.avgCustomerLongestMonologueInMsByAgent, topPerformers), get(data.teamAvgKpis, [0, 'teamAvgCustomerLongestMonologInMs']), get(data.topSellersAvgKpis, [0, 'topSellersAvgCustomerLongestMonologInMs'])),
  insights: ({ data }) => transformInsights(data.topSellersAvgKpis, data.insights)
};

export const transformTeam = (data, topPerformers) => (
  dataTransformer('team', teamTransformers, { data, topPerformers })
);

const transformByAgent = (data, teamAvg, topSellersAvg) => ({
  data,
  averages: filter([{
    id: 'sellers',
    value: teamAvg
  }, {
    id: 'topSellers',
    value: topSellersAvg
  }], ({ value }) => !isNil(value))
});

const transformInsights = (topSellersAvgKpis, {
  topKeywordsMissingFromRestOfTeamCalls,
  agentsWithHighestTalkToListenRatioAboveTopSellers,
  agentsWithLowestTalkToListenRatioBelowTopSellers,
  agentsWithSlowestTalkingSpeedBelowTopSellers,
}) => (
  omitBy(
    {
      topKeywordsMissing: {
        isHidden: get(topKeywordsMissingFromRestOfTeamCalls, SHOULD_PRESENT_INSIGHT) === 0,
        keywords: map(get(topKeywordsMissingFromRestOfTeamCalls, 'keywords'), 'keyword')
      },
      highTalkToListenRatio: {
        isHidden: get(agentsWithHighestTalkToListenRatioAboveTopSellers, SHOULD_PRESENT_INSIGHT) === 0,
        agents: transformAgents(agentsWithHighestTalkToListenRatioAboveTopSellers),
        sellersAvg: calcTalkToListenRatio(get(agentsWithHighestTalkToListenRatioAboveTopSellers, 'avgTalkToListenRatio')),
        topSellersAvg: calcTalkToListenRatio(get(topSellersAvgKpis, [0, 'topSellersAvgTalkToListenRatio'])),
        diffPercent: calculateDiffPercentage(
          get(agentsWithHighestTalkToListenRatioAboveTopSellers, 'avgTalkToListenRatio'),
          get(topSellersAvgKpis, [0, 'topSellersAvgTalkToListenRatio'])
        )
      },
      lowTalkToListenRatio: {
        isHidden: get(agentsWithLowestTalkToListenRatioBelowTopSellers, SHOULD_PRESENT_INSIGHT) === 0,
        agents: transformAgents(agentsWithLowestTalkToListenRatioBelowTopSellers),
        sellersAvg: calcTalkToListenRatio(get(agentsWithLowestTalkToListenRatioBelowTopSellers, 'avgTalkToListenRatio')),
        topSellersAvg: calcTalkToListenRatio(get(topSellersAvgKpis, [0, 'topSellersAvgTalkToListenRatio'])),
        diffPercent: calculateDiffPercentage(
          get(agentsWithLowestTalkToListenRatioBelowTopSellers, 'avgTalkToListenRatio'),
          get(topSellersAvgKpis, [0, 'topSellersAvgTalkToListenRatio'])
        )
      },
      slowestTalkingSpeed: {
        isHidden: get(agentsWithSlowestTalkingSpeedBelowTopSellers, SHOULD_PRESENT_INSIGHT) === 0,
        agents: transformAgents(agentsWithSlowestTalkingSpeedBelowTopSellers),
        sellersAvg: formatRound(get(agentsWithSlowestTalkingSpeedBelowTopSellers, 'avgTalkingSpeed')),
        topSellersAvg: formatRound(get(topSellersAvgKpis, [0, 'topSellersAvgWordsPerMin'])),
        diffPercent: calculateDiffPercentage(
          get(agentsWithSlowestTalkingSpeedBelowTopSellers, 'avgTalkingSpeed'),
          get(topSellersAvgKpis, [0, 'topSellersAvgWordsPerMin'])
        )
      },
    }, ({ isHidden }) => isHidden
  )
);

function transformAgents(data) {
  return map(data.agentsDetails, ({ agentFullName, aadUserId }) => ({
    agentName: agentFullName,
    id: aadUserId,
  }));
}
