import { PrimaryButton } from '@fluentui/react';

import styled from 'styled-components';

export const SaveButton = styled(PrimaryButton)`
  margin: 0 15px;
`;

export const FormStyled = styled.form`
  display: flex;
`;
