// @flow
import React, { useState } from 'react';
import { StyledText } from '../../../../utils/styles/StyledUtils';
import { durationToTime } from '../../../../utils/date/date';
import { TimelineSliderContainer, StyledTimeLabel, StyledTimelineSlider, timelineSliderStyles } from './StyledTimelineSlider';
import { isDirectionRTL } from '../../../../utils/dom/dom';

const MIN_SLIDER_WIDTH = 100;
const ROLLER_OFFSET = 0;
const ROLLER_OFFSET_RTL = -48;

const calculateTimeLabelPosition = (target, clientX, isRTL, setPoint) => {
  const rect = target.getBoundingClientRect();
  const offset = isRTL ? ROLLER_OFFSET_RTL : ROLLER_OFFSET;
  const distance = clientX - rect.left;
  if (rect.width > MIN_SLIDER_WIDTH) {
    const x = isRTL ? rect.width - distance : distance;
    setPoint({ x, width: rect.width, offset });
  }
};

const TimeLabel = ({ point, max, currentValue }) => {
  if (!point) { return null; }
  const labelValue = Math.round((point.x * max) / point.width);
  if (labelValue === currentValue) { return null; }

  return (
    <StyledTimeLabel location={point.x + point.offset}>
      <StyledText>{durationToTime(labelValue)}</StyledText>
    </StyledTimeLabel>
  );
};

export const TimelineSlider = ({
  min,
  max,
  step,
  onChange,
  value,
  height
}) => {
  const [point, setPoint] = useState(null);
  const isRTL = isDirectionRTL();

  const onMouseOver = (e) => {
    calculateTimeLabelPosition(e.target, e.clientX, isRTL, setPoint);
  };

  const onMouseLeave = () => {
    setPoint(null);
  };

  return (
    <TimelineSliderContainer onMouseMove={onMouseOver} onMouseLeave={onMouseLeave} height={height}>
      <TimeLabel point={point} max={max} currentValue={value} />
      <StyledTimelineSlider
        isRTL={isRTL}
        styles={timelineSliderStyles(value, height, isRTL)}
        min={min}
        onChange={onChange}
        max={max}
        step={step}
        value={value}
        showValue={false}
        aria-label="timeline"
      />
    </TimelineSliderContainer>
  );
};
