import React from 'react';
import useLocale from 'hooks/use-locale';
import { TopSellerCell } from '../../../../../../components/Table/default-columns';

const TopPerformerCellWrapper = ({ data, row, toggleTopPerformer, isDisabled }) => {
  const { t, tDefault } = useLocale('common');

  return (
    <TopSellerCell
      data={data}
      id={row.aadId}
      isDisabled={isDisabled}
      toggleTopSeller={toggleTopPerformer}
      markText={t('mark_as_top_performer')}
      unmarkText={t('unmark_as_top_performer')}
      disabledText={t('disabled_mark_non_team_member')}
      ariaLabelMarkText={tDefault('mark_as_top_performer')}
      ariaLabelUnmarkText={tDefault('unmark_as_top_performer')}
      ariaLabelDisabledText={tDefault('disabled_mark_non_team_member')}
      onRowHoverClassName="show-on-hover"
    />
  );
};

export default TopPerformerCellWrapper;
