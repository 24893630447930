import React, { useEffect } from 'react';
import { storageService } from 'ci-common-ui';
import { TRY_BEFORE_BUY_DATA } from 'constants/browser-storage';
import { map, merge } from 'lodash';
import Wizard from '../../components/Wizard/Wizard';
import { TRACK_IDS } from '../../constants/tracking';
import useLocale from '../../hooks/use-locale';
import GetSetUp from './components/steps/GetSetUp/GetSetUp';
import OrganizationInfo, { OrganizationInfoDisplayValues } from './components/steps/OrganizationInfo/OrganizationInfo';
import ConversationTracking, { ConversationTrackingDisplayValues } from './components/steps/ConversationTracking/ConversationTracking';
import UploadCalls from './components/steps/UploadCalls/UploadCalls';
import { MODALS } from '../../constants/modals';
import { useAppState } from '../../providers/AppStateProvider';
import { createTrialOrg } from '../../network/data-services/trial-service';
import { useOverridePageTracking } from '../../hooks/use-override-page-tracking';
import { componentLoadingTimeService } from '../../services/component-loading-time-service/component-loading-time-service';

const LOCALIZATION_PREFIX = 'pages.trial_setup';

const getResumeValues = () => JSON.parse(storageService.sessionStorage.getItem(TRY_BEFORE_BUY_DATA)) || {};
const setResumeValues = (values) => {
  const valuesWithoutFiles = {
    ...values,
    sellers: map(values.sellers, (seller) => ({ ...seller, files: [] }))
  };
  storageService.sessionStorage.setItem(TRY_BEFORE_BUY_DATA, JSON.stringify(valuesWithoutFiles));
};

const steps = [
  { name: 'get_set_up', component: GetSetUp },
  { name: 'organization_info', component: OrganizationInfo, displayValues: OrganizationInfoDisplayValues },
  { name: 'conversation_tracking', component: ConversationTracking, displayValues: ConversationTrackingDisplayValues },
  { name: 'upload_calls', component: UploadCalls },
];

const getAvailableSteps = () => steps;

const removeInvalidFiles = (settings) => ({
  ...settings,
  sellers: settings.sellers.map((seller) => ({
    ...seller,
    files: seller.files.filter((file) => !file.error)
  }))
});

let isFirstTrialSetupModalRender = true;

const startLoadingTimer = () => {
  if (isFirstTrialSetupModalRender) {
    componentLoadingTimeService.start(TRACK_IDS.SOURCES.TRIAL_SETUP_MODAL);
  }
  isFirstTrialSetupModalRender = false;
};

const TrialSetupModal = ({ closeModal }) => {
  startLoadingTimer();
  const { setAppState } = useAppState();
  const { t } = useLocale(LOCALIZATION_PREFIX);
  useOverridePageTracking(TRACK_IDS.SOURCES.TRIAL_SETUP_MODAL);

  const onFinish = async (settings) => {
    const { orgId, contactEmail, agents } = await createTrialOrg(settings);
    const newSettings = removeInvalidFiles(settings);
    const sellersWithAgentsDetails = newSettings.sellers.map((seller, sellerIndex) => merge({}, seller, agents.find(({ index }) => index === sellerIndex + 1)));

    closeModal();

    setAppState({
      modalName: MODALS.TrialDataUploader,
      trialSetupConfig: {
        orgId,
        contactEmail,
        sellers: sellersWithAgentsDetails,
        shouldRefreshAppAfterFinish: true,
      }
    });
  };

  useEffect(() => () => { isFirstTrialSetupModalRender = true; }, []); // unMount

  return (
    <Wizard
      closeModal={closeModal}
      getAvailableSteps={getAvailableSteps}
      translationPrefix={LOCALIZATION_PREFIX}
      trackingSource={TRACK_IDS.SOURCES.TRIAL_SETUP_MODAL}
      title={t('title')}
      initialValues={getResumeValues()}
      onSettingsChanged={setResumeValues}
      onFinish={onFinish}
    />
  );
};

export default TrialSetupModal;
