import { serverApi, BASE_URLS } from 'ci-common-ui';

export async function loadSellers() {
  return serverApi.GET({ path: '/user/v1.0-preview/agents', options: { baseUrl: BASE_URLS.NEW_API_SERVER } });
}

export async function loadAdminSellers(orgId) {
  return serverApi.GET({ path: `user/v1.0-preview/organizations/${orgId}/users`, options: { baseUrl: BASE_URLS.NEW_API_SERVER } });
}

export async function loadSellerData(params) {
  return serverApi.GET({ path: '/user/v1.0-preview/beta/seller-details', queryParams: params, options: { baseUrl: BASE_URLS.NEW_API_SERVER } });
}

export async function loadSellerConversations(params) {
  return serverApi.GET({ path: '/user/v1.0-preview/conversations/v2', queryParams: params, options: { baseUrl: BASE_URLS.NEW_API_SERVER } });
}

export async function loadTopPerformers(orgId, params, forceFetch) {
  return serverApi.GET({ path: `/user/v1.0-preview/organizations/${orgId}/top-performers`, queryParams: params, options: { cacheOptions: { cache: !forceFetch }, baseUrl: BASE_URLS.NEW_API_SERVER } });
}
