import React from 'react';
import styled from 'styled-components';
import { Icon } from '@fluentui/react';
import { FlexRowCentered, StyledLink } from '../../utils/styles/StyledUtils';
import { Tooltip } from '../Tooltip';

export const IconWrapper = styled(StyledLink).attrs({
  role: 'button'
})`
  cursor: default;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.Gray130};
  background: transparent;
  border: none;
  &&:hover{
    color: ${({ theme }) => theme.colors.Gray130};
    background: transparent;
    text-decoration: none;
  }
  @media (forced-colors: active) {
    forced-color-adjust: auto;
  }
`;

export const InformationIcon = styled(Icon).attrs({
  iconName: 'info'
})`
  cursor: default;
  color: ${({ theme }) => theme.colors.Gray130};
  background: transparent;
  &&:hover{
    color: ${({ theme }) => theme.colors.Gray130};
    background: transparent;
    text-decoration: none;
  }
`;

export const InfoIcon = ({ tooltipContent, tooltipStyle, tooltipId, onTooltipVisible, tooltipProps, tooltipAriaLabel }) => {
  const ariaLabelValue = tooltipAriaLabel || tooltipContent;

  return (
    <Tooltip id={tooltipId} content={tooltipContent} styles={tooltipStyle} onTooltipVisible={onTooltipVisible} tooltipProps={tooltipProps}>
      <IconWrapper aria-label={`info icon, ${ariaLabelValue}`}>
        <FlexRowCentered>
          <InformationIcon data-testid="InfoIcon" />
        </FlexRowCentered>
      </IconWrapper>
    </Tooltip>
  );
};
