import styled from 'styled-components';
import { FontSizes } from '@uifabric/fluent-theme';
import { rtl, IconWrapper, InformationIcon } from 'ci-common-ui';

export const ConversationsTable = styled.div`
  margin-top: 20px;
  ${InformationIcon}{  
    font-size: ${FontSizes.size12};
    cursor: pointer;
  }
  ${IconWrapper}{
    ${rtl`margin-left: 8px;`}
  }
`;
