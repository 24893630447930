import styled from 'styled-components';
import { FlexRow, rtl, colors } from 'ci-common-ui';
import { Icon } from '@fluentui/react';

export const IconWrapper = styled.div`
  ${rtl`margin-right`}: 6px;
  font-size: 16px;
`;

export const CommentIcon = styled(Icon).attrs({
  iconName: 'Comment'
})`
  color: ${({ isIconDisabled }) => isIconDisabled ? colors.Gray90 : colors.Gray160};
  cursor: default;
`;

export const Wrapper = styled(FlexRow)``;

export const Count = styled.div`
  font-size: 12px;
`;
