import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';
import get from 'lodash/get';
import { useFetch } from 'ci-common-ui';
import { StepLoader, StepRoot } from '../../../../../components/Wizard/StyledWizard';
import StepForm from '../../../../../components/Wizard/StepForm/StepForm';
import useLocale from '../../../../../hooks/use-locale';
import { SectionText, SectionTitle, SectionDescriptionRequired } from '../../../../../components/Modal/StyledModal';
import { ContentSection, ContentSectionTitle } from '../GetSetUp/StyledGetSetUp';
import { StyledTagListField } from './StyledConversationTracking';
import { getDefaultsKeywords } from '../../../../../network/data-services/trial-service';
import { componentLoadingTimeService } from '../../../../../services/component-loading-time-service/component-loading-time-service';
import { TRACK_IDS } from '../../../../../constants/tracking';

const TAG_MIN_LENGTH = 2;

export const ConversationTrackingDisplayValues = (settings, translationPrefix) => {
  const { t } = useLocale(`${translationPrefix}.conversation_tracking`);
  const { keywords, competitors } = settings;
  if (keywords && competitors) {
    return `${keywords.length} ${t('keywords')}, ${competitors.length} ${t('competitors')}`;
  }
  return null;
};

const ConversationTrackingStep = ({ handleSettings, settings, next, back, translationPrefix }) => {
  const { t, tAbs } = useLocale(translationPrefix);

  const { data, fetchData, isLoading } = useFetch(() => getDefaultsKeywords(settings.industry));
  const isFormPristine = isEmpty(get(settings, 'keywords')) && isEmpty(get(settings, 'competitors'));

  useEffect(() => {
    if (isFormPristine) {
      fetchData(settings.industry);
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      componentLoadingTimeService.end(TRACK_IDS.SOURCES.TRIAL_SETUP_MODAL);
    }
  }, [isLoading]);

  useEffect(() => {
    if (data) {
      handleSettings({
        keywords: data.keywords,
        competitors: data.competitors,
      });
    }
  }, [data]);

  if (isLoading) {
    return <StepLoader label={t('loading')} />;
  }

  const onSubmit = (value) => {
    handleSettings(value);
    next();
  };

  return (
    <StepRoot>
      <StepForm onSubmit={onSubmit} initialValues={settings} back={back}>
        <SectionTitle>{t('main_title')}</SectionTitle>
        <SectionText>{t('main_subtitle')}</SectionText>
        <ContentSection>
          <ContentSectionTitle>{t('note')}</ContentSectionTitle>
        </ContentSection>
        <ContentSection>
          <SectionDescriptionRequired bold capitalize>{t('tracked_keywords_title')}</SectionDescriptionRequired>
          <SectionText>{t('tracked_keywords_subtitle')}</SectionText>
          <StyledTagListField
            isRequired
            name="keywords"
            addButtonText={t('tracked_keywords_placeholder')}
            minLength={TAG_MIN_LENGTH}
            errorMessages={{
              required: tAbs('common.required'),
              errorAlreadyExist: tAbs('components.tag_list.errors.already_exist_error'),
              errorMinLength: tAbs('components.tag_list.errors.min_length_error', { numberOfChars: TAG_MIN_LENGTH }) }}
          />
        </ContentSection>
        <ContentSection>
          <SectionDescriptionRequired bold capitalize>{upperFirst(t('competitors'))}</SectionDescriptionRequired>
          <SectionText>{t('competitors_subtitle')}</SectionText>
          <StyledTagListField
            isRequired
            name="competitors"
            addButtonText={t('competitors_placeholder')}
            minLength={TAG_MIN_LENGTH}
            errorMessages={{
              required: tAbs('common.required'),
              errorAlreadyExist: tAbs('components.tag_list.errors.already_exist_error'),
              errorMinLength: tAbs('components.tag_list.errors.min_length_error', { numberOfChars: TAG_MIN_LENGTH }) }}
          />
        </ContentSection>
      </StepForm>
    </StepRoot>
  );
};

export default ConversationTrackingStep;
