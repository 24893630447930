import styled from 'styled-components';
import { FlexRow, colors } from 'ci-common-ui';

export const Container = styled(FlexRow)`
  flex-wrap: wrap;
`;

export const TrendValue = styled.span`
  color: ${colors.Gray160};
  font-weight: 600;
`;
