import React from 'react';
import { Container, Title, Subtitle, Text, DownloadNewDashboardButton, LearnMoreLink, PageDeprecationIcon } from 'pages/SAADeprecationPage/StyledSAADeprecationPage';
import useLocale from "hooks/use-locale";
import { getIsAdminUser } from "utils/app-context/app-context";

const LEARN_MORE_LINK_FOR_ADMIN = "https://learn.microsoft.com/en-us/microsoft-sales-copilot/configure-pbi-dashboard";
const LEARN_MORE_LINK = "https://learn.microsoft.com/en-us/microsoft-sales-copilot/analyze-sellers-conv-data-pbi";
const DOWNLOAD_NEW_DASHBOARD_LINK = "https://go.microsoft.com/fwlink/p/?linkid=2259835";

const SAADeprecationPage = () => {
    const { t } = useLocale('pages.SAADeprecationPage');
    const isAdminUser = getIsAdminUser();

    return (
        <Container>
            <PageDeprecationIcon />
            <Title>{t('title')}</Title>
            <Subtitle>{t(isAdminUser ? 'subtitle_for_admin' : 'subtitle')}</Subtitle>
            {isAdminUser && (
                <DownloadNewDashboardButton role='link' onClick={() => window.open(DOWNLOAD_NEW_DASHBOARD_LINK)}>
                    <Text>{t('download_new_dashboard_button')}</Text>
                </DownloadNewDashboardButton>
            )}
            <LearnMoreLink href={isAdminUser ? LEARN_MORE_LINK_FOR_ADMIN : LEARN_MORE_LINK} target='_blank'>
                <Text>{t(isAdminUser ? 'learn_more_link_for_admin' : 'learn_more_on_the_new_dashboard_link')}</Text>
            </LearnMoreLink>
        </Container>
    );
};

export default SAADeprecationPage;
