export enum LocalStorageKeys {
  ApiServerRegion = '__sci_api_server_region',
  CdnRegion = '__sci_cdn_copilot_region',
  CdnVersion = '__sci_cdn_copilot_version',
  CdnEnabled = '__sci_cdn_copilot_enabled',
  IsMock = '__sci_cdn_copilot_is_mock',
  DismissedCards = '__sci_cdn_realtime_dismissed_cards',
  CiBackendEnvVariable = 'CI_BACKEND_ENV', // use 'SciRegion' values
  CiUiEnvVariable = 'CI_UI_ENV', // use 'SciRegion' values
}

export const getLocalStorageValue = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    // Todo 3441755: add logger
    console.error(e);
  }

  return null;
};

export const setLocalStorageValue = (key: string, value: string) => {
  try {
    return localStorage.setItem(key, value);
  } catch (e) {
    // Todo 3441755: add logger
    console.error(e);
  }

  return null;
};
