import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { hot } from 'react-hot-loader/root';
import { useHistory } from 'react-router';
import { themeUtils, lightTheme, useScript, PROVISION_STATES, initI18nService, storageService } from 'ci-common-ui';
import { ThemeManagerProvider } from 'providers/ThemeProvider';
import { useAppState } from 'providers/AppStateProvider';
import services from 'network/data-services/index';
import * as urls from 'constants/urls';
import useLocale from 'hooks/use-locale';
import usePageViews from 'hooks/use-page-views';
import { get } from 'lodash';
import { constantQueryParamsObj } from 'constants/urls';
import SAADeprecationPage from "pages/SAADeprecationPage/SAADeprecationPage";
import PageError from 'pages/components/PageError/PageError';
import AppLayout from './components/AppLayout/AppLayout';
import Topbar from './components/TopBar/TopBar';
import ModalManager from './components/Modal/ModalManager';
import { AppContainer, AppSpinner } from './StyledApp';
import NPSWindow from './components/NPSWindow';
import { redirectOldRoutes } from './utils/url/url';
import { getValidNotificationBars } from './components/NotificationBars/utils';
import { getAllAppsFromLocalStorage, getIsEmbedMode, resetTheApp, getIsOrgStorageTypeDvOnly } from './utils/app-context/app-context';
import useAsyncError from './hooks/use-async-error';
import { setMouseActiveOnBody } from './utils/dom/dom';
import { PendingProvisionScreen } from './components/PendingProvisionScreen';
import { PromptWrapper } from './components/PromptWrapper/PromptWrapper';
import { TRACK_IDS } from './constants/tracking';
import { finishOnboardingProcess } from './network/data-services/settings';
import { eventAction, trackError, trackEvent } from './services/telemetry-service/telemetry-service';
import { getAutoProvisionSettings } from './network/data-services/app-service';
import { componentLoadingTimeService } from './services/component-loading-time-service/component-loading-time-service';

let userData = {};

const App = () => {
  const { appState: { isLoading, isProvisioningInProgress, isSoftError }, setAppState } = useAppState();
  const history = useHistory();
  const { i18n } = useLocale();
  const { isLoaded: isScriptLoaded } = useScript(urls.SHELL_URL);
  const throwError = useAsyncError();
  const isOrgStorageTypeDvOnly = getIsOrgStorageTypeDvOnly();

  usePageViews();

  const setHTMLocale = () => {
    const html = document.querySelector('html');
    html.setAttribute('lang', i18n.language);
    html.setAttribute('dir', i18n.dir());
  };

  useEffect(() => {
    componentLoadingTimeService.start(TRACK_IDS.SOURCES.APP);
    setMouseActiveOnBody();
    setStorageServiceWithTrackError();
  }, []);

  const startAutoProvisioning = async (autoProvisioningSettings) => {
    setAppState({ isProvisioningInProgress: true });
    try {
      trackEvent({
        overrideSource: TRACK_IDS.SOURCES.PENDING_PROVISION_SCREEN,
        actionOn: TRACK_IDS.USER_ATTEMPTS_TO_AUTO_ONBOARD,
        action: eventAction.info
      });
      await finishOnboardingProcess(autoProvisioningSettings);
      trackEvent({
        overrideSource: TRACK_IDS.SOURCES.PENDING_PROVISION_SCREEN,
        actionOn: TRACK_IDS.USER_AUTO_ONBOARDED,
        action: eventAction.info
      });
      resetTheApp();
    } catch (e) {
      trackError({
        overrideSource: TRACK_IDS.SOURCES.PENDING_PROVISION_SCREEN,
        actionOn: TRACK_IDS.USER_FAILED_TO_AUTO_ONBOARD
      });
    }
  };

  const loadUserApps = async () => {
    try {
      const { shouldReload } = await services.app.loadUserApps();

      if (shouldReload) {
        window.location.reload();
      }
    } catch (err) {
      setAppState({ isSoftError: true });
      throwError(err);
    }
    return null;
  };

  useEffect(() => {
    const loadUserData = async () => {
      try {
        userData = await services.app.loadUserData();
        const dataForNotificationsBars = await services.app.getDataForNotificationsBars();
        setAppState({
          isProvisioningInProgress: get(dataForNotificationsBars, 'provisionStatus.state', PROVISION_STATES.None) === PROVISION_STATES.ProvisionInProgress,
          notificationBars: getValidNotificationBars(dataForNotificationsBars),
          isLoading: false
        });
      } catch (err) {
        setAppState({
          isLoading: false,
          isSoftError: true
        });
      }

      return null;
    };

    const loadApp = async () => {
      if (!isEmpty(getAllAppsFromLocalStorage())) {
        await loadUserData();
        loadUserApps(); // we have apps cached, shooting a request in the background to make sure nothing has changed (revoke cache)
      } else {
        await loadUserApps();
        const autoProvisioningSettings = await getAutoProvisionSettings(getAllAppsFromLocalStorage());
        if (autoProvisioningSettings) { await startAutoProvisioning(autoProvisioningSettings); }
        loadUserData();
      }
    };

    redirectOldRoutes(history);
    initI18nService({
      localesPath: '/locales',
      detection: {
        order: ['querystring', 'navigator', 'htmlTag', 'localStorage', 'cookie', 'path', 'subdomain'],
        lookupQuerystring: constantQueryParamsObj.locale,
      }
    }, (localesErr) => {
      if (localesErr) {
        trackError({ actionOn: TRACK_IDS.LOCALES_ERROR, message: localesErr });
        throwError(localesErr);
        return;
      }
      setHTMLocale();
      themeUtils.loadFabricTheme(lightTheme); // fabric have to be loaded after locale was set
      loadApp();
    }, (key) => {
      trackError({ actionOn: TRACK_IDS.MISSING_TRANSLATION_KEY, message: key });
    });
  }, []);

  useEffect(() => {
    if (!isLoading && isScriptLoaded) {
      componentLoadingTimeService.end(TRACK_IDS.SOURCES.APP);
    }
  }, [isLoading, isScriptLoaded]);

  if (isLoading || !isScriptLoaded) return <AppSpinner />;

  return (
    <ThemeManagerProvider>
      <PromptWrapper />
      <AppContainer isEmbed={getIsEmbedMode()}>
        <Topbar shellData={userData.shellData} />
        { isSoftError ?
            <PageError /> :
            isOrgStorageTypeDvOnly ?
              <SAADeprecationPage /> :
              isProvisioningInProgress
                ? <PendingProvisionScreen />
                : <AppLayout />
        }
        <NPSWindow />
        <ModalManager />
      </AppContainer>
    </ThemeManagerProvider>
  );
};

export default hot(App);

function setStorageServiceWithTrackError() {
  const onError = (key, value, err) => trackError({ actionOn: TRACK_IDS.STORAGE_SET_ITEM, message: { keyTriedToBeSet: key, valueTriedToBeSet: value, err } });
  storageService.localStorage.setOnErrorFunction(onError);
  storageService.sessionStorage.setOnErrorFunction(onError);
}
