// @flow
import React from 'react';
import { Checkbox } from '@fluentui/react';
import { map, isEmpty, reduce } from 'lodash';
import { StyledText } from '../../../../utils/styles/StyledUtils';
import { durationToTime } from '../../../../utils/date/date';
import { calcPointOnTheTimeline } from '../Timeline/Timeline';
import {
  DualTimelineContainer, SentimentLabel, segmentTitleTooltipStyles, TooltipHostStyles, LegendsContainer,
  LegendItem, LegendDot, Segments, SegmentsTimeline, SegmentItem, SegmentItemTitle, SegmentItemTooltipTitle,
  SegmentItemTooltipTime, TimelinesContainer, SentimentText, CommentsInfoIconWrapper, CommentsCheckBoxContainer, LegendItems
} from './StyledDualTimeline';
import { TimelineSlider } from '../TimelineSlider/TimelineSlider';
import { TimelinesGroup } from '../TimelinesGroup/TimelinesGroup';
import { SENTIMENT_NAMES } from '../../../../constants/api';
import { useInfra } from '../../../../providers/InfraProvider';
import { CALL_STRINGS } from '../../strings';
import { useToggler } from '../../../../hooks/use-toggler';
import { InfoIcon } from '../../../../components/InfoIcon/InfoIcon';
import { Tooltip } from '../../../../components/Tooltip';

const SegmentItemTooltip = ({ segment }) => (
  <div>
    <SegmentItemTooltipTitle>{segment.title}</SegmentItemTooltipTitle>
    <SegmentItemTooltipTime>{durationToTime(segment.start)} - {durationToTime(segment.end)}</SegmentItemTooltipTime>
  </div>
);

export const DualTimeline = ({
  duration,
  timelineData,
  participants,
  onChange,
  currentTime,
  keywordInstances,
  hoveredKeywordInstances,
  selectedActionItem,
  comments,
  commentsMoveTo,
  hoveredActionItem,
  selectedSegment,
  segmentation,
  onSegmentSelected,
  isSmallPlayer,
  isAgentsTimelinesOpen,
  isCustomersTimelinesOpen,
  toggleAgentsTimelines,
  toggleCustomersTimelines,
  height,
  isOnlyAgentsRecorded,
  avatars,
}) => {
  const { t } = useInfra();
  const [isVisibleComments, toggleIsVisibleComments] = useToggler(true);

  const handleSegmentSelected = (currentSegment) => {
    onSegmentSelected(currentSegment || {});
    if (currentSegment) {
      onChange(currentSegment.start);
    }
  };

  return (
    <DualTimelineContainer isSmallPlayer={isSmallPlayer}>
      <Segments>
        <SegmentsTimeline>
          {segmentation.map((segment) => {
            const isSelected = !isEmpty(selectedSegment) && selectedSegment.fragmentStartIndex === segment.fragmentStartIndex;
            return (
              <SegmentItem
                key={segment.start}
                height={height}
                pointStart={calcPointOnTheTimeline(segment.start, duration)}
                pointEnd={calcPointOnTheTimeline(segment.end, duration)}
                isSelected={isSelected}
                isDisabled={!segment.title}
                isGreyedOut={!isEmpty(selectedSegment) && !isSelected}
              >
                <Tooltip styles={segmentTitleTooltipStyles} content={<SegmentItemTooltip segment={segment} />}>
                  <SegmentItemTitle data-testid='segment-item-title'
                    aria-label={segment.title || 'Meeting Content'}
                    onClick={() => handleSegmentSelected(isSelected ? null : segment)}>{segment.title}
                  </SegmentItemTitle>
                </Tooltip>
              </SegmentItem>
            );
          })}
        </SegmentsTimeline>
      </Segments>

      <TimelinesContainer height={height}>
        <TimelinesGroup
          headerText={t(CALL_STRINGS.ALL_SELLERS)}
          timelines={timelineData.agents}
          isOpen={isAgentsTimelinesOpen}
          onToggle={toggleAgentsTimelines}
          duration={duration}
          keywordInstances={keywordInstances}
          hoveredKeywordInstances={hoveredKeywordInstances}
          selectedActionItem={selectedActionItem}
          comments={comments}
          commentsMoveTo={commentsMoveTo}
          hoveredActionItem={hoveredActionItem}
          participants={participants}
          isWithMargin
          isVisibleComments={isVisibleComments}
          avatars={avatars}
        />
        <TimelinesGroup
          headerText={t(CALL_STRINGS.OTHERS)}
          timelines={timelineData.customers}
          isOpen={isCustomersTimelinesOpen}
          isHidden={isOnlyAgentsRecorded}
          onToggle={toggleCustomersTimelines}
          duration={duration}
          keywordInstances={keywordInstances}
          hoveredKeywordInstances={hoveredKeywordInstances}
          selectedActionItem={selectedActionItem}
          comments={comments}
          commentsMoveTo={commentsMoveTo}
          hoveredActionItem={hoveredActionItem}
          participants={participants}
          hideAvatar
          defaultUserName={t(CALL_STRINGS.CUSTOMER)}
          isVisibleComments={isVisibleComments}
          avatars={avatars}
        />
      </TimelinesContainer>

      <TimelineSlider
        height={height}
        min={0}
        max={duration / 1000}
        step={1}
        onChange={onChange}
        value={Math.round(currentTime)}
      />
      <LegendsAndCommentsCheckbox
        timelineData={timelineData}
        comments={comments}
        isVisibleComments={isVisibleComments}
        toggleIsVisibleComments={toggleIsVisibleComments}
      />
    </DualTimelineContainer>
  );
};

const LegendsWrapper = () => {
  const { t, shouldHideComments } = useInfra('pages.call');
  return (
      <>
        <SentimentLabel shouldHideComments={shouldHideComments}>
          <SentimentText>{t(CALL_STRINGS.SENTIMENT)}</SentimentText>
          <InfoIcon
              tooltipStyle={TooltipHostStyles}
              tooltipContent={t(CALL_STRINGS.TIMELINE_SENTIMENT_TOOLTIP, { type: 'sales' })}
          />
        </SentimentLabel>
        <LegendItems>
          {map(SENTIMENT_NAMES, (value, key) => (
              <LegendItem key={key} shouldHideComments={shouldHideComments}>
                <LegendDot data-testid="legend-dot" sentimentType={key} />
                <StyledText>{t(key)}</StyledText>
              </LegendItem>
          ))}
        </LegendItems>
      </>
      )
}

const CommentsWrapper = ({ comments, isVisibleComments, toggleIsVisibleComments, shouldHideLegends }) => {
    const { t } = useInfra();
    const allCommentsCount = reduce(comments, (commentsCountAccumulator, { count }) => commentsCountAccumulator + count, 0);

    return (
        <CommentsCheckBoxContainer shouldHideLegends={shouldHideLegends} data-testid="comments-container">
          <Checkbox
              checked={isVisibleComments}
              onChange={toggleIsVisibleComments}
              disabled={!allCommentsCount}
              label={`${t(CALL_STRINGS.COMMENTS)} (${allCommentsCount})`}
          />
          <CommentsInfoIconWrapper>
            <InfoIcon tooltipContent={t(CALL_STRINGS.COMMENTS_CHECKBOX_TOOLTIP)} />
          </CommentsInfoIconWrapper>
        </CommentsCheckBoxContainer>
    );
}

const LegendsAndCommentsCheckbox = ({ isVisibleComments, toggleIsVisibleComments, comments, timelineData }) => {
  const { shouldHideComments } = useInfra('pages.call');
  const allBlocks = [...timelineData.agents.mainTimeline.blocks, ...timelineData.customers.mainTimeline.blocks];
  const shouldHideLegends =  !allBlocks.some(({ sentimentType }) => sentimentType === SENTIMENT_NAMES.negative || sentimentType === SENTIMENT_NAMES.positive);

  return (
    <LegendsContainer shouldHideComments={shouldHideComments}>
      {!shouldHideLegends && (
        <LegendsWrapper />
      )}
      {!shouldHideComments && (
        <CommentsWrapper comments={comments} isVisibleComments={isVisibleComments} toggleIsVisibleComments={toggleIsVisibleComments} shouldHideLegends={shouldHideLegends} />
      )}
    </LegendsContainer>
  );
};
