import * as app from './app-service';
import * as insights from './insight-service';
import * as sellers from './seller-service';
import * as team from './team-service';
import * as settings from './settings';

export default {
  app,
  insights,
  sellers,
  team,
  settings,
};
