export const INDUSTRIES = [
  'financial_services',
  'healthcare',
  'automotive',
  'education',
  'manufacturing',
  'media',
  'nonprofit',
  'retail',
  'other',
];

export const REGIONS = [
  'jpn',
  'sam',
  'can',
  'ind',
  'apj',
  'gbr',
  'oce',
  'fra',
  'eur',
  'nam'
];
