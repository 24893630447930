import styled from 'styled-components';
import { Icon } from '@fluentui/react';
import { FlexRow } from '../../../../../../../../utils/styles/StyledUtils';
import { rtl } from '../../../../../../../../utils/styles/rtl';

export const StyledIcon = styled(Icon)`
  ${rtl`margin-right: 4px;`}
`;

export const TimePicker = styled(FlexRow)`
  justify-content: flex-end;
  padding: 0 16px 16px 16px;
`;

export const timeSlotDropdownStyles = {
  root: {
    flex: '1',
    marginRight: '4px',
  },
  dropdownItemsWrapper: {
    overflow: 'auto',
    maxHeight: 300,
  }
};
