import TeamsIcon from '../assets/images/telephony-providers/teams.svg';
import NiceIcon from '../assets/images/telephony-providers/nice.svg';
import CiscoIcon from '../assets/images/telephony-providers/cisco.svg';
import TwilioIcon from '../assets/images/telephony-providers/twilio.svg';
import GenericProviderIcon from '../assets/images/telephony-providers/generic.svg';

// Types which match the backend api
export const TELEPHONY_PROVIDER_TYPES = {
  TEAMS: 'msTeams',
  CISCO: 'cisco',
  TWILIO: 'twilio',
  NICE: 'niceInContact',
  CUSTOM: 'custom',
};

export const PROVIDER_HOSTING_TYPES = {
  ON_PREM: 'onPrem',
  CLOUD: 'cloud',
};

export const getTelephonyProvider = (providerInput) => {
    if (providerInput.type === TELEPHONY_PROVIDER_TYPES.CUSTOM) {
      return {
        IconComponent: GenericProviderIcon,
        providerType: TELEPHONY_PROVIDER_TYPES.CUSTOM,
        providerName: providerInput.displayName,
        companyName: providerInput.displayName,
        hostingType: PROVIDER_HOSTING_TYPES.CLOUD,
      }
    }

    return telephonyProviders[providerInput.type];
};

export const telephonyProviders = {
  [TELEPHONY_PROVIDER_TYPES.TEAMS]: {
    IconComponent: TeamsIcon,
    providerType: TELEPHONY_PROVIDER_TYPES.TEAMS,
    providerName: 'Teams',
    companyName: 'Microsoft',
    hostingType: PROVIDER_HOSTING_TYPES.CLOUD,
  },
  [TELEPHONY_PROVIDER_TYPES.CISCO]: {
    IconComponent: CiscoIcon,
    providerType: TELEPHONY_PROVIDER_TYPES.CISCO,
    providerName: 'Cisco',
    companyName: 'Cisco',
    hostingType: PROVIDER_HOSTING_TYPES.ON_PREM,
  },
  [TELEPHONY_PROVIDER_TYPES.TWILIO]: {
    IconComponent: TwilioIcon,
    providerType: TELEPHONY_PROVIDER_TYPES.TWILIO,
    providerName: 'Twilio',
    companyName: 'Twilio',
    hostingType: PROVIDER_HOSTING_TYPES.CLOUD,
  },
  [TELEPHONY_PROVIDER_TYPES.NICE]: {
    IconComponent: NiceIcon,
    providerType: TELEPHONY_PROVIDER_TYPES.NICE,
    providerName: 'CXone',
    companyName: 'Nice',
    hostingType: PROVIDER_HOSTING_TYPES.ON_PREM,
  },
};
