import isEmpty from 'lodash/isEmpty';
import { getIsOnboarded } from 'utils/app-context/app-context';
import { FEATURES, LICENSE_USAGE_RESPONSE_TYPES, PROVISION_STATES } from 'ci-common-ui';
import LicenseUsageBar from './bars/LicenseUsageBar';
import ProvisionBar from './bars/ProvisionBar';
import ViewingAs from './bars/ViewingAsBar';
import { DEFAULT, ERROR, INFO, notificationBarsTypes } from './notification-bars-types';
import { isFeatureEnabled } from '../../utils/app-context/feature-flighting';
import SAADepricatinBar from './bars/SAADepricatinBar';
import ComplianceBoundaryBar from "./bars/ComplianceBoundaryBar";

export const notificationBarsMap = {
  [notificationBarsTypes.SAADeprication]: SAADepricatinBar,
  [notificationBarsTypes.LicenseUsage]: LicenseUsageBar,
  [notificationBarsTypes.Provision]: ProvisionBar,
  [notificationBarsTypes.ViewingAs]: ViewingAs,
  [notificationBarsTypes.ComplianceBoundary]: ComplianceBoundaryBar,
};

export const getValidNotificationBars = (dataForNotificationsBars) => {
  const saaDepricationBar = {
    type: notificationBarsTypes.SAADeprication,
    props: SAADepricatinBar,
    level: DEFAULT
  };

  const complianceBoundaryBar = {
    type: notificationBarsTypes.ComplianceBoundary,
    props: {},
    level: DEFAULT
  };

  const bars = [saaDepricationBar, complianceBoundaryBar];
  if (dataForNotificationsBars.error) return bars;

  const { userLicense, provisionStatus, viewingAs } = dataForNotificationsBars;

  if (!isEmpty(viewingAs)) {
    bars.push({
      type: notificationBarsTypes.ViewingAs,
      props: viewingAs,
      level: INFO
    });
  }
  const hasValidStatus = LICENSE_USAGE_RESPONSE_TYPES[userLicense.capacityUsageStatus];
  if (!userLicense.error && getIsOnboarded() && (!userLicense.valid || (hasValidStatus && userLicense.capacityUsageStatus !== LICENSE_USAGE_RESPONSE_TYPES.Enabled))) {
    bars.push({
      type: notificationBarsTypes.LicenseUsage,
      props: userLicense,
      level: DEFAULT
    });
  }
  const provisionState = (!provisionStatus || provisionStatus.error) ? PROVISION_STATES.None : provisionStatus.state;
  const shouldShowProvisionBar = !isFeatureEnabled(FEATURES.TRY_AND_BUY) && (!getIsOnboarded() || provisionState === PROVISION_STATES.ProvisionFailed);
  if (shouldShowProvisionBar) {
    bars.push({
      type: notificationBarsTypes.Provision,
      props: { provisionState },
      level: provisionState === PROVISION_STATES.ProvisionFailed ? ERROR : DEFAULT
    });
  }

  return bars;
};
