import styled from 'styled-components';
import { StyledText, rtl, colors } from 'ci-common-ui';

import KpisBar from '../KpisBar/KpisBar';

export const MomKpisValueCount = styled(StyledText)`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 4px;
`;

export const TrendContainer = styled(StyledText)`
  margin-left: 2px;
  margin-right: 2px;
  color: ${({ isPositive }) => (isPositive ? colors.DynamicsGreen : colors.DynamicsRed)};
`;

export const MomKpisBar = styled(KpisBar)`
    margin-top: 16px;
`;

export const MomText = styled(StyledText)`
    ${rtl`margin-right: 6px`};
`;

export const MomTrendContainer = styled(StyledText)`
    margin: 4px;
`;
