import React from 'react';
import { Container, Title } from './StyledSellerEditor';
import FilesPanel from './components/FilesPanel/FilesPanel';
import SellerBasicDataForm from './components/SellerBasicDataForm/SellerBasicDataForm';
import useLocale from '../../../../hooks/use-locale';

export default ({
  selectedSeller,
  onSellerNameChanged,
  onSellerLanguageChanged,
  onFavoriteClicked,
  onDeleteSeller,
  onFilesAdded,
  onFileDeleteClicked,
  onChangeFileLanguage,
  onEditCallName,
}) => {
  const SELLER_EDITOR_TRANSLATION_PREFIX = 'components.upload_calls_form.seller_editor';
  const { t } = useLocale(SELLER_EDITOR_TRANSLATION_PREFIX);

  return (
    <Container>
      <Title>{t('title')}</Title>
      <SellerBasicDataForm
        translationPrefix={SELLER_EDITOR_TRANSLATION_PREFIX}
        selectedSeller={selectedSeller}
        onSellerNameChanged={onSellerNameChanged}
        onSellerLanguageChanged={onSellerLanguageChanged}
        onFavoriteClicked={onFavoriteClicked}
        onDeleteSeller={onDeleteSeller}
      />
      <FilesPanel
        translationPrefix={SELLER_EDITOR_TRANSLATION_PREFIX}
        selectedSeller={selectedSeller}
        onFilesAdded={onFilesAdded}
        onFileDeleteClicked={onFileDeleteClicked}
        onChangeFileLanguage={onChangeFileLanguage}
        onEditCallName={onEditCallName}
      />
    </Container>
  );
};
