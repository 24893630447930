import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { CopyIcon } from './StyledCopy';
import { useInfra } from '../../../../providers/InfraProvider';
import { CALL_STRINGS } from '../../strings';
import { durationToTime } from '../../../../utils/date/date';
import { copyToClipboardAsHtml } from '../../../../utils/dom/dom';
import { eventAction } from '../../../../services/telemetry-service';
import { TRACK_IDS } from '../../tracking';
import { Tooltip } from '../../../../components/Tooltip';

const LinkCopyText = ({ link, linkText, title, currentTime }) => (
  <span>
    {link && <><a href={link}>{linkText}</a>: </>}
    <span>{`${title}${currentTime ? ` - ${durationToTime(currentTime)}` : ''}`}</span>
  </span>
);

const copy = ({ title, link, linkText, currentTime }) => {
  const htmlText = ReactDOMServer.renderToStaticMarkup(<LinkCopyText title={title} link={link} linkText={linkText} currentTime={currentTime} />);
  copyToClipboardAsHtml(htmlText);
};

export const Copy = ({ title, currentTime, link, linkText, className }) => {
  const { t, trackEvent } = useInfra();
  const [isCopied, setIsCopied] = useState(false);

  const onClick = () => {
    copy({ title, link, linkText, currentTime });
    setIsCopied(true);
    trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.COPY_TO_CLIPBOARD });
  };

  return (
    <Tooltip content={t(isCopied ? CALL_STRINGS.COPIED_CALL_TOOLTIP : CALL_STRINGS.COPY_CALL_TOOLTIP)}>
      <CopyIcon data-testid="copy-button" className={className} onClick={onClick} onMouseLeave={() => setIsCopied(false)} />
    </Tooltip>
  );
};
