import React from 'react';
import { BottomLabel, Format, KPIContainer, Label, Value, ValueContainer } from './StyledKPI';
import { InfoIcon } from '../InfoIcon/InfoIcon';
import { Tooltip } from '../Tooltip';

export const KPI = ({ label, tooltip, value, format, bottomLabel, hideInfoIcon, sidesMargin }) => (
  <KPIContainer sidesMargin={sidesMargin}>
    <ValueContainer>
      <Value>{value}</Value>
      <Format>{format}</Format>
    </ValueContainer>
    <Tooltip content={hideInfoIcon && tooltip}>
      <Label>
        {label}
        {!hideInfoIcon && tooltip && (
        <InfoIcon tooltipContent={tooltip} />
        )}
      </Label>
    </Tooltip>
    {
        bottomLabel && (
          <BottomLabel>
            {bottomLabel}
          </BottomLabel>
        )
      }
  </KPIContainer>
);
