export default ({
  data, xField, yField, withMarker = true, allowZero
}) => ({
  series: data && data.map((serie) => ({
    ...serie,
    name: serie.text,
    color: serie.color,
    data: serie.data.map((item) => ({
      ...item,
      x: item[xField] || (allowZero && item[xField] === 0 ? 0 : null),
      y: item[yField] || (allowZero && item[yField] === 0 ? 0 : null)
    })),
    marker: {
      enabled: withMarker
    }
  }))
});
