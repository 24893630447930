import find from 'lodash/find';

export const SUPPORTED_CALL_LOCALES = [
  { key: 'en-US', text: 'English (United States)', lcid: 1033, threeLetterISOLanguageName: 'eng' },
  { key: 'en-GB', text: 'English (United Kingdom)', lcid: 2057, threeLetterISOLanguageName: 'eng' },
  { key: 'de-DE', text: 'German (Germany)', lcid: 1031, threeLetterISOLanguageName: 'deu' },
  { key: 'nl-NL', text: 'Nederlands (Nederland)', lcid: 1043, threeLetterISOLanguageName: 'nld' },
  { key: 'fr-FR', text: 'Français (France)', lcid: 1036, threeLetterISOLanguageName: 'fra' },
  { key: 'fr-CA', text: 'Français (Canada)', lcid: 3084, threeLetterISOLanguageName: 'fra' },
  { key: 'it-IT', text: 'Italiano (Italia)', lcid: 1040, threeLetterISOLanguageName: 'ita' },
  { key: 'es-ES', text: 'Español (España, alfabetización internacional)', lcid: 1034, threeLetterISOLanguageName: 'spa' },
  { key: 'es-MX', text: 'Español (México)', lcid: 2058, threeLetterISOLanguageName: 'spa' },
  { key: 'ja-JP', text: '日本語 (日本)', lcid: 1041, threeLetterISOLanguageName: 'jpn' },
  { key: 'pt-BR', text: 'Português (Brasil)', lcid: 1046, threeLetterISOLanguageName: 'por' },
  { key: 'pt-PT', text: 'Português (Portugal)', lcid: 2070, threeLetterISOLanguageName: 'por' },
  { key: 'zh-CN', text: '中文(中国)', lcid: 2052, threeLetterISOLanguageName: 'zho' },
  { key: 'he-IL', text: 'עברית (ישראל)', lcid: 1037, threeLetterISOLanguageName: 'heb' },
  { key: 'ar-AE', text: 'العربية (الإمارات العربية المتحدة)', lcid: 14337, threeLetterISOLanguageName: 'ara' },
  { key: 'ar-BH', text: 'العربية (البحرين)', lcid: 15361, threeLetterISOLanguageName: 'ara' },
  { key: 'ar-EG', text: 'العربية (مصر)', lcid: 3073, threeLetterISOLanguageName: 'ara' },
  { key: 'ar-IQ', text: 'العربية (العراق)', lcid: 2049, threeLetterISOLanguageName: 'ara' },
  { key: 'ar-JO', text: 'العربية (الأردن)', lcid: 11265, threeLetterISOLanguageName: 'ara' },
  { key: 'ar-KW', text: 'العربية (الكويت)', lcid: 13313, threeLetterISOLanguageName: 'ara' },
  { key: 'ar-LB', text: 'العربية (لبنان)', lcid: 12289, threeLetterISOLanguageName: 'ara' },
  { key: 'ar-OM', text: 'العربية (عمان)', lcid: 8193, threeLetterISOLanguageName: 'ara' },
  { key: 'ar-QA', text: 'العربية (قطر)', lcid: 16385, threeLetterISOLanguageName: 'ara' },
  { key: 'ar-SA', text: 'العربية (المملكة العربية السعودية)', lcid: 1025, threeLetterISOLanguageName: 'ara' },
  { key: 'ar-SY', text: 'العربية (سوريا)', lcid: 10241, threeLetterISOLanguageName: 'ara' },
  { key: 'da-DK', text: 'Danish (Denmark)', lcid: 1030, threeLetterISOLanguageName: 'dan' },
  { key: 'sv-SE', text: 'Swedish (Sweden)', lcid: 1053, threeLetterISOLanguageName: 'swe' },
  { key: 'fi-FI', text: 'Finnish (Finland)', lcid: 1035, threeLetterISOLanguageName: 'fin' },
  { key: 'nb-NO', text: 'Norwegian (Norway)', lcid: 1044, threeLetterISOLanguageName: 'nor' },
  { key: 'ko-KR', text: 'Korean (Korea)', lcid: 1042, threeLetterISOLanguageName: 'kor' },
  { key: 'tr-TR', text: 'Turkish (Turkey)', lcid: 1055, threeLetterISOLanguageName: 'tur' },
  { key: 'cs-CZ', text: 'Czech (Czech Republic) ', lcid: 1029, threeLetterISOLanguageName: 'ces' },
  { key: 'el-GR', text: 'Greek (Greece)', lcid: 1032, threeLetterISOLanguageName: 'ell' },
  { key: 'pl-PL', text: 'Polish (Poland)', lcid: 1045, threeLetterISOLanguageName: 'pol' },
  { key: 'th-TH', text: 'Thai (Thailand)', lcid: 1054, threeLetterISOLanguageName: 'tha' },
  { key: 'ru-RU', text: 'Russian (Russia)', lcid: 1049, threeLetterISOLanguageName: 'rus' }
];

export const SUPPORTED_CALL_LOCALES_SORTED = SUPPORTED_CALL_LOCALES.sort((a, b) => a.text.localeCompare(b.text))

export const getLocaleByLCID = (lcid) => (find(LOCALES_MAP, { id: lcid }) || {}).key;

export const RTL_LOCALES = [
  'ar-DZ', 'ar-BH', 'ar-EG', 'ar-IQ', 'ar-JO', 'ar-KW', 'ar-LB', 'ar-LY', 'ar-MA', 'ar-OM', 'ar-QA', 'ar-SA', 'ar-SD', 'ar-SY', 'ar-TN', 'ar-AE', 'ar-YE', 'ar',
  'fa-AF', 'fa-IR', 'fa',
  'ha-Latn', 'ha-Latn-GH', 'ha-Latn-NE', 'ha-Latn-NG', 'ha',
  'he-IL', 'he',
  'ps-AF', 'ps',
  'ur-IN', 'ur-PK', 'ur',
];

export const LOCALES_MAP = {
  en: { key: 'en-US', id: 1033 },
  ar: { key: 'ar-SA', id: 1025 },
  'ar-SA': { key: 'ar-SA', id: 1025 },
  bg: { key: 'bg-BG', id: 1026 },
  'bg-BG': { key: 'bg-BG', id: 1026 },
  ca: { key: 'ca-ES', id: 1027 },
  'ca-ES': { key: 'ca-ES', id: 1027 },
  cs: { key: 'cs-CZ', id: 1029 },
  'cs-CZ': { key: 'cs-CZ', id: 1029 },
  da: { key: 'da-DK', id: 1030 },
  'da-DK': { key: 'da-DK', id: 1030 },
  de: { key: 'de-DE', id: 1031 },
  'de-DE': { key: 'de-DE', id: 1031 },
  el: { key: 'el-GR', id: 1032 },
  'el-GR': { key: 'el-GR', id: 1032 },
  es: { key: 'es-ES', id: 3082 },
  'es-ES': { key: 'es-ES', id: 3082 },
  et: { key: 'et-EE', id: 1061 },
  'et-EE': { key: 'et-EE', id: 1061 },
  'eu-ES': { key: 'eu-ES', id: 1069 },
  fi: { key: 'fi-FI', id: 1035 },
  'fi-FI': { key: 'fi-FI', id: 1035 },
  fr: { key: 'fr-FR', id: 1036 },
  'fr-FR': { key: 'fr-FR', id: 1036 },
  gl: { key: 'gl-ES', id: 1110 },
  'gl-ES': { key: 'gl-ES', id: 1110 },
  he: { key: 'he-IL', id: 1037 },
  'he-IL': { key: 'he-IL', id: 1037 },
  hi: { key: 'hi-IN', id: 1081 },
  'hi-IN': { key: 'hi-IN', id: 1081 },
  hr: { key: 'hr-HR', id: 1050 },
  'hr-HR': { key: 'hr-HR', id: 1050 },
  hu: { key: 'hu-HU', id: 1038 },
  'hu-HU': { key: 'hu-HU', id: 1038 },
  id: { key: 'id-ID', id: 1057 },
  'id-ID': { key: 'id-ID', id: 1057 },
  it: { key: 'it-IT', id: 1040 },
  'it-IT': { key: 'it-IT', id: 1040 },
  ja: { key: 'ja-JP', id: 1041 },
  'ja-JP': { key: 'ja-JP', id: 1041 },
  kk: { key: 'kk-KZ', id: 1087 },
  'kk-KZ': { key: 'kk-KZ', id: 1087 },
  ko: { key: 'ko-KR', id: 1042 },
  'ko-KR': { key: 'ko-KR', id: 1042 },
  lt: { key: 'lt-LT', id: 1063 },
  'lt-LT': { key: 'lt-LT', id: 1063 },
  lv: { key: 'lv-LV', id: 1062 },
  'lv-LV': { key: 'lv-LV', id: 1062 },
  ms: { key: 'ms-MY', id: 1086 },
  'ms-MY': { key: 'ms-MY', id: 1086 },
  nb: { key: 'nb-NO', id: 1044 },
  'nb-NO': { key: 'nb-NO', id: 1044 },
  nl: { key: 'nl-NL', id: 1043 },
  'nl-NL': { key: 'nl-NL', id: 1043 },
  pl: { key: 'pl-PL', id: 1045 },
  'pl-PL': { key: 'pl-PL', id: 1045 },
  pt: { key: 'pt-BR', id: 1046 },
  'pt-BR': { key: 'pt-BR', id: 1046 },
  'pt-PT': { key: 'pt-PT', id: 2070 },
  ro: { key: 'ro-RO', id: 1048 },
  'ro-RO': { key: 'ro-RO', id: 1048 },
  ru: { key: 'ru-RU', id: 1049 },
  'ru-RU': { key: 'ru-RU', id: 1049 },
  sk: { key: 'sk-SK', id: 1051 },
  'sk-SK': { key: 'sk-SK', id: 1051 },
  sl: { key: 'sl-SI', id: 1060 },
  'sl-SI': { key: 'sl-SI', id: 1060 },
  sr: { key: 'sr-Cyrl-CS', id: 3098 },
  'sr-Cyrl-CS': { key: 'sr-Cyrl-CS', id: 3098 },
  'sr-Latn-CS': { key: 'sr-Latn-CS', id: 2074 },
  sv: { key: 'sv-SE', id: 1053 },
  'sv-SE': { key: 'sv-SE', id: 1053 },
  th: { key: 'th-TH', id: 1054 },
  'th-TH': { key: 'th-TH', id: 1054 },
  tr: { key: 'tr-TR', id: 1055 },
  'tr-TR': { key: 'tr-TR', id: 1055 },
  uk: { key: 'uk-UA', id: 1058 },
  'uk-UA': { key: 'uk-UA', id: 1058 },
  vi: { key: 'vi-VN', id: 1066 },
  'vi-VN': { key: 'vi-VN', id: 1066 },
  zh: { key: 'zh-CN', id: 2052 },
  'zh-CN': { key: 'zh-CN', id: 2052 },
  'zh-HK': { key: 'zh-HK', id: 3076 },
  'zh-TW': { key: 'zh-TW', id: 1028 }
};
