import { getIsMobile } from '../../../../utils/styles/device-sizes';

export const mobileConfig = ({ type }) => {
  const donutTitleConfig = type === 'pie' ? {
    title: { text: null },
    subtitle: { text: null }
  } : {};

  return getIsMobile() ? {
    chart: {
      spacingLeft: 0,
      spacingRight: 0
    },
    ...donutTitleConfig,
    legend: {
      layout: 'horizontal',
      verticalAlign: 'top'
    },
  } : {};
};
