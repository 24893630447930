import React from 'react';
import { FocusZone, Shimmer, ShimmerElementType } from '@fluentui/react';
import { every, filter, isEmpty, map, upperFirst } from 'lodash';
import { useTheme } from 'styled-components';
import { InfoIcon } from '../../../../components/InfoIcon/InfoIcon';
import { NoDataErrorWithImage } from '../../../../components/NoDataError';
import { ShowMore } from '../../../../components/ShowMore/ShowMore';
import { FlexRowWrap } from '../../../../utils/styles/StyledUtils';
import { CALL_STRINGS } from '../../strings';
import { Keyword } from './Keyword';
import { KeywordWrapper } from './KeywordWrapper';
import {
  ErrorContainer,
  ItemLoading,
  KeywordContainer,
  Root,
  keywordSubtitleStyles,
  keywordTitleStyles,
  keywordsContainerStyles,
} from './StyledKeywordsPanel';

const isValidKeyword = ({ instances }) => !isEmpty(instances);
const isEmptyHighlightsItem = (item) => {
  const { keywords } = item;
  return !keywords || keywords.length === 0;
};

const moveGeneralKeywordsToEnd = (keywords) => [
  ...filter(keywords, ({ type }) => type !== 'keywords'),
  ...filter(keywords, ({ type }) => type === 'keywords'),
];

const Loading = ({ type }) => (
  <div key={type} uuid={type}>
    <ItemLoading>
      <Shimmer
        shimmerElements={[
          { type: ShimmerElementType.line },
          { type: ShimmerElementType.gap },
          { type: ShimmerElementType.circle, height: 16 },
        ]}
      />
    </ItemLoading>
  </div>
);

export const KeywordsPanel = ({
  data: translatedHighlights,
  isLoading,
  selectedKeyword: { selectedKeywordId, selectedSection },
  onKeywordClicked,
  onKeywordHover,
  isTopicsLpcComponentReady,
  t,
  KeywordComponent,
  KeywordTitleComponent,
  Persona,
  isInteractiveMode,
}) => {
  const theme = useTheme();
  const isHighlightsEmpty = every(translatedHighlights, isEmptyHighlightsItem);
  if (isHighlightsEmpty) {
    return (
      <ErrorContainer>
        <NoDataErrorWithImage title={t(CALL_STRINGS.SIDE_PIVOT_HIGHLIGHTS_EMPTY_STATE_TITLE)} />
      </ErrorContainer>
    );
  }

  const createKeywordOrQuestions = ({ type, keywords, staticKeywords, participant }) => {
    const isSectionEmpty = isEmpty(keywords);
    const isQuestionType = type.includes('questions');
    if (isSectionEmpty) {
      return isLoading ? <Loading key={type} /> : null;
    }

    if (!keywords.some(isValidKeyword)) {
      return null;
    }

    const createHoveredKeyword = ({ id, actualWord, instances, vivaTopicsId }) => (
      <KeywordWrapper
        key={id}
        actualWord={actualWord}
        instances={instances}
        type={type}
        onKeywordClicked={onKeywordClicked}
        onKeywordHover={onKeywordHover}
        t={t}
        selectedKeywordId={selectedKeywordId}
        selectedSection={selectedSection}
        staticKeywords={staticKeywords}
        vivaTopicsId={isTopicsLpcComponentReady ? vivaTopicsId : null}
        Keyword={KeywordComponent ?? Keyword}
        isInteractiveMode={isInteractiveMode}
      />
    );

    const header = upperFirst(t(`keywords_panel_${type}_title`));
    const tooltip = upperFirst(t(`keywords_panel_${type}_tooltip`));
    const group = t(
      isQuestionType ? 'keywords_panel_questions_title' : 'keywords_panel_keywords_mentioned_title'
    );
    const ariaLabel = `${isQuestionType ? participant?.primaryName ?? header : header}, ${group}`;
    return (
      <KeywordContainer
        key={type}
        $type={type}
        style={keywordsContainerStyles()}
        aria-label={ariaLabel}
      >
        {isQuestionType && KeywordTitleComponent ? (
          <KeywordTitleComponent Persona={Persona} participant={participant} />
        ) : (
          <div
            style={
              isQuestionType ? keywordTitleStyles({ theme }) : keywordSubtitleStyles({ theme })
            }
          >
            {header}
            {isQuestionType && <InfoIcon tooltipContent={tooltip} />}
          </div>
        )}
        <FlexRowWrap>
          <FocusZone>
            <ShowMore
              showMoreText={t('keywords_panel_show_more')}
              showLessText={t('keywords_panel_show_less')}
              isHiddenByHeight
              minHeight={64}
            >
              {keywords.map(createHoveredKeyword)}
            </ShowMore>
          </FocusZone>
        </FlexRowWrap>
      </KeywordContainer>
    );
  };

  const keywordHighlights = filter(translatedHighlights, ({ type }) => !type.includes('questions'));
  const questionHighlights = filter(translatedHighlights, ({ type }) => type.includes('questions'));

  return (
    <>
      {keywordHighlights.length > 0 && (
        <KeywordContainer
          style={keywordsContainerStyles()}
          key="keywords_mentioned"
          $type="keywords_mentioned"
        >
          {KeywordTitleComponent ? (
            <KeywordTitleComponent />
          ) : (
            <div style={keywordTitleStyles({ theme })}>
              {upperFirst(t('keywords_panel_keywords_mentioned_title'))}
              <InfoIcon
                tooltipContent={upperFirst(t('keywords_panel_keywords_mentioned_tooltip'))}
              />
            </div>
          )}
          {map(moveGeneralKeywordsToEnd(keywordHighlights), createKeywordOrQuestions)}
        </KeywordContainer>
      )}
      {map(questionHighlights, createKeywordOrQuestions)}
    </>
  );
};

export const KeywordsPanelWrapper = (props) => (
  <Root data-testid="highlightsTab">
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <KeywordsPanel {...props} />
  </Root>
);
