import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';
import useLocale from 'hooks/use-locale';
import { useAppNavigation } from 'hooks/use-app-navigation';
import { routes } from 'pages/routing';
import useEmptyState from 'utils/empty-state/use-empty-state';
import { colors, hexToRGBA, Trans } from 'ci-common-ui';
import { size } from 'lodash';
import ShowMore from '../../../../components/ShowMore/ShowMore';
import PageSection from '../../../components/PageSection/PageSection';
import InsightCard from '../InsightCard/InsightCard';
import DelimitedInlineList from '../../../../components/DelimitedInlineList/DelimitedInlineList';
import { createCustomerSentimentsConfig } from './chart-configs';
import { InsightCardSectionLoaders } from '../../../components/PageSection/SectionLoaders';
import { settingsModalTabs } from '../../../../constants/modals';
import { StyledInsightCard } from '../InsightCard/StyledInsightCard';
import CardErrorBoundary from '../../../../components/ErrorBoundry/CardErrorBoundry';
import { TRACK_IDS } from '../../../../constants/tracking';
import { joinTrackDelimiter } from '../../../../utils/string/string';
import { createTopTrendingChartConfig } from '../chart-configs';
import { getExcelDataFromPie } from '../../../../utils/chart';
import { QUERY_PARAMS } from '../../../../constants/urls';

const LOCALIZATION_PREFIX = 'pages.home.sections.customers';
const localEmptyState = ({
  trackedKeywordsTopMentioned,
  trackedKeywordsTopTrending,
  competitorsTopMentioned,
  competitorsTopTrending,
  customerSentiment,
  brandsTopMentioned,
  brandsTopNew
}, navigateTo, getEmptyStateDetails) => {
  const navigateToConfigureKeywordsAndCompetitors = () => navigateTo({ params: { [QUERY_PARAMS.SETTINGS]: settingsModalTabs.conversationcontent.link } });

  let keywordsEmptyState = null;
  let competitorsEmptyState = null;
  const customerSentimentEmptyState = isEmpty(customerSentiment) && getEmptyStateDetails('insights.customer_sentiment');
  const brandsTopMentionedEmptyState = isEmpty(brandsTopMentioned) && getEmptyStateDetails('insights.brands');
  const isTextEmptyStateBrandsTopNew = isEmpty(brandsTopNew);

  const isTrackedKeywordsTopMentionedEmpty = isEmpty(trackedKeywordsTopMentioned);
  const isTextEmptyStateKeywordsTopTrending = isEmpty(trackedKeywordsTopTrending);

  if (isTextEmptyStateKeywordsTopTrending && isTrackedKeywordsTopMentionedEmpty) {
    keywordsEmptyState = getEmptyStateDetails('insights.tracked_keywords', 'not_configured', navigateToConfigureKeywordsAndCompetitors);
  } else if (isTrackedKeywordsTopMentionedEmpty) {
    keywordsEmptyState = getEmptyStateDetails('insights.tracked_keywords', 'top_mentioned');
  }

  const iscompetitorsTopMentionedEmpty = isEmpty(competitorsTopMentioned);
  const isTextEmptyStateCompetitorsTopTrending = isEmpty(competitorsTopTrending);

  if (iscompetitorsTopMentionedEmpty && isTextEmptyStateCompetitorsTopTrending) {
    competitorsEmptyState = getEmptyStateDetails('insights.competitors', 'not_configured', navigateToConfigureKeywordsAndCompetitors);
  } else if (iscompetitorsTopMentionedEmpty) {
    competitorsEmptyState = getEmptyStateDetails('insights.competitors', 'top_mentioned');
  }

  return {
    keywordsEmptyState,
    competitorsEmptyState,
    customerSentimentEmptyState,
    brandsTopMentionedEmptyState,
    isTextEmptyStateBrandsTopNew,
    isTextEmptyStateCompetitorsTopTrending,
    isTextEmptyStateKeywordsTopTrending
  };
};

const CustomerInsights = ({ isLoading, data, error }) => {
  const { t, tAbs, isRTL, appModeAwareTerms } = useLocale('pages.home.sections.customers');
  const { navigateTo } = useAppNavigation();
  const { getEmptyStateDetails } = useEmptyState('pages.home.sections.customers');
  const sectionTitle = t('title');
  const sectionSubtitle = upperFirst(t('subtitle'));

  if (isLoading) return <InsightCardSectionLoaders title={sectionTitle} subtitle={sectionSubtitle} count={3} />;

  const {
    customerSentiment,
    trackedKeywordsTopMentioned,
    trackedKeywordsTopTrending,
    competitorsTopTrending,
    competitorsTopMentioned,
    brandsTopNew,
    brandsTopMentioned,
  } = data;

  const {
    keywordsEmptyState,
    competitorsEmptyState,
    customerSentimentEmptyState,
    brandsTopMentionedEmptyState,
    isTextEmptyStateBrandsTopNew,
    isTextEmptyStateCompetitorsTopTrending,
    isTextEmptyStateKeywordsTopTrending
  } = localEmptyState(data, navigateTo, getEmptyStateDetails);

  const cards = [{
    trackId: joinTrackDelimiter([TRACK_IDS.COMMON.CUSTOMER_INSIGHTS, TRACK_IDS.COMMON.CUSTOMER_SENTIMENT]),
    csvData: getExcelDataFromPie(customerSentiment),
    cardName: t('insights.customer_sentiment.card_name'),
    title:
  <Trans i18nKey={`${LOCALIZATION_PREFIX}.insights.customer_sentiment.title`} count={get(customerSentiment, 'negativeCustomerSentimentCount')}>
    {{ average: get(customerSentiment, 'negativeCustomerSentimentCount') }} {{ _department: appModeAwareTerms._department }} f2
  </Trans>,
    description: t('insights.customer_sentiment.subtitle'),
    chartConfig: () => createCustomerSentimentsConfig(customerSentiment, tAbs, isRTL),
    onShowDetails: () => navigateTo({ path: routes.customerSentimentInsight.link }),
    emptyStateDetails: customerSentimentEmptyState,
    hasError: get(error, 'customerSentiment'),
  }, {
    trackId: joinTrackDelimiter([TRACK_IDS.COMMON.CUSTOMER_INSIGHTS, TRACK_IDS.TRACKED_KEYWORDS]),
    csvData: trackedKeywordsTopMentioned,
    cardName: t('insights.tracked_keywords.card_name'),
    title: (!isTextEmptyStateKeywordsTopTrending
      && (
      <>
        <Trans i18nKey={`${LOCALIZATION_PREFIX}.insights.tracked_keywords.title`} count={size(trackedKeywordsTopTrending)} />&nbsp;
        <DelimitedInlineList boldItems data={trackedKeywordsTopTrending} />
      </>
      )
    ),
    description: t(`insights.tracked_keywords.${isTextEmptyStateKeywordsTopTrending ? 'only_text_empty_state' : 'subtitle'}`),
    chartConfig: () => createTopTrendingChartConfig(
      trackedKeywordsTopMentioned, hexToRGBA(colors.DYNPrimary, 0.7),
    ),
    onShowDetails: !isTextEmptyStateKeywordsTopTrending && (() => navigateTo({ path: routes.trackedKeywordsInsight.link })),
    emptyStateDetails: keywordsEmptyState,
    hasError: get(error, 'trackedKeywordsTopMentioned') || get(error, 'trackedKeywordsTopTrending'),
  }, {
    trackId: joinTrackDelimiter([TRACK_IDS.COMMON.CUSTOMER_INSIGHTS, TRACK_IDS.TRACKED_COMPETITORS]),
    csvData: competitorsTopMentioned,
    cardName: t('insights.competitors.card_name'),
    title: (!isTextEmptyStateCompetitorsTopTrending
      && (
      <>
        <Trans i18nKey={`${LOCALIZATION_PREFIX}.insights.competitors.title`} count={size(competitorsTopTrending)}>
          fragment1 { size(competitorsTopTrending) > 1 ? { _competitor_plural: appModeAwareTerms._competitor_plural } : { _competitor: appModeAwareTerms._competitor }} f2
        </Trans>&nbsp;
        <DelimitedInlineList boldItems data={competitorsTopTrending} />
      </>
      )
    ),
    description: t(`insights.competitors.${isTextEmptyStateCompetitorsTopTrending ? 'only_text_empty_state' : 'subtitle'}`),
    chartConfig: () => createTopTrendingChartConfig(
      competitorsTopMentioned, hexToRGBA(colors.DYNPrimary, 0.7),
    ),
    onShowDetails: !isTextEmptyStateCompetitorsTopTrending && (() => navigateTo({ path: routes.competitorsInsight.link })),
    emptyStateDetails: competitorsEmptyState,
    hasError: get(error, 'competitorsTopTrending') || get(error, 'competitorsTopMentioned'),
  }, {
    trackId: joinTrackDelimiter([TRACK_IDS.COMMON.CUSTOMER_INSIGHTS, TRACK_IDS.BRANDS]),
    csvData: brandsTopMentioned,
    cardName: t('insights.brands.card_name'),
    title: !isTextEmptyStateBrandsTopNew && (
      <>
        <Trans i18nKey={`${LOCALIZATION_PREFIX}.insights.brands.title`} count={size(brandsTopNew)}>
          {{ amount: size(brandsTopNew) }} text
        </Trans>&nbsp;
        <DelimitedInlineList boldItems data={get(data, 'brandsTopNew')} />
      </>
    ),
    description: t(`insights.brands.${isTextEmptyStateBrandsTopNew ? 'only_text_empty_state' : 'subtitle'}`),
    chartConfig: () => createTopTrendingChartConfig(
      brandsTopMentioned, hexToRGBA(colors.DYNPrimary, 0.7),
    ),
    onShowDetails: !isTextEmptyStateBrandsTopNew && (() => navigateTo({ path: routes.brandsInsight.link })),
    emptyStateDetails: brandsTopMentionedEmptyState,
    hasError: get(error, 'brandsTopMentioned') || get(error, 'brandsTopNew'),
  }];

  return (
    <PageSection title={sectionTitle} subtitle={sectionSubtitle} isLoading={isLoading}>
      <ShowMore trackId={TRACK_IDS.COMMON.CUSTOMER_INSIGHTS}>
        {
          cards.map(({
            trackId,
            csvData,
            title,
            description,
            chartConfig,
            emptyStateDetails,
            onShowDetails,
            cardName,
            hasError,
          }) => (
            <StyledInsightCard key={cardName} aria-label={`${sectionTitle} - ${cardName}`} role="region">
              <CardErrorBoundary cardName={cardName}>
                <InsightCard
                  trackId={trackId}
                  csvData={csvData}
                  cardName={cardName}
                  title={title}
                  emptyStateDetails={emptyStateDetails}
                  description={description}
                  getChartConfig={chartConfig}
                  onShowDetails={onShowDetails}
                  isErrorState={hasError}
                />
              </CardErrorBoundary>
            </StyledInsightCard>
          ))
        }
      </ShowMore>
    </PageSection>
  );
};
export default CustomerInsights;
