import styled from 'styled-components';
import { StyledText } from '../../../utils/styles/StyledUtils';
import { colors } from '../../../constants/colors';
import { IconWrapper, InformationIcon } from '../../InfoIcon/InfoIcon';

const EXTERNAL_HEIGHT = '150px';
export const CONTAINER_HEIGHT = '350px';
export const CONTAINER_HEIGHT_DURING_CALL = '250px';
const PADDING_TOP = '12px';
export const SAVE_BAR_HEIGHT = '20px';

export const Container = styled.div`
  height: ${({ isCallCompleted, isRecordingEnabled }) => isRecordingEnabled ? (isCallCompleted ? CONTAINER_HEIGHT : CONTAINER_HEIGHT_DURING_CALL) : '100%'};
  max-height: calc(100vh - ${EXTERNAL_HEIGHT});
  background-color: ${({ theme, disabled }) => disabled ? colors.Gray20 : (theme.name === 'dark' ? theme.colors.Gray10 : 'white')};
  border: ${({ theme }) => theme.name === 'dark' ? 'none' : `1px solid ${theme.colors.Gray40}`};
  border-radius: 3px;
  overflow: hidden;
  ${InformationIcon} {
    margin: 0 5px;
    vertical-align: middle;
    cursor: default;
  }
`;

export const SaveIndicator = styled.div`
  background-color: ${({ theme }) => theme.colors.Gray20};
  display: flex;
  align-items: center;
  height: ${SAVE_BAR_HEIGHT};
  justify-content: flex-end;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.Gray110};
  ${IconWrapper}{
    min-width: 16px;
    min-height: 16px;
  }
`;

export const SaveStatusLabel = styled(StyledText)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.Gray130};
`;

export const getNotesFieldStyles = (isCallCompleted, isRecordingEnabled) => ({
  root: {
    height: isRecordingEnabled ? `calc(${isCallCompleted ? CONTAINER_HEIGHT : CONTAINER_HEIGHT_DURING_CALL} - ${SAVE_BAR_HEIGHT})` : `calc(100% - ${SAVE_BAR_HEIGHT})`,
    maxHeight: `calc(100vh - ${EXTERNAL_HEIGHT} - ${SAVE_BAR_HEIGHT})`,
  },
  wrapper: {
    height: '100%'
  },
  field: {
    fontSize: 12,
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: `${PADDING_TOP} 15px`,
    selectors: {
      '::placeholder': {
        fontSize: 12,
      },
    }
  },
  fieldGroup: {
    height: '100%',
    border: 'none',
    backgroundColor: 'transparent',
    selectors: {
      ':hover': {
        borderColor: 'rgb(161, 159, 157)',
      },
      ':after': {
        border: 'none !important',
      },
    }
  }
});
