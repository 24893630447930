import React, { useEffect, useRef, useState } from 'react';
import { get, map, isEmpty, find, filter, last, first, sortBy } from 'lodash';
import { List, ScrollToMode, FocusZone, FocusZoneDirection } from '@fluentui/react';
import {
  Root,
  TranscriptContainer,
  Content,
  ResyncButton,
  ActionsContainer,
  Actions,
  TranslateButton,
} from './StyledTranscript';
import { TranscriptSearch, useTranscriptSearch } from '../TranscriptSearch/TranscriptSearch';
import { useSync } from '../../../../hooks/use-sync';
import { useToggler } from '../../../../hooks/use-toggler';
import { joinTrackDelimiter } from '../../../../utils/string/string';
import { TRACK_IDS } from '../../tracking';
import { useInfra } from '../../../../providers/InfraProvider';
import { eventAction } from '../../../../services/telemetry-service';
import { TranscriptItem } from '../../../../components/TranscriptItem';
import { extractParticipantDetails, getParticipantById } from '../../utils';
import { CALL_STRINGS } from '../../strings';
import { getIsRTLTranscript } from '../utils/transcript-utils';
import { Tooltip } from '../../../../components/Tooltip';
import { NoDataErrorWithImage } from '../../../../components/NoDataError';
import { CallPageContextMenu } from '../CallPageContextMenu';

const SCROLLER_CONTAINER_ID = 'transcriptContent';

const EmptyTranscript = () => {
  const { t } = useInfra();
  return (
      <Root data-testid="transcript-empty-state">
        <ActionsContainer />
        <TranscriptContainer>
          <ActionsContainer>
            <div>{t(CALL_STRINGS.CALL_SIDEBAR_TRANSCRIPT_TITLE)}</div>
          </ActionsContainer>
          <Content>
            <NoDataErrorWithImage title={t(CALL_STRINGS.CALL_SIDEBAR_TRANSCRIPT_EMPTY_TITLE)} subtitle={t(CALL_STRINGS.CALL_SIDEBAR_TRANSCRIPT_EMPTY_SUBTITLE)}/>
          </Content>
        </TranscriptContainer>
      </Root>
  )
}


const TranscriptInner = ({
  transcript,
  translatedTranscript,
  participants,
  comments,
  keywords,
  actionItems,
  selectedKeyword,
  selectedActionItem,
  selectedSuggestion,
  onAddComment,
  onUpdateComment,
  onDeleteComment,
  currentTime,
  setSelectedTranscriptRow,
  isPlaying,
  avatars,
  onDeleteInsightsClick
}) => {
  const { t, trackEvent } = useInfra();
  const [isTranslated, toggleTranslation] = useToggler(false);
  const currentTranscript = isTranslated ? translatedTranscript : transcript;
  const { search, setSearch, searchIndex, setSearchIndex, rowIndex, rowSearchIndex, total } = useTranscriptSearch(currentTranscript);
  const [activeRows, setActiveRows] = useState([]);
  const [rowIdToScroll, setRowIdToScroll] = useState();
  const [selectedRowId, setSelectedRowId] = useState();
  const isRTLTranscript = getIsRTLTranscript(currentTranscript);

  const { isSynced, toggleSync, onOutOfSync } = useSync(search, isPlaying);
  const listRef = useRef(null);

  useEffect(() => {
    if (!selectedKeyword) return;
    const startInSeconds = get(selectedKeyword, 'instances[0].startInSeconds');
    const endInSeconds = get(selectedKeyword, 'instances[0].endInSeconds');
    const fragmentId = get(selectedKeyword, 'instances[0].fragmentId');

    const isCurrentTimeWillNotChange = startInSeconds <= currentTime && endInSeconds > currentTime;

    if (isCurrentTimeWillNotChange) {
      scrollToRow(fragmentId);
      setSelectedRowId(fragmentId);
    }
  }, [selectedKeyword]);

  useEffect(() => {
    if (!selectedActionItem) return;

    const { startInSeconds, endInSeconds, fragmentId } = selectedActionItem;
    const isCurrentTimeWillNotChange = startInSeconds <= currentTime && endInSeconds > currentTime;

    if (isCurrentTimeWillNotChange) {
      if (fragmentId) {
        scrollToRow(fragmentId);
        setSelectedRowId(fragmentId);
      }
    }
  }, [selectedActionItem]);

  useEffect(() => {
    if (!selectedSuggestion) return;

    const { startInSeconds, endInSeconds, fragmentId } = selectedSuggestion;
    const isCurrentTimeWillNotChange = startInSeconds <= currentTime && endInSeconds > currentTime;

    if (isPlaying || isCurrentTimeWillNotChange) {
      if (fragmentId) {
        scrollToRow(fragmentId);
        setSelectedRowId(fragmentId);
      }
    }
  }, [selectedSuggestion]);

  useEffect(() => {
    if (rowIndex >= 0) {
      scrollToRow(get(transcript[rowIndex], 'id'));
    }
  }, [rowIndex]);

  useEffect(() => {
    if (currentTime === null) return;
    const currentTimeRows = filter(currentTranscript, ({ startInSeconds }) => Math.floor(startInSeconds) === currentTime);
    const onlyCurrentRowsWithComments = filter(currentTimeRows, ({ id }) => Object.keys(comments).includes(id));
    const doesCurrentTimeIncludeComments = !isEmpty(onlyCurrentRowsWithComments);

    let newActiveRows = filter(currentTranscript, ({ startInSeconds, endInSeconds }) => startInSeconds <= currentTime && endInSeconds > currentTime);

    if (doesCurrentTimeIncludeComments) {
      newActiveRows = onlyCurrentRowsWithComments;
    } else if (isEmpty(newActiveRows)) {
      const nearestRow = last(filter(currentTranscript, ({ endInSeconds }) => endInSeconds < currentTime));
      const getFirstRow = () => first(sortBy(currentTranscript, ['startInSeconds', 'endInSeconds']));
      newActiveRows = nearestRow ? [nearestRow] : [getFirstRow()];
    }

    setActiveRows(newActiveRows);

    if (!isEmpty(newActiveRows)) {
      const newRowIdToScroll = newActiveRows[0].id;
      if (newRowIdToScroll !== rowIdToScroll) {
        setRowIdToScroll(newRowIdToScroll);
      }
    }
  }, [currentTime]);

  useEffect(() => {
    if (isSynced) {
      scrollToRow(rowIdToScroll);
      if (!isPlaying) {
        setSelectedRowId(rowIdToScroll);
      }
    }
  }, [rowIdToScroll, isSynced]);

  function getPlaybackSection(fragmentId) {
    const activeRow = find(activeRows, (row) => row && row.id === fragmentId);

    if (!isPlaying || !isSynced || isTranslated || !activeRow) return null;
    const playback = filter(activeRow.words, ({ offset }) => offset / 1000 <= currentTime && activeRow.endInSeconds >= currentTime).map(({ word }) => word).join(' ');
    return playback;
  }

  const onResyncClick = () => {
    toggleSync();
    trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.TRANSCRIPT_RESYNC });
  };

  const onShowMoreClick = (isExpanded) => {
    const actionOn = joinTrackDelimiter([TRACK_IDS.TRANSCRIPT_COMMENTS, isExpanded ? TRACK_IDS.SHOW_MORE_COLLAPSED : TRACK_IDS.SHOW_MORE_EXPANDED]);
    trackEvent({ action: eventAction.click, actionOn });
  };

  const onTranslateButtonClick = () => {
    toggleTranslation();
    trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.TRANSCRIPT_TRANSLATE_BUTTON, message: { isTranslated } });
  };

  const commentTexts = {
    edit: t(CALL_STRINGS.EDIT),
    delete: t(CALL_STRINGS.DELETE),
    commented: t(CALL_STRINGS.CALL_SIDEBAR_TRANSCRIPT_COMMENTS_COMMENTED),
    cancel: t(CALL_STRINGS.CANCEL),
    save: t(CALL_STRINGS.SAVE),
    api_error: t(CALL_STRINGS.CALL_SIDEBAR_TRANSCRIPT_COMMENTS_API_ERROR),
    defaultFullName: t(CALL_STRINGS.UNKNOWN_USER)
  };

  const addCommentText = t(CALL_STRINGS.CALL_SIDEBAR_TRANSCRIPT_COMMENTS_COMMENT);
  const showMoreTexts = {
    showLess: t(CALL_STRINGS.SHOW_LESS),
    showMore: t(CALL_STRINGS.CALL_SIDEBAR_TRANSCRIPT_COMMENTS_MORE_COMMENTS),
    showMorePlural: t(CALL_STRINGS.CALL_SIDEBAR_TRANSCRIPT_COMMENTS_MORE_COMMENTS_PLURAL),
  };

  const unknown = t(CALL_STRINGS.UNKNOWN);

  const items = map(currentTranscript, (row, index) => {
    const participantDetails = extractParticipantDetails(getParticipantById(participants, row.speakerId), unknown);
    const participantAvatar =  avatars?.[participantDetails.aadId];
    return ({
      key: row.id,
      commentTexts,
      addCommentText,
      showMoreTexts,
      onTranscriptClick: setSelectedTranscriptRow,
      onShowMoreClick,
      row: {
        ...row,
        index
      },
      comments: get(comments, row.id) || get(comments, index),
      search,
      currentSearchIndex: rowIndex === index ? rowSearchIndex : null,
      keywords,
      actionItems,
      selectedKeyword,
      selectedActionItem,
      participantDetails,
      participantAvatar,
      playback: getPlaybackSection(row.id),
      onAddComment,
      onUpdateComment,
      onDeleteComment,
      isRTL: isRTLTranscript,
      isSelected: selectedRowId === row.id
    });
  });

  const scrollToRow = (fragmentId) => {
    const index = items.findIndex((i) => i.key === fragmentId);
    const updatedSelectedIndex = Math.min(Math.max(index, 0), items.length - 1);
    listRef.current?.scrollToIndex(
      updatedSelectedIndex, () => 0, ScrollToMode.center
    );
  };

  const onRenderCell = React.useCallback((item) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TranscriptItem {...item} />
  ), []);

  return (
    <Root>
      <ActionsContainer>
        <Actions style={{ justifyContent: 'flex-end' }}>
          {onDeleteInsightsClick && <CallPageContextMenu onDeleteInsightsClick={onDeleteInsightsClick} />}
        </Actions>
      </ActionsContainer>
      <TranscriptContainer>
      <ActionsContainer>
        <div>{t(CALL_STRINGS.CALL_SIDEBAR_TRANSCRIPT_TITLE)}</div>
        <Actions>
          {!isEmpty(translatedTranscript) && (
          <Tooltip content={t(CALL_STRINGS[isTranslated ? 'BACK_TO_ORIGINAL' : 'TRANSLATE_TO_ENGLISH'])}>
            <TranslateButton onClick={onTranslateButtonClick}>{t(CALL_STRINGS.TRANSLATE)}</TranslateButton>
          </Tooltip>
          )}
          <TranscriptSearch search={search} searchIndex={searchIndex} total={total} setSearchIndex={setSearchIndex} setSearch={setSearch} />
        </Actions>
      </ActionsContainer>
        {!isSynced && <ResyncButton onClick={onResyncClick} text={t(CALL_STRINGS.CALL_SIDEBAR_TRANSCRIPT_RESYNC)} iconProps={{ iconName: 'Sync' }} />}
        <Content data-is-scrollable id={SCROLLER_CONTAINER_ID} onClick={onOutOfSync} onWheel={onOutOfSync}>
          <FocusZone direction={FocusZoneDirection.vertical}>
            <List items={items} renderedWindowsAhead={1} onRenderCell={onRenderCell} componentRef={listRef} getPageSpecification={() => ({ itemCount: 1 })} />
          </FocusZone>
        </Content>
      </TranscriptContainer>
    </Root>
  );
};


export const Transcript = (props) =>{
  const {transcript} = props;

  if(isEmpty(transcript)){
    return <EmptyTranscript/>
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <TranscriptInner {...props}/>
}
