export const isIE = !!window.navigator.msSaveBlob;

export const sendEmail = (email, { subject }) => {
  let url = `mailto:${email}?`;
  if (subject) {
    url += `subject=${subject}`;
  }
  window.open(url);
};

export const openChat = (email) => window.open(`sip:${email}`);

export const download = (filename, url) => {
  const element = document.createElement('a');
  element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(url)}`);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const isWindowTopAccessible = () => {
  try {
    // eslint-disable-next-line no-unused-expressions
    window.top.location.hostname;
    return true;
  } catch (err) {
    return false;
  }
};
