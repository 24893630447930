import React from 'react';
import every from 'lodash/every';
import isNil from 'lodash/isNil';
import { PercentageBar, getIsOnlyAgentsOrOnlyAgent } from 'ci-common-ui';
import { series, transformSentimentDataForPercentagesBar } from 'utils/chart';
import useLocale from 'hooks/use-locale';
import { OnlyAgent, BarContainer } from './StyledSentimentBarCell';

const SentimentBarCell = ({ data, row = {} }) => {
  const { t } = useLocale('common');
  if (getIsOnlyAgentsOrOnlyAgent(row.consentMode)) {
    return (
      <OnlyAgent>{t('seller_recording_only')}</OnlyAgent>
    );
  }

  if (!data || every([data.positive, data.neutral, data.negative], isNil)) return null;

  const values = transformSentimentDataForPercentagesBar([{
    id: data.id,
    positive: data.positive,
    neutral: data.neutral,
    negative: data.negative,
  }])[0];

  const parts = [
    { name: t(series.sentimentParams[2].key.toLowerCase()), color: series.sentimentParams[2].color, value: values.positive / 100 },
    { name: t(series.sentimentParams[1].key.toLowerCase()), color: series.sentimentParams[1].color, value: values.neutral / 100 },
    { name: t(series.sentimentParams[0].key.toLowerCase()), color: series.sentimentParams[0].color, value: values.negative / 100 }
  ];

  return (
    <BarContainer>
      <PercentageBar parts={parts} showTooltip />
    </BarContainer>
  );
};
export default SentimentBarCell;
