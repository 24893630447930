import React, { useState } from 'react';
import useLocale from 'hooks/use-locale';
import size from 'lodash/size';
import toLower from 'lodash/toLower';
import { DropdownField, useFetch, accessibilityUtils, Trans } from 'ci-common-ui';
import { getTeamSellers, refreshTeamSellers, getSettingsData } from 'network/data-services/settings';
import { appContext, getIsAdminUser } from 'utils/app-context/app-context';
import { Link } from '../../../../../components/Link/Link';
import SettingsForm from '../../SettingsForm/SettingsForm';
import SellersTable from '../components/SellersTable/SellersTable';
import { TabSection, TabSectionDescription } from '../../../StyledSettingsModal';
import { SectionText } from '../../../../../components/Modal/StyledModal';
import { hierarchyOptions } from './utils';
import { ViewAs } from './ViewAs/ViewAs';
import { componentLoadingTimeService } from '../../../../../services/component-loading-time-service/component-loading-time-service';
import { TRACK_IDS } from '../../../../../constants/tracking';

const HIERARCHY_DROPDOWN_WIDTH = '50px';
const LOCALIZATION_PREFIX = 'pages.settings.your_team';

export const YourTeamContent = ({ isLoadingSettingsData, values, sellers, isLoadingTable, errorFetchTeamSellers, onRefreshTable }) => {
  const { t, tAbs, tDefault } = useLocale(LOCALIZATION_PREFIX);
  const isAdmin = getIsAdminUser();
  const [filteredSellers, setFilteredSellers] = useState();

  const { readOnChange } = accessibilityUtils;

  const onSearch = (searchText) => {
    const filteredValues = searchText && sellers ? sellers.filter(({ fullName }) => toLower(fullName).includes(toLower(searchText))) : sellers;
    setFilteredSellers(filteredValues);
    const amountOfItemsToBeRead = size(filteredValues);
    readOnChange(amountOfItemsToBeRead ? `${amountOfItemsToBeRead} ${tAbs('common.new_matches')}` : tAbs('common.no_matches_found'));
  };

  const learnMoreUrl = 'https://go.microsoft.com/fwlink/?linkid=2098810';

  return (
    <>
      <SectionText>{t('title')}</SectionText>

      {isAdmin && (
        <TabSection>
          <TabSectionDescription bold>{t('hierarchy_title')}</TabSectionDescription>
          <TabSectionDescription>{t('hierarchy_description')}</TabSectionDescription>
          <DropdownField
            name="hierarchy"
            options={hierarchyOptions}
            isLoading={isLoadingSettingsData}
            width={HIERARCHY_DROPDOWN_WIDTH}
            errorMessages={{ required: tAbs('common.required') }}
          />
        </TabSection>
      )}

      {isAdmin && (
        <TabSection>
          <TabSectionDescription bold>{t('view_as.title')}</TabSectionDescription>
          <TabSectionDescription><Trans i18nKey={`${LOCALIZATION_PREFIX}.view_as.subtitle`}><br /></Trans></TabSectionDescription>
          <ViewAs data={values.viewAs} isLoading={isLoadingSettingsData} />
        </TabSection>
      )}

      <TabSection>
        <TabSectionDescription bold>{t('sellers_title')}</TabSectionDescription>
        <TabSectionDescription>{t('sellers_description')}</TabSectionDescription>
        <TabSectionDescription>{t('sellers_delete_description')}
          <Link href={learnMoreUrl} target="_blank" aria-label={tDefault('sellers_delete_description')} role="link">{tAbs('common.learn_more')}</Link>
        </TabSectionDescription>
        <SellersTable
          fieldName="topPerformers"
          sellers={filteredSellers || sellers}
          isLoading={isLoadingTable}
          error={errorFetchTeamSellers}
          isAdmin={isAdmin}
          onSearchChange={onSearch}
          onRefresh={onRefreshTable}
        />

      </TabSection>
    </>
  );
};

const YourTeamTab = ({ closeModal, setIsRefreshNeeded }) => {
  const { t } = useLocale('pages.settings.your_team');
  const { orgId } = appContext;
  const isAdmin = getIsAdminUser();
  const { fetchData: refreshTeam, isLoading: isLoadingRefreshTeamSellers } = useFetch(refreshTeamSellers);
  const { data: sellers, fetchData: fetchTeamSellers, isLoading: isLoadingTeamSellers, error: errorFetchTeamSellers } = useFetch((forceFetch) => getTeamSellers(orgId, isAdmin, forceFetch));
  const isLoadingTable = isLoadingTeamSellers || isLoadingRefreshTeamSellers;

  const onRefreshTable = async () => {
    await refreshTeam();
    fetchTeamSellers(true);
  };

  const getInitialValuesRequest = async () => {
    const initialValues = await getSettingsData(orgId);
    await fetchTeamSellers(true);

    componentLoadingTimeService.end(TRACK_IDS.SOURCES.SETTINGS_MODAL);
    return initialValues;
  };

  return (
    <SettingsForm
      tab="yourTeam"
      title={t('nav_title')}
      closeModal={closeModal}
      sellers={sellers}
      getInitialValuesRequest={getInitialValuesRequest}
      setIsRefreshNeeded={setIsRefreshNeeded}
      render={({ isLoadingSettingsData, values }) => (
        <YourTeamContent
          isLoadingSettingsData={isLoadingSettingsData}
          values={values}
          sellers={sellers}
          isLoadingTable={isLoadingTable || isLoadingSettingsData}
          errorFetchTeamSellers={errorFetchTeamSellers}
          onRefreshTable={onRefreshTable}
        />
      )}
    />

  );
};

export default YourTeamTab;
