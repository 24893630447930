import { useHistory, useLocation } from 'react-router';
import transform from 'lodash/transform';
import join from 'lodash/join';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import qs from 'query-string';
import { getRoutePageIdByPathname, routes } from '../pages/routing';
import { constantQueryParamsArray } from '../constants/urls';

export const useAppNavigation = () => {
  const history = useHistory();

  const navigateTo = ({ path, params, options }) => {
    const { mustParams } = routes[getRoutePageIdByPathname(path || window.location.pathname)] || { mustParams: [] };
    const updatedSearchQuery = get(options, 'overrideConstantParams') ? params : merge({}, pick(getSearchParams(), constantQueryParamsArray.concat(mustParams)), params);
    const updatedSearchQueryString = join(
      transform(
        updatedSearchQuery,
        (result, value, key) => {
          if (!isNil(value) && !isNil(key)) {
            result.push(`${key}=${encodeURIComponent(value)}`);
          }
        }, []
      ),
      '&'
    );
    const navigationObject = {
      search: updatedSearchQueryString
    };
    if (path) {
      navigationObject.pathname = path;
    }
    if (get(options, 'shouldReplace')) {
      history.replace(navigationObject);
    } else {
      history.push(navigationObject);
    }
    if (get(options, 'forceRefresh')) {
      window.location.reload();
    }
  };

  const navigateBack = () => {
    history.goBack();
  };

  const getSearchParams = () => qs.parse(history.location.search);

  return {
    navigateTo,
    navigateBack,
  };
};

export const useAppLocation = () => {
  const location = useLocation();

  const getSearchParam = (searchParamKey) => {
    const searchParams = getSearchParams();
    return searchParams[searchParamKey];
  };

  const getSearchParams = () => qs.parse(location.search);

  return {
    getSearchParam
  };
};
