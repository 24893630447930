import styled from 'styled-components';
import { IconButton } from '@fluentui/react';
import {
  FlexRow,
  FlexRowCentered,
  FlexColumnCentered,
} from '../../utils/styles/StyledUtils';
import { colors } from '../../constants/colors';
import { rtl } from '../../utils/styles/rtl';

export const CarouselContainer = styled(FlexColumnCentered)``;

export const SliderContainer = styled(FlexRow)``;

export const ArrowContainer = styled(FlexColumnCentered)`
  ${rtl`padding-right`}: ${({ type }) => (type === 'Forward' ? '0px' : '8px')};
  ${rtl`padding-left`}: ${({ type }) => (type === 'Back' ? '0px' : '8px')};
`;

export const CardsContainer = styled(FlexRow)`
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const CardContainer = styled(FlexRow)`
  ${rtl`padding-right`}: ${({ isLastCard }) => (isLastCard ? '0px' : '16px')};
`;

export const DotsContainer = styled(FlexRowCentered)``;

export const DotContainer = styled(FlexColumnCentered)``;

export const DotIcon = styled(IconButton).attrs({
  iconProps: { iconName: 'LocationDot' },
})`
  margin: 0 10px;
  margin-top: 5px;
`;

export const dotIconStyles = (isActive) => ({
  root: {
    display: 'inline-block',
    marginLeft: '-6px',
    marginRight: '-6px',
  },
  rootHovered: { backgroundColor: 'transparent' },
  rootPressed: { backgroundColor: 'transparent' },
  icon: {
    color: isActive ? colors.DYNPrimary : colors.DYNTint20,
  },
  iconHovered: { backgroundColor: 'transparent' },
});
