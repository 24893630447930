import styled from 'styled-components';
import { colors, StyledText, FlexColumn, FlexRow, rtl, CalendarPicker, breakPoints } from 'ci-common-ui';
import { Pivot, PrimaryButton } from '@fluentui/react';
import { FontSizes } from '@uifabric/fluent-theme';
import { Card } from '@uifabric/react-cards';

export const StyledRangeDates = styled(StyledText)`
  margin-top: 8px;
  color: ${colors.Gray130};
`;

export const StyledCustomFilterContainer = styled(Card)`
  position: absolute;
  ${rtl`right: 0;`}
  top: 38px;
  background: ${colors.White};
  padding: 12px 16px;
  max-width: 600px;
  z-index: 55;
`;

export const StyledCustomFilterFooter = styled(FlexRow)`
  justify-content: flex-end;  
  margin-top: 12px;
`;

export const StyledStartCalendarPicker = styled(CalendarPicker)`
  ${rtl`margin-right: 14px;`}
`;

export const ApplyButton = styled(PrimaryButton)`
  ${rtl`margin-right: 12px;`}
`;

export const calendarButtonStyle = {
  root: {
    width: '120px'
  }
};

export const StyledPivotContainer = styled(FlexColumn)`
  align-items: flex-end;
`;

const linkStyle = (isRtl) => ({
  height: '30px',
  flex: '1',
  fontSize: FontSizes.size14,
  minWidth: '96px',
  transition: 'background-color 150ms ease',
  selectors: {
    [`:${isRtl ? 'first' : 'last'}-child`]: {
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px'
    },
    [`:${isRtl ? 'last' : 'first'}-child`]: {
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px'
    },
    [`:not(:${isRtl ? 'first' : 'last'}-child)`]: {
      borderRight: `1px solid ${colors.Gray60}`,
    },
    [`@media (max-width: ${breakPoints.mobile}px)`]: {
      borderBottom: `1px solid ${colors.Gray60}`
    }
  },
});

export const pivotStyles = (isDisabled, isRtl) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    pointerEvents: isDisabled ? 'none' : 'auto'
  },

  link: linkStyle(isRtl),
  linkIsSelected: {
    ...linkStyle(isRtl),
    ...(isDisabled && { backgroundColor: colors.Gray60 }),
  },
  linkContent: {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
});

export const StyledPivot = styled(Pivot)`
  position: relative;
  border: 1px solid ${colors.Gray60};
  border-radius: 4px;
  width: 100%;
`;
