import React from 'react';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { Highlight, Match } from './StyledSearchMatchesCell';
import { MATCHES_KEYS } from '../../../../network/filters/search-filters';

export const order = [
  { arrKey: MATCHES_KEYS.SUBJECT, tKey: 'common.subject' },
  { arrKey: MATCHES_KEYS.BRANDS, tKey: 'common.brands' },
  { arrKey: MATCHES_KEYS.COMPETITORS, tKey: 'common.competitors' },
  { arrKey: MATCHES_KEYS.KEYWORDS, tKey: 'common.keywords' },
  { arrKey: MATCHES_KEYS.TAGS, tKey: 'common.tags' },
  { arrKey: MATCHES_KEYS.TRACKERS, tKey: 'common.trackers' }
];

const randomize = (index) => Math.ceil(Math.random() * (index + 2) * 1000);
const getKey1 = (index) => `match-${randomize(index)}`;
const getKey2 = (index) => `full-highlight-${randomize(index)}`;
const getKey3 = (index) => `highlight-${randomize(index)}`;

const getHighlightedText = (match, text, index, lastIndex) => {
  if (isEmpty(text)) { return null; }

  return (
    <Match key={getKey1(index)}>
      {
        !text.toLowerCase().includes(match.toLowerCase()) ? <Highlight key={getKey2(index)}>{text}</Highlight>
          : text
            .replace(new RegExp(match, 'gi'), `_${match}_`)
            .split('_')
            .filter((it, idx) => !(!it && idx === 0))
            .map((item) => item === match ? <Highlight key={getKey3(index)}>{item}</Highlight> : item)
      }
      { index === lastIndex ? null : <>,&nbsp;</> }
    </Match>
  );
};

export const getEntityMatches = (query, data) => uniq(data).map((item, index, arr) => getHighlightedText(query, item, index, arr.length - 1));
