import React from 'react';
import { Persona, PersonaSize } from '@fluentui/react';
import { isFunction } from 'lodash';
import { PersonaContainer, IconStyled } from './StyledPersonaCell';
import { Tooltip } from '../../../Tooltip';

export const PersonaCell = ({ data, row, column }) => {
  const isClickAble = column && !!column.onCellClick;
  const isDisabled = column && isFunction(column.disabled) ? column.disabled(row) : column && column.disabled;
  const isClickEnabled = isClickAble && !isDisabled;

  const onClick = (e) => {
    if (isClickEnabled) {
      e.stopPropagation();
      column.onCellClick(column, row);
    }
  };

  if (!data) return null;

  return (
    <PersonaContainer isClickAble={isClickEnabled} onClick={onClick}>
      <Persona text={data} imageUrl={row.Image} size={PersonaSize.size24} />
      {row.icon && (
        <Tooltip content={row.iconTooltip}>
          <IconStyled iconName={row.icon} color={row.iconColor} />
        </Tooltip>
      )}
    </PersonaContainer>
  );
};
