import React from 'react';
import styled from 'styled-components';
import { IconToggle } from '../../../IconToggle/IconToggle';
import { FlexRowCentered } from '../../../../utils/styles/StyledUtils';

export const StyledIconToggleCell = styled(FlexRowCentered)`
  height: 100%;
  justify-content: center;
  font-size: 14px;
`;

export const IconToggleCell = ({ row, column }) => (
  <StyledIconToggleCell>
    <IconToggle
      selected={row.selected}
      disabled={row.disabled}
      iconNameSelected={column.iconNameSelected}
      iconNameUnselected={column.iconNameUnselected}
      selectedText={column.selectedText}
      unselectedText={column.unselectedText}
      disabledText={column.disabledText}
      onToggle={() => column.onToggle(row)}
    />
  </StyledIconToggleCell>
);
