import styled from 'styled-components';
import { Persona } from '@fluentui/react';
import { colors } from '../../../../constants/colors';
import { FlexRow, FlexRowCentered } from '../../../../utils/styles/StyledUtils';

export const Container = styled.div`
  padding: 20px;
`;

export const Section = styled.div`
  border-radius: 25px;
  ${({ isEditMode }) => isEditMode && `
    background-color: ${colors.Gray10};
    padding: 8px;
    border-radius: 5px;
    transition: background-color 1.5s, border-radius 1.5s;
  `};
  margin-bottom: ${({ marginBottom }) => marginBottom || 25}px;
  ${({ flex }) => flex && `
    display: flex;
  `}
`;

export const CallTagsContainer = styled(Section)`
  max-width: 600px;
`;

export const SectionTitle = styled(FlexRow)`
  color: ${({ theme }) => theme.colors.Gray160};
  font-weight: 600;
  margin-bottom: 10px;
  ${({ spaceInBetween }) => spaceInBetween && 'justify-content: space-between'};
`;

export const StyledPersona = styled(Persona)`
  margin-bottom: 5px;
`;

export const TitleContainer = styled.div`
  flex: 1;
`;

export const Title = styled.div`
  text-transform: uppercase;
`;

export const personaStyles = { primaryText: { maxWidth: '200px' } };

export const OnlyAgent = styled(FlexRowCentered)`
  align-self: flex-start;
  height: 18px;
  padding: 0 8px;
  margin: auto 10px;
  background-color: ${({ theme }) => theme.colors.Gray20};
  border-radius: 20px;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.Gray130};
`;
