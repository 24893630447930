import React from 'react';
import { PivotItem } from '@fluentui/react';
import { KeywordsPanelWrapper } from '../../../KeywordsPanel';
import { CALL_STRINGS } from '../../../../strings';
import { PivotItemContainer } from '../PivotItemContainer/PivotItemContainer';

const HIGHLIGHTS_TAB = 'Highlights';

export const HighlightsPivotItem = ({
  keywords,
  selectedSegment,
  isLoading,
  selectedKeyword,
  onKeywordClicked,
  onKeywordHover,
  clearSelectedSegment,
  onRenderItemLink,
  t,
  isTopicsLpcComponentReady,
}) => (
  <PivotItem
    itemKey={HIGHLIGHTS_TAB}
    headerText={t(CALL_STRINGS.SIDE_PIVOT_HIGHLIGHTS)}
    onRenderItemLink={onRenderItemLink}
    alwaysRender
  >
    <PivotItemContainer
      selectedSegment={selectedSegment}
      clearSelectedSegment={clearSelectedSegment}
    >
      <KeywordsPanelWrapper
        data={keywords}
        isLoading={isLoading}
        selectedKeyword={selectedKeyword}
        onKeywordClicked={onKeywordClicked}
        onKeywordHover={onKeywordHover}
        isTopicsLpcComponentReady={isTopicsLpcComponentReady}
        t={t}
      />
    </PivotItemContainer>
  </PivotItem>
);
