import React from 'react';
import styled from 'styled-components';
import { DirectionalHint, TeachingBubble as TeachingBubbleFromFluent } from '@fluentui/react';
import { StyledText } from '../../utils/styles/StyledUtils';

export const TEACHING_BUBBLE_CLOSING_TRIGGER = {
  X_BUTTON: 'xButton',
  GOT_IT_BUTTON: 'gotItButton'
};

const Text = styled(StyledText)`
  color: ${({ theme }) => theme.colors.White};
`;

export function TeachingBubble({ target, onTeachingBubbleDismiss, texts, isWide, AdditionalContent }) {
  return (
    <TeachingBubbleFromFluent
      target={target}
      isWide={isWide}
      hasCloseButton
      closeButtonAriaLabel="Close"
      onDismiss={() => onTeachingBubbleDismiss(TEACHING_BUBBLE_CLOSING_TRIGGER.X_BUTTON)}
      headline={texts.teaching_bubble_headline}
      calloutProps={{ directionalHint: DirectionalHint.leftCenter, gapSpace: 28 }}
      primaryButtonProps={{ children: texts.got_it, onClick: () => onTeachingBubbleDismiss(TEACHING_BUBBLE_CLOSING_TRIGGER.GOT_IT_BUTTON) }}
    >
      <Text multiline>
        {texts.teaching_bubble_content}
      </Text>
      { AdditionalContent && <AdditionalContent /> }
    </TeachingBubbleFromFluent>
  );
}
