import React from 'react';
import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';
import { get, map, values, isEmpty, every } from 'lodash';
import { formatMonthDayYearDateAndTime } from '../../../../utils/date/date';
import { CALL_STRINGS } from '../../strings';
import { StyledText } from '../../../../utils/styles/StyledUtils';
import { SanitizedRichText } from '../../../../components/SanitizedRichText';

const StyledSubject = styled(StyledText)`
  font-size: 18px;
`;

const StyledSubtitle = styled(StyledText)`
  font-weight: 600;
`;

const Value = ({ children }) => (
  <p>
    <StyledText>{children}</StyledText>
    <br />
  </p>
);

export const getSummaryDetailsMap = ({
  participants,
  startDate,
  relatedEntities,
  notes,
  suggestions,
  actionItems,
  partnerHighlights,
}) => {
  const entitiesMap = {
    [CALL_STRINGS.CRM_ENTITY_TYPES_ACCOUNT]: map(
      get(relatedEntities, 'account'),
      ({ name }) => name
    ).join(', '),
    [CALL_STRINGS.CRM_ENTITY_TYPES_CONTACT]: map(
      get(relatedEntities, 'contact'),
      ({ name }) => name
    ).join(', '),
    [CALL_STRINGS.CRM_ENTITY_TYPES_LEAD]: get(relatedEntities, 'lead.name'),
    [CALL_STRINGS.CRM_ENTITY_TYPES_OPPORTUNITY]: get(relatedEntities, 'opportunity.name'),
    [CALL_STRINGS.CRM_ENTITY_TYPES_PHONECALL_ACTIVITY]: get(
      relatedEntities,
      'phonecall_activity.name'
    ),
  };

  return {
    [CALL_STRINGS.PARTICIPANTS]: participants,
    [CALL_STRINGS.EXEC_SUMMARY_BOARD_CALL_DATE_AND_TIME]: formatMonthDayYearDateAndTime(startDate),
    [CALL_STRINGS.RELATED_RECORDS]: entitiesMap,
    [CALL_STRINGS.NOTES]: notes,
    [CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_HIGHLIGHTS_TITLE]: suggestions,
    [CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_ACTION_ITEMS_TITLE]: actionItems,
    [CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_PARTNER_HIGHLIGHTS_TITLE]: partnerHighlights,
  };
};

export const getSummaryEmailBodyAsHtml = (props) => {
  const { t, subject } = props;

  const transformEntities = (entities) => {
    const isRelatedEntitiesEmpty = every(values(entities), (entity) => isEmpty(entity));
    if (isRelatedEntitiesEmpty) return null;

    return (
      <>
        {map(entities, (value, key) =>
          value ? (
            <li>
              <StyledText>
                {t(key)}: {value}
              </StyledText>
            </li>
          ) : null
        )}
      </>
    );
  };

  const transformInsights = (insights) => (
    <>
      {map(insights, ({ content }) => (
        <li>
          <StyledText>{content}</StyledText>
        </li>
      ))}
    </>
  );

  const transformParticipants = (participants) =>
    map(participants, ({ displayName }) => displayName || t(CALL_STRINGS.CUSTOMER)).join(', ');

  const transformHighlights = (highlights) =>
    map(highlights, ({ content }) => (
      <li>
        <SanitizedRichText html={content} />
      </li>
    ));

  const transformSubject = (value) =>
    value && (
      <>
        <StyledSubject>{value}</StyledSubject>
        <br />
        <br />
      </>
    );

  const detailsMap = getSummaryDetailsMap(props);

  const renderers = {
    [CALL_STRINGS.PARTICIPANTS]: transformParticipants,
    [CALL_STRINGS.RELATED_RECORDS]: transformEntities,
    [CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_HIGHLIGHTS_TITLE]: transformInsights,
    [CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_ACTION_ITEMS_TITLE]: transformInsights,
    [CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_PANEL_PARTNER_HIGHLIGHTS_TITLE]: transformHighlights,
  };

  const html = ReactDOMServer.renderToStaticMarkup(
    <>
      {transformSubject(subject)}
      {map(Object.keys(detailsMap), (key) => {
        const value = detailsMap[key];
        if (isEmpty(value)) return null;

        const renderer = renderers[key];
        const renderedValue = renderer ? renderer(value) : value;

        return (
          renderedValue && (
            <>
              <StyledSubtitle>{t(key)}:</StyledSubtitle>
              <Value>{renderedValue}</Value>
            </>
          )
        );
      })}
    </>
  );
  return html;
};
