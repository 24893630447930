export const GET_ALL_APPS_KEY = '__DynamicsSIAppGetAllAppsForUserWorldWide__';

export const XHR_REQUESTS_KEY = '__DynamicsSIAppDataCache__';

export const CS_APP_TOKEN_KEY = '__CustomerServiceAppTokenKey__';

export const SESSION_ID = 'msal.session.state';

export const TRY_BEFORE_BUY_DATA = 'TRY_BEFORE_BUY_DATA';

export const CI_GRAPH_API_TOKEN_KEY = 'CI_GRAPH_API_TOKEN_KEY';

export const IS_PE = 'IS_PE';