import React from 'react';
import { FlexRow, FlexColumn } from 'ci-common-ui';
import { Card, Container } from '../../StyledSellerBar';
import { Avatar, AvatarLines, AvatarLineShimmer1, AvatarLineShimmer2, Kpis, KpiShimmer1, KpiShimmer2 } from './StyledSellerBarLoader';

const SellerBarLoader = () => (
  <Card>
    <Container>
      <FlexRow>
        <Avatar />
        <AvatarLines>
          <AvatarLineShimmer1 />
          <AvatarLineShimmer2 />
        </AvatarLines>
      </FlexRow>
      <Kpis>
        <FlexColumn>
          <KpiShimmer1 />
          <KpiShimmer2 />
        </FlexColumn>
        <FlexColumn>
          <KpiShimmer1 />
          <KpiShimmer2 />
        </FlexColumn>
        <FlexColumn>
          <KpiShimmer1 />
          <KpiShimmer2 />
        </FlexColumn>
        <FlexColumn>
          <KpiShimmer1 />
          <KpiShimmer2 />
        </FlexColumn>
        <FlexColumn>
          <KpiShimmer1 />
          <KpiShimmer2 />
        </FlexColumn>
      </Kpis>
    </Container>
  </Card>
);
export default SellerBarLoader;
