// @flow
import React, { useMemo } from 'react';
import map from 'lodash/map';
import range from 'lodash/range';
import { Dropdown, IconButton } from '@fluentui/react';
import { Container, dropdownStyles, TotalPagesText } from './StyledPagination';
import { isDirectionRTL } from '../../../../utils/dom/dom';

const firstIconProps = {
  iconName: 'DoubleChevronLeft',
};
const nextIconProps = {
  iconName: 'ChevronLeft',
};
const prevIconProps = {
  iconName: 'ChevronRight',
};
const lastIconProps = {
  iconName: 'DoubleChevronRight',
};

export const Pagination = ({
  firstLabel,
  previousLabel,
  nextLabel,
  lastLabel,
  outOfLabel,
  className,
  totalPages,
  currentPage,
  onPageChanged,
}) => {
  const isRTL = isDirectionRTL();

  const pagesDropdownOptions = useMemo(
    () => map(
      range(1, totalPages + 1),
      (page) => ({ key: page, text: page }),
    ),
    [totalPages],
  );

  if (!currentPage || !totalPages) {
    return null;
  }

  return (
    <Container className={className}>
      <IconButton
        iconProps={isRTL ? lastIconProps : firstIconProps}
        title={firstLabel}
        a={1}
        ariaLabel={firstLabel}
        disabled={currentPage === 1}
        onClick={() => onPageChanged(1)}
      />
      <IconButton
        iconProps={isRTL ? prevIconProps : nextIconProps}
        title={previousLabel}
        ariaLabel={previousLabel}
        disabled={currentPage === 1}
        onClick={() => onPageChanged(currentPage - 1)}
      />
      <Dropdown
        disabled={totalPages <= 1}
        selectedKey={currentPage}
        onChange={(e, { key }) => onPageChanged(key)}
        options={pagesDropdownOptions}
        styles={dropdownStyles}
        ariaLabel={`page ${currentPage} of ${totalPages}`}
      />
      <TotalPagesText>
        {`${outOfLabel} ${totalPages}`}
      </TotalPagesText>
      <IconButton
        iconProps={isRTL ? nextIconProps : prevIconProps}
        title={nextLabel}
        ariaLabel={nextLabel}
        disabled={currentPage === totalPages}
        onClick={() => onPageChanged(currentPage + 1)}
      />
      <IconButton
        iconProps={isRTL ? firstIconProps : lastIconProps}
        title={lastLabel}
        ariaLabel={lastLabel}
        disabled={currentPage === totalPages}
        onClick={() => onPageChanged(totalPages)}
      />

    </Container>
  );
};
