import styled from 'styled-components';
import { FlexRow, StyledText, rtl, colors, hexToRGBA, deviceSizes } from 'ci-common-ui';

export const Container = styled(FlexRow)`
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  
    ${deviceSizes.tablet`
        justify-content: center;
    `}
`;

export const Item = styled(StyledText)`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    ${rtl`padding-right: 8px`};
    ${rtl`border-right: 1px solid ${hexToRGBA(colors.Black, 0.12)}`};
    ${rtl`margin-right: 8px`};
  }
  ${deviceSizes.bigMobile`
    flex-wrap: wrap;
  `}
`;

export const Title = styled(StyledText)`
    color: ${colors.Gray130};
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    margin-right: ${({ noMarginRight }) => (noMarginRight ? '0px' : '8px')};
    ${deviceSizes.bigMobile`
      overflow: auto;
      white-space: initial;
    `}
`;
