import { chartConfigs } from 'ci-common-ui';
import { formatMonthDayDate } from 'utils/date/date';
import { formatRound } from '../../../../../utils/numeric';
import { series as chartUtilsSeries } from '../../../../../utils/chart';

const { combineConfigs, dynamicLine, accessibilityConfig } = chartConfigs;

export const createOvertimeConfig = (data) => (
  combineConfigs(
    {
      data: data.map((item, index) => ({
        ...item,
        key: item.name,
        text: item.name,
        color: chartUtilsSeries.dynamicColors[index]
      })),
      xField: 'date',
      yField: 'value',
      xAxisFormatter: ({ value }) => formatMonthDayDate(value),
      yAxisFormatter: ({ value }) => formatRound(value),
    },
    [dynamicLine, accessibilityConfig],
  )
);
