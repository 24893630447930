import { jsonParse } from "../../utils/object";

export function storageFactory(storageName) {
  let browserStorageSupported;
  let storage;
  let onError;
  try {
    storage = window[storageName];
    const testKey = '__DynamicsSIAppCacheTest__';
    storage.setItem(testKey, testKey);
    storage.removeItem(testKey);
    browserStorageSupported = true;
  } catch (e) {
    storage = {};
    browserStorageSupported = false;
  }

  function isSupported() {
    return browserStorageSupported;
  }

  function clear() {
    if (browserStorageSupported) {
      storage.clear();
    } else {
      storage = {};
    }
  }

  function getItem(name) {
    if (browserStorageSupported) {
      return storage.getItem(name);
    }
    return storage[name];
  }

  function getObject(name) {
    if (browserStorageSupported) {
      return JSON.parse(storage.getItem(name));
    }
    return storage[name];
  }

  function key(index) {
    if (browserStorageSupported) {
      return storage.key(index);
    }
    return Object.keys(storage)[index] || null;
  }

  function removeItem(name) {
    if (browserStorageSupported) {
      storage.removeItem(name);
    } else {
      delete storage[name];
    }
  }

  function setItem(name, value) {
    try {
      if (browserStorageSupported) {
        storage.setItem(name, value);
      } else {
        storage[name] = String(value);
      }
    } catch (err) {
      onError?.(name, value, err);
      // eslint-disable-next-line no-console
      console.error(err);
      this.clear();
    }
  }

  function setObject(name, obj) {
    if (browserStorageSupported) {
      this.setItem(name, JSON.stringify(obj));
    } else {
      storage[name] = obj;
    }
  }

  function setOnErrorFunction(onErrorFunc) {
    onError = onErrorFunc;
  }

  return {
    getItem,
    setItem,
    removeItem,
    clear,
    key,
    isSupported,
    getObject,
    setObject,
    setOnErrorFunction
  };
}

export const storageService = {
  localStorage: storageFactory('localStorage'),
  sessionStorage: storageFactory('sessionStorage'),
};

export const getLocalStorageItemOrDefault = (localStorageItem, defaultValue) => {
  const localStorageItemValue = storageService.localStorage.getItem(localStorageItem);
  return jsonParse(localStorageItemValue, defaultValue);
}

export const getDevOnlyStorageTenantId = () => {
  const DEV_ONLY_STORAGE_TENANT_ID = 'DEV_ONLY_STORAGE_TENANT_ID';
  const devOnlyStorageTenantId = storageService.localStorage.getItem(DEV_ONLY_STORAGE_TENANT_ID);
  return !!devOnlyStorageTenantId && { storageTenantId: devOnlyStorageTenantId };
}
