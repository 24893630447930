import styled from 'styled-components';
import { rtl } from '../../../../utils/styles/rtl';

export const Header = styled.div`
  font-weight: bold;
  text-indent: 4px;
`;

export const Container = styled.div`
  font-size: 14px;
`;

export const TooltipRow = styled.tr`
  font-size: 14px;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
`;

export const TooltipBullet = styled.td`
  display: block;
  background-color: ${({ color }) => color};
  width: 8px;
  height: 8px;
  border-radius: ${({ bulletShape }) => bulletShape === 'square' ? '0px' : '50%'};
  transform: translateY(5px);
`;

export const Name = styled.td`
  ${rtl`padding-left`}: 2px;
`;

export const Value = styled.td`
  font-weight: bold;
  ${rtl`padding-left`}: 8px;
  ${rtl`text-align`}: right;
`;

export const TrendContainer = styled.td`
  ${rtl`padding-left`}: 8px;
`;
