import React from 'react';
import { useFetchNow } from 'ci-common-ui';
import useLocale from 'hooks/use-locale';
import { useQueryDateChange } from 'hooks/use-query-date-change';
import { useQueryScroll } from 'hooks/use-query-scroll';
import { scrollViewNames } from 'constants/selectors';
import { getTeamOverview } from 'network/data-services/team-service';
import { getDateParams } from 'utils/url/url';
import { getScrollViewSelectors } from 'utils/dom/dom';
import TeamInsights from './components/TeamInsights/TeamInsights';
import CustomerSentiment from './components/CustomerSentiment/CustomerSentiment';
import ConversationStyle from './components/ConversationStyle/ConversationStyle';
import Page from '../components/Page/Page';
import { getSpecificError } from '../../utils/error-state/error-state';
import { usePageLoadTime } from '../../hooks/use-page-load-time';
import { TRACK_IDS } from '../../constants/tracking';

const selectors = {
  [scrollViewNames.customerSentimentBySeller]: getScrollViewSelectors(scrollViewNames.customerSentimentBySeller),
  [scrollViewNames.talkToListenRatio]: getScrollViewSelectors(scrollViewNames.conversationStyle, scrollViewNames.talkToListenRatio),
  [scrollViewNames.switchRate]: getScrollViewSelectors(scrollViewNames.conversationStyle, scrollViewNames.switchRate)
};

const TeamPage = () => {
  const { t } = useLocale('pages.team');
  const { data, isLoading, error, fetchData } = useFetchNow((newParams) => getTeamOverview({ ...getDateParams(), ...newParams }), { getSpecificError });

  usePageLoadTime(TRACK_IDS.SOURCES.TEAM_PAGE, !isLoading);
  useQueryDateChange(fetchData);
  useQueryScroll(!!data, selectors);

  return (
    <Page title={t('title')} fullTimeRange={data && data.dateTimeRange} isLoading={isLoading} error={error}>
      <TeamInsights isLoading={isLoading} data={data} error={error} />
      <CustomerSentiment isLoading={isLoading} data={data} error={error} />
      <ConversationStyle isLoading={isLoading} data={data} error={error} />
    </Page>
  );
};
export default TeamPage;
