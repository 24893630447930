import numeral from 'numeral';
import { numericUtils } from 'ci-common-ui';
import { appContext } from './app-context/app-context';

export const { formatNumber, formatPercentage, formatFloatPercentage, formatRound, roundPercentagesProps, toNumber, formatKiloMillionBillion, numberWithCommas } = numericUtils;

// Currency
const CURRENCY_FORMAT = '0,0';
const DEFAULT_CURRENCY_SYMBOL = '$';
export const formatCurrency = (num) => `${appContext.currencySymbol || DEFAULT_CURRENCY_SYMBOL}${numeral(num).format(CURRENCY_FORMAT)}`;

// Time
const timeFormat = '00:00:00';
export const formatTime = (num) => numeral(num).format(timeFormat);

// Round
export const formatRoundAfterDot = (num, digits) => num.toFixed(digits);
