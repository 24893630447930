import styled from 'styled-components';
import EmptyPageSVG from 'assets/images/page-empty-state.svg';
import { FlexColumn, StyledText, colors, rtl } from 'ci-common-ui';

export const EmptyPageContainer = styled(FlexColumn)`
  width: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

export const EmptyPageImage = styled(EmptyPageSVG)`
  margin-top: 60px;
`;

const Text = styled(StyledText).attrs({
  multiline: true,
})`
  text-align: center;
`;

export const Title = styled(Text).attrs({
  variant: 'xxLarge',
})`
  font-weight: 600;
  color: ${colors.Gray160};
`;

export const Subtitle = styled(Text).attrs({
  variant: 'large',
})`
  margin-top: 16px;
  font-weight: 350;
  color: ${colors.Gray130};
`;

export const PlainText = styled.span`
  ${rtl`margin-left: 4px`};
`;
