import React from 'react';
import { IconButton } from '@fluentui/react';
import useLocale from '../../../hooks/use-locale';
import { StyledDismissibleBar } from '../StyledNotificationBars';

const DismissibleBar = ({ onDismiss, children, level }) => {
  const { t } = useLocale();
  const dismissText = t('common.dismiss');
  return (
    <StyledDismissibleBar level={level}>
      { children }
      <IconButton onClick={onDismiss} iconProps={{ iconName: 'Cancel' }} title={dismissText} ariaLabel={dismissText} />
    </StyledDismissibleBar>
  );
};
export default DismissibleBar;
