import styled from 'styled-components';
import { rtl, colors, FlexColumn, FocusableContainer } from 'ci-common-ui';

export const MatchesContainer = styled(FocusableContainer)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Matches = styled.span`
  ${rtl`margin-right: 4px`};
`;

export const EntityName = styled.span`
  color: ${colors.Black};
  font-weight: 600;
  ${rtl`margin-right: 4px`};
  text-transform: capitalize;
`;

export const Match = styled.span`
`;

export const Highlight = styled.span`
  background-color: ${colors.SearchMatch};
`;

export const TooltipContainer = styled.span`
`;

export const TooltipMatchesContainer = styled(FlexColumn).attrs({
  as: 'span'
})`
  margin-bottom: 8px;
`;

export const TooltipMatches = styled.span`
  margin-top: 4px;
`;
