import { getSkipAndTakeParams } from 'ci-common-ui';
import {
  loadSellers,
  loadAdminSellers,
  loadSellerData,
  loadSellerConversations,
  loadTopPerformers
} from '../rest-services/seller-rest-service';
import {
  transformSellerList,
  transformSellerListTopPerformer,
  transformSellerData,
  transformConversations
} from '../filters/sellerFilters/seller-filters';
import { getDateParams } from '../../utils/url/url';
import { getIsCustomerServiceMode } from '../../utils/app-context/app-context';

export const getTopPerformers = async (orgId, forceFetch) => {
  if (!orgId) {
    return [];
  }
  const res = await loadTopPerformers(orgId, getDateParams(), forceFetch);
  return res.data;
};

export const loadSellerList = async (orgId, forceFetch) => {
  const { data } = await loadSellers();
  const topPerformers = await getTopPerformers(orgId, forceFetch);
  return transformSellerListTopPerformer(data, topPerformers);
};

export const loadAdminSellerList = async (orgId) => {
  const { data } = await loadAdminSellers(orgId);
  return data;
};

export const getSellerList = async (orgId) => {
  const data = await loadSellerList(orgId);
  return transformSellerList(data);
};

export const getSellerData = async (params) => {
  const { data } = await loadSellerData(params);
  return transformSellerData(data);
};

export const getSellerConversations = async (params) => {
  const dates = getDateParams();
  const response = await loadSellerConversations({
    'end-date': dates.endDate,
    'start-date': dates.startDate,
    agentaadid: params.agentAadId,
    isCustomerService: getIsCustomerServiceMode(),
    ...getSkipAndTakeParams(params.currentPage),
  });
  return transformConversations(response.data);
};
