import { dayJS } from '../../utils/date/date';

export const CI_LOADING_TIMERS = {}; // exported only for unit-test purpose

export const loadingTimeService = {
  start: (key) => {
    CI_LOADING_TIMERS[key] = dayJS();
  },
  end: (key) => {
    const value = CI_LOADING_TIMERS[key];
    if (value) {
      const loadingTime = dayJS().diff(value, 'millisecond');
      delete CI_LOADING_TIMERS[key];
      return loadingTime;
    }
    return 0;
  }
};
