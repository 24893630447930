export const TRACK_IDS = {
  SOURCES: {
    CALL_PAGE: 'CALL_PAGE',
    ACTION_ITEMS: 'ACTION_ITEMS',
  },
  COMMON: { // IDs which are prefixed or suffixed to the IDs below (also can be used as is without concatenation)
    TAG_LIST: 'TAG_LIST',
    ADD: 'ADD',
    REMOVE: 'REMOVE'
  },
  AUDIO_PLAYER_FORWARD: 'AUDIO_PLAYER_FORWARD',
  AUDIO_PLAYER_BACKWARD: 'AUDIO_PLAYER_BACKWARD',
  AUDIO_PLAYER_SKIP_FORWARD: 'AUDIO_PLAYER_SKIP_FORWARD',
  AUDIO_PLAYER_SKIP_BACKWARD: 'AUDIO_PLAYER_SKIP_BACKWARD',
  AUDIO_PLAYER_PAUSE: 'AUDIO_PLAYER_PAUSE',
  AUDIO_PLAYER_PLAY: 'AUDIO_PLAYER_PLAY',
  AUDIO_PLAYER_TIMELINE_JUMP: 'AUDIO_PLAYER_TIMELINE_JUMP',
  SEGMENT: 'SEGMENT',
  KEYWORDS_PANEL: 'KEYWORDS_PANEL',
  TRANSCRIPT_COMMENTS: 'TRANSCRIPT_COMMENTS',
  TRANSCRIPT_RESYNC: 'TRANSCRIPT_RESYNC',
  TRANSCRIPT_TRANSLATE_BUTTON: 'TRANSCRIPT_TRANSLATE_BUTTON',
  TRANSCRIPT_SEARCH: 'TRANSCRIPT_SEARCH',
  TRANSCRIPT_SEARCH_NEXT: 'TRANSCRIPT_SEARCH_NEXT',
  TRANSCRIPT_SEARCH_BACK: 'TRANSCRIPT_SEARCH_BACK',
  LOAD_CALL_AUDIO: 'LOAD_CALL_AUDIO',
  TAB_CHANGED: 'TAB_CHANGED',
  KEYWORD: 'KEYWORD',
  VIVA_TOPICS_KEYWORD: 'VIVA_TOPICS_KEYWORD',
  ACTION_ITEMS_DELETE: 'ACTION_ITEMS_DELETE',
  ACTION_ITEMS_EDIT: 'ACTION_ITEMS_EDIT',
  ACTION_ITEMS_SEND_EMAIL: 'ACTION_ITEMS_SEND_EMAIL',
  ACTION_ITEMS_OPEN_CALENDAR: 'ACTION_ITEMS_OPEN_CALENDAR',
  ACTION_ITEMS_CREATE: 'ACTION_ITEMS_CREATE',
  ACTION_ITEMS_CREATE_AND_EDIT: 'ACTION_ITEMS_CREATE_AND_EDIT',
  ACTION_ITEMS_CREATE_TASK: 'ACTION_ITEMS_CREATE_TASK',
  ACTION_ITEMS_OPEN_COMMITTED: 'ACTION_ITEMS_OPEN_COMMITTED',
  ACTION_ITEM_VIEW: 'ACTION_ITEM_VIEW',
  ADD_COMMENT: 'ADD_COMMENT',
  EDIT_COMMENT: 'EDIT_COMMENT',
  DELETE_COMMENT: 'DELETE_COMMENT',
  SHOW_MORE_COLLAPSED: 'SHOW_MORE_COLLAPSED',
  SHOW_MORE_EXPANDED: 'SHOW_MORE_EXPANDED',
  COPY_TO_CLIPBOARD: 'COPY_TO_CLIPBOARD',
  EXEC_SUMMARY_ADD_SUGGESTION_TO_RECAP: 'EXEC_SUMMARY_ADD_SUGGESTION_TO_RECAP',
  EXEC_SUMMARY_SEE_ALL_SUGGESTIONS: 'EXEC_SUMMARY_SEE_ALL_SUGGESTIONS',
  EXEC_SUMMARY_SUGGESTION_TIMESTAMP: 'EXEC_SUMMARY_SUGGESTION_TIMESTAMP',
  EXEC_SUMMARY_TOGGLE_SUGGESTION_PANEL: 'EXEC_SUMMARY_TOGGLE_SUGGESTION_PANEL',
  EXEC_SUMMARY_SUGGESTIONS_MORE_ACTIONS_MENU: 'EXEC_SUMMARY_SUGGESTIONS_MORE_ACTIONS_MENU',
  EXEC_SUMMARY_SUGGESTION_GROUP_INFO_TOOLTIP: 'EXEC_SUMMARY_SUGGESTION_GROUP_INFO_TOOLTIP',
  EXEC_SUMMARY_PREVIEW_INFO_TOOLTIP: 'EXEC_SUMMARY_PREVIEW_INFO_TOOLTIP',
  EXEC_SUMMARY_DISCARD_CHANGES: 'EXEC_SUMMARY_DISCARD_CHANGES',
  EXEC_SUMMARY_SAVE: 'EXEC_SUMMARY_SAVE',
  EXEC_SUMMARY_EDIT: 'EXEC_SUMMARY_EDIT',
  EXEC_SUMMARY_COPY_TO_CLIPBOARD: 'EXEC_SUMMARY_COPY_TO_CLIPBOARD',
  EXEC_SUMMARY_EDITTED_BY: 'EXEC_SUMMARY_EDITTED_BY',
  EXEC_SUMMARY_LAST_UPDATED: 'EXEC_SUMMARY_LAST_UPDATED',
  EXEC_SUMMARY_LEAVE_WITHOUT_SAVE: 'EXEC_SUMMARY_LEAVE_WITHOUT_SAVE',
  EXEC_SUMMARY_FEATURE_TOUR_NEXT: 'EXEC_SUMMARY_FEATURE_TOUR_NEXT',
  EXEC_SUMMARY_FEATURE_TOUR_BACK: 'EXEC_SUMMARY_FEATURE_TOUR_BACK',
  EXEC_SUMMARY_FEATURE_TOUR_DISMISS: 'EXEC_SUMMARY_FEATURE_TOUR_DISMISS',
  EXEC_SUMMARY_FEATURE_TOUR_DONE: 'EXEC_SUMMARY_FEATURE_TOUR_DONE',
};
