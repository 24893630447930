import React from 'react';
import { TooltipOverflowMode } from '@fluentui/react';
import { durationToTimeText, formatMonthDayYearDate, dayJS } from '../../../../utils/date/date';
import { Container, MainTitle, Time, Date, Separator, tooltipHostStyles, HeaderTimeWrapper } from './StyledHeader';
import { useInfra } from '../../../../providers/InfraProvider';
import { CALL_STRINGS } from '../../strings';
import { Copy } from '../Copy';
import { setSearchParam } from '../../../../utils/url';
import { FlexRowCentered } from '../../../../utils/styles/StyledUtils';
import { Tooltip } from '../../../../components/Tooltip';

export const HeaderTime = ({ startDate, duration }) => {
  const startTime = dayJS(startDate).format('hh:mm A');
  const formattedDate = formatMonthDayYearDate(startDate);
  const headerDuration = durationToTimeText(duration);

  if (!headerDuration) {
    return null;
  }

  return (
    <HeaderTimeWrapper>
        <Date>{formattedDate}</Date>
        <Separator>|</Separator>
        <Time>{startTime}</Time>
        <Separator>|</Separator>
        <Time>{headerDuration}</Time>
    </HeaderTimeWrapper>
  );
};

export const Header = ({ subject, startDate, duration, BackButton, currentTimeToCopy }) => {
  const { t } = useInfra();
  const { origin, pathname } = window.location;
  const copyLink = `${origin}${pathname}?${setSearchParam(window, { playerCurrentTime: currentTimeToCopy || undefined })}`;
  return (
    <Container>
      { BackButton }
      <Tooltip styles={tooltipHostStyles} content={subject} overflowMode={TooltipOverflowMode.Self}>
        <MainTitle>{subject}</MainTitle>
      </Tooltip>
      <FlexRowCentered>
        <HeaderTime startDate={startDate} duration={duration} />
        <Copy title={subject} link={copyLink} linkText={t(CALL_STRINGS.COPY_CALL_TITLE)} currentTime={currentTimeToCopy} />
      </FlexRowCentered>
    </Container>
  );
};
