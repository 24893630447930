import { useEffect, useState } from 'react';
import get from 'lodash/get';

const initialState = {
  data: null,
  isLoading: false,
  error: null
};

async function baseFetchData(state, setState, fetchFn, params, options) {
  if (!state.isLoading) {
    setState({ ...initialState, data: state.data, isLoading: true });
  }
  try {
    const data = await fetchFn(params);
    const specificError = get(options, 'getSpecificError', () => null)(data);
    const newState = { ...initialState, data, error: specificError };

    setState(newState);
    return newState;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    // error telemetry events are caught in server-api.js
    const newState = { ...initialState, error };

    setState(newState);
    return newState;
  }
}

export const useFetchBase = (fetchFn, isFetchNow, options) => {
  const [state, setState] = useState({ ...initialState, isLoading: isFetchNow });
  const { data, isLoading, error } = state;

  const reset = () => setState(initialState);

  const fetchData = (params) => baseFetchData(state, setState, fetchFn, params, options);

  useEffect(() => {
    if (isFetchNow) {
      fetchData();
    }
  }, []);

  return {
    data, isLoading, error, fetchData, reset
  };
};

export const useFetch = (fetchFn, options) => useFetchBase(fetchFn, false, options);

export const useFetchNow = (fetchFn, options) => useFetchBase(fetchFn, true, options);
