import { chartConfigs } from 'ci-common-ui';
import { series, translateSeries, getOvertimeXAxisInterval } from 'utils/chart';
import { formatPercentage } from 'utils/numeric';
import { formatMonthDayDate } from 'utils/date/date';

const { combineConfigs, donut, donutKiloTitles, area, accessibilityConfig } = chartConfigs;

export const createPieConfig = (data, tAbs) => (
  combineConfigs(
    {
      data: data.series,
      seriesParams: translateSeries(series.sentimentParams, tAbs),
      dataLabelsFormatter: ({ percentage }) => formatPercentage(percentage),
      titleText: data.calls.toString(),
      subtitleText: tAbs('common.calls')
    },
    [
      donut,
      donutKiloTitles
    ],
  )
);

export const createOvertimeConfig = (data, tAbs) => (
  combineConfigs(
    {
      data,
      seriesParams: translateSeries(series.sentimentAreaParams, tAbs),
      categoryField: 'day',
      categoryFieldType: 'date',
      xTickInterval: getOvertimeXAxisInterval(data),
      yTickAmount: 3,
      xAxisFormatter: ({ value }) => formatMonthDayDate(value),
      yAxisFormatter: ({ value }) => formatPercentage(value),
    },
    [area, accessibilityConfig],
  )
);
