import React from 'react';
import { isEmpty } from 'lodash';
import PageHeader from '../PageHeader/PageHeader';
import PageError from '../PageError/PageError';
import { PageContainer } from '../../StyledPages';
import { isRequestErrorState } from '../../../utils/error-state/error-state';
import PageErrorBoundary from '../../../components/ErrorBoundry/PageErrorBoundary';
import EmptyPage from '../EmptyPage/EmptyPage';

export const getIsEmptyPage = (fullTimeRange, isLoading) => isEmpty(fullTimeRange) && !isLoading;

const Page = ({ title, error, isLoading, fullTimeRange, children, className, isEmptyPageDisabled, shouldShowErrorPage }) => {
  if (!isEmptyPageDisabled && !error && getIsEmptyPage(fullTimeRange, isLoading)) return <EmptyPage />;
  return (
    <PageContainer className={className}>
      <PageHeader title={title} isLoading={isLoading} fullTimeRange={fullTimeRange} isErrorState={isRequestErrorState(error)} />
      <PageErrorBoundary>
        {shouldShowErrorPage || isRequestErrorState(error) ? <PageError /> : children}
      </PageErrorBoundary>
    </PageContainer>
  );
};
export default Page;
