import styled from 'styled-components';
import { ActionButton } from '@fluentui/react';
import { rtl } from 'ci-common-ui';

export const Container = styled.div`
  padding: 20px 30px;
  padding-bottom: 10px;
`;

export const FormStyled = styled.form`
  height: 100%;
  
  & > div {
    margin-bottom: 10px;
  }
`;

export const Actions = styled.div`
  margin-top 30px;
  display: flex;
  align-items: center;
  
  & > button {
    ${rtl`margin-right: 10px;`}
  }
`;

export const RemoveButton = styled(ActionButton).attrs({
  iconProps: { iconName: 'RemoveLink' }
})`
`;
