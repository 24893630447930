import styled from 'styled-components';
import { IconButton, ActionButton, Checkbox as CheckboxFabric } from '@fluentui/react';
import { colors } from '../../../../constants/colors';
import { FlexSpaceBetween, FlexColumn, FlexRow } from '../../../../utils/styles/StyledUtils';
import { rtl } from '../../../../utils/styles/rtl';
import { Copy } from '../Copy';
import { ParticipantPersona } from '../../StyledCallPage';

const expandTransition = 'opacity 0.25s ease-in-out, display 0.1s linear, background-color 0.1s linear';

export const StyledPersona = styled(ParticipantPersona)`
  flex: 6;
  margin: 14px;
  ${rtl`margin-left: 12px`};
  ${rtl`margin-right: 8px`};
  
  .ms-Persona-details {
    ${({ showSecondaryText }) => showSecondaryText && 'height: 40px'};
  }
`;

export const ParticipantsContainer = styled(FlexColumn)``;

export const ParticipantContainer = styled(FlexSpaceBetween)`
  flex-direction: column;
  transition: ${expandTransition};
  ${({ isExpended }) => !isExpended && 'height: 60px'};
  margin-bottom: 8px;

  :hover {
    background-color: ${({ theme }) => theme.colors.Gray20};
    border-radius: 2px;
  }

  border-bottom: ${({ theme }) => `1px solid ${ theme.colors.Gray30 }`};
  
  :last-child {
    border-bottom: none;
  }
`;

  export const Header = styled(FlexRow)`
  ${({ shouldShowExpandIcon }) => shouldShowExpandIcon && 'cursor: pointer'};
  align-items: center;
  height: 60px;
`;

export const NameAndRole = styled(FlexSpaceBetween)`
  flex: 5;
  flex-direction: column;
  justify-content: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 50px;
  ${rtl`margin-right: 8px`};
`;

export const SearchContainer = styled.div`
  ${rtl`padding-left: 12px`};
  ${rtl`padding-right: 24px`};
  padding-bottom: 6px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: ${({ isRow }) => isRow ? 'row' : 'column'};
  display: ${({ isExpended }) => isExpended ? 'flex' : 'none'};
  opacity: ${({ isExpended }) => isExpended ? 1 : 0};
  transition: ${expandTransition};
  margin-bottom: 6px;
`;

export const Name = styled.div`
  font-weight: 400;
  line-height: 22px;
  height: 22px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
  color: ${colors.Black};
`;

export const Role = styled.div`
  height: 16px;
  font-size: 12px;
  color: ${colors.Gray140};
`;

export const LongestMonologueWrapper = styled.div`
  flex: 2;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 12px;
`;

export const LongestMonologueValue = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.Black};
  ${rtl`margin-right: 6px`};
`;

export const LongestMonologueSec = styled.div`
  color: ${({ theme }) => theme.colors.Black};
  align-self: baseline;
`;

export const LongestMonologueText = styled.div`
  color: ${({ theme }) => theme.colors.Gray140};
`;

export const LongestMonologueValueAndSec = styled(FlexRow)`
  line-height: 16px;
`;

export const ExpandCardIcon = styled(IconButton).attrs(({ participantName, isChevron, isUp }) => ({
  iconProps: { iconName: isChevron ? 'ChevronDown' : 'ContactLink' },
  ariaLabel: isChevron ? `${isUp ? "Collapse":"Expand"} show more about ${participantName}` : 'ContactLink'
}))`
  ${({ isChevron, isUp }) => isChevron && `transform: ${isUp ? 'rotate(-180deg)' : 'rotate(0deg)'}`};
  ${({ isChevron }) => isChevron && 'transition: all 0.5s ease'};
  ${({ isChevron }) => !isChevron && `color: ${colors.DYNPrimary}`};
  align-self: center;
  width: 14px;
  height: 14px;

  i {
    font-size: 14px;
    height: 14px;
    line-height: 14px;
    text-align: center;
    flex-shrink: 0;
  }

  :hover {
    background-color: transparent;
  }
`;

export const ExpandIconWrapper = styled.div`
  ${rtl`margin-right: 12px`};
`;

const getActionButton = (iconName) => styled(ActionButton).attrs({
  iconProps: { iconName },
})`
  border: 0;
  padding: 0 8px;
  padding-top: 12px;
`;

export const DisconnectContact = getActionButton('Cancel');
export const OpenContactInCrm = getActionButton('ContactCard');

export const Checkbox = styled(CheckboxFabric)`
  padding-top: 12px;
  padding-bottom: 6px;
  ${rtl`margin-right: 8px`};
`;

export const CheckboxContainer = styled(FlexRow)`
  align-items: baseline;
`;

export const Error = styled.div`
  color: ${colors.ScarletRed};
  padding-bottom: 8px;
`;

export const nameTooltipStyles = {
  root: { display: 'block', padding: 10 },
};

export const nameStyles = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  fontSize: '16px',
  color: colors.Black,
  maxWidth: 240
};

export const spinnerStyles = { root: { justifyContent: 'left', padding: '6px' } };

export const NumberAndCopyIconContainer = styled(FlexRow)`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`;

export const CopyIcon = styled(Copy)`
  color: ${({ theme }) => theme.colors.DYNPrimary};
  opacity: 0;
  width: 12px;
  height: 12px;
  transform: translateY(1px);
  margin: 0 4px;
  transition: all 0.25s ease-in-out;
  & .ms-Icon {
    font-size: 12px;
    height: 12px;
  }
  ${NumberAndCopyIconContainer}:hover & {
    opacity: 1;
  }
  `;
