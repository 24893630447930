import styled from 'styled-components';
import { FontSizes } from '@uifabric/fluent-theme';
import { StyledText, FlexRow, rtl, deviceSizes } from 'ci-common-ui';
import { dataAutomation, AUTOMATION_SELECTORS } from 'constants/selectors';
import { Shimmer } from '@fluentui/react';

export const PageHeaderContainer = styled(FlexRow).attrs({
  [dataAutomation]: AUTOMATION_SELECTORS.PAGE_HEADER
})`
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  min-height: 46px;
 
  ${deviceSizes.tablet`
    margin-bottom: 20px;
    flex-direction: column;
  `}
`;

export const PageTitle = styled(StyledText).attrs({
  role: 'heading',
  'aria-level': '1',
})`
  display: block;
  font-size: ${FontSizes.size32};
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${deviceSizes.tablet`
    white-space: initial;
    overflow: visible;
    text-overflow: initial;
  `}
  &:first-letter {
    text-transform: capitalize;
  }
  ${rtl`margin-right`}: 8px;
`;

export const TitleShimmer = styled(Shimmer).attrs({
  styles: {
    root: {
      minWidth: '0px',
      transform: 'translateY(-8px)'
    },
    dataWrapper: {
      maxWidth: '500px',
      wordBreak: 'break-word'
    }
  }
})`
  
  ${deviceSizes.tablet`
    margin-bottom: 20px;
  `}
`;
