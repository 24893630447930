import React from 'react';
import { routes } from '../../routing';
import BackButton from '../../../components/BackButton/BackButton';
import useLocale from '../../../hooks/use-locale';
import { useAppNavigation } from '../../../hooks/use-app-navigation';

const BackHomeButton = () => {
  const { t } = useLocale();
  const { navigateTo } = useAppNavigation();

  return (
    <BackButton
      onClick={() => navigateTo({ path: routes.home.link })}
      text={t('common.home')}
    />
  );
};
export default BackHomeButton;
