import React from 'react';
import get from 'lodash/get';
import useLocale from 'hooks/use-locale';
import useEmptyState from 'utils/empty-state/use-empty-state';
import isEmpty from 'lodash/isEmpty';
import PageSection from '../../../components/PageSection/PageSection';
import { createOverallConfig, createOvertimeConfig } from './chart-configs';
import ChartCardsRow from '../../../components/ChartCardsRow/ChartCardsRow';
import { joinTrackDelimiter } from '../../../../utils/string/string';
import { TRACK_IDS } from '../../../../constants/tracking';
import { getExcelDataFromPie } from '../../../../utils/chart';
import { Link } from '../../../../components/Link/Link';

const LOCALIZATION_PREFIX = 'pages.seller.sections.customer_sentiment';

const CustomerSentiment = ({ isLoading, data, error }) => {
  const { t, tAbs } = useLocale(LOCALIZATION_PREFIX);
  const { getEmptyStateDetails } = useEmptyState(LOCALIZATION_PREFIX);

  const title = t('title');
  const subtitle = (
    <>
      {t('subtitle')}
      <Link href="https://go.microsoft.com/fwlink/?linkid=2101138" target="_blank">{tAbs('common.learn_more')}</Link>
    </>
  );

  const overallCustomerSentiment = get(data, 'overallCustomerSentiment');
  const customerSentimentOverTime = get(data, 'customerSentimentOverTime');

  const cards = [{
    trackId: joinTrackDelimiter([TRACK_IDS.COMMON.OVERALL, TRACK_IDS.COMMON.CUSTOMER_SENTIMENT]),
    header: t('cards.overall.title'),
    csvData: getExcelDataFromPie(overallCustomerSentiment),
    getChartConfig: () => createOverallConfig(overallCustomerSentiment, tAbs),
    emptyStateDetails: !isLoading && isEmpty(get(overallCustomerSentiment, 'series')) && getEmptyStateDetails('cards.overall'),
    hasError: get(error, 'overallCustomerSentiment'),
  }, {
    trackId: joinTrackDelimiter([TRACK_IDS.COMMON.CUSTOMER_SENTIMENT, TRACK_IDS.COMMON.OVER_TIME]),
    header: t('cards.time.title'),
    csvData: customerSentimentOverTime,
    getChartConfig: () => createOvertimeConfig(customerSentimentOverTime, tAbs),
    emptyStateDetails: !isLoading && isEmpty(customerSentimentOverTime) && getEmptyStateDetails('cards.time'),
    hasError: get(error, 'customerSentimentOverTime'),
  }];

  return (
    <PageSection title={title} subtitle={subtitle} isLoading={isLoading}>
      <ChartCardsRow isLoading={isLoading} cards={cards} />
    </PageSection>
  );
};
export default CustomerSentiment;
