// @flow

import Axios from 'axios';
import qs from 'query-string';
import * as uuid from 'uuid';
import merge from 'lodash/merge';
import camelCase from 'lodash/camelCase';
import { blobToBase64 } from '../../utils/string/string';
import { CLIENT_ACTIVITY_ID, ORG_ID_HEADER_NAME, TELEMETRY_EVENTS_API_PATH } from '../../constants/network';
import { EMPTY_GUID } from '../../constants/api';
import { dayJS } from '../../utils/date/date';

let axios;
let cacheService;
const pending = {}; // a list of pending Promises waiting to be resolved
const JSON_KEY_REGEX = /".[a-z0-9]*":/ig; // starts with (") and then any alphanumeric, and ends with (":) -> example: ("1te2sT3":)
const CI_DOMAIN = 'sales.ai.dynamics.com';

/* eslint-disable */
let callbacks = { // should be implemented by consumer, all callbacks are being passed with the "options" param.
  getBaseUrl: (options) => '',
  getToken: async (options) => '',
  getCommonParams: (options) => ({}),
  getCommonHeaders: (options) => ({}),
  trackReqError: async (activityId, errObj, options) => { }
};
/* eslint-enable */

const toCamelCaseData = (data, options, baseURL) => { // TODO: Remove when SF -> Node migration is complete
  const domain = baseURL || options.baseUrl || '';
  if (!domain.includes(CI_DOMAIN) || options?.skipCamelCaseResponse) {
    return data;
  }
  try {
    const stringifiedData = JSON.stringify(data);
    return JSON.parse(stringifiedData.replace(JSON_KEY_REGEX, (match) => `"${camelCase(match)}":`));
  } catch (e) {
    return data;
  }
};

const initTrackMsg = (method, baseUrl, path, params) => ({
  method,
  baseUrl,
  path,
  params,
  status: undefined,
  timeInMs: 0,
});

const init = (axiosOptions, callbacksObj, cacheServiceObj) => {
  axios = Axios.create(merge({
    timeout: 60000,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'bracket' })
  }, axiosOptions));

  callbacks = merge(callbacks, callbacksObj);
  cacheService = cacheServiceObj;
  return axios;
};

const initParams = (params) => {
  const p = params || {};
  return {
    path: p.path || '',
    queryParams: p.queryParams || {},
    body: p.body || {},
    headers: p.headers || {},
    options: p.options || { cacheOptions: {} },
    cancelToken: p.cancelToken
  };
};

const getHeaders = async (headers = {}, options = {}) => {
  const token = await callbacks.getToken(options);
  // $FlowFixMe
  const commonHeaders = callbacks.getCommonHeaders(options);
  if (!commonHeaders[ORG_ID_HEADER_NAME]) {
    commonHeaders[ORG_ID_HEADER_NAME] = EMPTY_GUID;
  }

  return {
    Authorization: token ? `Bearer ${token}` : '',
    [CLIENT_ACTIVITY_ID]: uuid.v4(),
    // $FlowFixMe
    ...commonHeaders,
    ...headers
  };
};

// $FlowFixMe
const getQueryParams = (queryParams = {}, options = {}) => ({ ...callbacks.getCommonParams(options), ...queryParams });

const transformResponse = (response, options, baseURL) => ({
  data: toCamelCaseData(response.data, options, baseURL),
  headers: response.headers,
  status: response.status
});

const transformGetResponse = async (response, options, baseURL) => ({
  data: (options).responseType === 'blob' ? await blobToBase64(response.data) : toCamelCaseData(response.data, options, baseURL),
  headers: response.headers,
  status: response.status
});

const GET = async (params) => {
  const { path, queryParams, headers, options, cancelToken } = initParams(params);

  const baseURL = callbacks.getBaseUrl(options);
  const mergedQueryParams = getQueryParams(queryParams, options);
  const key = JSON.stringify([baseURL, path, mergedQueryParams]);

  let config = {};

  if (!pending[key]) {
    config = {
      baseURL,
      params: mergedQueryParams,
      headers: await getHeaders(headers, options),
      responseType: (options).responseType,
      cancelToken,
      isTokenOptional: options?.isTokenOptional
    };
  }

  if (!pending[key]) {
    // store in cache only when Promise resolved
    if (cacheService) {
      const cacheHit = cacheService.get(key, (options).cacheOptions);

      if (cacheHit) {
        return {
          data: toCamelCaseData(cacheHit.data, options, baseURL),
          headers: cacheHit.headers
        };
      }
    }

    const startTime = dayJS();
    const trackMessage = initTrackMsg('GET', baseURL, path, config.params);

    pending[key] = axios.get(path, config); // store Promise
    try {
      const response = await pending[key];

      delete pending[key]; // remove fulfilled Promise
      if (!(options).ignoreJsonContentType && !response.headers['content-type'].includes('application/json')) throw new Error('content-type is missing in response headers');

      const result = await transformGetResponse(response, options, baseURL);
      if (cacheService) { cacheService.set(key, result, (options).cacheOptions); }
      callbacks.trackReqSuccess(config.headers[CLIENT_ACTIVITY_ID], { ...trackMessage, status: response?.status, timeInMs: dayJS().diff(startTime) }, options);

      return result;
    } catch (error) {
      delete pending[key];
      callbacks.trackReqFailure(config.headers[CLIENT_ACTIVITY_ID], { ...trackMessage, status: error?.response?.status, timeInMs: dayJS().diff(startTime), error }, options);
      throw error;
    }
  }
  const response = await pending[key]; // promise still pending
  const result = await transformGetResponse(response, options, baseURL);
  return result;
};

const POST = async (params) => {
  const { path, body, queryParams, headers, options } = initParams(params);
  const baseURL = callbacks.getBaseUrl(options);
  const config = {
    baseURL,
    headers: await getHeaders(headers, options),
    params: getQueryParams(queryParams, options),
    isTokenOptional: options?.isTokenOptional
  };
  const startTime = dayJS();
  const trackMessage = initTrackMsg('POST', baseURL, path, config.params);

  try {
    const response = await axios.post(path, (options).shouldNotStringify ? body : JSON.stringify(body), config);
    if (path !== TELEMETRY_EVENTS_API_PATH) {
      callbacks.trackReqSuccess(config.headers[CLIENT_ACTIVITY_ID], { ...trackMessage, status: response?.status, timeInMs: dayJS().diff(startTime) }, options);
    }

    return transformResponse(response, options, config.baseURL);
  } catch (error) {
    if (path !== TELEMETRY_EVENTS_API_PATH) {
      callbacks.trackReqFailure(config.headers[CLIENT_ACTIVITY_ID], { ...trackMessage, status: error?.response?.status, timeInMs: dayJS().diff(startTime), error }, options);
    }
    throw error;
  }
};

const PUT = async (params) => {
  const { path, body, queryParams, headers, options } = initParams(params);
  const baseURL = callbacks.getBaseUrl(options);
  const config = {
    baseURL,
    headers: await getHeaders(headers, options),
    params: getQueryParams(queryParams, options),
    isTokenOptional: options?.isTokenOptional
  };
  const startTime = dayJS();
  const trackMessage = initTrackMsg('PUT', baseURL, path, config.params);

  try {
    const response = await axios.put(path, (options).shouldNotStringify ? body : JSON.stringify(body), config);
    callbacks.trackReqSuccess(config.headers[CLIENT_ACTIVITY_ID], { ...trackMessage, status: response?.status, timeInMs: dayJS().diff(startTime) }, options);

    return transformResponse(response, options, config.baseURL);
  } catch (error) {
    callbacks.trackReqFailure(config.headers[CLIENT_ACTIVITY_ID], { ...trackMessage, status: error?.response?.status, timeInMs: dayJS().diff(startTime), error }, options);
    throw error;
  }
};

const PATCH = async (params) => {
  const { path, body, queryParams, headers, options } = initParams(params);
  const baseURL = callbacks.getBaseUrl(options);
  const config = {
    baseURL,
    headers: await getHeaders(headers, options),
    params: getQueryParams(queryParams, options),
    isTokenOptional: options?.isTokenOptional
  };
  const startTime = dayJS();
  const trackMessage = initTrackMsg('PATCH', baseURL, path, config.params);

  try {
    const response = await axios.patch(path, (options).shouldNotStringify ? body : JSON.stringify(body), config);
    callbacks.trackReqSuccess(config.headers[CLIENT_ACTIVITY_ID], { ...trackMessage, status: response?.status, timeInMs: dayJS().diff(startTime) }, options);

    return transformResponse(response, options, config.baseURL);
  } catch (error) {
    callbacks.trackReqFailure(config.headers[CLIENT_ACTIVITY_ID], { ...trackMessage, status: error?.response?.status, timeInMs: dayJS().diff(startTime), error }, options);
    throw error;
  }
};

const DELETE = async (params) => {
  const { path, body, queryParams, headers, options } = initParams(params);
  const baseURL = callbacks.getBaseUrl(options);
  const config = {
    baseURL,
    headers: await getHeaders(headers, options),
    params: getQueryParams(queryParams, options),
  };
  const startTime = dayJS();
  const trackMessage = initTrackMsg('DELETE', baseURL, path, config.params);

  try {
    const response = await axios.request({ url: path, method: 'delete', data: (options).shouldNotStringify ? body : JSON.stringify(body), ...config });
    callbacks.trackReqSuccess(config.headers[CLIENT_ACTIVITY_ID], { ...trackMessage, status: response?.status, timeInMs: dayJS().diff(startTime) }, options);

    return transformResponse(response, options, config.baseURL);
  } catch (error) {
    callbacks.trackReqFailure(config.headers[CLIENT_ACTIVITY_ID], { ...trackMessage, status: error?.response?.status, timeInMs: dayJS().diff(startTime), error }, options);
    throw error;
  }
};

/* eslint-disable */
const cancelRequest = (source) => {
  source.cancel('requestCanceled');
};

const createCancelToken = () => axios.CancelToken.source();
/* eslint-enable */

export const serverApi = {
  init,
  GET,
  POST,
  PUT,
  PATCH,
  DELETE
};
