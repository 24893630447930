import styled from 'styled-components';
import { Link, Icon } from '@fluentui/react';
import { FlexRow } from '../../../../utils/styles/StyledUtils';
import { rtl } from '../../../../utils/styles/rtl';
import { colors } from '../../../../constants/colors';

export const CommentActions = styled(FlexRow)`
  display: none;
`;

export const ActivityItemContainer = styled.div`
  position: relative;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  
  &:hover ${CommentActions} {
    display: flex;
  }
`;

export const Name = styled.span`
  font-weight: 700;
`;

export const CommentEditorContainer = styled.div`
    padding: 0 24px;
`;

export const ActivityDescriptionContainer = styled(FlexRow)`
  justify-content: space-between;
  height: 18px;
  
`;

export const ActivityDescriptionMain = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const activityItemStyles = {
  activityContent: {
    width: '100%',
    padding: '0px 8px 0px 36px',
    color: colors.Gray160
  },
  commentText: {
    color: colors.Black,
    padding: '2px 20px'
  },
  timeStamp: {
    padding: '2px 20px',
    color: colors.Gray130,
    fontSize: '10px'
  }
};

export const Action = styled(Link)`
  &:not(:last-child) {
    ${({ isRTL }) => isRTL ? 'margin-left: 16px' : rtl`margin-right: 16px`};
  }
  font-size: 12px;
`;

export const CommentIcon = styled(Icon).attrs({
  iconName: 'Message'
})`
  ${rtl`margin-right`}: 8px;
  transform: translateY(2px);
`;
