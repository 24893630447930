import React, { useState, useEffect } from 'react';
import useLocale from 'hooks/use-locale';
import { useAppNavigation, useAppLocation } from 'hooks/use-app-navigation';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { formatMonthDayYearDateAndTime } from 'utils/date/date';
import { filtersUtils, accessibilityUtils } from 'ci-common-ui';
import { trackEvent, eventAction } from '../../../../services/telemetry-service/telemetry-service';
import { PaginatedTable } from '../../../../components/Table';
import { ownerColumn, LinkCell } from '../../../../components/Table/default-columns';
import { Container } from './StyledFilesTable';
import Filter from './components/Filter';
import StatusCell from './components/StatusCell';
import ErrorPanel from './components/ErrorPanel/ErrorPanel';
import { TRACK_IDS, TRACK_SUFFIX } from '../../../../constants/tracking';
import { routes } from '../../../routing';
import { QUERY_PARAMS } from '../../../../constants/urls';
import { joinTrackDelimiter } from '../../../../utils/string/string';

const { isEmptyOrFalsy } = filtersUtils;
const LOCALIZATION_PREFIX = 'pages.system_monitoring.files_table';

export const tableColumns = [
  {
    key: 'processingStartTime',
    sortKey: 'ProcessingStartTime',
    name: `${LOCALIZATION_PREFIX}.call_processing_date`,
    fieldName: 'processingStartTime',
    isSortable: true,
    minWidth: 140,
    maxWidth: 140,
    formatter: ({ data }) => formatMonthDayYearDateAndTime(data)
  },
  {
    key: 'clientFileName',
    name: 'common.file_name',
    fieldName: 'clientFileName',
    headerClassName: 'non-actionable-header-cell',
    minWidth: 220,
    isSortable: false,
    CellComponent: LinkCell,
    disabled: (row) => !(row.fileStatus === 100 || row.fileStatus === 90),
    onCellClick: (column, row) => {
      const { columnProps: { navigateTo, pageNumber } } = column;
      navigateTo({ params: { [QUERY_PARAMS.SCROLL_TO]: row.conversationId, [QUERY_PARAMS.PAGE_NUMBER]: pageNumber } });
      navigateTo({ path: `${routes.call.link}/${row.conversationId}`, params: { [QUERY_PARAMS.SCROLL_TO]: null, [QUERY_PARAMS.PAGE_NUMBER]: null } });
    },
  },
  {
    ...ownerColumn,
    key: 'agentFullName',
    sortKey: 'SellerName',
    name: 'common.seller',
    fieldName: 'agentFullName',
    isSortable: true,
    disabled: ({ aadId }) => isEmptyOrFalsy(aadId),
    onCellClick: (column, row) => {
      const { columnProps: { navigateTo, pageNumber } } = column;
      navigateTo({ params: { [QUERY_PARAMS.SCROLL_TO]: row.conversationId, [QUERY_PARAMS.PAGE_NUMBER]: pageNumber } });
      navigateTo({ path: `${routes.sellers.link}/${row.aadId}`, params: { [QUERY_PARAMS.SCROLL_TO]: null, [QUERY_PARAMS.PAGE_NUMBER]: null } });
    },
  },
  {
    key: 'fileStatus',
    sortKey: 'ProcessingStatus',
    name: 'common.status',
    fieldName: 'fileStatus',
    minWidth: 180,
    isSortable: true,
    CellComponent: StatusCell,
  },
];

const FilesTable = ({ filter, onFilterChange, data, isLoading, error }) => {
  const { t, tAbs, tDefault } = useLocale(LOCALIZATION_PREFIX);
  const { navigateTo } = useAppNavigation();
  const { getSearchParam } = useAppLocation();
  const [rowIndex, setRowIndex] = useState();
  const { readOnChange } = accessibilityUtils;

  const [selectedItem, setSelectedItem] = useState();

  const scrollToCallRowIfNeeded = () => {
    const scrollToConversationId = getSearchParam(QUERY_PARAMS.SCROLL_TO);
    if (scrollToConversationId && !isEmpty(data)) {
      setRowIndex(findIndex(get(data, 'listOfRelevantCallsDetails'), ({ conversationId }) => conversationId === scrollToConversationId));
      setTimeout(() => setRowIndex(null), 2000);
      navigateTo({ params: { [QUERY_PARAMS.SCROLL_TO]: null, [QUERY_PARAMS.PAGE_NUMBER]: null }, options: { shouldReplace: true } });
    }
  };
  useEffect(scrollToCallRowIfNeeded, [data]);

  const onSort = (e, { sortKey: sortBy }) => {
    const orderBy = filter.sortBy === sortBy && filter.orderBy === 'asc' ? 'desc' : 'asc';
    onFilterChange({ sortBy, orderBy, silentLoading: true });
    trackEvent({ action: eventAction.click, actionOn: joinTrackDelimiter([TRACK_IDS.FILES_TABLE, TRACK_SUFFIX.TABLE.SORT]), message: `${sortBy} ${orderBy}` });
    readOnChange(tAbs(`common.${filter.orderBy}`));
  };

  const onSelectFilter = ({ props: { itemKey: fileStatus } }) => {
    onFilterChange({ fileStatus, pageNumber: 1, silentLoading: true });
    trackEvent({ action: eventAction.click, actionOn: `${TRACK_IDS.FILES_TABLE_FILTER}`, message: fileStatus });
  };

  const onPageChanged = (pageNumber) => {
    onFilterChange({ pageNumber, silentLoading: true });
    trackEvent({ action: eventAction.click, actionOn: joinTrackDelimiter([TRACK_IDS.FILES_TABLE, TRACK_SUFFIX.TABLE.PAGING]), message: pageNumber });
  };

  const onRowClick = (row) => {
    const { fileStatus } = row;
    if (fileStatus === 0 || fileStatus === -1) {
      setSelectedItem(row);
      trackEvent({ action: eventAction.click, actionOn: joinTrackDelimiter([TRACK_IDS.FILES_TABLE, TRACK_SUFFIX.TABLE.ROW]), message: row.errorCode });
    }
  };

  const closePanel = () => {
    setSelectedItem(null);
  };

  const columns = tableColumns.map((c) => ({
    ...c,
    onCellClick: c.onCellClick,
    onColumnClick: c.isSortable ? onSort : undefined,
    isSorted: c.sortKey === filter.sortBy,
    isSortedDescending: filter.orderBy === 'desc',
    columnProps: {
      navigateTo,
      pageNumber: filter.pageNumber
    }
  }));

  return (
    <Container>
      <Filter selectedFilter={filter.fileStatus} totals={get(data, 'totals')} error={error} onSelectFilter={onSelectFilter} />
      <PaginatedTable
        items={get(data, 'listOfRelevantCallsDetails')}
        columns={columns}
        isLoading={isLoading}
        error={error && t('error')}
        onRowClick={onRowClick}
        currentPage={filter.pageNumber}
        totalPages={get(data, 'pagesCount')}
        isErrorState={error}
        cardName={t('card_name')}
        onPageChanged={onPageChanged}
        emptyStateDetails={!isLoading && isEmpty(get(data, 'listOfRelevantCallsDetails'))}
        ariaLabel={tDefault('grid_aria_label')}
        initialFocusedIndex={rowIndex}
      />
      <ErrorPanel item={selectedItem} closePanel={closePanel} />
    </Container>
  );
};
export default FilesTable;
