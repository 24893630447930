import styled, { css } from 'styled-components';
import { FlexRow, colors, StyledText, rtl } from 'ci-common-ui';
import { ActionButton, DatePicker, PrimaryButton, NormalPeoplePicker, CommandBarButton } from '@fluentui/react';

export const ADD_NEW_MAPPINGS_ITEMS_SPACING_DOWN = '16px';
export const ADD_NEW_MAPPINGS_ITEMS_MARGIN = rtl`margin-right: 20px`;

export const Container = styled.div`
`;

export const EmptyMessage = styled.div`
  text-align: center;
`;

export const AddNewMappingContainer = styled(FlexRow)`
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 6px;
`;

export const ExpandAddNewMapping = styled(ActionButton).attrs({
  iconProps: { iconName: 'Add' },
  ariaLabel: 'Expand add new mapping'
})`
  height: 32px;
  margin-top: 6px;
  margin-bottom: ${ADD_NEW_MAPPINGS_ITEMS_SPACING_DOWN};
`;

export const TimeLimit = styled(CommandBarButton).attrs(({ hideIcon }) => ({
  iconProps: !hideIcon && { iconName: 'Timer' },
  ariaLabel: 'Time limit'
}))`
  width: 132px;
  height: 32px;
  ${({ noMargin }) => !noMargin && css`
    ${ADD_NEW_MAPPINGS_ITEMS_MARGIN};
    margin-bottom: ${ADD_NEW_MAPPINGS_ITEMS_SPACING_DOWN};
  `};
  
  ${({ isOptionSelected }) => isOptionSelected && `background-color: ${colors.Gray30}`};
`;

export const TableContainer = styled.div`
  overflow: hidden;

  .ms-DetailsHeader {
    padding-top: calc(16px - ${ADD_NEW_MAPPINGS_ITEMS_SPACING_DOWN});
  }
`;

export const CustomDatePicker = styled(DatePicker).attrs({
  'data-is-focusable': true
})`
  height: 36px;
  line-height: 36px;
  
  input {
    height: 36px;
    line-height: 36px;
  }

  i {
    line-height: 24px;
  }

  :hover, .ms-TextField-fieldGroup:hover {
    background-color: ${colors.Gray30};
  }
`;

export const customDatePickerStyles = { icon: { color: colors.DYNPrimary } };

export const WillViewAsText = styled(StyledText)`
  ${ADD_NEW_MAPPINGS_ITEMS_MARGIN};
  padding-bottom: ${ADD_NEW_MAPPINGS_ITEMS_SPACING_DOWN};
`;

export const AddMappingButton = styled(PrimaryButton).attrs({
  ariaLabel: 'Add new mapping'
})`
  margin-bottom: ${ADD_NEW_MAPPINGS_ITEMS_SPACING_DOWN};
`;

export const StyledPicker = styled(NormalPeoplePicker)`
  width: 252px;
  padding-bottom: ${ADD_NEW_MAPPINGS_ITEMS_SPACING_DOWN};
  ${ADD_NEW_MAPPINGS_ITEMS_MARGIN};
`;
