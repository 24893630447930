import mapKeys from 'lodash/mapKeys';
import get from 'lodash/get';
import * as uuid from 'uuid';
import qs from 'query-string';
import { errorUtils, telemetryService } from 'ci-common-ui';
import { getIsCustomerServiceMode, getIsEmbedMode, getIsDemoUser, getUserRoles, getOrgUrl, getOrgId } from 'utils/app-context/app-context';
import { settingsModalTabs } from '../../constants/modals';
import { getDefaultRoute, getRoutePageIdByPathname, routes } from '../../pages/routing';
import { buildVersion } from '../../constants/config';
import { dateToServer } from '../../utils/date/date';
import { constantQueryParamsObj, QUERY_PARAMS } from '../../constants/urls';

const { maskEmailAddresses } = telemetryService;
const { cloneErrorObj, prettifyStackTrace } = errorUtils;

export const commonEventDetails = {
  FeatureName: 'callInsights',
  AppModule: 'CI APP',
  Area: 'Call Insights',
  ClientVersion: buildVersion
};

export const getCurrentEventCommonDetails = () => ({
  EventInfo_Time: dateToServer(),
  ActivityId: uuid.v4(),
  OrgId: getOrgId(),
  Data: JSON.stringify({
    isCustomerServiceMode: getIsCustomerServiceMode(),
    isEmbedMode: getIsEmbedMode(),
    isDemoUser: getIsDemoUser(),
    userRoles: getUserRoles(),
    orgUrl: getOrgUrl(),
    fullUrl: maskEmailAddresses(window.location.href)
  })
});

const getSettingsTab = (location) => {
  if (location.search) {
    const params = qs.parse(qs.extract(location.search));
    const { settings } = mapKeys(params, (val, key) => key.toLowerCase());

    if (settings) {
      const tab = settingsModalTabs[settings.toLowerCase()];
      if (tab) {
        return tab.trackId;
      }
    }
    return undefined;
  }
  return undefined;
};

export const shouldSkipPageView = (location, page) => {
  const queryParams = qs.parse(location.search);
  const startDate = queryParams[constantQueryParamsObj.startDate];

  const hasDateTimeFilter = get(page, 'mustParams', []).some((p) => p === constantQueryParamsObj.startDate);
  return (location.pathname === '/' || hasDateTimeFilter) && !startDate;
};

export const shouldSkipQueryParamRedirect = (location) => {
  const queryParams = qs.parse(location.search);
  const settings = queryParams[QUERY_PARAMS.SETTINGS];

  return settings === '';
};

export const getPage = (location) => {
  const settingsTab = getSettingsTab(location);
  if (settingsTab) return settingsTab;
  const page = routes[getRoutePageIdByPathname(location.pathname)] || getDefaultRoute();
  return page.trackId;
};

export const getTelemetryErrorMsgObj = async (error, stackField = 'stack') => {
  try {
    const err = cloneErrorObj(error);
    const prettyStackTrace = await prettifyStackTrace({ name: error.name, message: error.message, stack: error[stackField] });
    return {
      ...err,
      name: error.name,
      [stackField]: prettyStackTrace
    };
  } catch (e) {
    return { error: `there was an issue with an Error object parsing, ${e}` };
  }
};
