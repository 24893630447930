/* eslint-disable */
import React, {useEffect} from 'react';
import createInstrumenter from './createInstrumenter';
import useLocale from 'hooks/use-locale';
import { useAppState } from 'providers/AppStateProvider';
import {getIsDemoUser, getIsEmbedMode} from 'utils/app-context/app-context';
import { TopBarContainer } from './StyledTopBar';
import { useAppNavigation } from '../../hooks/use-app-navigation';
import { MODALS } from '../../constants/modals';
import { logout } from 'services/auth-service';
import { clearAllAppRelatedCache } from '../../utils/app-context/app-context';
import {routes} from '../../pages/routing';
import { QUERY_PARAMS } from '../../constants/urls';

const TopBar = ({ shellData }) => {
  const { appState: { isSoftError }, setAppState } = useAppState();
  const { navigateTo } = useAppNavigation();

  const { t, tAbs } = useLocale('common');

  useEffect(() => {
    const shellContainer = document.querySelector(`#shell`);
    if (shellContainer && getIsEmbedMode()) {
      shellContainer.style.display = 'none';
    } else {
      const WorkloadId = 'Dynamics365SalesInsights';
      const initialNavBarData = {
        IsConsumer: false,
        Culture: 'en-US',
        WorkloadId,
        UPN: '',
        ShellBootHost: 'https://shellprod.msocdn.com',
      };
      const renderSettings = {
        top: 'shell',
        enableSearchUX: true,
        initialRenderData: initialNavBarData,
        version: '0.1',
        collapseO365Settings: true,
        workloadSettingsSubLinks: [
          {
            Id: 'settingMenu1',
            Text: t('settings'),
            Action: () => {
              navigateTo({ params: { [QUERY_PARAMS.SETTINGS]: '' } });
            },
          },
          {
            Id: 'privacyMenu',
            Text: t('privacy'),
            Action: () => {
              window.open('https://go.microsoft.com/fwlink/p/?linkid=857875', '_blank');
            },
          },
          {
            Id: 'aboutMenu',
            Text: t('about'),
            Action: () => {
              setAppState({ modalName: MODALS.About });
            },
          },
        ],
      };

      window.O365Shell.RenderAsync(
        renderSettings,
        () => {
          const data = shellData || require('./mockO365ShellData1.json'); // If in case shell data come as null, putting mock data there
          window.O365Shell.SetData(data);

          window.O365Shell.OnSignOut(() => {
            clearAllAppRelatedCache();
            logout();
          });
        },
        () => {
          window.O365Shell.Header.UpdateHeaderLink('Dynamics 365 Sales Insights', '/');
        },
        () => {
          window.O365Shell.Extensibility.AddOrUpdateCustomHeaderButton({
            id: 'Menu',
            iconFontName: String.fromCharCode(0xE700),
            ariaLabel: 'menu',
            onShow: () => {

            },
            isStateless: true,
            onHide: () => {
            },
            affordanceMenuItemText: 'menu',
          });
        },
        (er) => {
          alert(JSON.stringify(er));
        },
      );

      const instrumenter = createInstrumenter({
        perf: window.performance,
        scenario: '',
        hostAppInfo: {
          id: WorkloadId,
          version: '1.2.3',
          userRing: 'inner'
        }
      });

      window.O365Shell.Search.SetSearchBoxSettings({ instrumenter });
      window.O365Shell.Search.SetPlaceholderText(getIsDemoUser() ? tAbs('pages.search.global.demo') : tAbs('pages.search.global.non_demo'));
      window.O365Shell.Search.OnSubmitSearch((query) => {
        if (query) {
          navigateTo({ path: routes.search.link, params: { query } });
        }
      });
    }
  }, []);

  return (
    <TopBarContainer />
  );
};

export default TopBar;
