import { get, isEmpty, size } from 'lodash';
import { isLocaleRTL } from '../../../../utils/string/string';
import { isTextRTL } from '../../../../utils/dom/dom';

export const getIsRTLTranscript = (transcript) => {
  if (isEmpty(transcript)) return false;
  const locale = get(transcript, '[0].locale');
  try {
    if (size(locale) > 1) {
      return isLocaleRTL(locale);
    }

    const first = isTextRTL(transcript[0].text);
    if (transcript.length === 1) return first;

    const second = isTextRTL(transcript[1].text);
    const beforeLast = isTextRTL(transcript[transcript.length - 2].text);
    const lastItem = isTextRTL(transcript[transcript.length - 1].text);

    return first || second || beforeLast || lastItem;
  } catch (e) {
    return false;
  }
};
