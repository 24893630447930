import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { LICENSE_USAGE_RESPONSE_TYPES, CI_BASE, urlUtils, STORAGE_TYPE } from 'ci-common-ui';
import { transformApps } from '../filters/appFilters/app-filters';
import * as appRestService from '../rest-services/app-rest-service';
import { appContext, getAllAppsFromLocalStorage, initAppContext, getIsDemoUser, getIsOnboarded, setAllAppsToLocalStorage, getValidApps } from '../../utils/app-context/app-context';
import { getOrg, getOrganizationList } from '../rest-services/settings-rest-service';
import { DEV_ORGS_CHECK_THRESHOLD } from '../../constants/thresholds';
import { toUIHost } from '../../utils/url/url';

const { getClusterUrlFromStaticMapping } = urlUtils;

export const getUserSettings = async () => {
  const res = await appRestService.getUserSettings();
  return res.data;
};

export const getO365ShellData = async () => {
  const res = await appRestService.getO365ShellData();
  return res.data;
};

export const getUserLicenseStatus = async (orgId) => {
  try {
    const res = await appRestService.getUserLicenseStatus(orgId);
    return res.data;
  } catch (error) {
    return { error: error.toJSON() };
  }
};

export const getProvisioningStatus = async (orgId) => {
  try {
    const res = await appRestService.getProvisioningStatus(orgId);
    return res.data;
  } catch (error) {
    return { error: error.toJSON() };
  }
};

export const getAppData = async () => {
  const promises = [getUserSettings(), getO365ShellData()];

  const [userSettings, shellData] = await Promise.all(promises);
  return { userSettings, shellData };
};

export const getAllAppsForUserWorldWide = async () => {
  const res = await appRestService.getAllAppsForUserWorldWide();
  return transformApps(res.data);
};

export const getOrganizationCurrencySymbol = async (orgId) => {
  const res = await appRestService.getOrganizationCurrencySymbol(orgId);
  return res.data;
};

export const getUserLicense = (userLicense) => {
  const isInvalid = getIsDemoUser() || !userLicense.userHasValidLicenseAssigned;
  return isInvalid ? {
    valid: false,
    capacityUsageStatus: LICENSE_USAGE_RESPONSE_TYPES.Invalid
  } : {
    valid: true,
    capacityUsageStatus: userLicense.capacityUsageStatus || LICENSE_USAGE_RESPONSE_TYPES.Enabled
  };
};

export async function loadUserData() {
  const apps = getAllAppsFromLocalStorage();
  const defaultApp = getDefaultApp(apps);
  appContext.clusterRegion = get(defaultApp, 'clusterRegion');

  // for dev and tip we won't redirect the SAA, and also not for demo users which don't have clusterRegion
  const isProdAndNotRegionally = window.location.hostname === CI_BASE;
  const shouldRedirectToRegion = appContext.clusterRegion && isProdAndNotRegionally;
  if (shouldRedirectToRegion) {
    window.location.host = toUIHost(appContext.clusterRegion);
  }

  const [userData, orgConfig] = await Promise.all([getAppData(), appRestService.getOrg(defaultApp.orgId)]);

  if (!isEmpty(userData) && userData.shellData && userData.userSettings) {
    const clientData = JSON.parse(userData.shellData.ClientData);
    const { userSettings } = userData;
    const appData = !isEmpty(defaultApp) ? {
      appId: defaultApp.appId,
      orgId: defaultApp.orgId,
      orgUrl: defaultApp.orgUrl,
      isDefault: defaultApp.isDefault,
      clusterRegion: defaultApp.clusterRegion,
      isTrialCreated: defaultApp.isTrial,
      tenantId: defaultApp.tenantID,
      isOrgStorageTypeDvOnly: orgConfig?.storageType === STORAGE_TYPE.DataverseOnly
    } : {};

    initAppContext({
      ...appData,
      user: {
        id: clientData.UID,
        name: userData.shellData.UserDisplayName,
        username: clientData.UPN,
        ...userSettings,
        isDemo: isEmpty(getValidApps())
      }
    });
  }

  if (getIsOnboarded()) {
    const currency = await getOrganizationCurrencySymbol(defaultApp.orgId);
    appContext.currencySymbol = currency?.currencySymbol;
  }

  return {
    shellData: userData.shellData
  };
}

export async function getViewingAs() {
  const res = await appRestService.getViewingAs();
  return res.data;
}

export async function getDataForNotificationsBars() {
  let provisionStatusPromise;
  let userLicensePromise = {};
  let viewAsPromise = null;
  const apps = getAllAppsFromLocalStorage();
  const defaultApp = getDefaultApp(apps);
  if (defaultApp) {
    provisionStatusPromise = getProvisioningStatus(defaultApp.orgId);
    userLicensePromise = getUserLicenseStatus(defaultApp.orgId);
  }

  if (!getIsDemoUser()) {
    viewAsPromise = getViewingAs();
  }

  const [provisionStatus, userLicense, viewingAs] = await Promise.all([provisionStatusPromise, userLicensePromise, viewAsPromise]);

  return {
    userLicense: userLicense.error ? userLicense : getUserLicense(userLicense),
    provisionStatus,
    viewingAs
  };
}

export async function getAutoProvisionSettings(apps) {
  if (isArray(apps) && apps.length === 0) {
    const orgList = await getOrganizationList();
    if (orgList.data.length > DEV_ORGS_CHECK_THRESHOLD) return false;
    const orgConfigsPromises = await Promise.allSettled(orgList.data.map(
      (org) => {
        const clusterUri = getClusterUrlFromStaticMapping(org.url);
        return getOrg(org.id, clusterUri);
      }
    ));

    const autoOnboardingOrgs = orgConfigsPromises
      .filter((promise) => promise.status === 'fulfilled');
    if (autoOnboardingOrgs.length === 1) {
      const org = autoOnboardingOrgs[0].value.data;
      const clusterUri = getClusterUrlFromStaticMapping(org.url);
      const settings = { org: { ...org, resolvedOrgDetails: { isAdminAndNotProvisioned: false, clusterUri } } };
      return settings;
    }
  }
  return false;
}

export async function loadUserApps() {
  const apps = await getAllAppsForUserWorldWide();

  const localStorageValue = getAllAppsFromLocalStorage();
  if (!localStorageValue) {
    setAllAppsToLocalStorage(apps);
    return { shouldReload: false };
  }
  if (JSON.stringify(apps) === JSON.stringify(localStorageValue)) return { shouldReload: false };

  setAllAppsToLocalStorage(apps);
  return { shouldReload: true };
}

function getDefaultApp(apps) {
  return !isEmpty(apps) && (apps.find((app) => app.isDefault) || apps[0]);
}

export const { sendEvents } = appRestService;
