import React from 'react';
import { PersonaSize } from '@fluentui/react';
import {
  StyledTimeline, Block, Line, TimelineContainer, SideGap, Avatar,
  KeywordContainer, InnerKeyword, CommentContainer
} from './StyledTimeline';
import { CommentsIndication } from '../CommentsIndicationInTimeline/CommentsIndication';

export const calcPointOnTheTimeline = (pointInSeconds, duration, isComment) => {
  const notRoundedPoint = (pointInSeconds * 100) / (duration / 1000);
  return isComment ? notRoundedPoint : Math.round(notRoundedPoint);
};

export const Timeline = ({ isFade, customHeader, className, blocks, avatarUrl, participantDetails = {}, duration, keywordInstances = [], comments = [], commentsMoveTo, hoveredKeywordInstances = [], selectedActionItem, hoveredActionItem, isVisibleComments }) => {
  function renderBlock(block, i) {
    const isSpeakingOnBlock = Boolean(block.sentimentType);
    return <Block data-testid='block' key={i} sentimentType={block.sentimentType} opacity={isSpeakingOnBlock ? 1 : 0} />;
  }

  function renderKeywordInstance(start, i, isHovered) {
    return (
      <KeywordContainer pointStart={calcPointOnTheTimeline(start, duration)} key={`${start}-${i}`}>
        <InnerKeyword isHovered={isHovered} />
      </KeywordContainer>
    );
  }

  function renderActionItem(start, isHovered) {
    return (
      <KeywordContainer pointStart={calcPointOnTheTimeline(start, duration)}>
        <InnerKeyword isHovered={isHovered} />
      </KeywordContainer>
    );
  }

  function renderComments({ startInSeconds, count }) {
    if (!count) return null;

    return (
      <CommentContainer key={`${startInSeconds}${count}`} pointStart={calcPointOnTheTimeline(startInSeconds, duration, true)}>
        <CommentsIndication count={count} onClick={() => commentsMoveTo(startInSeconds)} />
      </CommentContainer>
    );
  }

  function renderTimelineBlocks() {
    if (!blocks) return null;

    return (
      <TimelineContainer isFade={isFade}>
        <Line />
        {blocks.map((block, i) => renderBlock(block, i))}
        {keywordInstances.map(({ startInSeconds }, i) => renderKeywordInstance(startInSeconds, i))}
        {hoveredKeywordInstances.map(({ startInSeconds }, i) => renderKeywordInstance(startInSeconds, i, true))}
        {selectedActionItem && renderActionItem(selectedActionItem.startInSeconds)}
        {hoveredActionItem && renderActionItem(hoveredActionItem.startInSeconds, true)}
        {isVisibleComments && comments.map(renderComments)}
      </TimelineContainer>
    );
  }

  const { primaryText, isUnknown, id: participantId } = participantDetails;

  return (
    <StyledTimeline className={className}>
      <SideGap>
        {customHeader || (
        <Avatar
          size={PersonaSize.size24}
          imageUrl={avatarUrl}
          text={primaryText}
          isUnknown={isUnknown}
          participantId={participantId}
        />
        )}
      </SideGap>
      {renderTimelineBlocks()}
    </StyledTimeline>
  );
};
