import styled from 'styled-components';
import { rtl, colors, FlexColumn, FlexRow, FlexRowCentered, StyledText } from 'ci-common-ui';
import { FontSizes } from '@uifabric/fluent-theme';
import { Spinner } from '@fluentui/react';

const FOOTER_HEIGHT = 80;
const CONTENT_PADDING = 32;
const TITLE_HEIGHT = 48;

export const Root = styled(FlexColumn)`
  height: 100%;
  max-width: 1312px;
`;

export const StepRoot = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${CONTENT_PADDING}px;
  padding-bottom: 0;
  overflow: hidden;
`;

export const StepContainer = styled.div`
  flex: 1;
  height: calc(100% - ${FOOTER_HEIGHT}px);
  overflow-y: auto;
`;

export const StepFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: ${FOOTER_HEIGHT}px;
  
  & > button {
    ${rtl`margin-left`}: 10px;
  }
`;

export const StepLoader = styled(Spinner).attrs(({ label: ariaLabel }) => ({
  'aria-live': 'polite',
  'aria-label': ariaLabel
}))`
  margin: auto;
  padding-bottom: 60px;
`;

export const FormWrapper = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TitleText = styled.div.attrs({ as: 'h1' })`
  font-size: 14px;
  display: block;
  font-weight: 600; 
`;

export const Title = styled(FlexRowCentered)`
  justify-content: space-between;
  font-size: ${FontSizes.size14};
  font-weight: 600;
  padding: 0 30px;
  border-bottom: 1px solid ${colors.Gray300};
  height: ${TITLE_HEIGHT}px;
`;

export const Content = styled(FlexRow)`
  height: calc(100% - ${TITLE_HEIGHT}px);
  flex: 1;
`;

export const Error = styled.div`
  color: ${colors.ScarletRed};
`;

export const SubmitNote = styled(StyledText)`
  ${rtl`margin-left: 8px`};
  color: ${colors.Gray130};
  font-size: ${FontSizes.size14};
`;
