import styled from 'styled-components';
import { ActionButton, CommandBarButton, IconButton, Link } from '@fluentui/react';
import { FlexRow } from '../../utils/styles/StyledUtils';
import { rtl } from '../../utils/styles/rtl';
import { colors } from '../../constants/colors';
import { PILL_TYPES } from '../Pill/Pill';
import { ARIA_ROLES } from '../../constants/roles-list';

const commonItemStyle = `
  margin-bottom: 10px;
`;

export const ErrorMessage = styled.div`
  font-size: 12px;
  color: ${colors.Error};
  ${rtl`padding-left: 4px;`};
`;

export const InputErrorMessage = styled(ErrorMessage)`
  ${commonItemStyle}
  line-height: 28px;
`;

export const ListErrorMessage = styled(ErrorMessage)``;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: ${({ maxHeight }) => (maxHeight ? 'auto' : 'hidden')};
  max-height: ${({ maxHeight }) => {
    if (!maxHeight) return 'none';
    if (typeof maxHeight === 'string' && maxHeight.includes('px')) return maxHeight;
    return `${maxHeight}px`;
  }};
`;

export const Input = styled.input`
  background-color: ${colors.AliceBlue};
  height: 28px;
  border-radius: 2px;
  align-items: center;
  justify-content: space-between;
  border: none;
  max-width: 140px;
  outline: 0;
  ${commonItemStyle}
  ${rtl`
  margin-right: 10px;
  padding: 0px 0px 0px 8px;
`};
`;

export const AddButton = styled(CommandBarButton)`
  border-radius: 2px;
  background-color: ${colors.AliceBlue};
  height: 28px;
  ${commonItemStyle}

  .ms-Button-icon {
    transform: ${({ shouldShowOnlyAddIcon }) =>
      `translate(${shouldShowOnlyAddIcon ? '-4px' : '0px'}, 0.15px);`}
    font-size: ${({ shouldShowOnlyAddIcon }) => (shouldShowOnlyAddIcon ? '16px' : '14px')};
  }
  :hover {
    background-color: rgba(222,236,249,1);
  }
`;

export const List = styled(FlexRow)`
  flex-wrap: wrap;
`;

export const InlineList = styled(List)`
  display: inline-flex;
`;

export const FocusableContainer = styled(Link).attrs(({ role }) => ({
  role: role || ARIA_ROLES.CONTENT_INFO,
}))`
  &&:hover,
  :focus,
  :active {
    text-decoration: none;
  }
`;

export const StyledTag = styled(ActionButton).attrs({ type: PILL_TYPES.TAG })`
  user-select: text;
  display: flex;
  flex-direction: row;
  background-color: ${colors.AliceBlue};
  color: ${colors.Gray160};
  height: fit-content;
  min-height: 28px;
  border-radius: 2px;
  border: 0;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  overflow-x: hidden;
  padding: 0;
  ${rtl`margin-right: 10px;`};
  ${commonItemStyle}

  button {
    color: inherit;
  }
  .ms-Icon {
    font-size: 12px;
  }

  :hover {
    color: ${colors.Black};
    cursor: default;
  }
`;

export const Text = styled.span`
  font-size: 14px;
  transform: translateY(-1px);
  margin-right: ${({ isReadOnly }) => (isReadOnly ? '10px' : '6px')};
  margin-left: ${({ isReadOnly }) => (isReadOnly ? '10px' : '8px')};
`;

export const RemoveButton = styled(IconButton)`
  width: 20px;
  padding: 2px 7px;

  :hover {
    background-color: rgba(222, 236, 249, 1);
  }
  :disabled {
    background-color: ${colors.AliceBlue};
  }
`;
