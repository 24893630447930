import styled from 'styled-components';
import { FontSizes } from '@uifabric/fluent-theme';
import { CheckboxField } from 'ci-common-ui';

export const Header = styled.div`
  font-size: ${FontSizes.size16};
  margin-bottom: 20px;
`;

export const Text = styled.div` 
  margin-bottom: 20px;
`;

export const CheckboxFieldStyled = styled(CheckboxField)`
  .ms-Checkbox-checkbox {
    margin-bottom: 20px;
  }
`;

export const CheckboxContainer = styled.div``;
