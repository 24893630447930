import React from 'react';
import isNil from 'lodash/isNil';
import { TrendContainer, Percentage, TrendIcon } from './StyledTrend';
import { formatFloatPercentage } from '../../utils/numeric/numeric';

export const Trend = ({ value, emptyText = 'N/A' }) => {
  if (isNil(value)) {
    return (<span>{emptyText}</span>);
  }

  return (
    <TrendContainer value={value}>
      {value !== 0 ? <TrendIcon value={value} /> : null}
      <Percentage>
        {formatFloatPercentage(value)}
      </Percentage>
    </TrendContainer>
  );
};
