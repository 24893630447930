import styled from 'styled-components';
import { ExportToCSVButtonContainer } from 'ci-common-ui';

export const chartPivotStyles = {
  itemContainer:
    {
      height: '288px',
      selectors: {
        '&>div': {
          height: '100%'
        }
      }
    }
};

export const PivotChartContainer = styled.div`
  position: relative;
  ${ExportToCSVButtonContainer} {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-36px);
  }
`;
