import styled from 'styled-components';
import { FlexRowCentered } from '../../utils/styles/StyledUtils';

export const SpinnerContainer = styled(FlexRowCentered)`
  margin: 0;
  height: 100vh;
  justify-content: center;
  position: relative;
`;

export const spinnerStyles = {
  circle: {
    width: '60px',
    height: '60px',
    borderWidth: '3px'
  }
};
