import React, { useEffect, useState } from 'react';
import { getNPSRenderEndpoint } from 'network/data-services/nps-service';
import WindowCommunicationService from 'services/window-communication-service';
import { trackEvent, eventAction } from '../../services/telemetry-service/telemetry-service';
import Modal from '../Modal/Modal';
import { MODAL_STYLES, Loader, StyledIframe } from './StyledNPSWindow';
import { TRACK_IDS } from '../../constants/tracking';

const NPS_CHECK_DELAY = 1000;
const EVENT_TYPE_NPS_CLOSE = 'closeNpsDialog';
const EVENT_TYPE_NPS_LOADED = 'npsLoaded';

const NPSWindow = () => {
  const [iframeSrc, setIframeSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    WindowCommunicationService.addEventListener(EVENT_TYPE_NPS_CLOSE, onClose);
    WindowCommunicationService.addEventListener(
      EVENT_TYPE_NPS_LOADED,
      () => setIsLoading(false)
    );

    determineModalVisibility();
    return () => {
      WindowCommunicationService.removeEventListener(EVENT_TYPE_NPS_CLOSE);
      WindowCommunicationService.removeEventListener(EVENT_TYPE_NPS_LOADED);
    };
  }, []);

  const onClose = () => {
    trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.NPS_FEEDBACK_CLOSED });
    setIframeSrc(null);
  };

  const determineModalVisibility = () => setTimeout(
    async () => {
      const renderEndpoint = await getNPSRenderEndpoint();
      if (renderEndpoint) {
        trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.NPS_FEEDBACK_SHOWED });
        setIframeSrc(renderEndpoint);
      }
    },
    NPS_CHECK_DELAY
  );

  if (iframeSrc) {
    return (
      <Modal styles={MODAL_STYLES}>
        { isLoading && Loader }
        <StyledIframe
          isLoading={isLoading}
          title="Feedback"
          src={iframeSrc}
          frameBorder="0"
          allowFullScreen
          width="100%"
          height="100%"
        />
      </Modal>
    );
  }
  return null;
};
export default NPSWindow;
