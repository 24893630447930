import styled from 'styled-components';
import { FlexRow, FlexRowCentered } from 'ci-common-ui';

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  & .carousel-container {
    padding: 4px 0;
  }
`;
export const CarouselHeader = styled(FlexRowCentered)`
  justify-content: flex-end;
  margin-bottom: 10px;
`;

export const CarouselControls = styled(FlexRowCentered)`
  opacity: ${({ hide }) => (hide ? 0 : 1)};
`;

export const CarouselLabel = styled.p``;

export const CarouselBody = styled(FlexRow)`
  padding-bottom: 4px;
  overflow: hidden;
`;

export const CarouselItem = styled.div``;
