import React from 'react';
import useLocale from 'hooks/use-locale';
import { DeleteCell } from '../../../../../../components/Table/default-columns';

const DeleteSellerCell = ({ data, deleted, deleteAction }) => {
  const { t, tAbs, tAbsDefault } = useLocale('pages.settings.your_team.delete_seller');

  return (
    <DeleteCell
      id={data}
      deleted={deleted}
      btnText={t('delete_data')}
      ariaLabel={tAbsDefault('common.deleted_seller_data')}
      successText={tAbs('common.deleted')}
      onRowHoverClassName="show-on-hover"
      deleteAction={deleteAction}
      confirmProps={{
        dialogTitle: t('dialog_title'),
        dialogSubtext: tAbs('common.delete_data_message'),
        okText: tAbs('common.delete'),
        cancelText: tAbs('common.cancel'),
      }}
    />
  );
};

export default DeleteSellerCell;
