import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Table } from '../../../../../../../components/Table';
import useLocale from '../../../../../../../hooks/use-locale';
import { EmptyMessage, TableContainer as Container } from '../StyledViewAs';
import { getColumns, transformMappingsToTableFormat } from '../utils';
import { ViewAsPersonaCell } from './ViewAsPersonaCell';
import { UntilCell } from './UntilCell';
import { RemoveMappingCell } from './RemoveMappingCell';

export const MappingsTable = ({ allMappings, onInputChange, isLoading }) => {
  const { t } = useLocale('pages.settings.your_team.view_as');

  return (
    <Container>
      <Table
        items={transformMappingsToTableFormat(allMappings)}
        columns={getColumns(onInputChange, allMappings, { ViewAsPersonaCell, UntilCell, RemoveMappingCell })}
        isLoading={isLoading}
      />
      {isEmpty(allMappings) && !isLoading && (
      <EmptyMessage>{t('empty_table')}</EmptyMessage>)}
    </Container>
  );
};
