import React from 'react';
import useLocale from 'hooks/use-locale';
import { Table } from 'ci-common-ui';

const TableWrapper = ({ columns, ...rest }) => {
  const { tAbs } = useLocale();
  const translatedColumns = rest?.isHeaderVisible !== false ? columns.map((c) => ({ ...c, name: tAbs(c.name, c.nameProps) })) : columns;

  return (
    <Table
      columns={translatedColumns}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};

export default TableWrapper;
