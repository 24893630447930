import errorPNG from './images/error.png';
import provisionPNG from './images/provision.png';
import pageErrorSvg from './images/page-error-state.svg';
import activityIcon from './images/crm-entities/activities.svg';
import accountIcon from './images/crm-entities/accounts.svg';
import contactIcon from './images/crm-entities/contacts.svg';
import leadIcon from './images/crm-entities/leads.svg';
import opportunityIcon from './images/crm-entities/opportunities.svg';
import teamsIcon from './images/telephony-providers/teams.svg';
import ciscoIcon from './images/telephony-providers/cisco.svg';
import niceIcon from './images/telephony-providers/nice.svg';
import twilioIcon from './images/telephony-providers/twilio.svg';
import dotsIcon from './images/dots.svg';

export const assets = {
  images: {
    dotsIcon,
    errorPNG,
    provisionPNG,
    pageErrorSvg,
    activityIcon,
    accountIcon,
    contactIcon,
    leadIcon,
    opportunityIcon,
    teamsIcon,
    ciscoIcon,
    niceIcon,
    twilioIcon
  }
};
