export const readOnChange = (text, priority) => {
  const el = document.createElement('div');
  const id = `speak-${Date.now()}`;
  el.setAttribute('id', id);
  el.setAttribute('aria-live', priority || 'polite');
  positionElementOutOfViewport(el);
  document.body.appendChild(el);

  window.setTimeout(() => {
    document.getElementById(id).innerHTML = text;
  }, 100);

  window.setTimeout(() => {
    document.body.removeChild(document.getElementById(id));
  }, 1000);
};

export const positionElementOutOfViewport = (element) => {
  if (!element) {
    return;
  }
  const { style } = element;
  style.position = 'absolute';
  style.width = '1px';
  style.height = '1px';
  style.padding = '0';
  style.margin = '-1px';
  style.overflow = 'hidden';
  style.clip = 'rect(0, 0, 0, 0)';
  style.whiteSpace = 'nowrap'; /* added line */
  style.border = '0';
  style.zIndex = -99999;
  style.top = '-1000px';
};
