/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { get, map, filter } from 'lodash';
import { useParams } from 'react-router';
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';
import { getToken } from 'services/auth-service';
import { getWsUrl, getNewApiServerUrl } from 'utils/url/url';
import { useCallProcessing, AppSpinner, AppError, useFetch, useFetchNow, callService, callFilters, CallPage, StyledCallPage, CALL_PROCESSING_MODE_TYPES, DYNAMICS_INTERNAL_MEETING_LEARN_MORE_LINK } from 'ci-common-ui';
import DataCacheService from 'services/DataCacheService';
import useLocale from 'hooks/use-locale';
import { trackEvent, trackError, eventAction } from '../../services/telemetry-service/telemetry-service';
import { getIsCustomerServiceMode, appContext, getIsDemoUser, getIsEmbedMode } from '../../utils/app-context/app-context';
import BackButton from '../../components/BackButton/BackButton';
import { TRACK_IDS } from '../../constants/tracking';
import { useAppNavigation } from '../../hooks/use-app-navigation';
import EditableCrmEntitiesLinks from './components/EditableCrmEntitiesLinks/EditableCrmEntitiesLinks';
import { useAppState } from '../../providers/AppStateProvider';
import PageError from '../components/PageError/PageError';
import { usePageLoadTime } from '../../hooks/use-page-load-time';

const { getCall, updateComment, addComment, deleteComment, getOrg } = callService;
const { transformCall } = callFilters;
const { CallPageContainer } = StyledCallPage;

export const Container = styled.div`
  height:100%;
  overflow: hidden;
  ${CallPageContainer} {
    padding-bottom: 8px;
  }
`;

export const getCallStreamUrl = (baseUrl, conversationId, orgId, token) => `${baseUrl}/user/v1.0-preview/conversations/${conversationId}/actions/get-audio-stream?orgId=${orgId}&Authorization=Bearer ${token}`;
export const getCallProcessingUrl = (baseUrl, callId, orgId, token) => `${baseUrl}/realtime?conversationId=${callId}&orgId=${orgId}&accessToken=${token}`;

const getFilteredData = (callData) => transformCall({ callData });

const CallPageWrapper = () => {
  const { id } = useParams();
  const { t, tAbs, tDefault, localeId } = useLocale('pages.call');
  const { setAppState } = useAppState();
  const [{ isPartial, playerCurrentTime: initialCurrentTime }] = useQueryParams({ isPartial: StringParam, playerCurrentTime: NumberParam });

  const { navigateBack } = useAppNavigation();
  const {
    data: callData,
    error: callError,
    isLoading: isCallLoading,
    fetchData,
  } = useFetch((forceFetch) => getCall(id, { orgId: appContext.orgId, isDemo: getIsDemoUser() }, forceFetch));
  const {
    data: orgData,
    isLoading: isOrgDataLoading,
  } = useFetchNow(() => getOrg({ bodyParams: { orgId: appContext.orgId }, isGlobalSettings: false }));
  const [comments, setComments] = useState({});
  const [callStreamUrl, setCallStreamUrl] = useState(null);
  const [callWSUrl, setCallWSUrl] = useState(null);
  const [isLoadAudioError, setLoadAudioError] = useState(false);
  const { lastCallSummaryMessage, error: wsError } = useCallProcessing(callWSUrl, isPartial, CALL_PROCESSING_MODE_TYPES.CALL_SUMMARY);
  const data = isPartial ? (lastCallSummaryMessage && getFilteredData(lastCallSummaryMessage.data)) : callData;
  const isPartialLoading = !lastCallSummaryMessage || !lastCallSummaryMessage.isCompleted;
  const isLoading = isPartial ? isPartialLoading : isCallLoading || isOrgDataLoading;
  const error = isPartial ? wsError : callError;

  usePageLoadTime(TRACK_IDS.SOURCES.CALL_PAGE, !isLoading);

  const onUpdateComment = async (fragmentId, commentId, commentText) => {
    const updatedComment = await updateComment(get(callData, 'conversationId'), fragmentId, commentId, commentText);
    setComments({
      ...comments,
      [fragmentId]: map(comments[fragmentId], (comment) => get(comment, 'id') === commentId ? updatedComment : comment)
    });
    DataCacheService.removeAll();
  };

  const onAddComment = async (fragmentId, commentText) => {
    const comment = await addComment(get(callData, 'conversationId'), fragmentId, commentText);
    setComments({ ...comments, [fragmentId]: comments[fragmentId] ? [...comments[fragmentId], (comment)] : [comment] });
    DataCacheService.removeAll();
  };

  const onDeleteComment = async (fragmentId, commentId) => {
    await deleteComment(get(callData, 'conversationId'), fragmentId, commentId);
    setComments({ ...comments, [fragmentId]: filter(comments[fragmentId], (comment) => comment.id !== commentId) });
    DataCacheService.removeAll();
  };

  const onBackButtonClick = () => {
    trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.COMMON.BACK_BUTTON });
    navigateBack();
  };

  const setLeavingPagePromptMessage = (isEnabled, message) => {
    setAppState({ leavingPagePromptMessage: isEnabled ? message : null });
  };

  useEffect(() => {
    if (!isPartial) {
      if (callData) {
        setComments(get(callData, 'comments'));
      } else {
        fetchData(true);
      }
    }
  }, [callData]);

  useEffect(() => {
    if (get(callData, 'timelineData.isPlaybackSupported') && (!callStreamUrl || isLoadAudioError)) {
      const loadCallStreamUrl = async () => {
        const token = await getToken();
        const conversationId = get(callData, 'conversationId');
        const url = conversationId && getCallStreamUrl(getNewApiServerUrl(), conversationId, appContext.orgId, token);
        setCallStreamUrl(url);
      };
      loadCallStreamUrl();
    }
  }, [isLoadAudioError, callData]);

  useEffect(() => {
    if (isPartial) {
      const loadCallWSUrl = async () => {
        const token = await getToken();
        const url = getCallProcessingUrl(getWsUrl(), id, appContext.orgId, token);
        setCallWSUrl(url);
      };
      loadCallWSUrl();
    }
  }, []);

  const isAuthError = get(error, 'response.status') === 403;

  if (error && !isAuthError) {
    return <PageError />;
  }

  if ((!isPartial && isLoading && !data) || !data) {
    return <AppSpinner />;
  }

  const { isNotPermissions, isReadOnly } = data;

  if (isNotPermissions || isAuthError) {
    return (
      <AppError type="noPermission" title={t('not_permissions')} subtitle={t('not_permissions_subtitle')} />
    );
  }
  const isEditable = !getIsDemoUser() && !isReadOnly;

  return (
    <Container>
      <CallPage
        id={id}
        data={data}
        isEditable={isEditable}
        comments={comments}
        isLoading={isPartial ? isLoading : (!data && isLoading)}
        callStreamUrl={callStreamUrl}
        initialCurrentTime={initialCurrentTime}
        shouldHideMainHeader={getIsEmbedMode() && !getIsCustomerServiceMode()}
        setLoadAudioError={setLoadAudioError}
        onAddComment={onAddComment}
        onDeleteComment={onDeleteComment}
        onUpdateComment={onUpdateComment}
        isExecutiveSummaryEnabled={!getIsDemoUser() && (orgData?.executiveSummaryEnabled)}
        setLeavingPagePromptMessage={setLeavingPagePromptMessage}
        t={t}
        tAbs={tAbs}
        tDefault={tDefault}
        trackEvent={trackEvent}
        trackError={trackError}
        orgUrl={appContext.orgUrl}
        orgId={appContext.orgId}
        userId={appContext.user.id}
        userName={appContext.user.username}
        tenantId={appContext.tenantId}
        orgLCID={localeId}
        clearCache={() => DataCacheService.removeAll()}
        highlightsInternalMeetingLearnMoreLink={DYNAMICS_INTERNAL_MEETING_LEARN_MORE_LINK}
        tagsProps={{
          hideTagsComponent: false,
          onTagsChange: () => DataCacheService.removeAll(), // In order to see the updated tags in all calls tables
        }}
        ExtraComponents={{
          BackButton: <BackButton text={tAbs('common.back')} onClick={onBackButtonClick} />,
          EditableCrmEntitiesLinks: !getIsEmbedMode() && <EditableCrmEntitiesLinks isEditable={isEditable} conversationId={id} data={get(data, 'summary.crm')} callSubject={get(data, 'summary.subject')} />,
        }}
      />
    </Container>
  );
};

export default CallPageWrapper;
