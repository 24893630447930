import { formatRound } from './numeric/numeric';

export const addEmptyDataToStartAndEnd = (data, categoryField) => [
  { [categoryField]: ' ' },
  ...data,
  { [categoryField]: ' ' },
];

export const multiplyBy100 = (data) => data.map((item) => Object.keys(item).reduce(
  (res, key) => ({ ...res, [key]: typeof item[key] === 'number' ? formatRound(item[key] * 100) : item[key] }),
  {},
));

export const multiplyPieBy100 = (data) => Object.entries(data[0]).map(([name, y]) => ({ name, y: y * 100 }));
