import { useRef } from 'react';

export const useVar = (value) => {
  const ref = useRef(value);

  return {
    set: (val) => { ref.current = val; },
    get: () => ref.current
  };
};
