import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Trans } from 'ci-common-ui';
import useLocale from 'hooks/use-locale';
import useEmptyState from 'utils/empty-state/use-empty-state';
import PageSection from '../../../../components/PageSection/PageSection';
import { createOvertimeConfig, createPieConfig } from './chart-configs';
import ChartCardsRow from '../../../../components/ChartCardsRow/ChartCardsRow';
import { joinTrackDelimiter } from '../../../../../utils/string/string';
import { TRACK_IDS } from '../../../../../constants/tracking';

const LOCALIZATION_PREFIX = 'pages.insight.customer_sentiment';

const CustomerSentiment = ({ isLoading, data, error }) => {
  const { t, tAbs, appModeAwareTerms } = useLocale(LOCALIZATION_PREFIX);
  const { getEmptyStateDetails } = useEmptyState(LOCALIZATION_PREFIX);

  const subtitle = <Trans i18nKey={`${LOCALIZATION_PREFIX}.subtitle`}>{{ _department: appModeAwareTerms._department }}<br />second line</Trans>;
  const callsWithHigherNegativeSentimentAverageSentiments = get(data, 'callsWithHigherNegativeSentimentAverageSentiments');
  const averagedSentimentsOfCallsWithHigherNegSentimentByDate = get(data, 'averagedSentimentsOfCallsWithHigherNegSentimentByDate');

  const cards = [{
    trackId: joinTrackDelimiter([TRACK_IDS.COMMON.AVERAGE, TRACK_IDS.COMMON.CUSTOMER_SENTIMENT]),
    header: t('cards.avg_sentiment.title'),
    csvData: get(callsWithHigherNegativeSentimentAverageSentiments, 'series'),
    getChartConfig: () => createPieConfig(callsWithHigherNegativeSentimentAverageSentiments, tAbs),
    hasError: get(error, 'callsWithHigherNegativeSentimentAverageSentiments'),
    emptyStateDetails: isEmpty(callsWithHigherNegativeSentimentAverageSentiments) && getEmptyStateDetails('cards.avg_sentiment')
  }, {
    trackId: joinTrackDelimiter([TRACK_IDS.COMMON.OVER_TIME, TRACK_IDS.COMMON.CUSTOMER_SENTIMENT]),
    header: t('cards.over_time.title'),
    csvData: averagedSentimentsOfCallsWithHigherNegSentimentByDate,
    getChartConfig: () => createOvertimeConfig(averagedSentimentsOfCallsWithHigherNegSentimentByDate, tAbs),
    hasError: get(error, 'averagedSentimentsOfCallsWithHigherNegSentimentByDate'),
    emptyStateDetails: isEmpty(averagedSentimentsOfCallsWithHigherNegSentimentByDate) && getEmptyStateDetails('cards.over_time')
  }];

  const title = t('title', { numOfCalls: get(callsWithHigherNegativeSentimentAverageSentiments, 'calls', '') });

  return (
    <PageSection title={title} subtitle={subtitle} isLoading={isLoading}>
      <ChartCardsRow isLoading={isLoading} cards={cards} />
    </PageSection>
  );
};
export default CustomerSentiment;
