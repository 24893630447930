import React, { useEffect } from 'react';
import { Trans } from 'ci-common-ui';
import useLocale from 'hooks/use-locale';
import { get } from 'lodash';
import { Link } from '../../../../../components/Link/Link';
import { StepRoot } from '../../../../../components/Wizard/StyledWizard';
import { Header, Text, CheckboxFieldStyled, CheckboxContainer } from './StyledTermsAndConditions';
import StepForm from '../../../../../components/Wizard/StepForm/StepForm';
import { extractTextFromHtmlTags } from '../../../../../utils/string/string';
import { componentLoadingTimeService } from '../../../../../services/component-loading-time-service/component-loading-time-service';
import { TRACK_IDS } from '../../../../../constants/tracking';

const LOCALIZATION_PREFIX = 'pages.onboarding.terms';

const TERMS_AND_CONDITIONS_URL = 'https://www.microsoft.com/licensing/product-licensing/products';
const PRIVACY_URL = 'https://go.microsoft.com/fwlink/?linkid=521839';

const TermsAndConditionsStep = ({ settings, handleSettings, next, back }) => {
  const { t, tAbs, appModeAwareTerms } = useLocale(LOCALIZATION_PREFIX);
  const isAdminAndNotProvisioned = get(settings, 'org.resolvedOrgDetails.isAdminAndNotProvisioned');

  useEffect(() => {
    componentLoadingTimeService.end(TRACK_IDS.SOURCES.ONBOARDING_MODAL);
  }, []);

  const IsEyesOnDataComponent = () => (
    <CheckboxContainer>
      <Trans i18nKey={`${LOCALIZATION_PREFIX}.eyes_on_data_label`} values={{ _app_name: appModeAwareTerms._app_name }}>
        text <Link href={PRIVACY_URL} target="_blank">link</Link>
      </Trans>
    </CheckboxContainer>
  );

  const IsAgreeComponent = () => (
    <CheckboxContainer>
      <Trans i18nKey={`${LOCALIZATION_PREFIX}.agree_label`}>
        text
        <Link href={TERMS_AND_CONDITIONS_URL} target="_blank">link</Link> text2 <Link href={PRIVACY_URL} target="_blank">link2</Link>
      </Trans>
    </CheckboxContainer>
  );

  const onSubmit = (value) => {
    handleSettings(value);
    next();
  };

  return (
    <StepRoot>
      <StepForm onSubmit={onSubmit} initialValues={settings} back={back}>
        <Header>{t('subtitle')}</Header>
        <Text>{t('text1')}</Text>
        <Text>{t('text2')}</Text>

        {isAdminAndNotProvisioned && (
          <CheckboxFieldStyled
            name="isEyesOnData"
            ariaLabel={extractTextFromHtmlTags(IsEyesOnDataComponent)}
            errorMessages={{ required: tAbs('common.required') }}
            label={IsEyesOnDataComponent}
          />
        )}

        <CheckboxFieldStyled
          isRequired
          name="isAgree"
          ariaLabel={extractTextFromHtmlTags(IsAgreeComponent)}
          errorMessages={{ required: tAbs('common.required') }}
          label={IsAgreeComponent}
        />
      </StepForm>
    </StepRoot>
  );
};

export default TermsAndConditionsStep;
