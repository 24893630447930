import React, { useEffect } from 'react';
import SettingsModal from 'pages/SettingsModal/SettingsModal';
import OnboardingModal from 'pages/OnboardingModal/OnboardingModal';
import AboutModal from 'pages/AboutModal/AboutModal';
import Modal from './Modal';
import { useAppLocation } from '../../hooks/use-app-navigation';
import { useAppState } from '../../providers/AppStateProvider';
import { MODALS } from '../../constants/modals';
import TrialSetupModal from '../../pages/TrialSetupModal/TrialSetupModal';
import { trialDataUploaderModalStyles, relatedOpportunityModalStyles } from '../../pages/TrialSetupModal/components/StyledTrialDataUploader';
import TrialDataUploader from '../../pages/TrialSetupModal/components/TrialDataUploader';
import RelatedOpportunityModal from '../../pages/CallPage/components/RelatedOpportunityModal/RelatedOpportunityModal';
import PageErrorBoundary from '../ErrorBoundry/PageErrorBoundary';
import { QUERY_PARAMS } from '../../constants/urls';

export const MODAL_MAPPING = {
  [MODALS.Settings]: { component: SettingsModal, options: { isBlocking: true } },
  [MODALS.Onboarding]: { component: OnboardingModal },
  [MODALS.About]: { component: AboutModal },
  [MODALS.TrialSetup]: { component: TrialSetupModal },
  [MODALS.TrialDataUploader]: { component: TrialDataUploader, styles: trialDataUploaderModalStyles },
  [MODALS.RelatedOpportunity]: { component: RelatedOpportunityModal, styles: relatedOpportunityModalStyles },
};

const ModalManager = () => {
  const { appState: { modalName }, setAppState } = useAppState();

  const { getSearchParam } = useAppLocation();
  const settingsSearchParam = getSearchParam(QUERY_PARAMS.SETTINGS);

  useEffect(() => {
    if (settingsSearchParam !== undefined) {
      setAppState({ modalName: MODALS.Settings });
    }

    if (!settingsSearchParam && modalName === MODALS.Settings) {
      setAppState({ modalName: null });
    }
  }, [settingsSearchParam]);

  if (!modalName) {
    return null;
  }

  const { component: ModalContent, options, styles } = MODAL_MAPPING[modalName];

  const closeModal = () => {
    setAppState({ modalName: null });
  };

  return (
    <Modal onDismiss={closeModal} styles={styles} options={options}>
      <PageErrorBoundary>
        <ModalContent closeModal={closeModal} />
      </PageErrorBoundary>
    </Modal>
  );
};

export default ModalManager;
