/* eslint-disable no-param-reassign */
import {
  find,
  isNil,
  get,
  slice,
  flow,
  sortBy,
  map,
  omit,
  concat,
  filter,
  flatten,
  groupBy,
  isEmpty,
  includes,
  orderBy,
  findIndex,
  size,
} from 'lodash';
import fGroupBy from 'lodash/fp/groupBy';
import fmap from 'lodash/fp/map';
import { formatNumber } from '../../../../../utils/numeric/numeric';
import {
  MEASUREMENT_UNIT,
  TIMELINE_BLOCKS_COUNT,
  PARTICIPANTS_ROLES,
} from '../../../../../constants/filters';
import { SENTIMENT_NAMES, EMPTY_GUID, recordingTargetType } from '../../../../../constants/api';
import { dateFromServer } from '../../../../../utils/date/date';
import {
  transformTags,
  transformCrm,
  getFirstNonEmptyCustomerDetails,
} from '../../../../network/filters/utils';
import { moveTopicsToTopOfKeywordsList } from '../../../utils';

export const transformCall = ({ callData, realtimeSummary, shouldHideCrmDetails = false, isVivaTopicsEnabledInCallSummary = false, realtimeActionItems = [] }) => { // realtimeActionItems is only used for WS, and should be removed once WS is returning the action items directly
  const {
    duration,
    participantsInsights,
    consentMode,
    executiveSummary,
    actionItems: globalActionItems, // action items that is not related to any participant
    generatedActionItems,
    publishers: partners,
    highlights: partnerHighlights,
  } = callData;

  const participants = participantsInsights.map(
    ({ participant, insights: { conversationStyle } }) => ({
      kpis: participant.role === PARTICIPANTS_ROLES.agent ? transformKPIs(conversationStyle) : null,
      // longest monoluge is not part of the kpis group - it belongs only to customer, and gets rendered on a different place
      longestMonologueInMs: isNil(get(conversationStyle, 'longestMonologueInMs'))
        ? 0
        : Math.round(get(conversationStyle, 'longestMonologueInMs') / 1000),
      ...participant,
    })
  );

  const agentData = find(
    participantsInsights,
    ({ participant }) => participant.role === PARTICIPANTS_ROLES.agent
  );

  const sentimentsData = participantsInsights.map(({ insights: { sentiments } }) => sentiments);
  const insightsData = participantsInsights.map(
    ({ insights, participant: { id, crmId, role } }) => ({ insights, speakerId: id, crmId, role })
  );

  const transformedTranscript = transformTranscript(get(callData, 'transcript.origin'));
  const transformedSentiments = transformSentiments(sentimentsData);
  const transcriptWithSentiments = mergeTranscriptWithSentiments(transformedTranscript, transformedSentiments);

  return {
    conversationId: callData.id,
    // supporting currentUserOnly only for past calls that were made under this type, and referring currentUserOnly as OnlyAgents
    consentMode: consentMode === 'currentUserOnly' ? recordingTargetType.OnlyAgents : consentMode,
    isInternalMeeting: callData.isInternalMeeting,
    participants,
    participantsInsights,
    summary: {
      duration,
      startDate: dateFromServer(callData.startTime),
      subject: callData.subject,
      // Should delete kpis from here once MultiParticipantsAndRelateContact feature is fully on, cause kpis will be inside each participant
      kpis: transformKPIs(agentData.insights.conversationStyle),
      tags: transformTags(callData.tags),
      // Should remove '|| get(row, 'crm.contacts')' once server will always return participants object
      crm: shouldHideCrmDetails
        ? {}
        : transformCrm({
            ...get(callData, 'crm'),
            contacts:
              getFirstNonEmptyCustomerDetails(participants, get(callData, 'crm')) ||
              get(callData, 'crm.contacts'),
          }),
    },
    executiveSummary: {
      summary: transformSummary(executiveSummary, realtimeSummary),
      actionItems: transformActionItems(
        insightsData,
        transformedTranscript,
        globalActionItems,
        generatedActionItems,
        realtimeActionItems
      ),
      partnerHighlights: transformPartnerHighlights(
        partners,
        partnerHighlights,
        transformedTranscript
      ),
      suggestions: transformSuggestions(insightsData, transformedTranscript, duration / 1000),
    },
    keywords: transformKeywords(insightsData, isVivaTopicsEnabledInCallSummary),
    transcript: {
      origin: transformedTranscript,
      english: transformTranscript(get(callData, 'transcript.english')),
    },
    timelineData: {
      agents: transformTimelineGroupByRole(
        participants,
        duration,
        transcriptWithSentiments,
        PARTICIPANTS_ROLES.agent
      ),
      customers: transformTimelineGroupByRole(
        participants,
        duration,
        transcriptWithSentiments,
        PARTICIPANTS_ROLES.customer
      ),
      isPlaybackSupported: callData.isMediaAvailable,
    },
    segmentation: callData.segments || [],
    locale: callData.locale,
    comments: transformComments(callData.comments),
    isReadOnly: includes(callData.permissions, 'read') && !includes(callData.permissions, 'write'),
    isNotPermissions: isEmpty(callData.permissions),
    areActionItemsReady: callData.areActionItemsReady,
    processingType: callData.processingType,
    recordingLink: callData.recordingLink,
    insightsProcessingStatus: callData.insightsProcessingStatus,
  };
};

export const transformSentiments = (sentimentsData) => ({
  positive: { instances: transformSentimentType(sentimentsData, SENTIMENT_NAMES.positive) },
  neutral: { instances: transformSentimentType(sentimentsData, SENTIMENT_NAMES.neutral) },
  negative: { instances: transformSentimentType(sentimentsData, SENTIMENT_NAMES.negative) },
});

const transformSentimentType = (sentimentsData, sentimentType) => {
  const instances = sortBy(
    filter(
      concat(
        ...sentimentsData.map(
          (sentiment) =>
            get(sentiment, `${sentimentType}.positions`) || get(sentiment, sentimentType)
        )
      ),
      (sentimentPosition) => Boolean(sentimentPosition)
    ),
    ({ offset }) => offset
  );

  return map(
    filter(instances, (instance) => !isEmpty(instance)),
    (instance) => ({ ...instance, ...transformInstance(instance.offset, instance.duration) })
  );
};

export const transformSuggestions = (insightsData, transcript, durationInSecond) => {
  const suggestions = flatten(
    map(
      insightsData,
      ({ speakerId, insights: { executiveSummarySuggestions } }) =>
        map(executiveSummarySuggestions, (suggestion) => ({ ...suggestion, speakerId })) || []
    )
  );
  return sortBy(
    map(suggestions, (suggestion) => {
      const {startInSeconds, fragmentStartIndex, fragmentEndIndex} = suggestion;
      if(startInSeconds) {
        return suggestion;
      }

      const isFirstRow = fragmentStartIndex === 0;
      const isLastRow = get(orderBy(transcript, 'id'), 'length') - 1 === fragmentEndIndex;

      const rowStart = transcript[fragmentStartIndex];
      const rowNext = transcript[fragmentEndIndex + 1];

      return {
        ...suggestion,
        startInSeconds: isFirstRow || !rowStart ? 0 : rowStart.startInSeconds,
        endInSeconds: isLastRow || !rowNext ? durationInSecond : rowNext.startInSeconds,
      };
    }),
    ({ startInSeconds }) => startInSeconds
  );
};

const keywordsPaths = [
  { type: 'competitors', path: 'bagOfWords.trackedCompetitors' },
  { type: 'brands', path: 'bagOfWords.brands' },
  { type: 'tracked_keywords', path: 'bagOfWords.trackedWords' },
  { type: 'keywords', path: 'bagOfWords.keywords' },
  { type: 'people', path: 'mentions.people' },
  { type: 'products', path: 'mentions.products' },
  { type: 'times', path: 'mentions.date' },
  { type: 'prices', path: 'mentions.money' },
  { type: 'seller_questions', path: 'questions' },
  { type: 'customer_questions', path: 'questions' },
];

export const transformKeywords = (insightsData, isVivaTopicsEnabledInCallSummary = false) =>
  keywordsPaths.map(({ type, path }) => {
    if (type.includes('questions')) return transformQuestions(type, insightsData, path);
    return {
      type,
      keywords: transformKeywordsByType(
        insightsData.map(({ insights, speakerId }) => ({
          currentInsights: get(insights, path),
          speakerId,
        })),
        isVivaTopicsEnabledInCallSummary
      ),
    };
  });

export const transformSummary = (executiveSummary, realtimeSummary) => {
  let summary = omit(executiveSummary, 'editState');
  if (isEmpty(summary) && realtimeSummary) {
    summary = realtimeSummary;
  }
  return summary;
};

export const transformPartnerHighlights = (partners, highlights, transcript) =>
  sortBy(
    map(transformParticipantActionItemsOrHighlight(highlights, transcript), (highlight) => {
      const partner = find(partners, ({ id }) => id === highlight.partnerId);
      return {
        ...highlight,
        partnerName: partner?.name,
        partnerImage: partner?.image,
      };
    }),
    ({ startInSeconds }) => startInSeconds
  );

export const transformActionItems = (
  insightsData,
  transcript,
  globalActionItems,
  generatedActionItems,
  realtimeActionItems
) => {

  if (!isEmpty(generatedActionItems)) {
    const transformedGeneratedActionItems = transformGeneratedActionItems(generatedActionItems, transcript);
    return sortBy(transformedGeneratedActionItems, ['startInSeconds']);
  }

  let transformedActionItems = [
    ...flatten(
        insightsData.map(({ insights, speakerId }) => transformParticipantActionItemsOrHighlight(get(insights, 'actionItems'), transcript, speakerId))
    ),
    ...transformParticipantActionItemsOrHighlight(globalActionItems, transcript),
  ]

  if (isEmpty(transformedActionItems) && !isEmpty(realtimeActionItems)) {
    transformedActionItems = sortBy(realtimeActionItems, ['startInSeconds', 'endInSeconds']);
  }

  return sortBy(transformedActionItems, ['startInSeconds']);
};

export const getActionItemStartInSeconds = (transcript, fragmentId) => {
  const fragmentOffset = get(
    find(transcript, (fragment) => fragment.id === fragmentId),
    'offset'
  );

  return fragmentOffset ? fragmentOffset / 1000 : fragmentOffset;
};

export const transformParticipantActionItemsOrHighlight = (actionItems, transcript, speakerId) =>
  map(
    actionItems,
    ({
      defaultText,
      body,
      fragmentId,
      id,
      offset,
      length,
      duration,
      publisherId,
      crmTaskLink,
      actionItemId,
      startInSeconds,
    }) => ({
      content: defaultText || body,
      startInSeconds: startInSeconds || getActionItemStartInSeconds(transcript, fragmentId),
      fragmentStartIndex: findIndex(transcript, (fragment) => fragment.id === fragmentId),
      fragmentId,
      speakerId,
      crmTaskLink,
      partnerId: publisherId, // For highlights
      actionItemId,
      id,
      offset,
      length: length || duration,
    })
  );

export const transformGeneratedActionItems = (generatedActionItems, transcript) =>
    map(
        generatedActionItems,
        ({
          id,
          actionItemId,
          crmTaskLink,
          description: {
            topic,
            text,
            owner
          },
          citations: {
            transcriptCitations,
          }
         }) => {
          const { fragmentIndex, textLocationInCharsWithinFragment: {offset, length} } =  transcriptCitations[0];
          const { id: fragmentId, speakerId } = transcript[fragmentIndex];
          const content =  `${topic ? `${topic}: ` : ''}${text} ${owner?.text ? `(${owner.text})` : ''}`;

          return {
            content,
            startInSeconds: getActionItemStartInSeconds(transcript, fragmentId),
            fragmentStartIndex: fragmentIndex,
            fragmentId,
            speakerId,
            id,
            actionItemId,
            crmTaskLink,
            offset,
            length,
            owner
          }
        }
    );



export const transformKeywordsByType = (keywords, isVivaTopicsEnabledInCallSummary = false) => {
  const keywordsWithInstances = keywords.map(({ currentInsights, speakerId }) =>
    transformKeywordsList(currentInsights, speakerId)
  );

  const allParticipantsKeywords = flatten(keywordsWithInstances);
  
  let sortedKeywords = allParticipantsKeywords;
  if (isVivaTopicsEnabledInCallSummary) {
    sortedKeywords = moveTopicsToTopOfKeywordsList(allParticipantsKeywords);
  } 

  const keywordsGroups = groupBy(sortedKeywords, 'actualWord');

  const res = Object.keys(keywordsGroups).map((key) => ({
    ...keywordsGroups[key][0],
    instances: flatten(keywordsGroups[key].map(({ instances }) => instances)),
  }));
  return res;
};

export const transformQuestions = (type, insightsData, path) => {
  const isMultiParticipant = size(insightsData) > 2;
  const questionTypeMap = {
    seller_questions: { role: 'agent', isCrmIdExist: true },
    customer_questions: { role: 'customer', isCrmIdExist: false },
  };

  const questionInsights = insightsData
    .filter(({ role, crmId }) =>
      isMultiParticipant
        ? questionTypeMap[type].isCrmIdExist === Boolean(crmId)
        : role === questionTypeMap[type].role
    )
    .map(({ insights, speakerId }) => ({
      currentInsights: map(get(insights, path), (insight) => ({
        ...insight,
        actualWord: insight.text,
      })),
      speakerId,
    }));

  return {
    type,
    keywords: transformKeywordsByType(questionInsights),
  };
};

const transformKeywordsList = (keywords, speakerId) =>
  map(keywords, (k) => ({
    ...omit(k, 'positions'),
    instances: transformKeywordInstances(k.positions || k, speakerId),
  }));

const transformKeywordInstances = (instances, speakerId) =>
  map(instances, ({ offset, duration, fragmentId }) => {
    const instance = transformInstance(offset, duration, speakerId);
    return { ...instance, offset, duration, fragmentId };
  });

const transformInstance = (offset, duration, speakerId) => ({
  startInSeconds: offset / 1000,
  endInSeconds: (offset + duration) / 1000,
  speakerId,
});

export const transformKPIs = ({
  talkToListenRatio,
  talkingSpeedWordsPerMin,
  switchCount,
  avgPauseBeforeSpeakingInMs,
  // Should be removed once MP feature is fully integrated (this customerLongestMonologue is not being shown, only longestMonoluge)
  customerLongestMonologueInMs,
}) => [
  {
    id: 'talk_to_listen_ratio',
    value: isNil(talkToListenRatio)
      ? 0
      : `${Math.round(talkToListenRatio * 100)}/${Math.round((1 - talkToListenRatio) * 100)}`,
  },
  {
    id: 'avg_talking_speed',
    value: isNil(talkingSpeedWordsPerMin) ? 0 : Math.round(talkingSpeedWordsPerMin),
    units: MEASUREMENT_UNIT.wpm,
  },
  {
    id: 'switch_count',
    value: isNil(switchCount) ? 0 : Math.round(switchCount),
  },
  {
    id: 'avg_pause',
    value: isNil(avgPauseBeforeSpeakingInMs) ? 0 : Math.round(avgPauseBeforeSpeakingInMs / 1000),
    units: MEASUREMENT_UNIT.secs,
  },
  {
    // Should be removed once MP feature is fully integrated (this customerLongestMonologue is not being shown, only longestMonoluge)
    id: 'longest_customer_monologue',
    value: isNil(customerLongestMonologueInMs)
      ? 0
      : Math.round(customerLongestMonologueInMs / 1000),
    units: MEASUREMENT_UNIT.secs,
  },
];

export const transformTranscript = (transcript) =>
  sortBy(
    map(transcript, (row) => ({
      ...row,
      ...transformInstance(row.offset, row.duration, row.participantId),
    })),
    ({ offset }) => offset
  );

export const transformComment = ({
  id,
  text,
  timestamp,
  commenterFullName,
  fullName,
  fragmentId,
  fragmentStart,
  commenterAadId,
  aadId,
}) => {
  // If we're using old version of comments API, we identify the fragment by fragmentId(GUID), else if we're using v2, we identify the fragment by fragmentStart(number)
  const fragmentIdentifier = fragmentId ?
      fragmentId === EMPTY_GUID ? id : fragmentId.toLowerCase() :
      fragmentStart;
  return {
    id,
    fragmentIdentifier,
    content: text,
    timestamp,
    commenterFullName: commenterFullName || fullName,
    aadId: (aadId || commenterAadId).toLowerCase(),
  }
};

export const transformComments = (comments) =>
  flow([fmap(transformComment), fGroupBy('fragmentIdentifier')])(comments);

export const transformTimelineGroupByRole = (participants, duration, transcriptWithSentiments, role) => {
  const speakerIds = filter(participants, (participant) => participant.role === role).map(
    ({ id }) => id
  ).filter((speakerId) =>
    transcriptWithSentiments.filter((t) => t?.speakerId === speakerId).some((t) => t?.sentimentType)
  );

  return {
    mainTimeline: {
      speakerIds,
      blocks: transformTimeline(transcriptWithSentiments, duration, speakerIds),
    },
    detailsTimelines: speakerIds.map((id) => ({
      speakerId: id,
      blocks: transformTimeline(transcriptWithSentiments, duration, [id]),
    })),
  };
};

export const transformTimeline = (transcript, duration, speakerIds) => {
  const blockTimeFrame = duration / TIMELINE_BLOCKS_COUNT / 1000;
  const speakerTranscript = transcript.filter((t) => speakerIds.includes(t.speakerId));

  return flow([
    initTimeline,
    (blocksTimeline) => calculateSentimentBlocks(blocksTimeline, speakerTranscript, blockTimeFrame),
    fmap(setSentimentBlock),
  ])(blockTimeFrame);
};

const initTimeline = (blockTimeFrame) => {
  const blocksTimeline = Array(TIMELINE_BLOCKS_COUNT).fill(0);
  blocksTimeline.forEach((block, i) => {
    blocksTimeline[i] = {
      startInSeconds: i === 0 ? 0 : blocksTimeline[i - 1].endInSeconds,
      endInSeconds: Number(((i + 1) * blockTimeFrame).toFixed(2)),
      sentimentType: {},
    };
  });
  return blocksTimeline;
};

export const calculateSentimentBlocks = (blocksTimeline, transcript, blockTimeFrame) => {
  transcript.forEach((t) => {
    const startBlockIndex = getBlockIndex(blocksTimeline, t.startInSeconds);
    const endBlockIndex = getBlockIndex(blocksTimeline, t.endInSeconds);

    if (startBlockIndex === endBlockIndex) {
      blocksTimeline[startBlockIndex].sentimentType[t.sentimentType] =
        t.endInSeconds - t.startInSeconds;
      return;
    }

    slice(blocksTimeline, startBlockIndex, endBlockIndex).forEach((block, i) => {
      if (i === startBlockIndex) {
        block.sentimentType[t.sentimentType] = block.endInSeconds - t.startInSeconds;
      } else if (i === endBlockIndex) {
        block.sentimentType[t.sentimentType] = t.endInSeconds - block.startInSeconds;
      } else {
        block.sentimentType[t.sentimentType] = blockTimeFrame;
      }
    });
  });
  return blocksTimeline;
};

const setSentimentBlock = (timelineBlock) => {
  let type;
  let maxTypeLength = 0;
  Object.keys(timelineBlock.sentimentType).forEach((typeKey) => {
    const value = timelineBlock.sentimentType[typeKey] || 0;
    if (value > maxTypeLength) {
      type = typeKey;
      maxTypeLength = value;
    }
  });
  return {
    ...timelineBlock,
    sentimentType: type || null,
  };
};

export const mergeTranscriptWithSentiments = (transcript, sentiments) => {

  if (!transcript) return null;

 const fragmentsWithSentiments = transcript.map(({ startInSeconds, endInSeconds, speakerId, id }) => {
    const isPositive = sentiments?.positive?.instances?.find(({fragmentId}) => fragmentId === id);
    const isNegative = sentiments?.negative?.instances?.find(({fragmentId}) => fragmentId === id);

    const sentimentType = isPositive ? SENTIMENT_NAMES.positive: isNegative ? SENTIMENT_NAMES.negative: SENTIMENT_NAMES.neutral;

    return {
      fragmentId: id,
      startInSeconds,
      endInSeconds,
      speakerId,
      sentimentType,
    };
  });

  return fragmentsWithSentiments;
};

export const getBlockIndex = (blocks, endOfSentiment) => {
  if (!blocks.length) {
    return 0;
  }

  for (let i = 0; i < blocks.length; i++) {
    if (blocks[i].endInSeconds >= endOfSentiment) {
      return i;
    }
  }
  return blocks.length - 1;
};

export const transformOpportunityDetails = async (data) => {
  const rawDetails = JSON.parse(data);
  const details = rawDetails && rawDetails.value && rawDetails.value[0];
  return {
    name: details.name,
    opportunityId: details.opportunityid,
    owner: details['_ownerid_value@OData.Community.Display.V1.FormattedValue'],
    account: details['_parentaccountid_value@OData.Community.Display.V1.FormattedValue'],
    contact: details['_parentcontactid_value@OData.Community.Display.V1.FormattedValue'],
    amount: formatNumber(details.budgetamount),
    revenue: formatNumber(details.estimatedvalue),
    closeDate: details.estimatedclosedate,
  };
};

export const transformEntityDetails = (data) =>
  map(data, ({ name, id, type, details = {}, relatedEntities }) => ({
    name,
    entityId: id,
    entityType: type,
    details,
    relatedEntities,
    key: `${type}${id}${name}`,
  }));

export const transformFromLookupSchema = ({ type, id, primaryName, relatedEntities }) => ({
  entityType: type,
  entityId: id,
  name: primaryName,
  relatedEntities,
});
export const transformToLookupSchema = ({
  entityId,
  entityType,
  key,
  name,
  details,
  relatedEntities,
}) => ({
  type: entityType,
  attributes: null,
  id: entityId,
  key,
  primaryName: name,
  secondaryText: details.email,
  relatedEntities,
});
