import { filterOutDeprovisionedApps } from 'utils/app-context/app-context';

export const transformApps = (apps) => filterOutDeprovisionedApps(apps).map((app) => {
  const orgId = app.organizationId;
  // eslint-disable-next-line no-param-reassign
  delete app.organizationId;
  // eslint-disable-next-line no-param-reassign
  delete app.id; // irrelevant db-item id
  return { ...app, orgId };
});
