// @flow

import React, { memo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import combineConfigs from './configs/combineConfigs';
import defaultConfig from './configs/defaultConfig';
import { ChartContainer } from './StyledChart';
import { isDirectionRTL } from '../../utils/dom/dom';
import { mobileConfig } from './configs/mobile/mobile';

HighchartsMore(Highcharts);
highchartsAccessibility(Highcharts);

// workaround for making the line legend symbol circle
Highcharts.seriesTypes.line.prototype.drawLegendSymbol = Highcharts.seriesTypes.area.prototype.drawLegendSymbol;

// Allow the data-testid attributes on charts
Highcharts.AST.allowedAttributes.push('data-testid');

function validateData(series) {
  let isValid = true;
  if (Array.isArray([series])) {
    series.forEach((s) => {
      if (!(Array.isArray(s.data) && s.data.length)) {
        isValid = false;
      }
    });
  } else {
    isValid = false;
  }
  if (!isValid) {
    throw new Error(
      'series data should equal = [data: [{...}, {...}, etc.], key: value, key: value, etc.]',
    );
  }
}

// this is separated because inlining it in the Chart component makes the snapshots include the whole Highcharts object
const HighchartsReactComp = ({ options, containerProps, callback }) => (
  <HighchartsReact
    highcharts={Highcharts}
    options={options}
    containerProps={containerProps}
    callback={callback}
  />
);

export const Chart = memo(
  ({ config = {}, domProps = { style: { height: '100%' } }, afterRender, onMouseEnter, onMouseLeave }) => {
    const isRTL = isDirectionRTL();
    const { series, type } = config;
    // let mouseEnterTime;

    validateData(series);

    const finalConfig = combineConfigs({ series, type, isRTL }, [
      defaultConfig,
      config,
      mobileConfig,
      { series },
    ]);

    return (
      <ChartContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <HighchartsReactComp
          options={finalConfig}
          containerProps={domProps}
          callback={afterRender}
        />
      </ChartContainer>
    );
  },
  ({ keepRefreshing }) => !keepRefreshing,
);
