import styled from 'styled-components';
import { DefaultButton } from '@fluentui/react';
import { getControlStyles } from '../RecordingSection/StyledRecordingSection';
import { rtl } from '../../../utils/styles/rtl';

export const DropDown = styled(DefaultButton).attrs(({ theme, disabled }) => ({
  styles: getControlStyles(disabled, theme),
  iconProps: { iconName: 'Globe' },
}))`
  margin: 0 2px;
  border: 0;
  &:after {
    border: solid 1px ${({ theme }) => theme.colors.Black};
    border-radius: 4px;
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.backgroundColor};
    i, span {
      ${({ theme, disabled }) => !disabled && `color: ${theme.colors.DYNShade20}`};
    }
  }
  :active {
    i, span {
      color: ${({ theme }) => theme.colors.DYNShade30};
    }
  }

  .ms-Button-menuIcon {
    margin: 0;
    ${rtl`margin-right: 4px`};
    color: ${({ theme, disabled }) => disabled ? theme.colors.Gray60 : theme.colors.Gray130};
  }

  .ms-Button-icon {
    margin: 0;
    ${rtl`margin-left: 4px`};
    color: ${({ theme, disabled }) => disabled ? theme.colors.Gray60 : theme.colors.Gray160};
  }
`;
