import * as React from 'react';
import { map, toLower } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { Container } from './StyledComments';
import ShowMoreCommentsButton from './ShowMoreCommentsButton';
import CommentEditor from '../CommentEditor/CommentEditor';
import { Comment } from '../Comment/Comment';
import { ShowMore } from '../../../ShowMore/ShowMore';
import { CommentEditorContainer } from '../Comment/StyledComment';
import { useInfra } from '../../../../providers/InfraProvider';

const COMMENTS_MIN_LENGTH = 2;

const stopPropagation = (e) => e.stopPropagation();

export const Comments = ({
  comments,
  onAddComment,
  onUpdateComment,
  onDeleteComment,
  isCommentActionError,
  onDismissAddCommentError,
  isNewCommentMode,
  isCommentActionInProgress,
  onCancelNewComment,
  commentTexts,
  showMoreTexts,
  onShowMoreClick,
  isRTL
}) => {
  const { userId = '', isEditable } = useInfra();
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div onClick={stopPropagation}>
      {!isEmpty(comments) && (
        <Container isRTL={isRTL}>
          <ShowMore
            onClick={onShowMoreClick}
            // eslint-disable-next-line react/jsx-props-no-spreading
            CustomShowMoreButton={(props) => <ShowMoreCommentsButton {...props} showMoreTexts={showMoreTexts} />}
            minLength={COMMENTS_MIN_LENGTH}
          >
            {
              map(comments, ({
                commenterFullName,
                content,
                timestamp,
                aadId,
                id
              }) => (
                <Comment
                  isRTL={isRTL}
                  texts={commentTexts}
                  key={id}
                  commenterFullName={commenterFullName || commentTexts.defaultFullName || 'Unknown user'}
                  canEdit={isEditable && aadId === toLower(userId)}
                  onUpdateComment={(editedCommentText) => onUpdateComment(id, editedCommentText)}
                  onDeleteComment={() => onDeleteComment(id)}
                  timestamp={timestamp}
                  content={content}
                  isCommentActionInProgress={isCommentActionInProgress}
                />
              ))
            }
          </ShowMore>
        </Container>
      )}
      {isNewCommentMode && (
        <CommentEditorContainer>
          <CommentEditor
            isLoading={isCommentActionInProgress}
            saveText={commentTexts.save}
            cancelText={commentTexts.cancel}
            onCancelClicked={onCancelNewComment}
            onSaveClicked={onAddComment}
          />
        </CommentEditorContainer>
      )}
      {isCommentActionError && (
        <MessageBar
          messageBarType={MessageBarType.error}
          onDismiss={onDismissAddCommentError}
        >
          {commentTexts.api_error}
        </MessageBar>
      )}
    </div>
  );
};
