import styled, { css } from 'styled-components';
import { FontIcon, Link, Text } from '@fluentui/react'; // DO NOT remove /lib #PR-560326
import { Icon } from '@fluentui/react/lib/Icon';
import { colors } from '../../constants/colors';
import { ARIA_ROLES } from '../../constants/roles-list';
import { rtl } from './rtl';

const headingToDivCss = css`
  display: block;
  font-size: 1em;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  font-weight: normal;
`;

export const textCss = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: normal;
  color: inherit;
  ${({ multiline }) => multiline
          && css`
      line-height: 1.5;
      white-space: pre-line;
      word-break: break-word;
      word-wrap: break-word;
      color: inherit;
    `};
`;

const flexRowCss = css`
  display: flex;
  flex-direction: row;
`;

const flexColumnCss = css`
  display: flex;
  flex-direction: column;
`;

export const defaultFontFamilyCss = css`
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
`;

export const ellipsisCss = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FlexRow = styled.div`
  ${flexRowCss};
`;

export const FlexColumn = styled.div`
  ${flexColumnCss};
`;

export const FlexColumnCentered = styled.div`
  ${flexColumnCss};
  align-items: center;
  justify-content: center;
`;

export const FlexRowCentered = styled.div`
  ${flexRowCss};
  align-items: center;
`;

export const FlexRowWrap = styled.div`
  ${flexRowCss};
  flex-wrap: wrap;
`;

export const FlexSpaceBetween = styled.div`
  ${flexRowCss};
  justify-content: space-between;
`;

export const Stretch = styled.div`
  height: ${($height) => $height ? '100%' : 'auto'};
  width: ${($width) => $width ? '100%' : 'auto'};
`;

export const SROnly = styled.div`
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  font-size: 0;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
`;

export const StyledText = styled(Text)`
  ${textCss};
`;

export const StyledLink = styled(Link).attrs({
  role: 'link',
})`
  color: ${({ theme }) => theme.colors?.DYNPrimary};
  :hover {
    color: ${({ theme }) => theme.colors?.Black};
  }
  width: auto;
  height: auto;
`;

export const FocusableContainer = styled(Link).attrs(({ role }) => ({
  role: role || ARIA_ROLES.CONTENT_INFO,
}))`
  text-decoration: none;
  color: inherit;
  outline: inherit;
  width: 100%;
  cursor: default;
  &&:hover,:focus,:active {
    text-decoration: none;
    color: inherit;
  };
`;

export const HighContrastThemeAwareFocusableContainer = styled(Link).attrs(({ role }) => ({
    role: role || ARIA_ROLES.CONTENT_INFO,
}))`
  text-decoration: none;
  color: inherit;
  outline: inherit;
  width: 100%;
  cursor: default;
  &&:hover,:focus,:active {
    text-decoration: none;
    color: inherit;
  };
  @media (forced-colors: active) {
   color:white;
   forced-color-adjust: none;
   &&:focus,:active {
    text-decoration: none;
    color: white;
   };
    &&:hover,:focus,:active {
    text-decoration: none;
    color: black;
   };
  };
`;

export const StyledFontIcon = styled(FontIcon)``;

export const StyledPageTitle = styled.h1`
  ${headingToDivCss}
  `;

export const StyledTitle = styled.h2`
  ${headingToDivCss}
`;

export const StyledSubTitle = styled.h3`
  ${headingToDivCss}
`;

export const Required = styled.span.attrs({
    children: '*'
})`
  font-size: 14px;
  font-weight: 600;
  color: ${colors.DynamicsRed};
  ${rtl`margin-left: 5px`};
`;

export const BlockedIcon = styled(Icon).attrs(() => ({
    iconName: 'Blocked12',
    styles: { root: { color: colors.Error } }
}))`
`;
