import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import toString from 'lodash/toString';
import mapKeys from 'lodash/mapKeys';
import { get, find } from 'lodash';
import { PARTICIPANTS_ROLES, SENTIMENT_NAMES, recordingTargetType } from 'ci-common-ui';
import { transformServerDate } from './dateFilters/date-filters';
import { transformSellerListTopPerformer } from './sellerFilters/seller-filters';
import { transformSentimentDataForPie, transformSentimentDataForPercentagesBar } from '../../utils/chart';
import { dataTransformer } from '../../utils/error-state/error-state';
import { getContactsFromParticipantsObject, transformCrm, transformTags } from './utils';
import { dateFromServer } from '../../utils/date/date';

const keysMap = {
  negativeSentimentAvgOfHighNegCalls: SENTIMENT_NAMES.negative,
  neutralSentimentAvgOfHighNegCalls: SENTIMENT_NAMES.neutral,
  positiveSentimentAvgOfHighNegCalls: SENTIMENT_NAMES.positive,
  negativeSentimentAboveAvgCallsCount: 'callsCount',
  negativeAvgOnSpecificDate: SENTIMENT_NAMES.negative,
  neutralAvgOnSpecificDate: SENTIMENT_NAMES.neutral,
  positiveAvgOnSpecificDate: SENTIMENT_NAMES.positive,
  date: 'day'
};
export const transformInsightData = (data, topPerformers, transformFunc) => transformFunc(data, topPerformers);

const transformCallsWithHigherNegativeSentimentAverageSentiments = (data) => {
  if (isEmpty(data.callsWithHigherNegativeSentimentAverageSentiments)) {
    return [];
  }
  const pieData = [mapKeys(data.callsWithHigherNegativeSentimentAverageSentiments[0], (val, key) => keysMap[key])];
  return transformSentimentDataForPie(pieData);
};

const transformAveragedSentimentsOfCallsWithHigherNegSentimentByDate = (data) => {
  if (isEmpty(data.averagedSentimentsOfCallsWithHigherNegSentimentByDate)) {
    return [];
  }

  const chartData = data.averagedSentimentsOfCallsWithHigherNegSentimentByDate.map((item) => mapKeys(item, (val, key) => keysMap[key]));
  return transformSentimentDataForPercentagesBar(chartData);
};

const customerSentimentInsightTransformers = {
  dateTimeRange: (data) => transformServerDate(data),
  callsWithHigherNegativeSentimentAverageSentiments: transformCallsWithHigherNegativeSentimentAverageSentiments,
  averagedSentimentsOfCallsWithHigherNegSentimentByDate: transformAveragedSentimentsOfCallsWithHigherNegSentimentByDate,
};

export const transformCustomerSentimentInsightCharts = (data) => (
  dataTransformer('customerSentimentInsight', customerSentimentInsightTransformers, data)
);

const transformChartData = (data, totals, valueField, totalValueField) => data.map((keyword) => ({
  name: keyword.word,
  total: totals?.find((item) => item.word === keyword.word)[totalValueField],
  data: keyword?.data?.map((itemData) => ({
    value: itemData[valueField],
    date: new Date(itemData.date).getTime() // Highcharts requires "dynamic" series with 'x' value to be of type number
  }))
}));

const transformTopTrendingKeywords = ({ topWordsTotalCounts }) => (
  map(topWordsTotalCounts, ({ word, trend }) => ({
    name: word,
    trend
  }))
);

const keywordInsightTransformers = {
  dateTimeRange: (data) => transformServerDate(data),
  topTrendingKeywords: (data) => transformTopTrendingKeywords(data),
  totalMentionsOverTime: (data) => transformChartData(data.totalMentionsOverTime, data.topWordsTotalCounts, 'totalMentionsOnSpecificDate', 'totalMentions'),
  totalConversationsOverTime: (data) => transformChartData(data.totalCallsOverTime, data.topWordsTotalCounts, 'totalCallsOnSpecificDate', 'totalCalls'),
};

export const transformKeywordsInsightsCharts = (data) => (
  dataTransformer('keywordInsights', keywordInsightTransformers, data)
);

// Should remove '|| someFallback' once server will always return participants object, and not flat data
const getAgentName = ({ participants, agentName }) => get(find(participants, ({ typeId, role }) => [typeId, role].includes(PARTICIPANTS_ROLES.agent)), 'displayName') || agentName;

export const transformInsightTable = (data, topPerformers) => {
  const { listOfRelevantCallsDetails } = data;
  const listOfRelevantCallsDetailsWithTopPerformer = transformSellerListTopPerformer(listOfRelevantCallsDetails, topPerformers);

  return {
    ...data,
    listOfRelevantCallsDetails: listOfRelevantCallsDetailsWithTopPerformer.map((row) => ({
      ...row,
      key: row.id,
      id: toString(row.id),
      callTopic: row.subject,
      // supporting currentUserOnly only for past calls that were made under this type, and referring currentUserOnly as OnlyAgents
      consentMode: row.consentMode === 'currentUserOnly' ? recordingTargetType.OnlyAgents : row.consentMode,
      customerSentiment: { positive: row.positive, neutral: row.neutral, negative: row.negative },
      agentName: getAgentName(row),
      crmEntitiesLinks: transformCrm({
        contacts: getContactsFromParticipantsObject(row.participants),
        accounts: [{ name: row.accountName, id: row.callAccountCrmId }],
        opportunity: { name: row.opportunityName, id: row.callOpportunityCrmId },
        lead: { name: row.leadName, id: row.callLeadCrmId },
        activity: { name: row.phoneCallActivitySubject, id: row.callPhoneCallCrmId, type: row.crmActivityType }
      }),
      dateAndDuration: {
        startTime: dateFromServer(row.startTime),
        duration: row.duration
      },
      callType: {
        callScope: row.callScope,
        callDirection: row.callDirection
      },
      tags: transformTags(row.tags)
    }))
  };
};
