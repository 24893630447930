import styled from 'styled-components';
import { Link } from '@fluentui/react';
import { rtl } from '../../../../utils/styles/rtl';
import { StyledLink, FlexColumn } from '../../../../utils/styles/StyledUtils';
import { colors } from '../../../../constants/colors';

export const Container = styled.div.attrs(({ isRTL }) => ({
  dir: isRTL ? 'rtl' : 'ltr'
}))`
  margin-bottom: 8px;
  ${rtl`margin-left`}: 16px;
  ${({ isRTL }) => isRTL && `
    margin-left: 0px;
    margin-right: 16px;
  `};
  border-top: 1px solid ${colors.Gray30};
  padding-top: 8px;
`;

export const ActivityItemContainer = styled.div`
  position: relative;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const Comment = styled(FlexColumn)`
  
`;

export const Header = styled.div`
  font-size: 12px;
  line-height: 18px;
`;

export const Name = styled.span`
  font-weight: 700;
`;

export const Content = styled.div`
  font-size: 12px;
  margin-bottom: 8px;
  line-height: 18px;
`;

export const Action = styled(Link)`
  &:not(:last-child) {
    ${rtl`margin-right: 16px`};
  }
  font-size: 12px;
`;

export const Time = styled.div`
  font-size: 10px;
  color: ${colors.Gray130};
`;

export const ShowMoreButton = styled(StyledLink)`
  padding: 0 8px;
  font-size: 12px;
`;
