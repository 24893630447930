import styled from 'styled-components';
import callIcon from 'assets/images/call.svg';
import callIncomeIcon from 'assets/images/call-income.svg';
import callOutgoingIcon from 'assets/images/call-outgoing.svg';
import { FlexColumn } from 'ci-common-ui';

export const getPhoneIcon = (type) => {
  const icon = type ? (type === 'inbound' ? callIncomeIcon : callOutgoingIcon) : callIcon;

  return styled(icon)`
    height: 32px;
    width: 32px;
    margin: -4px;
    display: block;
  `;
};

export const CallScope = styled.div`
`;

export const CallTypeContainer = styled(FlexColumn)`
  position: relative;
  align-items: center;
  width: calc(100% - 8px);
`;
