import React from 'react';
import { ActionButton } from '@fluentui/react';
import { useInfra } from '../../../../../../providers/InfraProvider';
import { useResize } from '../../../../../../hooks/use-resize';
import { copyButtonStyles } from './StyledSuggestionPanel';
import { CALL_STRINGS } from '../../../../strings';

export const ScalableCopyButton = ({ onCopySummaryToClipboard, isDisabled }) => {
  const { t } = useInfra();
  const { isBigMobile } = useResize();

  return <ActionButton data-testid='copy-button'
                     styles={copyButtonStyles}
                     iconProps={{ iconName: 'Copy' }}
                     disabled={isDisabled}
                     onClick={onCopySummaryToClipboard}
                     text={isBigMobile ? '': t(CALL_STRINGS.COPY_CALL_TOOLTIP)}/>;
}