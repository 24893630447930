import React from 'react';
import useLocale from 'hooks/use-locale';
import { Trans } from 'ci-common-ui';
import { PrimaryButton } from "@fluentui/react";
import {  StepContainer, StepFooter, StepRoot } from '../../../../../components/Wizard/StyledWizard';
import { Header } from './StyledExternalOnboarding';
import { Link } from '../../../../../components/Link/Link';

const LOCALIZATION_PREFIX = 'pages.onboarding.external_onboarding';

const LEARN_MORE_URL = 'https://learn.microsoft.com/en-us/dynamics365/sales/fre-setup-ci-sales-app#configure-microsoft-teams-call-recording';

const ExternalOnboarding = ({closeModal}) => {
    const { t, tAbs } = useLocale(LOCALIZATION_PREFIX);

    return (
        <StepRoot>
            <StepContainer>
                <Header>{t('subtitle')}</Header>
                <Trans i18nKey={`${LOCALIZATION_PREFIX}.body`}>
                    text <Link href={LEARN_MORE_URL} target="_blank">link</Link>
                </Trans>
            </StepContainer>
            <StepFooter>
                <PrimaryButton text={tAbs('common.ok')} onClick={closeModal} />
            </StepFooter>
        </StepRoot>
    );
};

export default ExternalOnboarding;
