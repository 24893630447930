import React from 'react';
import { initializeIcons as initializeIconsFluentUI, registerIcons } from "@fluentui/react";

export const icons = {
    link_icon: (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 6.8667C8.27614 6.8667 8.5 7.09056 8.5 7.3667C8.5 7.61216 8.32312 7.81631 8.08988 7.85864L8 7.8667H6C4.34315 7.8667 3 9.20985 3 10.8667C3 12.4573 4.23784 13.7587 5.80275 13.8603L6 13.8667H8C8.27614 13.8667 8.5 14.0906 8.5 14.3667C8.5 14.6122 8.32312 14.8163 8.08988 14.8586L8 14.8667H6C3.79086 14.8667 2 13.0758 2 10.8667C2 8.7312 3.67346 6.98655 5.78053 6.87262L6 6.8667H8ZM14 6.8667C16.2091 6.8667 18 8.65756 18 10.8667C18 13.0022 16.3265 14.7468 14.2195 14.8608L14 14.8667H12C11.7239 14.8667 11.5 14.6428 11.5 14.3667C11.5 14.1212 11.6769 13.9171 11.9101 13.8748L12 13.8667H14C15.6569 13.8667 17 12.5236 17 10.8667C17 9.27612 15.7622 7.97465 14.1973 7.87308L14 7.8667H12C11.7239 7.8667 11.5 7.64284 11.5 7.3667C11.5 7.12124 11.6769 6.91709 11.9101 6.87475L12 6.8667H14ZM6 10.3667H14C14.2761 10.3667 14.5 10.5906 14.5 10.8667C14.5 11.1122 14.3231 11.3163 14.0899 11.3586L14 11.3667H6C5.72386 11.3667 5.5 11.1428 5.5 10.8667C5.5 10.6212 5.67688 10.4171 5.91012 10.3748L6 10.3667H14H6Z" fill="#0B53CE"/>
        </svg>
    ),
    open_folder: (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 3.8667C4.34315 3.8667 3 5.20985 3 6.8667V14.8667C3 16.5236 4.34315 17.8667 6 17.8667H10.5C10.7761 17.8667 11 17.6428 11 17.3667C11 17.0906 10.7761 16.8667 10.5 16.8667H6C4.89543 16.8667 4 15.9713 4 14.8667V6.8667C4 5.76213 4.89543 4.8667 6 4.8667H14C15.1046 4.8667 16 5.76213 16 6.8667V9.3667C16 9.64284 16.2239 9.8667 16.5 9.8667C16.7761 9.8667 17 9.64284 17 9.3667V6.8667C17 5.20985 15.6569 3.8667 14 3.8667H6ZM8.14645 9.01315C8.24582 8.91377 8.37661 8.86497 8.50684 8.86675H13.5C13.7761 8.86675 14 9.0906 14 9.36675C14 9.64289 13.7761 9.86675 13.5 9.86675H9.70715L15.8536 16.0131C16.0488 16.2084 16.0488 16.525 15.8536 16.7203C15.6583 16.9155 15.3417 16.9155 15.1464 16.7203L9 10.5738V14.3667C9 14.6429 8.77614 14.8667 8.5 14.8667C8.22386 14.8667 8 14.6429 8 14.3667V9.36675L8.00004 9.3603C8.00078 9.30212 8.01145 9.24634 8.03043 9.19457C8.05465 9.12838 8.09332 9.06627 8.14645 9.01315Z" fill="#0B53CE"/>
        </svg>
    ),
};

export const initializeIcons = () => {
    registerIcons({icons});
    initializeIconsFluentUI();
}

