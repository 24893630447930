import React, { useEffect } from 'react';
import { DEFAULT_GRAPH_API_IMAGE_SIZE, useFetch } from 'ci-common-ui';
import { getAvatar } from '../../network/rest-services/app-rest-service';
import { getGraphToken } from '../../services/auth-service';
import { StyledAvatarPersona } from './StyledAvatarPersona';

export const AvatarPersona = ({ className, aadId, apiImageSize, ...rest }) => {
  const { data: image, fetchData, isLoading } = useFetch(getAvatar);

  const getImage = async () => {
    if (aadId) {
      const token = await getGraphToken();
      if (token) {
        const size = apiImageSize || DEFAULT_GRAPH_API_IMAGE_SIZE;
        fetchData({ aadId, token, size });
      }
    }
  };

  useEffect(() => {
    getImage();
  }, []);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StyledAvatarPersona isLoading={isLoading} imageUrl={image} {...rest} className={className} />;
};
