import get from 'lodash/get';
import {
  serverApi,
  BASE_URLS,
  storageService,
  ORG_ID_HEADER_NAME,
  SESSION_ID_HEADER_NAME,
  SERVER_API_URLS,
  ERROR_CLIENT_ACTIVITY_ID,
} from 'ci-common-ui';
import { SciRegion } from '@sci/common';
import { SESSION_ID } from '../constants/browser-storage';
import { appContext, getIsCustomerServiceMode, getIsDemoUser } from '../utils/app-context/app-context';
import { eventAction, trackEvent, trackError } from '../services/telemetry-service/telemetry-service';
import { TRACK_IDS } from '../constants/tracking';
import { getTelemetryErrorMsgObj } from '../services/telemetry-service/utils';
import { SERVER_URL } from '../constants/config';
import { getToken } from '../services/auth-service';
import { getNewApiServerUrl, toNewBackendUrl } from '../utils/url/url';
import DataCacheService from '../services/DataCacheService';

const baseUrlMap = {
  [BASE_URLS.DEFAULT_API_SERVER]: () => appContext.clusterRegion || SERVER_URL,
  [BASE_URLS.NEW_API_SERVER]: getNewApiServerUrl,
  [BASE_URLS.MICROSOFT_GRAPH_SERVER]: () => 'https://graph.microsoft.com'
};

export const checkIsBaseUrlWEUR = (options) => {
  // apps-for-user-worldwide specifically should go to weur if we dont know clusterRegion yet,
  // as global cosmos stored in weur
  const isProd = SERVER_URL && !SERVER_URL.includes('dev') && !SERVER_URL.includes('tip');
  return options.isAppsForUserWorldWideCall && !appContext.clusterRegion && isProd;
};


export const getBaseUrl = (options) => {
  const isBaseUrlWEUR = checkIsBaseUrlWEUR(options)
  if (isBaseUrlWEUR) {
    return toNewBackendUrl(SERVER_API_URLS[SciRegion.weur]);
  }
  const baseUrl = get(options, 'baseUrl');

  if (baseUrl) {
    return baseUrlMap[baseUrl] ? baseUrlMap[baseUrl]() : baseUrl;
  }

  return baseUrlMap[BASE_URLS.DEFAULT_API_SERVER]();
};

export const getContentType = ({ isMultipartFormData }) => (
  { 'Content-Type': isMultipartFormData ? 'multipart/form-data' : 'application/json' }
);

export const getSessionId = () => {
  const { sessionStorage } = storageService;
  return sessionStorage.getItem(SESSION_ID);
};

const getContextId = () => Math.random().toString(16).slice(2);

export const getCommonParams = ({ baseUrl }) => (
  baseUrl && baseUrl !== BASE_URLS.DEFAULT_API_SERVER ? {} : {
    isCustomerService: getIsCustomerServiceMode(),
    isDemo: getIsDemoUser(),
    orgId: appContext.orgId,
    orgUrl: appContext.orgUrl,
    appId: appContext.appId
  }
);

export const getCommonHeaders = (options = {}) => ({
  'X-Context-ID': getContextId(),
  [SESSION_ID_HEADER_NAME]: getSessionId(),
  [ORG_ID_HEADER_NAME]: options.orgId || appContext.orgId,
  appId: appContext.appId,
  ...getContentType(options)
});

// eslint-disable-next-line no-unused-vars
export const trackReqSuccess = (overrideActivityId, message, options) => trackEvent({ overrideActivityId, action: eventAction.info, actionOn: TRACK_IDS.COMMON.REQUEST, message });

export const trackReqFailure = async (overrideActivityId, message, options) => {
  if (!get(options, 'acceptedErrorCodes', []).includes(message.status)) {
    storageService.sessionStorage.setItem(ERROR_CLIENT_ACTIVITY_ID, overrideActivityId);
    trackError({ overrideActivityId, actionOn: TRACK_IDS.COMMON.REQUEST, message: { ...message, error: await getTelemetryErrorMsgObj(message.error) } });
  }
};

const axiosOptions = {
  baseURL: SERVER_URL
};

const axiosCallbacks = {
  getBaseUrl,
  getToken: (options) => options?.token || getToken(),
  getCommonParams,
  getCommonHeaders,
  trackReqSuccess,
  trackReqFailure
};

export const initServerApi = () => serverApi.init(axiosOptions, axiosCallbacks, DataCacheService);
