import styled from 'styled-components';
import { FlexColumn, FlexRow } from 'ci-common-ui';

export const Container = styled(FlexRow)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`;

export const TitleShimmerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;
`;

export const DescriptionShimmer = styled(FlexColumn)`
  justify-content: space-around;
  width: 45%;
  height: 100%;
`;
