import styled, { keyframes, css } from 'styled-components';
import { StyledText, FlexColumn, StyledLink, rtl } from 'ci-common-ui';
import { dataAutomation, AUTOMATION_SELECTORS } from 'constants/selectors';

const fadeIn = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

export const PageSectionContainer = styled(FlexColumn).attrs({
  as: 'section',
  [dataAutomation]: AUTOMATION_SELECTORS.PAGE_SECTION
})`
  ${({ isLoading }) => isLoading === false && css`
      animation: ${fadeIn} 0.3s ease-in;
  `};
`;

export const SectionTitle = styled(StyledText).attrs({
  as: 'h1',
  multiline: true
})`
    font-size: 24px;
    font-weight: 600;
`;

export const SectionSubtitle = styled(StyledText).attrs({
  multiline: true
})`
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 16px;
    ${StyledLink} {
        ${rtl`margin-left`}: 4px;
    }
`;
