import styled from 'styled-components';
import { StyledChart, rtl, FlexRowWrap, deviceSizes } from 'ci-common-ui';
import { PageSectionContainer, SectionTitle } from './components/PageSection/StyledPageSection';
import { SectionCardContainer } from './components/SectionCard/StyledSectionCard';
import { AUTOMATION_SELECTORS, dataAutomation } from '../constants/selectors';

const { ChartContainer } = StyledChart;

export const CHART_HEIGHT_NUMBER = 288;
export const CARD_SPACING = '16px';
export const MIN_CARD_WIDTH = '300px';
export const CHART_HEIGHT = `${CHART_HEIGHT_NUMBER}px`;
const CAROUSEL_ITEM_PADDING = '8px';

export const PageContainer = styled.div`
  width: 100%;
  max-width: 1264px;
  padding: 0 ${CARD_SPACING};
`;

export const CardsRow = styled(FlexRowWrap)`
    justify-content: space-between;
    ${rtl`margin-left`}: -${CARD_SPACING};
    ${deviceSizes.mobile`
      ${rtl`margin-left`}: 0px;
    `}
    & > * {
        margin-bottom: ${CARD_SPACING};
        ${rtl`margin-left`}: ${CARD_SPACING};
        flex: 1 1 ${MIN_CARD_WIDTH};
        display: flex;
        flex-direction: column;
        width: auto;
        min-width: ${MIN_CARD_WIDTH};
        max-width: initial;
        ${deviceSizes.mobile`
          flex: 1 1 100%;
          min-width: 100%;
          ${rtl`margin-left`}: 0px;
        `}
    }
    & .ms-Pivot {
      white-space: normal;
      margin-bottom: 8px;
    }
    ${ChartContainer} {
      height: ${CHART_HEIGHT};
    }
`;

export const InsightsSection = styled(PageSectionContainer)`
    width: calc(100% + ${CAROUSEL_ITEM_PADDING} + ${CAROUSEL_ITEM_PADDING});
    ${rtl`margin-left`}: -${CAROUSEL_ITEM_PADDING};
    margin-bottom: 12px;
`;

export const InsightsTitle = styled(SectionTitle)`
    transform: translateY(30px);
    text-indent: ${CAROUSEL_ITEM_PADDING};
    ${deviceSizes.mobile`
      transform: translateY(0px);
    `}
`;

export const CarouselCard = styled(SectionCardContainer).attrs({
  [dataAutomation]: AUTOMATION_SELECTORS.PAGE_INSIGHT
})`
    display: block;
    height: 100%;
    padding: 16px;
    margin: 0 ${CAROUSEL_ITEM_PADDING};
`;

export const ChartCardsRowContainer = styled.div``;
