import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  
  ${({ isClickAble }) => isClickAble && `
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  `}
`;
