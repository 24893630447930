import { eventAction, trackEvent } from '../../services/telemetry-service/telemetry-service';
import { joinTrackDelimiter } from '../../utils/string/string';
import { TRACK_IDS } from '../../constants/tracking';

export default ({ trackId }) => ({
  chart: {
    events: {
      selection: (event) => {
        if (event.resetSelection) {
          trackEvent({ action: eventAction.click, actionOn: joinTrackDelimiter([trackId, TRACK_IDS.COMMON.CHART_RESET_ZOOM]) });
        } else {
          trackEvent({ action: eventAction.click, actionOn: joinTrackDelimiter([trackId, TRACK_IDS.COMMON.CHART_ZOOM]) });
        }
      },
      redraw() {
        this.render();
      }
    }
  },
});
