import React, { useState } from 'react';
import { isEmpty, map, omit, partial } from 'lodash';
import { ErrorBoundary } from '../../../../components/ErrorBoundary/ErrorBoundary';
import {
  commitActionItem,
  dismissActionItem,
  editActionItem,
  commitActionItemV2
} from '../../network/data-services/action-item-service';
import { Container } from './StyledActionItems';
import { ActionItemCard } from './components/ActionItemCard/ActionItemCard';
import { sendEmail } from '../../../../utils/browser';
import {
  downloadMeetingEvent,
  getPreparedActionItemForCommit,
  getTaskConvertedActionItemForCommit,
  ACTION_ITEM_TYPES,
  getActionOrDefault,
} from './utils';
import { openCrmEntity, openCrmEntityEdit } from '../../../../utils/crm-links';
import {NoDataError, NoDataErrorWithImage} from '../../../../components/NoDataError';
import { TRACK_IDS } from '../../tracking';
import { eventAction } from '../../../../services/telemetry-service/consts';
import { useInfra } from '../../../../providers/InfraProvider';
import { CALL_STRINGS } from '../../strings';
import { TabLoading } from '../TabLoading';
import { FormFields } from './action-item-type-config';

const ACTION_TYPE_TO_CRM_ENTITY_TYPE = Object.freeze({
  task: 'task',
  phoneCallActivity: 'phonecall',
  email: 'email',
  meeting: 'appointment',
});

export const ActionItems = ({
  conversationId,
  onSelectActionItem,
  onHoverActionItem,
  isLoading,
  fetchData,
  actionItems,
  error,
}) => {
  const { orgUrl, t, trackError, trackEvent, isStorageTypeDataverseOnly } = useInfra();
  const [actionLoadingForId, setActionLoadingForId] = useState();
  const [actionErrorForId, setActionErrorForId] = useState();
  const [formValues, setFormValues] = useState({});
  const [peopleFields, setPeopleFields] = useState({});
  const [formDirty, setFormDirty] = useState({});
  const [expandedActionItems, setExpandedActionItems] = useState({});

  const postAction = (actionItemId, ignoreErrorReset) => {
    if (!ignoreErrorReset) {
      setActionErrorForId(null);
    }
    setFormDirty(omit(formDirty, [actionItemId]));
    setFormValues(omit(formValues, [actionItemId]));
    setPeopleFields(omit(peopleFields, [actionItemId]));
  };

  const onActionItemDismissed = async (actionItemId) => {
    trackEvent({
      overrideSource: TRACK_IDS.SOURCES.ACTION_ITEMS,
      action: eventAction.click,
      actionOn: TRACK_IDS.ACTION_ITEMS_DELETE,
      message: actionItemId,
    });
    setActionLoadingForId(actionItemId);
    setActionErrorForId(null);
    try {
      await dismissActionItem(conversationId, actionItemId, isStorageTypeDataverseOnly);
      postAction(actionItemId);
    } catch (_error) {
      setActionErrorForId(actionItemId);
      trackError({
        overrideSource: TRACK_IDS.SOURCES.ACTION_ITEMS,
        actionOn: TRACK_IDS.ACTION_ITEMS_DELETE,
        message: JSON.stringify({ actionItemId, error: _error }),
      });
    } finally {
      setActionLoadingForId(null);
    }
    await fetchData();
  };

  const onSubmitActionItem = async (options, actionItem, formData) => {
    const { shouldOpenCrm, shouldConvertToTask } = options || {};

    setActionLoadingForId(actionItem.id);
    setActionErrorForId(null);

    const updatedActionItem = shouldConvertToTask
      ? getTaskConvertedActionItemForCommit(actionItem, formData)
      : getPreparedActionItemForCommit(actionItem, formData);

    try {
      const activityId = await commit(actionItem.id, updatedActionItem, formData);
      if (shouldOpenCrm && activityId) {
        openCrmEntityEdit(orgUrl, activityId);
      }

      postAction(actionItem.id);
    } catch (_error) {
      setActionErrorForId(actionItem.id);
      trackError({
        overrideSource: TRACK_IDS.SOURCES.ACTION_ITEMS,
        actionOn: shouldOpenCrm
          ? TRACK_IDS.ACTION_ITEMS_CREATE_AND_EDIT
          : TRACK_IDS.ACTION_ITEMS_CREATE,
        message: JSON.stringify({ id: actionItem.id, error: _error }),
      });
    } finally {
      setActionLoadingForId(null);
    }

    await fetchData();
    postAction(actionItem.id, true);
    trackEvent({
      overrideSource: TRACK_IDS.SOURCES.ACTION_ITEMS,
      action: eventAction.click,
      actionOn: shouldOpenCrm
        ? TRACK_IDS.ACTION_ITEMS_CREATE_AND_EDIT
        : TRACK_IDS.ACTION_ITEMS_CREATE,
      message: JSON.stringify({ id: actionItem.id }),
    });
  };

  const commit = async (actionItemId, updatedActionItem, formData) => {
    if (isStorageTypeDataverseOnly) {
      return commitActionItemV2(conversationId, actionItemId, updatedActionItem, formData);
    }

    await editActionItem(conversationId, actionItemId, updatedActionItem);

    return commitActionItem(conversationId, actionItemId, formData);
  }

  const onEmailCommitted = async (actionItemData, formData) => {
    trackEvent({
      overrideSource: TRACK_IDS.SOURCES.ACTION_ITEMS,
      action: eventAction.click,
      actionOn: TRACK_IDS.ACTION_ITEMS_SEND_EMAIL,
      message: actionItemData.id,
    });
    await onSubmitActionItem(null, actionItemData, formData);
    const emails = map(formData.to, (item) => item.email || item.text).join(';');
    const subject = formData.subject.name;
    sendEmail(emails, { subject });
  };

  const onMeetingActionClicked = async (actionItemData, formData) => {
    trackEvent({
      overrideSource: TRACK_IDS.SOURCES.ACTION_ITEMS,
      action: eventAction.click,
      actionOn: TRACK_IDS.ACTION_ITEMS_OPEN_CALENDAR,
      message: actionItemData.id,
    });
    await onSubmitActionItem(null, actionItemData, formData);
    downloadMeetingEvent(formData);
  };

  const onCommittedActionItemClicked = async ({ id, type, linkedCrmActivityId }) => {
    if (linkedCrmActivityId) {
      trackEvent({
        overrideSource: TRACK_IDS.SOURCES.ACTION_ITEMS,
        action: eventAction.click,
        actionOn: TRACK_IDS.ACTION_ITEMS_OPEN_COMMITTED,
        message: id,
      });
      openCrmEntity(orgUrl, ACTION_TYPE_TO_CRM_ENTITY_TYPE[type], linkedCrmActivityId);
    }
  };

  const onFormChange = (actionItemId, updatedFormValues, isDirty) => {
    setFormDirty({
      ...formDirty,
      [actionItemId]: isDirty,
    });
    setFormValues({
      ...formValues,
      [actionItemId]: updatedFormValues,
    });
  };

  const onPersonFieldChanged = (actionItemId, updatedPersonField) => {
    setPeopleFields({
      ...peopleFields,
      [actionItemId]: updatedPersonField,
    });
  };

  const actionItemTypeConfig = {
    [ACTION_ITEM_TYPES.EMAIL]: {
      icon: 'Mail',
      descriptionTranslationKey: 'email',
      formFields: FormFields[ACTION_ITEM_TYPES.EMAIL],
      formActions: [
        {
          translationKey: 'open_email',
          onClick: onEmailCommitted,
        },
        ...getActionOrDefault(isStorageTypeDataverseOnly, {
          translationKey: 'create_email_activity',
          onClick: partial(onSubmitActionItem, null),
        }),
        {
          translationKey: 'create_task',
          onClick: partial(onSubmitActionItem, { shouldConvertToTask: true }),
        },
      ],
    },
    [ACTION_ITEM_TYPES.MEETING]: {
      icon: 'Group',
      descriptionTranslationKey: 'meeting',
      formFields: FormFields[ACTION_ITEM_TYPES.MEETING],
      formActions: [
        {
          translationKey: 'open_calendar',
          onClick: onMeetingActionClicked,
        },
        ...getActionOrDefault(isStorageTypeDataverseOnly, {
          translationKey: 'create_appointment_activity',
          onClick: partial(onSubmitActionItem, null),
        }),
        {
          translationKey: 'create_task',
          onClick: partial(onSubmitActionItem, { shouldConvertToTask: true }),
        },
      ],
    },
    [ACTION_ITEM_TYPES.PHONE_CALL]: {
      icon: 'Phone',
      descriptionTranslationKey: 'call',
      formFields: FormFields[ACTION_ITEM_TYPES.PHONE_CALL],
      formActions: [
        {
          translationKey: 'create',
          onClick: partial(onSubmitActionItem, null),
        },
        {
          translationKey: 'create_and_edit',
          onClick: partial(onSubmitActionItem, { shouldOpenCrm: true }),
        },
        {
          translationKey: 'create_task',
          onClick: partial(onSubmitActionItem, { shouldConvertToTask: true }),
        },
      ],
    },
    [ACTION_ITEM_TYPES.TASK]: {
      icon: 'TaskSolid',
      descriptionTranslationKey: 'task',
      formFields: FormFields[ACTION_ITEM_TYPES.TASK],
      formActions: [
        {
          translationKey: 'create',
          onClick: partial(onSubmitActionItem, null),
        },
        {
          translationKey: 'create_and_edit',
          onClick: partial(onSubmitActionItem, { shouldOpenCrm: true }),
        },
      ],
    },
  };

  if (error) {
    return (
      <NoDataError
        title={t(CALL_STRINGS.SIDE_PIVOT_ACTION_ITEMS_ERRORS_GENERAL_TITLE)}
        subtitle={t(CALL_STRINGS.SIDE_PIVOT_ACTION_ITEMS_ERRORS_GENERAL_SUBTITLE)}
      />
    );
  }

  if (isLoading && isEmpty(actionItems)) {
    return (
      <TabLoading
        title={t(CALL_STRINGS.SIDE_PIVOT_ACTION_ITEMS_LOADING_TITLE)}
        subtitle={t(CALL_STRINGS.SIDE_PIVOT_ACTION_ITEMS_LOADING_SUBTITLE)}
      />
    );
  }

  return (
    <>
      <ErrorBoundary
        fallback={
          <NoDataError
            title={t(CALL_STRINGS.SIDE_PIVOT_ACTION_ITEMS_ERRORS_GENERAL_TITLE)}
            subtitle={t(CALL_STRINGS.SIDE_PIVOT_ACTION_ITEMS_ERRORS_GENERAL_SUBTITLE)}
          />
        }
        onError={(_error, errorInfo) => {
          trackError({
            overrideSource: TRACK_IDS.SOURCES.ACTION_ITEMS,
            actionOn: TRACK_IDS.COMMON.PAGE,
            message: JSON.stringify({ error: _error, errorInfo }),
          });
        }}
      >
        <Container data-testid="actionItemsTab">
          {isEmpty(actionItems) ? (
            <NoDataErrorWithImage
              title={t(CALL_STRINGS.SIDE_PIVOT_ACTION_ITEMS_EMPTY_STATE_TITLE)}
            />
          ) : (
            map(actionItems, (actionItem) => (
              <ActionItemCard
                key={actionItem.id}
                actionItem={actionItem}
                actionItemTypeConfig={actionItemTypeConfig}
                onSelectActionItem={onSelectActionItem}
                onHoverActionItem={onHoverActionItem}
                onActionItemDismissed={() => onActionItemDismissed(actionItem.id)}
                onCommittedActionItemClicked={() => onCommittedActionItemClicked(actionItem)}
                isActionLoading={actionLoadingForId === actionItem.id}
                isActionError={actionErrorForId === actionItem.id}
                onFormChange={(newFormValues, isDirty) =>
                  onFormChange(actionItem.id, newFormValues, isDirty)
                }
                formValues={formValues[actionItem.id]}
                onPersonFieldUpdated={(newPeopleFields) =>
                  onPersonFieldChanged(actionItem.id, newPeopleFields)
                }
                overridePeopleFields={peopleFields[actionItem.id]}
                isFormDirty={formDirty[actionItem.id]}
                isExpanded={expandedActionItems[actionItem.id]}
                toggleExpansion={() =>
                  setExpandedActionItems({
                    ...expandedActionItems,
                    [actionItem.id]: !expandedActionItems[actionItem.id],
                  })
                }
              />
            ))
          )}
        </Container>
      </ErrorBoundary>
    </>
  );
};
