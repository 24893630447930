import React, { useEffect } from 'react';
import get from 'lodash/get';
import { browserUtils } from 'ci-common-ui';
import { routes } from 'pages/routing';
import { useAppNavigation } from 'hooks/use-app-navigation';
import SellerBar from '../../../../components/SellerBar';
import CustomerSentiment from '../CustomerSentiment/CustomerSentiment';
import SellerInsights from '../SellerInsights/SellerInsights';
import CallHistory from '../CallHistory/CallHistory';
import { isRequestErrorState } from '../../../../utils/error-state/error-state';
import { Container } from './StyledSellerDetails';
import useLocale from '../../../../hooks/use-locale';
import { appContext } from '../../../../utils/app-context/app-context';
import { componentLoadingTimeService } from '../../../../services/component-loading-time-service/component-loading-time-service';
import { TRACK_IDS } from '../../../../constants/tracking';

const { openChat, sendEmail } = browserUtils;

const SellerDetails = ({ isLoading, data, selectedSellerId, sellerList, selectedSellerEmail, error }) => {
  const { navigateTo } = useAppNavigation();
  const { tAbs } = useLocale('');

  useEffect(() => {
    if (!isLoading) {
      componentLoadingTimeService.end(TRACK_IDS.SOURCES.SELLER_PAGE);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isRequestErrorState(error)) {
      throw new Error();
    }
  }, [error]);

  const { user } = appContext;

  const onEmailActionClicked = () => sendEmail(
    selectedSellerEmail,
    { subject: tAbs('components.seller_bar.actions.email_subject', { name: user.name }) },
  );
  const onChatActionClicked = () => openChat(selectedSellerEmail);

  return (
    <Container>
      <SellerBar
        isLoading={isLoading}
        selectedSellerKey={selectedSellerId}
        sellerOptions={sellerList}
        kpis={get(data, 'kpis')}
        onChangeSeller={(newSellerId) => navigateTo({
          path: `${routes.sellers.link}/${newSellerId}`,
        })}
        onEmailActionClicked={onEmailActionClicked}
        onChatActionClicked={onChatActionClicked}
      />
      <SellerInsights isLoading={isLoading} data={data} />
      <CustomerSentiment isLoading={isLoading} data={data} error={error} />
      <CallHistory
        selectedSellerId={selectedSellerId}
        isCustomerSentimentPerCallLoading={isLoading}
      />
    </Container>
  );
};

export default SellerDetails;
