import styled from 'styled-components';
import { ActionButton } from '@fluentui/react';
import { rtl } from '../../../../utils/styles/rtl';
import { colors } from '../../../../constants/colors';
import { FlexRowCentered } from '../../../../utils/styles/StyledUtils';

export const DeleteWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  height: 100%;
`;

export const DeleteButton = styled(ActionButton)`
  height: 26px;
  margin: 0 -8px;

  i {
    color: ${colors.ScarletRed};
  }
`;

export const Success = styled(FlexRowCentered)`
  
  i {
    font-size: 16px;
    color: ${colors.JadeGreen};
    ${rtl`margin-right`}: 8px;
  }
`;
