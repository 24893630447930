import { IconButton } from '@fluentui/react';
import styled from 'styled-components';
import { colors } from '../../../../../../constants/colors';
import { rtl } from '../../../../../../utils/styles/rtl';
import {
  FlexColumn,
  FlexRowCentered,
  StyledText,
} from '../../../../../../utils/styles/StyledUtils';

export const Container = styled(FlexColumn)`
  ${({ isFullSize }) =>
    isFullSize &&
    `
    margin-bottom: 12px;
  `}
`;

export const HeaderContainer = styled(FlexRowCentered)`
  justify-content: space-between;
  padding: 0 18px;

  ${({ isFullSize }) =>
    isFullSize &&
    `
    padding: 0;
  `}
`;

export const TitleContainer = styled.div``;

export const Title = styled(StyledText)`
  font-weight: 600;
  ${rtl`margin-right`}: 6px;
`;

export const Content = styled(StyledText)`
  max-width: 600px;
  display: block;
`;

export const chevronButtonStyles = {
  root: {
    cursor: 'pointer',
    fontSize: '10px',
  },
};

export const chevronIconStyles = {
  root: {
    fontSize: '12px',
  },
};

export const SuggestionContainer = styled.div`
  padding: 8px 8px 16px 8px;
  margin: 0 8px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.Gray30}`};
  transition: background-color 150ms ease;
  cursor: pointer;

  ${({ isFullSize }) =>
    isFullSize &&
    `
    margin: 0;
  `}

  :hover {
    background-color: ${({ theme }) => theme.colors.Gray20};
  }
`;

export const FooterSuggestionGroup = styled(FlexRowCentered)`
justify-content: space-between;
padding-top: 4px;
`;

export const getShowAllStyles = (isHidden) => ({
  root: {
    visibility: isHidden ? 'hidden' : 'visible',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '8px',
    marginBottom: '20px',
    selectors: {
      ':hover': {
        textDecoration: 'none',
      },
    },
  },
});

export const SuggestionActions = styled(FlexRowCentered)`
  margin-top: 8px;
  font-size: 12px;
`;

export const timestampStyles = ({ theme }) => ({
  root: {
    color: `${theme.palette.neutralPrimary} !important`,
    textDecoration: 'underline',
    selectors: {
      ':hover': {
        color: `${theme.palette.themePrimary} !important`,
      },
    },
  },
});

export const ActionMenuButton = styled(IconButton)`
  .ms-Button-menuIcon {
    display: none;
  }
`;

export const PartnerHighlightContainer = styled(FlexRowCentered)``;

export const partnerImageStyles = {
  image: { width: '20px', height: '20px', marginRight: '4px' },
};

export const moreIconStyles = {
  root: {
    color: colors.Gray160,
    fontSize: '12px',
    selectors: {
      ':hover': {
        color: colors.DYNPrimary,
      },
    },
  },
};

export const addSuggestionIconStyles = {
  root: {
    margin: '0px 4px 0 0',
    fontSize: '12px',
  },
};

export const addedSuggestionIconStyles = {
  root: {
    margin: '0px 4px 0 0',
    fontSize: '12px',
    color: colors.DynamicsGreen,
  },
};

export const dividerStyles = {
  root: {
    margin: '0 8px 0 4px',
  },
};

export const createTaskDividerStyles = {
  root: {
    margin: '0 6px',
  },
};

export const getActionButtonStyles = (isAdded) => ({
  root: {
    height: 'auto',
    fontSize: '12px',
    padding: 0,
  },
  label: {
    selectors: {
      ':hover': {
        color: isAdded && colors.Gray160,
      },
    },
  },
});

export const addSuggestionIconProps = { iconName: 'Add', styles: addSuggestionIconStyles };

export const addedSuggestionIconProps = {
  iconName: 'CheckMark',
  styles: addedSuggestionIconStyles,
};

export const moreIconProps = { iconName: 'More', styles: moreIconStyles };

export const getChevronIconProps = (isCollapsed) => ({
  styles: chevronIconStyles,
  iconName: isCollapsed ? 'ChevronDown' : 'ChevronUp',
});
