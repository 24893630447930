import { get } from 'lodash';
import React from 'react';
import CrmEntitiesLinks from '../../../../components/CrmEntitiesLinks/CrmEntitiesLinks';
import { callTypeColumn, sentimentColumn, callTopicColumn, dateAndDurationColumn, crmEntitiesLinksColumn, tagsColumn, commentsCountColumn } from '../../../../components/Table/default-columns';

export const columns = [
  {
    ...callTypeColumn,
    fieldName: 'callType',
  },
  {
    ...callTopicColumn,
    fieldName: 'callTopic'
  },
  {
    ...sentimentColumn,
    fieldName: 'customerSentiment'
  },
  {
    ...tagsColumn,
    fieldName: 'tags'
  },
  {
    ...commentsCountColumn,
    fieldName: 'commentsCount'
  },
  {
    ...dateAndDurationColumn,
    fieldName: 'dateAndDuration'
  },
  {
    ...crmEntitiesLinksColumn,
    fieldName: 'crmEntitiesLinks',
    CellComponent: ({ data }) => <CrmEntitiesLinks data={{ ...get(data, 'crm'), id: get(data, 'id') }} isNewSchema />
  }
];
