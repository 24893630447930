import styled from 'styled-components';
import { Slider } from '@fluentui/react';
import { rtl } from '../../../../utils/styles/rtl';
import { durationToTime } from '../../../../utils/date/date';
import { timelineLeftMargin } from '../Timeline/StyledTimeline';

const HEIGHT_TRANSITION = 'height 0.3s ease';

export const TimelineSliderContainer = styled.div`
  height: 100px;
  position: absolute;
  top: 36px;
  ${rtl`
    right: 0;
    left: ${timelineLeftMargin};
  `}
  z-index: 4;
    transition: ${HEIGHT_TRANSITION};

   ${({ height }) => height && `
    height: ${height}px;
  `}
  
`;

export const StyledTimelineSlider = styled(Slider)`
  .ms-Slider-slideBox {
    padding: 0;
    :hover, :active {
      .ms-Slider-thumb {
        border-radius: 2px;
        border-width: 1px;
        border-color: ${({ theme }) => theme.colors.DYNPrimary};
      }
  }
`;

export const StyledTimeLabel = styled.div`
  position: absolute;
  top: -52px;
  ${rtl`left`}: ${({ location }) => location}px;
  background: ${({ theme }) => theme.colors.White};
  z-index: 3;
  min-width: auto;
  transform: translateX(-50%);
  padding: 5px 7px 4px 7px;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.065);
  transition: box-shadow 0.5s ease 0s;
`;

const topMargin = 25;

export const timelineSliderStyles = (value, height, isRTL) => ({ theme }) => ({
  root: {
    height: '100%',
    cursor: 'pointer'
  },
  container: {
    height: '100%'
  },
  lineContainer: {
    display: 'hidden'
  },
  line: {
    height,
    transition: HEIGHT_TRANSITION,
    display: 'hidden'
  },
  thumb: {
    height: height + topMargin,
    transition: HEIGHT_TRANSITION,
    width: '0px',
    top: `-${topMargin}px`,
    transform: 'none',
    borderRadius: '2px',
    borderWidth: '1px',
    borderColor: theme.palette.themePrimary,
    zIndex: '2',
    selectors: {
      ':after': {
        position: 'absolute',
        transform: `translateX(${isRTL ? 50 : -50}%)`,
        top: '-28px',
        content: `"${durationToTime(value)}"`,
        backgroundColor: theme.palette.themePrimary,
        color: theme.palette.white,
        padding: '0px 6px',
        borderRadius: '4px'
      },
      ':before': {
        position: 'absolute',
        transform: `translateX(${isRTL ? 50 : -50}%)`,
        top: 0,
        content: '""',
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
        borderTop: `5px solid ${theme.palette.themePrimary}`
      },
    }
  }
});
