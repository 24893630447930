import React, { useState, useEffect, useRef } from 'react';
import size from 'lodash/size';
import first from 'lodash/first';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import { Tooltip } from 'ci-common-ui';
import useLocale from '../../../../../../../hooks/use-locale';
import { AddNewMappingContainer as Container, ExpandAddNewMapping as Expand, WillViewAsText, AddMappingButton } from '../StyledViewAs';
import { Picker } from './Picker';
import { getAllOnboardedUsers } from '../../../../../../../network/data-services/settings';
import { appContext } from '../../../../../../../utils/app-context/app-context';
import { calcExpiryDate, getMappingFromPickerSchema, toPickerSchema } from '../utils';
import { TimeLimitDropDown } from './TimeLimitDropDown';
import { eventAction, trackEvent } from '../../../../../../../services/telemetry-service/telemetry-service';
import { TRACK_IDS } from '../../../../../../../constants/tracking';

export const AddNewMapping = ({ onAddMapping, allMappedSourceUsers, isLoading }) => {
  const { t } = useLocale('pages.settings.your_team.view_as');
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedTimeLimit, setSelectedTimeLimit] = useState(null);
  const [sourceSelectedUsers, setSourceSelectedUsers] = useState([]);
  const [targetSelectedUsers, setTargetSelectedUsers] = useState([]);
  const sourceUserPickerRef = useRef(null);
  const targetUserPickerRef = useRef(null);
  const addButtonRef = useRef(null);
  const timeLimitRef = useRef(null);
  const isSourceUserPickerFilled = size(sourceSelectedUsers) === 1;
  const isTargetUserPickerFilled = size(targetSelectedUsers) === 1;
  const isAddMappingEnabled = isSourceUserPickerFilled && isTargetUserPickerFilled && selectedTimeLimit;
  const areBothPickersEmpty = !isSourceUserPickerFilled && !isTargetUserPickerFilled;
  const expandAddNewMapping = () => {
    setIsExpanded(true);
    trackEvent({
      action: eventAction.click,
      actionOn: TRACK_IDS.VIEW_AS_EXPAND_ADDING_BAR
    });
  };

  useEffect(() => {
    if (isSourceUserPickerFilled) {
      targetUserPickerRef?.current?.focus();
    } else if (areBothPickersEmpty) {
      sourceUserPickerRef?.current?.focus();
    }
  }, [sourceSelectedUsers]);

  useEffect(() => {
    if (isTargetUserPickerFilled) {
      timeLimitRef?.current?.focus();
    }
  }, [targetSelectedUsers]);

  useEffect(() => {
    if (isTargetUserPickerFilled) {
      addButtonRef?.current?.focus();
    }
  }, [selectedTimeLimit]);

  useEffect(() => {
    if (isExpanded) {
      sourceUserPickerRef?.current?.focus();
    }
  }, [isExpanded]);

  const onAddMappingClick = () => {
    const sourceUser = first(sourceSelectedUsers);
    const targetUser = first(targetSelectedUsers);
    const expiryDate = calcExpiryDate(selectedTimeLimit);
    onAddMapping(getMappingFromPickerSchema(sourceUser, targetUser, expiryDate));
    setSourceSelectedUsers([]);
    setTargetSelectedUsers([]);
    setSelectedTimeLimit(null);
    trackEvent({
      action: eventAction.click,
      actionOn: TRACK_IDS.VIEW_AS_ADD_NEW_MAPPING,
      message: { sourceUser: sourceUser?.aadId, targetUser: targetUser?.aadId, expiryDate }
    });
  };

  const searchUserBase = async (filterText, filterFunc) => {
    const { users } = await getAllOnboardedUsers(appContext.orgId, { filterText });
    const filteredUsers = filter(users, filterFunc);
    return map(filteredUsers, toPickerSchema);
  };

  const searchSourceUsers = async (filterText) => {
    trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.VIEW_AS_SEARCH_USER, message: 'source user' });
    const userNotYetViewsAs = (aadId) => !find(allMappedSourceUsers, ({ aadId }));
    const userNotInCurrentTarget = (aadId) => !find(targetSelectedUsers, ({ aadId }));
    const isUserValidToSource = ({ aadId }) => userNotYetViewsAs(aadId) && userNotInCurrentTarget(aadId);

    return searchUserBase(filterText, isUserValidToSource);
  };

  const searchTargetUsers = async (filterText) => {
    trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.VIEW_AS_SEARCH_USER, message: 'target user' });
    const userNotInCurrentSource = (aadId) => !find(sourceSelectedUsers, ({ aadId }));
    const isUserValidToTarget = ({ aadId }) => userNotInCurrentSource(aadId);

    return searchUserBase(filterText, isUserValidToTarget);
  };

  if (!isExpanded) {
    return <Expand onClick={expandAddNewMapping} disabled={isLoading}>{t('add_user')}</Expand>;
  }

  return (
    <Container>
      <Picker componentRef={sourceUserPickerRef} selectedUsers={sourceSelectedUsers} setSelectedUsers={setSourceSelectedUsers} searchUsers={searchSourceUsers} />
      <WillViewAsText>{t('will_view_as')}</WillViewAsText>
      <Picker componentRef={targetUserPickerRef} selectedUsers={targetSelectedUsers} setSelectedUsers={setTargetSelectedUsers} searchUsers={searchTargetUsers} />
      <Tooltip content={t('time_limit_tooltip')}>
        <TimeLimitDropDown text={selectedTimeLimit?.text || t('time_limit')} selectedTimeLimit={selectedTimeLimit} onUpdateExpiryDate={setSelectedTimeLimit} timeLimitRef={timeLimitRef} />
      </Tooltip>
      <AddMappingButton componentRef={addButtonRef} disabled={!isAddMappingEnabled} onClick={onAddMappingClick}>{t('add')}</AddMappingButton>
    </Container>
  );
};
