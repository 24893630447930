import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import { dayJS, formatDate } from '../../utils/date/date';
import { viewAsExpiryDateFormat } from '../../pages/SettingsModal/components/Tabs/YourTeam/ViewAs/utils';

export const transformSettings = async (data) => {
  const {
    id,
    url,
    name,
    hierarchyDepth,
    topPerformers,
    readOnlyAccessToData,
    trackers,
    competitors,
    locales,
    supportedLocales,
    retentionPeriod,
    storageConnectionString,
    storageContainerName,
    storageType,
    storageName,
    useInternalStorage,
    viewAs
  } = data;

  return {
    id,
    url,
    name,
    hierarchy: hierarchyDepth,
    topPerformers,
    retentionPolicy: retentionPeriod,
    dataSource: {
      connectionString: storageConnectionString,
      containerName: storageContainerName,
      storageName,
    },
    keywords: { trackers, competitors, languages: locales },
    supportedLanguages: supportedLocales,
    isEyesOnData: readOnlyAccessToData,
    storageType,
    useInternalStorage,
    viewAs: transformViewAs(viewAs)
  };
};

export const transformSettingsBeforeSend = (values, { isSeller, isManager }) => {
  const { id, url, name, hierarchy, topPerformers, keywords, dataSource, retentionPolicy, isEyesOnData, useInternalStorage } = values;

  const commonParams = { id, url, name, isDefault: true };

  if (isSeller) {
    return commonParams;
  }

  const managerAdminParams = {
    hierarchyDepth: hierarchy,
    trackers: keywords.trackers,
    competitors: keywords.competitors,
    locales: keywords.languages,
    topPerformers,
    topPerformersFilterType: 'Manual',
  };

  if (isManager) {
    return {
      ...commonParams,
      ...managerAdminParams
    };
  }

  return {
    ...commonParams,
    ...managerAdminParams,
    retentionPeriod: retentionPolicy,
    storageConnectionString: useInternalStorage ? undefined : dataSource.connectionString,
    storageContainerName: useInternalStorage ? undefined : dataSource.containerName,
    readOnlyAccessToData: isEyesOnData,
    useInternalStorage,
  };
};

function transformViewAs(data) {
  if (!data) return [];
  const { configuredViewAs: mappings } = data;
  const onlyNonExpiredMappings = filter(mappings, ({ expiryDate }) => !isViewAsDateExpired(expiryDate));
  return sortBy(onlyNonExpiredMappings,
    [
      ({ sourceUser }) => sourceUser.fullName.toLowerCase(),
      ({ targetUser }) => targetUser.fullName.toLowerCase()
    ]);
}

export function isViewAsDateExpired(expiryDate) {
  if (!expiryDate) {
    return false;
  }

  if (!isDateInViewAsFormat(expiryDate)) {
    throw new Error(`date must be in ${viewAsExpiryDateFormat} format, or falsy value`);
  }

  const todayLocal = dayJS();
  const todayWithoutHours = formatDate(todayLocal, viewAsExpiryDateFormat);
  const todayMinusDate = dayJS(todayWithoutHours).diff(expiryDate, 'day');
  const expiryDateIsInPast = todayMinusDate > 0;
  return expiryDateIsInPast;
}

function isDateInViewAsFormat(expiryDate) {
  const dateInCorrectFomrat = dayJS(expiryDate).format(viewAsExpiryDateFormat);
  return expiryDate === dateInCorrectFomrat;
}
