import React from 'react';
import { get, isEmpty } from 'lodash';
import {
  TabContainer,
  TabContent,
  ResetSegmentBar,
  ResetSegmentIcon,
  ResetSegmentTitle,
  ResetSegmentButton,
  segmentTitleTooltipStyles,
} from '../../StyledSidePivot';
import { useInfra } from '../../../../../../providers/InfraProvider';
import { CALL_STRINGS } from '../../../../strings';
import { Tooltip } from '../../../../../../components/Tooltip';

const ResetSegment = ({ selectedSegment, clearSelectedSegment }) => {
  const { t } = useInfra();

  const title = get(selectedSegment, 'title');

  return (
    <ResetSegmentBar open={!isEmpty(selectedSegment)} data-testid="resetSegmentBar">
      <ResetSegmentIcon />
      <Tooltip styles={segmentTitleTooltipStyles} content={title}>
        <ResetSegmentTitle>{title}</ResetSegmentTitle>
      </Tooltip>
      <ResetSegmentButton onClick={clearSelectedSegment}>
        {t(CALL_STRINGS.CLEAR)}
      </ResetSegmentButton>
    </ResetSegmentBar>
  );
};

export const PivotItemContainer = ({ selectedSegment, clearSelectedSegment, children }) => (
  <TabContainer data-testid="pivotItemConainer">
    <ResetSegment selectedSegment={selectedSegment} clearSelectedSegment={clearSelectedSegment} />
    <TabContent isSegmentOpen={!isEmpty(selectedSegment)}>{children}</TabContent>
  </TabContainer>
);
