import styled from 'styled-components';
import { colors, FlexRowCentered, rtl } from 'ci-common-ui';
import { Spinner, MessageBar, MessageBarType } from '@fluentui/react';

export const Root = styled.div`
  height: 100%;
`;

export const Container = styled.div`
  height: 100%;
  overflow-y: auto;
  border-top: 1px solid ${colors.Gray300};
`;

export const Loading = styled(Spinner)`
  margin-top: 50px;
`;

export const Header = styled(FlexRowCentered)`
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  padding: 0 20px;
  ${rtl`padding-right: 10px`};
  height: 48px;
`;

export const Error = styled(MessageBar).attrs({
  messageBarType: MessageBarType.error,
})`
`;
