const SAADeprication = 'SAADeprication';
const LicenseUsage = 'LicenseUsage';
const Provision = 'Provision';
const ViewingAs = 'ViewingAs';
const ComplianceBoundary = 'ComplianceBoundary';

export const INFO = 'INFO';
export const DEFAULT = 'DEFAULT';
export const WARNING = 'WARNING';
export const ERROR = 'ERROR';

export const notificationBarsTypes = {
  SAADeprication,
  LicenseUsage,
  Provision,
  ViewingAs,
  ComplianceBoundary
};
