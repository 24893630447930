import { useState, useEffect } from 'react';
import { getIsMobile, getIsBigMobile, getIsTablet, getIsDesktop } from '../utils/styles/device-sizes';

export const useResize = () => {
  const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const { width, height } = size;

  useEffect(() => {
    const handleResize = () => setSize({ width: window.innerWidth, height: window.innerHeight });
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    width,
    height,
    isMobile: getIsMobile(width),
    isBigMobile: getIsBigMobile(width),
    isTablet: getIsTablet(width),
    isDesktop: getIsDesktop(width)
  };
};
