import styled from 'styled-components';
import { Spinner, SpinnerSize } from '@fluentui/react';
import React from 'react';

export const MODAL_STYLES = {
  main: {
    width: '640px',
    height: '500px'
  }
};

export const StyledIframe = styled.iframe`
  ${({ isLoading }) => isLoading && 'display: none;'}
`;

export const Loader = <Spinner styles={{ root: { height: '100%' } }} size={SpinnerSize.large} />;
