import { filtersUtils, PARTICIPANTS_ROLES } from 'ci-common-ui';
import { filter, map } from 'lodash';
import { getIsDemoUser } from '../../utils/app-context/app-context';

export const { calculateDiffPercentage, calcTalkToListenRatio, transformTags, transformCrm, getFirstNonEmptyCustomerDetails, isEmptyOrFalsy } = filtersUtils;

export const getContactsFromParticipantsObject = (participants) => {
  const allCustomers = filter(participants, ({ typeId, role }) => [typeId, role].includes(PARTICIPANTS_ROLES.customer));
  const customersWithCrmId = filter(allCustomers, ({ crmId, displayName }) => !isEmptyOrFalsy(crmId) || (getIsDemoUser() && displayName));
  const contacts = map(customersWithCrmId, ({ crmId, displayName }) => ({ id: crmId, name: displayName }));
  return contacts;
};

export const SHOULD_PRESENT_INSIGHT = 'shouldPresentInsight';
