import styled from 'styled-components';
import { ActionButton } from '@fluentui/react';
import { FontSizes } from '@uifabric/fluent-theme';
import { FlexRow } from 'ci-common-ui';

export const SellerActionsContainer = styled(FlexRow)`
  align-content: center;
`;

export const Action = styled(ActionButton).attrs(({ iconName }) => ({
  iconProps: { iconName }
}))`
  font-size: ${FontSizes.size14};
  text-transform: capitalize;
  height: 20px;
`;
