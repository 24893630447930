import StackTrace from 'stacktrace-js';

const toPrettyStackTrace = (stackFrames) => stackFrames.map((sf) => sf.toString());

export const prettifyStackTrace = async (error) => {
  let err;
  try {
    const prettyError = await StackTrace.fromError(error).then(toPrettyStackTrace);
    err = [`${error.name || 'Error'}: ${error.message || ''}`].concat(prettyError);
  } catch (e) {
    err = error;
  }
  return err;
};

export const cloneErrorObj = (err = {}) => {
  const plainObject = {};
  Object.getOwnPropertyNames(err).forEach((key) => {
    plainObject[key] = err[key];
  });
  return plainObject;
};
