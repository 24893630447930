import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import useLocale from 'hooks/use-locale';
import { IconButton } from '@fluentui/react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import size from 'lodash/size';
import { Content, Root, Title, TitleText } from './StyledWizard';
import { TRACK_IDS } from '../../constants/tracking';
import Stepper from './Stepper/Stepper';
import { trackEvent } from '../../services/telemetry-service/telemetry-service';
import { eventAction } from '../../services/telemetry-service/consts';
import { joinTrackDelimiter } from '../../utils/string/string';

const getCurrentStepName = (steps, stepIndex) => get(steps, `[${stepIndex}].name`);

const Wizard = ({ initialValues, getAvailableSteps, closeModal, translationPrefix, trackingSource, title, onSettingsChanged, onFinish }) => {
  const { t, tAbsDefault } = useLocale(translationPrefix);
  const [{ stepIndex, prevStepIndex }, setStep] = useState({ stepIndex: 0, prevStepIndex: 0 });
  const [settings, setSettings] = useState(initialValues || {});

  const availableSteps = map(getAvailableSteps(settings, stepIndex), (step) => ({
    ...step,
    title: t(`${step.name}.${step.navTitle || 'title'}`)
  }));

  const step = availableSteps[stepIndex] || {};
  const { component: StepComponent, shouldSkip } = step;
  const skip = shouldSkip && shouldSkip(settings);

  const next = (isAutoSkipped) => {
    trackEvent({
      overrideSource: trackingSource,
      actionOn: joinTrackDelimiter([TRACK_IDS.COMMON.NEXT_BUTTON, isAutoSkipped && TRACK_IDS.COMMON.AUTO_SKIP]),
      action: eventAction.click,
      message: getCurrentStepName(availableSteps, stepIndex)
    });
    setStep({ stepIndex: stepIndex + 1, prevStepIndex: stepIndex });
  };
  const back = () => {
    trackEvent({
      overrideSource: trackingSource,
      actionOn: TRACK_IDS.COMMON.BACK_BUTTON,
      action: eventAction.click,
      message: getCurrentStepName(availableSteps, stepIndex)
    });
    setStep({ stepIndex: stepIndex - 1, prevStepIndex: stepIndex });
  };

  useEffect(() => {
    if (size(availableSteps) === stepIndex && onFinish) {
      onFinish(settings);
      trackEvent({
        overrideSource: trackingSource,
        actionOn: TRACK_IDS.COMMON.FINISH_BUTTON,
        action: eventAction.click,
        message: settings
      });
    }
    if (skip && !isEmpty(step)) {
      const isBack = prevStepIndex > stepIndex;
      if (isBack) {
        back();
      } else {
        next(true);
      }
    }
  }, [stepIndex]);

  if (isEmpty(step) || skip) {
    return null;
  }

  const handleSettings = (data, override) => {
    const newData = override ? data : { ...settings, ...data };
    setSettings(newData);
    if (onSettingsChanged) {
      onSettingsChanged(newData);
    }
  };

  return (
    <Root>
      <Title>
        <TitleText>{title || t(`${step.name}.${step.navTitle || 'title'}`)}</TitleText>
        <IconButton iconProps={{ iconName: 'Cancel' }} ariaLabel={tAbsDefault('common.close')} onClick={closeModal} />
      </Title>
      <Content>
        <Stepper steps={availableSteps} current={stepIndex} settings={settings} translationPrefix={translationPrefix} />
        <StepComponent
          settings={settings}
          handleSettings={handleSettings}
          next={next}
          back={back}
          closeModal={closeModal}
          trackingSource={trackingSource}
          translationPrefix={`${translationPrefix}.${step.name}`}
        />
      </Content>
    </Root>
  );
};

export default Wizard;
