import styled from 'styled-components';
import { FlexColumn, StyledText, colors, rtl } from 'ci-common-ui';
import { FontSizes } from '@uifabric/fluent-theme';

export const Container = styled(FlexColumn)`
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 52px;
`;

export const LoaderImage = styled.img`
  width: 190px;
`;

export const Title = styled(StyledText)`
  font-size: ${FontSizes.size16};
  margin-top: 26px;
  font-weight: 600;
`;

export const Description = styled.div`
  font-size: ${FontSizes.size14};
  margin-top: 30px;
  margin-bottom: 23px;
  text-align: center;
`;

export const UploadProgressContainer = styled.div`
  font-size: ${FontSizes.size14};
  margin-top: 30px;
  margin-bottom: 23px;
  text-align: center;
`;

export const ProgressTitle = styled(StyledText)`
  font-size: ${FontSizes.size14};
  margin-top: 26px;
  font-weight: 600;
  ${rtl`margin-right: 4px;`}
`;

export const FailedUploads = styled(StyledText)`
  font-size: ${FontSizes.size14};
  color: ${colors.Gray130};
`;

export const ProgressSubtitle = styled(StyledText)`
  font-size: ${FontSizes.size12};
  color: ${colors.Gray130}; 
`;

export const trialDataUploaderModalStyles = {
  main: {
    maxWidth: 724,
    maxHeight: 452,
  },
};

export const relatedOpportunityModalStyles = {
  main: {
    maxWidth: 724,
    minHeight: 500,
    height: 'auto',
  },
};

const PROGRESS_BAR_HEIGHT = '4px';
export const progressIndicatorStyles = {
  root: {
    width: '100%',
    marginTop: '34px'
  },
  itemProgress: {
    height: PROGRESS_BAR_HEIGHT
  },
  progressBar: {
    height: PROGRESS_BAR_HEIGHT
  },
  progressTrack: {
    height: PROGRESS_BAR_HEIGHT
  }
};
