import { SUPPORTED_CALL_LOCALES } from 'ci-common-ui';

export const defaultCallLanguage = SUPPORTED_CALL_LOCALES[0].key;

export const INITIAL_SELLERS = [
  {
    nameTranslationKey: 'default_seller_1',
    key: 1,
    defaultCallLanguage,
    files: [],
  },
  {
    nameTranslationKey: 'default_seller_2',
    key: 2,
    defaultCallLanguage,
    files: [],
  },
  {
    nameTranslationKey: 'default_seller_3',
    key: 3,
    defaultCallLanguage,
    files: [],
  },
];
