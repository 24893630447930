import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import size from 'lodash/size';
import useLocale from 'hooks/use-locale';
import useEmptyState from 'utils/empty-state/use-empty-state';
import PageSection from '../../../../components/PageSection/PageSection';
import { createOvertimeConfig } from './chart-configs';
import ChartCardsRow from '../../../../components/ChartCardsRow/ChartCardsRow';
import { joinTrackDelimiter } from '../../../../../utils/string/string';
import { TRACK_IDS } from '../../../../../constants/tracking';

const LOCALIZATION_PREFIX = 'pages.insight.keywords_section';

const KeywordsSection = ({ isLoading, data, title, subtitle, error }) => {
  const { t } = useLocale(LOCALIZATION_PREFIX);
  const { getEmptyStateDetails } = useEmptyState(LOCALIZATION_PREFIX);

  const totalMentionsOverTime = get(data, 'totalMentionsOverTime');
  const totalConversationsOverTime = get(data, 'totalConversationsOverTime');

  const isTotalMentionsOverTimeHasOnlyOneItem = size(get(totalMentionsOverTime, '[0].data')) === 1;
  const isTotalMentionsOverTimeEmpty = isEmpty(totalMentionsOverTime) || isTotalMentionsOverTimeHasOnlyOneItem;
  const isTotalConversationsOverTimeHasOnlyOneItem = size(get(totalConversationsOverTime, '[0].data')) === 1;
  const isTotalConversationsOverTimeEmpty = isEmpty(totalConversationsOverTime) || isTotalConversationsOverTimeHasOnlyOneItem;

  const cards = [{
    trackId: joinTrackDelimiter([TRACK_IDS.COMMON.MENTIONS, TRACK_IDS.COMMON.OVER_TIME]),
    header: t('mentions.title'),
    csvData: !isTotalMentionsOverTimeEmpty && Array.isArray(totalMentionsOverTime) && totalMentionsOverTime.map((item) => omit(item, 'data')),
    getChartConfig: () => createOvertimeConfig(totalMentionsOverTime),
    hasError: get(error, 'totalMentionsOverTime'),
    emptyStateDetails: isTotalMentionsOverTimeEmpty && getEmptyStateDetails('mentions', isTotalMentionsOverTimeHasOnlyOneItem ? 'only_one_item' : '')
  }, {
    trackId: joinTrackDelimiter([TRACK_IDS.COMMON.CONVERSATIONS, TRACK_IDS.COMMON.OVER_TIME]),
    header: t('conversations.title'),
    csvData: !isTotalConversationsOverTimeEmpty && Array.isArray(totalConversationsOverTime) && totalConversationsOverTime.map((item) => omit(item, 'data')),
    getChartConfig: () => createOvertimeConfig(totalConversationsOverTime),
    hasError: get(error, 'totalConversationsOverTime'),
    emptyStateDetails: isTotalConversationsOverTimeEmpty && getEmptyStateDetails('conversations', isTotalConversationsOverTimeHasOnlyOneItem ? 'only_one_item' : '')
  }];

  return (
    <PageSection title={title} subtitle={subtitle} isLoading={isLoading}>
      <ChartCardsRow isLoading={isLoading} cards={cards} />
    </PageSection>
  );
};

export default KeywordsSection;
