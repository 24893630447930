import React, { useEffect, useState } from 'react';
import forEach from 'lodash/forEach';
import sumBy from 'lodash/sumBy';
import size from 'lodash/size';
import TrialSetupLoaderImage from 'assets/images/trial-setup-loader.png';
import { Trans } from 'ci-common-ui';
import { PrimaryButton, ProgressIndicator } from '@fluentui/react';
import { Container, Description, LoaderImage, ProgressSubtitle, progressIndicatorStyles, Title, ProgressTitle, FailedUploads } from './StyledTrialDataUploader';
import useLocale from '../../../hooks/use-locale';
import { useAppState } from '../../../providers/AppStateProvider';
import { uploadCall } from '../../../network/data-services/trial-service';
import { useAppNavigation } from '../../../hooks/use-app-navigation';
import { routes } from '../../routing';
import { resetTheApp } from '../../../utils/app-context/app-context';
import { trackEvent } from '../../../services/telemetry-service/telemetry-service';
import { TRACK_IDS } from '../../../constants/tracking';
import { eventAction } from '../../../services/telemetry-service/consts';

const LOCALIZATION_PREFIX = 'pages.trial_data_uploader';

const TrialDataUploader = ({ closeModal }) => {
  const { t, appModeAwareTerms } = useLocale(LOCALIZATION_PREFIX);
  const { appState: { trialSetupConfig: { contactEmail, sellers, orgId, shouldRefreshAppAfterFinish } } } = useAppState();
  const [uploadedCalls, setUploadedCalls] = useState(0);
  const [failedUploads, setFailedUploads] = useState(0);
  const [isUploadDone, setIsUploadDone] = useState(false);
  const { navigateTo } = useAppNavigation();
  const totalCalls = sumBy(sellers, ({ files }) => size(files));

  useEffect(() => {
    forEach(sellers, ({ agentAADId, agentCrmId, files }) => {
      forEach(files, async ({ file, callName, language }) => {
        try {
          await uploadCall({
            agentAADId,
            agentCrmId,
            file,
            callName,
            orgId,
            locale: language,
            contactEmail,
          });
        } catch (err) {
          setFailedUploads((prevFailedUploads) => prevFailedUploads + 1);
        }
        setUploadedCalls((prevUploadedCalls) => prevUploadedCalls + 1);
      });
    });
  }, []);

  useEffect(() => {
    if (uploadedCalls === totalCalls) {
      setIsUploadDone(true);
      if (shouldRefreshAppAfterFinish) {
        resetTheApp();
      }
    }
  }, [uploadedCalls]);

  const onMonitorClicked = () => {
    trackEvent({
      overrideSource: TRACK_IDS.SOURCES.TRIAL_SETUP_MODAL,
      actionOn: TRACK_IDS.TRIAL_DATA_UPLOADER_MONITOR_CALLS,
      action: eventAction.click
    });
    closeModal();
    navigateTo({ path: routes.systemMonitoring.link });
  };

  const stateTextLocalizationKey = isUploadDone ? 'upload_done' : 'in_progress';
  return (
    <Container>
      <LoaderImage src={TrialSetupLoaderImage} />
      <Title>{t(`${stateTextLocalizationKey}.title`)}</Title>
      <Description>
        {
          isUploadDone ? (
            <Trans i18nKey={`${LOCALIZATION_PREFIX}.${stateTextLocalizationKey}.description`} values={{ _app_name_term: appModeAwareTerms._app_name_term }}>
              f1 <br /> f3 <strong>{{ contactEmail }}</strong> f5 <br /> f7
            </Trans>
          ) : (
            <Trans i18nKey={`${LOCALIZATION_PREFIX}.${stateTextLocalizationKey}.description`} values={{ _app_name_term: appModeAwareTerms._app_name_term }}>
              f1 <strong>{{ contactEmail }}</strong> f3 <br /> f5
            </Trans>
          )
        }
      </Description>
      <PrimaryButton
        type="submit"
        disabled={!isUploadDone}
        text={t('monitor_calls_processing')}
        onClick={onMonitorClicked}
      />
      <ProgressIndicator
        label={(
          <>
            <ProgressTitle>{t(`${stateTextLocalizationKey}.progress_indicator_title`)}</ProgressTitle>
            {
              isUploadDone && failedUploads !== 0 && <FailedUploads>{t('failed_uploads_note', { count: failedUploads })}</FailedUploads>
            }
          </>
        )}
        description={
          t('progress_indicator_description', { uploadedCallsCount: (uploadedCalls - failedUploads), totalCalls })
        }
        percentComplete={(uploadedCalls - failedUploads) / totalCalls}
        styles={progressIndicatorStyles}
      />
      <ProgressSubtitle />
    </Container>
  );
};

export default TrialDataUploader;
