import styled from 'styled-components';
import { colors } from '../../../../constants/colors';
import { FlexRow } from '../../../../utils/styles/StyledUtils';

export const CommentEditorContainer = styled.div``;

export const NewCommentActions = styled(FlexRow)``;

export const saveButtonStyles = {
  icon: {
    color: colors.DYNPrimary,
  }
};

export const cancelButtonStyles = {
  icon: {
    color: colors.Red10,
  }
};

const COMMENT_BOX_INITIAL_HEIGHT = '36px';

export const commentTextFieldStyles = {
  field: {
    'background-color': colors.White,
    'box-sizing': 'border-box',
    'border-radius': '3px',
    height: COMMENT_BOX_INITIAL_HEIGHT,
    padding: '8px',
  },
  fieldGroup: {
    'min-height': COMMENT_BOX_INITIAL_HEIGHT,
  }
};
