import React from 'react';
import { Body, Header, SectionCardContainer } from './StyledSectionCard';
import SectionCardChartLoader from './components/SectionCardChartLoader/SectionCardChartLoader';
import NoDataError from './components/NoDataError/NoDataError';
import withEmptyState from '../../../components/WithEmptyState/with-empty-state';
import { ExportToCSV } from '../../../components/ExportToCSV/ExportToCSV';

const SectionCard = ({
  header,
  csvData,
  isLoading,
  loadingComponent,
  errorComponent,
  className,
  scrollViewName,
  children,
  renderContent,
  trackId,
  shimmerHeight,
}) => {
  const loader = loadingComponent || <SectionCardChartLoader shimmerHeight={shimmerHeight} />;
  const error = errorComponent || <NoDataError />;
  if (isLoading) return loader;

  const content = !errorComponent && (children || renderContent());

  return (
    <SectionCardContainer
      className={className}
      role="region"
      aria-label={header}
      data-view-content={scrollViewName}
    >
      {!!header && (
        <Header>
          <span>{header}</span>
          <ExportToCSV data={csvData} trackId={trackId} />
        </Header>
      )}
      <Body hasHeader={header}>{content || error}</Body>
    </SectionCardContainer>
  );
};
export default withEmptyState(SectionCard);
