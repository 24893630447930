const FIX_UPLOAD_CALL_RECORDINGS_URL = 'https://docs.microsoft.com/dynamics365/ai/sales/configure-conversation-intelligence-call-data#upload-call-recordings';
const FRE_SETUP_SALES_INSIGHT_APP_URL = 'https://docs.microsoft.com/dynamics365/ai/sales/fre-setup-sales-insight-app';
const FAQS_SALES_INSIGHTS_URL = 'https://docs.microsoft.com/dynamics365/ai/sales/faqs-sales-insights';
const DYNAMICS_SALES_INSIGHTS_URL = 'https://appsource.microsoft.com/product/dynamics-365/mscrm.70b76f06-f739-4808-bd58-b5674a0a42d4';
const FIX_SETTINGS_URL = 'https://go.microsoft.com/fwlink/?linkid=2149192';

export const defaultErrorCode = 'MicrosoftInternalError';
export const DEFAULT_ERROR_CODE_TRANSLATION_KEY = 'microsoft_internal_error';

export const errorsMap = {
  InvalidMetadata: {
    translationKey: 'invalid_metadata',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL
  },
  CrmActivityIsAlreadyRelatedToAnotherFile: {
    translationKey: 'crm_activity_is_already_related_to_another_file',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL
  },
  UnableToDownloadClientConversationFile: {
    translationKey: 'unable_to_download_client_conversation_file',
  },
  AgentRecordingOnly: {
    translationKey: 'agent_recording_only',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
    customLinkName: true,
  },
  AudioBlobDoesNotExist: {
    translationKey: 'audio_blob_does_not_exist',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL
  },
  DoesntExistYet: {
    translationKey: 'doesnt_exist_yet',
  },
  OldBlob: {
    translationKey: 'old_blob',
    fixLink: FRE_SETUP_SALES_INSIGHT_APP_URL,
    secondFixLink: FAQS_SALES_INSIGHTS_URL,
    customLinkName: true,
    customSecondLinkName: true,
  },
  InvalidAudioBlob: {
    translationKey: 'invalid_audio_blob',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  CannotDownloadMetadata: {
    translationKey: 'cannot_download_metadata',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  InvalidAgentEmail: {
    translationKey: 'invalid_agent_email',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  MissingAgentId: {
    translationKey: 'missing_agent_id',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  InvalidLocale: {
    translationKey: 'invalid_locale',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  InvalidFileName: {
    translationKey: 'invalid_file_name',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  MissingStartTime: {
    translationKey: 'missing_start_time',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  MissingDirection: {
    translationKey: 'missing_direction',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  MicrosoftInternalError: {
    translationKey: DEFAULT_ERROR_CODE_TRANSLATION_KEY,
  },
  InvalidStartTime: {
    translationKey: 'invalid_start_time',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  MissingSpeaker: {
    translationKey: 'missing_speaker',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  ConversationIntelligenceNotInstalled: {
    translationKey: 'conversation_intelligence_not_installed',
    fixLink: DYNAMICS_SALES_INSIGHTS_URL,
    customLinkName: true,
  },
  PhoneCallActivityIdOrMediaReferenceAreMissing: {
    translationKey: 'phone_call_activity_id_or_media_reference_are_missing',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  GeneralError: {
    translationKey: 'general_error',
  },
  ActivityNotExistInCrm: {
    translationKey: 'activity_not_exist_in_crm',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  AgentNotProvisioned: {
    translationKey: 'agent_not_provisioned',
    fixLink: FRE_SETUP_SALES_INSIGHT_APP_URL,
    secondFixLink: FAQS_SALES_INSIGHTS_URL,
    customLinkName: true,
    customSecondLinkName: true,
  },
  OneSpeakerInstanceIsEmpty: {
    translationKey: 'one_speaker_instance_is_empty',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  NullSpeakerInTranscript: {
    translationKey: 'null_speaker_in_transcript',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  WrongChannelCount: {
    translationKey: 'wrong_channel_count',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  InvalidCallScope: {
    translationKey: 'invalid_call_scope',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  InvalidAgentChannel: {
    translationKey: 'invalid_agent_channel',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  ProcessingMaxTimeExceeded: {
    translationKey: 'processing_max_time_exceeded',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  InvalidParticipants: {
    translationKey: 'invalid_participants',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  InvalidCrmAccounts: {
    translationKey: 'invalid_crm_accounts',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  InvalidCrmContacts: {
    translationKey: 'invalid_crm_contacts',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  ContactEntityNotFound: {
    translationKey: 'contact_entity_not_found',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  OpportunityEntityNotFound: {
    translationKey: 'opportunity_entity_not_found',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  LeadEntityNotFound: {
    translationKey: 'lead_entity_not_found',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  AccountEntityNotFound: {
    translationKey: 'account_entity_not_found',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  PhoneCallActivityNotFound: {
    translationKey: 'phone_call_activity_not_found',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  ActivityNotFound: {
    translationKey: 'activity_not_found',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  MediaReferenceIdNotSupportedInSolutionVersion: {
    translationKey: 'media_reference_id_not_supported_in_solution_version',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  ConversationIntelligenceSolutionNotInstalled: {
    translationKey: 'conversation_intelligence_solution_not_installed',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  UnsupportedFileType: {
    translationKey: 'unsupported_file_type',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  CouldNotResolvePhoneCallActivityIdFromMediaReferenceId: {
    translationKey: 'could_not_resolve_phone_call_activity_id_from_media_reference_id',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  ConversationBlobDoesNotExist: {
    translationKey: 'conversation_blob_does_not_exist',
    fixLink: FIX_UPLOAD_CALL_RECORDINGS_URL,
  },
  LanguageIdentificationNotSupportedForExistingLanguageConfiguration: {
    translationKey: 'language_identification_not_supported_for_existing_language_configuration',
    fixLink: FIX_SETTINGS_URL,
  },
  AutoLanguageDetectionFailedToDetect: {
    translationKey: 'auto_language_detection_failed_to_detect',
    fixLink: FIX_SETTINGS_URL,
  },
  EmptyRealtimeTranscript: {
    translationKey: 'empty_realtime_transcript',
  },
  EmptyRealtimeAudio: {
    translationKey: 'empty_realtime_audio',
  },
  MediaRecordingServiceFailedToUploadBlobs: {
    translationKey: 'media_recording_service_failed_to_upload_blobs',
  },
  EmptyTranscript: {
    translationKey: 'empty_transcript',
  },
  UnknownException: {
    translationKey: DEFAULT_ERROR_CODE_TRANSLATION_KEY,
  },
  CsTranscriptionFailed: {
    translationKey: DEFAULT_ERROR_CODE_TRANSLATION_KEY,
  },
  SentimentsFailed: {
    translationKey: DEFAULT_ERROR_CODE_TRANSLATION_KEY,
  },
  MLServiceProcessError: {
    translationKey: DEFAULT_ERROR_CODE_TRANSLATION_KEY,
  },
  InvalidFileStatus: {
    translationKey: DEFAULT_ERROR_CODE_TRANSLATION_KEY,
  },
  FailedToGetBlobInstance: {
    translationKey: DEFAULT_ERROR_CODE_TRANSLATION_KEY,
  },
  FileStatusRemoved: {
    translationKey: DEFAULT_ERROR_CODE_TRANSLATION_KEY,
  },
  AudioBlobMissingMidPipeline: {
    translationKey: DEFAULT_ERROR_CODE_TRANSLATION_KEY,
  },
  CrmEnrichmentFailed: {
    translationKey: DEFAULT_ERROR_CODE_TRANSLATION_KEY,
  },
  InvalidInputForProcessingInsights: {
    translationKey: DEFAULT_ERROR_CODE_TRANSLATION_KEY,
  },
  PayloadSizeExceeded: {
    translationKey: DEFAULT_ERROR_CODE_TRANSLATION_KEY,
  },
  InvalidMetadataJsonFile: {
    translationKey: DEFAULT_ERROR_CODE_TRANSLATION_KEY,
  },
};
