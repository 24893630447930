import { chartConfigs } from 'ci-common-ui';
import { series, translateSeries } from 'utils/chart';
import { formatPercentage, formatKiloMillionBillion, numberWithCommas } from 'utils/numeric';
import { FontSizes } from '@uifabric/fluent-theme';

const { combineConfigs, donut, donutTitle } = chartConfigs;

export const createCustomerSentimentsConfig = (data, tAbs, isRTL) => combineConfigs(
  {
    data: data.series,
    seriesParams: translateSeries(series.sentimentParams, tAbs),
    dataLabelsFormatter: ({ percentage }) => formatPercentage(percentage),
    titleText: `
      <div style="text-align: center">
        <div style="font-size: ${FontSizes.size10}; margin-bottom: -5px">${tAbs('common.avg_across')}&nbsp;</div>
        <div title="${`${numberWithCommas(data.calls)}${tAbs('common.calls')}`}" style="margin-bottom: -1px;">${formatKiloMillionBillion(data.calls)}</div>
        <div style="font-size: ${FontSizes.size10}; margin-bottom: -5px">${tAbs('common.calls')}</div>
      </div>
    `,
  },
  [
    donut,
    donutTitle,
    {
      chart: {
        spacing: [0, 0, 0, 0]
      },
      title: {
        useHTML: true,
        x: isRTL ? -37 : 42,
        y: 28,
      },
      legend: {
        layout: 'vertical',
        verticalAlign: 'middle',
        padding: 3,
        itemMarginTop: 5,
        itemMarginBottom: 5,
        itemStyle: {
          lineHeight: '14px',
        },
      },
      series: [
        {
          color: 'blue',
          style: {
            color: 'red',
          },
        },
      ],
    },
  ],
);
