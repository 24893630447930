import map from 'lodash/map';
import { TRACK_IDS } from '../../../../../../constants/tracking';
import { eventAction, trackEvent } from '../../../../../../services/telemetry-service/telemetry-service';
import { dayJS, formatDate, urlFormat } from '../../../../../../utils/date/date';

export const viewAsExpiryDateFormat = urlFormat;

export const transformSingleMappingToTableFormat = ({ sourceUser, targetUser, expiryDate }) => ({
  sourceUser,
  targetUser,
  expiryDate
});

export const transformMappingsToTableFormat = (mappings) => map(mappings, transformSingleMappingToTableFormat);

export const getAllMappedSourceUsers = (allMappings) => map(allMappings, ({ sourceUser }) => sourceUser);

export const getColumns = (onInputChange, allMappings, { ViewAsPersonaCell, UntilCell, RemoveMappingCell }) => {
  const onRemoveMapping = (sourceAadid, targetAadid) => {
    trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.VIEW_AS_REMOVE_MAPPING, message: { sourceAadid, targetAadid } });
    return onInputChange(allMappings.filter(({ sourceUser, targetUser }) => !(sourceAadid === sourceUser.aadId && targetAadid === targetUser.aadId)));
  };
  const onUpdateExpiryDate = (newExpiryDateObj, mappingId) => onInputChange(allMappings.map((mapping) => {
    trackEvent({
      action: eventAction.click,
      actionOn: TRACK_IDS.VIEW_AS_UPDATE_EXPIRY_DATE,
      message: { newExpiryDate: calcExpiryDate(newExpiryDateObj), ...getSourceAndTargetAadIdsFromMappingId(mappingId) }
    });
    const shouldUpdateMapping = getMappingId(mapping) === mappingId;
    return shouldUpdateMapping ? { ...mapping, expiryDate: calcExpiryDate(newExpiryDateObj) } : mapping;
  }));

  const namesWidth = 390;

  return [
    {
      key: 'sourceUser',
      name: 'common.name',
      fieldName: 'sourceUser',
      minWidth: namesWidth,
      maxWidth: namesWidth,
      CellComponent: ViewAsPersonaCell
    },
    {
      key: 'targetUser',
      name: 'common.will_view_as',
      fieldName: 'targetUser',
      minWidth: namesWidth,
      maxWidth: namesWidth,
      CellComponent: ViewAsPersonaCell
    },
    {
      key: 'expiryDate',
      name: 'common.until',
      fieldName: 'expiryDate',
      minWidth: 110,
      maxWidth: 110,
      onUpdateExpiryDate,
      CellComponent: UntilCell
    },
    {
      key: 'removeMapping',
      name: 'common.empty_space',
      fieldName: 'removeMapping',
      minWidth: 60,
      maxWidth: 60,
      onRemoveMapping,
      CellComponent: RemoveMappingCell
    }
  ];
};

export const calcExpiryDate = ({ value, unit, key }) => {
  if (key === 'forever') {
    return null;
  }

  if (key === 'select_a_date') {
    return value;
  }

  const nowLocal = dayJS();
  const nowPlusHours = nowLocal.add(value, unit);
  return formatDate(nowPlusHours, viewAsExpiryDateFormat);
};

const MAPPING_ID_SEPERATOR = '_';

export const getMappingId = ({ sourceUser, targetUser }) => `${sourceUser.aadId}${MAPPING_ID_SEPERATOR}${targetUser.aadId}`;

export const getSourceAndTargetAadIdsFromMappingId = (mappingId) => ({ sourceUserAadId: mappingId?.split(MAPPING_ID_SEPERATOR)?.[0], targetUserAadId: mappingId?.split(MAPPING_ID_SEPERATOR)?.[1] });

export const getFormattedExpiryDate = (date) => dayJS(date).format(viewAsExpiryDateFormat);

export const toPickerSchema = ({ aadId, email, fullName } = {}) => ({
  text: fullName,
  secondaryText: email,
  key: aadId,
  aadId
});

export const getSuggestionProps = (isfilterTextTooShort, t) => ({
  suggestionsHeaderText: isfilterTextTooShort ? t('min_length_message') : t('suggested_users'),
  noResultsFoundText: isfilterTextTooShort ? '' : t('no_results_found'),
  loadingText: t('loading'),
  showRemoveButtons: false
});

export const getMappingFromPickerSchema = (sourceUserInPersonaSchema, targetUserInPersonaSchema, expiryDate) => ({
  sourceUser: {
    aadId: sourceUserInPersonaSchema.aadId,
    fullName: sourceUserInPersonaSchema.text,
    email: sourceUserInPersonaSchema.secondaryText
  },
  targetUser: {
    aadId: targetUserInPersonaSchema.aadId,
    fullName: targetUserInPersonaSchema.text,
    email: targetUserInPersonaSchema.secondaryText
  },
  expiryDate
});
