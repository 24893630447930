import { orderBy, unionBy } from 'lodash';
import { FEATURES, urlUtils, getDevOnlyStorageTenantId } from 'ci-common-ui';
import { isFeatureEnabled } from 'utils/app-context/feature-flighting';
import { getIsSellerUser, getIsManagerUser, getIsAdminUser } from 'utils/app-context/app-context';
import * as settingsRestService from '../rest-services/settings-rest-service';
import * as sellerService from './seller-service';
import { transformSettings, transformSettingsBeforeSend } from '../filters/settings-filters';
import { getCrmToken } from '../../services/auth-service';

const { getClusterUrlFromStaticMapping } = urlUtils;

export const getTeamSellers = async (orgId, isAdmin, forceFetch) => {
  const sellers = await sellerService.loadSellerList(orgId, forceFetch);
  const teamSellers = sellers.map((s) => ({ ...s, isOnUserTeam: true }));
  if (isAdmin && isFeatureEnabled(FEATURES.ADMIN_SELLERS)) {
    const adminSellers = await sellerService.loadAdminSellerList(orgId);
    const combineSellers = unionBy(teamSellers, adminSellers, 'aadId');
    return combineSellers;
  }
  return teamSellers;
};

export const { refreshTeamSellers } = settingsRestService;

export const deleteSellerData = async (id) => {
  const { data } = await settingsRestService.deleteSellerData(id);
  return !!data;
};

export const getLicenseUsage = async () => {
  const { data } = await settingsRestService.getLicenseUsage();
  return data;
};

export const { savePreviewFeatures } = settingsRestService;

export const getContainerList = async (storageConnectionMethod, clusterUri = null) => {
  const { data } = await settingsRestService.getContainerList({ ...storageConnectionMethod, ...getDevOnlyStorageTenantId() }, clusterUri);
  return data;
};

export const getOrganizationList = async () => {
  const { data: list } = await settingsRestService.getOrganizationList();
  let recentIds;

  if (isFeatureEnabled(FEATURES.RECENTLY_ACCESSED_ORGANIZATION_LIST)) {
    // removed the outdated api call, the feature is currently disabled
    recentIds = null;
  }

  const sortedList = orderBy(list, 'friendlyName');

  return [
    ...sortedList.filter(({ id }) => recentIds && recentIds.includes(id)),
    ...sortedList.filter(({ id }) => !recentIds || !recentIds.includes(id)),
  ];
};

export const getSettingsData = async (orgId) => {
  const viewAsPromise = getIsAdminUser() ? getViewAs(orgId) : null;
  const [{ data: org }, viewAs] = await Promise.all([settingsRestService.getOrg(orgId), viewAsPromise]);
  return transformSettings({ ...org, viewAs });
};

export const saveSettingsData = async (type, orgId, orgUrl, isAdmin, { id, url, name, hierarchy, viewAs, topPerformers, keywords, dataSource, retentionPolicy, isEyesOnData, useInternalStorage }, clusterRegion) => {
  const isSeller = getIsSellerUser();
  const isManager = getIsManagerUser();
  const params = transformSettingsBeforeSend({ id, url, name, hierarchy, topPerformers, keywords, dataSource, retentionPolicy, isEyesOnData, useInternalStorage }, { isSeller, isManager });
  const postViewAsPromise = getIsAdminUser() ? postViewAs(orgId, viewAs) : null;
  const [{ status }, viewAsStatus] = await Promise.all([settingsRestService.patchOrg(params, clusterRegion), postViewAsPromise]);
  return status === 200 && (!postViewAsPromise || viewAsStatus === 200);
};

// Bypass this check - always return true, the outdated API was removed
// eslint-disable-next-line no-unused-vars
export const checkPermissions = async (org) => Promise.resolve(true);

export const getOrganizationDetails = async (org, forceFetch) => {
  const { id, url } = org;
  const clusterUri = getClusterUrlFromStaticMapping(url);
  const { data } = await settingsRestService.getOrganizationDetails(id, url, forceFetch, clusterUri);
  const { isAdmin, isOrgExistAndProvisioned } = data;
  const isAdminAndNotProvisioned = isAdmin && !isOrgExistAndProvisioned;
  return {
    clusterUri,
    isAdminAndNotProvisioned,
    ...data
  };
};

export const finishOnboardingProcess = async ({ org: { id, url, name, resolvedOrgDetails: { isAdminAndNotProvisioned, clusterUri } }, isEyesOnData, dataSource, keywords }) => {
  const params = transformSettingsBeforeSend(
    { id, url, name, keywords, dataSource, isEyesOnData },
    { isSeller: !isAdminAndNotProvisioned, isManager: !isAdminAndNotProvisioned }
  );

  const overrideQueryParams = { 'settings-context': isAdminAndNotProvisioned ? 'org' : 'user' };

  const { status } = await settingsRestService.postOrg(params, clusterUri, overrideQueryParams);
  const isSucceeded = status === 200;
  if (isSucceeded && isAdminAndNotProvisioned) {
    const crmToken = await getCrmToken(url);
    await settingsRestService.installMDLSolution(url, crmToken);
  }
  return isSucceeded;
};

export const activeOrg = async ({ orgId, url, clusterRegion }) => {
  const { status } = await settingsRestService.patchOrg({ id: orgId, url, isDefault: true }, clusterRegion);
  return status === 200;
};

export const deleteOrg = async ({ orgId, clusterRegion }) => {
  const { status } = await settingsRestService.deleteOrg(orgId, clusterRegion);
  return status === 200;
};

export const getViewAs = async (orgId) => {
  const { data } = await settingsRestService.getViewAs(orgId);
  return data;
};

export const postViewAs = async (orgId, configuredViewAs) => {
  const { status } = await settingsRestService.postViewAs(orgId, { configuredViewAs });
  return status;
};

export const getAllOnboardedUsers = async (orgId, { filterText, skip, take }) => {
  const { data } = await settingsRestService.getAllOnboardedUsers(orgId, { filterText, skip, take });
  return data;
};
