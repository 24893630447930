import { serverApi, BASE_URLS } from 'ci-common-ui';
import { toNewBackendUrl } from '../../utils/url/url';
import { getIsAdminUser } from '../../utils/app-context/app-context';

const getSettingsContextQueryParams = () => ({ 'settings-context': getIsAdminUser() ? 'org' : 'user' });

export const getTeamSellers = () => serverApi.GET({ path: '/user/v1.0-preview/agents', options: { baseUrl: BASE_URLS.NEW_API_SERVER } });

export const refreshTeamSellers = () => serverApi.POST({ path: '/user/v1.0-preview/agents/actions/sync-hierarchy', options: { baseUrl: BASE_URLS.NEW_API_SERVER } });

export const deleteSellerData = (aadId) => serverApi.POST({ path: '/user/v1.0-preview/actions/clear-agent-data', queryParams: { 'agent-aad-id': aadId }, options: { baseUrl: BASE_URLS.NEW_API_SERVER, cacheOptions: { cache: false } } });

export const savePreviewFeatures = (orgId, orgUrl, data, clusterUri) => serverApi.POST({ path: `/v1.0/organizations/${orgId}/features`, body: data, queryParams: { orgUrl, orgId }, options: { baseUrl: clusterUri } });

export const getLicenseUsage = () => serverApi.GET({ path: '/user/v1.0-preview/license/actions/get-usage-details', options: { baseUrl: BASE_URLS.NEW_API_SERVER } });

export const getContainerList = (body, clusterUri = null) => serverApi.POST({ path: '/user/v1.0-preview/actions/get-storage-containers', body, options: { baseUrl: clusterUri ? toNewBackendUrl(clusterUri) : BASE_URLS.NEW_API_SERVER } });

export const getOrganizationList = () => serverApi.GET({ path: '/user/v1.0-preview/provisioning/organizations-list', options: { baseUrl: BASE_URLS.NEW_API_SERVER } });

export const getOrganizationDetails = (orgId, orgUrl, forceFetch, clusterUri) => serverApi.GET({ path: `/user/v1.0-preview/organizations/${orgId}/provisioning/onboarding-state`, queryParams: { orgUrl }, options: { baseUrl: clusterUri ? toNewBackendUrl(clusterUri) : BASE_URLS.NEW_API_SERVER, cacheOptions: { cache: !forceFetch } } });

export const deleteContactDataByContactId = (contactId) => serverApi.POST({ path: '/user/v1.0-preview/actions/clear-agent-data', queryParams: { 'contact-crm-id': contactId }, options: { baseUrl: BASE_URLS.NEW_API_SERVER } });

export const getOrg = (orgId, clusterUri = false) => serverApi.GET({ path: `/user/v1.0-preview/organizations/${orgId}`, queryParams: getSettingsContextQueryParams(), options: { baseUrl: clusterUri ? toNewBackendUrl(clusterUri) : BASE_URLS.NEW_API_SERVER, cacheOptions: { cache: false }, acceptedErrorCodes: [404] } });

export const postOrg = (orgParams, clusterUri, overrideQueryParams) => serverApi.POST({ path: `/user/v1.0-preview/organizations/${orgParams.id}`, body: orgParams, queryParams: overrideQueryParams || getSettingsContextQueryParams(), options: { baseUrl: toNewBackendUrl(clusterUri), orgId: orgParams.id } });

export const patchOrg = (orgParams, clusterUri) => serverApi.PATCH({ path: `/user/v1.0-preview/organizations/${orgParams.id}`, body: orgParams, queryParams: getSettingsContextQueryParams(), options: { baseUrl: toNewBackendUrl(clusterUri), orgId: orgParams.id } });

export const deleteOrg = (orgId, clusterUri) => serverApi.DELETE({ path: `/user/v1.0-preview/organizations/${orgId}`, queryParams: getSettingsContextQueryParams(), options: { baseUrl: toNewBackendUrl(clusterUri), orgId } });

export const getViewAs = (orgId) => serverApi.GET({ path: `/user/v1.0-preview/organizations/${orgId}/actions/get-view-as`, options: { cacheOptions: { cache: false }, baseUrl: BASE_URLS.NEW_API_SERVER } });

export const postViewAs = (orgId, configuredViewAs) => serverApi.POST({ path: `/user/v1.0-preview/organizations/${orgId}/actions/update-view-as`, body: configuredViewAs, options: { baseUrl: BASE_URLS.NEW_API_SERVER } });

export const getAllOnboardedUsers = (orgId, { filterText, skip = 0, take = 20 }) => serverApi.GET({ path: `/user/v1.0-preview/organizations/${orgId}/onboarded-users`, queryParams: { search: filterText, skip, take }, options: { baseUrl: BASE_URLS.NEW_API_SERVER } });

export const installMDLSolution = (orgUrl, token) => serverApi.POST({ path: '/api/data/v9.1/msdyn_InstallSalesInsightsSolution', body: { SolutionName: 'SalesInsightsMDLConfig' }, options: { baseUrl: orgUrl, token } });
