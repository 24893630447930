import transform from 'lodash/transform';
import omitBy from 'lodash/omitBy';
import mapValues from 'lodash/mapValues';
import isEmpty from 'lodash/isEmpty';
import { trackError } from '../../services/telemetry-service/telemetry-service';
import { TRACK_IDS } from '../../constants/tracking';

export const isTransformerErrorState = (data) => data === TRANSFORMER_ERROR;
export const isRequestErrorState = (data) => data === REQUEST_ERROR;

const TRANSFORMER_ERROR = 'TRANSFORMER_ERROR';
export const REQUEST_ERROR = 'REQUEST_ERROR';

export const dataTransformer = (transformerId, transformers, data) => (
  transform(
    transformers,
    (result, transformer, key) => {
      try {
        // eslint-disable-next-line no-param-reassign
        result[key] = transformer(data);
      } catch (error) {
        // eslint-disable-next-line no-param-reassign
        result[key] = TRANSFORMER_ERROR;
        const message = {
          transformerId,
          key,
          message: error.message,
          stacktrace: error.stack
        };
        trackError({ actionOn: TRACK_IDS.COMMON.REQUEST, message: JSON.stringify(message) });
      }
    }, {}
  )
);

export const getSpecificError = (data) => {
  const erroredKeys = omitBy(
    mapValues(data, (value) => isTransformerErrorState(value)),
    (value) => !value
  );
  return isEmpty(erroredKeys) ? null : erroredKeys;
};
