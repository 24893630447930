import { pick } from 'lodash';
import { chartUtils, colors, hexToRGBA, SENTIMENT_NAMES, SENTIMENT_COLORS, LISTENING_AND_TALKING_NAMES } from 'ci-common-ui';
import { roundPercentagesProps } from 'utils/numeric';
import { CHART_HEIGHT_NUMBER } from '../pages/StyledPages';

export const { multiplyBy100 } = chartUtils;

const X_AXIS_TICK_COUNT = 5;
const MINIMAL_X_AXIS_INTERVAL = 1;

const sentimentParams = [
  {
    key: SENTIMENT_NAMES.negative,
    color: SENTIMENT_COLORS.negative,
    labelColor: colors.White

  },
  {
    key: SENTIMENT_NAMES.neutral,
    color: SENTIMENT_COLORS.neutral,
    labelColor: colors.White

  },
  {
    key: SENTIMENT_NAMES.positive,
    color: SENTIMENT_COLORS.positive,
    labelColor: colors.White
  }
];

const sentimentAreaColors = [SENTIMENT_COLORS.negative, SENTIMENT_COLORS.neutral, SENTIMENT_COLORS.positive];

// colors <-> names
export const series = {
  sentimentParams,
  sentimentAreaParams: sentimentParams.map((item, index) => ({ ...item, color: sentimentAreaColors[index] })),
  listeningAndTalkingParams: [
    {
      key: LISTENING_AND_TALKING_NAMES[0],
      color: hexToRGBA(colors.DYNPrimary, 0.7),
      labelColor: colors.Black
    },
    {
      key: LISTENING_AND_TALKING_NAMES[1],
      color: colors.DYNShade10,
      labelColor: colors.White
    }
  ],
  dynamicColors: [colors.MangoOrange, colors.GrapePurple, colors.PacificBlue, colors.AzulBlue, colors.SaharaYellow, colors.ScarletRed, colors.AtlanticBlue, colors.CandyPink, colors.JadeGreen, colors.BlushPink]
};

export const plotLineColors = [colors.Gray160, colors.Gray130];

export const getOvertimeXAxisInterval = (arr) => arr && arr.length < X_AXIS_TICK_COUNT ? MINIMAL_X_AXIS_INTERVAL : Math.floor(arr.length / X_AXIS_TICK_COUNT);

// translate series name: create a new translated 'text' field according to 'key' field
export const translateSeries = (seriesArr, tAbs, key = 'key') => seriesArr.map((item) => ({ ...item, text: tAbs(`common.${item[key].toLowerCase()}`) }));

const yToValue = (currentSeries) => currentSeries && currentSeries.map(({ name, y }) => ({ name, value: y }));

// for donuts CSV title
export const getExcelDataFromPie = (data) => data && [
  ...yToValue(data.series),
  { name: 'callsCount', value: data.calls }
];

// scroll
export const getBarChartMinScrollHeight = (data, barPlusMarginHeight = 30, legendHeight = 70) => {
  const tempMinHeight = data ? (data.length * barPlusMarginHeight) + legendHeight : undefined;
  return tempMinHeight && tempMinHeight > CHART_HEIGHT_NUMBER ? tempMinHeight : undefined;
};

const setSeriesFromObject = (dataObj) => Object.entries(dataObj).map(([name, y]) => ({ name, y }));

const setSeriesForPie = (data) => {
  const seriesProps = pick(data, [SENTIMENT_NAMES.negative, SENTIMENT_NAMES.neutral, SENTIMENT_NAMES.positive]);
  const seriesRoundedProps = roundPercentagesProps(seriesProps, [SENTIMENT_NAMES.negative, SENTIMENT_NAMES.neutral, SENTIMENT_NAMES.positive], true);
  return setSeriesFromObject(seriesRoundedProps);
};

export const transformSentimentDataForPie = (data) => {
  if (data.length) {
    const sentimentData = data[0];

    return {
      calls: sentimentData.callsCount,
      series: setSeriesForPie(sentimentData),
    };
  }
  return {
    calls: 0,
    series: [],
  };
};

export const transformSentimentDataForPercentagesBar = (data) => data.map((item) => roundPercentagesProps(item, [SENTIMENT_NAMES.negative, SENTIMENT_NAMES.neutral, SENTIMENT_NAMES.positive], true));
