import styled from 'styled-components';
import { Spinner } from '@fluentui/react';
import { rtl } from '../../../../utils/styles/rtl';

export const ComboBoxContainer = styled.div`
  position: relative;
`;
export const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 6px;
  ${rtl`right`}: 6px;
`;
