export const CALL_PROCESSING_MESSAGE_TYPES = {
  START_CONNECTION: 'START_CONNECTION',
  CLOSE_CONNECTION: 'CLOSE_CONNECTION',
  HEARTBEAT: 'HEARTBEAT',
  INIT_TRANSCRIPT: 'INIT_TRANSCRIPT',
  CHANGE_MODE: 'CHANGE_MODE',
  FRAGMENT: 'FRAGMENT',
  FRAGMENT_INSIGHT: 'FRAGMENT_INSIGHT',
  CALL_SUMMARY: 'CALL_SUMMARY',
  MEDIA_RECORDING_STATE: 'MEDIA_RECORDING_STATE',
};

export const CALL_PROCESSING_MODE_TYPES = {
  IN_CALL: 'IN_CALL',
  CALL_SUMMARY: 'CALL_SUMMARY'
};
