import React, { useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import map from 'lodash/map';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';
import { ActionButton, SearchBox, Spinner, MessageBar, MessageBarType } from '@fluentui/react';
import { useFetch } from '../../../../../../hooks/use-fetch';
import Result from '../Result/Result';
import { Label, Results } from '../../StyledCrmRecordLookup';
import { Container, ResultsSizeTitle, ResultsContainer, spinnerStyles } from './StyledSearchView';
import { useInfra } from '../../../../../../providers/InfraProvider';
import { CALL_STRINGS } from '../../../../strings';
import { ClickOutside } from '../../../../../../components/ClickOutside';

const DEBOUNCE_TIME = 500;

const ResultsWrapper = ({ isLoading, error, searchResults, onRecordChosen }) => {
  const { t } = useInfra();

  if (error) {
    return <MessageBar messageBarType={MessageBarType.error}>{t(CALL_STRINGS.SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_CRM_RECORD_LOOKUP_API_ERROR)}</MessageBar>;
  }

  if (Array.isArray(searchResults) && isEmpty(searchResults)) {
    return <MessageBar>{t(CALL_STRINGS.SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_CRM_RECORD_LOOKUP_EMPTY_RESULT)}</MessageBar>;
  }

  if (isLoading) {
    return <Spinner styles={spinnerStyles} />;
  }

  if (Array.isArray(searchResults) && isEmpty(searchResults)) {
    return <MessageBar>{t(CALL_STRINGS.SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_CRM_RECORD_LOOKUP_EMPTY_RESULT)}</MessageBar>;
  }

  return (
    <ResultsContainer>
      {!isEmpty(searchResults) && <ResultsSizeTitle>{size(searchResults)} {t(CALL_STRINGS.RESULTS)}</ResultsSizeTitle>}
      {
        map(
          searchResults,
          (result) => (
            <Result key={JSON.stringify(result)} data={result} onResultClick={onRecordChosen} />
          ),
        )
      }
    </ResultsContainer>
  );
};

export default ({ entityType, initialSearchTerm, onRecordChosen, newRecordClicked, getSearchResults, hideCreateNewRecord, title, shouldFocusOnMount, searchTermMinLength = 0 }) => {
  const { data: searchResults, error, isLoading, fetchData, reset: resetSearchResults } = useFetch(getSearchResults);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const searchBoxRef = useRef();
  const { t, orgId, orgUrl } = useInfra();

  useEffect(() => {
    if (shouldFocusOnMount) {
      searchBoxRef.current.focus();
    }
  }, []);

  const onSearch = debounce((_event, updatedSearchTerm) => {
    setSearchTerm(updatedSearchTerm);
    getData({ orgId, entityType, searchTerm: updatedSearchTerm, orgUrl });
  }, DEBOUNCE_TIME);

  // eslint-disable-next-line no-shadow
  const getData = ({ orgId, entityType, searchTerm, orgUrl }) => {
    if (size(searchTerm) >= searchTermMinLength) {
      fetchData({ orgId, entityType, searchTerm, orgUrl });
    }
  };

  const onClickOutside = () => { setSearchTerm(null); resetSearchResults(); };
  const onSearchBoxFocus = () => getData({ orgId, entityType, searchTerm, orgUrl });

  return (
    <ClickOutside onClickOutside={onClickOutside}>
      <Container>
        <Label>{title || t(CALL_STRINGS.SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_CRM_RECORD_LOOKUP_LOOK_FOR_RECORD)}</Label>
        <SearchBox
          value={searchTerm}
          placeholder={t(CALL_STRINGS.SEARCH)}
          ariaLabel="Search for a record"
          onChange={onSearch}
          componentRef={searchBoxRef}
          onFocus={onSearchBoxFocus}
        />
        {(isLoading || searchResults || error) && (
        <Results hideCreateNewRecord={hideCreateNewRecord}>
          <ResultsWrapper
            isLoading={isLoading}
            error={error}
            searchResults={searchResults}
            onRecordChosen={onRecordChosen}
          />
        </Results>
        )}
        {!hideCreateNewRecord && (
        <ActionButton
          iconProps={{ iconName: 'Add' }}
          text={t(CALL_STRINGS.SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_CRM_RECORD_LOOKUP_CREATE_NEW_RECORD)}
          onClick={newRecordClicked}
        />
        )}
      </Container>
    </ClickOutside>
  );
};
