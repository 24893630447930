import get from 'lodash/get';
import remove from 'lodash/remove';

const MESSAGE_TYPE = 'message';

const onReceiveMessage = (subscriptions, event) => {
  const msgType = get(event, 'data.msgType');
  if (msgType) {
    subscriptions.forEach((subscription) => {
      if (subscription.type === msgType) {
        subscription.callback(event);
      }
    });
  }
};

class WindowCommunicationService {
  _subscriptions;
  constructor() {
    this._subscriptions = [];
    window.addEventListener(MESSAGE_TYPE, (event) => onReceiveMessage(this._subscriptions, event));
  }

  addEventListener(type, callback) {
    this._subscriptions.push({ type, callback });
  }

  removeEventListener(msgType) {
    remove(this._subscriptions, ({ type }) => type === msgType);
  }
}

export default new WindowCommunicationService();
