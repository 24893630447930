import styled from 'styled-components';
import { FlexRow } from 'ci-common-ui';
import { IconButton } from '@fluentui/react';

export const Container = styled.div`
`;

export const Content = styled(FlexRow)`
  justify-content: flex-start;
  padding: 20px 0px;
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: 400;
`;

export const ChevronIconDown = styled(IconButton).attrs({
  iconProps: { iconName: 'ChevronDown' },
  ariaLabel: 'ChevronDown',
  styles: { root: { marginLeft: '4px' } }
})`
  transform: ${({ isUp }) => isUp ? 'rotate(-180deg)' : 'rotate(0deg)'};
  transition: all 0.5s ease;
`;

export const IconWrapper = styled.span`
  transform: translateY(-3px);
`;
