// @flow
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { DayOfWeek } from '@fluentui/react';
import { StyledCalendar, CalendarLabel, CalendarContainer } from './StyledCalendarPicker';

export const defaultDatePickerStrings = {
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  goToToday: 'Go to today',
  weekNumberFormatString: 'Week number {0}',
  prevMonthAriaLabel: 'Previous month',
  nextMonthAriaLabel: 'Next month',
  prevYearAriaLabel: 'Previous year',
  nextYearAriaLabel: 'Next year',
  prevYearRangeAriaLabel: 'Previous year range',
  nextYearRangeAriaLabel: 'Next year range',
  closeButtonAriaLabel: 'Close',
};

export const datePickerDayTimesParts = {
  partOfDay: ['AM', 'PM'],
  timeSlots: [
    '01:00', '01:15', '01:30', '01:45',
    '02:00', '02:15', '02:30', '02:45',
    '03:00', '03:15', '03:30', '03:45',
    '04:00', '04:15', '04:30', '04:45',
    '05:00', '05:15', '05:30', '05:45',
    '06:00', '06:15', '06:30', '06:45',
    '07:00', '07:15', '07:30', '07:45',
    '08:00', '08:15', '08:30', '08:45',
    '09:00', '09:15', '09:30', '09:45',
    '10:00', '10:15', '10:30', '10:45',
    '11:00', '11:15', '11:30', '11:45',
    '12:00', '12:15', '12:30', '12:45',
  ],
};

export const datePickerDayTimes = [
  ...datePickerDayTimesParts.timeSlots.map((time) => `${time} ${datePickerDayTimesParts.partOfDay[0]}`),
  ...datePickerDayTimesParts.timeSlots.map((time) => `${time} ${datePickerDayTimesParts.partOfDay[1]}`),
];

export const CalendarPicker = ({
  selectedDate,
  label,
  onSelectDate,
  calendarPickerProps,
  className,
}) => {
  const calendarProps = {
    onSelectDate,
    isMonthPickerVisible: true,
    value: selectedDate,
    firstDayOfWeek: DayOfWeek.Sunday,
    isDayPickerVisible: true,
    highlightCurrentMonth: true,
    highlightSelectedMonth: true,
    showMonthPickerAsOverlay: true,
    showGoToToday: false,
    strings: defaultDatePickerStrings,
    ...calendarPickerProps,
  };

  return (
    <div className={className}>
      <CalendarLabel>{label}</CalendarLabel>
      <CalendarContainer>
        <StyledCalendar {...calendarProps} />
      </CalendarContainer>
    </div>
  );
};
