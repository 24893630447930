const isNotificationSupported = 'Notification' in window;
const NOTIFICATION_GRANTED = 'granted';

const getBrowserFocus = () => !document.hidden;

export const askForNotificationPermission = () => {
  if (!isNotificationSupported) {
    return;
  }

  if (window.Notification.permission !== NOTIFICATION_GRANTED) {
    window.Notification.requestPermission();
  }
};

export const showBrowserNotification = (title, body, icon) => {
  if (!isNotificationSupported) {
    return;
  }

  const hasFocus = getBrowserFocus();
  const hasNotificationGranted = window.Notification.permission === NOTIFICATION_GRANTED;
  if (hasFocus || !hasNotificationGranted) {
    return;
  }

  const notification = new window.Notification(title, {
    icon,
    body,
    requireInteraction: true,
  });

  notification.onclick = () => {
    window.parent.focus();
  };
};
