import React from 'react';
import { StyledBar, StyledBarsContainer } from './StyledNotificationBars';
import { notificationBarsMap } from './utils';

const NotificationBars = ({ bars = [] }) => !bars.length ? null : (
  <StyledBarsContainer>
    {bars.map(({ type, props, level }) => {
      const Component = notificationBarsMap[type];
      return <StyledBar key={type} level={level}><Component data={props} level={level} /></StyledBar>;
    })}
  </StyledBarsContainer>
);
export default NotificationBars;
