import { formatDate, urlFormat, monthsFromTodayToDate, dayJS } from 'utils/date/date';

const DEFAULT_MONTH_BEFORE = 3;

export const fullTimeRange = {
  start: formatDate(monthsFromTodayToDate(-DEFAULT_MONTH_BEFORE), urlFormat),
  end: formatDate(dayJS(), urlFormat)
};

export const STATUS_NAMES = {
  completed: 'completed',
  failed: 'failed',
  inProgress: 'inProgress',
  ignored: 'ignored'
};
