import React, { useEffect } from 'react';
import useLocale from 'hooks/use-locale';
import { useFetchNow, Trans } from 'ci-common-ui';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import Page from '../../components/Page/Page';
import { getInsightDetails } from '../../../network/data-services/insight-service';
import KeywordsSection from './components/KeywordsSection/KeywordsSection';
import RelevantConversations from '../components/RelevantConversations/RelevantConversations';
import { useQueryDateChange } from '../../../hooks/use-query-date-change';
import { getDateParams } from '../../../utils/url/url';
import { callTypeColumn, dateAndDurationColumn, keywordsColumn, tagsColumn, commentsCountColumn, ownerColumn, callTopicColumn, crmEntitiesLinksColumn } from '../../../components/Table/default-columns';
import BackHomeButton from '../components/BackHomeButton';
import DelimitedInlineList from '../../../components/DelimitedInlineList/DelimitedInlineList';
import { getSpecificError } from '../../../utils/error-state/error-state';
import { TRACK_IDS } from '../../../constants/tracking';
import { componentLoadingTimeService } from '../../../services/component-loading-time-service/component-loading-time-service';

const LOCALIZATION_PREFIX = 'pages.insight.top_performers';

const type = 'TopSellersMentioning';
const columns = [callTypeColumn, callTopicColumn, keywordsColumn, tagsColumn, commentsCountColumn, ownerColumn, dateAndDurationColumn, crmEntitiesLinksColumn];

const InsightTopSellersPage = () => {
  const { appModeAwareTerms } = useLocale();
  const dateParams = getDateParams();
  const apiParams = { type, ...dateParams };
  const tableApiParams = { type, ...dateParams };
  const { data, isLoading, error, fetchData } = useFetchNow(() => getInsightDetails(apiParams, 'topSellers'), { getSpecificError });

  const keywords = get(data, 'topTrendingKeywords');
  /* eslint-disable */
  const keywordsSectionTitle = !isEmpty(keywords) && !isLoading ? (
    <Trans i18nKey={`${LOCALIZATION_PREFIX}.title`} count={size(keywords)}>
      fragment1 {{ _representative_plural: appModeAwareTerms._representative_plural }} fragment3 <DelimitedInlineList data={keywords} /> fragment5 {{ _representative_plural: appModeAwareTerms._representative_plural }}
    </Trans>
  ) : null;
  /* eslint-enable */

  const keywordsSectionSubtitle = <Trans i18nKey={`${LOCALIZATION_PREFIX}.subtitle`}>{{ _representative_plural: appModeAwareTerms._representative_plural }}<br /></Trans>;

  useQueryDateChange(fetchData);

  useEffect(() => {
    componentLoadingTimeService.start(TRACK_IDS.SOURCES.TOP_SELLERS_INSIGHT_PAGE);
  }, []);

  return (
    <Page isLoading={isLoading} title={<BackHomeButton />} fullTimeRange={data && data.dateTimeRange} error={error}>
      <KeywordsSection isLoading={isLoading} data={data} title={keywordsSectionTitle} subtitle={keywordsSectionSubtitle} />
      <RelevantConversations columns={columns} apiParams={tableApiParams} isPageDetailsLoading={isLoading} trackSource={TRACK_IDS.SOURCES.TOP_SELLERS_INSIGHT_PAGE} />
    </Page>
  );
};
export default InsightTopSellersPage;
