import { CdnProdRegions, SciRegion } from '../regions-constants';

export const SciCdnUrls = {
  [SciRegion.local]: `http://localhost:3030`,
  [SciRegion.dev2]: `https://cdn-uireleases${SciRegion.dev2}.azureedge.net`,
  [SciRegion.dev3]: `https://cdn-uireleases${SciRegion.dev3}.azureedge.net`,
  [SciRegion.dev4]: `https://cdn-uireleases${SciRegion.dev4}.azureedge.net`,
  [SciRegion.dev7]: `https://cdn-uireleases${SciRegion.dev7}.azureedge.net`,
  [SciRegion.dev8]: `https://cdn-uireleases${SciRegion.dev8}.azureedge.net`,
  [SciRegion.dev9]: `https://cdn-uireleases${SciRegion.dev9}.azureedge.net`,
  [SciRegion.dev11]: `https://cdn-uireleases${SciRegion.dev11}.azureedge.net`,
  [SciRegion.tip1]: `https://cdn-uireleases${CdnProdRegions.tip}.azureedge.net`,
  [SciRegion.nam]: `https://cdn-uireleases${CdnProdRegions.nam}.azureedge.net`,
  [SciRegion.nam2]: `https://cdn-uireleases${CdnProdRegions.nam2}.azureedge.net`,
  [SciRegion.ssam]: `https://cdn-uireleases${CdnProdRegions.sam}.azureedge.net`,
  [SciRegion.ccan]: `https://cdn-uireleases${CdnProdRegions.can}.azureedge.net`,
  [SciRegion.weur]: `https://cdn-uireleases${CdnProdRegions.eur}.azureedge.net`,
  [SciRegion.seapj]: `https://cdn-uireleases${CdnProdRegions.apj}.azureedge.net`,
  [SciRegion.eoce]: `https://cdn-uireleases${CdnProdRegions.oce}.azureedge.net`,
  [SciRegion.ejpn]: `https://cdn-uireleases${CdnProdRegions.jpn}.azureedge.net`,
  [SciRegion.cind]: `https://cdn-uireleases${CdnProdRegions.ind}.azureedge.net`,
  [SciRegion.gbr]: `https://cdn-uireleases${CdnProdRegions.gbr}.azureedge.net`,
  [SciRegion.cfra]: `https://cdn-uireleases${CdnProdRegions.fra}.azureedge.net`,
  [SciRegion.nzaf]: `https://cdn-uireleases${CdnProdRegions.zaf}.azureedge.net`,
  [SciRegion.nuae]: `https://cdn-uireleases${CdnProdRegions.uae}.azureedge.net`,
  [SciRegion.swiss]: `https://cdn-uireleases${CdnProdRegions.che}.azureedge.net`,
  [SciRegion.ger]: `https://cdn-uireleases${CdnProdRegions.ger}.azureedge.net`,
  [SciRegion.norway]: `https://cdn-uireleases${CdnProdRegions.norway}.azureedge.net`,
  [SciRegion.korea]: `https://cdn-uireleases${CdnProdRegions.korea}.azureedge.net`,
};

export type SciCdnRegion = keyof typeof SciCdnUrls;

export const getSciCdnUrl = (
  region: SciRegion | string,
  version: string,
  project: string
) => {
  if (region === SciRegion.local) {
    return SciCdnUrls[region];
  }

  const cdnRegion = Object.keys(SciCdnUrls).includes(region)
    ? (region as SciCdnRegion)
    : SciRegion.nam;

  return `${SciCdnUrls[cdnRegion]}/${version}/${project}`;
};
