import React, { useState, useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import useLocale from 'hooks/use-locale';
import { teamsTheme, lightTheme } from 'ci-common-ui';
import { trackEvent, eventAction } from '../services/telemetry-service/telemetry-service';

const ThemeContext = React.createContext();
const { Provider } = ThemeContext;

const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) throw new Error('useTheme must be used within a ThemeManager');

  return context;
};

const ThemeManagerProvider = ({ children }) => {
  const [theme, setTheme] = useState(lightTheme);
  const { i18n } = useLocale();

  const changeTheme = (newTheme) => {
    trackEvent({ action: eventAction.themeChanged });
    setTheme(newTheme);
  };

  return (
    <Provider value={{ theme, changeTheme, allThemes: { lightTheme, teamsTheme } }}>
      <ThemeProvider theme={{ ...theme, dir: i18n.dir() }}>{children}</ThemeProvider>
    </Provider>
  );
};

export { ThemeManagerProvider, useTheme };
