import React from 'react';
import { Shimmer, ShimmerElementType } from '@fluentui/react';
import { Container, DescriptionShimmer, TitleShimmerContainer } from './StyledInsightCardLoader';

const InsightCardLoader = ({ className }) => (
  <Container className={className}>
    <TitleShimmerContainer>
      <Shimmer width={170} />
    </TitleShimmerContainer>
    <Shimmer shimmerElements={[{ type: ShimmerElementType.line, width: 300, height: 172 }]} />
    <DescriptionShimmer>
      <Shimmer />
      <Shimmer />
      <Shimmer />
      <Shimmer />
    </DescriptionShimmer>
  </Container>
);
export default InsightCardLoader;
