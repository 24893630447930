import React from 'react';
import ReactDOM from 'react-dom';
import { ConfirmationModal } from 'ci-common-ui';
import { eventAction, trackEvent } from '../../services/telemetry-service/telemetry-service';

const UserConfirmation = (message, shouldBlockNavigation, setAppState) => {
  const { title, subtitle, confirmButtonText, cancelButtonText, actionOn } = message ? JSON.parse(message) : {};
  const container = document.createElement('div');
  document.body.appendChild(container);

  const closeModal = (shouldBlock) => {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);

    shouldBlockNavigation(shouldBlock);
  };

  const onConfirm = () => {
    closeModal(true);
    setAppState({ leavingPagePromptMessage: null });
  };

  const onCancel = () => {
    closeModal(false);
  };

  if (actionOn) {
    trackEvent({ action: eventAction.click, actionOn });
  }

  ReactDOM.render(
    <ConfirmationModal
      isOpen
      title={title}
      subtitle={subtitle}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmButtonText={confirmButtonText}
      cancelButtonText={cancelButtonText}
    />,
    container
  );
};
export default UserConfirmation;
