import { addEmptyDataToStartAndEnd } from '../../../../../utils/chart';
import combineConfigs from '../../combineConfigs';
import tooltip from '../../tooltip/tooltip';
import seriesMapper from '../../series/seriesMapper';
import xAxisCategoriesMap from '../../xAxis/xAxisCategoriesMap';
import xAxisLabels from '../../xAxis/xAxisLabels';
import dataLabels from '../../dataLabels/dataLabels';
import yAxisPlotLines from '../../yAxis/yAxisPlotLines';

const type = 'column';

export default ({
  data,
  seriesParams,
  categoryField,
  seriesLabelColors,
  plotLines,
  xAxisFormatter,
  yAxisFormatter,
  dataLabelsFormatter,
  shouldAddEmptyDataToStartAndEnd,
  tooltipOptions
}) => combineConfigs(
  {
    type,
    data: shouldAddEmptyDataToStartAndEnd ? addEmptyDataToStartAndEnd(data, categoryField) : data,
    seriesParams,
    xAxisFormatter,
    yAxisFormatter,
    dataLabelsFormatter,
    seriesLabelColors,
    plotLines,
    categoryField,
    tooltipOptions: tooltipOptions || {
      getProps: ({ series: { name }, y: percentage }) => ({
        name,
        value: dataLabelsFormatter({ percentage }),
      }),
      withHeader: true,
      withBullet: true,
    },
  },
  [
    xAxisLabels,
    xAxisCategoriesMap,
    yAxisPlotLines,
    dataLabels,
    tooltip,
    seriesMapper,
    {
      type,
      plotOptions: {
        column: {
          stacking: 'percent',
          maxPointWidth: 50
        },
      }
    },
  ],
);
