import React from 'react';
import { Dialog, DialogFooter, PrimaryButton, DefaultButton } from '@fluentui/react';
import useLocale from 'hooks/use-locale';

const DIALOG_WIDTH = 440;

const OrgTableDialog = ({ details: { isOpen, title, subText, onConfirm, closeDialog } }) => {
  const { tAbs } = useLocale();

  return (
    <Dialog
      hidden={!isOpen}
      onDismiss={closeDialog}
      minWidth={DIALOG_WIDTH}
      dialogContentProps={{ title, subText }}
    >
      <DialogFooter>
        <PrimaryButton onClick={onConfirm} text={tAbs('common.ok')} />
        <DefaultButton onClick={closeDialog} text={tAbs('common.cancel')} />
      </DialogFooter>
    </Dialog>
  );
};
export default OrgTableDialog;
