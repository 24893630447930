import { loadingTimeService } from 'ci-common-ui';
import { TRACK_IDS } from '../../constants/tracking';
import { trackEvent, eventAction } from '../telemetry-service/telemetry-service';

export const componentLoadingTimeService = {
  start: (source) => {
    loadingTimeService.start(source);
  },
  end: (source) => {
    const loadingTime = loadingTimeService.end(source);
    if (loadingTime > 0) {
      trackEvent({ overrideSource: source, actionOn: TRACK_IDS.COMMON.PAGE_LOAD_TIME_MEASUREMENT_MILLISECONDS, action: eventAction.pageLoaded, message: loadingTime });
    }
  }
};
