import styled from 'styled-components';
import { FlexRowCentered } from '../../../utils/styles/StyledUtils';

const CALL_SUMMARY_HEIGHT_MARGIN = '120px';
const CALL_SUMMARY_WIDTH_MARGIN = '400px';

export const ModalHeader = styled(FlexRowCentered)`
  justify-content: space-between;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.White};
  font-size: 18px;
  font-weight: 600;
`;

export const CallPageContainer = styled.div`
  height: calc(100vh - ${CALL_SUMMARY_HEIGHT_MARGIN});
  width: calc(100vw - ${CALL_SUMMARY_WIDTH_MARGIN});
  min-width: 950px;
`;

export const saveAndCloseButtonStyles = {
  root: {
    marginLeft: '12px'
  }
};

export const cancelButtonStyles = ({ theme }) => ({
  root: {
    color: theme.palette.neutralPrimary
  }
});
