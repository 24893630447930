import { useEffect } from 'react';
import { useToggler } from './use-toggler';

export function useSync(search = '', isPlaying = false) {
  const [isSynced, toggleSync] = useToggler(true);
  useEffect(() => {
    onOutOfSync();
  }, [search]);

  useEffect(() => {
    if (!isPlaying && !isSynced) {
      toggleSync();
    }
  }, [isPlaying]);

  const onOutOfSync = () => {
    if (isPlaying && isSynced) {
      toggleSync();
    }
  };

  return { isSynced, toggleSync, onOutOfSync };
}
