import styled from 'styled-components';
import { colors, StyledText, rtl } from 'ci-common-ui';
import { FontSizes } from '@uifabric/fluent-theme';

export const INPUT_WIDTH = '50%';
export const INPUT_MIN_WIDTH = 350;

export const Section = styled.div`
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  
  &:last-child {
    border-bottom: none;
  }
`;

export const SectionTitle = styled.div.attrs({ as: 'h2' })`
  font-size: ${FontSizes.size20};
  font-weight: 600;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  &::first-letter {
    text-transform: capitalize;
  }

  a {
    font-size: 14px;
    font-weight: normal;
    margin: 0 5px;
    color: ${colors.DYNPrimary};
  }
`;

export const SectionDescription = styled.div`
  font-size: ${FontSizes.size16};
  color: ${colors.Gray130};
  margin-bottom: 10px;
  
  a {
    margin: 0 5px;
    color: ${colors.DYNPrimary};
  }
  
  ${({ maxWidth }) => maxWidth && `
    max-width: ${maxWidth};
  `}
    
  ${({ bold }) => bold && `
    font-weight: 600;
    color: ${colors.Gray160};
  `}
  
  ${({ capitalize }) => capitalize && `
      text-transform: capitalize;
  `}
`;

export const SectionDescriptionRequired = styled(SectionDescription)`
  &:after {
    content: "*";
    font-size: 14px;
    font-weight: 600;
    color: ${colors.Red};
    ${rtl`margin-left: 6px`};
  }
`;

export const SectionText = styled(StyledText)`
  color: ${colors.Gray130};
  display: block;  
  white-space: normal;
  font-size: ${FontSizes.size16};
  margin-bottom: 32px;
`;

export const SectionNote = styled.div`
  font-size: ${FontSizes.size16};
  font-weight: 600;
  margin-bottom: 32px;
`;
