import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { useFetch, usePrevious } from 'ci-common-ui';
import CrmEntitiesLinks from '../../components/CrmEntitiesLinks/CrmEntitiesLinks';
import Page from '../components/Page/Page';
import { getDateParams, getPageNumberFromUrlOrDefault } from '../../utils/url/url';
import RelevantConversations from './components/RelevantConversations/RelevantConversations';
import {
  commentsCountColumn,
  callTopicColumn,
  ownerColumn,
  sentimentColumn,
  crmEntitiesLinksColumn,
  callTypeColumn,
  dateAndDurationColumn,
  tagsColumn
} from '../../components/Table/default-columns';
import useLocale from '../../hooks/use-locale';
import SearchMatchesCell from '../../components/Table/Cells/SearchMatchesCell/SearchMatchesCell';
import { getCallsSearchTable } from '../../network/data-services/search-service';
import { useQueryDateChange } from '../../hooks/use-query-date-change';
import { eventAction, trackEvent } from '../../services/telemetry-service/telemetry-service';
import { joinTrackDelimiter } from '../../utils/string/string';
import { TRACK_IDS, TRACK_SUFFIX } from '../../constants/tracking';
import { QUERY_PARAMS } from '../../constants/urls';
import { componentLoadingTimeService } from '../../services/component-loading-time-service/component-loading-time-service';

const getColumns = (query) => [callTypeColumn, callTopicColumn, ownerColumn, sentimentColumn, dateAndDurationColumn, tagsColumn, commentsCountColumn,
  {
    ...crmEntitiesLinksColumn,
    maxWidth: 140,
    minWidth: 140,
    CellComponent: ({ data }) => <CrmEntitiesLinks data={data} isNewSchema hideNames />
  },
  {
    key: 'matches',
    name: 'common.matches',
    fieldName: 'matches',
    query,
    CellComponent: SearchMatchesCell,
    maxWidth: 200,
    minWidth: 160
  }
];

const initialPageNumber = 1;

const SearchPage = () => {
  const { t } = useLocale('pages.search');
  const [pageNumber, setPageNumber] = useState(getPageNumberFromUrlOrDefault(initialPageNumber));
  const [{ [QUERY_PARAMS.QUERY]: query }] = useQueryParams({ [QUERY_PARAMS.QUERY]: StringParam });
  const { startDate, endDate } = getDateParams();
  const apiParams = { search: query, 'start-date': startDate, 'end-date': endDate, 'page-number': pageNumber };
  const { data, isLoading, fetchData, error } = useFetch((newParams) => getCallsSearchTable({ ...apiParams, ...newParams }));
  const prevQuery = usePrevious(query);

  const fetchInitPage = () => {
    setPageNumber(initialPageNumber);
    fetchData({ 'page-number': initialPageNumber });
  };

  if (window.O365Shell) {
    window.O365Shell.Search.SetSearchText(query);
  }

  const onQueryDateChange = () => {
    fetchInitPage();
  };

  const onPageChanged = (newPage) => {
    trackEvent({ action: eventAction.click, actionOn: joinTrackDelimiter([TRACK_IDS.RELEVANT_CONVERSATIONS_TABLE, TRACK_SUFFIX.TABLE.PAGING]), message: newPage });
    fetchData({ 'page-number': newPage });
    setPageNumber(newPage);
  };

  useQueryDateChange(onQueryDateChange);

  useEffect(() => {
    const returnedFromOutsideToNonInitialPage = pageNumber !== initialPageNumber && query && !prevQuery;
    if (!returnedFromOutsideToNonInitialPage && (!prevQuery || (query && query !== prevQuery))) {
      fetchInitPage();
    } else {
      fetchData();
    }
  }, [query]);

  useEffect(() => {
    componentLoadingTimeService.start(TRACK_IDS.SOURCES.SEARCH_PAGE);
  }, []);

  return (
    <Page title={t('title')} isLoading={!data || isLoading} fullTimeRange={data && data.dateTimeRange} error={error} shouldShowErrorPage={!!error}>
      <RelevantConversations
        columns={getColumns(query)}
        pageNumber={pageNumber}
        onPageChanged={onPageChanged}
        data={data}
        isLoading={!data || isLoading}
        error={error}
      />
    </Page>
  );
};
export default SearchPage;
