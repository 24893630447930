import React from 'react';
import { TagsContainer } from './StyledTagsCell';
import { Pills } from '../PillsCell/PillsCell';

const TagsCell = ({ data }) => (
  <TagsContainer>
    <Pills data={data} />
  </TagsContainer>
);

export default TagsCell;
