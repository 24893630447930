export enum CrmProdRegion {
  crm = 'crm',
  crm2 = 'crm2',
  crm3 = 'crm3',
  crm4 = 'crm4',
  crm5 = 'crm5',
  crm6 = 'crm6',
  crm7 = 'crm7',
  crm8 = 'crm8',
  crm10 = 'crm10',
  crm11 = 'crm11',
  crm12 = 'crm12',
  crm14 = 'crm14',
  crm15 = 'crm15',
  crm16 = 'crm16',
  crm17 = 'crm17',
  crm19 = 'crm19',
  crm21 = 'crm21',
  microsoftsales = 'microsoftsales.crm.dynamics.com',
  msxfre = 'msxfre.crm.dynamics.com',
  msxtest = 'msx-test.crm.dynamics.com',
  cinam2e2e = 'ci-nam2-e2e.crm.dynamics.com',
  d365salesxr = 'd365salesxr.crm.dynamics.com',
}

export type CrmRegion = keyof typeof CrmProdRegion;
