import styled from 'styled-components';
import { FlexRowCentered, colors } from 'ci-common-ui';
import { SectionCardContainer } from '../../../components/SectionCard/StyledSectionCard';
import { STATUS_NAMES } from '../../constants';

export const Container = styled(SectionCardContainer)`
  padding: 8px;
`;

export const FilterWrapper = styled(FlexRowCentered)`
  justify-content: space-between;
`;

export const filterPivotStyles = {
  root: {
    whiteSpace: 'initial'
  }
};

export const Progress = styled(FlexRowCentered)`
  width: 100%;
  height: 100%;
  
  & > div {
    margin: 0 4px;
    width: 100px;
  }
`;

export const StatusCellContainer = styled(FlexRowCentered)`
  height: 100%;
  cursor: ${({ clickable }) => clickable ? 'pointer' : 'auto'};
`;

export const Circle = styled(FlexRowCentered)`
  justify-content: center;
  width: 14px;
  height: 14px;
  color: white;
  border-radius: 50%;
  margin: 0 4px;
  background-color: ${({ status }) => {
    switch (status) {
      case STATUS_NAMES.failed:
        return colors.ScarletRed;
      case STATUS_NAMES.ignored:
        return colors.Gray130;
      case STATUS_NAMES.completed:
        return colors.JadeGreen;
      default:
        return colors.JadeGreen;
    }
  }};
`;
