import React, { useState } from 'react';
import { IconButton, Text } from '@fluentui/react';
import {
  CardContainer,
  BlockedIconContainer,
  CardHeader,
  IconContainer,
  HeaderTextContainer,
  titleStyle,
  subTitleStyle,
  CardBody,
  CardBodyText,
  DotsContainer,
  IconCardParentContainer,
  bodyTextStyle
} from './StyledIconCard';
import DotsSvg from '../../assets/images/dots.svg';
import { HighContrastThemeAwareFocusableContainer, BlockedIcon } from '../../utils/styles/StyledUtils';
import { ARIA_ROLES } from '../../constants/roles-list';
import { ENTER_KEY_CODE } from '../../constants/key-codes';

/**
 * Fluent UI card
 * @param IconCardProps
 * title: localized title of the card
 * subtitle: localized sub title of the card
 * Icon: JSX icon
 * bodyText: text of the body of the card
 * isDisabled: if set to true there is a blocked icon within the card
 * onClick: callback for on card click
 * cardActionMenuProps: Fluent UIs IContextualMenuProps.
 * If given, three dots with additional action will be rendered on the top right corner of the card.
 * @returns a React component
 */
export const IconCard = ({
  title,
  subtitle,
  CardIcon,
  bodyText,
  isDisabled,
  onClick,
  cardActionMenuProps,
}) => {
  const [isCardActionButtonFocused, setIsCardActionButtonFocused] = useState(false);

  const handleOnFocus = () => {
    setIsCardActionButtonFocused(true);
  };
  const handleOnBlur = () => {
    setIsCardActionButtonFocused(false);
  };

  const handleEnterClick = (e) => {
    if (e.key === ENTER_KEY_CODE && !isCardActionButtonFocused) {
      e.preventDefault();
      onClick();
    }
  };

  return (
   <IconCardParentContainer>
    <HighContrastThemeAwareFocusableContainer
      role={ARIA_ROLES.BUTTON}
      onKeyDown={handleEnterClick}
      aria-label={`Configure ${title}`}
    >
      <CardContainer
        data-testid="IconCard"
        aria-label={`Configure ${title}`}
        onClick={onClick}
        disabled={isDisabled}
      >
        <CardHeader>
          <IconContainer>
            <CardIcon width="44px" height="44px" />
          </IconContainer>
          <HeaderTextContainer>
            <Text style={titleStyle}>{title}</Text>
            <Text style={subTitleStyle}>{subtitle}</Text>
          </HeaderTextContainer>
        </CardHeader>
        <CardBody>
          {isDisabled && (
            <BlockedIconContainer>
              <BlockedIcon />
            </BlockedIconContainer>
          )}
          <CardBodyText>
            <Text style={bodyTextStyle}>{bodyText}</Text>
          </CardBodyText>
        </CardBody>
       </CardContainer>
      </HighContrastThemeAwareFocusableContainer>
      {cardActionMenuProps && (
        <DotsContainer>
          <IconButton
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            data-testid="IconCardActionButton"
            onRenderMenuIcon={() => null}
            menuProps={cardActionMenuProps}
            onRenderIcon={() => <DotsSvg />}
            ariaLabel="More"
          />
        </DotsContainer>
      )}
    </IconCardParentContainer>
  );
};
