import React from 'react';
import { PageSectionContainer, SectionSubtitle, SectionTitle } from './StyledPageSection';

const PageSection = ({
  title, subtitle, isLoading, children
}) => (
  <PageSectionContainer isLoading={isLoading}>
    <SectionTitle>{title}</SectionTitle>
    { subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle> }
    {children}
  </PageSectionContainer>
);
export default PageSection;
