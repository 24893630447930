import React, { useState } from 'react';
import { ActivityItem } from '@fluentui/react';
import { Action, ActivityDescriptionContainer, ActivityDescriptionMain, ActivityItemContainer, activityItemStyles, CommentActions, Name, CommentIcon } from './StyledComment';
import CommentEditor from '../CommentEditor/CommentEditor';
import { timeAgo } from '../../../../utils/date/date';

export const Comment = ({
  commenterFullName,
  canEdit,
  onUpdateComment,
  onDeleteComment,
  timestamp,
  content,
  texts,
  isRTL,
  isCommentActionInProgress
}) => {
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);

  const onRenderCommentTitle = () => (
    <ActivityDescriptionContainer>
      <ActivityDescriptionMain>
        <CommentIcon />
        <Name>{commenterFullName}</Name>
        &nbsp;
        {texts.commented}
      </ActivityDescriptionMain>
      {
        canEdit && !isBeingEdited && (
          <CommentActions>
            <Action isRTL={isRTL} disabled={isBeingEdited} onClick={() => setIsBeingEdited(true)}>{texts.edit}</Action>
            <Action isRTL={isRTL} disabled={isCommentActionInProgress} onClick={onDeleteComment}>{texts.delete}</Action>
          </CommentActions>
        )
      }
    </ActivityDescriptionContainer>
  );

  const getCommentContent = () => {
    if (isBeingEdited) {
      return (
        <CommentEditor
          isLoading={isUpdateInProgress}
          saveText={texts.save}
          cancelText={texts.cancel}
          initialContent={content}
          onCancelClicked={() => setIsBeingEdited(false)}
          onSaveClicked={async (commentText) => {
            setIsUpdateInProgress(true);
            await onUpdateComment(commentText);
            setIsUpdateInProgress(false);
            setIsBeingEdited(false);
          }}
        />
      );
    }
    return content;
  };

  return (
    <ActivityItemContainer>
      <ActivityItem
        styles={activityItemStyles}
        activityDescription={[
          <Name>{commenterFullName}</Name>,
          texts.commented,
        ]}
        onRenderActivityDescription={onRenderCommentTitle}
        comments={[getCommentContent()]}
        timeStamp={timeAgo(timestamp)}
      />
    </ActivityItemContainer>
  );
};
