import React from 'react';
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import { Item, Title, Container } from './StyledKpisBar';

const KpisBar = ({ translatedTitlesAndComponents, className }) => {
  const content = map(translatedTitlesAndComponents, ({ title, component }) => (
    <Item key={title}>
      <Title noMarginRight={isNil(component)}>{title}</Title>
      {component}
    </Item>
  ));

  return <Container className={className}>{ content }</Container>;
};

export default KpisBar;
