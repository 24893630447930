import React from 'react';
import Chart from '../../../components/Chart/Chart';
import withEmptyState from '../../../components/WithEmptyState/with-empty-state';
import { PivotChartContainer } from './StyledChartPivot';
import { ExportToCSV } from '../../../components/ExportToCSV/ExportToCSV';

const ChartPivotItem = ({
  csvData,
  getChartConfig,
  trackId,
  errorComponent,
}) => (
  errorComponent || (
    <PivotChartContainer>
      <Chart config={getChartConfig()} trackId={trackId} />
      <ExportToCSV data={csvData} trackId={trackId} />
    </PivotChartContainer>
  )
);

export default withEmptyState(ChartPivotItem);
