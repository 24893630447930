/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import useLocale from 'hooks/use-locale';
import { useAppNavigation } from 'hooks/use-app-navigation';
import { FlexRowCentered, PROVISION_STATES, Trans } from 'ci-common-ui';
import { get } from 'lodash';
import { Bold, Text } from '../StyledNotificationBars';
import { QUERY_PARAMS } from '../../../constants/urls';
import { useAppState } from '../../../providers/AppStateProvider';
import { MODALS, settingsModalTabs } from '../../../constants/modals';
import { appContext, getIsOnboarded } from '../../../utils/app-context/app-context';
import { postOrg } from '../../../network/rest-services/settings-rest-service';
import DataCacheService from '../../../services/DataCacheService';
import { eventAction } from '../../../services/telemetry-service/consts';
import { TRACK_IDS } from '../../../constants/tracking';
import { trackEvent } from '../../../services/telemetry-service/telemetry-service';
import { Link } from '../../Link/Link';
import DismissibleBar from './DismissibleBar';
import { notificationBarsTypes } from '../notification-bars-types';
import useNotificationBar from '../../../hooks/use-notification-bar';

const LOCALISATION_PREFIX = 'components.notification_bar.provision';

const tryAgainProvision = async (setAppState) => {
  setAppState({ isLoading: true });
  trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.TRY_AGAIN_PROVISION });
  const { orgId, orgUrl, clusterRegion } = appContext;
  await postOrg({ id: orgId, url: orgUrl }, clusterRegion);
  DataCacheService.removeAll();
  window.location.reload();
};

const ProvisionBar = ({ data }) => {
  const { t, appModeAwareTerms } = useLocale(LOCALISATION_PREFIX);
  const { removeNotificationBar } = useNotificationBar();
  const { navigateTo } = useAppNavigation();
  const { setAppState } = useAppState();
  const provisionState = get(data, 'provisionState', PROVISION_STATES.None);
  const failure = PROVISION_STATES.ProvisionFailed === provisionState;
  const openOnboardingModal = () => setAppState({ modalName: MODALS.Onboarding });
  const onDismiss = () => removeNotificationBar(notificationBarsTypes.Provision);

  return (
    <DismissibleBar onDismiss={onDismiss}>
      <FlexRowCentered>
        <Text>
          { !getIsOnboarded()
            ? (
              <Trans i18nKey={`${LOCALISATION_PREFIX}.start`} values={{ _demo_app: t('demo_app'), _app_name: appModeAwareTerms._app_name }}>
                <Bold>text</Bold>
                desc1
                <Link onClick={openOnboardingModal}>text</Link>
              </Trans>
            )
            : failure
              && (
              <Trans i18nKey={`${LOCALISATION_PREFIX}.failure`}>
                <Link onClick={() => tryAgainProvision(setAppState)}>tryAgainFrag</Link>
                <Link onClick={() => navigateTo({ params: { [QUERY_PARAMS.SETTINGS]: settingsModalTabs.datasource.link } })}>goToSettingsFrag</Link>
                endFragment
              </Trans>
              )}
        </Text>
      </FlexRowCentered>
    </DismissibleBar>
  );
};
export default ProvisionBar;
