import React from 'react';
import useLocale from 'hooks/use-locale';
import { get } from 'lodash';
import { getIsAdminUser, getIsOnboarded, getIsTrialMode } from 'utils/app-context/app-context';
import SettingsForm from '../../SettingsForm/SettingsForm';
import DataSourceWrapper from './DataSourceWrapper';
import DataSource, { OrgWrapper } from '../../../../../components/Settings/DataSource/DataSource';

const DataSourceTab = ({ closeModal }) => {
  const { t } = useLocale('pages.settings.data_source');
  const isAdmin = getIsAdminUser();
  const isUnprovisioned = !getIsOnboarded();
  const isTrailMode = getIsTrialMode();

  if (!isAdmin || isUnprovisioned || isTrailMode) {
    return (
      <DataSourceWrapper closeModal={closeModal}>
        <OrgWrapper isUnprovisioned={isUnprovisioned} />
      </DataSourceWrapper>
    );
  }

  return (
    <SettingsForm
      tab="dataSource"
      title={t('nav_title')}
      closeModal={closeModal}
      render={({ isLoadingSettingsData, settingsData }) => (
        <>
          {!get(settingsData, 'useInternalStorage') && (
            <DataSource isLoadingSettingsData={isLoadingSettingsData} initialValues={settingsData} />
          )}
          <OrgWrapper />
        </>
      )}
    />
  );
};

export default DataSourceTab;
