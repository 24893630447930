import { serverApi, BASE_URLS, DEFAULT_GRAPH_API_IMAGE_SIZE, TELEMETRY_EVENTS_API_PATH } from 'ci-common-ui';
import { toNewBackendUrl } from 'utils/url/url';
import { getIsAdminUser } from 'utils/app-context/app-context';

const getSettingsContextQueryParams = () => ({ 'settings-context': getIsAdminUser() ? 'org' : 'user' });

const ONE_DAY = 24 * 60 * 60000;

export async function getViewingAs() {
  return serverApi.GET({ path: '/user/v1.0-preview/actions/get-view-as', options: { cacheOptions: { cache: false }, baseUrl: BASE_URLS.NEW_API_SERVER } });
}

export async function getUserSettings() {
  const res = await serverApi.GET({ path: '/user/v1.0-preview/agents/actions/settings', options: { baseUrl: BASE_URLS.NEW_API_SERVER }});
  return res;
}

export const getOrg = async (orgId, clusterUri = false) => {
  const res = await serverApi.GET({ path: `/user/v1.0-preview/organizations/${orgId}`, queryParams: getSettingsContextQueryParams(), options: { baseUrl: clusterUri ? toNewBackendUrl(clusterUri) : BASE_URLS.NEW_API_SERVER, cacheOptions: { cache: false }, acceptedErrorCodes: [404] } });
  return res.data;
}

export async function getO365ShellData() {
  const res = await serverApi.GET({ path: '/user/v1.0-preview/beta/shell/o365-shell-data', options: { skipCamelCaseResponse: true, baseUrl: BASE_URLS.NEW_API_SERVER } });
  return res;
}

export async function getAllAppsForUserWorldWide() {
  return serverApi.GET({ path: '/user/v1.0-preview/provisioning/apps-for-user-worldwide', options: { isAppsForUserWorldWideCall: true, cacheOptions: { cache: false }, baseUrl: BASE_URLS.NEW_API_SERVER } });
}

export const getUserLicenseStatus = (orgId) => serverApi.GET({ path: `/user/v1.0-preview/organizations/${orgId}/actions/get-user-license-status`, options: { baseUrl: BASE_URLS.NEW_API_SERVER } });

export async function getProvisioningStatus(orgId) {
  const res = await serverApi.GET({ path: `user/v1.0-preview/organizations/${orgId}/provisioning/status`, options: { baseUrl: BASE_URLS.NEW_API_SERVER, cacheOptions: { cache: false } } });
  return res;
}

export async function getOrganizationCurrencySymbol(orgId) {
  const res = await serverApi.GET({ path: `user/v1.0-preview/organizations/${orgId}/currency-symbol`, options: { baseUrl: BASE_URLS.NEW_API_SERVER } });
  return res;
}

export const sendEvents = (events) => serverApi.POST({ path: TELEMETRY_EVENTS_API_PATH, body: { events }, options: { baseUrl: BASE_URLS.NEW_API_SERVER } });

export async function getAvatar({ aadId, token, size = DEFAULT_GRAPH_API_IMAGE_SIZE }) {
  const res = await serverApi.GET({
    path: `/v1.0/users/${aadId}/photos/${size}x${size}/$value`,
    headers: { Authorization: `Bearer ${token}` },
    options: {
      cacheOptions: { maxAge: ONE_DAY },
      baseUrl: BASE_URLS.MICROSOFT_GRAPH_SERVER,
      responseType: 'blob',
      acceptedErrorCodes: [404],
      ignoreJsonContentType: true
    }
  });
  return res.data;
}
