import styled, { css } from 'styled-components';
import { rtl, FlexColumn, colors } from 'ci-common-ui';
import { FontSizes } from '@uifabric/fluent-theme';
import { ActionButton, Icon } from '@fluentui/react';
import { AvatarPersona } from '../AvatarPersona/AvatarPersona';

export const getEntityIcon = (icon) => styled(icon)`
  height: 16px;
  width: 16px;
  position: relative;
`;

export const PlaceHolder = styled.span`
  ${rtl`margin-right: 12.5px`};
  ${rtl`margin-left: 12.5px`};
`;

export const TooltipContentContainer = styled(FlexColumn).attrs({
  as: 'span'
})`
  font-size: ${FontSizes.size14};
  justify-content: space-between;
  align-items: center;
  ${rtl`margin-right: 15px`};
  ${rtl`margin-left: 15px`};
`;

export const TooltipTitle = styled.span`
  font-weight: 600;
  margin-bottom: 3px;
`;

export const TooltipSubtitle = styled.span``;

export const Name = styled.span`
  text-overflow: ellipsis;
  max-width: ${({ isEditMode }) => isEditMode ? '250px' : '105px'};
  overflow: hidden;
  ${rtl`margin-left: 6px`};
  color: ${colors.Gray130};
  display: table-cell;
`;

export const IconAndNameContainer = styled.span.attrs({ as: ActionButton })`
  ${rtl`margin-right: 6px`};
  ${rtl`margin-left: 6px`};
  &:first-child {
    margin: 0px;
  }
  &:last-child {
    margin: 0px;
  }
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  white-space: nowrap;
  transition: opacity 2s;
  opacity: ${({ isClickable, shouldShowName }) => !isClickable && !shouldShowName ? '0.15' : '1'};
  ${({ isClickable, shouldShowPlusMoreContacts }) => isClickable && !shouldShowPlusMoreContacts ? `
    :hover {
      ${Name} {
        color: ${colors.DYNPrimary};
      }
      svg {
        fill: ${colors.DYNPrimary};
      }
    }
    `
    : shouldShowPlusMoreContacts ? 'cursor: pointer' : 'cursor: auto'};
`;

const IconStyles = (color) => `
  color: ${color};
  height: 100%;
  padding: 0;
  width: 20px;
  margin: 0px 2px;
  transform: translateY(1px);
  text-align: center;
  :hover {
    color: ${color};
    background-color: ${colors.Gray20};
    cursor: pointer;
  }
`;

export const RemoveButton = styled(Icon).attrs({
  iconName: 'Cancel',
  ariaLabel: 'Remove entity'
})`
  ${IconStyles(colors.Red)}
`;

export const Record = styled.div`
  font-size: 14px;
  ${({ isEditMode }) => !isEditMode && 'display: inline'};
  ${({ isEditMode }) => isEditMode && 'margin-right: 10px'};
`;

export const Records = styled.div`
  display: flex;
  flex-direction: ${({ isEditMode }) => isEditMode ? 'column' : 'row'};
`;

export const ContactPersona = styled(AvatarPersona)`
  padding: 8px 12px;
  height: 52px;
`;

export const PersonaWrapper = styled.div`
  padding: 4px;
  :hover {
    background-color: ${colors.Gray20};
    cursor: pointer;
  }
`;

export const ContcatsTitle = styled.div`
  width: 90%;
  margin: 0px 6px;
  font-size: 12px;
  color: ${colors.Gray130};
  font-weight: 600;
  padding: 8px;
  border-bottom: 1px solid ${colors.Gray20}; 
`;

export const PlusMoreButton = styled(ActionButton).attrs({
  ariaLabel: 'Show more contacts'
})`
  height: 12px;
  color: ${colors.DYNShade10};
  ${rtl`padding-left: 0`};
`;

export const RecordWrapper = styled.div`
 ${({ shouldShowPlusMoreContacts }) => shouldShowPlusMoreContacts && css`
  :hover {
    color: ${colors.DYNShade10};
    cursor: pointer;
    :hover {
      ${Name} {
        color: ${colors.DYNPrimary};
      }
      svg {
        fill: ${colors.DYNPrimary};
      }
    }
  `}
`;
