import { downloadScript } from './download-script';
import { getLocaleByLCID } from '../constants/locales';
import { eventAction } from '../services/telemetry-service';
import { TRACK_IDS } from '../constants/tracking';

const scriptSrc = 'https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/Embed.js';
const cssSrc = 'https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/Embed.css';

const renderSurvey = ({ formsProId, formsProEligibilityId, locale, tenantId, orgId }) => {
  const se = new window.SurveyEmbed(formsProId, 'https://customervoice.microsoft.com/', 'https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/', 'true');
  const context = { FormsProEligibilityId: formsProEligibilityId, locale, TenantId: tenantId, OrganizationId: orgId }; // https://dynamics.wiki/index.php/Customer_Experience_Surveys_(CES)_API_Documentation#EU_Schrems
  se.renderPopup(context);
};

export const loadFeedback = async ({ formsProId, formsProEligibilityId, orgLCID, tenantId, orgId, trackEvent }) => {
  trackEvent({action: eventAction.info, actionOn: TRACK_IDS.BEFORE_RENDER_FEEDBACK_SURVEY, message: { formsProId, formsProEligibilityId, tenantId }});
  const locale = getLocaleByLCID(orgLCID);
  const hasScriptLoaded = await downloadScript(scriptSrc, 'loadFeedbackScript');
  const hasCssLoaded = await downloadScript(cssSrc, 'loadFeedbackCss', null, true);
  if (hasScriptLoaded && hasCssLoaded) {
    renderSurvey({ formsProId, formsProEligibilityId, locale, tenantId, orgId });
    trackEvent({action: eventAction.info, actionOn: TRACK_IDS.AFTER_RENDER_FEEDBACK_SURVEY, message: { formsProId, formsProEligibilityId, tenantId }});
  }
};
