import qs from 'query-string';
import assign from 'lodash/assign';
import mapKeys from 'lodash/mapKeys';
import isEmpty from 'lodash/isEmpty';
import { storageService, userRoles } from 'ci-common-ui';
import { GET_ALL_APPS_KEY } from '../../constants/browser-storage';
import DataCacheService from '../../services/DataCacheService';
import { constantQueryParamsObj } from '../../constants/urls';

// Interfaces

const Default = 'Default';
const Embed = 'Embed';
const CustomerService = 'CustomerService';

export const appModes = {
  Default,
  Embed,
  CustomerService,
};

// appMode initial value (will be filled on app first load -> app-service.js)
// eslint-disable-next-line import/no-mutable-exports
export let appContext = {
  appId: undefined,
  orgId: undefined,
  orgUrl: undefined,
  isDefault: true,
  clusterRegion: undefined,
  appMode: appModes.Default,
  currencySymbol: undefined,
  isTrialCreated: undefined,
  isOrgStorageTypeDvOnly: undefined,
  user: {
    id: undefined,
    name: '',
    username: undefined,
    userRoles: [userRoles.SalesPerson, userRoles.SalesManager],
    features: [],
    deprecationDate: new Date(2019, 8, 1),
    isDemo: true
  }
};

export const initAppContext = (newContext = {}, url = window.location.href) => {
  const appMode = extractMode(url);
  // make sure the userRoles default is not override if the new userRoles is null
  // eslint-disable-next-line no-param-reassign
  newContext.user.userRoles = newContext.user.userRoles || appContext.user.userRoles;
  appContext = assign(appContext, newContext, { appMode });
};

// Org details

export const getOrgId = () => appContext.orgId;

export const getOrgUrl = () => appContext.orgUrl;

export const getIsOrgStorageTypeDvOnly = () => appContext.isOrgStorageTypeDvOnly;

// User Roles

export const getIsDemoUser = () => appContext.user.isDemo;

export const getUserRoles = () => (getIsDemoUser() ? [userRoles.SalesManager, userRoles.SalesPerson] : appContext.user.userRoles);

export const getIsAdminUser = () => getUserRoles().includes(userRoles.SystemAdministrator) || getIsTrialMode();

export const getIsManagerUser = () => getUserRoles().includes(userRoles.SalesManager) && !getIsAdminUser();

export const getIsSellerUser = () => getUserRoles().includes(userRoles.SalesPerson) && !getIsManagerUser() && !getIsAdminUser();

// App Mode

export const getIsDefaultMode = () => appContext.appMode === appModes.Default;

export const getIsEmbedMode = () => [appModes.Embed, appModes.CustomerService].includes(appContext.appMode);

export const getIsCustomerServiceMode = () => appContext.appMode === appModes.CustomerService;

export const extractMode = (url) => {
  const params = qs.parse(qs.extract(url));
  const queryParams = mapKeys(params, (val, key) => key.toLowerCase());
  if (queryParams[constantQueryParamsObj.customerService.toLowerCase()]) return appModes.CustomerService;
  if (queryParams[constantQueryParamsObj.embed.toLowerCase()]) return appModes.Embed;
  return appModes.Default;
};

// AppsForUserWorldWide

export const getAllAppsFromLocalStorage = () => storageService.localStorage.getObject(GET_ALL_APPS_KEY);

export const setAllAppsToLocalStorage = (apps) => storageService.localStorage.setObject(GET_ALL_APPS_KEY, apps);

export const filterOutDeprovisionedApps = (apps) => !isEmpty(apps) ? apps.filter((app) => !app.markForDeprovision) : [];

export const getValidApps = () => filterOutDeprovisionedApps(getAllAppsFromLocalStorage());

// Provisioning

export const getIsOnboarded = () => !isEmpty(getValidApps());

export const getIsTrialMode = () => appContext.isTrialCreated;

// CACHE

export const clearAllAppRelatedCache = () => {
  storageService.localStorage.removeItem(GET_ALL_APPS_KEY);
  DataCacheService.removeAll();
};

export const resetTheApp = (shouldRouteToRoot = false) => {
  clearAllAppRelatedCache();
  if (shouldRouteToRoot) {
    window.location.href = '/';
  } else {
    window.location.reload();
  }
};
