import { get } from 'lodash';
import { formatMonthDayDate } from '../../../../utils/date/date';

export default () => (
  {
    accessibility: {
      point: {
        descriptionFormatter: ({ day, series, y }) => `${formatMonthDayDate(day)}, a ${get(series, 'name', '')} graph with a point value of ${y}.`
      },
      screenReaderSection: {
        beforeChartFormat: '<div></div>'
      },
      keyboardNavigation: {
        order: ['legend', 'series']
      },
    }
  }
);
