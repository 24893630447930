/* eslint-disable no-mixed-operators */
import styled from 'styled-components';
import capitalize from 'lodash/capitalize';
import { FlexRowCentered, Stretch } from '../../../../utils/styles/StyledUtils';
import { rtl } from '../../../../utils/styles/rtl';
import { TIMELINE_ITEM_HEIGHT } from '../../utils';
import { SENTIMENT_NAMES } from '../../../../constants/api';
import { ParticipantPersona } from '../../StyledCallPage';

export const timelineLeftMargin = '140px';

export const StyledTimeline = styled(FlexRowCentered)`
  height: ${TIMELINE_ITEM_HEIGHT}px;
`;

export const TimelineContainer = styled(FlexRowCentered)`
  position: relative;
  width: 100%;
  height: 12px;
  min-width: 500px;
  transition: opacity 0.3s ease;

  ${({ isFade }) => isFade && `
    opacity: 0;
  `}
`;

export const SideGap = styled(FlexRowCentered)`
  width: ${timelineLeftMargin};
  ${rtl`padding-left`}: 20px;
  flex-shrink: 0;
`;

export const Avatar = styled(ParticipantPersona)``;

export const Block = styled.div`
  height: 16px;
  width: 6px;
  margin: 0 auto;
  margin-right: 1px;
  z-index: 1;
  background: ${({ theme, sentimentType }) =>
    sentimentType?.toLowerCase() === SENTIMENT_NAMES.neutral ? theme.colors.Gray110 : theme.colors[capitalize(sentimentType)]
  };
  opacity: ${({ opacity }) => opacity}; 
  border-radius: ${({ sentimentType }) => sentimentType === SENTIMENT_NAMES.neutral ? '4px' : sentimentType === SENTIMENT_NAMES.negative ? '0px 4px 4px 0px' : '4px 0px 0px 4px'};
`;

export const Line = styled.div`
  background-color: ${({ theme }) => theme.colors.Gray40};
  width: 100%;
  height: 8px;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
`;

const MarkContainer = styled.div`
  position: absolute;
  top: -1px;
  z-index: 3;
  ${rtl`left`}: calc(${({ pointStart }) => pointStart}%);
`;

export const KeywordContainer = styled(MarkContainer)`
  width: 14px; 
  height: 14px;
  background: white; 
  transform: translateX(-50%) rotate(45deg);
  padding: 2px; 
`;

export const CommentContainer = styled(MarkContainer)`
 z-index: 6;
`;

export const InnerKeyword = styled(Stretch).attrs({
  $height: true,
  $width: true
})`
    background: ${({ theme }) => theme.colors.DYNPrimary};
    
   ${({ isHovered }) => isHovered && `
      background: ${({ theme }) => theme.colors.Gray110};
  `}
`;
