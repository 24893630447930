import { Icon, ActionButton } from '@fluentui/react';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { rtl } from '../../../utils/styles/rtl';
import { StyledText } from '../../../utils/styles/StyledUtils';

export const Container = styled.div`
  padding: 8px 16px;
  width: 100%;
  height: 100%;
`;

export const ChevronIcon = styled(Icon).attrs(({ isExpanded }) => ({
  iconName: 'ChevronUp',
  styles: {
    root: {
      transform: isExpanded ? 'rotate(0deg)' : 'rotate(180deg)',
      transition: 'transform 0.2s ease-in-out',
    },
  },
}))`
  font-size: 8px;
`;

export const ExpandableItemContainer = styled.div`
  margin-bottom: 20px;
`;

export const OpenActivityWrapper = styled.div``;

export const ExpandableItemTitleContainer = styled(ActionButton).attrs({
  color: colors.Gray130,
  cursor: 'pointer',
})``;

export const ItemTitle = styled(StyledText)`
  font-size: 12px;
  ${rtl`margin-right`}: 10px;
  color: ${colors.Gray130};
`;

export const ItemBody = styled.div`
  padding-top: 0px;
`;

export const ExpandableItemBody = styled(ItemBody)`
  display: ${({ hide }) => (hide ? 'none' : 'block')};
`;

export const OpenActivityButton = styled(ActionButton).attrs({
  iconProps: { iconName: 'open_folder' },
  styles: {
    icon: {
      height: '20px',
    },
  },
})``;
