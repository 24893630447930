import styled from 'styled-components';
import { colors, FlexRow } from 'ci-common-ui';
import { ActionButton } from '@fluentui/react';

export const Container = styled(FlexRow)``;

export const FavoriteIcon = styled(ActionButton).attrs(({ isFavorite }) => ({
  iconProps: { iconName: isFavorite ? 'FavoriteStarFill' : 'FavoriteStar' }
}))`
  cursor: pointer;
  ${({ isFavorite }) => isFavorite && `color: ${colors.DYNPrimary};`}
  &:hover {
    color: ${colors.Black};
  }
`;

export const DeleteIcon = styled(ActionButton).attrs(({
  iconProps: { iconName: 'Cancel' }
}))`
  cursor: pointer;
  &:hover {
    color: ${colors.Black};
  }
`;
