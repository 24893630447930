import React, { useEffect } from 'react';
import { formatMonthDayYearDate, dayJS } from 'utils/date/date';
import useLocale from 'hooks/use-locale';
import { getOrganizationList } from 'network/data-services/settings';
import { useFetchNow } from 'ci-common-ui';
import get from 'lodash/get';
import { find } from 'lodash';
import { AboutContainer, AboutRibon, Body, Title, Row, Loader, CloseButton } from './StyledAboutModal';
import { buildVersion } from '../../constants/config';
import { appContext } from '../../utils/app-context/app-context';
import { getSessionId } from '../../network/server-api-utils';
import { useOverridePageTracking } from '../../hooks/use-override-page-tracking';
import { TRACK_IDS } from '../../constants/tracking';
import { componentLoadingTimeService } from '../../services/component-loading-time-service/component-loading-time-service';

const getOrgName = (organizationList) => {
  const { orgId } = appContext;
  const currentOrg = find(organizationList, ({ id }) => id === orgId);
  return get(currentOrg, 'friendlyName');
};

const AboutBody = () => {
  const { t } = useLocale('pages.about');
  const { data: organizationList, isLoading: isLoadingOrganizationList } = useFetchNow(getOrganizationList);

  useEffect(() => {
    if (!isLoadingOrganizationList) {
      componentLoadingTimeService.end(TRACK_IDS.SOURCES.ABOUT_MODAL);
    }
  }, [isLoadingOrganizationList]);

  if (isLoadingOrganizationList) {
    return <Loader />;
  }

  const rows = [
    { key: 'client_version', content: buildVersion },
    { key: 'organization_name', content: getOrgName(organizationList) },
    { key: 'session_id', content: getSessionId() },
    { key: 'time', content: formatMonthDayYearDate(dayJS()) }
  ].filter(({ content }) => content);

  return (
    <Body>
      {rows.map((row) => (
        <Row key={row.key}>
          {t(row.key)}: {row.content}
        </Row>
      ))}
    </Body>
  );
};

const AboutModal = ({ closeModal }) => {
  const { t, appModeAwareTerms } = useLocale('common');
  useOverridePageTracking(TRACK_IDS.SOURCES.ABOUT_MODAL);

  useEffect(() => {
    componentLoadingTimeService.start(TRACK_IDS.SOURCES.ABOUT_MODAL);
  }, []);

  return (
    <AboutContainer>
      <AboutRibon>
        <Title>{appModeAwareTerms._app_name}</Title>
        <CloseButton iconProps={{ iconName: 'Cancel' }} ariaLabel={`${t('close')} ${t('about')}`} onClick={closeModal} />
      </AboutRibon>
      <AboutBody />
    </AboutContainer>
  );
};

export default AboutModal;
