/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect } from 'react';
import { DefaultButton, Icon, Slider } from '@fluentui/react';
import get from 'lodash/get';
import { durationToTime } from '../../../../utils/date/date';
import { soundStatus, DEFAULT_VOLUME } from '../../../../constants/howler';
import { useSound } from '../../../../hooks/use-sound';
import { eventAction } from '../../../../services/telemetry-service';
import { FlexRowCentered } from '../../../../utils/styles/StyledUtils';
import {
  Container, AudioPlayerContainer, MainButtonsContainer, playButtonStyles, playIconStyles,
  VolumeContainer, TimeLabelContainer, forwardButtonStyles, volumeSliderStyles, Duration, CurrentTime, PlayIcon, forwardIconStyles
} from './StyledAudioPlayer';
import { TRACK_IDS } from '../../tracking';
import { useInfra } from '../../../../providers/InfraProvider';
import { isDirectionRTL } from '../../../../utils/dom/dom';
import { useSoundProxy } from '../../../../hooks/use-sound-proxy';

const VOLUME_MIN = 0.0;
const VOLUME_MAX = 1.0;
const VOLUME_STEP = 0.05;
const SKIP_INTERVAL = 10;

export const SkipButton = ({ isBack, isRTL, isLoaded, time, segmentation = [], onClick }) => {
  const { trackEvent } = useInfra();

  const currentSegmentIndex = segmentation.findIndex(({ start, end }) => time >= start && time < end);
  const nextSegment = segmentation[currentSegmentIndex + (isBack ? -1 : 1)];
  const isDisabled = !isLoaded || !nextSegment;

  const onForwardClick = () => {
    if (nextSegment) {
      onClick(nextSegment.start - time);
      trackEvent({ action: eventAction.click, actionOn: isBack ? TRACK_IDS.AUDIO_PLAYER_BACKWARD : TRACK_IDS.AUDIO_PLAYER_FORWARD, message: nextSegment.start - time });
    }
  };

  return (
    <DefaultButton onClick={onForwardClick} styles={forwardButtonStyles} disabled={isDisabled}>
      <Icon
        iconName="Next"
        styles={forwardIconStyles(isBack ? !isRTL : isRTL)}
        aria-label={isBack ? 'Backward' : 'Forward'}
      />
    </DefaultButton>
  );
};

export const ForwardButton = ({ isBack, isRTL, onClick, isDisabled }) => {
  const { trackEvent } = useInfra();
  const onForwardClick = () => {
    onClick(isBack ? -SKIP_INTERVAL : SKIP_INTERVAL);
    trackEvent({ action: eventAction.click, actionOn: isBack ? TRACK_IDS.AUDIO_PLAYER_SKIP_BACKWARD : TRACK_IDS.AUDIO_PLAYER_SKIP_FORWARD, message: SKIP_INTERVAL });
  };

  return (
    <DefaultButton onClick={onForwardClick} styles={forwardButtonStyles} disabled={isDisabled}>
      <Icon
        iconName="FastForward"
        styles={forwardIconStyles(isBack ? !isRTL : isRTL)}
        aria-label={isBack ? 'Backward' : 'Forward'}
      />
    </DefaultButton>
  );
};

export const PlayButton = ({ isPlaying, togglePlayPause, isLoading, isDisabled }) => {
  const { trackEvent } = useInfra();
  const onPlayClick = () => {
    togglePlayPause();
    trackEvent({ action: eventAction.click, actionOn: isPlaying ? TRACK_IDS.AUDIO_PLAYER_PAUSE : TRACK_IDS.AUDIO_PLAYER_PLAY });
  };

  const getPlayIconName = () => {
    if (isLoading) { return 'Sync'; }
    return isPlaying ? 'Pause' : 'Play';
  };

  return (
    <DefaultButton onClick={onPlayClick} styles={playButtonStyles()} disabled={isDisabled}>
      <PlayIcon
        isLoading={isLoading}
        iconName={getPlayIconName()}
        styles={playIconStyles}
        aria-label={getPlayIconName()}
      />
    </DefaultButton>
  );
};

export const VolumeButton = ({ onChange, isDisabled }) => (
  <FlexRowCentered>
    <Icon iconName="Volume2" />
    <Slider
      styles={volumeSliderStyles}
      min={VOLUME_MIN}
      max={VOLUME_MAX}
      step={VOLUME_STEP}
      defaultValue={DEFAULT_VOLUME}
      showValue={false}
      disabled={isDisabled}
      onChange={onChange}
      aria-label="Volume Slider"
    />
  </FlexRowCentered>
);

export const TimeLabel = ({ duration, currentTime }) => (
  <>
    <CurrentTime>{durationToTime(currentTime)}</CurrentTime>
    <Duration>{` / ${durationToTime(duration)}`}</Duration>
  </>
);

const DefaultTimeline = ({ duration, onChange, value }) => (
  <Slider
    min={0}
    max={duration}
    step={1}
    onChange={onChange}
    value={value || 0}
    showValue={false}
  />
);

export const useMoveTo = (selectedEntity, timeToKey, isPlaying, moveTo) => {
  useEffect(() => {
    const timeTo = get(selectedEntity, timeToKey);
    if (timeTo !== undefined) {
      moveTo(timeTo);
    }
  }, [selectedEntity]);
};

const Timeline = (props) => {
  const { isSmallPlayer, renderTimeline } = props;
  if (isSmallPlayer) { return <DefaultTimeline {...props} />; }
  const TimelineComp = renderTimeline || DefaultTimeline;
  return <TimelineComp {...props} />;
};

export const AudioPlayer = ({
  isAudioEnabled,
  url,
  initialCurrentTime,
  onLoadAudioError,
  onStateChange,
  requestHowlerDataFromIframe,
  renderTimeline,
  defaultDuration,
  timelineProps,
  isSmallPlayer,
  selectedKeyword,
  selectedActionItem,
  selectedSuggestion,
  selectedTranscriptRow,
  comments
}) => {
  const { trackEvent } = useInfra();
  const isRTL = isDirectionRTL();
  const useSoundHook = requestHowlerDataFromIframe ? useSoundProxy : useSound;
  const { forward, moveTo, setVolume, duration, currentTime, isPlaying, status, togglePlayPause, isPlayInLoading, isPauseInLoading } = useSoundHook(url, null, onLoadAudioError, initialCurrentTime, requestHowlerDataFromIframe);
  const isLoading = status === soundStatus.LOADING;
  const isLoaded = isAudioEnabled && status === soundStatus.LOADED;
  const time = Math.round(currentTime);

  useMoveTo(selectedKeyword, 'instances[0].startInSeconds', isPlaying, moveTo);
  useMoveTo(selectedActionItem, 'startInSeconds', isPlaying, moveTo);
  useMoveTo(selectedTranscriptRow, 'startInSeconds', false, moveTo);
  useMoveTo(selectedSuggestion, 'startInSeconds', isPlaying, moveTo);

  useEffect(() => {
    if (!onStateChange) return;

    onStateChange({
      isPlaying,
      duration: duration || defaultDuration,
      value: currentTime
    });
  },
  [isPlaying, duration, currentTime]);

  const onTimelineChanged = (value) => {
    moveTo(value);
    trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.AUDIO_PLAYER_TIMELINE_JUMP, message: value });
  };

  return (
    <Container>
      <Timeline
        duration={duration || defaultDuration}
        onChange={onTimelineChanged}
        value={time}
        timelineProps={timelineProps}
        isSmallPlayer={isSmallPlayer}
        renderTimeline={renderTimeline}
        comments={comments}
        commentsMoveTo={moveTo}
      />
      <AudioPlayerContainer $isSmallPlayer={isSmallPlayer} data-testid="audio-player-container" >
        <MainButtonsContainer>
          <SkipButton isBack isRTL={isRTL} onClick={forward} isLoaded={isLoaded} time={time} segmentation={timelineProps?.segmentation} />
          <ForwardButton isBack isRTL={isRTL} onClick={forward} isDisabled={!isLoaded} />
          <PlayButton isDisabled={!isLoaded || isPlayInLoading || isPauseInLoading} isLoading={isLoading || isPlayInLoading} isPlaying={isPlaying} togglePlayPause={togglePlayPause} />
          <ForwardButton isRTL={isRTL} onClick={forward} isDisabled={!isLoaded} />
          <SkipButton isRTL={isRTL} onClick={forward} isLoaded={isLoaded} time={time} segmentation={timelineProps?.segmentation} />
        </MainButtonsContainer>
        <VolumeContainer>
          <VolumeButton onChange={setVolume} isDisabled={!isLoaded} />
        </VolumeContainer>
        <TimeLabelContainer>
          <TimeLabel duration={duration || defaultDuration} currentTime={currentTime} />
        </TimeLabelContainer>
      </AudioPlayerContainer>
    </Container>
  );
};
