import React from 'react';
import upperFirst from 'lodash/upperFirst';
import filter from 'lodash/filter';
import map from 'lodash/map';
import size from 'lodash/size';
import { Trans } from 'ci-common-ui';
import useLocale from 'hooks/use-locale';
import { extractTextFromHtmlTags } from 'utils/string/string';
import MultiCarousel from '../../../../components/MultiCarousel/MultiCarousel';
import { CarouselCard, InsightsSection, InsightsTitle } from '../../../StyledPages';
import DelimitedInlineList from '../../../../components/DelimitedInlineList/DelimitedInlineList';

const SellerInsights = ({ isLoading, data }) => {
  const { t, tAbs, tDefault, appModeAwareTerms } = useLocale('pages.seller.sections.seller_insights');
  if (isLoading) return null;

  const {
    agentName
  } = data;
  const {
    percentageOfAgentCallsWithPositiveCustomerSentimentAboveAvg,
    agentAndTopSellersTalkToListenRatio,
    agentAndTopSellersWordsPerMinute,
    topKeywordsMissingFromAgentCalls,
  } = data.insights;

  const insights = filter(
    [percentageOfAgentCallsWithPositiveCustomerSentimentAboveAvg && {
      id: 'percentageOfAgentCallsWithPositiveCustomerSentimentAboveAvg',
      text: (
        <Trans i18nKey="pages.seller.sections.seller_insights.cards.positive_sentiment">
          Positive customer sentiment is higher than the avg in <strong>{{ value: percentageOfAgentCallsWithPositiveCustomerSentimentAboveAvg.value }}%</strong> of calls.
        </Trans>
      )
    }, agentAndTopSellersTalkToListenRatio && {
      id: 'agentAndTopSellersTalkToListenRatio',
      text: (
        <Trans i18nKey="pages.seller.sections.seller_insights.cards.talk_listen_ratio">
          {{ agentName }} talks an average of <strong>{{ agentRatio: agentAndTopSellersTalkToListenRatio.agentValue }}</strong> in calls. This is <strong>{{ trend: agentAndTopSellersTalkToListenRatio.trend }}%</strong> {{
            higherOrLower: agentAndTopSellersTalkToListenRatio.agentValue > agentAndTopSellersTalkToListenRatio.topSellersValue
              ? tAbs('common.higher') : tAbs('common.lower')
          }} than your top {{ _representative_plural: appModeAwareTerms._representative_plural }}, who talk an average of <strong>{{ topSellersRatio: agentAndTopSellersTalkToListenRatio.topSellersValue }}</strong> in calls.
        </Trans>
      )
    }, agentAndTopSellersWordsPerMinute && {
      id: 'agentAndTopSellersWordsPerMinute',
      text: (
        <Trans i18nKey="pages.seller.sections.seller_insights.cards.talk_speed">
          {{ agentName }} has an average talking speed of <strong>{{ agentSpeed: agentAndTopSellersWordsPerMinute.agentValue }}</strong> words per minute. Thats <strong>{{ trend: agentAndTopSellersWordsPerMinute.trend }}%</strong> slower than your top {{ _representative_plural: appModeAwareTerms._representative_plural }} average talking speed, which is <strong>{{ topSellersSpeed: agentAndTopSellersWordsPerMinute.topSellersValue }}</strong> words per minute.<br />Industry benchmarks for sales calls suggest that the sweet spot for talking speed is around <strong>175</strong> words per minute.
        </Trans>
      )
    }, topKeywordsMissingFromAgentCalls && {
      id: 'topKeywordsMissingFromAgentCalls',
      text: (
        <Trans i18nKey="pages.seller.sections.seller_insights.cards.top_keywords" count={size(topKeywordsMissingFromAgentCalls.keywords)}>
          <DelimitedInlineList boldItems data={map(topKeywordsMissingFromAgentCalls.keywords, (keyword) => ({ name: keyword }))} />
          are top {{ _keyword_plural: appModeAwareTerms._keyword_plural, _keyword: appModeAwareTerms._keyword }} that arent being mentioned by {{ agentName }} in their {{ _department: appModeAwareTerms._department }} calls
        </Trans>
      )
    }]
  );
  if (!insights.length) return null;

  return (
    <InsightsSection isLoading={isLoading}>
      <InsightsTitle>{upperFirst(t('title'))}</InsightsTitle>
      <MultiCarousel containerAriaLabel={tDefault('description')}>
        {insights.map(({ id, text }) => <CarouselCard key={id} ariaLabel={extractTextFromHtmlTags(text)}>{text}</CarouselCard>)}
      </MultiCarousel>
    </InsightsSection>
  );
};
export default SellerInsights;
