import React from 'react';
import useLocale from 'hooks/use-locale';
import { NoDataError as CommonNoDataError } from 'ci-common-ui';

const NoDataError = ({ title, subtitle }) => {
  const { t } = useLocale('errors');
  return (
    <CommonNoDataError title={title || t('no_data')} subtitle={subtitle !== undefined ? subtitle : t('no_data_cta')} />
  );
};
export default NoDataError;
