import React, { useEffect, useState } from 'react';
import toLower from 'lodash/toLower';
import { TagList } from '../../../components/TagList';
import { Container } from './StyledCallTags';
import { addTag, removeTag } from '../network/data-services/call-service';
import { TRACK_IDS } from '../tracking';
import { joinTrackDelimiter } from '../../../utils/string/string';
import { useInfra } from '../../../providers/InfraProvider';
import { CALL_STRINGS } from '../strings';
import { eventAction } from '../../../services/telemetry-service';

export const CallTags = ({ tags, conversationId, onTagsChange }) => {
  const { t, trackError, trackEvent, isEditable } = useInfra('components.tag_list');
  const [currentTags, setCurrentTags] = useState(tags);
  const [serverError, setServerError] = useState('');

  useEffect(() => {
    setCurrentTags(tags);
  }, [tags]);

  const onTagListChange = (isAddingTag, tag, prevTags) => {
    setServerError(null);
    const action = isAddingTag ? addTag : removeTag;
    action(conversationId, tag)
      .then(() => {
        const actionOn = joinTrackDelimiter([TRACK_IDS.COMMON.TAG_LIST, TRACK_IDS.COMMON[isAddingTag ? 'ADD' : 'REMOVE']]);
        trackEvent({ action: eventAction.click, actionOn, message: tag });
      })
      .catch((err) => {
        trackError({ actionOn: joinTrackDelimiter([TRACK_IDS.COMMON.TAG_LIST]), message: JSON.stringify(err) });
        setCurrentTags(prevTags);
        setServerError(t(CALL_STRINGS.TAG_LIST_ERRORS_SERVER_ERROR, { addOrRemove: isAddingTag ? toLower(t(CALL_STRINGS.SAVE)) : toLower(t(CALL_STRINGS.DELETE)) }));
      });

    if (onTagsChange) {
      onTagsChange();
    }
  };

  return (
    <Container>
      <TagList
        valuesList={currentTags}
        onAddTag={(tag, prevTags) => onTagListChange(true, tag, prevTags)}
        onRemoveTag={(tag, prevTags) => onTagListChange(false, tag, prevTags)}
        errorMessages={{ errorAlreadyExist: t(CALL_STRINGS.TAG_LIST_ERRORS_ALREADY_EXIST_ERROR) }}
        isReadOnly={!isEditable}
        addButtonText={t(CALL_STRINGS.TAG_LIST_ADD_CALL_TAGS)}
        serverError={serverError}
        addButtonAriaText="add tag button"
      />
    </Container>
  );
};
