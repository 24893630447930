import combineConfigs from '../../combineConfigs';
import tooltip from '../../tooltip/tooltip';
import xAxisLabels from '../../xAxis/xAxisLabels';
import yAxisLabels from '../../yAxis/yAxisLabels';
import dynamicSeriesMapper from '../../series/dynamicSeriesMapper';

const type = 'line';

// dynamic means each data series has different dates (or other x values) than the others

export default ({
  data,
  xField,
  yField,
  seriesParams,
  categoryField,
  categoryFieldType,
  xTickInterval,
  yTickAmount,
  xAxisFormatter,
  yAxisFormatter,
}) => combineConfigs(
  {
    type,
    data,
    xField,
    yField,
    seriesParams,
    xAxisFormatter,
    yAxisFormatter,
    withMarker: false,
    categoryField,
    categoryFieldType,
    tooltipOptions: {
      getProps: ({ series: { name }, y: value }) => ({ name, value: yAxisFormatter({ value }) }),
      getTitle: ({ x: value }) => xAxisFormatter({ value }),
      withHeader: true,
      withBullet: true,
    }
  },
  [
    xAxisLabels,
    yAxisLabels,
    tooltip,
    dynamicSeriesMapper,
    {
      type,
      xAxis: {
        tickInterval: xTickInterval
      },
      yAxis: {
        tickAmount: yTickAmount
      },
      plotOptions: {
        line: {
          marker: {
            symbol: 'circle'
          }
        }
      }
    }
  ]
);
