import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  padding: 0 12px;
  background-color: ${({ theme }) => theme.name === 'dark' ? theme.colors.White : theme.colors.Gray10};
`;

export const TranscriptContainer = styled.div`
  overflow-y: auto;
  height: 100%;
  padding: 1px;
`;

export const EmptyState = styled.div`
  padding: 30px;
  text-align: center;
`;
