import React, { useEffect } from 'react';
import useLocale from 'hooks/use-locale';
import { Spinner } from '@fluentui/react';
import { useFetch } from 'ci-common-ui';
import { getOrganizationList, activeOrg, deleteOrg } from 'network/data-services/settings';
import { appContext, getAllAppsFromLocalStorage, setAllAppsToLocalStorage, resetTheApp } from 'utils/app-context/app-context';
import DataCacheService from 'services/DataCacheService';
import { Section, SectionTitle, SectionDescription } from '../../Modal/StyledModal';
import OrgCard from './OrgCard';
import OrgTable from './OrgTable';
import AddOrg from './AddOrg';
import { eventAction, trackEvent } from '../../../services/telemetry-service/telemetry-service';
import { TRACK_IDS } from '../../../constants/tracking';
import { joinTrackDelimiter } from '../../../utils/string/string';

const getOrgNameOrId = (app, organizationList) => {
  const org = organizationList && organizationList.find((o) => o.id === app.orgId);
  return org ? org.friendlyName : app.orgId;
};

const HostedOrg = () => {
  const { t, tAbs } = useLocale('pages.settings.data_source.hosted_org');
  const { orgId: activeOrgId } = appContext;
  const { data: organizationList, fetchData: fetchOrganizationList, isLoading: isLoadingOrganizationList } = useFetch(getOrganizationList);
  const { fetchData: setActiveOrg, isLoading: isLoadingActiveOrg } = useFetch(activeOrg);
  const { fetchData: setDisconnectOrg, isLoading: isLoadingDisconnect } = useFetch(deleteOrg);

  const apps = getAllAppsFromLocalStorage();

  useEffect(() => {
    fetchOrganizationList();
  }, []);

  if (isLoadingOrganizationList || !organizationList || isLoadingActiveOrg || isLoadingDisconnect) {
    return <Spinner />;
  }

  const currentActiveOrg = organizationList.find(({ id }) => id === activeOrgId);
  const appsWithoutDefault = apps.filter(({ orgId }) => orgId !== activeOrgId);
  const onBoardingOrgs = appsWithoutDefault.map((a) => ({ ...a, friendlyName: getOrgNameOrId(a, organizationList) }));

  const onConnectOrg = async ({ orgId, orgUrl: url, clusterRegion }) => {
    await setActiveOrg({ orgId, url, clusterRegion });
    trackEvent({ action: eventAction.click, actionOn: joinTrackDelimiter([TRACK_IDS.SOURCES.DATA_SOURCE_TAB, TRACK_IDS.COMMON.SET_ACTIVE_ORG]), message: orgId });
    resetTheApp(true);
  };

  const onDisconnectOrg = async ({ orgId, clusterRegion }) => {
    const newApps = apps.filter((item) => item.orgId !== orgId);
    setAllAppsToLocalStorage(newApps);
    DataCacheService.removeAll();
    await setDisconnectOrg({ orgId, clusterRegion });
    trackEvent({ action: eventAction.click, actionOn: joinTrackDelimiter([TRACK_IDS.SOURCES.DATA_SOURCE_TAB, TRACK_IDS.COMMON.DISCONNECT]), message: orgId });
  };

  return (
    <Section>

      <SectionTitle>{t('dynamics_env_title')}</SectionTitle>

      <OrgCard org={currentActiveOrg} t={t} translatedAlt={tAbs('common.organization')} />

      <SectionTitle>{t('other_env_title')}</SectionTitle>
      <SectionDescription>{t('other_env_description')}</SectionDescription>
      <AddOrg />
      <OrgTable orgs={onBoardingOrgs} onConnectOrg={onConnectOrg} onDisconnectOrg={onDisconnectOrg} t={t} />

    </Section>
  );
};

export default HostedOrg;
