import map from 'lodash/map';
import { colors } from '../../../../constants/colors';

export default ({
  seriesParams, data, withMarker = true, allowZero
}) => ({
  series: map(seriesParams, ({ key, text, color, labelColor }) => ({
    name: text,
    color,
    dataLabels: {
      color: labelColor || colors.Gray160
    },
    data: map(data, (item) => ({ ...item, y: item[key] || (allowZero && item[key] === 0 ? 0 : null) })),
    marker: {
      enabled: withMarker,
    },
  })),
});
