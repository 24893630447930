import React from 'react';
import useLocale from 'hooks/use-locale';
import { Icon } from '@fluentui/react';
import { DownloadContainer, DownloadBtn, Preview } from '../StyledErrorPanel';
import DownloadJson, { parseJson } from '../../../../../../../components/DownloadJson/DownloadJson';

const Metadata = ({ metadata }) => {
  const { t, tAbs } = useLocale('pages.system_monitoring.error_panel');

  if (!metadata) {
    return null;
  }

  return (
    <div>
      <DownloadContainer>
        {tAbs('common.or')}
        <DownloadJson json={metadata}>
          <DownloadBtn>
            {t('download')}
            <Icon iconName="Download" />
          </DownloadBtn>
        </DownloadJson>
      </DownloadContainer>
      <Preview>
        <pre>{parseJson(metadata)}</pre>
      </Preview>
    </div>
  );
};

export default Metadata;
