import { matchPath } from 'react-router';
import { getIsDemoUser, getIsSellerUser } from '../utils/app-context/app-context';
import { TRACK_IDS } from '../constants/tracking';
import { constantQueryParamsObj, QUERY_PARAMS } from '../constants/urls';

const defaultParams = [constantQueryParamsObj.startDate, constantQueryParamsObj.endDate];

export const routes = {
  home: {
    pageId: 'home',
    trackId: TRACK_IDS.SOURCES.HOME_PAGE,
    path: '/home',
    link: '/home',
    shouldHide: getIsSellerUser,
    mustParams: defaultParams
  },
  team: {
    pageId: 'team',
    trackId: TRACK_IDS.SOURCES.TEAM_PAGE,
    path: '/team',
    link: '/team',
    shouldHide: getIsSellerUser,
    mustParams: defaultParams
  },
  sellers: {
    pageId: 'sellers',
    trackId: TRACK_IDS.SOURCES.SELLER_PAGE,
    path: '/sellers/:id?',
    link: '/sellers',
    mustParams: defaultParams
  },
  systemMonitoring: {
    pageId: 'systemMonitoring',
    trackId: TRACK_IDS.SOURCES.SYSTEM_MONITORING_PAGE,
    path: '/system-monitoring',
    link: '/system-monitoring',
    shouldHide: () => getIsDemoUser(),
    mustParams: defaultParams
  },
  call: {
    pageId: 'call',
    trackId: TRACK_IDS.SOURCES.CALL_PAGE,
    path: '/call/:id',
    link: '/call',
    mustParams: []
  },
  search: {
    pageId: 'search',
    trackId: TRACK_IDS.SOURCES.SEARCH_PAGE,
    path: '/search',
    link: '/search',
    shouldHide: false,
    mustParams: [...defaultParams, QUERY_PARAMS.QUERY]
  },
  customerSentimentInsight: {
    pageId: 'customerSentimentInsight',
    trackId: TRACK_IDS.SOURCES.CUSTOMER_SENTIMENT_INSIGHT_PAGE,
    path: '/insights/customer-sentiment',
    link: '/insights/customer-sentiment',
    shouldHide: getIsSellerUser,
    mustParams: defaultParams
  },
  trackedKeywordsInsight: {
    pageId: 'trackedKeywordsInsight',
    trackId: TRACK_IDS.SOURCES.TRACKED_KEYWORDS_INSIGHT_PAGE,
    path: '/insights/tracked-keywords',
    link: '/insights/tracked-keywords',
    shouldHide: getIsSellerUser,
    mustParams: defaultParams
  },
  competitorsInsight: {
    pageId: 'competitorsInsight',
    trackId: TRACK_IDS.SOURCES.COMPETITORS_INSIGHT_PAGE,
    path: '/insights/competitors',
    link: '/insights/competitors',
    shouldHide: getIsSellerUser,
    mustParams: defaultParams
  },
  brandsInsight: {
    pageId: 'brandsInsight',
    trackId: TRACK_IDS.SOURCES.BRANDS_INSIGHT_PAGE,
    path: '/insights/brands',
    link: '/insights/brands',
    shouldHide: getIsSellerUser,
    mustParams: defaultParams
  },
  topSellersInsight: {
    pageId: 'topSellersInsight',
    trackId: TRACK_IDS.SOURCES.TOP_SELLERS_INSIGHT_PAGE,
    path: '/insights/top-sellers',
    link: '/insights/top-sellers',
    shouldHide: getIsSellerUser,
    mustParams: defaultParams
  }
};

export const redirectRoutes = {
  '/SalesRep': routes.sellers.link,
  '/SystemMonitoring': routes.systemMonitoring.link,
  '/Insights/CustomerSentiments': routes.customerSentimentInsight.link,
  '/Insights/TrackedKeywords': routes.trackedKeywordsInsight.link,
  '/Insights/Competitors': routes.competitorsInsight.link,
  '/Insights/Brands': routes.brandsInsight.link,
  '/Insights/Keywords': routes.topSellersInsight.link
};

export const getDefaultRoute = () => getIsSellerUser() ? routes.sellers : routes.home;

export const getRoutePageIdByPathname = (pathname) => Object.keys(routes).find((key) => matchPath(pathname, routes[key]));
