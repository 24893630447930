export default ({ xAxisFormatter }) => ({
  xAxis: {
    labels: {
      ...(xAxisFormatter
        ? {
          formatter() {
            return xAxisFormatter(this);
          },
        }
        : {}),
    },
  },
});
