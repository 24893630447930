import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import { useFetch } from 'ci-common-ui';
import useLocale from 'hooks/use-locale';
import { routes } from 'pages/routing';
import { getSellerConversations } from 'network/data-services/seller-service';
import { useAppNavigation, useAppLocation } from 'hooks/use-app-navigation';
import useEmptyState from 'utils/empty-state/use-empty-state';
import { useQueryDateChange } from 'hooks/use-query-date-change';
import { trackEvent, eventAction } from '../../../../services/telemetry-service/telemetry-service';
import { SectionTitle } from '../../../components/PageSection/StyledPageSection';
import { PaginatedTable } from '../../../../components/Table';
import { columns } from './columns';

import { TRACK_IDS, TRACK_SUFFIX } from '../../../../constants/tracking';
import { joinTrackDelimiter } from '../../../../utils/string/string';
import { QUERY_PARAMS } from '../../../../constants/urls';
import { getPageNumberFromUrlOrDefault } from '../../../../utils/url/url';

const translateCallData = (call, index, currentPage, trackCallTopicClickEvent) => ({
  ...call,
  customerSentiment: { ...call.sentiment, id: call.id },
  crmEntitiesLinks: call,
  dateAndDuration: {
    duration: call.duration,
    startTime: call.startTime
  },
  callType: {
    callScope: call.callScope,
    callDirection: call.callDirection
  },
  key: index,
  currentPage,
  trackCallTopicClickEvent
});

const LOCALIZATION_PREFIX = 'pages.seller.sections.call_history';

const fallbackPage = 1;

const CallHistory = ({
  selectedSellerId: agentAadId,
  isCustomerSentimentPerCallLoading
}) => {
  const { t, tDefault } = useLocale(LOCALIZATION_PREFIX);
  const { data, isLoading, fetchData, error } = useFetch((newParams) => getSellerConversations(newParams));
  const { navigateTo } = useAppNavigation();
  const { getSearchParam } = useAppLocation();
  const [rowIndex, setRowIndex] = useState();
  const { getEmptyStateDetails } = useEmptyState(LOCALIZATION_PREFIX);

  const defaultPage = getPageNumberFromUrlOrDefault(fallbackPage);
  const [currentPage, setCurrentPage] = useState(defaultPage);
  useEffect(() => {
    if (agentAadId && currentPage) {
      fetchData({ agentAadId, currentPage });
    }
  }, [currentPage]);

  useEffect(() => {
    if (agentAadId) {
      if (currentPage === defaultPage) {
        fetchData({ agentAadId, currentPage });
      } else {
        // Changing current page will trigger fetching data on currentPage useEffect
        setCurrentPage(defaultPage);
      }
    }
  }, [agentAadId]);

  useQueryDateChange((newParams) => {
    setCurrentPage(defaultPage);
    if (agentAadId && currentPage) {
      fetchData({ agentAadId, currentPage: defaultPage, ...newParams });
    }
  });

  const scrollToCallRowIfNeeded = () => {
    const callId = getSearchParam(QUERY_PARAMS.SCROLL_TO);
    if (callId && !isEmpty(data)) {
      setRowIndex(findIndex(data.conversations, ({ conversationId }) => conversationId === callId));
      setTimeout(() => setRowIndex(null), 2000);
      navigateTo({ params: { [QUERY_PARAMS.SCROLL_TO]: null, [QUERY_PARAMS.PAGE_NUMBER]: null }, options: { shouldReplace: true } });
    }
  };

  useEffect(scrollToCallRowIfNeeded, [data]);

  const onPageChanged = (newPage) => {
    trackEvent({ action: eventAction.click, actionOn: joinTrackDelimiter([TRACK_IDS.CALL_HISTORY_TABLE, TRACK_SUFFIX.TABLE.PAGING]), message: newPage });
    setCurrentPage(newPage);
  };

  const trackCallTopicClickEvent = (id) => {
    trackEvent({ action: eventAction.click, actionOn: joinTrackDelimiter([TRACK_IDS.CALL_HISTORY_TABLE, TRACK_SUFFIX.TABLE.CELL, TRACK_IDS.CALL_TOPIC]), message: id });
  };

  const conversationsItems = map(get(data, 'conversations'), (call, index) => translateCallData(call, index, currentPage, trackCallTopicClickEvent));

  const emptyStateDetails = getEmptyStateDetails('table', null, () => navigateTo({ path: routes.systemMonitoring.link }));

  const isTableLoading = isLoading || isCustomerSentimentPerCallLoading;

  return (
    <>
      <SectionTitle>{t('section_title')}</SectionTitle>
      <PaginatedTable
        columns={columns}
        items={conversationsItems}
        currentPage={currentPage}
        totalPages={get(data, 'pagesCount')}
        onPageChanged={onPageChanged}
        initialFocusedIndex={rowIndex}
        isLoading={isTableLoading}
        emptyStateDetails={agentAadId && !isTableLoading && isEmpty(get(data, 'conversations')) && emptyStateDetails}
        isErrorState={error}
        cardName={t('section_title')}
        ariaLabel={tDefault('grid_aria_label')}
      />
    </>
  );
};
export default CallHistory;
