import styled from 'styled-components';
import { TrackedLink } from "../../../../../../components/TrackedLink";

export const Disclaimer = styled.div`
  padding: 8px 8px 16px 8px;
  cursor: default;
  width: 600px;
  height: 40px;
  size: 14px;
  line-height: 20px;
  color: #616161;

  ${({ isFullSize }) =>
    isFullSize &&
    `margin: 0;`
  }`;

export const Container = styled.div``;

export const LearnMoreLink = styled(TrackedLink)`
  && {
    color: ${({ theme }) => theme.colors.DYNPrimary};
  }`;
