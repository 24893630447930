import qs from 'query-string';
import { CrmRegionMappings } from '@sci/common';
import { storageService } from '../services/storage-service/storage-service';
import { CI_CRM_ENV_BE_LOCAL_STORAGE_KEY, SERVER_API_URLS } from '../constants/crm-envs';

export const getSearchParam = (history) => qs.parse((history || window).location.search);

export const setSearchParam = (history, params) => {
  const parsed = qs.parse(history.location.search);
  return qs.stringify({ ...parsed, ...params });
};

export const getUpdateQueryStringParameterUrl = (uri, key, value) => {
  const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
  const separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (uri.match(re)) {
    return uri.replace(re, `$1${key}=${value}$2`);
  }

  return `${uri + separator + key}=${value}`;
};

// extractCrmNumber is duplicated in CRM.Solutions.RICommon - src/Controls/DigitalSales/QuickSettingsFeature/CiCrmUtils.ts - upon changes here, update there.
export const extractCrmNumber = (orgUrl) => {
  const crmNumber = orgUrl?.match(/.(\d+).dynamics.com/);
  const number = crmNumber ? crmNumber[1] : '';
  return `crm${number}`;
};

// getCrmRegionByUrl is duplicated in CRM.Solutions.RICommon - src/Controls/DigitalSales/QuickSettingsFeature/CiCrmUtils.ts - upon changes here, update there.
export const getCrmRegionByUrl = (orgUrl) => {
  const orgUrlWithoutHttps = orgUrl?.replace(/(^\w+:|^)\/\//, '');
  return CrmRegionMappings[orgUrlWithoutHttps] || CrmRegionMappings[extractCrmNumber(orgUrlWithoutHttps)];
}; // only tip & prod

export const getClusterUrlFromStaticMapping = (orgUrl) => {
  const BE_ENV = storageService.localStorage.getItem(CI_CRM_ENV_BE_LOCAL_STORAGE_KEY);
  const region = getCrmRegionByUrl(orgUrl);

  return SERVER_API_URLS[BE_ENV] || SERVER_API_URLS[region];
};
