import styled, { css } from 'styled-components';
import { Icon } from '@fluentui/react';
import { FlexColumn, FlexRowCentered, StyledText } from '../../../../utils/styles/StyledUtils';
import { rtl } from '../../../../utils/styles/rtl';
import { deviceSizes } from '../../../../utils/styles/device-sizes';
import { AUDIO_PLAYER_SMALL_BREAK_POINT_WIDTH } from '../../StyledCallPage';

export const Container = styled(FlexColumn)`
  height: 100%;
  padding-top: 8px;
`;

export const AudioPlayerContainer = styled(FlexRowCentered)`
  pointer-events: none;
  position: relative;
  justify-content: center;
  z-index: 1;
  margin-top: -75px;
  
  ${({ $isSmallPlayer }) => $isSmallPlayer && `
    && {
      margin-top: 0px;
    }
  `}
  @media (max-width: ${AUDIO_PLAYER_SMALL_BREAK_POINT_WIDTH}px) {
    margin-top: 8px;
  }
  ${deviceSizes.bigMobile`
    margin-top: 0px;
    flex-direction: row;
    justify-content: space-around;
    height: 100%;
  `}
`;

export const MainButtonsContainer = styled(FlexRowCentered)`
  pointer-events: all;
  margin-bottom: 8px;
  ${deviceSizes.bigMobile`
    order: 2;
  `}
  `;

const VolumeAndTimeContainer = styled(FlexRowCentered)`
  pointer-events: all;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  direction: ltr;
  ${deviceSizes.bigMobile`
    position: static;
    transform: none;
  `}
`;

export const VolumeContainer = styled(VolumeAndTimeContainer)`
  ${rtl`right`}: 68px;
  ${deviceSizes.bigMobile`
    order: 1;
  `}
`;

export const TimeLabelContainer = styled(VolumeAndTimeContainer)`
  ${rtl`right`}: 0;
  ${deviceSizes.bigMobile`
    order: 3;
  `}
`;

export const CurrentTime = styled(StyledText)`
  color: ${({ theme }) => theme.colors.Black};
  font-size: 12px;
`;

export const Duration = styled(StyledText)`
  color: ${({ theme }) => theme.colors.Gray130};
  font-size: 12px;
`;

export const ShareLabel = styled(StyledText)`
  font-size: 12px;
`;

export const PlayIcon = styled(Icon)`
${({ isLoading }) => isLoading && css`
    animation:spin 2s linear infinite;
    @keyframes spin { 100% { transform:rotate(360deg); } }
  `}
`;

export const playButtonStyles = () => ({
  root: {
    border: 'none',
    minWidth: 'auto',
    padding: 0,
    margin: '0 2px',
    width: '42px',
    height: '42px',
    borderRadius: '50%',
  }
});

export const forwardButtonStyles = {
  root: {
    border: 'none',
    minWidth: 'auto',
    padding: 0,
    width: '34px',
    height: '34px',
    borderRadius: '50%'
  }
};

export const forwardIconStyles = (rotate) => ({
  root: {
    fontSize: '18px',
    transform: rotate && 'rotate(180deg)'
  }
});

export const volumeSliderStyles = {
  root: {
    width: '76px'
  },
  thumb: {
    width: '14px',
    height: '14px'
  }
};

export const shareIconStyles = {
  root: {
    marginRight: '4px'
  }
};

export const playIconStyles = {
  root: {
    fontSize: '20px'
  }
};
