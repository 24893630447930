import styled from 'styled-components';
import { Dropdown, PersonaCoin, Icon } from '@fluentui/react';
import { FontSizes } from '@uifabric/fluent-theme';
import { FlexRow, rtl, colors } from 'ci-common-ui';

export const dropdownStyles = {
  title: {
    maxWidth: '320px',
    border: 'none',
    fontSize: FontSizes.size24,
    lineHeight: 32,
    selectors: {
      ':hover': {
        backgroundColor: colors.Gray40,
        border: 'none',
        outline: 'none',
      },
    },
  },
  dropdown: {
    display: 'inline-block',
  },
};
export const OptionContainer = styled(FlexRow)``;

export const StyledPersonaCoin = styled(PersonaCoin)`
  ${rtl`margin-right: 12px;`}
`;

export const StyledDropdown = styled(Dropdown)`
  ${rtl`text-align: left;`}
`;

export const DropdownTitle = styled(FlexRow)`
`;

export const DropdownTitleText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Star = styled(Icon).attrs({
  iconName: 'FavoriteStarFill'
})`
  ${rtl`margin-left: 5px;`}
  font-size: ${FontSizes.size20};
  color: ${colors.Gray130};
`;
