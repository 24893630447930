import omit from 'lodash/omit';
import { BASE_URLS, serverApi } from 'ci-common-ui';

export async function loadSystemMonitoringAnalysis(params) {
  return serverApi.GET({ path: '/user/v1.0-preview/beta/monitoring/analysis-count', queryParams: omit(params, 'silentLoading'), options: { baseUrl: BASE_URLS.NEW_API_SERVER, cacheOptions: { cache: false } } });
}

export async function loadSystemMonitoringFiles(params) {
  return serverApi.GET({ path: '/user/v1.0-preview/beta/monitoring/files', queryParams: omit(params, 'silentLoading'), options: { baseUrl: BASE_URLS.NEW_API_SERVER, cacheOptions: { cache: false } } });
}
