import get from 'lodash/get';
import * as config from 'constants/config';
import { getToken } from 'services/auth-service';
import jwtDecode from 'jwt-decode';
import { loadNPSShouldDisplay, loadNPSRenderEndpoint } from '../rest-services/nps-rest-service';

async function getNPSShouldDisplay() {
  const userToken = await getToken();
  if (!userToken || !config.npsEndpoint) {
    return false;
  }
  const npsData = await loadNPSShouldDisplay(jwtDecode(userToken).puid);
  return get(npsData, 'display');
}

export async function getNPSRenderEndpoint() {
  const shouldDisplay = await getNPSShouldDisplay();
  if (shouldDisplay) {
    const token = await getToken();
    const decodedToken = jwtDecode(token);
    const puid = get(decodedToken, 'puid');
    return loadNPSRenderEndpoint(puid);
  }
  return null;
}
