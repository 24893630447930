import { useEffect, useState, useRef } from 'react';

export const usePolling = (func, intervalTime, timeoutTime = null, shouldStartPollingNow = true) => {
  const [isPolling, setPolling] = useState(false);
  const [isTimeoutError, setTimeoutError] = useState(false);
  const intervalRef = useRef();
  const timeoutRef = useRef();

  const setStopPollingTimeout = () => {
    timeoutRef.current = setTimeout(() => {
      setTimeoutError(true);
      cancelPolling();
    }, timeoutTime);
  };

  const startPolling = (params) => {
    setPolling(true);
    if (shouldStartPollingNow) {
      func(params);
    }
    intervalRef.current = setInterval(() => func(params), intervalTime);
    if (timeoutTime) {
      setStopPollingTimeout();
    }
  };

  const cancelPolling = () => {
    setPolling(false);
    clearInterval(intervalRef.current);
  };

  const stopPolling = () => {
    stopTimeout();
    cancelPolling();
  };

  const stopTimeout = () => {
    clearTimeout(timeoutRef.current);
  };

  useEffect(() => () => {
    stopPolling();
  }, []);

  return { startPolling, stopPolling, isPolling, isTimeoutError };
};
