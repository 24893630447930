import React, { useEffect } from 'react';
import BannerImage from 'assets/images/banner-logo-1.png';
import { StepRoot } from '../../../../../components/Wizard/StyledWizard';
import StepForm from '../../../../../components/Wizard/StepForm/StepForm';
import { SectionText, SectionTitle } from '../../../../../components/Modal/StyledModal';
import { BulletsContainer, ContentSection, ContentSectionTitle, StyledBanner } from './StyledGetSetUp';
import useLocale from '../../../../../hooks/use-locale';
import Bullet from '../../../../../components/Modal/components/Bullet/Bullet';
import { componentLoadingTimeService } from '../../../../../services/component-loading-time-service/component-loading-time-service';
import { TRACK_IDS } from '../../../../../constants/tracking';

const GetSetUpStep = ({ settings, next, translationPrefix }) => {
  const { t } = useLocale(translationPrefix);

  useEffect(() => {
    componentLoadingTimeService.end(TRACK_IDS.SOURCES.TRIAL_SETUP_MODAL);
  }, []);

  return (
    <StepRoot>
      <StepForm onSubmit={next} initialValues={settings}>
        <StyledBanner src={BannerImage} />
        <SectionTitle>{t('content_main_title')}</SectionTitle>
        <SectionText>{t('main_subtitle')}</SectionText>
        <ContentSection>
          <ContentSectionTitle>{t('first_section_title')}</ContentSectionTitle>
          <SectionText>
            {t('first_section_subtitle')}
            <BulletsContainer>
              <Bullet iconName="Heart">{t('first_section_bullet_1')}</Bullet>
              <Bullet iconName="Heart">{t('first_section_bullet_2')}</Bullet>
              <Bullet iconName="Heart">{t('first_section_bullet_3')}</Bullet>
            </BulletsContainer>
          </SectionText>
        </ContentSection>
        <ContentSection>
          <ContentSectionTitle>{t('second_section_title')}</ContentSectionTitle>
          <SectionText>
            <Bullet iconName="Phone">
              {t('second_section_subtitle_1')}
              <br />
              <strong>{t('second_section_subtitle_2')}</strong>
              <br />
              <br />
              {t('second_section_subtitle_3')}
            </Bullet>
          </SectionText>
        </ContentSection>
      </StepForm>
    </StepRoot>
  );
};

export default GetSetUpStep;
