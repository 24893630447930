import React from 'react';
import { MessageBar, MessageBarType, Spinner, SpinnerSize } from '@fluentui/react';
import { useInfra } from '../../../../providers/InfraProvider';
import { CALL_STRINGS } from '../../strings';
import { MessageBarContent } from '../../StyledCallPage';

export const StatusBar = ({
  infoMessage,
  isLoading,
  isLoadingError,
  isReadOnly,
  isDeleteInsightsFailed,
  setDeleteInsightsFailed,
  setIsInfoMessageHidden,
}) => {
  const { t } = useInfra();

  if (infoMessage) {
    return (
      <MessageBar
        messageBarType={MessageBarType.info}
        data-testid="custom-message"
        onDismiss={() => setIsInfoMessageHidden(true)}
        dismissButtonAriaLabel="Custom message dismiss button"
      >
        {infoMessage}
      </MessageBar>
    );
  }

  if (isLoadingError) {
    return (
      <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
        {t(CALL_STRINGS.CALL_PROCESSING_FAILURE)}
      </MessageBar>
    );
  }

  if (isLoading) {
    return (
      <MessageBarContent>
        <Spinner size={SpinnerSize.small} />
        {t(CALL_STRINGS.CALL_PROCESSING_BAR)}
      </MessageBarContent>
    );
  }

  if (isReadOnly) {
    return (
      <MessageBar isMultiline={false} messageBarIconProps={{ iconName: 'Lock' }}>
        {t(CALL_STRINGS.CALL_READ_ONLY)}
      </MessageBar>
    );
  }

  if (isDeleteInsightsFailed) {
    return (
      <MessageBar
        isMultiline={false}
        messageBarType={MessageBarType.error}
        data-testid="delete-failed"
        onDismiss={() => setDeleteInsightsFailed(false)}
        dismissButtonAriaLabel="Custom message dismiss button"
      >
        {t(CALL_STRINGS.DELETE_INSIGHTS_FAILED)}
      </MessageBar>
    );
  }

  return null;
};
