import { Persona, PersonaSize } from '@fluentui/react';
import styled from 'styled-components';
import { colors } from '../../../../../../constants/colors';
import { rtl } from '../../../../../../utils/styles/rtl';
import {
  defaultFontFamilyCss,
  FlexColumnCentered,
  FlexRowCentered,
  StyledText,
  textCss
} from '../../../../../../utils/styles/StyledUtils';
import SummaryPlaceholderSVG from '../../../../../../assets/images/executive-summary-empty-state.svg';

const HEADER_HEIGHT = '45px';
const LAST_EDITOR_CONTAINER_HEIGHT = '28px';
export const Container = styled.div`
  flex: 1;
`;

export const HeaderContainer = styled(FlexRowCentered)`
  padding: 2px 8px;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.Gray30};
  height: ${HEADER_HEIGHT};
`;

export const ContentContainer = styled.div`
  padding: 16px 24px 8px 24px;
  height: calc(100% - (${HEADER_HEIGHT} + ${({ messageBarType }) => messageBarType ? '40px' : '0px'}));
`;

export const textFieldStyles = {
  root: {
    height: `calc(100% - ${LAST_EDITOR_CONTAINER_HEIGHT})`,
    width: '100%',
    border: 'none'
  },
  wrapper: {
    height: '100%',
    border: 'none',
  },
  fieldGroup: {
    height: '100%',
    border: 'none',
    selectors: {
      ':after': {
        border: 'none !important',
      },
    }
  },
  field: {
    padding: 0,
  }
};

export const tooltipHostStyles = {
  root: {
    display: 'flex',
    alignItems: 'center'
  }
};

export const LastEditorAvatar = styled(Persona).attrs({
  size: PersonaSize.size24,
})`
  ${rtl`margin-left`}: 8px;
`;

export const LastEditorContainer = styled(FlexRowCentered)`
  justify-content: flex-end;
  margin-bottom: 8px;
`;

export const LastEditorTimestamp = styled(StyledText)`
  font-size: 12px;
  color: ${colors.Gray130}
`;

export const MessageBarContainer = styled.div`
padding: 8px 8px 0 8px;
`;

export const Summary = styled(StyledText)`
  color: ${colors.Gray160};
`;

export const SummaryContainer = styled.div`
  height: calc(100% - ${LAST_EDITOR_CONTAINER_HEIGHT});
  overflow-y: auto;
  cursor: ${({ isEditable }) => isEditable ? 'pointer' : 'default'};
  &[data-is-empty=true]:empty:not(:focus):before {
    ${textCss};
    ${defaultFontFamilyCss};
    white-space: pre-line;
    content:attr(data-text);
    color: ${colors.Gray130};
  }
`;

export const SummaryPlaceholderContainer = styled(FlexColumnCentered)`
  margin-top: 124px;
  text-align: center;
`;

export const SummaryPlaceholderIcon = styled(SummaryPlaceholderSVG)`
  height: 48px;
  width: 48px;
  & path {
    stroke: ${({ theme }) => theme.colors.DYNPrimary};
  }
  & circle {
    fill: ${({ theme }) => theme.colors.DYNPrimary};
  }
`;

export const SummaryPlaceholderTitle = styled(StyledText)`
  font-size: 18px;
  margin-top: 20px;
`;

export const SummaryPlaceholderSubtitle = styled(StyledText)`
  color: ${colors.Gray130};
  margin-top: 20px;
`;
