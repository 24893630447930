export const openCrmEntityEdit = (orgUrl, recordId) => openCrmEntity(orgUrl, 'task', recordId);

export const getCrmEntityLink = (orgUrl, entityName, recordId) => `${orgUrl}/main.aspx?pagetype=entityrecord&etn=${entityName}&id=${recordId}`;

export const openCrmEntity = (orgUrl, entityName, recordId) => {
  window.open(getCrmEntityLink(orgUrl, entityName, recordId));
};

export const createCrmEntity = (orgUrl, entityName) => {
  window.open(`${orgUrl}/main.aspx?pagetype=entityrecord&etn=${entityName}`, '_blank');
};
