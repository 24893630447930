import { useEffect } from 'react';
import { trackEvent } from '../services/telemetry-service/telemetry-service';
import { eventAction } from '../services/telemetry-service/consts';
import { dayJS } from '../utils/date/date';

export const useOverridePageTracking = (trackId) => {
  useEffect(() => {
    const startTime = dayJS();
    trackEvent({ overrideSource: trackId, action: eventAction.pageView });
    return () => {
      const timeSpent = dayJS().diff(startTime, 'seconds');
      trackEvent({ overrideSource: trackId, action: eventAction.pageTimeSpent, message: timeSpent });
    };
  }, []);
};
