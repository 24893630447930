export const exportToCSV = (data, fileName = 'csv_file') => {
  if (data && data.length && typeof data[0] === 'object') {
    const mimeType = 'data:text/csv;charset=utf-8,';
    const headerTitles = `${Object.keys(data[0]).join(',')}\n`;
    const rows = data.map((row) => `${Object.values(row).join(',')}`).join('\n');
    const csvContent = mimeType + headerTitles + rows;
    if (window.navigator.msSaveBlob) { // IE10
      window.navigator.msSaveBlob(new window.Blob([csvContent], {
        type: mimeType
      }), `${fileName}.csv`);
    } else {
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `${fileName}.csv`);
      document.body.appendChild(link); // Required for FF
      link.click(); // This will download the file
      document.body.removeChild(link);
    }
    return true;
  }
  // eslint-disable-next-line no-console
  console.error('invalid csv data provided, expected array of objects');
  return false;
};
