import styled from 'styled-components';
import { colors } from '../../../../../../constants/colors';
import { FlexColumn, FlexRow, StyledText } from '../../../../../../utils/styles/StyledUtils';
import { rtl } from '../../../../../../utils/styles/rtl';

export const ResultContent = styled(FlexColumn)`
  flex: 1;
  ${rtl`margin-left: 8px;`}
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const ResultPrimaryLabel = styled(FlexRow)`
`;

export const Label = styled(StyledText)`
  font-size: 12px;
  color: ${colors.Gray130};
  margin-bottom: 8px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;
