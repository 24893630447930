import { telemetryService, devUtils } from 'ci-common-ui';
import { getCurrentEventCommonDetails, getPage, shouldSkipPageView, shouldSkipQueryParamRedirect } from './utils';

const { enqueueEvent, getCleanMessage, getMessageWithoutPrivacyData, eventLevels, eventAction, shouldDisableBackgroundLogs } = telemetryService;

export {
  eventAction
};

let telemetrySource;

export const getTelemetrySource = () => telemetrySource || 'UNKNOWN_SOURCE';

export const setTelemetrySource = (source) => {
  if (source) {
    telemetrySource = source;
  }
};

const addEventToQueue = (event) => {
  const currentEvent = {
    ...getCurrentEventCommonDetails(),
    ...event
  };

  if (devUtils.isLocalhost()) {
    // eslint-disable-next-line no-console
    const writeLog = event.Level === eventLevels.error ? console.error : console.log;
    writeLog(event);
  } else {
    enqueueEvent(currentEvent);
  }
};

export const trackEvent = ({ overrideSource, action, actionOn, message }) => {
  if (!action) throw new Error('action must be provided');

  if (shouldDisableBackgroundLogs(actionOn)) {
    return;
  }

  const event = {
    Source: overrideSource || getTelemetrySource(),
    Action: action,
    ActionOn: actionOn || getTelemetrySource(),
    Message: getMessageWithoutPrivacyData(message),
    Level: eventLevels.info
  };

  addEventToQueue(event);
};

export const trackPage = (location, timeSpent) => {
  const currentPage = getPage(location);
  if (!shouldSkipPageView(location, currentPage)) {
    if (timeSpent) {
      trackEvent({ action: eventAction.pageTimeSpent, message: timeSpent });
      if (shouldSkipQueryParamRedirect(location)) {
        return;
      }
    }
    if (currentPage) {
      setTelemetrySource(currentPage);
      trackEvent({ action: eventAction.pageView });
    }
  }
};

export const trackError = ({ overrideSource, action, actionOn, message, overrideActivityId }) => {
  if (shouldDisableBackgroundLogs(actionOn)) {
    return;
  }

  const errorEvent = {
    Source: overrideSource || getTelemetrySource(),
    Action: action || eventAction.error,
    ActionOn: actionOn || getTelemetrySource(),
    Message: getCleanMessage(message),
    Level: eventLevels.error
  };

  if (overrideActivityId) {
    errorEvent.ActivityId = overrideActivityId;
  }

  addEventToQueue(errorEvent);
};
