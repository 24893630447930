import React from 'react';
/* eslint-disable-next-line no-restricted-imports  */
import { TooltipHost } from '@fluentui/react';

export const Tooltip = (props) => {
  const { onTooltipVisible, children } = props;

  const onTooltipToggle = (isVisible) => {
    if (onTooltipVisible && isVisible) {
      onTooltipVisible();
    }
  };
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <TooltipHost {...props} onTooltipToggle={onTooltipToggle}>{children}</TooltipHost>;
};
