import styled from 'styled-components';
import PageErrorSVG from 'assets/images/page-error-state.svg';
import { FlexColumn, StyledText, colors } from 'ci-common-ui';

export const PageErrorContainer = styled(FlexColumn)`
  width: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
`;

export const PageErrorImage = styled(PageErrorSVG)``;

const Text = styled(StyledText).attrs({
  multiline: true,
})`
  max-width: 718px;
  text-align: center;
`;

export const Title = styled(Text).attrs({
  variant: 'mediumPlus',
})`
  margin-top: 60px;
  font-weight: 600;
  color: ${colors.Gray160};
`;

export const Subtitle = styled(Text).attrs({
  variant: 'medium',
})`
  margin-top: 16px;
  font-weight: 500;
  color: ${colors.Gray130};
`;
