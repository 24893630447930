import React from 'react';
import { StepperRoot, StepperItem, CircleItem, Circle, Line, Text, DisplayValues } from './StyledStepper';

const Stepper = ({ steps, current, settings, translationPrefix }) => (
  <StepperRoot>
    {steps.map(({ name, title, displayValues }, index) => {
      const isCompleted = index <= current;
      const isLast = index < steps.length - 1;
      return (
        <StepperItem key={name}>
          <CircleItem>
            <Circle selected={isCompleted} />
            { isLast && <Line selected={isCompleted} /> }
          </CircleItem>
          <Text selected={isCompleted}>
            <div>{title}</div>
            {displayValues && <DisplayValues>{displayValues(settings, translationPrefix)}</DisplayValues>}
          </Text>
        </StepperItem>
      );
    })}
  </StepperRoot>
);

export default Stepper;
