import React from 'react';
import { FlexColumn, FlexRow } from 'ci-common-ui';
import { formatDate, formatMonthDayYearDate, TIME_FORMAT, durationToTimeText } from '../../../../utils/date/date';
import { StartDate, StartTime, Duration } from './StyledDateAndDurationCell';

export const DateAndDurationCell = ({ data }) => {
  if (!data) return null;
  const { startTime, duration } = data;

  return (
    <FlexColumn>
      <FlexRow>
        <StartDate>{formatMonthDayYearDate(startTime)}</StartDate>
        <StartTime>{formatDate(startTime, TIME_FORMAT)}</StartTime>
      </FlexRow>
      <Duration>{durationToTimeText(parseFloat(duration) / 1000)}</Duration>
    </FlexColumn>
  );
};
