import React from 'react';
import ReactDOM from 'react-dom';
import { clientId, mrClientId, getIsAuthenticated } from 'constants/config';
import { CS_APP_TOKEN_KEY, CI_GRAPH_API_TOKEN_KEY } from 'constants/browser-storage';
import { storageService, securityUtils, CRM_POST_MESSAGE_TYPES, TRACK_IDS as COMMON_TRACK_IDS } from 'ci-common-ui';
import { appModes, extractMode } from 'utils/app-context/app-context';
import * as jwt from 'jwt-decode';
import { isNil } from 'lodash';
import { PublicClientApplication, InteractionRequiredAuthError, LogLevel } from '@azure/msal-browser';
import { trackError, trackEvent, eventAction } from './telemetry-service/telemetry-service';
import { LoginWithPopup } from '../components/LoginWithPopup/LoginWithPopup';
import { TRACK_IDS } from '../constants/tracking';
import DataCacheService from './DataCacheService';
import { getSearchParam } from '../utils/url/url';

const HALF_HOUR = 30 * 60000; // half the time of the default app token's expiration (1 hour);
const usernameKey = 'lastUsername';
let currentUsername = null;

const { tenantId } = getSearchParam();

const msalConfig = {
  auth: {
    authority: `https://login.microsoftonline.com/${tenantId || 'common'}`,
    clientId,
    redirectUri: `${window.location.origin}`,
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: 'localStorage'
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            trackError({ actionOn: TRACK_IDS.COMMON.AUTHENTICATION, message });
            break;
          default:
        }
      },
      piiLoggingEnabled: false
    },
  }
};

let publicClientApplication = new PublicClientApplication(msalConfig);

function getAccountInfo(loginHint) {
  if (loginHint) {
    return publicClientApplication.getAccountByUsername(loginHint);
  }
  const accounts = publicClientApplication.getAllAccounts();
  if (accounts && accounts.length === 1) {
    return accounts[0];
  }

  return null;
}

const loginWithPopup = (loginHint, params) => new Promise((resolve) => {
  const loginWithPopupEl = document.getElementById('login-with-popup');
  const onClick = async () => {
    await publicClientApplication.loginPopup(params);
    loginWithPopupEl.style.display = 'none';
    resolve(getAccountInfo(loginHint));
  };
  ReactDOM.render(<LoginWithPopup onClick={onClick} />, loginWithPopupEl);
  loginWithPopupEl.style.display = 'block';
  window.parent.postMessage({
    msg: CRM_POST_MESSAGE_TYPES.GET_TOKEN_FROM_CI_POPUP
  },
  '*');
  window.parent.postMessage({
    msg: CRM_POST_MESSAGE_TYPES.GET_TOKEN_FROM_CI_POPUP
  },
  '*');
});

async function getUserAccountOrLogin({p, loginHint, isForceLogin}) {
  const params = p;
  let account = isForceLogin ? null : getAccountInfo(loginHint);

  if (!account) {
    if (window.parent === window) {
      if (getAccountInfo()) {
        params.prompt = 'select_account'; // avoid redirect loop if already signed in with another account
      }
      await publicClientApplication.loginRedirect(params);
    } else {
      try {
        await publicClientApplication.ssoSilent(params);
        account = getAccountInfo(loginHint);
      } catch (e) {
        trackError({ actionOn: TRACK_IDS.PUBLIC_CLIENT_APP_SSO_SILENT_FAILED, message: { error: e }});
        account = await loginWithPopup(loginHint, params);
      }
    }
  }
  return account;
}

async function handleAcquireTokenError(error, params, username) {
  const isRequiresInteraction = error instanceof InteractionRequiredAuthError;
  const isNoTokensFound = error?.message?.includes('no_tokens_found');

  if (isRequiresInteraction || isNoTokensFound) {
    trackEvent({ actionOn: TRACK_IDS.ACQUIRE_TOKEN_FAILED_ON_VALID_ERROR, message: { error, isRequiresInteraction, isNoTokensFound }, action: eventAction.info});
    const account = await getUserAccountOrLogin({ p: params, loginHint: username, isForceLogin: true }); // This will force login here because the refreshToken is expired in this case
    return { account };
  }

  trackError({ actionOn: TRACK_IDS.ACQUIRE_TOKEN_FAILED, message: { error }});
  throw error;
}

export async function getToken(loginHint, scopeParam, redirectUri) {
  if (redirectUri) {
    msalConfig.auth.redirectUri = redirectUri;
    publicClientApplication = new PublicClientApplication(msalConfig);
  }

  if (!getIsAuthenticated()) return null;

  if (isCustomerService) {
    return getCustomerServiceTokenFromStorage();
  }

  const params = { scopes: [`${scopeParam || clientId}/.default`], loginHint, authority: `https://login.microsoftonline.com/${tenantId || 'common'}` };
  const returnedAccount = await publicClientApplication.handleRedirectPromise();
  let username = loginHint;
  if (currentUsername) {
    username = currentUsername;
  } else {
    if (returnedAccount) {
      username = returnedAccount.account.username;
    }
    const existingUsername = storageService.localStorage.getItem(usernameKey, username);

    if (username && existingUsername !== username) {
      DataCacheService.removeAll();
    }
    if (!username) {
      username = existingUsername;
    }
    storageService.localStorage.setItem(usernameKey, username);
    currentUsername = username;
  }

  const account = await getUserAccountOrLogin({ p: params, loginHint: username, isForceLogin: false });

  try {
    const response = await publicClientApplication.acquireTokenSilent({ ...params, account });
    return response.accessToken;
  } catch (e) {
      const { refreshedAccount } = await handleAcquireTokenError(e, params, username);
      if(refreshedAccount){
        trackEvent({ actionOn: TRACK_IDS.RETRY_ACQUIRE_TOKEN_AFTER_FAILURE, message: { error: e, }, action: eventAction.info});
        const response = await publicClientApplication.acquireTokenSilent({ ...params, account: refreshedAccount });
        return response.accessToken;
      }
  }
  return null;
}

export async function getGraphToken() {
  try {
    if (!getIsAuthenticated() || isCustomerService) return null;

    const cacheHit = DataCacheService.get(CI_GRAPH_API_TOKEN_KEY, { storage: window, maxAge: HALF_HOUR, nonObjectItem: true });
    if (cacheHit) {
      return cacheHit;
    }

    const params = { scopes: ['User.Read', 'Mail.Read'], authority: 'https://login.microsoftonline.com/common' };
    const loginHint = storageService.localStorage.getItem(usernameKey, currentUsername);
    const account = await getAccountInfo(loginHint);
    const response = await publicClientApplication.acquireTokenSilent({ ...params, account });
    DataCacheService.set(CI_GRAPH_API_TOKEN_KEY, response.accessToken, { storage: window, maxAge: HALF_HOUR });

    return response.accessToken;
  } catch (e) {
    trackError({ actionOn: TRACK_IDS.GRAPH_API_AUTHENTICATION_ERROR, message: e.message });
  }
  return null;
}

export const getMRToken = (loginHint, overrideClientId) => getToken(loginHint, overrideClientId || mrClientId, window.location.origin + window.location.pathname);

export const getGraphTokenForCRM = (loginHint) => getToken(loginHint, 'https://graph.microsoft.com', window.location.origin + window.location.pathname);

export async function getCrmToken(orgUrl) {
  const loginHint = storageService.localStorage.getItem(usernameKey, currentUsername);
  return getToken(loginHint, orgUrl);
}

const isCustomerService = extractMode(window.location.href) === appModes.CustomerService;

export const customerServiceMessageTypes = {
  cIToken: 'CIToken',
  renewCIToken: 'RenewCIToken',
  clearCIToken: 'ClearCIToken',
  cICloseSettings: 'CICloseSettings',
};

export const saveTokenToCache = (accessToken) => {
  storageService.sessionStorage.setObject(CS_APP_TOKEN_KEY, { access_token: accessToken });
};

export const isTokenExpired = (token) => {
  if (!getIsAuthenticated()) return false;
  if (isNil(token)) return true;
  try {
    const decodedToken = jwt(token);
    const currentTime = new Date().getTime() / 1000;
    return decodedToken.exp < currentTime + 300;
  } catch (ex) {
    return true;
  }
};

export const getCustomerServiceTokenFromStorage = () => {
  const cachedToken = storageService.sessionStorage.getObject(CS_APP_TOKEN_KEY);
  const appToken = cachedToken ? cachedToken.access_token : null;
  const isExpired = appToken == null || isTokenExpired(appToken);
  return isExpired
    ? postMessagePromise({ messageType: customerServiceMessageTypes.renewCIToken }).then((token) => {
      saveTokenToCache(token);
      return token;
    })
    : Promise.resolve(appToken);
};

export const initWithAuth = (startTheAppCallback, loginHint) => {
  if (isCustomerService) {
    return getCustomerServiceTokenFromStorage().then(() => {
      startTheAppCallback();
      listenToClearToken();
    });
  }

  return getToken(loginHint).then((token) => token && startTheAppCallback());
};

export const listenToClearToken = () => {
  if (isCustomerService) {
    receiveMessagePromise(customerServiceMessageTypes.clearCIToken).then(() => {
      storageService.sessionStorage.clear();
      window.location.reload();
    });
  }
};

export const logout = () => {
  if (!isCustomerService) {
    publicClientApplication.logout();
  }
};

export const postMessagePromise = (message, origin = '*') => new Promise((resolve) => {
  receiveMessagePromise(customerServiceMessageTypes.cIToken).then((token) => {
    resolve(token);
  });
  window.top.postMessage(message, origin);
});

export const receiveMessagePromise = (messageType) => new Promise((resolve, reject) => {
  const receiveMessage = (message) => {
    if (!securityUtils.isAllowedOrigin(message.origin)) {
      trackError({ actionOn: COMMON_TRACK_IDS.INVALID_IFRAME_MESSAGE_ORIGIN, message: { origin: message.origin } });
      reject(new Error('Invalid message origin'));
    }

    if (message.data.messageType === messageType) {
      window.removeEventListener('message', receiveMessage, false);
      resolve(message.data.token);
    }
  };
  window.addEventListener('message', receiveMessage, false);
});
