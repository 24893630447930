import React, { useState } from 'react';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import { IconButton, Spinner } from '@fluentui/react';
import { assign, find, isEmpty, noop } from 'lodash';
import { Header, NewRecordResultTitle, ResultAndError, Error } from './StyledNewRecordTypeSelectorView';
import { createCrmEntity, openCrmEntity } from '../../../../../../utils/crm-links';
import { entities } from '../../../../../../constants/crm-entities';
import { NewRecordTitle, ResultContainer, Results, StyledFontIcon } from '../../StyledCrmRecordLookup';
import { useInfra } from '../../../../../../providers/InfraProvider';
import { CALL_STRINGS } from '../../../../strings';
import { Tooltip } from '../../../../../../components/Tooltip';

const getVisibleEntitiesFromAllowedToBeCreated = (typesAllowedToBeCreated, visibleEntities) => {
  const visibleEntitiesArray = map(typesAllowedToBeCreated, (obj) => assign(obj, find(visibleEntities, (_, type) => type === obj.type)));
  return visibleEntitiesArray.reduce((result, item) => ({ ...result, [item.type]: item }), {});
};

export default ({ onBackClicked, typesAllowedToBeCreated, createNewPhonecallAndRelateExistingCola }) => {
  const { orgUrl, t } = useInfra();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  let visibleEntities = pickBy(entities, 'crmEntityName');

  if (!isEmpty(typesAllowedToBeCreated)) {
    visibleEntities = getVisibleEntitiesFromAllowedToBeCreated(typesAllowedToBeCreated, visibleEntities);
  }

  const onCreateClick = async (crmEntityName, entityType) => {
    if (createNewPhonecallAndRelateExistingCola) {
      setIsLoading(true);
      setError(null);
      try {
        const newPhonecallId = await createNewPhonecallAndRelateExistingCola(entityType);
        openCrmEntity(orgUrl, crmEntityName, newPhonecallId);
      } catch (err) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    } else {
      createCrmEntity(orgUrl, crmEntityName);
    }
  };

  return (
    <div>
      <Header>
        <IconButton iconProps={{ iconName: 'Back' }} onClick={onBackClicked} />
        <NewRecordTitle>{t(CALL_STRINGS.SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_CRM_RECORD_LOOKUP_NEW_RECORD_LABEL)}</NewRecordTitle>
      </Header>
      <Results>
        {
          map(
            visibleEntities,
            ({ IconComponent, fontIconCode, crmEntityName, disabled, disabledTooltipContent }, entityTypeName) => (
              <ResultAndError key="">
                <ResultContainer disabled={disabled || isLoading} key={entityTypeName} onClick={(disabled || isLoading) ? noop : (() => onCreateClick(crmEntityName, entityTypeName))}>
                  {isLoading ? <Spinner /> : (
                    <>
                      {IconComponent && <IconComponent />}
                      {fontIconCode && <StyledFontIcon iconFontFamily="CRMMDL2" iconCode={fontIconCode} />}
                    </>
                  )}
                  <Tooltip content={(disabled && !isLoading) ? disabledTooltipContent : t(CALL_STRINGS.SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_CRM_RECORD_LOOKUP_CREATE_NEW_RECORD_TOOLTIP, { crmEntityName })}>
                    <NewRecordResultTitle>{t(`crm_entity_types_${entityTypeName}`)}</NewRecordResultTitle>
                  </Tooltip>
                </ResultContainer>
                {error && <Error>{t(CALL_STRINGS.SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_API_ERROR)}</Error>}
              </ResultAndError>
            ),
          )
        }
      </Results>
    </div>
  );
};
