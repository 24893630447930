import styled from 'styled-components';
import { DefaultButton, ActionButton } from '@fluentui/react';
import orgImageSrc from 'assets/images/org.png';
import { colors } from 'ci-common-ui';

export const OrgCardContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const DisconnectBtn = styled(DefaultButton)`
  margin: 20px 0;
`;

export const DisconnectBtnTitle = styled.div.attrs({ as: 'h1' })`
  font-size:20px;
  font-weight: 600; 
`;

export const OrgImage = styled.img.attrs({
  src: orgImageSrc,
})`
    height: 48px;
    width: 48px;
    margin-right: 15px;
`;

export const AddOrgBtn = styled(ActionButton)`
  margin-bottom: 10px;
`;

export const OrgActionCellContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  
  button {
    color: inherit;
  }
`;

export const OrgName = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${colors.Gray160};
`;
