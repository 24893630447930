import { Icon } from '@fluentui/react';
import React from 'react';
import { BulletContainer, ContentBullet, ContentBulletDescription } from './StyledBullet';

export default ({ children, iconName }) => (
  <BulletContainer>
    <ContentBullet>
      <Icon iconName={iconName} />
    </ContentBullet>
    <ContentBulletDescription>
      {children}
    </ContentBulletDescription>
  </BulletContainer>
);
