import styled from 'styled-components';
import { FontSizes } from '@uifabric/fluent-theme';
import { colors } from 'ci-common-ui';

export const StartDate = styled.span`
  font-size: ${FontSizes.size12};
  color: ${colors.Gray130};
  margin-right: 6px;
  font-weight: 400;
`;

export const StartTime = styled.span`
  color: ${colors.Gray130};
  font-weight: 400;
`;

export const Duration = styled.div`
  margin-top: 5px;
  font-size: ${FontSizes.size12};
  color: ${colors.Gray130};
  font-weight: 400;
`;
