import styled from 'styled-components';
import { Icon } from '@fluentui/react';
import { StyledText, FlexRow, colors, deviceSizes } from 'ci-common-ui';
import BannerImage from 'assets/images/demo-banner.svg';

export const BannerContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding-top: 48px;
  padding-bottom: 32px;
  position: relative;
  border-bottom: 1px solid ${colors.Gray40};
  background-color: ${colors.White};

  ${deviceSizes.tablet` 
    padding-top: 24px;
  `}
`;

export const BannerInnerContainer = styled(FlexRow)`
  width: 100%;
  max-width: 1092px;
  min-width: 540px;
  justify-content: space-between;
  padding: 0px 14px;
`;

export const BannerHeader = styled(StyledText)`
  font-size: 34px;

  ${deviceSizes.tablet` 
    font-size: 32px;
  `}
`;

export const BannerDescription = styled(StyledText)`
  margin-top: 32px;
`;

export const StyledBannerImage = styled(BannerImage)`
  display: inline;
  width: 100%;

  ${deviceSizes.tablet` 
     display: none;
  `}
`;

export const ConnectButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
`;

export const BannerCancelIcon = styled(Icon)`
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 16px;
  cursor: pointer;
`;
