import styled from 'styled-components';
import { FontSizes } from '@uifabric/fluent-theme';
import { Link } from '../../../Link/Link';

export const Title = styled(Link)`
  font-size: ${FontSizes.size14};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;
