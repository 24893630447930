import styled from 'styled-components';
import { FlexRow, FlexColumn, colors } from 'ci-common-ui';
import { Spinner, SpinnerSize, IconButton } from '@fluentui/react';

export const Loader = styled(Spinner).attrs({
  size: SpinnerSize.large
})`
  width: 100%;
  height: 100%;
`;

export const CloseButton = styled(IconButton).attrs({
  styles: { root: { margin: '-18px', padding: '22px', color: colors.White } }
})`
`;

export const AboutContainer = styled(FlexColumn)`
  width: 100%;
  height: 100%;
`;

export const AboutRibon = styled(FlexRow)`
  justify-content: space-between; 
  background-color: rgba(0,0,0,0.87);
  padding: 24px;
  color: rgba(255,255,255,0.98);
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  padding: 24px;
  color: rgba(0,0,0,0.62);
`;

export const Title = styled.div`
  font-weight: 400;
  font-size:16px;
`;

export const Row = styled.div`
    margin: 20px 0 0
`;
