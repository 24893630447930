import { useEffect } from 'react';
import { componentLoadingTimeService } from '../services/component-loading-time-service/component-loading-time-service';

export const usePageLoadTime = (source, isLoadingDone) => {
  useEffect(() => {
    componentLoadingTimeService.start(source);
  }, []);

  useEffect(() => {
    if (isLoadingDone) {
      componentLoadingTimeService.end(source);
    }
  }, [isLoadingDone]);
};
