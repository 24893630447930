export const appName = '__SalesInsights__';
export const {
  clientId,
  mrClientId,
  deployedServer: SERVER_URL,
  npsEndpoint,
  buildVersion
} = window[appName] || {};

export const getIsAuthenticated = () => typeof clientId === 'string' && clientId.length > 0;
