import React, { useCallback, useState, useEffect } from 'react';
import useLocale from 'hooks/use-locale';
import { useFetchNow, Trans } from 'ci-common-ui';
import { useQueryDateChange } from 'hooks/use-query-date-change';
import { Link } from '../../components/Link/Link';
import Page from '../components/Page/Page';
import AnalysisCharts from './components/AnalysisCharts/AnalysisCharts';
import FilesTable, { tableColumns } from './components/FilesTable/FilesTable';
import { fullTimeRange } from './constants';
import { SubTitle, UploadCallsButton } from './StyledSystemMonitoringPage';
import { useAppNavigation } from '../../hooks/use-app-navigation';
import { settingsModalTabs } from '../../constants/modals';
import { getIsTrialMode } from '../../utils/app-context/app-context';
import { getSystemMonitoringAnalysis, getSystemMonitoringFiles } from '../../network/data-services/system-monitoring-service';
import { getDateParams, getPageNumberFromUrlOrDefault } from '../../utils/url/url';
import { getSpecificError } from '../../utils/error-state/error-state';
import { TRACK_IDS } from '../../constants/tracking';
import { usePageLoadTime } from '../../hooks/use-page-load-time';
import { QUERY_PARAMS } from '../../constants/urls';

const initialPageNumber = 1;

let silentLoadingInterval = null;
const INTERVAL_TIME = 20000;

const LEARN_MORE_LINK = 'https://go.microsoft.com/fwlink/?linkid=2101248';
const TRANSLATION_PREFIX = 'pages.system_monitoring';

const SystemMonitoringPage = () => {
  const { t, appModeAwareTerms } = useLocale(TRANSLATION_PREFIX);
  const { navigateTo } = useAppNavigation();

  const defaultFilter = {
    fileStatus: '',
    sortBy: tableColumns[0].sortKey,
    orderBy: 'desc',
    pageNumber: getPageNumberFromUrlOrDefault(initialPageNumber),
    silentLoading: false,
  };

  const [filter, setFilter] = useState(defaultFilter);

  const onFilterChange = useCallback((filterProps) => {
    setFilter((currentFilter) => ({ ...currentFilter, ...filterProps }));
  }, []);

  const {
    data: filesTableData,
    isLoading: filesTableIsLoading,
    error: filesTableError,
    fetchData: filesTableFetchData
  } = useFetchNow((newParams) => getSystemMonitoringFiles({ ...getDateParams(), ...filter, ...newParams }));

  const {
    data: analysisChartsData,
    isLoading: analysisChartsIsLoading,
    error: analysisChartsError,
    fetchData: analysisChartsFetchData
  } = useFetchNow((newParams) => getSystemMonitoringAnalysis({ ...getDateParams(), ...newParams }), { getSpecificError });

  usePageLoadTime(TRACK_IDS.SOURCES.SYSTEM_MONITORING_PAGE, (!filesTableIsLoading && !analysisChartsIsLoading));

  const onUploadButtonClicked = () => {
    navigateTo({ params: { [QUERY_PARAMS.SETTINGS]: settingsModalTabs.uploadcalls.link } });
  };

  const fetchData = () => {
    filesTableFetchData();
    analysisChartsFetchData();
  };

  const [isSilentTableLoading, setIsSilentTableLoading] = useState();
  const [isSilentGraphLoading, setIsSilentGraphLoading] = useState();

  useEffect(() => {
    setIsSilentTableLoading(false);
    setIsSilentGraphLoading(filter.silentLoading);
    fetchData();
    if (silentLoadingInterval) {
      clearInterval(silentLoadingInterval);
    }
    silentLoadingInterval = setInterval(() => {
      setIsSilentTableLoading(true);
      setIsSilentGraphLoading(true);
      fetchData();
    }, INTERVAL_TIME);
  }, [filter]);

  useEffect(() => () => clearInterval(silentLoadingInterval), []);

  useQueryDateChange(() => {
    onFilterChange({ pageNumber: initialPageNumber, silentLoading: false });
  });

  return (
    <Page error={filesTableError && analysisChartsError} title={t('title')} fullTimeRange={fullTimeRange}>
      <SubTitle>
        <Trans i18nKey={`${TRANSLATION_PREFIX}.subtitle`} values={{ _app_name: appModeAwareTerms._app_name }}>
          text <Link href={LEARN_MORE_LINK} target="_blank">link</Link>
        </Trans>
      </SubTitle>
      {
        getIsTrialMode() && (
          <UploadCallsButton
            text={t('upload_more_calls')}
            iconProps={{ iconName: 'PublishContent' }}
            onClick={onUploadButtonClicked}
          />
        )
      }
      <AnalysisCharts
        onFilterChange={onFilterChange}
        data={analysisChartsData}
        isLoading={analysisChartsIsLoading && !isSilentGraphLoading}
        error={analysisChartsError}
      />
      <FilesTable
        filter={filter}
        onFilterChange={onFilterChange}
        data={filesTableData}
        isLoading={filesTableIsLoading && !isSilentTableLoading}
        error={filesTableError}
      />
    </Page>
  );
};
export default SystemMonitoringPage;
