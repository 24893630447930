import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { Container } from './StyledExecSummary';
import { SuggestionPanel } from './components/SuggestionPanel';
import { useToggler } from '../../../../hooks/use-toggler';
import { SummaryBoard, getSummaryEmailBody } from './components/SummaryBoard';
import { useInfra } from '../../../../providers/InfraProvider';
import { eventAction } from '../../../../services/telemetry-service';
import { TRACK_IDS } from '../../tracking';
import { CALL_STRINGS } from '../../strings';
import { TabLoading } from '../TabLoading';
import { copyToClipboardAsHtml, copyToClipboardAsText } from '../../../../utils/dom/dom';
import { getSummaryEmailBodyAsHtml } from './utils';

export const ExecSummary = ({
  id,
  subject,
  data,
  insightsProcessingStatus,
  onSelectSuggestion,
  participants,
  startDate,
  relatedEntities,
  isInternalMeeting,
  isLoading,
  isExecutiveSummaryEnabled,
  onSelectActionItem,
}) => {
  const { trackEvent, t, setLeavingPagePromptMessage, isEditable, isViva } =
    useInfra();
  const { summary = {}, suggestions, actionItems, partnerHighlights } = data || {};
  const [isSuggestionsOpened, toggleIsSuggestionsOpened] = useToggler(true);

  const [summaryDetails, setSummaryDetails] = useState(summary);
  const [isEditMode, setIsEditMode] = useState(false);
  const [counterAddSuggestions, setCounterAddSuggestions] = useState(0);

  const onEdit = () => {
    if (isEditable && !isEditMode) {
      setIsEditMode(true);
      setLeavingPagePromptMessage(true, {
        title: t(CALL_STRINGS.EXEC_SUMMARY_BOARD_UNSAVED_CHANGES_TITLE),
        subtitle: t(CALL_STRINGS.EXEC_SUMMARY_BOARD_UNSAVED_CHANGES_SUBTITLE),
        confirmButtonText: t(CALL_STRINGS.CLOSE),
        cancelButtonText: t(CALL_STRINGS.CANCEL),
        actionOn: TRACK_IDS.EXEC_SUMMARY_LEAVE_WITHOUT_SAVE,
      });
      trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.EXEC_SUMMARY_EDIT });
    }
  };

  const resetSummaryDetails = (updatedSummaryDetails) => {
    setSummaryDetails(updatedSummaryDetails || summary);
    setCounterAddSuggestions(0);
  };

  useEffect(() => {
    if (!isEmpty(summary)) {
      setSummaryDetails(summary);
    }
  }, [summary]);

  useEffect(() => {
    const { lastEditDetails } = summaryDetails || {};
    if (lastEditDetails) {
      trackEvent({
        action: eventAction.info,
        actionOn: TRACK_IDS.EXEC_SUMMARY_LAST_UPDATED,
        message: lastEditDetails,
      });
    }
  }, [summaryDetails]);

  const onAddSuggestionClicked = (newSuggestions) => {
    const currentContent = summaryDetails.content || '';
    const newContent = `${currentContent && `${currentContent}\n`}${newSuggestions
      .map(({ content }) => `\n• ${content}`)
      .join('\n')}`;
    setSummaryDetails({ ...summaryDetails, content: newContent });
    setCounterAddSuggestions((counter) => counter + 1);
  };

  const onToggleSuggestion = () => {
    trackEvent({
      action: eventAction.click,
      actionOn: TRACK_IDS.EXEC_SUMMARY_TOGGLE_SUGGESTION_PANEL,
      message: !isSuggestionsOpened,
    });
    toggleIsSuggestionsOpened();
  };

  if (isLoading && isEmpty(data)) {
    return (
      <TabLoading
        title={t(CALL_STRINGS.EXEC_SUMMARY_EMPTY_STATE_TITLE)}
        subtitle={t(CALL_STRINGS.EXEC_SUMMARY_EMPTY_STATE_SUBTITLE)}
      />
    );
  }

  const onCopySummaryToClipboard = () => {
    const summaryContent = summaryDetails?.content;
    let body;
    if (isViva) {
      // include suggestions & action items
      body = getSummaryEmailBody(
        subject,
        participants,
        startDate,
        relatedEntities,
        null,
        t,
        suggestions,
        actionItems
      );

      const html = getSummaryEmailBodyAsHtml({
        subject,
        participants,
        startDate,
        relatedEntities,
        t,
        suggestions,
        actionItems,
        partnerHighlights,
      });
      copyToClipboardAsHtml(html);
    } else {
      // include notes
      body = getSummaryEmailBody(
        subject,
        participants,
        startDate,
        relatedEntities,
        summaryContent,
        t
      );
      copyToClipboardAsText(body);
    }

    trackEvent({
      action: eventAction.click,
      actionOn: TRACK_IDS.EXEC_SUMMARY_COPY_TO_CLIPBOARD,
      message: { isNotesCanvasHidden: isViva },
    });
  };

  const isEmptySuggestions = !get(suggestions, 'length') && !get(actionItems, 'length') && !get(partnerHighlights, 'length');
  const shouldDisplaySuggestion = isExecutiveSummaryEnabled && (isViva || !isEmptySuggestions)

  return (
    <Container data-testid="notesTab">
      {!isViva && (
        <>
          <span data-testid="summary-board" />
          <SummaryBoard
            id={id}
            subject={subject}
            isExecutiveSummaryEnabled={shouldDisplaySuggestion}
            summaryDetails={summaryDetails}
            onToggleSuggestion={onToggleSuggestion}
            setSummaryDetails={setSummaryDetails}
            resetSummaryDetails={resetSummaryDetails}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            onCopySummaryToClipboard={onCopySummaryToClipboard}
            onEdit={onEdit}
            counterAddSuggestions={counterAddSuggestions}
          />
        </>
      )}
      {shouldDisplaySuggestion && (
        <SuggestionPanel
          isInternalMeeting={isInternalMeeting}
          isSuggestionsOpened={isSuggestionsOpened}
          isEmptySuggestions={isEmptySuggestions}
          suggestions={suggestions}
          actionItems={actionItems}
          partnerHighlights={partnerHighlights}
          onClose={onToggleSuggestion}
          onAddClicked={onAddSuggestionClicked}
          onSelectSuggestion={onSelectSuggestion}
          onSelectActionItem={onSelectActionItem}
          onEdit={onEdit}
          onCopySummaryToClipboard={onCopySummaryToClipboard}
          isFullSize={isViva}
          insightsProcessingStatus={insightsProcessingStatus}
        />
      )}
    </Container>
  );
};
