import styled from 'styled-components';
import { rtl } from '../../../../utils/styles/rtl';

export const SearchBoxContainer = styled.div`
  display: flex;
  width: 100%;
  border: ${({ theme }) => `1px solid ${theme.colors.Gray40}`};
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const Result = styled.div`
  background-color: ${({ theme }) => theme.colors.Gray40};
  padding: 2px;
`;

export const Line = styled.div`
  width: 1px;
  background-color: ${({ theme }) => theme.colors.Gray40};
  height: calc(100% - 10px);
  ${rtl`margin-left: 8px`};
`;

export const searchBoxStyle = {
  field: {
    width: 'inherit'
  },
  root: {
    width: 'inherit',
    border: 'none',
    flex: 1,
    selectors: {
      ':after': {
        display: 'none',
      },
    }
  }
};
