import { useAppState } from '../providers/AppStateProvider';

const useNotificationBar = () => {
  const { appState, setAppState } = useAppState();
  return {
    addNotificationBar: (bar) => setAppState({
      notificationBars: appState.notificationBars.push(bar)
    }),
    removeNotificationBar: (type) => setAppState({
      notificationBars: appState.notificationBars.filter((bar) => bar.type !== type)
    })
  };
};
export default useNotificationBar;
