/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Trans } from 'ci-common-ui';
import { getIsAdminUser } from '../app-context/app-context';
import useLocale from '../../hooks/use-locale';
import { Link } from '../../components/Link/Link';

const getTranslationFormat = (translationKey, values, clickHandler) => (
  <Trans i18nKey={translationKey} values={values}>
    <br />
    <Link onClick={clickHandler} />
    <b>title</b>
  </Trans>
);

export default (translationPrefix) => {
  const { i18n, appModeAwareTerms } = useLocale(translationPrefix);
  const userRole = getIsAdminUser() ? 'admin' : 'default';

  const getVisualTranslationKey = (key, emptyCaseName, visualName) => {
    const componentEmptyStateKey = `${translationPrefix}.${key}.empty_state`;

    const roleSpecificCaseSpecificTranslationKey = `${componentEmptyStateKey}.cases.${emptyCaseName}.roles.${userRole}.${visualName}`;
    if (i18n.exists(roleSpecificCaseSpecificTranslationKey)) {
      return roleSpecificCaseSpecificTranslationKey;
    }

    const caseSpecificTranslationKey = `${componentEmptyStateKey}.cases.${emptyCaseName}.${visualName}`;
    if (i18n.exists(caseSpecificTranslationKey)) {
      return caseSpecificTranslationKey;
    }

    const roleSpecificTranslationKey = `${componentEmptyStateKey}.roles.${userRole}.${visualName}`;
    if (i18n.exists(roleSpecificTranslationKey)) {
      return roleSpecificTranslationKey;
    }

    return `${componentEmptyStateKey}.${visualName}`;
  };

  return {
    getEmptyStateDetails: (key, emptyCaseName, clickHandler) => ({
      title: getTranslationFormat(getVisualTranslationKey(key, emptyCaseName, 'title'), undefined, clickHandler),
      subtitle: getTranslationFormat(getVisualTranslationKey(key, emptyCaseName, 'subtitle'), { _app_name: appModeAwareTerms._app_name }, clickHandler)
    })
  };
};
