export const CRM_POST_MESSAGE_TYPES = {
  SETTINGS_PAGE_CHANGED_CI_SETTINGS: 'SETTINGS_PAGE_CHANGED_CI_SETTINGS',
  TEAMS_SETTINGS_PAGE_CHANGED_CI_SETTINGS: 'TEAMS_SETTINGS_PAGE_CHANGED_CI_SETTINGS',
  DIALER_CHANGED_CI_SETTINGS: 'DIALER_CHANGED_CI_SETTINGS',
  THIRD_PARTY_COOKIES_SUPPORTED: 'THIRD_PARTY_COOKIES_SUPPORTED',
  THIRD_PARTY_COOKIES_NOT_SUPPORTED: 'THIRD_PARTY_COOKIES_NOT_SUPPORTED',
  GET_TOKEN_FROM_CI_POPUP: 'GET_TOKEN_FROM_CI_POPUP',
  PROXY_AXIOS_REQUEST: 'PROXY_AXIOS_REQUEST',
  PROXY_AXIOS_RESPONSE: 'PROXY_AXIOS_RESPONSE',
  PROXY_SOCKET_REQUEST: 'PROXY_SOCKET_REQUEST',
  PROXY_SOCKET_MESSAGE_RECEIVED: 'PROXY_SOCKET_MESSAGE_RECEIVED',
  PROXY_SOCKET_OPENED: 'PROXY_SOCKET_OPENED',
  PROXY_SOCKET_CLOSED: 'PROXY_SOCKET_CLOSED',
  PROXY_SOCKET_READY_STATE: 'PROXY_SOCKET_READY_STATE',
  PROXY_HOWLER_REQUEST: 'PROXY_HOWLER_REQUEST',
  PROXY_HOWLER_RESPONSE: 'PROXY_HOWLER_RESPONSE',
  VALIDATE_IS_GUEST_USER: 'VALIDATE_IS_GUEST_USER',
  REFRESH_CI_TOKEN: 'REFRESH_CI_TOKEN',
  SET_AUTH_IFRAME_ENV_VARIABLES: 'SET_AUTH_IFRAME_ENV_VARIABLES',
};

export const PROXY_AXIOS_REQUEST_TYPES = {
  CI_PROXY_AXIOS_REQUEST: 'CI_PROXY_AXIOS_REQUEST',
  MR_PROXY_AXIOS_REQUEST: 'MR_PROXY_AXIOS_REQUEST',
  GRAPH_PROXY_AXIOS_REQUEST: 'GRAPH_PROXY_AXIOS_REQUEST'
};

export const TEAMS_DIALER_POST_MESSAGE_TYPES = {
  TEAMS_DIALER_IFRAME_LOADED: 'TEAMS_DIALER_IFRAME_LOADED',
  SEARCH_CONTACTS: 'SEARCH_CONTACTS',
  GET_SHOULD_HIDE_INCOMING_CALL_MODAL: 'GET_SHOULD_HIDE_INCOMING_CALL_MODAL',
  HANDLE_ACTION: 'HANDLE_ACTION',
  HANDLE_LOG_EVENT: 'HANDLE_LOG_EVENT',
  ON_CONTACT_CLICKED: 'ON_CONTACT_CLICKED',
  START_CALL: 'START_CALL',
  INIT_DIALER: 'INIT_DIALER',
  ACCEPT_CALL: 'ACCEPT_CALL',
  REJECT_CALL: 'REJECT_CALL',
  ACCEPT_RESUME_CALL: 'ACCEPT_RESUME_CALL',
  REJECT_RESUME_CALL: 'REJECT_RESUME_CALL',
  SET_IS_SILENT: 'SET_IS_SILENT'
};

export const SOUND_PLAYER_MESSAGE_TYPES = {
  LOAD_URL: 'LOAD_URL',
  ON_HOWLER_LOAD_SUCCESS: 'ON_HOWLER_LOAD_SUCCESS',
  ON_HOWLER_LOAD_ERROR: 'ON_HOWLER_LOAD_ERROR',
  ON_HOWLER_END: 'ON_HOWLER_END',
  ON_HOWLER_PLAY: 'ON_HOWLER_PLAY',
  ON_HOWLER_PAUSE: 'ON_HOWLER_PAUSE',
  SEEK: 'SEEK',
  SET_VOLUME: 'SET_VOLUME',
  STOP: 'STOP',
  OFF: 'OFF',
  PLAY: 'PLAY',
  PAUSE: 'PAUSE',
  IS_PLAYING: 'IS_PLAYING',
};
