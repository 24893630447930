import { AUTOMATION_SELECTORS as COMMON_AUTOMATION_SELECTORS } from 'ci-common-ui';

export { pageScrollerAttr, dataAutomation, pageScrollerSelector } from 'ci-common-ui';

// common data attributes
export const dataViewClick = 'data-view-click';

export const dataViewContent = 'data-view-content';

// data-automation values
export const AUTOMATION_SELECTORS = {
  ...COMMON_AUTOMATION_SELECTORS,
  SETTINGS_MODAL: 'settings-modal',
  DUAL_TIMELINE: 'dual-timeline'
};

// elements that are scrolled-to from the query param
export const scrollViewNames = {
  customerSentimentBySeller: 'customerSentimentBySeller',
  conversationStyle: 'conversationStyle',
  talkToListenRatio: 'talkToListenRatio',
  switchRate: 'switchRate'
};
