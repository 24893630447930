import React, { useState, useEffect, useRef } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { TextField, DropdownField, isConnectionStringValid } from '../../components/Form';

const CONNECTION_STRING_GET_CONTAINER_PARAM_KEY = 'connectionString';
const STORAGE_NAME_GET_CONTAINER_PARAM_KEY = 'storageName';

export const DataConnectionFields = ({
  initialValues,
  containerList,
  fetchContainerList,
  isLoading,
  containerListError,
  connectionStringLabel,
  storageNameLabel,
  containerLabel,
  containerPlaceholder,
  connectionStringFieldName,
  storageNameFieldName,
  containerFieldName,
  shouldUseStorageName,
  errorMessages,
  width,
  minWidth,
  onFieldChange
}) => {
  const didFirstFetch = useRef(false);
  const shouldUseConnectionString = !shouldUseStorageName;

  const containerListOptions = containerList ? containerList.map((name) => ({ key: name, text: name, 'aria-selected': 'false', })) : [];

  const onMountFunction = (key, fieldName) => {
    const initialFieldValue = get(initialValues, fieldName);
    if (!didFirstFetch.current && initialFieldValue) {
      didFirstFetch.current = true;
      fetchContainerList({ [key]: initialFieldValue });
    }
  };

  const onFieldBlurBase = ({ input, meta }, key) => {
    const { modified, error } = meta;
    const { value } = input;
    if (modified && !error) {
      fetchContainerList({ [key]: value });
    }
  };

  const onChange = () => {
    onFieldChange?.();
  }

  return (
    <div>
      {shouldUseConnectionString ? (
        <ConnStringComponent
          onMountFunction={onMountFunction}
          connectionStringLabel={connectionStringLabel}
          connectionStringFieldName={connectionStringFieldName}
          onFieldBlurBase={onFieldBlurBase}
          onChange={onChange}
          width={width}
          minWidth={minWidth}
          isLoading={isLoading}
          containerListError={containerListError}
          initialValues={initialValues}
          errorMessages={errorMessages}
        />
      )
        : (
          <StorageNameComponent
            onMountFunction={onMountFunction}
            storageNameLabel={storageNameLabel}
            storageNameFieldName={storageNameFieldName}
            onFieldBlurBase={onFieldBlurBase}
            onChange={onChange}
            width={width}
            minWidth={minWidth}
            isLoading={isLoading}
            containerListError={containerListError}
            errorMessages={errorMessages}
            initialValues={initialValues}
          />)
      }
      <DropdownField label={containerLabel} placeholder={containerPlaceholder} name={containerFieldName} isRequired options={containerListOptions} width={width} minWidth={minWidth} isLoading={isLoading} isDisabled={containerListError || isEmpty(containerList)} errorMessages={errorMessages} />
    </div>
  );
};

function StorageNameComponent({ initialValues, onMountFunction, storageNameLabel, storageNameFieldName, onFieldBlurBase, onChange, width, minWidth, isLoading, containerListError, errorMessages }) {
  useEffect(() => {
    onMountFunction(STORAGE_NAME_GET_CONTAINER_PARAM_KEY, storageNameFieldName);
  }, [initialValues]);

  return (
    <TextField
      type="text"
      label={storageNameLabel}
      name={storageNameFieldName}
      isRequired
      onBlur={({ input, meta }) => onFieldBlurBase({ input, meta }, STORAGE_NAME_GET_CONTAINER_PARAM_KEY)}
      onChange={onChange}
      width={width}
      minWidth={minWidth}
      isLoading={isLoading}
      customError={containerListError && errorMessages.containerList}
      success={!containerListError && !isLoading}
      errorMessages={errorMessages}
    />
  );
}

function ConnStringComponent({ initialValues, onMountFunction, connectionStringLabel, connectionStringFieldName, onFieldBlurBase, onChange, width, minWidth, isLoading, containerListError, errorMessages }) {
  const [isFocus, setFocus] = useState();

  useEffect(() => {
    onMountFunction(CONNECTION_STRING_GET_CONTAINER_PARAM_KEY, connectionStringFieldName);
  }, [initialValues]);

  const onConnectionStringBlur = ({ input, meta }) => {
    onFieldBlurBase({ input, meta }, CONNECTION_STRING_GET_CONTAINER_PARAM_KEY);
    setFocus(false);
  };

  return (
    <TextField
      type={isFocus ? 'text' : 'password'}
      label={connectionStringLabel}
      name={connectionStringFieldName}
      isRequired
      onFocus={() => setFocus(true)}
      onBlur={onConnectionStringBlur}
      onChange={onChange}
      width={width}
      minWidth={minWidth}
      isLoading={isLoading}
      customError={containerListError && errorMessages.containerList}
      success={!containerListError && !isLoading}
      errorMessages={errorMessages}
      validate={isConnectionStringValid}
    />
  );
};
