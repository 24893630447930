import React from 'react';
import merge from 'lodash/merge';
import { Modal as FabricModal } from '@fluentui/react';
import { deviceSizes } from 'ci-common-ui';

const modalStyle = {
  root: {
    padding: deviceSizes.bigMobile ? 10 : 56
  },
  main: {
    width: '100%',
    height: '100%',
    maxHeight: 880,
    maxWidth: 1312,
  },
  scrollableContent: {
    width: '100%',
    height: '100%',
    overflowY: deviceSizes.bigMobile ? 'auto' : 'hidden',
    overflowX: 'hidden'
  },
};

const Modal = ({ onDismiss, styles, children, options = {} }) => (
  <FabricModal isOpen onDismiss={onDismiss} styles={merge({}, modalStyle, styles)} isBlocking={options.isBlocking}>{children}</FabricModal>
);
export default Modal;
