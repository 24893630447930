import React from 'react';
import useLocale from 'hooks/use-locale';
import get from 'lodash/get';
import camelCase from 'lodash/camelCase';
import { transformTotalData, createTotalConfig, createCompletionConfig } from './chart-configs';
import ChartCardsRow from '../../../components/ChartCardsRow/ChartCardsRow';
import { TRACK_IDS } from '../../../../constants/tracking';
import { isRequestErrorState } from '../../../../utils/error-state/error-state';

const AnalysisCharts = React.memo(({ onFilterChange, data, isLoading, error }) => {
  const { t, tAbs } = useLocale('pages.system_monitoring.analysis');

  const onStatusClicked = (fileStatus) => {
    onFilterChange({ fileStatus: camelCase(fileStatus), pageNumber: 1, silentLoading: true });
  };

  const cards = [{
    trackId: TRACK_IDS.TOTAL_CALL_STATUS,
    header: t('total_chart_title'),
    csvData: [get(data, 'total.status')],
    getChartConfig: () => createTotalConfig(transformTotalData(get(data, 'total')), onStatusClicked, tAbs),
    emptyStateDetails: !isLoading && !get(data, 'total'),
    hasError: get(error, 'total') || isRequestErrorState(error),
    keepRefreshing: true,
  }, {
    trackId: TRACK_IDS.COMPLETION_RATE,
    header: t('completion_chart_title'),
    csvData: get(data, 'completion'),
    getChartConfig: () => {
      const completionData = get(data, 'completion');
      const isOnlyOneItem = completionData && completionData.length === 1;
      return createCompletionConfig(completionData, isOnlyOneItem, tAbs);
    },
    cardStyle: { flex: '3 3 500px' },
    emptyStateDetails: !isLoading && !get(data, 'completion'),
    hasError: get(error, 'completion') || isRequestErrorState(error),
    keepRefreshing: true,
  }];
  return (
    <ChartCardsRow isLoading={isLoading} cards={cards} shimmerHeight={275} />
  );
});
export default AnalysisCharts;
