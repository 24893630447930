import React, { useState } from 'react';
import { noop } from 'lodash';
import { TopSeller, Star, StarWrapper } from './StyledTopSellerCell';
import { ENTER_KEY_CODE } from '../../../../constants/key-codes';
import { Tooltip } from '../../../Tooltip';

export const TopSellerCell = ({ data: isTopSeller, id, markText, unmarkText, disabledText, onRowHoverClassName, toggleTopSeller, isDisabled, ariaLabelMarkText, ariaLabelUnmarkText, ariaLabelDisabledText }) => {
  const [isHover, toggleHover] = useState(false);

  const containerClassName = isTopSeller ? '' : onRowHoverClassName;

  const toggle = () => {
    toggleHover(false);
    toggleTopSeller({ id, value: !isTopSeller });
  };
  const handleEnterClick = (e) => {
    if (e.key === ENTER_KEY_CODE) {
      e.preventDefault();
      toggle();
    }
  };

  const isFill = (!isHover && isTopSeller) || (isHover && !isTopSeller);
  const tooltipContent = isDisabled ? disabledText : isTopSeller ? unmarkText : markText;
  const ariaLabelContent = isDisabled ? ariaLabelDisabledText : isTopSeller ? ariaLabelMarkText : ariaLabelUnmarkText;

  return (

    <TopSeller className={containerClassName} aria-label={`${ariaLabelContent} button`}>
      <Tooltip content={tooltipContent}>
        <StarWrapper onKeyDown={handleEnterClick}>
          <Star
            iconName={`FavoriteStar${isFill ? 'Fill' : ''}`}
            isDisabled={isDisabled}
            onClick={isDisabled ? noop : toggle}
            onMouseEnter={isDisabled ? noop : () => toggleHover(true)}
            onMouseLeave={isDisabled ? noop : () => toggleHover(false)}
          />
        </StarWrapper>
      </Tooltip>
    </TopSeller>
  );
};
