import styled from 'styled-components';

export const LoginPopupButtonContainer = styled.div`
  margin: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
`;

export const LoginButton = styled.button`
  padding: 8px 16px;
  font-size: 14px;
  background-color: #0067b8;
  color: #fff;
  cursor: pointer;
`;
