import React from 'react';
import { useQueryParams, StringParam } from 'use-query-params';
import useLocale from '../../hooks/use-locale';
import DateTimeFilter from './DateTimeFilter';

export const DateTimeFilters = {
  DAY: 'day',
  SEVEN_DAYS: 'seven_days',
  THIRTY_DAYS: 'thirty_days',
  ALL_TIME: 'all_time',
  CUSTOM: 'custom',
};

const translateDatesStrings = ({ t, tAbs }) => {
  const dateTimeFilterStrings = {
    startDateLabel: tAbs('common.start'),
    endDateLabel: tAbs('common.end'),
    apply: tAbs('common.apply'),
    clear: tAbs('common.clear'),
    last2days: t('last_2_days'),
    last7days: t('last_7_days'),
    last30days: t('last_30_days'),
    allTime: t('all_time'),
    custom: t('custom')
  };

  const calendarPickerStrings = {
    months: [
      t('january'),
      t('february'),
      t('march'),
      t('april'),
      t('may'),
      t('june'),
      t('july'),
      t('august'),
      t('september'),
      t('october'),
      t('november'),
      t('december')
    ],
    shortMonths: [t('short_january'), t('short_february'), t('short_march'), t('short_april'), t('short_may'), t('short_june'),
      t('short_july'), t('short_august'), t('short_september'), t('short_october'), t('short_november'), t('short_december')],
    days: [t('sunday'), t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday')],
    shortDays: [t('short_sunday'), t('short_monday'), t('short_tuesday'), t('short_wednesday'), t('short_thursday'), t('short_friday'), t('short_saturday')],
    weekNumberFormatString: 'Week number {0}',
    prevMonthAriaLabel: 'Previous month',
    nextMonthAriaLabel: 'Next month',
    prevYearAriaLabel: 'Previous year',
    nextYearAriaLabel: 'Next year',
    prevYearRangeAriaLabel: 'Previous year range',
    nextYearRangeAriaLabel: 'Next year range',
    closeButtonAriaLabel: tAbs('common.close'),
  };

  return { dateTimeFilterStrings, calendarPickerStrings };
};

const DateTimeFilterContainer = ({ fullTimeRange, isLoading }) => {
  const { t, tAbs, isRTL } = useLocale('components.date_time_filter');
  const [query, setQuery] = useQueryParams({
    startDate: StringParam,
    endDate: StringParam,
  });

  const { dateTimeFilterStrings, calendarPickerStrings } = translateDatesStrings({ t, tAbs });

  const startCalendarPickerProps = { strings: calendarPickerStrings };
  const endCalendarPickerProps = { strings: calendarPickerStrings };

  return (
    <DateTimeFilter
      fullTimeRange={fullTimeRange || { start: '', end: '' }}
      isLoading={isLoading}
      endCalendarPickerProps={endCalendarPickerProps}
      startCalendarPickerProps={startCalendarPickerProps}
      startDate={query.startDate}
      endDate={query.endDate}
      onDateChanged={setQuery}
      strings={dateTimeFilterStrings}
      isRtl={isRTL}
    />
  );
};

export default DateTimeFilterContainer;
