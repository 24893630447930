import React from 'react';
import { FlexColumn } from 'ci-common-ui';
import useLocale from 'hooks/use-locale';
import { getIsCustomerServiceMode } from 'utils/app-context/app-context';
import { Link } from '../../../../components/Link/Link';
import { StyledPrimaryButton } from '../../../../components/StyledButton/StyledButton';
import {
  BannerContainer,
  BannerInnerContainer,
  BannerHeader,
  BannerDescription,
  ConnectButtonContainer,
  BannerCancelIcon,
  StyledBannerImage,
} from './StyledSetupAppBanner';
import { MODALS } from '../../../../constants/modals';
import { useAppState } from '../../../../providers/AppStateProvider';
import { TRACK_IDS } from '../../../../constants/tracking';
import { eventAction } from '../../../../services/telemetry-service/consts';
import { trackEvent } from '../../../../services/telemetry-service/telemetry-service';

const LEARN_MORE_URL = 'https://docs.microsoft.com/dynamics365/ai/sales/dynamics365-sales-insights-app';
const LEARN_MORE_URL_CUSTOMER_SERVICE = 'https://go.microsoft.com/fwlink/?linkid=2116725';

const SetupAppBanner = ({ onCloseClicked }) => {
  const { setAppState } = useAppState();
  const { t, tAbs } = useLocale('pages.home.demo_app_banner');
  const onConnectDataClick = () => {
    trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.PROVISION_BANNER_GET_STARTED });
    setAppState({ modalName: MODALS.Onboarding });
  };

  return (
    <BannerContainer>
      <BannerCancelIcon iconName="cancel" onClick={onCloseClicked} />
      <BannerInnerContainer>
        <FlexColumn>
          <BannerHeader multiline>{t('title')}</BannerHeader>
          <BannerDescription multiline>
            {t('subtitle')}
            <Link href={getIsCustomerServiceMode() ? LEARN_MORE_URL_CUSTOMER_SERVICE : LEARN_MORE_URL} target="_blank">{tAbs('common.learn_more')}</Link>
          </BannerDescription>
          <ConnectButtonContainer>
            <StyledPrimaryButton iconProps={{ iconName: 'PlugDisconnected' }} onClick={onConnectDataClick}>
              {t('connect_button')}
            </StyledPrimaryButton>
          </ConnectButtonContainer>
        </FlexColumn>
        <StyledBannerImage />
      </BannerInnerContainer>
    </BannerContainer>
  );
};

export default SetupAppBanner;
