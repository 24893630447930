import React from 'react';
import { StyledLink } from '../../utils/styles/StyledUtils';
import { eventAction } from '../../services/telemetry-service';

export const TrackedLink = (props) => {
  const { trackEvent, actionOn, trackMessage, children, onClick, ...otherProps } = props;
  const onLinkClick = () => {
    if (onClick) onClick();
    trackEvent({ action: eventAction.click, actionOn: actionOn || 'LINK', message: trackMessage || otherProps.href });
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StyledLink {...otherProps} onClick={onLinkClick}>{children}</StyledLink>;
};
