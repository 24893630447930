import React, { useEffect } from 'react';
import { PROVISION_STATES, usePolling, useFetch, Trans } from 'ci-common-ui';
import { get, isEmpty, isNil } from 'lodash';
import styled from 'styled-components';
import useLocale from 'hooks/use-locale';
import { ProgressIndicator } from '@fluentui/react';
import { getProvisioningStatus } from '../network/data-services/app-service';
import EmptyPage from '../pages/components/EmptyPage/EmptyPage';
import { eventAction } from '../services/telemetry-service/consts';
import { appContext } from '../utils/app-context/app-context';
import { trackEvent, trackError } from '../services/telemetry-service/telemetry-service';
import { TRACK_IDS } from '../constants/tracking';
import { useAppState } from '../providers/AppStateProvider';
import { notificationBarsTypes, ERROR } from './NotificationBars/notification-bars-types';
import { dayJS } from '../utils/date/date';

const StyledProgressIndicator = styled(ProgressIndicator)`padding: 60px 0;`;
const PendingProvisionContainer = styled.div`padding: 100px 150px;`;
const POLLING_INTERVAL = 1000 * 15; // 15 secs
const POLLING_TIMEOUT = 1000 * 60 * 30; // 30 min
const LOCALIZATION_PREFIX = 'components.pending_provision';
const notNilAndNotInProgress = (provisionState) => !isNil(provisionState) && provisionState !== PROVISION_STATES.ProvisionInProgress;
const subtitle = <Trans i18nKey={`${LOCALIZATION_PREFIX}.subtitle`}>fragment1<br />fragment2</Trans>;

export const PendingProvisionScreen = () => {
  const { setAppState, appState: { notificationBars } } = useAppState();
  const { t } = useLocale(LOCALIZATION_PREFIX);
  const { data: provisioningData, fetchData: fetchProvisionStatus } = useFetch(() => getProvisioningStatus(appContext.orgId));
  const { startPolling: startPollingProvisionStatus, isPolling, isTimeoutError } = usePolling(fetchProvisionStatus, POLLING_INTERVAL, POLLING_TIMEOUT);
  const provisionState = get(provisioningData, 'state');
  const isUserProvisioned = provisionState === PROVISION_STATES.Provisioned;

  useEffect(() => {
    const startTime = dayJS();
    if (!isPolling) {
      trackEvent({ overrideSource: TRACK_IDS.SOURCES.PENDING_PROVISION_SCREEN, action: eventAction.pageView, actionOn: TRACK_IDS.COMMON.VIEWED });
      startPollingProvisionStatus();
    }

    return () => {
      const timeSpent = dayJS().diff(startTime, 'seconds');
      trackEvent({ overrideSource: TRACK_IDS.SOURCES.PENDING_PROVISION_SCREEN, action: eventAction.pageTimeSpent, message: timeSpent });
    };
  }, []);

  useEffect(() => {
    if (isTimeoutError || notNilAndNotInProgress(provisionState)) {
      let newProvisionBar = null;
      if (isUserProvisioned) {
        trackEvent({ overrideSource: TRACK_IDS.SOURCES.PENDING_PROVISION_SCREEN, action: eventAction.pageView, actionOn: TRACK_IDS.USER_FINISHED_PROVISIONING });
      } else {
        newProvisionBar = {
          type: notificationBarsTypes.Provision,
          props: { provisionState },
          level: ERROR
        };
        trackError({ overrideSource: TRACK_IDS.SOURCES.PENDING_PROVISION_SCREEN, actionOn: TRACK_IDS.USER_FAILED_PROVISIONING });
      }

      const newState = { isProvisioningInProgress: false };
      if (newProvisionBar) {
        newState.notificationBars = isEmpty(notificationBars) ? [newProvisionBar] : notificationBars.map((bar) => bar.type === newProvisionBar.type ? newProvisionBar : bar);
      }

      setAppState(newState);
    }
  }, [provisionState, isTimeoutError]);

  return (
    <PendingProvisionContainer>
      <EmptyPage titleText={t('title')} subtitleText={subtitle} />
      <StyledProgressIndicator />
    </PendingProvisionContainer>
  );
};
