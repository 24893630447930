import styled from 'styled-components';
import { Icon } from '@fluentui/react';
import { colors } from '../../../../../../../../constants/colors';
import { FlexColumn, FlexRow, FlexRowCentered } from '../../../../../../../../utils/styles/StyledUtils';

export const Container = styled.div`
  margin-bottom: 16px;
`;

export const CurrentPeopleBar = styled(FlexRow)`
  align-items: center;
`;

export const personaStyles = {
  root: {
    display: 'inline-block',
    marginRight: '4px'
  },
  primaryText: {
    display: 'none'
  }
};

export const SelectorContainer = styled(FlexColumn)`
  padding: 0 8px;
`;
export const SelectorSectionValues = styled.div`
  margin-top: 12px;
`;

export const SelectorBody = styled.div`
  padding: 8px;
  background-color: ${colors.Gray10};
`;

export const FieldLabel = styled.div`
  margin-top: 4px;
  padding-bottom: 8px;
  color: ${colors.Gray130};
  font-size: 12px;
  border-bottom: 1px solid ${colors.Gray30}; 
`;

export const SectionHeader = styled(FlexRow)`
  align-items: center; 
  justify-content: space-between;
`;

export const checkboxStyles = {
  root: {
    height: '30px'
  }
};

export const singleChoicePersonaStyles = (isSelectedValue) => ({
  root: {
    marginTop: '4px',
    cursor: 'pointer',
    borderRight: isSelectedValue ? `2px solid ${colors.DYNPrimary}` : 'none',
  },
  primaryText: {
    fontWeight: isSelectedValue ? 600 : 'normal'
  }
});

export const DropdownValues = styled(FlexRowCentered)`
  height: 100%;
  overflow: hidden;

  ${({ showIcon }) => showIcon && `
    justify-content: space-between;
    width: 100%;
  `}
`;

export const NonCrmIcon = styled(Icon)`
  margin: 0 5px;
  color: ${colors.DYNPrimary};
`;

export const AddNew = styled(FlexRowCentered)`
  font-weight: normal;
  cursor: pointer;
  padding: 0px 12px;
  margin: 0px -8px;
    
  &:hover {
    background-color: ${colors.Gray20};
  }
`;

export const ErrorMessage = styled.div`
  color: ${colors.Error};
`;
