import styled from 'styled-components';
import { colors, FlexColumnCentered } from 'ci-common-ui';
import { PrimaryButton, Link } from '@fluentui/react';
import PageDeprecationPNG from '../../assets/images/page-deprecation-icon.png';

export const Container = styled(FlexColumnCentered)`
  height: 100%;
  width: 100%;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  padding: 5px;
`;

export const Text = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

export const Subtitle = styled(Text)`
  padding-bottom: 25px;
  white-space: break-spaces;
  text-align: center;
`;

export const DownloadNewDashboardButton = styled(PrimaryButton)`
  height: 25px;
  background-color: ${colors.DYNPrimary};
  color: ${colors.White};
  margin-bottom: 10px;
`;

export const LearnMoreLink = styled(Link)`
  color: ${colors.DYNPrimary};
`;

export const PageDeprecationIcon = styled.div`
  height: 128px;
  width: 128px;
  background-image: url(${PageDeprecationPNG});
  background-repeat: no-repeat;
`;
