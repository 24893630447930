import styled from 'styled-components';
import { colors } from 'ci-common-ui';
import { Spinner } from '@fluentui/react';

export const Container = styled.div`
`;

export const Content = styled.div`
  padding 20px 30px;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const SubTitle = styled.div`
  color: ${colors.Gray130};
  margin-top: 8px;
  margin-bottom: 24px;
`;

export const Details = styled.div`
  padding 20px 0;
`;

export const Loading = styled(Spinner)`
  margin-top: 50px;
`;

export const Error = styled.div`
  padding 20px 0;
  color: ${colors.ScarletRed};
`;
