import { merge, find, first, isNil, size, get, isEmpty, sortBy, take, reverse, map } from 'lodash';
import { transformSentimentDataForPie, transformSentimentDataForPercentagesBar } from 'utils/chart';
import { transformServerDate } from '../dateFilters/date-filters';
import { dataTransformer } from '../../../utils/error-state/error-state';
import { formatRound } from '../../../utils/numeric';

const GROUP_NAME = {
  TOP_SELLERS: 'TopSellers',
  REST_OF_TEAM: 'RestOfTeam'
};

const MAX_TOP_ITEMS = 5;

const homeTransformers = {
  dateTimeRange: (data) => transformServerDate(data),
  customerSentiment: (data) => transformCustomerSentiment(data),
  trackedKeywordsTopTrending: (data) => transformTopKeywords(data.trackedKeywordsTopTrending),
  trackedKeywordsTopMentioned: (data) => transformTopKeywords(data.trackedKeywordsTopMentioned),
  competitorsTopTrending: (data) => transformTopCompetitors(data.competitorsTopTrending),
  competitorsTopMentioned: (data) => transformTopCompetitors(data.competitorsTopMentioned),
  brandsTopNew: (data) => transformTopBrands(data.brandsTopNew),
  brandsTopMentioned: (data) => transformTopBrands(data.brandsTopMentioned),
  talkToListenRatio: (data) => transformTalkToListenRatio(data.topSellersTalkToListenRatio),
  bestPracticeKeywords: (data) => transformBestPracticeKeywords(data.topSellersTopMentioning, data.topSellersTopMentioningDiff),
  negativeCustomerSentiment: (data) => transformNegativeCustomerSentiment(data.topSellersNegativeCustomerSentiment),
  switchRate: (data) => transformSwitchRate(data.topSellersSwitchRate),
  customerEngagement: (data) => transformCustomerEngagement(data.topSellersTimeSpentOn),
  opportunityKpis: (data) => transformOpportunityKpis(data),
  momKpis: (data) => transformMomKpis(data),
  topPerformers: (data) => data.topPerformers,
};

export const transformHome = (data, topPerformers) => (
  dataTransformer('home', homeTransformers, { ...data, topPerformers })
);

const transformOpportunityKpis = ({ opportunitiesStats, opportunitiesWinRate }) => (
  merge({}, first(opportunitiesStats), first(opportunitiesWinRate))
);

const transformMomKpis = ({ totalCallCount, totalCallCountWhereCompetitorsIsMentioned,
  totalCallCountWhereTrackedKeywordIsMentioned, totalCallDuration }) => ({
  recordedCalls: {
    count: first(totalCallCount).countTotalCall,
    variance: first(totalCallCount).variance
  },
  recordedHours: {
    count: first(totalCallDuration).sumOfCallRecordingDurationsInHours,
    variance: first(totalCallDuration).variance
  },
  callsWithCompetitorMentions: {
    count: first(totalCallCountWhereCompetitorsIsMentioned).callsCountWhereCompetitorsIsMentioned,
    variance: first(totalCallCountWhereCompetitorsIsMentioned).variance
  },
  callsWithTrackedKeywords: {
    count: first(totalCallCountWhereTrackedKeywordIsMentioned).callsCountWhereTrackerIsMentioned,
    variance: first(totalCallCountWhereTrackedKeywordIsMentioned).variance
  }
});

const transformCustomerSentiment = ({ customerSentiment }) => {
  if (
    isEmpty(customerSentiment)
    || customerSentiment[0].sentimentCallsCount === 0
    || customerSentiment[0].customerNegativeSentimentAboveAvgCount === 0) {
    return null;
  }

  const pieData = [{
    callsCount: customerSentiment[0].sentimentCallsCount,
    positive: customerSentiment[0].customerPositiveAvgSentiment,
    negative: customerSentiment[0].customerNegativeAvgSentiment,
    neutral: customerSentiment[0].customerNeutralAvgSentiment,
  }];

  return {
    ...transformSentimentDataForPie(pieData),
    negativeCustomerSentimentCount: customerSentiment[0].customerNegativeSentimentAboveAvgCount
  };
};

const takeTopItems = (items) => (
  reverse(take(
    reverse(sortBy(items, 'value')),
    MAX_TOP_ITEMS
  ))
);

const transformTopKeywords = (data) => (
  takeTopItems(map(
    data,
    ({
      tracker,
      trend,
      total,
    }) => ({
      name: tracker,
      value: total,
      trend
    }),
  ))
);

const transformTopCompetitors = (data) => (
  takeTopItems(map(
    data,
    ({
      competitor,
      trend,
      total,
    }) => ({
      name: competitor,
      value: total,
      trend
    }),
  ))
);

const transformTopBrands = (data) => (
  takeTopItems(map(
    data,
    ({
      brand,
      total
    }) => ({
      name: brand,
      value: total
    })
  ))
);

const transformCustomerEngagement = (data) => {
  const customerEngagement = first(data);
  if (isEmpty(customerEngagement)) {
    return null;
  }
  return [{
    name: 'calls',
    value: Math.round(customerEngagement.avgTimeOnCallsHours)
  }, {
    name: 'emails',
    value: Math.round(customerEngagement.avgTimeOnMailsSentHours)
  }, {
    name: 'meetings',
    value: Math.round(customerEngagement.avgTimeMeetingAttendedHours)
  }];
};

const transformTalkToListenRatio = (data) => {
  const ratioData = first(data);
  if (isNil(get(ratioData, 'topSellertTalkToListenRatio'))) {
    return null;
  }

  const { topSellertTalkToListenRatio, restOfTheTeamTalkToListenRatio } = ratioData;

  const isRestOfTheTeamEmpty = restOfTheTeamTalkToListenRatio === null;

  const topSellerTalking = formatRound(topSellertTalkToListenRatio * 100);
  const topSellerListening = 100 - topSellerTalking;
  const restOfTeamTalking = isRestOfTheTeamEmpty ? null : formatRound(restOfTheTeamTalkToListenRatio * 100);
  const restOfTeamListening = isRestOfTheTeamEmpty ? null : 100 - restOfTeamTalking;

  return {
    topSellerTalking,
    topSellerListening,
    restOfTeamTalking,
    restOfTeamListening,
  };
};

const transformBestPracticeKeywords = (topData, topDataDiff) => ({
  topData: takeTopItems(map(topData, ({ keyword, topSellersTotal }) => ({
    name: keyword,
    value: topSellersTotal
  }))),
  topDataDiff: map(topDataDiff, ({ keyword }) => ({
    name: keyword
  })),
});

const transformSentimentData = ({ positiveAvg, negativeAvg, neutralAvg }) => ({
  positive: positiveAvg,
  negative: negativeAvg,
  neutral: neutralAvg
});

const transformNegativeCustomerSentiment = (data) => {
  if (size(data) !== 2) {
    return null;
  }
  const topSellerData = find(data, ({ groupName }) => groupName === GROUP_NAME.TOP_SELLERS);
  const restOfTeamData = find(data, ({ groupName }) => groupName === GROUP_NAME.REST_OF_TEAM);
  const isRestOfTeamEmpty = restOfTeamData.positiveAvg === null;
  if (isEmpty(topSellerData) || isEmpty(restOfTeamData)) {
    return null;
  }

  return {
    topSeller: transformSentimentDataForPercentagesBar([transformSentimentData(topSellerData)])[0],
    restOfTeam: isRestOfTeamEmpty ? transformSentimentData(restOfTeamData) : transformSentimentDataForPercentagesBar([transformSentimentData(restOfTeamData)])[0],
  };
};

const transformSwitchRate = (data) => {
  if (size(data) !== 2) {
    return null;
  }
  const topSellerData = find(data, ({ groupName }) => groupName === GROUP_NAME.TOP_SELLERS);
  const restOfTeamData = find(data, ({ groupName }) => groupName === GROUP_NAME.REST_OF_TEAM);
  if (isEmpty(topSellerData) || isEmpty(restOfTeamData)) {
    return null;
  }
  return {
    topSeller: Math.round(topSellerData.avgSwitchRate),
    restOfTeam: Math.round(restOfTeamData.avgSwitchRate),
  };
};
