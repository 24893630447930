import React from 'react';
import isNil from 'lodash/isNil';
import transform from 'lodash/transform';
import snakeCase from 'lodash/snakeCase';
import useLocale from 'hooks/use-locale';
import { formatDate, getDaysLeftToQuarter, getQuarter, dayJS } from 'utils/date/date';
import { formatRoundAfterDot, formatCurrency } from 'utils/numeric';
import { Shimmer } from '@fluentui/react';
import { getIsTrialMode } from 'utils/app-context/app-context';
import { OpportunityKpiValue, OpportunityKpisBar } from './StyledOpportunityKpis';
import KpisBar from '../KpisBar/KpisBar';

const opportunityKpisTransformation = {
  actualRevenue: (value) => `${formatCurrency(value)}`,
  avgRevenue: (value) => `${formatCurrency(value)}`,
  dealsWon: (value) => value,
  opportunityWinRate: (value) => `${formatRoundAfterDot(value * 100, 2)}%`,
  avgDealSize: (value) => `${formatCurrency(value)}`,
};

const OpportunityKpis = ({ data, isLoading }) => {
  const { t } = useLocale('pages.home.opportunityKpis');
  if ((!data && !isLoading) || getIsTrialMode()) return <OpportunityKpisBar />;

  const titlesWithoutComponents = [
    { title: formatDate(dayJS(), 'dddd, MMMM D, YYYY') },
    { title: t('quarter_info', { quarterNum: getQuarter(), daysLeft: getDaysLeftToQuarter() }) },
  ];

  const transformedOpportunityKpis = transform(data, (result, value, key) => {
    const opportunityKpiTransformation = opportunityKpisTransformation[key];
    if (!isNil(value) && opportunityKpiTransformation) {
      result.push({
        title: t(snakeCase(key)),
        component: <OpportunityKpiValue>{opportunityKpiTransformation(value)}</OpportunityKpiValue>,
      });
    }
  }, titlesWithoutComponents);

  return (
    <OpportunityKpisBar>
      <Shimmer isDataLoaded={!isLoading}>
        <KpisBar translatedTitlesAndComponents={transformedOpportunityKpis} />
      </Shimmer>
    </OpportunityKpisBar>
  );
};

export default OpportunityKpis;
