import React from 'react';
import useLocale from 'hooks/use-locale';
import { IconButton } from '@fluentui/react';
import { TabHeader, TabBody } from '../../../StyledSettingsModal';

const DataSourceWrapper = ({ closeModal, children }) => {
  const { t, tAbsDefault } = useLocale('pages.settings.data_source');
  return (
    <>
      <TabHeader>
        <span>{t('nav_title')}</span>
        <IconButton iconProps={{ iconName: 'Cancel' }} ariaLabel={`${tAbsDefault('common.close')} ${tAbsDefault('common.settings')}`} onClick={closeModal} />
      </TabHeader>
      <TabBody>
        {children}
      </TabBody>
    </>
  );
};

export default DataSourceWrapper;
