import { ACTION_ITEM_TYPES, ActionItemEntitiyTypes } from './utils';

const FIELD_TYPE_IDS = {
  SUBJECT: 'subject',
  PEOPLE: 'people',
  OWNER: 'owner',
  TO: 'to',
  FROM: 'from',
  DATE: 'date',
  REGARDING: 'regarding',
};

export const FormFields = Object.freeze({
  [ACTION_ITEM_TYPES.EMAIL]: [
    { id: FIELD_TYPE_IDS.SUBJECT },
    {
      id: FIELD_TYPE_IDS.PEOPLE,
      config: {
        fields: [
          {
            id: FIELD_TYPE_IDS.FROM,
            isSingleValue: true,
            entityType: ActionItemEntitiyTypes.OWNER,
            isRequired: true,
          },
          {
            id: FIELD_TYPE_IDS.TO,
            entityType: ActionItemEntitiyTypes.RECIPIENT,
            isRequired: true,
            isCrmEntityRequired: true,
          },
        ],
      },
    },
  ],
  [ACTION_ITEM_TYPES.MEETING]: [
    { id: FIELD_TYPE_IDS.SUBJECT },
    {
      id: FIELD_TYPE_IDS.PEOPLE,
      config: {
        fields: [
          {
            id: FIELD_TYPE_IDS.OWNER,
            isSingleValue: true,
            entityType: ActionItemEntitiyTypes.OWNER,
            isRequired: true,
          },
          {
            id: FIELD_TYPE_IDS.TO,
            entityType: ActionItemEntitiyTypes.RECIPIENT,
            isRequired: true,
            isCrmEntityRequired: true,
          },
        ],
      },
    },
    { id: FIELD_TYPE_IDS.DATE, isTimeEnabled: true },
  ],
  [ACTION_ITEM_TYPES.TASK]: [
    { id: FIELD_TYPE_IDS.SUBJECT },
    {
      id: FIELD_TYPE_IDS.PEOPLE,
      config: {
        fields: [
          {
            id: FIELD_TYPE_IDS.OWNER,
            isSingleValue: true,
            entityType: ActionItemEntitiyTypes.OWNER,
            isRequired: true,
            isCrmEntityRequired: true,
          },
        ],
      },
    },
    { id: FIELD_TYPE_IDS.DATE, isTimeEnabled: true },
    { id: FIELD_TYPE_IDS.REGARDING },
  ],
  [ACTION_ITEM_TYPES.PHONE_CALL]: [
    { id: FIELD_TYPE_IDS.SUBJECT },
    {
      id: FIELD_TYPE_IDS.PEOPLE,
      config: {
        fields: [
          {
            id: FIELD_TYPE_IDS.FROM,
            isSingleValue: true,
            entityType: ActionItemEntitiyTypes.OWNER,
            isRequired: true,
            isCrmEntityRequired: true,
          },
          {
            id: FIELD_TYPE_IDS.TO,
            entityType: ActionItemEntitiyTypes.RECIPIENT,
            isRequired: true,
            isCrmEntityRequired: true,
          },
        ],
      },
    },
    { id: FIELD_TYPE_IDS.DATE, isTimeEnabled: true },
    { id: FIELD_TYPE_IDS.REGARDING },
  ],
});
