import styled from 'styled-components';
import { defaultFontFamilyCss, FlexColumn, FlexRowCentered } from '../../utils/styles/StyledUtils';
import { colors } from '../../constants/colors';
import ErrorSVG from '../../assets/images/page-error-state.svg';
import NoPermissionSVG from '../../assets/images/no-permission.svg';

export const AppErrorWrapper = styled(FlexRowCentered)`
  margin: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  color: ${colors.Gray160};
`;

export const AppErrorContainer = styled(FlexColumn)`
  ${defaultFontFamilyCss};
  color: ${colors.Gray160};
  align-items: center;
  max-width: 700px;
  text-align: center;
  padding: 8px;
`;

export const Title = styled.div`
  margin-top: 24px;
  font-size: 18px;
  font-weight: 600;
`;

export const Subtitle = styled.div`
  margin-top: 16px;
  font-size: 16px;
`;

export const ErrorImg = styled(ErrorSVG).attrs({
  alt: 'App Error'
})`
  width: 200px;
  height: 200px;
`;

export const NoPermissionImg = styled(NoPermissionSVG).attrs({
  alt: 'No Permission Error'
})`
  width: 200px;
  height: 200px;
`;
