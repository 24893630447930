import forOwn from 'lodash/forOwn';
import mapKeys from 'lodash/mapKeys';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { defaultLocale, setExternalLibrariesLocale } from '../services/i18n-service/i18n-service';
import { LOCALES_MAP } from '../constants/locales';

export const DEFAULT_APP_LOCALE_MODE = 'default';

i18next.init({
  pluralSeparator: '#',
});

export function useLocale(_prefix, _appMode) {
  const prefix = _prefix || '';
  const appMode = _appMode || DEFAULT_APP_LOCALE_MODE;
  const { t: translate, i18n } = useTranslation(null, { i18n: i18next });
  const isRTL = i18n.dir() === 'rtl';
  const translateDefault = i18next.getFixedT(i18n.language?.startsWith(defaultLocale) ? i18n.language : defaultLocale);

  const localeId = LOCALES_MAP[i18n.language] ? LOCALES_MAP[i18n.language].id : LOCALES_MAP[defaultLocale].id;
  const appModeAwareTerms = mapKeys(
    translate(
      `common.app_mode_aware_terms.${appMode}`,
      { returnObjects: true },
    ),
    (value, translationKey) => `_${translationKey}`,
  );

  function getAppModeAwareKey(key) {
    const appModeAwareKey = `${key}_${appMode}`;
    if (appMode !== DEFAULT_APP_LOCALE_MODE && i18n.exists(appModeAwareKey)) {
      return appModeAwareKey;
    }
    return key;
  }

  function tBase(key, params, basePrefix, translationFunction) {
    let text;
    const k = basePrefix + key;
    const translated = translationFunction(k, appModeAwareTerms);
    text = translated || k;

    if (!translated) {
      // eslint-disable-next-line no-console
      console.error(`Translation error: ${k} doesn't exist`); // TODO: change with telemetry
    }

    if (params) {
      forOwn(params, (value, paramKey) => {
        text = text.replace(new RegExp(`%${paramKey}%`, 'g'), value);
      });
    }
    return text;
  }

  function t(key, params) {
    return tBase(getAppModeAwareKey(key), params, prefix ? `${prefix}.` : prefix, translate);
  }

  function tAbs(key, params) {
    return tBase(getAppModeAwareKey(key), params, '', translate);
  }

  function tDefault(key, params) {
    return tBase(getAppModeAwareKey(key), params, prefix ? `${prefix}.` : prefix, translateDefault);
  }

  function tAbsDefault(key, params) {
    return tBase(getAppModeAwareKey(key), params, '', translateDefault);
  }

  function tObj(key) {
    return translate((prefix ? `${prefix}.` : prefix) + key, { returnObjects: true });
  }

  function tAbsObj(key) {
    return translate(key, { returnObjects: true });
  }

  function changeLocale(locale) {
    i18n.changeLanguage(locale);
    setExternalLibrariesLocale(locale);
  }

  return {
    t,
    tAbs,
    changeLocale,
    isRTL,
    localeId,
    i18n,
    appModeAwareTerms,
    tDefault,
    tAbsDefault,
    tObj,
    tAbsObj
  };
}
