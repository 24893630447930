import styled from 'styled-components';
import { TabBody } from '../../../StyledSettingsModal';

export const Container = styled.div`
  height: 100%;
`;

export const TextContainer = styled.div`
flex: 1;
`;

export const ChartContainer = styled.div`
  flex: 1;
  height: 240px;
  min-width: 180px;
  `;

export const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TabBodyWrapper = styled(TabBody)`
  justify-content: center;
  width: 100%;
`;
