import React from 'react';
import useLocale from 'hooks/use-locale';
import { useToggler } from 'ci-common-ui';
import { IconButton } from '@fluentui/react';

import { Root, Text } from './StyledConnectionStringDisplay';

// eslint-disable-next-line react/no-array-index-key
const dots = <span>{Array.from(Array(48)).map((item, index) => <span key={index}>&#8226;</span>)}</span>;

const ConnectionString = ({ text }) => {
  const { t } = useLocale('pages.onboarding.finish');
  const [isVisible, toggleVisible] = useToggler(false);

  const currentText = isVisible ? text : dots;

  return (
    <Root>
      <Text title={isVisible ? text : undefined}>{t('connection_string')}: {currentText}</Text>
      <IconButton iconProps={{ iconName: isVisible ? 'Hide' : 'RedEye' }} onClick={toggleVisible} />
    </Root>
  );
};

export default ConnectionString;
