import React, { useEffect } from 'react';
import { IconButton } from '@fluentui/react';
import useLocale from 'hooks/use-locale';
import { useFetchNow } from 'ci-common-ui';
import { getLicenseUsage } from 'network/data-services/settings';
import { TabTitleText, TabHeader } from '../../../StyledSettingsModal';
import { Container, TabBodyWrapper } from './StyledLicenseUsage';
import LicenseUsage from './LicenseUsage';
import { componentLoadingTimeService } from '../../../../../services/component-loading-time-service/component-loading-time-service';
import { TRACK_IDS } from '../../../../../constants/tracking';

const LicenseUsageWrapper = ({ closeModal }) => {
  const { t, tAbsDefault } = useLocale('pages.settings.license_usage');
  const { data: licenseUsage, isLoading, error } = useFetchNow(getLicenseUsage);

  useEffect(() => {
    if (!isLoading) {
      componentLoadingTimeService.end(TRACK_IDS.SOURCES.SETTINGS_MODAL);
    }
  }, [isLoading]);

  return (
    <Container>
      <TabHeader>
        <TabTitleText>{t('nav_title')}</TabTitleText>
        <IconButton iconProps={{ iconName: 'Cancel' }} onClick={closeModal} ariaLabel={`${tAbsDefault('common.close')} ${tAbsDefault('common.settings')}`} />
      </TabHeader>
      <TabBodyWrapper>
        <LicenseUsage licenseUsage={licenseUsage} isLoading={isLoading} error={error} />
      </TabBodyWrapper>
    </Container>
  );
};

export default LicenseUsageWrapper;
