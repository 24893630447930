import React, { useEffect } from 'react';
import SelectOrganization from './components/Steps/SelectOrganization/SelectOrganization';
import TermsAndConditions from './components/Steps/TermsAndConditions/TermsAndConditions';
import ExternalOnboarding from './components/Steps/ExternalOnboarding/ExternalOnboarding';
import Finish from './components/Steps/Finish/Finish';
import Wizard from '../../components/Wizard/Wizard';
import { TRACK_IDS } from '../../constants/tracking';
import { useOverridePageTracking } from '../../hooks/use-override-page-tracking';
import { componentLoadingTimeService } from '../../services/component-loading-time-service/component-loading-time-service';

const steps = [
  { name: 'organization', component: SelectOrganization },
  { name: 'terms', component: TermsAndConditions },
  { name: 'finish', component: Finish },
];

const adminSteps = [
  { name: 'organization', component: SelectOrganization },
  { name: 'external_onboarding', component: ExternalOnboarding },
];

const getAvailableSteps = (settings, stepIndex) => {
  if (stepIndex === 0) {
    return [steps[0]];
  }

  const { isAdminAndNotProvisioned } = settings.org.resolvedOrgDetails;

  return isAdminAndNotProvisioned ? adminSteps : steps;
};

let isFirstOnboardingModalRender = true;

const startLoadingTimer = () => {
  if (isFirstOnboardingModalRender) {
    componentLoadingTimeService.start(TRACK_IDS.SOURCES.ONBOARDING_MODAL);
  }
  isFirstOnboardingModalRender = false;
};

const OnboardingModal = ({ closeModal }) => {
  startLoadingTimer();
  useOverridePageTracking(TRACK_IDS.SOURCES.ONBOARDING_MODAL);

  useEffect(() => () => { isFirstOnboardingModalRender = true; }, []); // unMount

  return (
    <Wizard
      closeModal={closeModal}
      getAvailableSteps={getAvailableSteps}
      translationPrefix="pages.onboarding"
      trackingSource={TRACK_IDS.SOURCES.ONBOARDING_MODAL}
    />
  );
};

export default OnboardingModal;
