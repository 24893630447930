import styled, { css } from 'styled-components';
import { colors, lightTheme } from '../../constants/colors';
import { StyledLink, defaultFontFamilyCss } from '../../utils/styles/StyledUtils';

export const PILL_TYPES = {
  TAG: 'tag',
  BADGE: 'badge'
};

const pillStyle = {
  [PILL_TYPES.BADGE]: css && `
    padding: 4px 8px;
    background-color: ${lightTheme.colors.Gray20};
    color: ${lightTheme.colors.Gray130};
    border: 0;
    font-weight: normal;
    font-size: 10px;
  `,
  [PILL_TYPES.TAG]: css && `
    padding: 2px 8px;
    background-color: ${colors.White};
    color: ${colors.Gray130};
    border: 1px solid ${colors.Gray300};
    font-size: 12px;
  `
};

export const Pill = styled(StyledLink).attrs({
  as: 'button'
})`
  border-radius: 20px;
  ${({ type }) => pillStyle[type] || pillStyle[PILL_TYPES.TAG]};
  ${defaultFontFamilyCss};
`;
