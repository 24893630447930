export const downloadScript = (src, id, isScriptNotLoadedYet, isCss) => new Promise((resolve) => {
  const onScriptLoad = () => resolve(true);
  const onScriptError = () => resolve(false);

  const addLoadEvent = (currentScript) => {
    currentScript.addEventListener('load', () => {
      cleanup(currentScript);
      onScriptLoad();
    });
  };

  const addErrorEvent = (currentScript) => {
    currentScript.addEventListener('error', () => {
      cleanup(currentScript);
      onScriptError();
    });
  };

  const cleanup = (currentScript) => {
    currentScript.removeEventListener('load', onScriptLoad);
    currentScript.removeEventListener('error', onScriptError);
  };

  const existingScript = document.querySelector(`script[src="${src}"]`) || document.querySelector(`link[href="${src}"]`); // to prevent duplicate scripts on DOM

  if (!src) {
    resolve(false);
  } else if (existingScript) {
    if (isScriptNotLoadedYet) {
      addLoadEvent(existingScript);
      addErrorEvent(existingScript);
    } else {
      resolve(true);
    }
  } else {
    // Create script
    const script = document.createElement(isCss ? 'link' : 'script');
    if (isCss) {
      script.href = src;
      script.rel = 'stylesheet';
      script.type = 'text/css';
    } else {
      script.src = src;
      script.async = true;
    }

    if (id) {
      script.id = id;
    }

    addLoadEvent(script);
    addErrorEvent(script);

    // Add script to document body
    document.body.appendChild(script);
  }
});
