import React, { useState, useEffect } from 'react';
import { map, sortBy, get, filter, countBy } from 'lodash';
import { PersonaSize, SpinnerSize, Spinner } from '@fluentui/react';
import { useToggler } from '../../../../hooks/use-toggler';
import { useFetch } from '../../../../hooks/use-fetch';
import {
  ParticipantsContainer, ParticipantContainer, StyledPersona, DisconnectContact, OpenContactInCrm, spinnerStyles, CopyIcon,
  Header, LongestMonologueWrapper, ExpandCardIcon, ContentWrapper, LongestMonologueText, ExpandIconWrapper, SearchContainer,
  Error, LongestMonologueSec, LongestMonologueValue, LongestMonologueValueAndSec, NumberAndCopyIconContainer,
} from './StyledParticipants';
import { useInfra } from '../../../../providers/InfraProvider';
import SearchView from '../CrmRecordLookup/components/SearchView/SearchView';
import { openCrmEntity } from '../../../../utils/crm-links';
import { CRM_ENTITY_TYPES } from '../../../../constants/crm-entities';
import { CALL_STRINGS } from '../../strings';
import { updateParticipant as updateParticipantCallService, getAllColaEntitiesOptions } from '../../network/data-services/call-service';
import { transformToLookupSchema } from '../../network/filters/call-filters/call-filters';
import { CallKPIs } from '../CallKPIs/CallKPIs';
import { EMPTY_GUID } from '../../../../constants/api';
import { extractParticipantDetails } from '../../utils';
import { PARTICIPANTS_ROLES } from '../../../../constants/filters';
import { Tooltip } from '../../../../components/Tooltip';

const getContactsFromCrm = async ({ searchTerm }) => map(await getAllColaEntitiesOptions(searchTerm, CRM_ENTITY_TYPES.CONTACT), transformToLookupSchema);
const Loading = ({ loaderText }) => <Spinner label={loaderText} ariaLive="polite" labelPosition="right" styles={spinnerStyles} size={SpinnerSize.small} />;

const Content = ({ isContactRelated, isLoading, isAgent, kpis, error, crmId, patchParticipant }) => {
  const { t, tDefault, orgUrl, clearCache, isEditable } = useInfra();
  const [wasJustRelated, setWasJustRelated] = useState(false);
  const loaderText = isContactRelated ? t(CALL_STRINGS.UNLINKING_CONTACT) : t(CALL_STRINGS.LINKING_WITH_CONTACT);
  const openContactString = wasJustRelated ? CALL_STRINGS.VIEW_CONTACT : CALL_STRINGS.SEE_DETAILS;
  const openContactInCrm = () => openCrmEntity(orgUrl, CRM_ENTITY_TYPES.CONTACT, crmId);

  const relateOrDisconnectContact = ({ updatedCrmId }) => {
    patchParticipant({ crmId: updatedCrmId });
    setWasJustRelated(!isEmpty(updatedCrmId));
    clearCache();
  };

  const disconnectContact = () => relateOrDisconnectContact({ updatedCrmId: null });
  const relateContact = ({ id: selectedContactCrmId }) => relateOrDisconnectContact({ updatedCrmId: selectedContactCrmId });

  if (isLoading) return <Loading loaderText={loaderText} />;
  if (isAgent) return <CallKPIs kpis={filter(kpis, notLongestMonolouge)} hideInfoIcon sidesMargin={8} />;
  if (!isContactRelated && isEditable) {
    return (
      <SearchContainer>
        <SearchView
          onRecordChosen={relateContact}
          getSearchResults={getContactsFromCrm}
          searchTermMinLength={0}
          title={t(CALL_STRINGS.LOOK_FOR_CONTACT)}
          hideCreateNewRecord
        />
        {error && <Error>{t(CALL_STRINGS.ERROR_UPDATE_CONTACT)}</Error>}
      </SearchContainer>
    );
  }

  if (isContactRelated) {
    return (
      <>
        <OpenContactInCrm onClick={openContactInCrm} ariaLabel={tDefault(openContactString)}>{t(openContactString)}</OpenContactInCrm>
        {isEditable && <DisconnectContact onClick={disconnectContact} ariaLabel={tDefault(CALL_STRINGS.UNLINK_FROM_CONTACT)}>{t(CALL_STRINGS.UNLINK_FROM_CONTACT)}</DisconnectContact>}
      </>
    );
  }

  return null;
};

const Participant = ({ conversationId, participant = {}, setNewParticipantDetails }) => {
  const { t, isEditable, shouldHideParticipantCrmDetails } = useInfra();
  const { id: participantId, displayName: participantName, aadId, crmId, phoneNumber, longestMonologueInMs, kpis, participantAvatar } = participant;
  const { data: updatedParticipant, fetchData: patchParticipant, isLoading, error } = useFetch((detailsToUpdate) => updateParticipantCallService(conversationId, participantId, detailsToUpdate));
  const { primaryText: personaPrimaryText, secondaryText: personaSecondaryText, isContactRelated, isAgent, isCustomer, isUnknown } = extractParticipantDetails(participant, t(CALL_STRINGS.UNKNOWN));
  const isChevron = isAgent || isContactRelated;
  const isKpisEmpty = !kpis?.length;
  const shouldShowExpandIcon = isAgent ? !isKpisEmpty : (!shouldHideParticipantCrmDetails && (isContactRelated || isEditable));
  const [isExpended, toggleIsExpended] = useToggler(false);

  const renderSecondaryText = () => (
    phoneNumber && personaSecondaryText === phoneNumber ? (
      <NumberAndCopyIconContainer>
        {personaSecondaryText}
        <CopyIcon title={personaSecondaryText} />
      </NumberAndCopyIconContainer>
    ) : personaSecondaryText
  );

  useEffect(() => {
    if (updatedParticipant) {
      setNewParticipantDetails({ ...participant, ...updatedParticipant });
    }
  }, [updatedParticipant]);

  return (
    <ParticipantContainer key={aadId} isExpended={isExpended}>
      <Header data-testid="participant-header" shouldShowExpandIcon={shouldShowExpandIcon} onClick={() => shouldShowExpandIcon && toggleIsExpended()}>
        <StyledPersona imageUrl={participantAvatar} participantId={participantId} text={personaPrimaryText} showSecondaryText={!!personaSecondaryText} size={PersonaSize.size32} isUnknown={isUnknown} onRenderSecondaryText={renderSecondaryText} />
        {isCustomer && !!longestMonologueInMs && (
          <LongestMonologueWrapper>
            <LongestMonologueValueAndSec><LongestMonologueValue>{longestMonologueInMs}</LongestMonologueValue><LongestMonologueSec>{t(CALL_STRINGS.MEASUREMENT_UNITS_SEC)}</LongestMonologueSec></LongestMonologueValueAndSec>
            <LongestMonologueText>{t(CALL_STRINGS.LONGEST_MONOLOGUE)}</LongestMonologueText>
          </LongestMonologueWrapper>
        )}
        {shouldShowExpandIcon && (
          <ExpandIconWrapper data-testid="expand-icon-wrapper">
            <Tooltip content={!isChevron && t(CALL_STRINGS.LINK_TO_CONTACT)}>
              <ExpandCardIcon participantName={participantName} isChevron={isChevron} isUp={isExpended} />
            </Tooltip>
          </ExpandIconWrapper>
        )}
      </Header>
      <ContentWrapper data-testid="content-wrapper" isExpended={isExpended} isRow={isContactRelated}>
        <Content
          isContactRelated={isContactRelated}
          isLoading={isLoading}
          isAgent={isAgent}
          kpis={kpis}
          phoneNumber={phoneNumber}
          error={error}
          crmId={crmId}
          patchParticipant={patchParticipant}
        />
      </ContentWrapper>
    </ParticipantContainer>
  );
};

export const Participants = ({ participants, conversationId, setNewParticipantDetails, avatars }) => {
  const { t } = useInfra();
  const processedParticipants = getProcessedParticipants(participants, t, avatars);

  return (
    <ParticipantsContainer>
      {map(processedParticipants, (participant) => (
        <Participant
          conversationId={conversationId}
          participant={participant}
          setNewParticipantDetails={setNewParticipantDetails}
          key={get(participant, 'id')}
        />
      ))}
    </ParticipantsContainer>
  );
};

function getProcessedParticipants(participants, t, avatars) {
  let unknownParticipantsCount = 0;
  const isThereMultipleUnkowns = get(countBy(participants, shouldDisplayNameBeUnknown), 'true') > 1;
  const getUnkownName = () => t(CALL_STRINGS.UNKNOWN) + (isThereMultipleUnkowns ? ` ${++unknownParticipantsCount}` : '');
  const getDisplayName = (participant) => shouldDisplayNameBeUnknown(participant) ? getUnkownName() : (participant.displayName || participant.phoneNumber);
  const sortedParticipants = sortBy(participants, 'role');
  return map(sortedParticipants, (participant) => ({
    ...participant, displayName: getDisplayName(participant),
    participantAvatar: avatars?.[participant.aadId]
  }));
}

function isEmpty(guid) { return !guid || guid === EMPTY_GUID; }
function notLongestMonolouge({ id }) { return id !== 'longest_customer_monologue'; }
function shouldDisplayNameBeUnknown({ crmId, role, displayName, phoneNumber }) { return role === PARTICIPANTS_ROLES.customer && !displayName && !phoneNumber && isEmpty(crmId); }
