import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import first from 'lodash/first';
import slice from 'lodash/slice';
import { IconButton } from '@fluentui/react';
import { noop } from 'lodash';
import { Label, ResultContent } from './StyledResult';
import { useToggler } from '../../../../../../hooks/use-toggler';
import { CRM_LOOKUP_TYPES_TO_ENTITY_TYPE, entities } from '../../../../../../constants/crm-entities';
import { ResultContainer, ResultTitle, StyledFontIcon, ResultSecondaryText, TitleAndSecondayContainer } from '../../StyledCrmRecordLookup';
import { Tooltip } from '../../../../../../components/Tooltip';

export default ({ onResultClick, data }) => {
  const [isExpanded, toggleExpansion] = useToggler();
  const { type, attributes, primaryName, secondaryText, disabled, disabledTooltipContent } = data;
  const crmEntity = get(entities, CRM_LOOKUP_TYPES_TO_ENTITY_TYPE[type]);
  const IconComponent = get(crmEntity, 'IconComponent');
  const fontIconCode = get(crmEntity, 'fontIconCode');
  const onExpandClicked = (event) => {
    event.stopPropagation();
    toggleExpansion();
  };

  return (
    <ResultContainer disabled={disabled} onClick={disabled ? noop : () => onResultClick(data)}>
      {IconComponent && <IconComponent />}
      {fontIconCode && <StyledFontIcon iconFontFamily="CRMMDL2" iconCode={fontIconCode} />}
      <ResultContent>
        <Tooltip content={disabled && disabledTooltipContent}>
          <TitleAndSecondayContainer>
            <ResultTitle>{primaryName}</ResultTitle>
            {secondaryText && <ResultSecondaryText>{secondaryText}</ResultSecondaryText>}
          </TitleAndSecondayContainer>
        </Tooltip>
        {
          !isEmpty(attributes) && <Label>{first(attributes)}</Label>
        }
        {
          isExpanded && (
            map(slice(attributes, 1), (value) => <Label key={value}>{value}</Label>)
          )
        }
      </ResultContent>
      {
        size(attributes) > 1 && (
          <IconButton iconProps={{ iconName: isExpanded ? 'ChevronUp' : 'ChevronDown' }} onClick={onExpandClicked} />
        )
      }
    </ResultContainer>
  );
};
