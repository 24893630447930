import styled from 'styled-components';
import { Icon, Pivot } from '@fluentui/react';
import { rtl } from '../../../../utils/styles/rtl';
import { FlexRowCentered, StyledText } from '../../../../utils/styles/StyledUtils';
import { StyledNoDataError } from '../../../../components/NoDataError';
import { deviceSizes } from '../../../../utils/styles/device-sizes';

const RESET_SEGMENT_BAR_HEIGHT = '36px';
const PIVOT_BAR_HEIGHT = '44px';

export const Container = styled.div`
  position: relative;
  height: 100%;

  & > div {
    height: 100%;
  }
`;

export const TabContainer = styled.div`
  height: 100%;

  ${StyledNoDataError.NoDataErrorContainer} {
    padding: 0px 20px;
  }
`;

export const TabContent = styled.div`
  height: 100%;
  overflow: auto;
  transition: height 0.2s;
  background-color: ${({ theme }) => theme.colors.White};

  ${({ isSegmentOpen }) => isSegmentOpen && `
    height: calc(100% - ${RESET_SEGMENT_BAR_HEIGHT});
  `}
`;

export const ResetSegmentBar = styled(FlexRowCentered)`
  background-color: ${({ theme }) => theme.colors.DYNPrimary};
  height: 0;
  opacity: 0;
  color: ${({ theme }) => theme.colors.White};
  padding: 0 15px;
  overflow: hidden;
  transition: height 0.2s, opacity 0.2s;
  visibility: hidden;

  ${({ open }) => open && `
    height: ${RESET_SEGMENT_BAR_HEIGHT};
    opacity: 1;
    visibility: visible;
  `}
`;

export const ResetSegmentIcon = styled(Icon).attrs({
  iconName: 'Filter',
})`
  ${rtl`padding-right: 10px;`}
`;

export const ResetSegmentTitle = styled(StyledText)`
  color: ${({ theme }) => theme.colors.White};
`;

export const ResetSegmentButton = styled.button`
  color: ${({ theme }) => theme.colors.White};
  background-color: ${({ theme }) => theme.colors.DYNPrimary};
  ${rtl`margin-left: auto;`}
  ${rtl`padding-left: 5px;`}
  font-weight: 600;
  cursor: pointer;
`;

export const tooltipStyles = {
  root: {
    margin: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
};

export const PivotContainer = styled(Pivot)`
  .ms-Pivot {
    ${deviceSizes.bigMobile`
      height: auto;
      flex-wrap: wrap;
    `}
  }
`;

export const segmentTitleTooltipStyles = {
  root: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
};

export const pivotStyles = ({ theme }) => ({
  root: {
    height: PIVOT_BAR_HEIGHT,
    backgroundColor: theme.palette.neutralLighterAlt,
    marginRight: 40,
    display: 'flex',
  },
  itemContainer: {
    height: `calc(100% - ${PIVOT_BAR_HEIGHT})`,
    border: `1px solid ${theme.palette.neutralLight}`,
    overflow: 'auto',
    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.065)',
    borderRadius: 2,
    selectors: {
      '> div': {
        height: '100%',
      }
    },
  },
  link: {
    minWidth: 100,
    marginRight: 0,
  },
  linkIsSelected: {
    minWidth: 100,
    marginRight: 0,
  }
});
