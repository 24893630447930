// @flow
import React from 'react';
import { DetailsListLayoutMode, DetailsRow, SelectionMode, ShimmeredDetailsList, DetailsHeader } from '@fluentui/react';
import { DefaultCell, RowWrapper, TableContainer, HeaderColumnContainer } from './StyledTable';
import { Tooltip } from '../Tooltip';

const EMPTY_ITEMS = [];

const renderCustomHeader = (props) => (
  <DetailsHeader
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
    onRenderColumnHeaderTooltip={(tooltipHostProps) => {
      const { tooltip, onTooltipRender: TooltipComponent, headerDisabled, ariaLabel } = tooltipHostProps.column;
      return (
        <HeaderColumnContainer disabled={headerDisabled} aria-label={ariaLabel}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Tooltip {...tooltipHostProps} content={TooltipComponent ? <TooltipComponent /> : tooltip} />
        </HeaderColumnContainer>
      );
    }}
  />
);

export const Table = ({
  items,
  columns,
  onRowClick,
  isLoading = false,
  initialFocusedIndex,
  isHeaderVisible = true,
  selectionMode = SelectionMode.none,
  selection,
  selectionPreservedOnEmptyClick,
  checkboxVisibility,
  ariaLabel
}) => (
  <TableContainer aria-label={ariaLabel}>
    <ShimmeredDetailsList
      items={items || EMPTY_ITEMS}
      columns={columns}
      onRenderDetailsHeader={renderCustomHeader}
      enableShimmer={isLoading}
      selectionMode={selectionMode}
      layoutMode={DetailsListLayoutMode.justified}
      onRenderItemColumn={renderItemColumn}
      onRenderRow={(props) => renderRow(props, onRowClick, initialFocusedIndex)}
      onItemInvoked={onRowClick}
      initialFocusedIndex={initialFocusedIndex}
      isHeaderVisible={isHeaderVisible}
      selection={selection}
      selectionPreservedOnEmptyClick={selectionPreservedOnEmptyClick}
      checkboxVisibility={checkboxVisibility}
    />
  </TableContainer>
);

function renderRow(props, onRowClick, focusedIndex) {
  const { itemIndex } = props;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <RowWrapper isFocused={focusedIndex === itemIndex}><DetailsRow {...props} onClick={() => onRowClick && onRowClick(props.item)} /></RowWrapper>;
}

function getData(row, column) {
  return column.formatter ? column.formatter({ data: row[column.fieldName], row }) : row[column.fieldName];
}

function getDefaultCell({ row, column }) {
  return <DefaultCell>{getData(row, column)}</DefaultCell>;
}

function renderItemColumn(row, index, column) {
  const itemFieldData = getData(row, column);
  const CellComponent = column.CellComponent || getDefaultCell;
  return <CellComponent data={itemFieldData} row={row} column={column} />;
}
