import React, { useEffect } from 'react';
import { getContainerList } from 'network/data-services/settings';
import useLocale from 'hooks/use-locale';
import upperFirst from 'lodash/upperFirst';
import { DataConnectionFields, FEATURES, STORAGE_TYPE, useFetch } from 'ci-common-ui';
import { get } from 'lodash';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { Link } from '../../Link/Link';
import HostedOrg from '../HostedOrg/HostedOrg';
import AddOrg from '../HostedOrg/AddOrg';
import { Section, SectionTitle, INPUT_WIDTH, INPUT_MIN_WIDTH } from '../../Modal/StyledModal';
import { componentLoadingTimeService } from '../../../services/component-loading-time-service/component-loading-time-service';
import { TRACK_IDS } from '../../../constants/tracking';
import { isFeatureEnabled } from '../../../utils/app-context/feature-flighting';

const LEARN_HOW_TO_CONNECT = 'https://go.microsoft.com/fwlink/?linkid=2047864';

export const OrgWrapper = ({ isUnprovisioned }) => (
  <Section>
    {isUnprovisioned ? <AddOrg /> : <HostedOrg />}
  </Section>
);

const DataSource = ({ isLoadingSettingsData, initialValues }) => {
  const { t, tAbs } = useLocale('pages.settings.data_source.connection_string');
  const clusterUri = get(initialValues, 'org.resolvedOrgDetails.clusterUri');
  const { data: containerList, fetchData: fetchContainerList, isLoading: isLoadingContainerList, error: containerListError } = useFetch((body) => getContainerList(body, clusterUri));
  const isLoading = isLoadingSettingsData || isLoadingContainerList;
  const isStorageTypeDVOnly = initialValues?.storageType === STORAGE_TYPE.DataverseOnly;
  const shouldUseStorageName = isFeatureEnabled(FEATURES.USE_STORAGE_NAME_ALWAYS) || isStorageTypeDVOnly;
  const CONFIGURE_SERVICE_PRINCIPAL_LINK = 'https://go.microsoft.com/fwlink/?linkid=2255900&clcid=0x409';

  useEffect(() => {
    if (!isLoading) { // both settings & onboarding modals renders this component
      componentLoadingTimeService.end(TRACK_IDS.SOURCES.SETTINGS_MODAL);
      componentLoadingTimeService.end(TRACK_IDS.SOURCES.ONBOARDING_MODAL);
    }
  }, [isLoading]);

  return (
    <Section>
      <SectionTitle>
        {upperFirst(t('title'))}
        <Link href={LEARN_HOW_TO_CONNECT} target="_blank">{t('learn_how_to_connect')}</Link>
      </SectionTitle>
      <MessageBar messageBarType={MessageBarType.info}>
          {t('storage_card_configure_storage_name_warning_text')} <Link href={CONFIGURE_SERVICE_PRINCIPAL_LINK} target="_blank">{tAbs('common.learn_more')}</Link>
      </MessageBar>
      <DataConnectionFields
        initialValues={initialValues}
        containerList={containerList}
        fetchContainerList={fetchContainerList}
        isLoading={isLoading}
        containerListError={containerListError}
        connectionStringLabel={t('label')}
        containerLabel={t('container_name')}
        connectionStringFieldName="dataSource.connectionString"
        containerFieldName="dataSource.containerName"
        errorMessages={{ required: tAbs('common.required'), containerList: t('error'), invalidConnectionString: tAbs('errors.validation.connection_string') }}
        width={INPUT_WIDTH}
        minWidth={INPUT_MIN_WIDTH}
        shouldUseStorageName={shouldUseStorageName}
        storageNameLabel={t('storage_name')}
        storageNameFieldName="dataSource.storageName"
      />
    </Section>

  );
};

export default DataSource;
