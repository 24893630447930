import {
  CrmProdRegion,
  SciRegion,
} from '../regions-constants';

export const CrmRegionMappings: Record<string, SciRegion> = {
  localhost: SciRegion.local,
  [SciRegion.dev2]: SciRegion.dev2,
  [SciRegion.dev3]: SciRegion.dev3,
  [SciRegion.dev4]: SciRegion.dev4,
  [SciRegion.dev7]: SciRegion.dev7,
  [SciRegion.dev8]: SciRegion.dev8,
  [SciRegion.dev9]: SciRegion.dev9,
  [SciRegion.dev11]: SciRegion.dev11,
  [CrmProdRegion.crm]: SciRegion.nam,
  [CrmProdRegion.crm2]: SciRegion.ssam,
  [CrmProdRegion.crm3]: SciRegion.ccan,
  [CrmProdRegion.crm4]: SciRegion.weur,
  [CrmProdRegion.crm5]: SciRegion.seapj,
  [CrmProdRegion.crm6]: SciRegion.eoce,
  [CrmProdRegion.crm7]: SciRegion.ejpn,
  [CrmProdRegion.crm8]: SciRegion.cind,
  [CrmProdRegion.crm10]: SciRegion.tip1,
  [CrmProdRegion.crm11]: SciRegion.gbr,
  [CrmProdRegion.crm12]: SciRegion.cfra,
  [CrmProdRegion.crm14]: SciRegion.nzaf,
  [CrmProdRegion.crm15]: SciRegion.nuae,
  [CrmProdRegion.crm16]: SciRegion.ger,
  [CrmProdRegion.crm17]: SciRegion.swiss,
  [CrmProdRegion.crm19]: SciRegion.norway,
  [CrmProdRegion.crm21]: SciRegion.korea,
  [CrmProdRegion.microsoftsales]: SciRegion.nam2,
  [CrmProdRegion.msxfre]: SciRegion.nam2,
  [CrmProdRegion.msxtest]: SciRegion.nam2,
  [CrmProdRegion.cinam2e2e]: SciRegion.nam2,
  [CrmProdRegion.d365salesxr]: SciRegion.nam2,
};

export const getRegionByDynamicsOrgUrl = (orgUrl: string): SciRegion => {
  const domainMatch =
    /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/gim.exec(orgUrl);
  const orgUrlWithoutHttps = domainMatch?.[1] || '';

  if (CrmRegionMappings[orgUrlWithoutHttps]) {
    return CrmRegionMappings[orgUrlWithoutHttps];
  }

  const crmNumber = extractCrmNumber(orgUrlWithoutHttps);
  if (crmNumber === CrmProdRegion.crm10) {
    return extractCRMDevEnv(orgUrl, crmNumber);
  }
  return CrmRegionMappings[crmNumber] || SciRegion.nam;
};

const extractCrmNumber = (orgUrl: string) => {
  const crmNumberMatch = orgUrl.match(/(\.|^)(crm\d*).dynamics.com/);
  const crmNumber = crmNumberMatch ? crmNumberMatch[2] : '';
  return crmNumber;
};

const extractCRMDevEnv = (orgUrl: string, crmNumber: string) => {
  const crmDevNumberMatch = orgUrl.match(/.*(dev\d*).*\.dynamics\.com/);
  const crmDevNumber = crmDevNumberMatch
    ? crmDevNumberMatch[1].toLocaleLowerCase()
    : '';
  return CrmRegionMappings[crmDevNumber] || CrmRegionMappings[crmNumber];
};
