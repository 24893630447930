import styled from 'styled-components';
import { colors, FlexRow, rtl } from 'ci-common-ui';
import { Icon, TextField } from '@fluentui/react';

export const FormContainer = styled(FlexRow)`
  margin-top: 16px;
  align-items: center;
  &>*:not(:last-child) {
    ${rtl`margin-right: 5px;`}
  }
`;

export const LocaleIcon = styled(Icon)`
  ${rtl`margin-right: 3px;`}

`;

export const StyledTextField = styled(TextField)`
  width: 258px;
  ${rtl`margin-right: 4px;`}
`;

export const DropdownTitle = styled(FlexRow)`
  align-items: center;
`;

export const Mask = styled.div`
  color: ${colors.Gray130};
  ${rtl`margin-right: 3px;`}
`;

export const LanguageValue = styled.div`
  color: ${colors.Black};
  font-weight: 600;
`;

export const dropdownStyles = {
  title: {
    width: '280px',
  },
};
