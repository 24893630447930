import React, { useState } from 'react';
import { useInfra } from '../../../../../../providers/InfraProvider';
import { Disclaimer, Container, LearnMoreLink } from './StyledInternalMeetingHighlightsDisclaimer';
import { CALL_STRINGS } from '../../../../strings';

export function InternalMeetingHighlightsDisclaimer() {
  const { t, trackEvent, highlightsInternalMeetingLearnMoreLink } = useInfra();
  const [isDismissed, setIsDismissed] = useState(false);

  return (
    !isDismissed && (
      <Container>
        <Disclaimer
          onDismiss={() => {
            setIsDismissed(true);
          }}
          data-testid="internal-meeting-disclaimer"
        >
          {t(CALL_STRINGS.EXEC_SUMMARY_SUGGESTIONS_INTERNAL_MEETING_DISCLAIMER)}
          <LearnMoreLink
            href={highlightsInternalMeetingLearnMoreLink}
            target="_blank"
            trackEvent={trackEvent}
          >
            {t(CALL_STRINGS.LEARN_MORE)}
          </LearnMoreLink>
        </Disclaimer>
      </Container>
    )
  );
}
