import { useEffect, useRef } from 'react';

const useIsUnmountRef = () => {
  const unMounting = useRef(false);
  // eslint-disable-next-line no-return-assign
  useEffect(() => () => unMounting.current = true, []);
  return unMounting;
};

export default useIsUnmountRef;
