import React from 'react';
import { IconButton, Spinner } from '@fluentui/react';
import useLocale from 'hooks/use-locale';
import { Form, useFetchNow } from 'ci-common-ui';
import { getSettingsData, saveSettingsData } from 'network/data-services/settings';
import { appContext, getIsAdminUser } from 'utils/app-context/app-context';
import { trackEvent, trackError, eventAction } from '../../../../services/telemetry-service/telemetry-service';
import SettingsPrompt from '../SettingsPrompt/SettingsPrompt';

import {
  TabHeader, FormStyled, FormAction, FormError, TabBody, PrimaryButtonStyled, DefaultButtonStyled, TabTitleText
} from '../../StyledSettingsModal';
import { TRACK_IDS } from '../../../../constants/tracking';

const SettingsForm = ({
  title, tab, closeModal, setIsRefreshNeeded, render: RenderComponent, getInitialValuesRequest, saveRequest
}) => {
  const { t, tDefault } = useLocale('common');
  const { orgId, orgUrl, clusterRegion } = appContext;
  const isAdmin = getIsAdminUser();

  const getInitialValues = getInitialValuesRequest || getSettingsData;
  const save = saveRequest || saveSettingsData;

  const { data: settingsData, fetchData: fetchSettingsData, isLoading: isLoadingSettingsData } = useFetchNow((forceFetch) => getInitialValues(orgId, forceFetch));

  const onSubmit = async (values) => {
    if (setIsRefreshNeeded) {
      setIsRefreshNeeded(true);
    }
    await save(tab, orgId, orgUrl, isAdmin, values, clusterRegion);
    trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.COMMON.SUBMIT_BUTTON, message: tab });
    fetchSettingsData(true);
  };

  const onSubmitError = async (error) => {
    trackError({ actionOn: TRACK_IDS.COMMON.SUBMIT_BUTTON, message: error });
  };

  return (
    <Form
      onSubmit={onSubmit}
      onSubmitError={onSubmitError}
      initialValues={settingsData}
      autocomplete="off"
      render={({
        form, handleSubmit, hasValidationErrors, submitting, dirty, submitError, values,
      }) => (
        <FormStyled onSubmit={handleSubmit} noValidate>
          <TabHeader>
            <TabTitleText>{title}</TabTitleText>
            <FormAction>
              {submitting && <Spinner />}
              {submitError && <FormError>{submitError}</FormError>}
              <DefaultButtonStyled text={t('clear')} disabled={!dirty || submitting || isLoadingSettingsData} onClick={form.reset} />
              <PrimaryButtonStyled type="submit" text={t('save')} disabled={!dirty || hasValidationErrors || submitting || isLoadingSettingsData} />
              <IconButton iconProps={{ iconName: 'Cancel' }} ariaLabel={`${tDefault('close')} ${tDefault('settings')}`} onClick={closeModal} />
            </FormAction>
          </TabHeader>
          <TabBody>
            <RenderComponent
              settingsData={settingsData}
              isLoadingSettingsData={isLoadingSettingsData}
              values={values}
            />
          </TabBody>

          <SettingsPrompt when={dirty} />

        </FormStyled>
      )}
    />
  );
};

export default SettingsForm;
