import React from 'react';
import { browserUtils } from 'ci-common-ui';
import noop from 'lodash/noop';
import { Link } from '../Link/Link';

const { isIE } = browserUtils;

const JSON_FILE_NAME = 'error_log.json';

export const parseJson = (json) => {
  if (!json) { return json; }
  try {
    return JSON.stringify(JSON.parse(json), null, 2);
  } catch (e) {
    return json;
  }
};

const FILE_TYPE = 'data:text/json;charset=utf-8,';
const createLink = (json) => json && `${FILE_TYPE}${encodeURIComponent(parseJson(json))}`;

// Only for IE
const download = (item) => {
  const { Metadata } = item;
  // eslint-disable-next-line no-undef
  const blob = new Blob([parseJson(Metadata)], { type: FILE_TYPE });
  window.navigator.msSaveOrOpenBlob(blob, JSON_FILE_NAME);
};

const DownloadJson = ({ json, children }) => {
  const metadataLink = createLink(json);

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      href={isIE ? undefined : metadataLink}
      download={isIE ? undefined : JSON_FILE_NAME}
      onClick={isIE ? () => download(json) : noop}
    >
      {children}
    </Link>
  );
};

export default DownloadJson;
