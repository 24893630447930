import { SP_DEV_BASE, SP_PROD_BASE, SP_TEST_BASE, SalesCopilotAppId, SpRegions } from "../regions-constants";

export const SP_REGIONS_TO_APPS_IDS: Record<SpRegions, SalesCopilotAppId> = {
  [SpRegions.local]: SalesCopilotAppId.local,
  [SpRegions.dev]: SalesCopilotAppId.dev,
  [SpRegions.ildev1]: SalesCopilotAppId.ildev1,
  [SpRegions.ildev2]: SalesCopilotAppId.ildev2,
  [SpRegions.ildev3]: SalesCopilotAppId.ildev3,
  [SpRegions.ildev4]: SalesCopilotAppId.ildev4,
  [SpRegions.daily]: SalesCopilotAppId.daily,
  [SpRegions.fre]: SalesCopilotAppId.fre,
  [SpRegions.partnerfre]: SalesCopilotAppId.partnerfre,
  [SpRegions.research]: SalesCopilotAppId.research,
  [SpRegions.tip]: SalesCopilotAppId.tip,
  [SpRegions.sg]: SalesCopilotAppId.sg,
  [SpRegions.prod]: SalesCopilotAppId.prod,
};

export const SP_APPS_IDS_TRAFFIC_MANAGER_SUFFIX: Record<SalesCopilotAppId, string> = {
  [SalesCopilotAppId.local]: `https://stabledevtrafficmanager.${SP_DEV_BASE}`,
  [SalesCopilotAppId.dev]: `https://devtrafficmanager.${SP_DEV_BASE}`,
  [SalesCopilotAppId.ildev1]: `https://ildev1trafficmanager.${SP_DEV_BASE}`,
  [SalesCopilotAppId.ildev2]: `https://ildev2trafficmanager.${SP_DEV_BASE}`,
  [SalesCopilotAppId.ildev3]: `https://ildev3trafficmanager.${SP_DEV_BASE}`,
  [SalesCopilotAppId.ildev4]: `https://ildev4trafficmanager.${SP_DEV_BASE}`,
  [SalesCopilotAppId.daily]: `https://dailytrafficmanager.${SP_TEST_BASE}`,
  [SalesCopilotAppId.fre]: `https://fretrafficmanager.${SP_PROD_BASE}`,
  [SalesCopilotAppId.partnerfre]: `https://partnerfretrafficmanager.${SP_PROD_BASE}`,
  [SalesCopilotAppId.research]: `https://researchtrafficmanager.${SP_PROD_BASE}`,
  [SalesCopilotAppId.tip]: `https://tiptrafficmanager.${SP_TEST_BASE}`,
  [SalesCopilotAppId.sg]: `https://sgtrafficmanager.${SP_PROD_BASE}`,
  [SalesCopilotAppId.prod]: `https://trafficmanager.${SP_PROD_BASE}`,
};

export const getSpTrafficManagerFromAppId = (salesCopilotAppId: SalesCopilotAppId): string => SP_APPS_IDS_TRAFFIC_MANAGER_SUFFIX[salesCopilotAppId];
