import React, { useEffect, useState } from 'react';
import filter from 'lodash/filter';
import find from 'lodash/find';
import maxBy from 'lodash/maxBy';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import first from 'lodash/first';
import { INITIAL_SELLERS } from '../../pages/TrialSetupModal/components/steps/UploadCalls/initial-sellers-config';
import { Container } from './StyledUploadCallsPanel';
import SellerEditor from './components/SellerEditor/SellerEditor';
import Sidebar from './components/Sidebar/Sidebar';
import useLocale from '../../hooks/use-locale';

export default ({
  settings, handleSettings
}) => {
  const { t } = useLocale('components.upload_calls_form');
  const [selectedSeller, setSelectedSeller] = useState();

  useEffect(() => {
    if (isEmpty(settings.sellers)) {
      handleSettings({
        sellers: map(INITIAL_SELLERS, ({ nameTranslationKey, key, defaultCallLanguage, files }) => ({
          key,
          files,
          defaultCallLanguage,
          name: t(nameTranslationKey),
        })),
      });
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(settings) && !isEmpty(settings.sellers) && isEmpty(selectedSeller)) {
      setSelectedSeller(first(settings.sellers));
    }
  }, [settings]);

  const onAddSeller = () => {
    const newSeller = {
      key: maxBy(settings.sellers, 'key').key + 1,
      name: t('new_seller'),
      files: []
    };
    handleSettings({
      sellers: [...settings.sellers, newSeller],
    });
  };

  const onDeleteSeller = () => {
    handleSettings({
      sellers: settings.sellers.filter(({ key }) => key !== selectedSeller.key),
    });
    setSelectedSeller(null);
  };

  const updateSeller = (updatedSellerFields) => {
    const sellers = map(
      settings.sellers,
      (seller) => seller.key === selectedSeller.key ? ({
        ...seller,
        ...updatedSellerFields
      }) : seller
    );

    handleSettings({
      sellers
    });
    setSelectedSeller(find(sellers, ({ key }) => key === selectedSeller.key));
  };

  const onSellerClick = (_, seller) => setSelectedSeller(seller);
  const onFavoriteClicked = () => {
    updateSeller({ isFavorite: !selectedSeller.isFavorite });
  };

  const onSellerNameChanged = (newName) => {
    updateSeller({ name: newName });
  };

  const onSellerLanguageChanged = (newLanguage) => {
    updateSeller({ defaultCallLanguage: newLanguage });
  };

  const onFilesAdded = (files) => {
    updateSeller({ files: [...selectedSeller.files, ...files] });
  };

  const onFileDeleteClicked = (deletedFile) => {
    updateSeller({ files: filter(selectedSeller.files, ({ name }) => name !== deletedFile.name) });
  };

  const onEditCallName = (currentFile, newName) => {
    updateSeller({
      files: map(selectedSeller.files, (file) => ({
        ...file,
        callName: currentFile.name === file.name ? newName : file.callName,
      }))
    });
  };

  const onChangeFileLanguage = (modifiedFile, newLanguage) => {
    updateSeller({
      files:
        map(
          selectedSeller.files,
          (file) => file.name === modifiedFile.name ? {
            ...file, language: newLanguage
          } : file
        )
    });
  };

  if (!selectedSeller) {
    return null;
  }

  return (
    <Container>
      <Sidebar
        sellers={settings.sellers}
        onSellerClicked={onSellerClick}
        selectedSeller={selectedSeller}
        onAddSeller={onAddSeller}
      />
      <SellerEditor
        selectedSeller={selectedSeller}
        onFilesAdded={onFilesAdded}
        onSellerNameChanged={onSellerNameChanged}
        onSellerLanguageChanged={onSellerLanguageChanged}
        onFavoriteClicked={onFavoriteClicked}
        onDeleteSeller={onDeleteSeller}
        onFileDeleteClicked={onFileDeleteClicked}
        onEditCallName={onEditCallName}
        onChangeFileLanguage={onChangeFileLanguage}
      />
    </Container>
  );
};
