import styled from 'styled-components';
import { FontSizes } from '@uifabric/fluent-theme';
import { Icon, Image } from '@fluentui/react';
import { rtl, colors, FlexColumn, FlexRow, StyledText, FlexColumnCentered, deviceSizes } from 'ci-common-ui';

export const BannerContainer = styled.div`
  position: relative;
  height: 122px;
  background-color: ${colors.White};
  border-bottom: 1px solid ${colors.Gray40};
`;

export const StyledBannerImage = styled(Image).attrs({
  alt: 'banner image',
  height: '121px'
})`
  display: inline;
  ${rtl`margin-right: 60px;`}

  ${deviceSizes.tablet` 
     display: none;
  `}
`;

export const BannerInnerContainer = styled(FlexRow)`
  width: 100%;
  justify-content: flex-start;
`;

export const ExplanationContainer = styled(FlexColumn)`
  ${rtl`margin-right: 100px;`}
  justify-content: center;
  align-items: flex-start;
  max-width: 45%;
`;

export const Title = styled(StyledText)`
  font-size: ${FontSizes.size16};
  font-weight: 600;
`;

export const Subtitle = styled(StyledText)`
  font-size: ${FontSizes.size14};
  color: ${colors.Gray130};
  white-space: normal;
`;

export const BannerCancelIcon = styled(Icon).attrs({
  iconName: 'cancel'
})`
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 16px;
  cursor: pointer;
`;

export const CallToActionContainer = styled(FlexColumnCentered)`
  flex: 1;
  ${rtl`margin-right: 60px;`}
`;

export const GetStartedCallToAction = styled(StyledText)`
  color: ${colors.Gray130};
  margin-top: 16px;
`;

const PROGRESS_BAR_HEIGHT = '4px';
export const progressIndicatorStyles = {
  root: {
    width: '100%',
  },
  itemName: {
    fontSize: 14,
    fontWeight: 600,
  },
  itemProgress: {
    height: PROGRESS_BAR_HEIGHT
  },
  progressBar: {
    height: PROGRESS_BAR_HEIGHT
  },
  progressTrack: {
    height: PROGRESS_BAR_HEIGHT
  }
};
