import styled from 'styled-components';
import { rtl, colors, FlexRow } from 'ci-common-ui';
import { FontSizes } from '@uifabric/fluent-theme';
import { Icon } from '@fluentui/react';

export const Section = styled.div` 
   margin-bottom: 40px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: ${FontSizes.size20};
  margin: 10px 0;
`;

export const Keywords = styled(FlexRow)`
  flex-wrap: wrap;
`;

export const Keyword = styled.div`
  background-color: ${colors.Gray200};
  height: 32px;
  border-radius: 2px;
  ${rtl`margin-left`}: 8px;
  margin-bottom: 10px;
  padding: 0 8px;
  align-items: center;
  line-height: 32px;
`;

export const CheckIcon = styled(Icon).attrs({
  iconName: 'CheckMark'
})`
  ${rtl`margin-right`}: 10px;
`;
