import { loadInsightDetails, loadInsightTable } from '../rest-services/insight-rest-service';
import { transformCustomerSentimentInsightCharts, transformInsightData, transformKeywordsInsightsCharts } from '../filters/insight-filters';
import { getTopPerformers } from './seller-service';

const INSIGHT_ID_TO_FILTER = {
  customerSentiment: transformCustomerSentimentInsightCharts,
  brands: transformKeywordsInsightsCharts,
  competitors: transformKeywordsInsightsCharts,
  topSellers: transformKeywordsInsightsCharts,
  trackedKeywords: transformKeywordsInsightsCharts,
};

export const getInsightDetails = async (params, insightId) => {
  const data = await loadInsightDetails(params);
  return INSIGHT_ID_TO_FILTER[insightId](data.data);
};

export const getInsightTable = async (params, transformFunc) => {
  const { data } = await loadInsightTable(params);
  const topPerformers = await getTopPerformers(params.orgId);

  return transformInsightData(data, topPerformers, transformFunc);
};
