import React from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import { Pivot, PivotItem } from '@fluentui/react';
import useLocale from 'hooks/use-locale';
import useEmptyState from 'utils/empty-state/use-empty-state';
import isEmpty from 'lodash/isEmpty';
import { useAppNavigation } from 'hooks/use-app-navigation';
import { routes } from 'pages/routing';
import { trackEvent, eventAction } from '../../../../services/telemetry-service/telemetry-service';
import { CardsRow } from '../../../StyledPages';
import PageSection from '../../../components/PageSection/PageSection';
import { SectionLoaders } from '../../../components/PageSection/SectionLoaders';
import { dataViewClick, scrollViewNames } from '../../../../constants/selectors';
import { createListeningAndTalkingConfig, createMonologueConfig, createPauseSpeakingConfig, createSwitchRateConfig, createTalkingSpeedConfig } from './chart-configs';
import ChartPivotItem from '../../../components/ChartPivot/ChartPivotItem';
import CardErrorBoundary from '../../../../components/ErrorBoundry/CardErrorBoundry';
import { chartPivotStyles } from '../../../components/ChartPivot/StyledChartPivot';
import { TRACK_IDS } from '../../../../constants/tracking';
import { joinTrackDelimiter } from '../../../../utils/string/string';
import { StyledSectionCard } from './StyledConversationStyle';
import { Link } from '../../../../components/Link/Link';

const LOCALIZATION_PREFIX = 'pages.team.sections.conversation_style';

const ConversationStyle = ({ isLoading, data, error }) => {
  const { t, tAbs, isRTL } = useLocale(LOCALIZATION_PREFIX);
  const { getEmptyStateDetails } = useEmptyState(LOCALIZATION_PREFIX);
  const { navigateTo } = useAppNavigation();

  const title = t('title');
  const subtitle = (
    <>
      {t('subtitle')}
      <Link href="https://go.microsoft.com/fwlink/?linkid=2101139" target="_blank" actionOn={joinTrackDelimiter([TRACK_IDS.COMMON.CONVERSATION_STYLE, TRACK_IDS.COMMON.LEARN_MORE])}>{tAbs('common.learn_more')}</Link>
    </>
  );

  if (isLoading) return <SectionLoaders title={title} subtitle={subtitle} count={1} />;

  const {
    talkToListenRatioByAgent,
    avgWordsPerMinuteByAgent,
    avgSwitchRateByAgent,
    avgAgentPauseBeforeSpeakingInMsByAgent,
    avgCustomerLongestMonologueInMsByAgent,
  } = data;

  const trackTabClick = (item) => trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.CONVERSATION_STYLE_TAB, message: item.props.headerText });

  const commonEmptyStateDetails = getEmptyStateDetails('cards.common', null, () => navigateTo({ path: routes.systemMonitoring.link }));

  const cards = [
    {
      trackId: joinTrackDelimiter([TRACK_IDS.COMMON.CONVERSATION_STYLE, TRACK_IDS.COMMON.TALK_TO_LISTEN_RATIO]),
      header: tAbs('common.kpi_ratio'),
      csvData: get(talkToListenRatioByAgent, 'data'),
      getChartConfig: () => createListeningAndTalkingConfig(talkToListenRatioByAgent, tAbs, isRTL),
      scrollViewName: scrollViewNames.talkToListenRatio,
      emptyStateDetails: !isLoading && isEmpty(get(talkToListenRatioByAgent, 'data')) && commonEmptyStateDetails,
      hasError: get(error, 'talkToListenRatioByAgent')
    }, {
      trackId: joinTrackDelimiter([TRACK_IDS.COMMON.CONVERSATION_STYLE, TRACK_IDS.COMMON.TALKING_SPEED]),
      header: tAbs('common.kpi_speed'),
      csvData: get(avgWordsPerMinuteByAgent, 'data'),
      getChartConfig: () => createTalkingSpeedConfig(avgWordsPerMinuteByAgent, tAbs, isRTL),
      emptyStateDetails: !isLoading && isEmpty(get(avgWordsPerMinuteByAgent, 'data')) && commonEmptyStateDetails,
      hasError: get(error, 'avgWordsPerMinuteByAgent')
    }, {
      trackId: joinTrackDelimiter([TRACK_IDS.COMMON.CONVERSATION_STYLE, TRACK_IDS.COMMON.SWITCH_RATE]),
      header: tAbs('common.kpi_switches'),
      csvData: get(avgSwitchRateByAgent, 'data'),
      getChartConfig: () => createSwitchRateConfig(avgSwitchRateByAgent, tAbs, isRTL),
      scrollViewName: scrollViewNames.switchRate,
      emptyStateDetails: !isLoading && isEmpty(get(avgSwitchRateByAgent, 'data')) && commonEmptyStateDetails,
      hasError: get(error, 'avgSwitchRateByAgent')
    }, {
      trackId: joinTrackDelimiter([TRACK_IDS.COMMON.CONVERSATION_STYLE, TRACK_IDS.COMMON.PAUSE_BEFORE_SPEAKING]),
      header: tAbs('common.kpi_pause'),
      csvData: get(avgAgentPauseBeforeSpeakingInMsByAgent, 'data'),
      getChartConfig: () => createPauseSpeakingConfig(avgAgentPauseBeforeSpeakingInMsByAgent, tAbs, isRTL),
      emptyStateDetails: !isLoading && isEmpty(get(avgAgentPauseBeforeSpeakingInMsByAgent, 'data')) && commonEmptyStateDetails,
      hasError: get(error, 'avgAgentPauseBeforeSpeakingInMsByAgent')
    }, {
      trackId: joinTrackDelimiter([TRACK_IDS.COMMON.CONVERSATION_STYLE, TRACK_IDS.COMMON.LONGEST_CUSTOMER_MONOLOUGE]),
      header: tAbs('common.kpi_monologue'),
      csvData: get(avgCustomerLongestMonologueInMsByAgent, 'data'),
      getChartConfig: () => createMonologueConfig(avgCustomerLongestMonologueInMsByAgent, tAbs, isRTL),
      emptyStateDetails: !isLoading && isEmpty(get(avgCustomerLongestMonologueInMsByAgent, 'data')) && commonEmptyStateDetails,
      hasError: get(error, 'avgCustomerLongestMonologueInMsByAgent')
    }
  ];

  return (
    <PageSection title={title} subtitle={subtitle} isLoading={isLoading}>
      <CardsRow>
        <StyledSectionCard isLoading={isLoading} scrollViewName={scrollViewNames.conversationStyle}>
          <Pivot onLinkClick={trackTabClick} styles={chartPivotStyles}>
            {
              map(cards, ({ trackId, header, csvData, getChartConfig, scrollViewName, emptyStateDetails, hasError }) => (
                <PivotItem key={header} headerText={header} headerButtonProps={{ [dataViewClick]: scrollViewName }}>
                  <CardErrorBoundary cardName={header}>
                    <ChartPivotItem
                      trackId={trackId}
                      header={header}
                      csvData={csvData}
                      cardName={header}
                      getChartConfig={getChartConfig}
                      scrollViewName={scrollViewName}
                      emptyStateDetails={emptyStateDetails}
                      isErrorState={hasError}
                    />
                  </CardErrorBoundary>
                </PivotItem>
              ))
            }
          </Pivot>
        </StyledSectionCard>
      </CardsRow>
    </PageSection>
  );
};
export default ConversationStyle;
