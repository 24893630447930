export const ARIA_ROLES = {
  ALERT: 'alert', // The alert role can be used to tell the user an element has been dynamically updated. Screen readers will instantly start reading out the updated content when the role is added. If the user is expected to close the alert, then the alertdialog role should be used instead.
  APPLICATION: 'application', // The application role indicates to assistive technologies that an element and all of its children should be treated similar to a desktop application, and no traditional HTML interpretation techniques should be used. This role should only be used to define very dynamic and desktop-like web applications.
  ARTICLE: 'article', // The article role indicates a section of a page that could easily stand on its own on a page, in a document, or on a website. It is usually set on related content items such as comments, forum posts, newspaper articles or other items grouped together on one page.
  BANNER: 'banner', // A banner role represents general and informative content frequently placed at the beginning of the page. This usually includes a logo, company name, search icon, photo related to the page, or slogan.
  BUTTON: 'button', // The button role should be used for clickable elements that trigger a response when activated by the user. Adding role="button" will make an element appear as a button control to a screen reader. This role can be used in combination with the aria-pressed attribute to create toggle buttons.
  CELL: 'cell', // The cell value of the ARIA role attribute identifies an element as being a cell in a tabular container that does not contain column or row header information. To be supported, the cell must be nested in an element with the role of row.
  CHECKBOX: 'checkbox', // The checkbox role is used for checkable interactive controls. Elements containing role="checkbox" must also include the aria-checked attribute to expose the checkbox's state to assistive technology.
  COMMENT: 'comment', // The comment landmark role semantically denotes a comment/reaction to some content on the page, or to a previous comment.
  COMPLEMENTARY: 'complementary', // The complementary landmark role is used to designate a supporting section that relates to the main content, yet can stand alone when separated. These sections are frequently presented as sidebars or call-out boxes. If possible, use the HTML <aside> element instead.
  CONTENT_INFO: 'contentinfo', // The contentinfo landmark role is used to identify information repeated at the end of every page of a website, including copyright information, navigation links, and privacy statements. This section is commonly called a footer.
  DIALOG: 'dialog', // The dialog role is used to mark up an HTML based application dialog or window that separates content or UI from the rest of the web application or page. Dialogs are generally placed on top of the rest of the page content using an overlay. Dialogs can be either non-modal (it's still possible to interact with content outside of the dialog) or modal (only the content in the dialog can be interacted with).
  DOCUMENT: 'document', // Generally used in complex composite widgets or applications, the document role can inform assistive technologies to switch context to a reading mode: The document role tells assistive technologies with reading or browse modes to use the document mode to read the content contained within this element.
  FEED: 'feed', // A feed is a dynamic scrollable list of articles in which articles are added to or removed from either end of the list as the user scrolls. A feed enables screen readers to use the browse mode reading cursor to both read and scroll through a stream of rich content that may continue scrolling infinitely by loading more content as the user reads.
  FIGURE: 'figure', // The ARIA figure role can be used to identify a figure inside page content where appropriate semantics do not already exist. A figure is generally considered to be one or more images, code snippets, or other content that puts across information in a different way to a regular flow of text.
  FORM: 'form', // The form landmark role can be used to identify a group of elements on a page that provide equivalent functionality to an HTML form.
  GRID: 'grid', // The grid role is for a widget that contains one or more rows of cells. The position of each cell is significant and can be focused using keyboard input.
  GRID_CELL: 'gridcell', // The gridcell role is used to make a cell in a grid or treegrid. It is intended to mimic the functionality of the HTML td element for table-style grouping of information.
  HEADING: 'heading', // The heading role defines this element as a heading to a page or section. To give the page more structure, a level should also be provided to indicate relationships between sections.
  IMG: 'img', // The ARIA img role can be used to identify multiple elements inside page content that should be considered as a single image. These elements could be images, code snippets, text, emojis, or other content that can be combined to deliver information in a visual manner.
  LINK: 'link', // The ARIA link is used to identify an element that creates a hyperlink to a resource that is in the application or external.
  LIST: 'list', // The ARIA list role can be used to identify a list of items. It is normally used in conjunction with the listitem role, which is used to identify a list item contained inside the list.
  LIST_BOX: 'listbox', // The listbox role is used for lists from which a user may select one or more items which are static and, unlike HTML <select> elements, may contain images.
  LIST_ITEM: 'listitem', // The ARIA listitem role can be used to identify an item inside a list of items. It is normally used in conjunction with the list role, which is used to identify a list container.
  MAIN: 'main', // The main landmark role is used to indicate the primary content of a document. The main content area consists of content that is directly related to or expands upon the central topic of a document, or the central functionality of an application.
  MARK: 'mark', // The mark landmark role semantically denotes HTML elements containing text that is marked/highlighted for reference purposes. This is semantically equivalent to the HTML <mark> element. If possible, you should use this element instead.
  NAVIGATION: 'navigation', // The navigation landmark role is used to identify major groups of links used for navigating through a website or page content.
  NONE: 'none', // The navigation landmark role is used to identify major groups of links used for navigating through a website or page content.
  REGION: 'region', // The region landmark role is used to identify an area in the document that the author has identified as significant. It is used to provide a generic landmark for people to be able to navigate to easily when none of the other landmark roles are appropriate.
  ROW: 'row', // An element with role="row" is a row of cells within a tabular structure. A row contains one or more cells,  grid cells or column headers, and possibly a row header, within a grid, table or treegrid, and optionally within a rowgroup.
  ROW_GROUP: 'rowgroup', // An element with role="rowgroup" is a group of rows within a tabular structure. A rowgroup contains one or more rows of cells,  grid cells, column headers, or row headers within a grid, table or treegrid.
  SEARCH: 'search', // The search landmark role is used to identify a section of the page used to search the page, site, or collection of sites.
  STATUS: 'status', // The status role defines a live region containing advisory information for the user that is not important enough to be an alert.
  SUGGESTION: 'suggestion', // The suggestion landmark role semantically denotes a single proposed change to an editable document. This should be used on an element that wraps an element with an insertion role, and one with a deletion role.
  SWITCH: 'switch', // The ARIA switch role is functionally identical to the checkbox role, except that instead of representing "checked" and "unchecked" states, which are fairly generic in meaning, the switch role represents the states "on" and "off."
  TAB: 'tab', // The ARIA tab role indicates an interactive element inside a tablist that, when activated, displays its associated tabpanel.
  TAB_LIST: 'tablist', // The ARIA tablist role contains the tab roles.
  TABLE: 'table', // The table value of the ARIA role attribute identifies the element containing the role as having a non-interactive table structure containing data arranged in rows and columns, similar to the native <table> HTML element.
  TAB_PANEL: 'tabpanel', //
  TEXTBOX: 'textbox', // The textbox role is used to identify an element that allows the input of free-form text.
  TIMER: 'timer' // The timer role indicates to assistive technologies that an element is a numerical counter listing the amount of elapsed time from a starting point or the remaining time until an end point.
};
