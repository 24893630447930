// @flow
// eslint-disable-next-line no-restricted-imports
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import toArray from 'dayjs/plugin/toArray';

dayjs.extend(utc);
dayjs.extend(LocalizedFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(toArray);

export const monthDayFormat = 'MMM D';
export const TIME_FORMAT = 'h:mm A';

export const monthDayYearFormat = 'MMM D, YYYY';
export const inputFieldFormat = 'MMMM D, YYYY';

export const EXTENDED_FORMAT_WITH_TIME = 'MMM D, YYYY hh:mm A';
export const EXTENDED_FORMAT = 'MMM D, YYYY';

const MILISECOND_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSSS';
const TIME_ZONE_DELIMITERS = ['+', 'z', 'Z'];

// eslint-disable-next-line global-require, import/no-dynamic-require
export const importLocale = (locale) => require(`dayjs/locale/${locale}.js`);

export const getIsLocaleImported = (locale) => locale in dayjs.Ls;

export const switchLocale = (locale) => dayjs.locale(locale);

export const isTimezoneDateString = (date) => typeof date === 'string' && TIME_ZONE_DELIMITERS.some((d) => date.includes(d));

// this constructor function encapsulates the default `dayjs()` constructor since we want only 1 instance of dayjs,
export const dayJS = (date) => dayjs(date);

export const toUTC = (date) => dayjs.utc(date);

export const toLocal = (date) => dayjs(date).local();

export const dateFromServer = (date) => toLocal(toUTC(date)); // all Microsoft apis returns UTC date formats by default, even when timezone isn't supplied

export const dateToServer = (date, format = undefined) => toUTC(date).format(format); // all Microsoft apis expects UTC date formats

export const dateToTelemetry = (date) => dateToServer(date, MILISECOND_TIME_FORMAT);

export const formatDate = (date, format) => dayJS(date).format(format);

export const formatMonthDayDate = (date) => dayJS(date).format(monthDayFormat);

export const formatMonthDayYearDate = (date) => dayJS(date).format(monthDayYearFormat);

export const formatMonthDayYearDateAndTime = (date) => dayJS(date).format(EXTENDED_FORMAT_WITH_TIME);

export const formatTime = (date) => dayJS(date).format(TIME_FORMAT);

export const toJSDate = (date) => dayJS(date).toDate();

export const toStartOfTheDay = (date) => dayJS(date).hour(0).minute(0).second(0);

export const toEndOfTheDay = (date) => dayJS(date).hour(23).minute(59).second(59);

export const timeAgo = (date) => dayJS(date).fromNow();

export const toDuration = (seconds) => dayJS().startOf('day').add(seconds, 'second');

export const convertMsToHours = (ms) => dayjs.duration(ms).asHours();

export const durationToTime = (seconds) => {
  const time = toDuration(seconds);
  const format = time.hour() ? 'HH:mm:ss' : 'mm:ss';
  return time.format(format);
};

export const durationToTimeText = (seconds, hoursText = 'h', minutesText = 'm', secondsText = 's') => {
  const time = toDuration(seconds);
  const timeFormat = [];
  if (time.hour()) timeFormat.push(`H[${hoursText}]`);
  timeFormat.push(`m[${minutesText}]`);
  timeFormat.push(`s[${secondsText}]`);
  return time.format(timeFormat.join(' '));
};

export function getDateObject(dateValue, format) {
  return new Date(dayJS(dateValue).format(format));
}
