import styled from 'styled-components';

export const Form = styled.div`
  display: inline-flex;
  width: 100%;
  height: 100%;
  padding: 10px;

  [id^="id-"][id*="_null_modelFormComponent_"][id*="_modelFormComponent_"] {
    height: auto;
  }

  [id^="outerHeaderContainer_null_modelFormComponent_"][id*="_modelFormComponent_"] {
    height: auto;
  }
`;
