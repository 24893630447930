import React, { useState, useEffect } from 'react';
import { DefaultButton, PivotLinkSize, PivotLinkFormat, PivotItem } from '@fluentui/react';
import { CalendarPicker, FlexRowCentered, useToggler, usePrevious, ClickOutside } from 'ci-common-ui';
import { Card } from '@uifabric/react-cards';
import { KEY_CODE } from 'constants/keyword';
import { trackEvent, eventAction } from '../../services/telemetry-service/telemetry-service';

import { dayJS, formatFullDate, getDateObject, formatDate, urlFormat } from '../../utils/date/date';
import { StyledRangeDates, calendarButtonStyle, StyledPivot, StyledPivotContainer, pivotStyles, StyledCustomFilterContainer, StyledStartCalendarPicker, StyledCustomFilterFooter, ApplyButton } from './StyledDateTimeFilter';
import { TRACK_IDS } from '../../constants/tracking';

const ALL_TIME_FILTER = 'all';

export const DateTimeFilters = {
  TWO_DAYS: 'two_days',
  SEVEN_DAYS: 'seven_days',
  THIRTY_DAYS: 'thirty_days',
  ALL_TIME: 'all_time',
  CUSTOM: 'custom',
};

const DAY_DIFF_IND_DAYS = 1;
const WEEK_DIFF_IN_DAYS = 6;
const MONTH_DIFF_IN_DAYS = 29;

const filters = [
  {
    value: DateTimeFilters.TWO_DAYS,
    label: 'last2days',
    getStartDate: () => dayJS().subtract(DAY_DIFF_IND_DAYS, 'day')
  },
  {
    value: DateTimeFilters.SEVEN_DAYS,
    label: 'last7days',
    getStartDate: () => dayJS().subtract(WEEK_DIFF_IN_DAYS, 'day')
  },
  {
    value: DateTimeFilters.THIRTY_DAYS,
    label: 'last30days',
    getStartDate: () => dayJS().subtract(MONTH_DIFF_IN_DAYS, 'day')
  },
  {
    value: DateTimeFilters.ALL_TIME,
    label: 'allTime',
    getStartDate: () => null
  },
  {
    value: DateTimeFilters.CUSTOM,
    label: 'custom',
    getStartDate: () => dayJS().subtract(3, 'month')
  }
];

const DateTimeFilter = ({
  fullTimeRange = {},
  isLoading,
  startDate,
  endDate,
  strings,
  onDateChanged,
  startCalendarPickerProps,
  endCalendarPickerProps,
  isRtl
}) => {
  const [selectedFilter, setSelectedFilter] = useState(getSelectedFilterValue());
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [showCustomFilterPopup, toggleCustomFilterPopup] = useToggler();
  const [filterClicked, setFilterClicked] = useToggler();
  const prevSelectedFilter = usePrevious(selectedFilter);

  useEffect(() => {
    if (!startDate || !endDate) {
      const filter = filters.find((f) => f.value === DateTimeFilters.TWO_DAYS);
      const filterEndDate = formatDate(undefined, urlFormat);
      if (!filter) return;
      const queryParams = { startDate: filter.getStartDate().format(urlFormat), endDate: filterEndDate };
      onDateChanged(queryParams);
    }
  }, []);

  useEffect(() => {
    initCalendarValues();
  }, [selectedFilter]);

  useEffect(() => {
    if (!fullTimeRange) return;
    setSelectedFilter(getSelectedFilterValue());
    setSelectedStartDate(getDateObject(fullTimeRange.end));
    setSelectedEndDate(getDateObject(fullTimeRange.end));
  }, [fullTimeRange.start, fullTimeRange.end]);

  useEffect(() => {
    if (!selectedStartDate) return;

    if (!selectedEndDate || selectedStartDate > selectedEndDate) {
      setSelectedEndDate(selectedStartDate);
    }
  }, [selectedStartDate]);

  function initCalendarValues() {
    if (!selectedFilter || filterClicked) return;
    setSelectedStartDate(selectedFilter === DateTimeFilters.CUSTOM ? getDateObject(startDate) : getDateObject(fullTimeRange.end));
    setSelectedEndDate(selectedFilter === DateTimeFilters.CUSTOM ? getDateObject(endDate) : getDateObject(fullTimeRange.end));
  }

  function onFilterClick(filterItem) {
    if (isLoading) return null;

    let start;
    let end;
    const filter = filters.find((f) => f.value === filterItem.props.itemKey);
    const filterEndDate = formatDate(undefined, urlFormat);
    if (!filter) return null;
    if (!filterClicked) setFilterClicked();

    if (filter.value === DateTimeFilters.CUSTOM && showCustomFilterPopup) {
      setSelectedFilter(prevSelectedFilter);
      return toggleCustomFilterPopup();
    }

    setSelectedFilter(filter.value);

    switch (filter.value) {
      case DateTimeFilters.ALL_TIME:
        start = ALL_TIME_FILTER;
        end = ALL_TIME_FILTER;
        break;

      case DateTimeFilters.CUSTOM:
        toggleCustomFilterPopup();
        return filter;
      default:
        start = filter.getStartDate().format(urlFormat);
        end = filterEndDate;
    }
    if (showCustomFilterPopup) toggleCustomFilterPopup();

    const queryParams = { startDate: start, endDate: end };
    onDateChanged(queryParams);
    trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.DATE_TIME_FILTER, message: filter.value });

    return filter;
  }

  function onApplyClicked() {
    if (!selectedStartDate || !selectedEndDate) return;

    toggleCustomFilterPopup();
    const start = dayJS(selectedStartDate).format(urlFormat);
    const end = dayJS(selectedEndDate).format(urlFormat);
    const queryParams = { startDate: start, endDate: end };
    onDateChanged(queryParams);
  }

  function onClearClicked() {
    setSelectedStartDate(getDateObject(fullTimeRange.end));
    setSelectedEndDate(getDateObject(fullTimeRange.end));
  }

  function getSelectedFilterValue() {
    if (!fullTimeRange || !fullTimeRange.start || !fullTimeRange.end) return null;

    if (startDate === ALL_TIME_FILTER || endDate === ALL_TIME_FILTER) {
      return DateTimeFilters.ALL_TIME;
    }

    if (!startDate || !endDate) {
      return DateTimeFilters.TWO_DAYS;
    }
    const start = dayJS(startDate);
    const end = dayJS(endDate);

    const startDiff = dayJS().diff(start, 'day');
    const endDiff = dayJS().diff(end, 'hour');
    if (startDiff === 1 && endDiff <= 24 && endDiff >= 0) return DateTimeFilters.TWO_DAYS;
    if (startDiff === WEEK_DIFF_IN_DAYS && endDiff <= 24 && endDiff >= 0) return DateTimeFilters.SEVEN_DAYS;
    if (startDiff === MONTH_DIFF_IN_DAYS && endDiff <= 24 && endDiff >= 0) return DateTimeFilters.THIRTY_DAYS;

    const fullRangeStart = dayJS(fullTimeRange.start);
    const fullRangeEnd = dayJS(fullTimeRange.end);
    if (!fullRangeStart.diff(start, 'day') && !fullRangeEnd.diff(end, 'day')) return DateTimeFilters.ALL_TIME;

    return DateTimeFilters.CUSTOM;
  }

  const onKeyDownCustomFilter = (e) => {
    if (e.key === KEY_CODE.ENTER) {
      e.preventDefault();
      onApplyClicked();
    }
  };

  function renderRangeDates() {
    if (!fullTimeRange || isLoading) return null;

    const isCustomFilter = getSelectedFilterValue() === DateTimeFilters.CUSTOM;
    const start = startDate === ALL_TIME_FILTER ? fullTimeRange.start : startDate;
    const end = isCustomFilter ? endDate : undefined;
    const formattedStart = formatFullDate(start);
    const formattedEnd = formatFullDate(end);
    return <StyledRangeDates>{`${formattedStart} - ${formattedEnd}`}</StyledRangeDates>;
  }

  function renderCustomFilter() {
    if (!fullTimeRange) return null;
    const startProps = {
      minDate: getDateObject(fullTimeRange.start),
      maxDate: getDateObject(),
      ...startCalendarPickerProps
    };
    const startCalendarPicker = (
      <StyledStartCalendarPicker
        onSelectDate={setSelectedStartDate}
        selectedDate={selectedStartDate}
        label={strings.startDateLabel}
        calendarPickerProps={startProps}
        calendarButtonStyle={calendarButtonStyle}
      />
    );

    const endProps = {
      minDate: getDateObject(selectedStartDate
        ? Math.max(getDateObject(fullTimeRange.start), selectedStartDate)
        : fullTimeRange.start),
      maxDate: getDateObject(),
      ...endCalendarPickerProps
    };
    const endCalendarPicker = (
      <CalendarPicker
        onSelectDate={setSelectedEndDate}
        selectedDate={selectedEndDate}
        label={strings.endDateLabel}
        calendarPickerProps={endProps}
        calendarButtonStyle={calendarButtonStyle}
      />
    );

    return (
      <ClickOutside onClickOutside={toggleCustomFilterPopup}>
        <StyledCustomFilterContainer aria-label="Custom dates filter" onKeyDown={onKeyDownCustomFilter}>
          <Card.Item>
            <FlexRowCentered>
              {startCalendarPicker}
              {endCalendarPicker}
            </FlexRowCentered>
            <StyledCustomFilterFooter>
              <ApplyButton onClick={onApplyClicked} disabled={!selectedStartDate || !selectedEndDate}>
                {strings.apply}
              </ApplyButton>
              <DefaultButton onClick={onClearClicked}>
                {strings.clear}
              </DefaultButton>
            </StyledCustomFilterFooter>
          </Card.Item>
        </StyledCustomFilterContainer>
      </ClickOutside>
    );
  }

  function renderFilters() {
    return filters.map(({ label, value }) => (
      <PivotItem headerText={<span title={strings[label]}>{strings[label]}</span>} itemKey={value} key={value}>
        {
          value === DateTimeFilters.CUSTOM && showCustomFilterPopup
          && renderCustomFilter()
        }
      </PivotItem>
    ));
  }

  return (
    <StyledPivotContainer>
      <StyledPivot
        linkSize={PivotLinkSize.large}
        linkFormat={PivotLinkFormat.tabs}
        onLinkClick={onFilterClick}
        selectedKey={selectedFilter}
        styles={pivotStyles(isLoading, isRtl)}
      >
        {renderFilters()}
      </StyledPivot>
      {renderRangeDates()}
    </StyledPivotContainer>
  );
};

export default DateTimeFilter;
