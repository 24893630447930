import React from 'react';
import { filter } from 'lodash';
import { Timeline } from '../Timeline/Timeline';
import { TimelineHeaderList, TimelineCustomHeaderContainer, TimelineCustomHeaderCount, TimelineCustomHeaderClose } from './StyledTimelinesGroup';
import { extractParticipantDetails } from '../../utils';
import { useInfra } from '../../../../providers/InfraProvider';
import { CALL_STRINGS } from '../../strings';

const TimelineCustomHeader = ({ text, count, isOpen, onToggle }) => (
  <TimelineCustomHeaderContainer onClick={onToggle}>
    <span>{text}</span>
    <TimelineCustomHeaderCount>({count})</TimelineCustomHeaderCount>
    <TimelineCustomHeaderClose isOpen={isOpen} />
  </TimelineCustomHeaderContainer>
);

export const TimelinesGroup = ({ timelines, isOpen, isHidden, onToggle, duration, headerText, keywordInstances, hoveredKeywordInstances, selectedActionItem, comments, commentsMoveTo, hoveredActionItem, participants, isVisibleComments, isWithMargin, avatars }) => {
  const { t } = useInfra();

  if (isHidden) {
    return null;
  }

  const getKeywordInstancesByIds = (speakerIds) => filter(keywordInstances, (item) => item && speakerIds.includes(item.speakerId));
  const getCommentsByIds = (speakerIds) => filter(comments, (item) => item && speakerIds.includes(item.speakerId));
  const getHoveredKeywordInstancesByIds = (speakerIds) => filter(hoveredKeywordInstances, (item) => item && speakerIds.includes(item.speakerId));

  const getSelectedActionItemByIds = (speakerIds) => selectedActionItem && speakerIds.includes(selectedActionItem.speakerId) ? selectedActionItem : null;
  const getHoveredActionItemByIds = (speakerIds) => hoveredActionItem && speakerIds.includes(hoveredActionItem.speakerId) ? hoveredActionItem : null;

  const { mainTimeline, detailsTimelines } = timelines;
  const isSingle = detailsTimelines.length === 1;

  return (
    <div>
      {!isSingle && (
        <Timeline
          isFade={isOpen}
          customHeader={<TimelineCustomHeader text={headerText} count={detailsTimelines.length} isOpen={isOpen} onToggle={onToggle} />}
          duration={duration}
          blocks={mainTimeline.blocks}
          keywordInstances={getKeywordInstancesByIds(mainTimeline.speakerIds)}
          comments={getCommentsByIds(mainTimeline.speakerIds)}
          commentsMoveTo={commentsMoveTo}
          hoveredKeywordInstances={getHoveredKeywordInstancesByIds(mainTimeline.speakerIds)}
          selectedActionItem={getSelectedActionItemByIds(mainTimeline.speakerIds)}
          hoveredActionItem={getHoveredActionItemByIds(mainTimeline.speakerIds)}
          isVisibleComments={isVisibleComments}
        />
      )}
      <TimelineHeaderList isOpen={isOpen} isSingle={isSingle} isWithMargin={isWithMargin}>
        {detailsTimelines.map(({ speakerId, blocks }) => {
            const participantDetails = extractParticipantDetails(participants.find((a) => a.id === speakerId), t(CALL_STRINGS.UNKNOWN));
            const participantAvatar = avatars?.[participantDetails.aadId];
            return (
                <Timeline
                    key={speakerId}
                    duration={duration}
                    blocks={blocks}
                    participantDetails={participantDetails}
                    avatarUrl={participantAvatar}
                    keywordInstances={getKeywordInstancesByIds([speakerId])}
                    comments={getCommentsByIds([speakerId])}
                    commentsMoveTo={commentsMoveTo}
                    hoveredKeywordInstances={getHoveredKeywordInstancesByIds([speakerId])}
                    selectedActionItem={getSelectedActionItemByIds([speakerId])}
                    hoveredActionItem={getHoveredActionItemByIds([speakerId])}
                    isVisibleComments={isVisibleComments}
                />
            );
        })}
      </TimelineHeaderList>
    </div>

  );
};
