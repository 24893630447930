import styled from 'styled-components';
import { rtl, colors } from 'ci-common-ui';

import orgImageSrc from 'assets/images/org.png';

const SEARCH_CONTAINER_HEIGHT = 75;

export const Text = styled.div`
  margin-bottom: 15px;
`;

export const SerachContainer = styled.div`
height: ${SEARCH_CONTAINER_HEIGHT}px;
`;

export const ListContainer = styled.div`
  height: calc(100% - ${SEARCH_CONTAINER_HEIGHT}px);
  overflow: auto;
  min-width: 100px;
  min-height: 100px;
`;

export const OrgImage = styled.img.attrs({
  src: orgImageSrc,
})`
  height: 48px;
  width: 48px;
  ${rtl`margin-right`}: 15px;
`;

export const OrgContainer = styled.div`
  display: flex;
  padding: 10px 0;
  cursor: pointer;
  border: 1px solid white;

  &:hover {
    background: ${colors.Gray10};
  }
  
  &:focus {
    border-color: ${colors.Black};
  }
  
  &:active {
    border-color: white;
  }
  
  && {
    ${({ isSelected }) => isSelected && `
      border-color: ${colors.DYNPrimary};
    `}
  }
`;
