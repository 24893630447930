import React from 'react';
import { Spinner } from '@fluentui/react'; // DO NOT remove /lib #PR-560326
import { SpinnerContainer, spinnerStyles } from './StyledAppSpinner';
import { ARIA_ROLES } from '../../constants/roles-list';
import { ATTR_VALUES } from '../../constants/a11y';

export const AppSpinner = ({ label, className }) => {
  const ariaLabel = label ? undefined : ATTR_VALUES.LOADING;

  return (
    <SpinnerContainer className={className}>
      <Spinner label={label} styles={spinnerStyles} role={ARIA_ROLES.STATUS} aria-label={ariaLabel} />
    </SpinnerContainer>
  );
};
