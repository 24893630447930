import React from 'react';
import useLocale from 'hooks/use-locale';
import SettingsForm from '../../SettingsForm/SettingsForm';
import ConversationContent from '../../../../../components/Settings/ConversationContent/ConversationContent';

const ConversationContentTab = ({ closeModal }) => {
  const { t } = useLocale('pages.settings.conversation_content');

  return (
    <SettingsForm
      tab="conversationContent"
      title={t('nav_title')}
      closeModal={closeModal}
      render={({ isLoadingSettingsData, values }) => <ConversationContent isLoadingSettingsData={isLoadingSettingsData} supportedLanguages={values && values.supportedLanguages} />}
    />
  );
};

export default ConversationContentTab;
