import React from 'react';
import { Icon } from '@fluentui/react';
import { useToggler, useResize, ClickOutside } from 'ci-common-ui';
import useLocale from 'hooks/use-locale';
import {
  LinkText,
  navButtonStyle,
  NavIconContainer,
  PageOverlay,
  RowContainer,
  Selector,
  StyledIcon,
  StyledNavBar,
  TabButtonRow,
  TabsContainer,
  ButtonRowContainer,
} from './StyledNavBar';

const NavBar = ({ pages, currentTab, onTabChange }) => {
  const { t } = useLocale('components.nav_bar');
  const { isMobile } = useResize();
  const filteredPages = pages.filter(({ shouldHide }) => !shouldHide || !shouldHide());
  const [isExpanded, toggleExpansion, setTogglerState] = useToggler(!isMobile);
  const tabIdx = filteredPages.findIndex(({ pageId }) => pageId === currentTab);
  const isCurrentPageInNavBar = tabIdx > -1;

  function renderTabs() {
    return (
      <>
        {filteredPages.map((navBarPage) => (
          <TabButtonRow
            key={navBarPage.pageId}
            selected={currentTab === navBarPage.pageId}
            onClick={() => onTabChange(navBarPage.pageId)}
          >
            <ButtonRowContainer>
              <StyledIcon>
                <Icon iconName={navBarPage.iconId} />
              </StyledIcon>
              <LinkText>{t(navBarPage.label)}</LinkText>
            </ButtonRowContainer>
          </TabButtonRow>
        ))}
      </>
    );
  }

  const navBar = (
    <StyledNavBar isExpanded={isExpanded}>
      {!isMobile && (
        <RowContainer onClick={toggleExpansion}>
          <ButtonRowContainer aria-label={isExpanded ? 'Collapse' : 'Expand'}>
            <StyledIcon>
              <Icon iconName="GlobalNavButton" style={navButtonStyle} />
            </StyledIcon>
          </ButtonRowContainer>
        </RowContainer>
      )}
      <TabsContainer>
        {isCurrentPageInNavBar && <Selector tabIdx={tabIdx} />}
        {renderTabs()}
      </TabsContainer>
    </StyledNavBar>
  );

  if (isMobile) {
    return (
      <>
        {isExpanded && <PageOverlay />}
        <ClickOutside onClickOutside={() => setTogglerState(false)}>
          <NavIconContainer onClick={toggleExpansion} onTouchEnd={toggleExpansion}>
            <Icon
              id="toggleNav"
              iconName="GlobalNavButton"
              style={{ padding: '14px', fontSize: '16px', background: 'black', color: 'white' }}
            />
          </NavIconContainer>
          {navBar}
        </ClickOutside>
      </>
    );
  }
  return navBar;
};
export default NavBar;
