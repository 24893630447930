import React from 'react';
import { isNil, map } from 'lodash';
import { KPI, Trans } from 'ci-common-ui';
import { Container, TrendValue } from './StyledSellerBarKPIs';

const KPIs = ({ data }) => (
  <Container>
    {map(data, ({
      label, value, format, tooltip, teamAvgValue, teamAvgTrend
    }) => (
      <KPI
        key={label}
        label={label}
        tooltip={tooltip}
        value={value}
        format={format}
        sidesMargin={14}
        bottomLabel={!isNil(teamAvgValue) && !isNil(teamAvgTrend) && (
          <Trans i18nKey="components.seller_bar.kpi_bottom_label">
            Team avg <TrendValue>{{ teamAvgValue }}</TrendValue> ({{ teamAvgTrend: `${teamAvgTrend >= 0 ? '+' : '-'}${Math.abs(teamAvgTrend)}` }})
          </Trans>
        )}
      />
    ))}
  </Container>
);
export default KPIs;
