import styled from 'styled-components';
import { Icon } from '@fluentui/react';
import { FontSizes } from '@uifabric/fluent-theme';
import { colors, FlexColumn, FlexRowCentered } from 'ci-common-ui';

export const UploadIcon = styled(Icon).attrs({
  iconName: 'PublishContent',
})`
  font-size: ${FontSizes.size28};
  color: ${colors.DYNPrimary};
  width: 32px;
  height: 32px;
`;

export const tableLanguageDropdown = {
  title: {
    backgroundColor: colors.Gray20,
    borderRadius: '8px',
    border: 'none',
  },
};

export const UploadContainer = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
  border: 2px dashed ${colors.DYNPrimary};
  border-radius: 8px;
  background-color: ${colors.DYNTint50};
  height: 120px;
  padding: 24px 0;
  margin-top: 16px;
`;

export const HiddenFileInput = styled.input.attrs({
  type: 'file',
  multiple: true,
})`
  display: none;
`;

export const DragTitle = styled.div`
  font-weight: 600;
  font-size: ${FontSizes.size14};
  margin-top: 4px;
`;

export const FileConstraints = styled.div`
  font-size: ${FontSizes.size12};
  color: ${colors.Gray130};
`;

export const ErrorCellContainer = styled(FlexRowCentered)`
  height: 100%;
  justify-content: center;
  color: ${colors.ScarletRed};
  font-size: ${FontSizes.size16};
  cursor: default;
`;

export const fileDropStyles = {
  height: '100%'
};
