// @flow
import React from 'react';
import noop from 'lodash/noop';
import { Field, useForm } from 'react-final-form';
import { TextField as FBTextField, Spinner } from '@fluentui/react';
import { composeValidators, requiredValidate } from '../validation';
import { setStyle } from './CommonStyles';

export const TextField = ({
  name, type, label, subLabel, validate, prefix, suffix, isRequired, placeholder, onRenderLabel, isDisabled, isReadOnly, isLoading, success, customError, width, minWidth, errorMessages, onChange = noop, onFocus = noop, onBlur = noop,
}) => {
  const formApi = useForm();

  return (
    <Field
      name={name}
      validate={isRequired ? composeValidators(requiredValidate, validate) : validate}
      render={({ input, meta }) => {
        const handleChange = (e) => {
          input.onChange(e);
          onChange({ input, meta, formApi });
        };
        const handleFocus = (e) => {
          input.onFocus(e);
          onFocus({ input, meta, formApi });
        };

        const handleBlur = (e) => {
          input.onBlur(e);
          onBlur({ input, meta, formApi });
        };

        return (
          <FBTextField
            label={label}
            subLabel={subLabel}
            data-testid={input.name}
            type={type}
            prefix={prefix}
            name={input.name}
            placeholder={placeholder}
            value={input.value}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            required={isRequired}
            disabled={isDisabled || isLoading}
            readOnly={isReadOnly}
            errorMessage={(meta.touched && meta.error && errorMessages[meta.error]) || customError}
            styles={setStyle({ width, minWidth, success })}
            onRenderSuffix={isLoading ? () => <Spinner /> : () => suffix}
            onRenderLabel={onRenderLabel}
          />
        );
      }}
    />
  );
};
