// @flow
import React from 'react';
import noop from 'lodash/noop';
import { Field } from 'react-final-form';
import { Checkbox } from '@fluentui/react';
import { requiredValidate } from '../../validation';
import { Container, Loading, Description } from './StyledCheckboxField';

export const CheckboxField = ({
  name, label, ariaLabel, description, isRequired, isDisabled, width, minWidth, isBold, isLoading, errorMessages, className, inputProps, onChange
}) => {
  const disabled = isLoading || isDisabled;

  return (
    <Field
      name={name}
      type="checkbox"
      validate={isRequired ? requiredValidate : noop}
      render={({ input, meta }) => (
        <Container className={className}>
          <Checkbox
            label={typeof label === 'string' ? label : undefined}
            ariaLabel={ariaLabel}
            name={input.name}
            checked={input.checked}
            onChange={(value) => {
                input.onChange(value);
                if(onChange){
                    onChange(value);
                }
            }}
            required={isRequired}
            disabled={disabled}
            onRenderLabel={typeof label === 'function' ? label : undefined}
            errorMessage={meta.touched && meta.error && errorMessages[meta.error]}
            isBold={isBold}
            styles={{ root: { width, minWidth }, text: { fontWeight: isBold ? 'bold' : 'normal' }, checkbox: { alignSelf: 'flex-start', marginTop: '3px', width: '16px', height: '16px' } }}
            inputProps={inputProps}
          />
          {description && <Description disabled={disabled}>{description}</Description>}
          {isLoading && <Loading />}
        </Container>
      )}
    />
  );
};
