import React, { useState } from 'react';
import { Dialog, DialogFooter, PrimaryButton, DefaultButton, Icon } from '@fluentui/react';

import { DeleteWrapper, DeleteButton, Success } from './StyledDeleteCell';

const DIALOG_WIDTH = 440;

const DeleteButtonWrapper = ({ deleted, openDialog, btnText, successText }) => {
  if (deleted) {
    return (
      <Success>
        <Icon iconName="CheckMark" />
        <span>{successText}</span>
      </Success>
    );
  }

  return (
    <DeleteButton iconProps={{ iconName: 'Cancel' }} onClick={openDialog}>{btnText}</DeleteButton>
  );
};

export const DeleteCell = ({ id, ariaLabel, btnText, successText, deleted, confirmProps: { dialogTitle, dialogSubtext, okText, cancelText }, onRowHoverClassName, deleteAction }) => {
  const [isOpen, toggleDialog] = useState(false);

  const openDialog = () => toggleDialog(true);
  const closeDialog = () => toggleDialog(false);

  const onDelete = () => {
    closeDialog();
    deleteAction(id);
  };

  const containerClassName = deleted ? '' : onRowHoverClassName;

  return (
    <DeleteWrapper className={containerClassName} aria-label={`${ariaLabel} button`}>
      <DeleteButtonWrapper
        deleted={deleted}
        openDialog={openDialog}
        btnText={btnText}
        successText={successText}
      />
      <Dialog
        hidden={!isOpen}
        onDismiss={closeDialog}
        minWidth={DIALOG_WIDTH}
        dialogContentProps={{
          title: dialogTitle,
          subText: dialogSubtext
        }}
      >
        <DialogFooter>
          <PrimaryButton onClick={onDelete} text={okText} />
          <DefaultButton onClick={closeDialog} text={cancelText} />
        </DialogFooter>
      </Dialog>

    </DeleteWrapper>
  );
};
