import React from 'react';
import filter from 'lodash/filter';
import { Redirect, Route, Switch } from 'react-router';
import { routes, getDefaultRoute } from 'pages/routing';
import HomePage from 'pages/HomePage/HomePage';
import TeamPage from 'pages/TeamPage/TeamPage';
import SellerPage from 'pages/SellerPage/SellerPage';
import SystemMonitoringPage from 'pages/SystemMonitoringPage/SystemMonitoringPage';
import CallPage from 'pages/CallPage/CallPageWrapper';
import InsightCustomerSentimentPage from 'pages/insights-pages/InsightCustomerSentimentPage/InsightCustomerSentimentPage';
import InsightTrackedKeywordsPage from 'pages/insights-pages/insight-keywords-pages/InsightTrackedKeywordsPage/InsightTrackedKeywordsPage';
import InsightCompetitorsPage from 'pages/insights-pages/insight-keywords-pages/InsightCompetitorsPage';
import InsightBrandsPage from 'pages/insights-pages/insight-keywords-pages/InsightBrandsPage';
import InsightTopSellersPage from 'pages/insights-pages/insight-keywords-pages/InsightTopSellersPage';
import { FEATURES } from 'ci-common-ui';
import NavBarContainer from '../NavBar/NavBarContainer';
import { Container } from './StyledAppLayout';
import { CenterContent, PageLayout } from '../../StyledApp';
import { getIsEmbedMode, getIsOnboarded } from '../../utils/app-context/app-context';
import { useAppState } from '../../providers/AppStateProvider';
import NotificationBars from '../NotificationBars/NotificationBars';
import PageErrorBoundary from '../ErrorBoundry/PageErrorBoundary';

import SetupAppBannerContainer from '../../pages/HomePage/components/SetupAppBanner/SetupAppBannerContainer';
import SearchPage from '../../pages/SearchPage/SearchPage';
import { isFeatureEnabled } from '../../utils/app-context/feature-flighting';

const pageContentMap = {
  home: (
    <HomePage />
  ),
  team: (
    <CenterContent>
      <TeamPage />
    </CenterContent>
  ),
  sellers: (
    <CenterContent>
      <SellerPage />
    </CenterContent>
  ),
  systemMonitoring: (
    <CenterContent>
      <SystemMonitoringPage />
    </CenterContent>
  ),
  call: (
    <PageErrorBoundary>
      <CallPage />
    </PageErrorBoundary>
  ),
  search: (
    <CenterContent>
      <SearchPage />
    </CenterContent>
  ),
  customerSentimentInsight: (
    <CenterContent>
      <InsightCustomerSentimentPage />
    </CenterContent>
  ),
  trackedKeywordsInsight: (
    <CenterContent>
      <InsightTrackedKeywordsPage />
    </CenterContent>
  ),
  competitorsInsight: (
    <CenterContent>
      <InsightCompetitorsPage />
    </CenterContent>
  ),
  brandsInsight: (
    <CenterContent>
      <InsightBrandsPage />
    </CenterContent>
  ),
  topSellersInsight: (
    <CenterContent>
      <InsightTopSellersPage />
    </CenterContent>
  )
};

export default () => {
  const { appState: { notificationBars, isSoftError } } = useAppState();
  const availablePages = filter(routes, ({ shouldHide }) => !shouldHide || !shouldHide());

  return (
    <Container>
      {!getIsEmbedMode() && !isSoftError && <NavBarContainer />}
      <Switch>
        {availablePages.map(({ pageId, path }) => (
          <Route exact path={path} key={`page-${pageId}`}>
            <PageLayout>
              <NotificationBars bars={notificationBars} />
              {isFeatureEnabled(FEATURES.TRY_AND_BUY) && !getIsOnboarded() && (
                <SetupAppBannerContainer />
              )}
              {pageContentMap[pageId]}
            </PageLayout>
          </Route>
        ))}
        <Route
          path="/refresh-page/:path?"
          component={(props) => (
            <Redirect to={{
              pathname: `/${props.match.params.path || ''}`,
              search: props.location.search
            }}
            />
          )}
        />
        <Route path="*">
          <Redirect to={getDefaultRoute().link} />
        </Route>
      </Switch>
    </Container>
  );
};
