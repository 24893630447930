import * as React from 'react';
import { map, upperFirst } from 'lodash';
import { Container } from './StyledCallKPIs';
import { KPI } from '../../../../components/KPI/KPI';
import { useInfra } from '../../../../providers/InfraProvider';

export const CallKPIs = ({ kpis, hideInfoIcon, sidesMargin, allowMultipleLines }) => {
  const { t } = useInfra();
  const filteredKpis = kpis.filter(({ value }) => value);

  const adaptedKPIs = map(filteredKpis, ({ id, value, units }) => ({
    value,
    format: units ? t(`measurement_units_${units}`) : '',
    label: upperFirst(t(`kpis_${id}_title`)),
    tooltip: upperFirst(t(`kpis_${id}_tooltip`))
  }));

  return (
    <Container allowMultipleLines={allowMultipleLines}>
      {map(adaptedKPIs, ({ label, value, format, tooltip }) => (
        <KPI key={label} label={label} tooltip={tooltip} value={value} format={format} hideInfoIcon={hideInfoIcon} sidesMargin={sidesMargin} />
      ))}
    </Container>
  );
};
