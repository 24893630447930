export enum ProdRegions {
  prod = 'prod',
  nam = 'nam',
  nam2 = 'nam2',
  ssam = 'ssam',
  ccan = 'ccan',
  weur = 'weur',
  seapj = 'seapj',
  eoce = 'eoce',
  ejpn = 'ejpn',
  cind = 'cind',
  tip1 = 'tip1',
  gbr = 'gbr',
  cfra = 'cfra',
  nzaf = 'nzaf',
  nuae = 'nuae',
  swiss = 'swiss',
  ger = 'ger',
  norway = 'norway',
  korea = 'korea',
}