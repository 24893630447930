import React from 'react';
import { Icon } from '@fluentui/react';
import { KeywordLink } from './StyledKeywordsPanel';
import { Tooltip } from '../../../../components/Tooltip';

export const Keyword = ({
  checked,
  hidden,
  onClick,
  onMouseUp,
  onMouseEnter,
  onMouseLeave,
  isTopic,
  instances,
  displayedText,
  tooltipBodyText,
}) => (
  <Tooltip content={tooltipBodyText}>
    <KeywordLink
      data-testid="keyword-link"
      $isSelected={checked}
      $hide={hidden}
      onClick={onClick}
      onMouseUp={onMouseUp}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      $isTopic={isTopic}
      instances={instances}
    >
        {isTopic ? <><Icon iconName="NumberSymbol" /> {displayedText}</> : displayedText} 
    </KeywordLink>
  </Tooltip>
);
