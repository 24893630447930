import { ActionButton } from '@fluentui/react';
import React from 'react';

export const RemoveMappingCell = ({ row, column }) => {
  const { sourceUser, targetUser } = row;
  const { onRemoveMapping } = column;

  return (
    <ActionButton
      iconProps={{ iconName: 'Cancel' }}
      onClick={() => onRemoveMapping(sourceUser.aadId, targetUser.aadId)}
    />
  );
};
