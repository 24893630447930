import React, { useState } from 'react';
import { get, find, noop, size, map } from 'lodash';
import {
  CallPageContainer,
  SidebarContainer,
  TranscriptPanelContainer,
  MainContent,
  ContentContainer,
  PlayerContainer,
  TogglePlayerSize,
  TogglePlayerSizeIcon,
} from './StyledCallPage';
import { Header, SidePivot, AudioPlayer, DualTimeline, Transcript } from './components';
import { eventAction } from '../../services/telemetry-service';
import { InfraProvider } from '../../providers/InfraProvider';
import {
  decorateTranscriptBySegment,
  filterKeywordsBySegmentation,
  setSegmentTitle,
  setSegmentTime,
  calcTimelinesHeight,
  mergeParticipants,
  addToAllUpdatedParticipants,
} from './utils';
import { TRACK_IDS } from './tracking';
import { CALL_STRINGS } from './strings';
import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import { AppError } from '../../components/AppError';
import { useToggler } from '../../hooks/use-toggler';
import { StatusBar } from './components/StatusBar';
import { getIsOnlyAgentsOrOnlyAgent } from '../../constants/api';

export const addPropToActionItemFromRow = (actionItem, transcript) => {
  const isStartInSecondsExist = !!get(actionItem, 'startInSeconds');
  if(isStartInSecondsExist){
    return actionItem;
  }

  const actionItemFragmentId = get(actionItem, 'fragmentId');
  const row = find(transcript.origin, ({ id }) => id === actionItemFragmentId);
  const { startInSeconds, endInSeconds, speakerId } = row || {};

  return { ...actionItem, startInSeconds, endInSeconds, speakerId };
};

const transformCommentsToTimelineSchema = (commentsObj, transcript) =>
  map(Object.keys(commentsObj), (fragmentIdentifier) => {
    const currentComments = commentsObj[fragmentIdentifier];
    const count = size(currentComments);
    const { startInSeconds, speakerId } = currentComments[0] || {}

    if(startInSeconds){
      return {
        fragmentIdentifier,
        startInSeconds,
        speakerId,
        count,
      };
    }

    const row = find(transcript.origin, ({ id }, index) => id === fragmentIdentifier || index === Number(fragmentIdentifier));
    const { startInSeconds: startInSecondsFromTranscript, speakerId:speakerIdFromTranscript } = row || {};

    return {
      fragmentIdentifier,
      startInSeconds: startInSecondsFromTranscript,
      speakerId: speakerIdFromTranscript,
      count,
    };
  });


const RenderTimeline = ({
  duration,
  value,
  onChange,
  isSmallPlayer,
  comments,
  commentsMoveTo,
  timelineProps,
}) => (
  <DualTimeline
    duration={duration * 1000}
    currentTime={value}
    onChange={onChange}
    isSmallPlayer={isSmallPlayer}
    comments={comments}
    commentsMoveTo={commentsMoveTo}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...timelineProps}
  />
);

export const CallPage = ({
  data,
  isEditable,
  isLoading,
  isLoadingError,
  callStreamUrl,
  initialCurrentTime,
  setLoadAudioError,
  comments,
  isCommentsLoading,
  onUpdateComment,
  onAddComment,
  onDeleteComment,
  requestHowlerDataFromIframe,
  userId,
  orgId,
  t,
  tAbs,
  tDefault,
  trackEvent,
  trackError,
  orgUrl,
  userName,
  tenantId,
  orgLCID,
  tagsProps,
  shouldHideMainHeader,
  shouldHideReadOnlyStatusBar,
  ExtraComponents = {},
  isExecutiveSummaryEnabled,
  clearCache,
  shouldHideParticipantCrmDetails,
  shouldHideComments,
  shouldHideExecSummaryTour,
  isViva,
  highlightsInternalMeetingLearnMoreLink,
  setLeavingPagePromptMessage = noop,
  tabsOrder,
  avatars,
  CreateTaskComponent,
  CreateFeedbackComponent,
  onDeleteInsightsClick,
  isTopicsLpcComponentReady,
  isStorageTypeDataverseOnly,
  crmActivity,
  infoMessage
}) => {
  const [isSmallPlayer, setIsSmallPlayer] = useState(false);
  const [isAgentsTimelinesOpen, toggleAgentsTimelines] = useToggler();
  const [isCustomersTimelinesOpen, toggleCustomersTimelines] = useToggler();

  const ariaLabelToggleTimeline = `${isSmallPlayer ? 'open' : 'close'} timeline toggle`;

  const [selectedSegment, setSelectedSegment] = useState({});
  const [selectedKeywordDetails, setSelectedKeywordDetails] = useState({});
  const [hoveredKeywordDetails, setHoveredKeywordDetails] = useState({});
  const [selectedActionItem, setSelectedActionItem] = useState(null);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [hoveredActionItem, setHoveredActionItem] = useState(null);
  const [selectedTranscriptRow, setSelectedTranscriptRow] = useState({});
  const [soundState, setSoundState] = useState({});
  const [isDeleteInsightsFailed, setIsDeleteInsightsFailed] = useState(false);
  const [allUpdatedParticipants, setAllUpdatedParticipants] = useState([]);
  const [isInfoMessageHidden, setIsInfoMessageHidden] = useState(false);

  const addUpdatedParticipant = (updatedParticipant) =>
    setAllUpdatedParticipants(
      addToAllUpdatedParticipants(allUpdatedParticipants, updatedParticipant)
    );

  const deleteInsightsHandler = !onDeleteInsightsClick ? undefined : async () => {
    try {
      await onDeleteInsightsClick();
    } catch {
      setIsDeleteInsightsFailed(true);
    }
  };

  const mergedData = {
    ...data,
    participants: mergeParticipants(data.participants, allUpdatedParticipants),
  };
  const {
    summary,
    keywords,
    transcript,
    segmentation,
    consentMode,
  } = mergedData;
  const isOnlyAgentsRecorded = getIsOnlyAgentsOrOnlyAgent(consentMode);

  const durationInSecond = soundState.duration || summary.duration / 1000;

  const actionItems = data?.executiveSummary?.actionItems || [];

  const segmentWithTitle = setSegmentTitle(segmentation, t);
  const segmentWithTime = setSegmentTime(segmentWithTitle, transcript.origin, durationInSecond);
  const filteredKeywords = filterKeywordsBySegmentation(keywords, selectedSegment);

  const markedTranscript = decorateTranscriptBySegment(transcript.origin, segmentWithTime);
  const markedTranslatedTranscript = decorateTranscriptBySegment(
    transcript.english,
    segmentWithTime
  );

  const onSegmentSelected = (segment) => {
    if (segment && segment.fragmentStartIndex !== selectedSegment.fragmentStartIndex) {
      setSelectedSegment(segment);
      setSelectedKeywordDetails({});
      setSelectedActionItem(null);
      trackEvent({ action: eventAction.click, actionOn: TRACK_IDS.SEGMENT, message: segment });
    }
  };

  const clearSelectedSegment = () => onSegmentSelected({});

  const onSelectSuggestion = (suggestion) => {
    setSelectedSuggestion(suggestion);
  };

  const onSelectActionItem = (actionItem) => {
    if (!actionItem) {
      return setSelectedActionItem(null);
    }
    const newActionItem = addPropToActionItemFromRow(actionItem, transcript);
    return setSelectedActionItem(newActionItem);
  };

  const onHoverActionItem = (actionItem, isMouseLeave) => {
    const newActionItem = addPropToActionItemFromRow(actionItem, transcript);
    setHoveredActionItem(isMouseLeave ? null : newActionItem);
  };

  const onKeywordClicked = ({ type: section, keywordId, fragmentId, isTopicKeyword }) => {
    if (!keywordId) {
      setSelectedKeywordDetails({});
      return;
    }

    const { selectedSection, selectedKeywordId } = selectedKeywordDetails;
    if (selectedSection === section && selectedKeywordId === keywordId) {
      setSelectedKeywordDetails({});
    } else {
      setSelectedKeywordDetails({
        selectedSection: section,
        selectedKeywordId: keywordId,
        data: getSelectedKeyword(section, keywordId),
      });

      const actionOn = isTopicKeyword ? TRACK_IDS.VIVA_TOPICS_KEYWORD : TRACK_IDS.KEYWORD;
      trackEvent({
        action: eventAction.click,
        actionOn,
        message: `${section} - ${fragmentId}`,
      });
    }
  };

  const onKeywordHover = (section, keywordId, fragmentId, isMouseLeave, shouldTrackEvent, isTopicKeyword) => {
    if (shouldTrackEvent && isTopicKeyword) {
      trackEvent({
        action: eventAction.hover,
        actionOn: TRACK_IDS.VIVA_TOPICS_KEYWORD,
        message: `${section} - ${fragmentId}`,
      });
    }
    
    if (isMouseLeave) {
      setHoveredKeywordDetails({});
    } else {
      setHoveredKeywordDetails({
        selectedSection: section,
        selectedKeywordId: keywordId,
        data: getSelectedKeyword(section, keywordId),
      });
    }
  };

  const getSelectedKeyword = (selectedSection, selectedKeywordId) => {
    if (!selectedSection) return {};

    const keywordsSection = find(filteredKeywords, ({ type }) => type === selectedSection);
    return find(
      keywordsSection.keywords,
      ({ actualWord: keywordId }) => keywordId === selectedKeywordId
    );
  };

  const timelinesHeight = calcTimelinesHeight(
    mergedData.timelineData,
    isAgentsTimelinesOpen,
    isCustomersTimelinesOpen,
    isOnlyAgentsRecorded
  );


  const shouldShowReadOnlyStatusBar = !isEditable && !shouldHideReadOnlyStatusBar;
  return (
    <InfraProvider
      t={t}
      tAbs={tAbs}
      tDefault={tDefault}
      trackEvent={trackEvent}
      trackError={trackError}
      orgUrl={orgUrl}
      orgId={orgId}
      userId={userId}
      tagsProps={tagsProps}
      ExtraComponents={ExtraComponents}
      clearCache={clearCache}
      isEditable={isEditable}
      setLeavingPagePromptMessage={setLeavingPagePromptMessage}
      userName={userName}
      tenantId={tenantId}
      orgLCID={orgLCID}
      CreateTaskComponent={CreateTaskComponent}
      CreateFeedbackComponent={CreateFeedbackComponent}
      shouldHideParticipantCrmDetails={shouldHideParticipantCrmDetails}
      shouldHideComments={shouldHideComments}
      shouldHideExecSummaryTour={shouldHideExecSummaryTour}
      isViva={isViva}
      highlightsInternalMeetingLearnMoreLink={highlightsInternalMeetingLearnMoreLink}
      isStorageTypeDataverseOnly={isStorageTypeDataverseOnly}
      crmActivity={crmActivity}
    >
      <ErrorBoundary
        fallback={
          <AppError
            title={t(CALL_STRINGS.CALL_ERROR_TITLE)}
            subtitle={t(CALL_STRINGS.CALL_ERROR_SUBTITLE)}
          />
        }
      >
        <CallPageContainer>
          {!shouldHideMainHeader && (
            <Header
              subject={summary.subject}
              startDate={summary.startDate}
              duration={durationInSecond}
              BackButton={ExtraComponents.BackButton}
              currentTimeToCopy={soundState.isPlaying ? 0 : soundState.value}
            />
          )}
          <MainContent shouldHideMainHeader={shouldHideMainHeader}>
            {!isInfoMessageHidden && <StatusBar infoMessage={infoMessage} setIsInfoMessageHidden={setIsInfoMessageHidden}/> }
            <StatusBar
              isLoading={isLoading}
              isLoadingError={isLoadingError}
              isReadOnly={shouldShowReadOnlyStatusBar}
              isDeleteInsightsFailed={isDeleteInsightsFailed}
              setDeleteInsightsFailed={setIsDeleteInsightsFailed}
            />
            <ContentContainer
              isStatusBarDisplayed={isLoading || isLoadingError || shouldShowReadOnlyStatusBar}
              isInfoMessageBarDisplayed={!!infoMessage && !isInfoMessageHidden}
              isSmallPlayer={isSmallPlayer}
              timelinesHeight={timelinesHeight}
            >
              <SidebarContainer>
                <SidePivot
                  data={mergedData}
                  conversationId={mergedData.conversationId}
                  keywords={filteredKeywords}
                  isTopicsLpcComponentReady={isTopicsLpcComponentReady}
                  selectedSegment={selectedSegment}
                  isLoading={isLoading && !isLoadingError}
                  selectedKeyword={selectedKeywordDetails}
                  onKeywordClicked={onKeywordClicked}
                  onKeywordHover={onKeywordHover}
                  onSelectActionItem={onSelectActionItem}
                  onHoverActionItem={onHoverActionItem}
                  clearSelectedSegment={clearSelectedSegment}
                  shouldHideMainHeader={shouldHideMainHeader}
                  duration={durationInSecond}
                  isExecutiveSummaryEnabled={isExecutiveSummaryEnabled}
                  onSelectSuggestion={onSelectSuggestion}
                  setNewParticipantDetails={addUpdatedParticipant}
                  isOnlyAgentsRecorded={isOnlyAgentsRecorded}
                  pivotItemsOrder={tabsOrder}
                  avatars={avatars}
                />
              </SidebarContainer>
              <TranscriptPanelContainer>
                <Transcript
                  transcript={markedTranscript}
                  participants={mergedData.participants}
                  translatedTranscript={markedTranslatedTranscript}
                  isCommentsLoading={isCommentsLoading}
                  keywords={keywords}
                  actionItems={actionItems}
                  selectedKeyword={selectedKeywordDetails.data}
                  selectedActionItem={selectedActionItem}
                  selectedSuggestion={selectedSuggestion}
                  comments={comments}
                  currentTime={soundState.value}
                  duration={durationInSecond}
                  isPlaying={soundState.isPlaying}
                  setSelectedTranscriptRow={setSelectedTranscriptRow}
                  onAddComment={onAddComment}
                  onUpdateComment={onUpdateComment}
                  onDeleteComment={onDeleteComment}
                  avatars={avatars}
                  onDeleteInsightsClick={deleteInsightsHandler}
                />
              </TranscriptPanelContainer>
            </ContentContainer>
            <PlayerContainer isSmallPlayer={isSmallPlayer} timelinesHeight={timelinesHeight}>
              <TogglePlayerSize
                isSmallPlayer={isSmallPlayer}
                onClick={() => setIsSmallPlayer(!isSmallPlayer)}
              >
                <TogglePlayerSizeIcon
                  data-testid="toggle-player-size-icon"
                  isSmallPlayer={isSmallPlayer}
                  ariaLabel={ariaLabelToggleTimeline}
                />
              </TogglePlayerSize>
              <AudioPlayer
                isAudioEnabled={mergedData.timelineData.isPlaybackSupported}
                url={callStreamUrl}
                initialCurrentTime={initialCurrentTime}
                isSmallPlayer={isSmallPlayer}
                onLoadAudioError={(err) => {
                  setLoadAudioError(true);
                  trackError({ actionOn: TRACK_IDS.LOAD_CALL_AUDIO, message: JSON.stringify(err) });
                }}
                onStateChange={setSoundState}
                requestHowlerDataFromIframe={requestHowlerDataFromIframe}
                renderTimeline={RenderTimeline}
                defaultDuration={durationInSecond}
                selectedKeyword={selectedKeywordDetails.data}
                selectedActionItem={selectedActionItem}
                selectedSuggestion={selectedSuggestion}
                selectedTranscriptRow={selectedTranscriptRow}
                comments={comments}
                timelineProps={{
                  height: timelinesHeight,
                  timelineData: mergedData.timelineData,
                  participants: mergedData.participants,
                  keywordInstances: get(selectedKeywordDetails.data, 'instances', []),
                  hoveredKeywordInstances: get(hoveredKeywordDetails.data, 'instances', []),
                  selectedActionItem,
                  comments: transformCommentsToTimelineSchema(comments, transcript),
                  hoveredActionItem,
                  selectedSpeakerId: get(selectedKeywordDetails.data, 'speakerId', []),
                  hoveredSpeakerId: get(hoveredKeywordDetails.data, 'speakerId', []),
                  selectedSegment,
                  segmentation: segmentWithTime,
                  onSegmentSelected,
                  isAgentsTimelinesOpen,
                  isCustomersTimelinesOpen,
                  toggleAgentsTimelines,
                  toggleCustomersTimelines,
                  isOnlyAgentsRecorded,
                  avatars,
                }}
              />
            </PlayerContainer>
          </MainContent>
        </CallPageContainer>
      </ErrorBoundary>
    </InfraProvider>
  );
};
