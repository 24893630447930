import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import some from 'lodash/some';
import { Nav } from '@fluentui/react';
import { userRoles, useVar, FEATURES } from 'ci-common-ui';
import useLocale from 'hooks/use-locale';
import { useAppNavigation, useAppLocation } from 'hooks/use-app-navigation';
import useIsUnmountRef from 'hooks/use-is-unmount-ref';
import { appContext, getIsOnboarded, getIsCustomerServiceMode, getIsTrialMode, getIsOrgStorageTypeDvOnly } from 'utils/app-context/app-context';
import DataCacheService from 'services/DataCacheService';
import YourTeam from './components/Tabs/YourTeam/YourTeam';
import ConversationTracking from './components/Tabs/ConversationContent/ConversationContent';
import ConversationTrackingTrial from './components/Tabs/ConversationContentTrial/ConversationContentTrial';
import DataSource from './components/Tabs/DataSource/DataSource';
import Privacy from './components/Tabs/Privacy/Privacy';
import LicenseUsage from './components/Tabs/LicenseUsage/LicenseUsageWrapper';

import {
  Root, NavContainer, NavHeader, TabContainer,
} from './StyledSettingsModal';

import { settingsModalTabs } from '../../constants/modals';

import UploadCalls from './components/Tabs/UploadCalls/UploadCalls';
import { customerServiceMessageTypes } from '../../services/auth-service';
import { eventAction, trackEvent } from '../../services/telemetry-service/telemetry-service';
import { TRACK_IDS } from '../../constants/tracking';
import { joinTrackDelimiter } from '../../utils/string/string';
import { QUERY_PARAMS } from '../../constants/urls';
import { componentLoadingTimeService } from '../../services/component-loading-time-service/component-loading-time-service';
import {useAppState} from '../../providers/AppStateProvider';
import { isFeatureEnabled } from "../../utils/app-context/feature-flighting";

const HideOnState = {
  Unprovisioned: 'Unprovisioned',
  CustomerService: 'CustomerService',
  TrialSetupNotComplete: 'TrialSetupNotComplete',
  TrialSetupComplete: 'TrialSetupComplete',
  SoftError: 'SoftError',
  HideTabFFEnabled: 'HideTabFFEnabled',
  OrgStorageTypeDvOnly: 'OrgStorageTypeDvOnly'
};

const tabs = [
  {
    name: 'your_team',
    key: settingsModalTabs.yourteam.link,
    component: YourTeam,
    roles: [userRoles.SalesManager, userRoles.SystemAdministrator],
    hideOn: [HideOnState.Unprovisioned, HideOnState.TrialSetupComplete, HideOnState.SoftError, HideOnState.OrgStorageTypeDvOnly],
  },
  {
    name: 'conversation_content',
    key: settingsModalTabs.conversationcontent.link,
    component: ConversationTracking,
    roles: [userRoles.SalesManager, userRoles.SystemAdministrator],
    hideOn: [HideOnState.Unprovisioned, HideOnState.TrialSetupComplete, HideOnState.SoftError, HideOnState.OrgStorageTypeDvOnly],
  },
  {
    name: 'data_source',
    key: settingsModalTabs.datasource.link,
    component: DataSource,
    roles: [userRoles.SalesManager, userRoles.SalesPerson, userRoles.SystemAdministrator],
  },
  {
    name: 'privacy',
    key: settingsModalTabs.privacy.link,
    component: Privacy,
    roles: [userRoles.SystemAdministrator],
    hideOn: [HideOnState.Unprovisioned, HideOnState.TrialSetupComplete, HideOnState.SoftError, HideOnState.OrgStorageTypeDvOnly],
  },
  {
    name: 'license_usage',
    key: settingsModalTabs.licenseusage.link,
    component: LicenseUsage,
    roles: [userRoles.SystemAdministrator],
    hideOn: [HideOnState.Unprovisioned, HideOnState.TrialSetupComplete, HideOnState.SoftError, HideOnState.HideTabFFEnabled, HideOnState.OrgStorageTypeDvOnly],
    HideTabFFName: FEATURES.HIDE_LICENSE_USAGE_FROM_SAA_SETTINGS
  },
  {
    name: 'conversation_content',
    key: settingsModalTabs.conversationcontent.link,
    component: ConversationTrackingTrial,
    roles: [userRoles.SystemAdministrator],
    hideOn: [HideOnState.TrialSetupNotComplete, HideOnState.SoftError, HideOnState.OrgStorageTypeDvOnly],
  },
  {
    name: 'upload_calls',
    key: settingsModalTabs.uploadcalls.link,
    component: UploadCalls,
    roles: [userRoles.SystemAdministrator],
    hideOn: [HideOnState.TrialSetupNotComplete, HideOnState.SoftError, HideOnState.OrgStorageTypeDvOnly],
  },
];

const getAvailableTabs = ({isSoftError, isOrgStorageTypeDvOnly}) => {
  const currentUserRoles = appContext.user.userRoles;
  const hiddenTabConditions = (HideTabFFName) => ({
    [HideOnState.Unprovisioned]: !getIsOnboarded(),
    [HideOnState.CustomerService]: getIsCustomerServiceMode(),
    [HideOnState.TrialSetupNotComplete]: !getIsTrialMode(),
    [HideOnState.TrialSetupComplete]: getIsTrialMode(),
    [HideOnState.SoftError]: isSoftError,
    [HideOnState.HideTabFFEnabled]: HideTabFFName ? isFeatureEnabled(HideTabFFName) : false, // If there is no FF for hiding, we shouldn't hide the tab.
    [HideOnState.OrgStorageTypeDvOnly]: isOrgStorageTypeDvOnly
  });

  return tabs.filter(({ roles, hideOn, HideTabFFName }) => {
    const isRoleValid = some(roles, (role) => currentUserRoles.includes(role));
    const shouldHide = some(hideOn, (key) => hiddenTabConditions(HideTabFFName)[key]);
    return isRoleValid && !shouldHide;
  });
};

let isFirstSettingsModalRender = true;

const startLoadingTimer = () => {
  if (isFirstSettingsModalRender) {
    componentLoadingTimeService.start(TRACK_IDS.SOURCES.SETTINGS_MODAL);
  }
  isFirstSettingsModalRender = false;
};

const SettingsModal = () => {
  startLoadingTimer();
  const { t, tAbs } = useLocale('pages.settings');
  const { appState: { isSoftError } } = useAppState();
  const { location: { pathname: path }, action } = useHistory();
  const [isRefreshNeeded, setIsRefreshNeeded] = useState(false);
  const { navigateTo, navigateBack } = useAppNavigation();
  const { getSearchParam } = useAppLocation();
  const isOrgStorageTypeDvOnly = getIsOrgStorageTypeDvOnly();

  const isClosingModal = useVar(false);

  const closeSettingsModal = () => {
    trackEvent({ action: eventAction.click, actionOn: joinTrackDelimiter([TRACK_IDS.SOURCES.SETTINGS_MODAL, TRACK_IDS.COMMON.CLOSE_BUTTON]) });
    if (getIsCustomerServiceMode()) {
      window.top.postMessage(customerServiceMessageTypes.cICloseSettings, '*');
    }
    isClosingModal.set(true);
    if (action === 'REPLACE') {
      navigateBack();
    } else {
      // this will fix the issue when using deep link to settings
      navigateTo({ path, params: { [QUERY_PARAMS.SETTINGS]: null }, options: { shouldReplace: true } });
    }
  };

  const tab = getSearchParam(QUERY_PARAMS.SETTINGS);

  const availableTabs = getAvailableTabs({isSoftError, isOrgStorageTypeDvOnly}).map((tabObj) => ({
    ...tabObj,
    name: t(`${tabObj.name}.nav_title`),
  }));

  const isUnmountRef = useIsUnmountRef();

  useEffect(() => () => {
    if (isUnmountRef.current) {
      if (isRefreshNeeded) {
        DataCacheService.removeAll();
      }
      if (!isClosingModal.get()) {
        navigateTo({ path, params: { [QUERY_PARAMS.SETTINGS]: null }, options: { shouldReplace: true } });
      }
    }
  }, [isRefreshNeeded]);

  useEffect(() => {
    if (!tab) {
      navigateTo({ path, params: { [QUERY_PARAMS.SETTINGS]: availableTabs[0].key }, options: { shouldReplace: true } });
    }
    return () => { // unMount
      isFirstSettingsModalRender = true;
    };
  }, []);

  if (!tab) {
    return null;
  }

  const onLinkClick = (e, { key }) => {
    navigateTo({ path, params: { [QUERY_PARAMS.SETTINGS]: key }, options: { shouldReplace: true } });
  };

  const { component: TabContent } = availableTabs.find(({ key }) => key === tab) || {};

  return (
    <Root>
      <NavContainer>
        <NavHeader>{tAbs('common.settings')}</NavHeader>
        <Nav groups={[{ links: availableTabs }]} selectedKey={tab} onLinkClick={onLinkClick} />
      </NavContainer>
      <TabContainer>
        {TabContent && <TabContent closeModal={closeSettingsModal} setIsRefreshNeeded={setIsRefreshNeeded} />}
      </TabContainer>
    </Root>
  );
};

export default SettingsModal;
