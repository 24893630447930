import React from 'react';
import styled from 'styled-components';
import { Icon, Link } from '@fluentui/react';
import { exportToCSV } from '../../utils/file/file';
import { colors } from '../../constants/colors';
import { Tooltip } from '../Tooltip';

const iconName = 'ExcelDocument';

export const ExportToCSVButtonContainer = styled(Link)`
  [data-icon-name=${iconName}] {
    font-size: 14px;
    cursor: pointer;
    color: ${colors.Gray130};
    &:hover {
      color: ${colors.Excel};
      }
  }
  height: 19px;
`;

export const ExportToCSVButton = ({ data, fileName, onSuccess, onError, tooltipText, ariaLabel = 'Export to CSV' }) => {
  const onExport = () => {
    const success = exportToCSV(data, fileName);
    if (success && onSuccess) {
      onSuccess();
    } else if (!success && onError) {
      onError();
    }
  };

  return (
    <Tooltip content={tooltipText}>
      <ExportToCSVButtonContainer onClick={onExport} aria-label={ariaLabel}>
        <Icon iconName={iconName} />
      </ExportToCSVButtonContainer>
    </Tooltip>
  );
};
