import React, { useEffect } from 'react';
import { Spinner } from '@fluentui/react';
import useLocale from 'hooks/use-locale';
import { TagListField, DropdownField, SUPPORTED_CALL_LOCALES_SORTED, FEATURES } from 'ci-common-ui';
import { isFeatureEnabled } from 'utils/app-context/feature-flighting';
import { isEmpty } from 'lodash';
import { Section, SectionDescription, SectionText, SectionNote, INPUT_WIDTH, INPUT_MIN_WIDTH, SectionDescriptionRequired } from '../../Modal/StyledModal';
import { componentLoadingTimeService } from '../../../services/component-loading-time-service/component-loading-time-service';
import { TRACK_IDS } from '../../../constants/tracking';

const TAG_MIN_LENGTH = 2;
const dropdownRole = 'combobox';
const ariaLabelObj = {
  dropDownAria: 'call languages'
};

const ConversationContent = ({ isLoadingSettingsData, hideLanguages, supportedLanguages }) => {
  const { t, tAbs } = useLocale('pages.settings.conversation_content');

  useEffect(() => {
    if (!isLoadingSettingsData) {
      componentLoadingTimeService.end(TRACK_IDS.SOURCES.SETTINGS_MODAL);
    }
  }, [isLoadingSettingsData]);

  if (isLoadingSettingsData) {
    return <Spinner />;
  }

  const shouldShowLanguages = !hideLanguages && isFeatureEnabled(FEATURES.CALL_LANGUAGES);

  const languagesOptions = shouldShowLanguages && !isEmpty(supportedLanguages) ? SUPPORTED_CALL_LOCALES_SORTED.filter(({ key }) => supportedLanguages.includes(key)) : SUPPORTED_CALL_LOCALES_SORTED;

  return (
    <div>
      <SectionText>{t('subtitle')}</SectionText>
      <SectionNote>{t('note')}</SectionNote>

      <Section>
        <SectionDescriptionRequired bold>{t('keywords.title')}</SectionDescriptionRequired>
        <SectionDescription>{t('keywords.description')}</SectionDescription>

        <TagListField
          isRequired
          name="keywords.trackers"
          addButtonText={t('keywords.label')}
          addButtonAriaText={t('keywords.label')}
          minLength={TAG_MIN_LENGTH}
          errorMessages={{
            required: tAbs('common.required'),
            errorAlreadyExist: tAbs('components.tag_list.errors.already_exist_error'),
            errorMinLength: tAbs('components.tag_list.errors.min_length_error', { numberOfChars: TAG_MIN_LENGTH }) }}
        />
      </Section>

      <Section>
        <SectionDescriptionRequired bold capitalize>{t('competitors.title')}</SectionDescriptionRequired>
        <SectionDescription>{t('competitors.description')}</SectionDescription>

        <TagListField
          isRequired
          name="keywords.competitors"
          addButtonText={t('competitors.label')}
          addButtonAriaText={t('competitors.label')}
          minLength={TAG_MIN_LENGTH}
          errorMessages={{
            required: tAbs('common.required'),
            errorAlreadyExist: tAbs('components.tag_list.errors.already_exist_error'),
            errorMinLength: tAbs('components.tag_list.errors.min_length_error', { numberOfChars: TAG_MIN_LENGTH }) }}
        />
      </Section>

      {shouldShowLanguages && (
      <Section>
        <SectionDescription bold>{t('languages.title')}</SectionDescription>
        <SectionDescription maxWidth={INPUT_WIDTH}>{t('languages.description')}</SectionDescription>

        <DropdownField
          name="keywords.languages"
          placeholder={t('languages.label')}
          options={languagesOptions}
          multiSelect
          role={dropdownRole}
          ariaLabel={ariaLabelObj.dropDownAria}
          isLoading={isLoadingSettingsData}
          width={INPUT_WIDTH}
          minWidth={INPUT_MIN_WIDTH}
          errorMessages={{ required: tAbs('common.required') }}
        />

      </Section>
      )}

    </div>
  );
};

export default ConversationContent;
