import React from 'react';
import { get, noop } from 'lodash';
import { CRM_ENTITY_TYPES, entities } from '../../../../constants/crm-entities';
import { HeaderTime } from '../Header';
import {
  Container,
  Section,
  SectionTitle,
  TitleContainer,
  Title,
  OnlyAgent,
  CallTagsContainer,
} from './StyledOverview';
import { Participants } from '../Participants/Participants';
import { useInfra } from '../../../../providers/InfraProvider';
import { CALL_STRINGS } from '../../strings';
import { CallTags } from '../CallTags';
import { Copy } from '../Copy';
import { getCrmEntityLink } from '../../../../utils/crm-links';

const defaultTagsProps = { hideTagsComponent: false, onTagsChange: noop };

const createCopyLink = (crm, orgUrl) => {
  const activity = crm[CRM_ENTITY_TYPES.APPOINTMENT_ACTIVITY]
    ? {
        name: entities[CRM_ENTITY_TYPES.APPOINTMENT_ACTIVITY].crmEntityName,
        id: get(crm, `${CRM_ENTITY_TYPES.APPOINTMENT_ACTIVITY}.id`),
      }
    : {
        name: entities[CRM_ENTITY_TYPES.PHONECALL_ACTIVITY].crmEntityName,
        id: get(crm, `${CRM_ENTITY_TYPES.PHONECALL_ACTIVITY}.id`),
      };

  if (!get(activity, 'id')) {
    return null;
  }

  return getCrmEntityLink(orgUrl, activity.name, activity.id);
};

export const Overview = ({
  data,
  id,
  participants,
  shouldHideMainHeader,
  isViva,
  duration,
  isOnlyAgentsRecorded,
  setNewParticipantDetails,
  avatars,
}) => {
  const { t, ExtraComponents = {}, tagsProps = defaultTagsProps, orgUrl, crmActivity } = useInfra();
  const { EditableCrmEntitiesLinks } = ExtraComponents;

  const { startDate, subject, crm } = data;
  const copyLink = createCopyLink(crm, orgUrl) ?? (crmActivity && getCrmEntityLink(orgUrl, crmActivity.type, crmActivity.id));

  return (
    <Container data-testid="overviewTab">
      {shouldHideMainHeader && (
        <Section flex>
          <TitleContainer>
            <Title>{subject}</Title>
            <HeaderTime startDate={startDate} duration={duration} />
          </TitleContainer>
          {copyLink && (
            <Copy title={subject} link={copyLink} linkText={t(CALL_STRINGS.COPY_CALL_TITLE)} />
          )}
        </Section>
      )}

      {EditableCrmEntitiesLinks}

      {tagsProps.hideTagsComponent ? null : (
        <CallTagsContainer marginBottom={15}>
          <SectionTitle>{t(CALL_STRINGS.TAGS)}</SectionTitle>
          <CallTags
            tags={get(data, 'tags')}
            conversationId={id}
            onTagsChange={tagsProps.onTagsChange}
          />
        </CallTagsContainer>
      )}

      <Section marginBottom={15}>
        <SectionTitle>
          {t(isViva ? CALL_STRINGS.PARTICIPANTS : CALL_STRINGS.ATTENDEES)}
          {isOnlyAgentsRecorded && <OnlyAgent>{t(CALL_STRINGS.SELLER_RECORDING_ONLY)}</OnlyAgent>}
        </SectionTitle>
        <Participants
          participants={participants}
          conversationId={id}
          setNewParticipantDetails={setNewParticipantDetails}
          avatars={avatars}
        />
      </Section>
    </Container>
  );
};
