import React from 'react';
import { isFunction } from 'lodash';
import { Container } from './StyledLinkCell';
import { Tooltip } from '../../../Tooltip';

export const LinkCell = ({ data, row, column }) => {
  const isClickAble = column && !!column.onCellClick;
  const isDisabled = column && isFunction(column.disabled) ? column.disabled(row) : column && column.disabled;
  const isClickEnabled = isClickAble && !isDisabled;

  const onClick = (e) => {
    if (isClickEnabled) {
      e.stopPropagation();
      column.onCellClick(column, row);
    }
  };

  return (
    <Container isClickAble={isClickEnabled} onClick={onClick}>
      <Tooltip content={data}>
        {data}
      </Tooltip>
    </Container>
  );
};
