import { sumBy, groupBy, isEmpty } from 'lodash';
import { STATUS_NAMES } from 'pages/SystemMonitoringPage/constants';
import { dateFromServer } from 'utils/date/date';
import { dataTransformer } from '../../utils/error-state/error-state';

const calcPercent = (data, totalData, statusConditionFunc) => Math.round((100 / totalData) * sumBy(data, ({ fileStatus, total }) => (statusConditionFunc(fileStatus) ? total : 0)));

const transformTotalChartsData = (data) => {
  const totalData = sumBy(data, ({ total }) => total);

  if (!totalData) {
    return null;
  }

  return {
    total: totalData,
    status: {
      [STATUS_NAMES.completed]: calcPercent(data, totalData, (status) => status === 100 || status === 90),
      [STATUS_NAMES.failed]: calcPercent(data, totalData, (status) => status === 0),
      [STATUS_NAMES.inProgress]: calcPercent(data, totalData, (status) => status > 0 && status < 90),
      [STATUS_NAMES.ignored]: calcPercent(data, totalData, (status) => status === -1),
    }
  };
};

const transformCompletionChartsData = (data) => {
  const dataWithLocalDates = data.map((item) => ({ ...item, dateOf: dateFromServer(item.dateOf) }));
  const groups = groupBy(dataWithLocalDates, 'dateOf');

  if (isEmpty(groups)) {
    return null;
  }

  return Object.keys(groups).map((day) => {
    const items = groups[day];
    const total = sumBy(items, 'total');
    const completeItem = items.find(({ fileStatus }) => fileStatus === 100 || fileStatus === 90);
    const completeTotal = completeItem ? completeItem.total : 0;

    return {
      completion: Math.round(100 * ((completeTotal / total) * 100)) / 100,
      day,
    };
  });
};

const systemMonitoringChartsTransformers = {
  total: transformTotalChartsData,
  completion: transformCompletionChartsData,
};

export const transformSystemMonitoringCharts = (data) => (
  dataTransformer('systemMonitoring', systemMonitoringChartsTransformers, data)
);
