import React, { useState, useRef } from 'react';
import { ENTER_KEY_CODE } from '../../constants/key-codes';
import { ARIA_ROLES } from '../../constants/roles-list';
import { ToastContainer, Toast } from './StyledButtonWithToast';

const TOAST_TIMEOUT = 3000;

export const ButtonWithToast = ({ button, toastText }) => {
  const [isVisible, setIsVisible] = useState(false);
  const timeoutId = useRef();
  
  const showToast = () => {
    setIsVisible(true);
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      setIsVisible(false);
    }, TOAST_TIMEOUT);
  }

  const handleEnterClick = (e) => {
    if (e.key === ENTER_KEY_CODE) {
      showToast();
    }
  };

  return (
    <>
      <ToastContainer onClick={showToast} onKeyDown={handleEnterClick} role={ARIA_ROLES.NONE} data-testid='button-toast-container'>{button}</ToastContainer>
      {isVisible && <Toast data-testid='button-toast'>{toastText}</Toast>}
    </>
  )
}
