import * as config from 'constants/config';

export async function loadNPSShouldDisplay(puid) {
  // eslint-disable-next-line no-undef
  return fetch(`${config.npsEndpoint}/display/salesinsights/?puid=${puid}`).then(
    (result) => result.json()
  );
}

export function loadNPSRenderEndpoint(puid) {
  return `${config.npsEndpoint}/render/salesinsights/?puid=${puid}`;
}
