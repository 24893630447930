// @flow
import React from 'react';
import noop from 'lodash/noop';
import { Field } from 'react-final-form';
import { Dropdown, Spinner } from '@fluentui/react';
import { requiredValidate, requiredArrayValidate } from '../validation';
import { setStyle } from './CommonStyles';
import { getAriaLabelForDropdown } from './utils';

const defaultAriaLabel = 'dropdown field';

const spinnerStyle = {
  marginTop: 5,
};

export const DropdownField = ({
  name, label, subLabel, onRenderLabel, placeholder, options, multiSelect, isRequired, isDisabled, isLoading, success, customError, width, minWidth, errorMessages, role = 'listbox', ariaLabel
}) => {
  const validate = multiSelect ? requiredArrayValidate : requiredValidate;
  return (
    <Field
      name={name}
      validate={isRequired ? validate : noop}
      render={({ input, meta }) => {
        const onChange = (e, item) => {
          if (item) {
            if (multiSelect) {
              const selectedKeys = input.value;
              input.onChange(item.selected ? [...selectedKeys, item.key] : selectedKeys.filter((key) => key !== item.key));
              input.onBlur();
            } else {
              input.onChange(item.key);
            }
          }
        };

        return (
          <Dropdown
            aria-busy
            multiSelect={multiSelect}
            label={label}
            subLabel={subLabel}
            onRenderLabel={onRenderLabel}
            placeholder={placeholder}
            name={input.name}
            data-testid={input.name}
            role={role}
            required={isRequired}
            disabled={isDisabled || isLoading}
            defaultSelectedKey={!multiSelect ? input.value : undefined}
            defaultSelectedKeys={multiSelect ? input.value : undefined}
            selectedKey={!multiSelect ? input.value : undefined}
            selectedKeys={multiSelect ? input.value : undefined}
            onChange={onChange}
            onBlur={input.onBlur}
            onFocus={input.onFocus}
            ariaLabel={ariaLabel || defaultAriaLabel}
            options={options}
            errorMessage={customError || (meta.touched && meta.error && errorMessages[meta.error])}
            styles={setStyle({ width, minWidth, success })}
            onRenderCaretDown={isLoading ? () => <Spinner style={spinnerStyle} /> : undefined}
            aria-label={`${ariaLabel || ''} ${getAriaLabelForDropdown(options, input, multiSelect)}`}
          />
        );
      }}
    />
  );
};
