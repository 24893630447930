import styled from 'styled-components';
import { rtl } from '../../utils/styles/rtl';
import { colors, hexToRGBA } from '../../constants/colors';
import { FlexRow } from '../../utils/styles/StyledUtils';
import { AUTOMATION_SELECTORS, dataAutomation } from '../../constants/selectors';
import { IconWrapper, InformationIcon } from '../InfoIcon/InfoIcon';

export const KPIContainer = styled.div.attrs({
  [dataAutomation]: AUTOMATION_SELECTORS.KPI
})`
  max-width: 110px;
  display: flex;
  flex-direction: column;
  margin: ${({ sidesMargin }) => typeof sidesMargin === 'number' ? `${sidesMargin}px` : sidesMargin};
  margin-top: 0;
  margin-bottom: 0;

  ${InformationIcon} {
    font-size: 10px;
    cursor: default;
    vertical-align: middle;
    
  }
  ${IconWrapper}{
    ${rtl`margin-left: 4px;`}
  }
`;

export const Label = styled.div`
  height: 32px;
  font-size: 12px;
  color: ${({ theme }) => hexToRGBA(theme.colors.Black, 0.62)};
  letter-spacing: 0.4px;
`;

export const ValueContainer = styled(FlexRow)`
  align-items: baseline;
`;

export const Value = styled.div`
  font-size: 20px;
  margin-bottom: 2px;
`;

export const Format = styled.div`
  font-size: 12px;
  ${rtl`margin-left: 2px;`}
`;

export const BottomLabel = styled.div`
  font-size: 10px;
  color: ${hexToRGBA(colors.Black, 0.62)};
  margin-top: 2px;
`;
