import styled from 'styled-components';
import { FontSizes } from '@uifabric/fluent-theme';
import { FlexRow, FlexRowCentered, colors } from 'ci-common-ui';
import { AvatarPersona } from '../AvatarPersona/AvatarPersona';

export const Card = styled.div`
  background: ${colors.White};
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  border-radius: 4px;
`;

export const Container = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px;
`;

export const SellerAvatar = styled(AvatarPersona)`
  flex-shrink: 0;
`;

export const SellerTitle = styled.div`
  font-size: ${FontSizes.size24};
`;

export const SellerDropdownContainerStyled = styled(FlexRowCentered)`
`;
