import React from 'react';
import { Form, TextField } from 'ci-common-ui';
import useLocale from 'hooks/use-locale';
import { Container, FormStyled, Actions, RemoveButton } from './StyledOpportunityDetails';

const OpportunityDetails = ({ opportunity, removeOpportunity }) => {
  const { t, tAbs } = useLocale('pages.call.sidebar.related_opportunity');

  const onRemove = () => {
    removeOpportunity();
  };

  return (
    <Container>
      <Form
        initialValues={opportunity}
        autocomplete="off"
        render={() => (
          <FormStyled noValidate>
            <TextField isReadOnly label={tAbs('common.topic')} name="name" />
            <TextField isReadOnly label={tAbs('common.crm_entity_types.contact')} name="contact" />
            <TextField isReadOnly label={tAbs('common.crm_entity_types.account')} name="account" />
            <TextField isReadOnly label={t('amount')} name="amount" type="string" prefix="$" />
            <TextField isReadOnly label={t('revenue')} name="revenue" type="string" prefix="$" />
            <TextField isReadOnly label={t('close_date')} name="closeDate" />
            <Actions>
              <RemoveButton text={t('remove_opportunity')} onClick={onRemove} />
            </Actions>
          </FormStyled>
        )}
      />

    </Container>
  );
};
export default OpportunityDetails;
