import { colors } from '../../../constants/colors';
import { isDirectionRTL } from '../../../utils/dom/dom';

export default ({ type }) => {
  const isRTL = isDirectionRTL();
  return ({
    chart: {
      type,
      zoomType: 'x',
      spacing: [8, 0, 0, 0],
      style: {
        fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
        fontSize: '14px',
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: null,
      },
      lineWidth: 1,
      opposite: isRTL,
    },
    xAxis: {
      reversed: isRTL,
      labels: {
        autoRotation: false,
        style: {
          fontSize: '14px',
        },
      },
    },
    accessibility: {
      screenReaderSection: {
        beforeChartFormat: '<div></div>'
      }
    },
    legend: {
      align: isRTL ? 'right' : 'left',
      verticalAlign: 'top',
      rtl: isRTL,
      margin: 12,
      itemStyle: {
        fontSize: '14px',
        fontWeight: null
      },
      symbolRadius: 3,
      symbolHeight: 6,
      symbolWidth: 6,
    },
    plotOptions: {
      [type]: {
        showInLegend: true,
        dataLabels: {
          style: {
            color: colors.Black,
            fontWeight: 'normal',
            textOutline: 'none',
            fontSize: '14px',
          },
        },
        events: {
          legendItemClick: () => false
        },
        point: {
          events: {
            legendItemClick: () => false
          },
        }
      },
    },
    title: {
      text: undefined,
    },
    tooltip: {
      style: {
        direction: isRTL ? 'rtl' : 'ltr',
        textAlign: isRTL ? 'right' : 'left',
      },
    },
  });
};
