import combineConfigs from '../../combineConfigs';
import tooltip from '../../tooltip/tooltip';
import seriesMapper from '../../series/seriesMapper';
import xAxisCategoriesMap from '../../xAxis/xAxisCategoriesMap';
import xAxisLabels from '../../xAxis/xAxisLabels';
import yAxisLabels from '../../yAxis/yAxisLabels';
import noYAxisLine from '../../yAxis/noYAxisLine';
import xAxisGridLine from '../../xAxis/xAxisGridLine';

const type = 'area';

export default ({
  data,
  seriesParams,
  categoryField,
  categoryFieldType,
  xTickInterval,
  yTickAmount,
  xAxisFormatter,
  yAxisFormatter,
  stacking,
  allowZero,
}) => combineConfigs(
  {
    type,
    data,
    seriesParams,
    xAxisFormatter,
    yAxisFormatter,
    categoryField,
    categoryFieldType,
    withMarker: false,
    allowZero,
    tooltipOptions: {
      getProps: ({ series: { name }, y: value }) => ({ name, value: yAxisFormatter({ value }) }),
      getTitle: ({ x: value }) => xAxisFormatter({ value }),
      withHeader: true,
      withBullet: true,
    },
  },
  [
    xAxisLabels,
    yAxisLabels,
    noYAxisLine,
    xAxisGridLine,
    seriesMapper,
    xAxisCategoriesMap,
    tooltip,
    {
      type,
      xAxis: {
        tickInterval: xTickInterval,
      },
      yAxis: {
        tickAmount: yTickAmount,
        max: 100
      },
      legend: {
        reversed: true,
      },
      plotOptions: {
        area: {
          stacking: stacking || 'percent',
          marker: {
            symbol: 'circle',
          },
        },
      },
    },
  ],
);
