import styled from 'styled-components';
import { colors } from '../../../../constants/colors';
import { rtl } from '../../../../utils/styles/rtl';

export const Label = styled.div`
  color: ${colors.Gray160};
  font-weight: 600;
  ${({ isRequired }) => isRequired && `
    &:after {
      content: " *";
      color: rgb(164, 38, 44);
      ${rtl`padding-right: 12px;`}
    }
  `}
`;

export const SubLabel = styled.div`
  color: ${colors.Gray130};
  font-size: 12px;
  margin-bottom: 4px;
`;
