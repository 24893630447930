import React, { useState } from 'react';
import { Callout } from '@fluentui/react';
import { Container } from './StyledCrmRecordLookup';
import SearchView from './components/SearchView/SearchView';
import NewRecordTypeSelectorView from './components/NewRecordTypeSelectorView/NewRecordTypeSelectorView';

export const CrmRecordLookup = ({ entityType, targetRef, initialSearchTerm, onRecordChosen, onDismiss, getSearchResults, searchTermMinLength, typesAllowedToBeCreated, createNewPhonecallAndRelateExistingCola }) => {
  const [isNewRecordTypeSelectorOpen, setIsNewRecordTypeSelectorOpen] = useState(false);

  const newRecordClicked = () => {
    setIsNewRecordTypeSelectorOpen(true);
  };

  return (
    <Callout
      isBeakVisible={false}
      gapSpace={0}
      target={targetRef}
      onDismiss={onDismiss}
      hideOverflow
    >
      <Container>
        {
          isNewRecordTypeSelectorOpen ? (
            <NewRecordTypeSelectorView
              onBackClicked={() => setIsNewRecordTypeSelectorOpen(false)}
              createNewPhonecallAndRelateExistingCola={createNewPhonecallAndRelateExistingCola}
              typesAllowedToBeCreated={typesAllowedToBeCreated}
            />
          ) : (
            <SearchView
              entityType={entityType}
              initialSearchTerm={initialSearchTerm}
              onRecordChosen={onRecordChosen}
              newRecordClicked={newRecordClicked}
              getSearchResults={getSearchResults}
              searchTermMinLength={searchTermMinLength}
              shouldFocusOnMount
            />
          )
        }
      </Container>
    </Callout>
  );
};
