import styled from 'styled-components';
import { deviceSizes } from '../../../../utils/styles/device-sizes';
import { ellipsisCss, FlexRow, StyledText } from '../../../../utils/styles/StyledUtils';

export const Container = styled(FlexRow)`
  margin: 10px 0;
  max-width: 100%;
  ${deviceSizes.smallMobile`
    flex-wrap: wrap;
  `}
`;

export const tooltipHostStyles = {
  root: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
};

export const MainTitle = styled(StyledText).attrs({
  multiline: true,
})`
  ${ellipsisCss};
  font-size: 24px;
  font-weight: 600;
  margin: 2px 10px;
`;

export const Date = styled(StyledText).attrs({
  multiline: true,
})`
  ${ellipsisCss};
  color: ${({ theme }) => theme.colors.Gray130};
  font-size: 12px;
  margin-top: 10px;
  flex-shrink: 0;
`;

export const Time = styled(Date)`
  direction: ltr;
`;

export const Separator = styled(Time)`
  margin-left: 5px;
  margin-right: 5px;
`;

export const HeaderTimeWrapper = styled(FlexRow)`
`;
