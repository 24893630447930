import styled from 'styled-components';
import { FontSizes } from '@uifabric/fluent-theme';
import { rtl, colors } from 'ci-common-ui';
import Page from '../components/Page/Page';
import { PageSectionContainer } from '../components/PageSection/StyledPageSection';

export const HomePageContentContainer = styled(Page)`
  max-width: 1090px;
  ${PageSectionContainer}:first-of-type {
    margin-bottom: 60px;
  }
`;

export const HomePageContainer = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
`;

export const Title = styled.span`
  white-space: normal;
  font-size: ${FontSizes.size32};
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  ${rtl`margin-right`}: 8px;
`;

export const DemoDataPill = styled.span`
  background-color: ${colors.Gray30};
  color: ${colors.Gray160};
  border-radius: 2px;
  padding: 2px 8px;
  margin-left: 5px;
  position: relative;
  bottom: 4px;
`;
