import styled from 'styled-components';
import { StyledText, StyledFontIcon } from '../../utils/styles/StyledUtils';
import { colors } from '../../constants/colors';

export const TrendContainer = styled(StyledText)`
  color: ${({ value }) => (value === 0 ? colors.Gray90 : value > 0 ? colors.DynamicsGreen : colors.Red10)};
`;

export const Percentage = styled(StyledText)`
  font-size: 14px;
`;

export const TrendIcon = styled(StyledFontIcon).attrs(({ value }) => ({
  iconName: value > 0 ? 'TriangleSolidUp12' : 'TriangleSolidDown12'
}))`
  font-size: 9px;
  margin-right: 4px;
`;
