import numeral from 'numeral';
import { reduce, max, isNumber } from 'lodash';

export const formatNumber = (num, format) => numeral(num).format(format);
export const toNumber = (str) => numeral(str).value();

// Percentage
export const PERCENTAGE_FORMAT = '0%';
const PERCENTAGE_TOTAL = 100;

export const formatPercentage = (num) => numeral(num / 100).format(PERCENTAGE_FORMAT);
export const formatFloatPercentage = (num) => numeral(num).format(PERCENTAGE_FORMAT);

export const roundPercentages = (numbers, skipComplement) => {
  let totalSum = reduce(numbers, (sum, number) => sum + Math.round(number), 0);
  const newNumbers = numbers.concat([]).map((n) => Math.round(n));

  if (skipComplement || totalSum === PERCENTAGE_TOTAL) {
    return newNumbers;
  }

  const shouldIncrease = totalSum < PERCENTAGE_TOTAL;
  const shouldDecrease = totalSum > PERCENTAGE_TOTAL;
  const toAdd = shouldDecrease ? -1 : 1;
  let i = numbers.indexOf(max(numbers)); // start with the biggest original number

  while (totalSum !== PERCENTAGE_TOTAL) {
    const shouldChange = !((shouldDecrease && newNumbers[i] === 0) || (shouldIncrease && newNumbers[i] === PERCENTAGE_TOTAL)); // a number shouldn't change to below 0 or above 100
    if (shouldChange) {
      newNumbers[i] += toAdd;
      totalSum += toAdd;
    }
    i = i === (newNumbers.length - 1) ? 0 : i + 1; // i++ or new cycle
  }

  return newNumbers;
};

export const roundPercentagesProps = (data, percentagePropsKeys, isFloat) => {
  if (!data) {
    return data;
  }

  let skipComplement = false;
  const numbers = percentagePropsKeys.map((key) => {
    if (!isNumber(data[key])) {
      skipComplement = true;
    }
    return data[key] * (isFloat ? 100 : 1);
  });
  const res = roundPercentages(numbers, skipComplement);
  const roundedPercentageProps = reduce(percentagePropsKeys, (obj, key, i) => ({ ...obj, [key]: res[i] }), {});
  return {
    ...data,
    ...roundedPercentageProps
  };
};

// Round
export const formatRound = (num) => Math.round(num);

export function numberWithCommas(num) {
  return numeral(num).format('0,0.[0]');
}

export const KILO_MILLION_BILLION_FORMAT = '0a';
export const formatKiloMillionBillion = (num) => numeral(num).format(KILO_MILLION_BILLION_FORMAT);
