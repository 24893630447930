import React from 'react';
import { Callout, PersonaSize, DirectionalHint } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { map, get, has, size, noop } from 'lodash';
import { appContext, getIsDemoUser } from 'utils/app-context/app-context';
import { EMPTY_GUID, crmLinksUtils, entities, CRM_ENTITY_TYPES, useToggler, FlexColumn, Tooltip } from 'ci-common-ui';
import {
  getEntityIcon, IconAndNameContainer, Name, TooltipContentContainer, PlusMoreButton, ContcatsTitle,
  TooltipSubtitle, TooltipTitle, RemoveButton, Records, Record, ContactPersona, PersonaWrapper, RecordWrapper
} from './StyledCrmEntitiesLinks';
import useLocale from '../../hooks/use-locale';

const { openCrmEntity } = crmLinksUtils;

const mapEntitiesToIds = ({
  crmActivityType,
  callPhoneCallCrmId,
  callAccountCrmId,
  callContactCrmId,
  callLeadCrmId,
  callOpportunityCrmId,
  contactName,
  opportunityName,
  leadName,
  accountName,
  phoneCallActivitySubject,
}) => [
  // Even if crmActivityType === 'appointment', server stored the id under callPhoneCallCrmId (it's not a UI typo bug)
  { type: CRM_ENTITY_TYPES[`${crmActivityType === 'appointment' ? 'APPOINTMENT' : 'PHONECALL'}_ACTIVITY`], id: callPhoneCallCrmId, name: phoneCallActivitySubject },
  { type: CRM_ENTITY_TYPES.OPPORTUNITY, id: callOpportunityCrmId, name: opportunityName },
  { type: CRM_ENTITY_TYPES.LEAD, id: callLeadCrmId, name: leadName },
  { type: CRM_ENTITY_TYPES.ACCOUNT, id: callAccountCrmId, name: accountName },
  { type: CRM_ENTITY_TYPES.CONTACT, id: callContactCrmId, name: contactName },
];

const mapNewEntitiesToIds = (crmEntities) => {
  const activityType = CRM_ENTITY_TYPES[`${has(crmEntities, CRM_ENTITY_TYPES.APPOINTMENT_ACTIVITY) ? 'APPOINTMENT' : 'PHONECALL'}_ACTIVITY`];
  return [
    { type: activityType, id: get(crmEntities[activityType], 'id'), name: get(crmEntities[activityType], 'name') },
    { type: CRM_ENTITY_TYPES.OPPORTUNITY, id: get(crmEntities[CRM_ENTITY_TYPES.OPPORTUNITY], 'id'), name: get(crmEntities[CRM_ENTITY_TYPES.OPPORTUNITY], 'name') },
    { type: CRM_ENTITY_TYPES.LEAD, id: get(crmEntities[CRM_ENTITY_TYPES.LEAD], 'id'), name: get(crmEntities[CRM_ENTITY_TYPES.LEAD], 'name') },
    { type: CRM_ENTITY_TYPES.ACCOUNT, id: get(crmEntities[CRM_ENTITY_TYPES.ACCOUNT], '[0].id'), name: get(crmEntities[CRM_ENTITY_TYPES.ACCOUNT], '[0].name') },
    { type: CRM_ENTITY_TYPES.CONTACT, id: get(crmEntities[CRM_ENTITY_TYPES.CONTACT], '[0].id'), name: get(crmEntities[CRM_ENTITY_TYPES.CONTACT], '[0].name') }
  ];
};

const ToolTipContent = ({ type, name }) => {
  const { tAbs } = useLocale();
  return (
    <TooltipContentContainer>
      {name && <TooltipTitle>{name}</TooltipTitle>}
      <TooltipSubtitle>{tAbs(`common.crm_entity_types.${type}`)}</TooltipSubtitle>
    </TooltipContentContainer>
  );
};

const ShowMoreContacts = ({ toggleIsContactListVisible, t, hideNames, allContacts, isContactListVisible, plusMoreContactId }) => (
  <div>
    <PlusMoreButton>{t(`plus_more${hideNames ? '_short' : ''}`, { count: size(allContacts) - 1 })}</PlusMoreButton>
    {isContactListVisible && (
      <Callout role="alertdialog" onDismiss={() => toggleIsContactListVisible(false)} target={`#${plusMoreContactId}`} directionalHint={DirectionalHint.rightCenter} isBeakVisible={false}>
        <ContcatsTitle>{t('contacts_title')}</ContcatsTitle>
        <FlexColumn>
          {allContacts.map(({ name, id }) => (
            <PersonaWrapper key={id}>
              <ContactPersona text={name} size={PersonaSize.size32} onClick={id ? () => openCrmEntity(appContext.orgUrl, entities[CRM_ENTITY_TYPES.CONTACT].crmEntityName, id) : noop} />
            </PersonaWrapper>
          ))}
        </FlexColumn>
      </Callout>
    )}
  </div>
);

export default ({ data = {}, isEditMode, removeEntity, isNewSchema, hideContact, hideNames }) => {
  const { t, tAbs, tDefault, tAbsDefault } = useLocale('components.crm_entities_links');
  const [isContactListVisible, toggleIsContactListVisible] = useToggler(false);

  const onLinkClick = (e, type, id, isClickable) => {
    e.stopPropagation();
    if (isClickable) {
      openCrmEntity(appContext.orgUrl, entities[type].crmEntityName, id);
    }
  };

  const allContacts = map(data[CRM_ENTITY_TYPES.CONTACT], ({ id, name }) => ({ type: CRM_ENTITY_TYPES.CONTACT, id, name }));
  const processedData = isNewSchema ? mapNewEntitiesToIds(data) : mapEntitiesToIds(data);

  return (
    <Records isEditMode={isEditMode}>
      {map(processedData, ({ type, id, name }) => {
        const { tooltipContent, isClickable, shouldShowName, ariaLabel, EntityIcon, isEmpty, shouldShowPlusMoreContacts } = getRecordDetails(type, id, isEditMode, name, allContacts, tAbs, tDefault, tAbsDefault);
        const plusMoreContactId = useId(`plus-more-contacts-${id}`);
        return (
          hideContact && type === CRM_ENTITY_TYPES.CONTACT ? null : (
            <RecordWrapper shouldShowPlusMoreContacts={shouldShowPlusMoreContacts} onClick={shouldShowPlusMoreContacts ? toggleIsContactListVisible : noop} id={plusMoreContactId} key={type}>
              <Record key={type} isEditMode={isEditMode}>
                <Tooltip content={!shouldShowPlusMoreContacts && tooltipContent}>
                  <IconAndNameContainer
                    isClickable={isClickable}
                    shouldShowName={shouldShowName}
                    onClick={shouldShowPlusMoreContacts ? noop : (e) => onLinkClick(e, type, id, isClickable)}
                    isEditMode={isEditMode}
                    aria-label={ariaLabel}
                    shouldShowPlusMoreContacts={shouldShowPlusMoreContacts}
                  >
                    <EntityIcon />
                    {!hideNames && shouldShowName && <Name isEditMode={isEditMode}>{name}</Name>}
                  </IconAndNameContainer>
                </Tooltip>
                {isEditMode && !isEmpty && (
                <Tooltip content={t('disconnect_entity', { type: entities[type].crmEntityName })}>
                  <RemoveButton onClick={() => removeEntity(type, id)} />
                </Tooltip>
                )}
              </Record>
              {shouldShowPlusMoreContacts && (
              <ShowMoreContacts
                toggleIsContactListVisible={toggleIsContactListVisible}
                t={t}
                hideNames={hideNames}
                allContacts={allContacts}
                isContactListVisible={isContactListVisible}
                plusMoreContactId={plusMoreContactId}
              />
              )}
            </RecordWrapper>
          )
        );
      })}
    </Records>
  );
};

function getRecordDetails(type, id, isEditMode, name, allContacts, tAbs, tDefault, tAbsDefault) {
  const EntityIcon = getEntityIcon(entities[type].IconComponent);
  const isEmpty = !id || id === EMPTY_GUID;
  const isClickable = !isEmpty;
  const isAccountOrContact = [CRM_ENTITY_TYPES.ACCOUNT, CRM_ENTITY_TYPES.CONTACT].includes(type);
  const shouldShowName = (isEditMode && !isEmpty) || (isAccountOrContact && name && (getIsDemoUser() || !isEmpty));
  const isActivity = [CRM_ENTITY_TYPES.PHONECALL_ACTIVITY, CRM_ENTITY_TYPES.APPOINTMENT_ACTIVITY].includes(type);
  const isContact = [CRM_ENTITY_TYPES.CONTACT].includes(type);
  const isMultipleContacts = size(allContacts) > 1;
  const shouldShowPlusMoreContacts = isContact && isMultipleContacts;
  const prefixAndType = { prefix: isActivity ? 'an' : entities[type].prefix, type: tAbs(`common.crm_entity_types.${isActivity ? 'activity' : type}`) };
  const ariaLabel = shouldShowName || isClickable ? `${tAbsDefault(`common.crm_entity_types.${type}`)} name=${name}` : tAbsDefault('common.crm_entities_links_no_entity', prefixAndType).toLowerCase();
  const tooltipContent = shouldShowName || isClickable ? <ToolTipContent type={type} name={name} /> : tAbs('common.crm_entities_links_no_entity', prefixAndType).toLowerCase();
  return { tooltipContent, isClickable, shouldShowName, ariaLabel, EntityIcon, isEmpty, shouldShowPlusMoreContacts };
}
