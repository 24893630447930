import styled from 'styled-components';
import { Card } from '../../../../components/SellerBar/StyledSellerBar';

export const Container = styled.div`
  & > * {
    margin-bottom: 30px;
  }
  ${Card}{
    margin-top: 44px;
    margin-bottom: 15px;
  }
`;
