import React from 'react';
import useLocale from 'hooks/use-locale';
import { PaginatedTable } from 'ci-common-ui';
import withEmptyState from '../WithEmptyState/with-empty-state';

const PaginatedTableWrapper = ({ columns, ...rest }) => {
  const { t, tAbs } = useLocale('components.table.pagination');
  const translatedColumns = columns.map((c) => ({ ...c, name: tAbs(c.name) }));

  return (
    <PaginatedTable
      columns={translatedColumns}
      firstLabel={t('first_label')}
      previousLabel={t('previous_label')}
      nextLabel={t('next_label')}
      lastLabel={t('last_label')}
      outOfLabel={t('out_of')}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};

export default withEmptyState(PaginatedTableWrapper);
