import activityIcon from '../assets/images/crm-entities/activities.svg';
import accountIcon from '../assets/images/crm-entities/accounts.svg';
import contactIcon from '../assets/images/crm-entities/contacts.svg';
import leadIcon from '../assets/images/crm-entities/leads.svg';
import opportunityIcon from '../assets/images/crm-entities/opportunities.svg';

export const CUSTOM_CRM_ENTITY_TYPES = {
  MSDYN_SCICONVERSATION: 'msdyn_sciconversation',
  MSDYN_RECORDINGS: 'msdyn_recording'
};


export const CRM_ENTITY_TYPES = {
  OPPORTUNITY: 'opportunity',
  PHONECALL_ACTIVITY: 'phonecall_activity',
  APPOINTMENT_ACTIVITY: 'appointment_activity',
  ACCOUNT: 'account',
  CONTACT: 'contact',
  SYSTEM_USER: 'systemuser',
  LEAD: 'lead',
  CAMPAIGN: 'campaign',
  CONTRACT: 'contract',
  ENTITLEMENT_TEMPLATE: 'entitlement_template',
  CAMPAIGN_ACTIVITY: 'campaign_activity',
  ENTITLEMENT: 'entitlement',
  INVOICE: 'invoice',
  INCIDENT: 'incident',
  KNOWLEDGE_ARTICLE: 'knowledge_article',
  QUOTE: 'quote',
  SITE: 'site',
  SALES_ORDER: 'sales_order',
  PLAYBOOK: 'playbook',
  ANNOTATION: 'annotation'
};

export const entities = {
  [CRM_ENTITY_TYPES.PHONECALL_ACTIVITY]: {
    prefix: 'a',
    IconComponent: activityIcon,
    crmEntityName: 'phonecall',
    crmEntityNamePlural: 'phonecalls'
  },
  [CRM_ENTITY_TYPES.APPOINTMENT_ACTIVITY]: {
    prefix: 'an',
    IconComponent: activityIcon,
    crmEntityName: 'appointment',
    crmEntityNamePlural: 'appointments'
  },
  [CRM_ENTITY_TYPES.ACCOUNT]: {
    prefix: 'an',
    IconComponent: accountIcon,
    crmEntityName: 'account',
    crmEntityNamePlural: 'accounts'
  },
  [CRM_ENTITY_TYPES.CONTACT]: {
    prefix: 'a',
    IconComponent: contactIcon,
    crmEntityName: 'contact',
    crmEntityNamePlural: 'contacts'
  },
  [CRM_ENTITY_TYPES.LEAD]: {
    prefix: 'a',
    IconComponent: leadIcon,
    crmEntityName: 'lead',
    crmEntityNamePlural: 'leads'
  },
  [CRM_ENTITY_TYPES.OPPORTUNITY]: {
    prefix: 'an',
    IconComponent: opportunityIcon,
    crmEntityName: 'opportunity',
    crmEntityNamePlural: 'opportunities'
  },
  [CRM_ENTITY_TYPES.CAMPAIGN]: {
    crmEntityName: 'campaign',
    fontIconCode: 'E789',
  },
  [CRM_ENTITY_TYPES.CONTRACT]: {
    crmEntityName: 'contract',
    fontIconCode: 'EB95'
  },
  [CRM_ENTITY_TYPES.ENTITLEMENT_TEMPLATE]: {
    crmEntityName: 'entitlementtemplate',
    fontIconCode: 'EFF7'
  },
  [CRM_ENTITY_TYPES.CAMPAIGN_ACTIVITY]: {
    crmEntityName: 'campaignactivity',
    fontIconCode: 'EFF7'
  },
  [CRM_ENTITY_TYPES.ENTITLEMENT]: {
    crmEntityName: 'entitlement',
    fontIconCode: 'EB95'
  },
  [CRM_ENTITY_TYPES.INVOICE]: {
    crmEntityName: 'invoice',
    fontIconCode: 'EFE4'
  },
  [CRM_ENTITY_TYPES.KNOWLEDGE_ARTICLE]: {
    crmEntityName: 'knowledgearticle',
    fontIconCode: 'F000'
  },
  [CRM_ENTITY_TYPES.QUOTE]: {
    crmEntityName: 'quote',
    fontIconCode: 'F067'
  },
  [CRM_ENTITY_TYPES.SITE]: {
    crmEntityName: 'site',
    fontIconCode: 'E731'
  },
  [CRM_ENTITY_TYPES.INCIDENT]: {
    fontIconCode: 'E7B9',
    crmEntityName: 'incident',
    crmEntityNamePlural: 'incidents'
  },
  [CRM_ENTITY_TYPES.SALES_ORDER]: {
    fontIconCode: 'F03B'
  },
  [CRM_ENTITY_TYPES.PLAYBOOK]: {
    fontIconCode: 'EFF7'
  },
  [CUSTOM_CRM_ENTITY_TYPES.MSDYN_SCICONVERSATION]: {
    crmEntityName: 'msdyn_sciconversation',
    crmEntityNamePlural: 'msdyn_sciconversations'
  },
  [CUSTOM_CRM_ENTITY_TYPES.MSDYN_RECORDINGS]: {
    crmEntityName: 'msdyn_recording',
    crmEntityNamePlural: 'msdyn_recordings'
  },
};

export const CRM_LOOKUP_TYPES_TO_ENTITY_TYPE = {
  systemuser: CRM_ENTITY_TYPES.CONTACT,
  account: CRM_ENTITY_TYPES.ACCOUNT,
  campaign: CRM_ENTITY_TYPES.CAMPAIGN,
  contact: CRM_ENTITY_TYPES.CONTACT,
  entitlements: CRM_ENTITY_TYPES.ENTITLEMENT,
  incidents: CRM_ENTITY_TYPES.INCIDENT,
  invoice: CRM_ENTITY_TYPES.INVOICE,
  knowledgearticle: CRM_ENTITY_TYPES.KNOWLEDGE_ARTICLE,
  lead: CRM_ENTITY_TYPES.LEAD,
  opportunity: CRM_ENTITY_TYPES.OPPORTUNITY,
  msdyn_playbookinstance: CRM_ENTITY_TYPES.PLAYBOOK,
  quote: CRM_ENTITY_TYPES.QUOTE,
  salesorder: CRM_ENTITY_TYPES.SALES_ORDER,
  phonecall_activity: CRM_ENTITY_TYPES.PHONECALL_ACTIVITY,
  appointment_activity: CRM_ENTITY_TYPES.APPOINTMENT_ACTIVITY
};

export const LEAD_AND_OPPORTUNITY = [CRM_ENTITY_TYPES.LEAD, CRM_ENTITY_TYPES.OPPORTUNITY];
