export const eventLevels = {
  critical: 1,
  error: 2,
  warning: 3,
  info: 4,
  verbose: 5,
};

export const eventAction = {
  click: 'CLICK',
  hover: 'HOVER',
  pageView: 'PAGE_VIEW',
  pageLoaded: 'PAGE_LOADED',
  pageTimeSpent: 'PAGE_TIME_SPENT',
  themeChanged: 'THEME_CHANGED',
  error: 'ERROR',
  emptyState: 'EMPTY_STATE',
  link: 'LINK',
  info: 'INFO',
  sendUsageEvent: 'SEND_USAGE_EVENT',
  measure: 'MEASURE',
  warning: 'WARNING',
};

export const DEFAULT_TRACK_DELIMITER = '_';

export const DISABLE_BACKGROUND_LOGS = 'disableBackgroundLogs';