import React from 'react';
import { AppErrorWrapper, AppErrorContainer, Title, Subtitle, ErrorImg, NoPermissionImg } from './StyledAppError';

const errorsMap = {
  general: ErrorImg,
  noPermission: NoPermissionImg,
};

export const AppError = ({ title, subtitle, type = 'general' }) => {
  const ErrorImage = errorsMap[type];
  return (
    <AppErrorWrapper>
      <AppErrorContainer>
        <ErrorImage />
        <Title>{title}</Title>
        { subtitle && <Subtitle>{subtitle}</Subtitle> }
      </AppErrorContainer>
    </AppErrorWrapper>
  );
};
