/**
 * This file is a copy of the original createInstrumenter.js file (with its imports) from the "@msfast/search-instrumentation/lib/createInstrumenter".
 * The "@msfast/search-instrumentation" package depended on "request" package via a set of middleware dependencies.
 * The "request" package is a deprecated and vulnerable package. The "@msfast/search-instrumentation" package doesn't have a newer version that doesn't depend on "request".
 * We used only the "@msfast/search-instrumentation/lib/createInstrumenter" file from the "@msfast/search-instrumentation" package.
 * So we removed the "@msfast/search-instrumentation" package and copied the "@msfast/search-instrumentation/lib/createInstrumenter" file to this location.
 */

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
function createDispatcher() {
    var listeners = [];
    return {
        register: function (callback) {
            return "" + (listeners.push(callback) - 1);
        },
        unregister: function (id) {
            listeners[parseInt(id, 10)] = 0;
        },
        dispatch: function (payload) {
            listeners.forEach(function (listener) { return listener && listener(payload); });
        },
    };
}
function generateGuid() {
    var s4 = function () {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    };
    return (s4() +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        s4() +
        s4());
}
var marks = [
    "markAppBoot",
    "markHeaderBoot",
    "markSbInteractive",
    "markSbReady",
    "markZeroQueryReady",
    "markSearchLayoutMount",
    "markSearchLayoutRenderStart",
];
var markDependencies = {
    markAppBoot: [],
    markHeaderBoot: ["markAppBoot"],
    markSbInteractive: ["markHeaderBoot"],
    markSbReady: ["markSbInteractive"],
    markZeroQueryReady: ["markSbReady"],
    SbQuerySubmitted: ["markSbReady"],
    SerpRouteChange: [],
    SerpFetchStart: ["SbQuerySubmitted"],
    SerpAnswerFetchDone: ["SerpFetchStart"],
    SerpFetchDone: ["SerpFetchStart"],
    SerpAnswersRenderDone: [
        "markAppBoot",
        "SbQuerySubmitted",
        "SerpRouteChange",
        "SerpFetchStart",
        "SerpAnswerFetchDone",
    ],
    SerpDataReady: ["SerpFetchStart", "SerpFetchDone"],
    SerpRenderDone: [
        "markAppBoot",
        "SbQuerySubmitted",
        "markSearchLayoutMount",
        "markSearchLayoutRenderStart",
        "SerpPrefetchStart",
        "SerpPrefetchDone",
        "SerpConfigStart",
        "SerpConfigDone",
        "SerpRouteChange",
        "SerpFetchStart",
        "SerpDataReady",
        "SerpFetchDone",
        "SerpFullPageLoad",
        "SerpInPlaceNavigation",
        "RouterHistoryChange",
    ],
    markSearchLayoutMount: ["SbQuerySubmitted"],
    markSearchLayoutRenderStart: ["SbQuerySubmitted", "markSearchLayoutMount"],
    SerpPrefetchStart: [],
    SerpPrefetchDone: ["SerpPrefetchStart"],
    SerpConfigStart: [],
    SerpConfigDone: ["SerpConfigStart"],
    SerpFullPageLoad: [],
    SerpInPlaceNavigation: [],
    RouterHistoryChange: [],
    SetRefinersFromDeepLinkQueryStart: [],
    SetRefinersFromDeepLinkQueryDone: [],
    CreateCustomAggregationsStart: [],
    CreateCustomAggregationsDone: [],
    UpdateAvailableRefinersStart: [],
    UpdateAvailableRefinersDone: [],
    UpdateRefinersStart: [],
    ClearRefinersStart: [],
    SerpOldRefinerButtonClick: [],
    SerpRefinersCheck: ["UpdateAvailableRefinersStart"],
    SerpRefinersRenderDone: [
        "SerpConfigStart",
        "SerpConfigDone",
        "SerpFetchStart",
        "SerpRouteChange",
        "SerpDataReady",
        "SerpFetchDone",
        "UpdateRefinersStart",
        "ClearRefinersStart",
        "SetRefinersFromDeepLinkQueryStart",
        "SetRefinersFromDeepLinkQueryDone",
        "CreateCustomAggregationsStart",
        "CreateCustomAggregationsDone",
        "UpdateAvailableRefinersStart",
        "UpdateAvailableRefinersDone",
        "SerpOldRefinerButtonClick",
    ],
};
var getNewConversationManager = function () {
    var activeConversation;
    var lastSubmittedConversation;
    var getNewConversation = function () {
        var logicalSearchIds = {};
        var submitted = false;
        var createLogicalSearchIdInternal = function (context) {
            var logicalSearchId = generateGuid();
            logicalSearchIds[context] = logicalSearchId;
            return logicalSearchId;
        };
        return {
            conversationId: generateGuid(),
            creationTime: Date.now(),
            getSubmittedState: function () {
                return submitted;
            },
            submit: function () {
                submitted = true;
            },
            createLogicalSearchId: createLogicalSearchIdInternal,
            getLogicalSearchId: function (context) {
                return (logicalSearchIds[context] || createLogicalSearchIdInternal(context));
            },
        };
    };
    var startConversationInternal = function () {
        // Store last conversation if it has been submitted
        if (activeConversation && activeConversation.getSubmittedState()) {
            lastSubmittedConversation = activeConversation;
        }
        var conversation = getNewConversation();
        activeConversation = conversation;
        return conversation;
    };
    return {
        startConversation: startConversationInternal,
        getActiveConversation: function () {
            if (!activeConversation || activeConversation.getSubmittedState()) {
                return startConversationInternal();
            }
            return activeConversation;
        },
        getLastSubmittedConversation: function () {
            // Return current conversation if it has been submitted
            if (activeConversation && activeConversation.getSubmittedState()) {
                return activeConversation;
            }
            // In some cases we are have not yet submitted any conversations,
            // for instance on a direct search url navigation. In these cases we create
            // and return a new conversation in the submitted state.
            if (!lastSubmittedConversation) {
                var conversation = getNewConversation();
                conversation.submit();
                lastSubmittedConversation = conversation;
            }
            return lastSubmittedConversation;
        },
    };
};
export default function createInstrumenter(initialProps) {
    var currentProps = initialProps;
    var doneMarks = {};
    var performanceMarks = marks.reduce(function (partialPerformanceMarks, mark, i) {
        partialPerformanceMarks[mark] = function () {
            if (!doneMarks[mark]) {
                doneMarks[mark] = true;
                currentProps.perf.mark(mark);
            }
            if (i) {
                try {
                    currentProps.perf.measure(marks[i - 1] + "-" + mark, marks[i - 1], mark);
                }
                catch (_a) {
                    /* not supported */
                }
            }
        };
        return partialPerformanceMarks;
    }, {});
    // Variables to keep track of startTime of tracked events
    var startTimeTracker = {};
    return __assign(__assign(__assign({}, createDispatcher()), performanceMarks), { conversationManager: getNewConversationManager(), props: currentProps, setProps: function (newProps) {
            this.props = currentProps = __assign(__assign({}, this.props), newProps);
            this.dispatch(newProps);
            return currentProps;
        },
        mark: function (eventName) {
            var measureList = [];
            currentProps.perf.mark(eventName);
            var eventDependencies = markDependencies[eventName] || [];
            for (var _i = 0, eventDependencies_1 = eventDependencies; _i < eventDependencies_1.length; _i++) {
                var dependency = eventDependencies_1[_i];
                try {
                    var measureName = dependency + "-" + eventName;
                    currentProps.perf.measure(measureName, dependency, eventName);
                    var measure = currentProps.perf.getEntriesByName(measureName).pop();
                    if (measure) {
                        // If the current event startTime is later than the previously tracked
                        measure.startTime > (startTimeTracker[eventName] || 0) &&
                        measureList.push(measure);
                    }
                }
                catch (_a) {
                    /* Guard against measure requests where the prereq has not been fulfilled */
                }
            }
            // Update the tracker startTime for next events
            if (measureList.length > 0) {
                // Extracting the metric with the max of startTime
                var lastEventStartTime = Math.max.apply(Math, measureList.map(function (perfMeasure) { return perfMeasure.startTime; }));
                startTimeTracker[eventName] = lastEventStartTime;
            }
            return measureList;
        } });
}
