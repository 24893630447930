import React from 'react';
import { PersonaCell, colors } from 'ci-common-ui';
import useLocale from 'hooks/use-locale';

const PersonaWithTopPerformerCell = ({ data, row, column }) => {
  const { t } = useLocale('common');

  return (
    <PersonaCell
      data={data}
      column={column}
      row={{
        ...row,
        icon: row.isTopPerformerAgent && 'FavoriteStarFill',
        iconColor: colors.Gray130,
        iconTooltip: t('top_performer')
      }}
    />
  );
};

export default PersonaWithTopPerformerCell;
