import React from 'react';
import { ActionButton } from '@fluentui/react';
import useLocale from '../../hooks/use-locale';
import { Container } from './StyledBackButton';

const BackButton = ({ text, onClick }) => {
  const { isRTL } = useLocale();

  return (
    <Container>
      <ActionButton
        aria-label={text}
        iconProps={{ iconName: isRTL ? 'Forward' : 'Back' }}
        onClick={onClick}
      >
        {text}
      </ActionButton>
    </Container>
  );
};
export default BackButton;
