import styled from 'styled-components';
import { rtl, FlexRow, Pill } from 'ci-common-ui';

export const StyledPillsCell = styled.div`
  max-width: 100px;
`;

export const showMoreIconStyles = {
  root: {
    cursor: 'pointer',
    position: 'relative',
    top: '10px',
  }
};

export const PillsContainer = styled(FlexRow)`
align-items: center;
  ${Pill} {
    ${rtl`margin-right`}: 8px;
    &:last-child {
      margin: 0;
    }
  }
`;
