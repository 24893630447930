import React, { useEffect, useState } from 'react';
import useLocale from 'hooks/use-locale';
import { DropdownField, CheckboxField, Form, TextField, EMPTY_GUID } from 'ci-common-ui';
import { deleteContactDataByContactId } from 'network/rest-services/settings-rest-service';
import { Dialog, DialogFooter, PrimaryButton, DefaultButton } from '@fluentui/react';
import { get } from 'lodash';
import { isValidGuid, joinTrackDelimiter } from 'utils/string/string';
import { Link } from '../../../../../components/Link/Link';
import SettingsForm from '../../SettingsForm/SettingsForm';
import { SaveButton, FormStyled } from './StyledPrivacy';
import {
  TabSection, TabSectionTitle, TabSectionDescription, INPUT_WIDTH, INPUT_MIN_WIDTH
} from '../../../StyledSettingsModal';
import { eventAction, trackEvent } from '../../../../../services/telemetry-service/telemetry-service';
import { TRACK_IDS } from '../../../../../constants/tracking';
import { componentLoadingTimeService } from '../../../../../services/component-loading-time-service/component-loading-time-service';

const DIALOG_WIDTH = 440;
const INVALID_CONTACT_ID_FORMAT = 'invalidContactId';

const DeleteUserDataByContactId = () => {
  const { t, tAbs } = useLocale('pages.settings.privacy');
  const [isOpen, toggleDialog] = useState(false);
  const openDialog = () => toggleDialog(true);
  const closeDialog = () => toggleDialog(false);

  const onDeleteData = (handleSubmit, values, form) => {
    closeDialog();
    handleSubmit(values)
      .then(() => {
        setTimeout(() => form.reset(), 6000);
        trackEvent({ action: eventAction.click, actionOn: joinTrackDelimiter([TRACK_IDS.SOURCES.PRIVACY_TAB, TRACK_IDS.COMMON.DELETE_CONTACT_DATA]), message: get(values, 'contactId') });
      });
  };

  return (
    <TabSection>
      <TabSectionTitle>{t('delete_contact_data.title')}</TabSectionTitle>
      <TabSectionDescription>{t('delete_contact_data.description')}</TabSectionDescription>
      <Form
        onSubmit={async ({ contactId }) => deleteContactDataByContactId(contactId)}
        autocomplete="off"
        render={({ submitSucceeded, form, handleSubmit, submitting, values, submitError, invalid }) => (
          <FormStyled noValidate>
            <TextField
              placeholder={t('delete_contact_data.enter_contact_id', { EMPTY_GUID })}
              width="360px"
              name="contactId"
              validate={(contactId) => !contactId || isValidGuid(contactId) ? null : INVALID_CONTACT_ID_FORMAT}
              errorMessages={{ [INVALID_CONTACT_ID_FORMAT]: t('delete_contact_data.invalid_format') }}
              customError={submitError && t('delete_contact_data.failed')}
              isLoading={submitting}
              success={submitSucceeded}
            />
            <SaveButton onClick={openDialog} text={t('delete_contact_data.delete_data')} disabled={submitting || invalid || !get(values, 'contactId')} />
            <Dialog
              hidden={!isOpen}
              onDismiss={closeDialog}
              minWidth={DIALOG_WIDTH}
              dialogContentProps={{
                title: t('delete_contact_data.dialog_title'),
                subText: tAbs('common.delete_data_message_contact'),
              }}
            >
              <DialogFooter>
                <PrimaryButton onClick={() => onDeleteData(handleSubmit, values, form)} text={tAbs('common.ok')} />
                <DefaultButton onClick={closeDialog} text={tAbs('common.cancel')} />
              </DialogFooter>
            </Dialog>
          </FormStyled>
        )}
      />
    </TabSection>
  );
};

const privacyStatementUrl = 'https://go.microsoft.com/fwlink/?linkid=521839';

const retentionPolicyOptions = [
  { key: 'thirtyDays', text: '30_days' },
  { key: 'ninetyDays', text: '90_days' },
  { key: 'year', text: '1_year' },
  { key: 'twoYears', text: 'num_years', num: 2 },
  { key: 'threeYears', text: 'num_years', num: 3 },
  { key: 'fourYears', text: 'num_years', num: 4 },
  { key: 'fiveYears', text: 'num_years', num: 5 },
  { key: 'sixYears', text: 'num_years', num: 6 },
  { key: 'sevenYears', text: 'num_years', num: 7 },
  { key: 'eightYears', text: 'num_years', num: 8 },
  { key: 'nineYears', text: 'num_years', num: 9 },
  { key: 'tenYears', text: 'num_years', num: 10 },
  { key: 'always', text: 'always' },
];

export const PrivacyContent = ({ isLoadingSettingsData }) => {
  const { t, tAbs } = useLocale('pages.settings.privacy');

  useEffect(() => {
    if (!isLoadingSettingsData) {
      componentLoadingTimeService.end(TRACK_IDS.SOURCES.SETTINGS_MODAL);
    }
  }, [isLoadingSettingsData]);

  return (
    <>
      <TabSection>
        <TabSectionTitle>{t('eyes_on_data.title')}</TabSectionTitle>
        <CheckboxField
          name="isEyesOnData"
          isLoading={isLoadingSettingsData}
          label={t('eyes_on_data.label')}
          errorMessages={{ required: tAbs('common.required') }}
          description={(
            <>
              {t('eyes_on_data.description')}
              <Link href={privacyStatementUrl} target="_blank">{t('eyes_on_data.link')}</Link>
            </>
          )}
        />
      </TabSection>
      <DeleteUserDataByContactId />
      <TabSection>
        <TabSectionTitle>{t('retention.title')}</TabSectionTitle>
        <TabSectionDescription>{t('retention.description')}</TabSectionDescription>
        <DropdownField
          name="retentionPolicy"
          placeholder={t('retention.label')}
          options={retentionPolicyOptions.map(({ key, text, num }) => ({ key, text: tAbs(`common.${text}`, num && { num }) }))}
          isLoading={isLoadingSettingsData}
          width={INPUT_WIDTH}
          minWidth={INPUT_MIN_WIDTH}
          errorMessages={{ required: tAbs('common.required') }}
        />
      </TabSection>
    </>
  );
};

const PrivacyTab = ({ closeModal }) => {
  const { t } = useLocale('pages.settings.privacy');

  return (
    <SettingsForm
      tab="privacy"
      title={t('nav_title')}
      closeModal={closeModal}
      render={PrivacyContent}
    />

  );
};

export default PrivacyTab;
