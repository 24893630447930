import React from 'react';
import { i18next, ErrorBoundary } from 'ci-common-ui';
import NoDataError from '../../pages/components/SectionCard/components/NoDataError/NoDataError';
import { trackError } from '../../services/telemetry-service/telemetry-service';
import { TRACK_IDS } from '../../constants/tracking';
import { getTelemetryErrorMsgObj } from '../../services/telemetry-service/utils';

const CardErrorBoundary = ({ children, cardName }) => (
  <ErrorBoundary
    fallback={(
      <NoDataError
        title={i18next.t('errors.ui_component.title_client_side', { cardName })}
        subtitle={i18next.t('errors.ui_component.subtitle')}
      />
    )}
    onError={async (error, errorInfo) => {
      const message = { cardName, error: await getTelemetryErrorMsgObj(error), errorInfo: await getTelemetryErrorMsgObj(errorInfo, 'componentStack') };
      trackError({ actionOn: TRACK_IDS.COMMON.CARD, message });
    }}
  >
    {children}
  </ErrorBoundary>
);

export default CardErrorBoundary;
