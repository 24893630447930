import flow from 'lodash/flow';
import isNil from 'lodash/isNil';
import React from 'react';
import useLocale from 'hooks/use-locale';
import { Trend } from 'ci-common-ui';
import { orderBy } from 'lodash';
import { ItemContainer, ItemName } from './StyledDelimitedInlineList';

export default ({ data, boldItems, components }) => {
  const { t } = useLocale('common');

  const getTopItemsComponents = (topItemsData) => (
    orderBy(topItemsData, 'trend', 'desc').map(({ name, trend }) => (
      <ItemContainer key={name}>
        <ItemName boldItems={boldItems}>{name}</ItemName>{!isNil(trend) && <>&nbsp;(<Trend value={trend} />)</>}
      </ItemContainer>
    ))
  );

  const addSeparators = (topItemsComponents) => (
    topItemsComponents.map(
      (topItemElement, index) => (
        <span key={topItemElement.key}>{topItemElement}{getSeparator(index, topItemsComponents.length)}</span>
      )
    )
  );

  const getSeparator = (index, totalItems) => {
    if (index === totalItems - 2) {
      return ` ${t('and')} `;
    } if (index === totalItems - 1) {
      return '';
    }
    return ', ';
  };

  if (components) {
    return addSeparators(components);
  }
  return (
    flow(
      getTopItemsComponents,
      addSeparators
    )(data)
  );
};
