import combineConfigs from '../../combineConfigs';
import tooltip from '../../tooltip/tooltip';
import dataLabels from '../../dataLabels/dataLabels';
import centerTitle from '../../title/centerTitle';
import centerSubtitle from '../../title/centerSubtitle';
import { formatKiloMillionBillion, numberWithCommas } from '../../../../../utils/numeric/numeric';
import { getIsMobile } from '../../../../../utils/styles/device-sizes';

const type = 'pie';
const DONUT_SIZE = '75%';
const DATA_LABELS_DISTANCE_DEFAULT = 5;

export const donutKiloTitles = ({ titleText, subtitleText }) => combineConfigs({ titleText, subtitleText },
  [
    donutTitle,
    {
      title: {
        text: `<div title="${`${numberWithCommas(titleText)} ${subtitleText}`}">${formatKiloMillionBillion(titleText)}</div>`,
        useHTML: true
      },
      subtitle: {
        text: subtitleText
      }
    }
  ]);

export const donutTitle = ({ titleText, subtitleText }) => combineConfigs({},
  [
    centerTitle,
    centerSubtitle,
    {
      title: {
        text: titleText,
        style: {
          fontSize: '30px',
        },
        y: 42,
      },
      subtitle: {
        text: subtitleText,
        style: {
          fontSize: '12px',
        },
        y: 50,
      },
      legend: getIsMobile() ? {
        title: {
          text: `${titleText || ''}<br /> ${subtitleText || ''}`
        }
      } : {},
    },
  ],);

export default ({
  data, seriesParams, dataLabelsFormatter, dataLabelsDistance,
}) => combineConfigs(
  {
    type,
    data,
    dataLabelsFormatter: dataLabelsFormatter && ((dataObj) => dataObj.percentage > 0 ? dataLabelsFormatter(dataObj) : null),
    tooltipOptions: {
      getProps: ({ name, percentage }) => ({ name, value: dataLabelsFormatter({ percentage }) }),
    },
  },
  [
    dataLabels,
    tooltip,
    {
      type,
      series: [
        {
          innerSize: DONUT_SIZE,
          colorByPoint: true,
          data: seriesParams.map(({ key, text, color }) => ({
            ...data.find((item) => item.name === key),
            name: text,
            originalName: key,
            color,
          })),
        },
      ],
      legend: {
        reversed: true,
      },
      plotOptions: {
        pie: {
          center: ['50%', '50%'],
          dataLabels: {
            distance: dataLabelsDistance || DATA_LABELS_DISTANCE_DEFAULT,
          },
          slicedOffset: 0
        },
      },
    },
  ],
);
