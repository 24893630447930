import React from 'react';
import styled from 'styled-components';
import { FlexColumn, colors, AppSpinner as CommonAppSpinner } from 'ci-common-ui';
import { pageScrollerAttr } from 'constants/selectors';

const MAIN_TOP_BAR = '50px';

export const AppContainer = styled.div`
  height: ${({ isEmbed }) => isEmbed ? '100%' : `calc(100% - ${MAIN_TOP_BAR})`};
`;

export const CenterContent = styled(FlexColumn).attrs({
  [pageScrollerAttr]: true
})`
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  padding: 30px 14px;
  align-items: center;
  background-color: ${colors.Gray10};
  height: ${({ autoHeight, isEmptyPage }) => (autoHeight && !isEmptyPage) ? 'auto' : '100%'};
`;

export const PageLayout = styled(FlexColumn).attrs({
  as: 'main',
})`
  flex-grow: 1;
  overflow: hidden;
  background-color: ${colors.Gray10};
`;

export const AppSpinner = styled.div.attrs({
  children: <CommonAppSpinner />
})`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
`;
