import styled from 'styled-components';
import { StyledText, deviceSizes } from 'ci-common-ui';

export const OpportunityKpiValue = styled(StyledText)`
    font-weight: 600;
`;

export const OpportunityKpisBar = styled.div`
  margin-top: 52px;
  margin-bottom: 30px;
  
  ${deviceSizes.tablet`
    margin-top: 32px;
  `}
`;
