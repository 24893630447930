// @flow
/* eslint-disable no-useless-escape */
import Bowser from 'bowser';
import get from 'lodash/get';
import { dayJS } from '../../utils/date/date';
import { storageService } from '../storage-service/storage-service';
import { TRACK_IDS } from '../../constants/tracking';
import { DISABLE_BACKGROUND_LOGS } from './consts';

const { browser, os } = Bowser.parse(window.navigator.userAgent);

let defaultEventDetails = {
  UserAgent: window.navigator.userAgent,
  UserInfo_Language: window.navigator.language,
  UserInfo_TimeZone: dayJS().utcOffset(),
  DeviceInfo_BrowserName: browser.name,
  DeviceInfo_BrowserVersion: browser.version,
  DeviceInfo_OsName: os.name,
  DeviceInfo_OsVersion: os.versionName,
  CategoryName: 'browser log',
  FeatureName: undefined,
  HostType: 'browser',
  AppModule: undefined,
  ClientVersion: undefined,
  Origin: window.location.origin,
  Source: undefined,
  ActionOn: undefined,
  Action: undefined,
  Message: undefined,
  Level: undefined
};

const EVENTS_SENDING_INTERVAL = 5000;
export const REMOVED_DATA_STR = 'REMOVED CUSTOMER DATA FROM THIS LOG';
export const EMAIL_MASK = '<MASKED-EMAIL>';
export const PHONE_NUMBER_MASK = '<MASKED-PHONE-NUMBER>';
export const TOKEN_MASK = '<MASKED-TOKEN>';
export const FILTER_MASK = '<MASKED-FILTER>';

let eventsQueue = [];

const sendEventsQueue = (sendEvents) => {
  if (eventsQueue.length) {
    sendEvents(eventsQueue);
    eventsQueue = [];
  }
};

export const initTelemetry = ({ onSendEvents, commonEventDetails, interval = EVENTS_SENDING_INTERVAL }) => {
  defaultEventDetails = {
    ...defaultEventDetails,
    ...commonEventDetails
  };
  setInterval(() => {
    sendEventsQueue(onSendEvents);
  }, interval);
};

export const enqueueEvent = (event) => {
  eventsQueue.push({ ...defaultEventDetails, ...event });
};

export const getCleanMessage = (message) => getMessageWithoutPrivacyData(getMessageWithoutToken(message));

const getMessageWithoutToken = (message) => {
  if (message) {
    try {
      const parsed = typeof message === 'string' ? JSON.parse(message) : message;
      if (get(parsed, 'error.config.headers')) {
        parsed.error.config.headers.Authorization = undefined;
        parsed.error.config.headers.cookie = undefined;
      }
      if (get(parsed, 'error.response.config.headers')) {
        parsed.error.response.config.headers.Authorization = undefined;
        parsed.error.response.config.headers.cookie = undefined;
      }
      if (get(parsed, 'response.config.headers')) {
        parsed.response.config.headers.Authorization = undefined;
        parsed.response.config.headers.cookie = undefined;
      }
      if (get(parsed, 'response.headers')) {
        parsed.response.headers.Authorization = undefined;
        parsed.response.headers.cookie = undefined;
      }
      if (get(parsed, 'config.headers')) {
        parsed.config.headers.Authorization = undefined;
        parsed.config.headers.cookie = undefined;
      }
      return JSON.stringify(parsed);
    } catch (e) {
      return message;
    }
  }
  return message;
};

export const updateCommonEventDetails = (newDetails) => {
  defaultEventDetails = {
    ...defaultEventDetails,
    ...newDetails
  };
};

// PRIVACY DATA IN LOGS
const PROHIBITED_DATA_KEYS = [
  'contact',
  'phoneNumber',
  'contacts',
  'contactName',
  'contactNumber',
  'fullName',
  'email',
  'storageConnectionString',
  'Authorization'
];

export const getMessageWithoutPrivacyData = (message) => {
  try {
    let messageString = typeof message === 'string' ? message : JSON.stringify(message);
    messageString = maskQueryFilter(maskToken(maskPhoneNumber(maskEmailAddresses(messageString)))); // may mutate (decode) original message
    const hasPrivacyData = new RegExp(PROHIBITED_DATA_KEYS.join('|')).test(messageString);
    if (!hasPrivacyData) {
      return messageString;
    }

    const messageAsObject = tryJsonParse(messageString);
    if (!messageAsObject) {
      return messageString; // it's not JSON, no need to remove any key
    }

    removeProhibitedKeyValuesInPlace(messageAsObject);
    return JSON.stringify(messageAsObject);
  } catch (error) {
    return message;
  }
};

export function maskEmailAddresses(message){
  return message.replace(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g, EMAIL_MASK);
}

export function maskPhoneNumber(message) {
  const decodedMessage = decodeURIComponent(message);
  const decodedMessageWithoutPhoneNumbers = decodedMessage.replace(/[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}/gm, PHONE_NUMBER_MASK);
  const messageContainsPhoneNumber = decodedMessageWithoutPhoneNumbers !== decodedMessage;
  return messageContainsPhoneNumber ? decodedMessageWithoutPhoneNumbers : message;
}

export function maskToken(message) {
  return message.replace(/(([a-zA-Z0-9_=]{16,})\.([a-zA-Z0-9_=]{16,})\.([a-zA-Z0-9_\-\+\/=]*))/g, TOKEN_MASK);
}

export function maskQueryFilter(message) {
  return message.replace(/filter=.*/g, FILTER_MASK);
}
/* eslint-disable no-param-reassign */
function removeProhibitedKeyValuesInPlace(obj) {
  if (!isObject(obj)) return;

  Object.keys(obj).forEach((key) => {
    const curr = obj[key];
    if (isKeyProhibited(key)) {
      delete obj[key];
      obj[REMOVED_DATA_STR] = true;
    } else if (isObject(curr)) {
      removeProhibitedKeyValuesInPlace(curr);
    } else if (isArray(curr)) {
      curr.forEach(removeProhibitedKeyValuesInPlace);
    }
  });
}
/* eslint-enable no-param-reassign */

function isKeyProhibited(key) {
  const lowerCaseProhibitedKeys = PROHIBITED_DATA_KEYS.map((prohibitedKey) => prohibitedKey?.toLowerCase());
  return lowerCaseProhibitedKeys?.includes(key?.toLowerCase());
}

function tryJsonParse(str) {
  try {
    return JSON.parse(str);
  } catch {
    return false;
  }
}

function isArray(toTest) { return Array.isArray(toTest); }
function isObject(toTest) { return toTest && !isArray(toTest) && typeof toTest === 'object'; }

export const shouldDisableBackgroundLogs = (actionOn) => {
  const disableBackgroundLogs = storageService.localStorage.getItem(DISABLE_BACKGROUND_LOGS);
  const backgroundLogs = [TRACK_IDS.INVALID_IFRAME_MESSAGE_ORIGIN];

  return disableBackgroundLogs && backgroundLogs.includes(actionOn);
};