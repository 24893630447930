import styled, { css } from 'styled-components';
import { rtl } from '../../utils/styles/rtl';
import { colors, hexToRGBA } from '../../constants/colors';
import { Pagination } from './components/Pagination/Pagination';
import { deviceSizes } from '../../utils/styles/device-sizes';

const highlightedRowCss = css`
  background-color: ${colors.White};
  box-shadow: 0px 0px 1px ${hexToRGBA(colors.Black, 0.16)}, 0px 4px 8px ${hexToRGBA(colors.Black, 0.12)};
`;

export const TableContainer = styled.div`
  .ms-DetailsHeader {
    border-bottom: 1px solid #C8C8C8;
  }
  .ms-DetailsHeader-cellName {
    font-size: 12px;
    color: ${colors.Gray130};
    font-weight: 600;
  }
  .ms-DetailsHeader-cellName::first-letter {
    text-transform: capitalize;
  }
  .ms-DetailsRow {
    border-bottom: 1px solid #EAEAEA;
  }
  .ms-DetailsList, .ms-DetailsHeader, .ms-DetailsRow {
    background-color: transparent;
  }
  .ms-DetailsRow-cell {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 100%;
    display: flex;
    align-items: center;
  }
  ${deviceSizes.tablet`
    .ms-DetailsList { /* Horizontal scroll shadows */
      background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white), linear-gradient(to right, rgb(0 0 0 / 10%), rgba(255,255,255,0)), linear-gradient(to left, rgb(0 0 0 / 10%), rgba(255,255,255,0));
      background-position: left center, right center, left center, right center;
      background-repeat: no-repeat;
      background-color: white;
      background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
      background-attachment: local, local, scroll, scroll;
    }
  `}
`;

export const RowWrapper = styled.div`
  ${({ isFocused }) => isFocused && highlightedRowCss};
  transition: background 0.5s ease-in, box-shadow 0.5s ease-in;

  .show-on-hover {
    opacity: 0
  }

  & > div:focus-within[tabindex="0"] {
    ${highlightedRowCss};
    transition: background 0.5s ease-in, box-shadow 0.5s ease-in;
    .show-on-hover {
      opacity: 1
    }
  }

  &:hover {
    ${highlightedRowCss}
    .show-on-hover {
      opacity: 1
    }
  }
`;

export const DefaultCell = styled.span.attrs((props) => ({
  children: props.data || props.children
}))`
  font-size: 12px;
  color: ${colors.Gray130};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Empty = styled.div`
  text-align: center;
`;

export const Error = styled.div`
  text-align: center;
  color: ${colors.ScarletRed};
`;

export const StyledPagination = styled(Pagination)`
  margin-top: 8px;
`;

export const PaginatedTableContainer = styled.div``;

export const HeaderColumnContainer = styled.div`
  height: 100%;

  ${({ disabled }) => disabled && `
    opacity: 0.4;
    margin: 0;
    ${rtl`margin-right: -8px`};
    ${rtl`margin-left: -12px`};
  `}
`;
