import styled from 'styled-components';
import { Icon } from '@fluentui/react';
import { rtl } from '../../../../../../../../utils/styles/rtl';

export const StyledIcon = styled(Icon)`
  ${rtl`margin-right: 4px;`}
`;

export const FieldContainer = styled.div`
`;
