// @flow

import { omitBy } from 'lodash';
import { storageService } from '../storage-service/storage-service';

const isStorageSupported = Boolean(
  storageService.sessionStorage.isSupported() && storageService.localStorage.isSupported(),
);

const MAX_AGE = 5 * 60000; // 5 minutes
const GARBAGE_COLLECTOR_INTERVAL = 5 * 60000; // 5 minutes

export const defaultCacheOptions = {
  cache: true,
  maxAge: MAX_AGE,
  maxAttempts: Infinity,
  storage: isStorageSupported ? storageService.sessionStorage : window,
  storageKey: '__Cache__',
  nonObjectItem: false
};

export class cacheService {
  options = defaultCacheOptions;

  constructor(options) {
    this.options = {
      ...this.options,
      ...options,
    };
    if (this.usingStorage(this.options)) {
      if (!this.options.storage.getItem(this.options.storageKey)) {
        this.options.storage.setItem(this.options.storageKey, '{}');
      }
    } else {
      this.options.storage[this.options.storageKey] = {};
    }

    const collectGarbage = () => {
      const now = new Date().getTime();
      const calcIsExpired = ({ created, maxAge }) => now - created > maxAge;

      const currentCache = JSON.parse(this.options.storage.getItem(this.options.storageKey));
      const onlyValidCache = omitBy(currentCache, calcIsExpired);
      this.options.storage.setItem(options.storageKey, JSON.stringify(onlyValidCache));
    };

    setInterval(collectGarbage, GARBAGE_COLLECTOR_INTERVAL);
  }

  usingStorage(options = this.options) {
    return isStorageSupported && options.storage !== window;
  }

  getItem(key, options) {
    return this.usingStorage(options)
      ? JSON.parse(options.storage.getItem(options.storageKey))[key]
      : options.storage[options.storageKey] && options.storage[options.storageKey][key];
  }

  setItem(key, value, options) {
    if (this.usingStorage(options)) {
      // sessionStorage or localStorage
      const store = JSON.parse(options.storage.getItem(options.storageKey)) || {};

      store[key] = {
        created: new Date().getTime(),
        attempts: 0,
        value,
        maxAge: options.maxAge
      };

      try {
        options.storage.setItem(options.storageKey, JSON.stringify(store));
      } catch (e) {
        this.removeAll();
      }
    } else {
      // eslint-disable-next-line no-param-reassign
      options.storage[options.storageKey] = options.storage[options.storageKey] || {};
      // eslint-disable-next-line no-param-reassign
      options.storage[options.storageKey][key] = {
        created: new Date().getTime(),
        attempts: 0,
        value,
        maxAge: options.maxAge
      };
    }
  }

  increaseAttempts(key, value, options) {
    if (this.usingStorage(options)) {
      // sessionStorage or localStorage
      const store = JSON.parse(options.storage.getItem(options.storageKey));
      ++store[key].attempts;
      options.storage.setItem(options.storageKey, JSON.stringify(store));
    } else {
      // eslint-disable-next-line no-param-reassign
      options.storage[options.storageKey][key] = {
        created: new Date().getTime(),
        // eslint-disable-next-line no-param-reassign
        attempts: ++options.storage[options.storageKey][key].attempts,
        value,
      };
    }
  }

  get(key, cacheOptions) {
    const options = {
      ...this.options,
      ...cacheOptions,
    };
    if (!options.cache) {
      return null;
    }
    const item = this.getItem(key, options);
    if (item) {
      const now = new Date().getTime();
      if (now - item.created <= options.maxAge && item.attempts + 1 <= options.maxAttempts) {
        this.increaseAttempts(key, item.value, options);
        return options.nonObjectItem ? item.value : { ...item.value };
      }
      this.remove(key, options);
    }
    return null;
  }

  set(key, value, cacheOptions) {
    const options = {
      ...this.options,
      ...cacheOptions,
    };

    this.setItem(key, value, options);
  }

  remove(key, options = this.options) {
    if (this.usingStorage(options)) {
      // sessionStorage or localStorage
      const item = JSON.parse(options.storage.getItem(options.storageKey));
      delete item[key];
      options.storage.setItem(options.storageKey, JSON.stringify(item));
    } else {
      // window
      delete window[options.storageKey][key];
    }
  }

  removeAll() {
    if (this.usingStorage(this.options)) {
      // sessionStorage or localStorage
      this.options.storage.setItem(this.options.storageKey, '{}');
    } else {
      // window
      window[this.options.storageKey] = {};
    }
  }
}
