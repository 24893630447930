import React, { useState } from 'react';
import { ActionButton, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import isEmpty from 'lodash/isEmpty';
import { CommentEditorContainer, NewCommentActions, commentTextFieldStyles, cancelButtonStyles, saveButtonStyles } from './StyledCommentEditor';

const CommentEditor = ({
  onSaveClicked,
  onCancelClicked,
  initialContent,
  saveText,
  cancelText,
  isLoading
}) => {
  const [content, setContent] = useState(initialContent);

  return (
    <CommentEditorContainer>
      <TextField
        multiline
        resizable={false}
        autoAdjustHeight
        styles={commentTextFieldStyles}
        value={content}
        onChange={(event) => setContent(event.target.value)}
        disabled={isLoading}
        autoFocus
      />
      <NewCommentActions>
        {isLoading && <Spinner ariaLive="polite" labelPosition="right" size={SpinnerSize.small} />}
        <ActionButton
          iconProps={{ iconName: 'CheckMark' }}
          onClick={() => onSaveClicked(content)}
          styles={saveButtonStyles}
          disabled={isEmpty(content) || isLoading}
        >
          {saveText}
        </ActionButton>
        <ActionButton
          iconProps={{ iconName: 'Cancel' }}
          onClick={onCancelClicked}
          styles={cancelButtonStyles}
          disabled={isLoading}
        >
          {cancelText}
        </ActionButton>
      </NewCommentActions>
    </CommentEditorContainer>
  );
};

export default CommentEditor;
