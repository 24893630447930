import styled from 'styled-components';
import { rtl } from '../../utils/styles/rtl';
import {
  FlexRow,
  FlexRowCentered,
  FlexColumn,
} from '../../utils/styles/StyledUtils';
import { colors } from '../../constants/colors';

export const CardHeader = styled(FlexRow)`
  justify-content: start;
  width: 100%;
  cursor: default;
  padding-top: 8px;
`;

export const HeaderTextContainer = styled(FlexColumn)`
  justify-content: start;
  align-items: start;
  width: 100%;
  padding-bottom: 8px;
  ${rtl`padding-left: 12px`};
  @media (forced-colors: active) {
    color:inherit;
    forced-color-adjust: none;
  };
`;

export const DotsContainer = styled(FlexColumn)`
  position: absolute;
  top: 16px;
  right: 16px;
  @media (forced-colors: active) {
    background-color:${colors.Gray30};
    forced-color-adjust: none;
  };
`;

export const titleStyle = {
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  color: "inherit",
  display: 'block',
};

export const subTitleStyle = {
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '16px',
  color: colors.Gray130,
  display: 'block',
};

export const CardContainer = styled(FlexColumn)`
  width: 260px;
  height: 140px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  ${({ disabled }) => disabled && `
    pointer-events: none;
  `}
  &:hover {
    background-color: ${colors.Gray30};
  }
  &:active {
    background-color: ${colors.Gray70};
  }
  @media (forced-colors: active) {
    color:inherit;
    forced-color-adjust: none;
  };
`;

export const IconCardParentContainer = styled.div`
    position:relative;
`;

export const CardBody = styled(FlexRow)`
  align-items: start;
  justify-content: start;
  width: 100%;
  padding-top:4px;
  padding-bottom:10px;
  font-size: 16px;
`;

export const CardBodyText = styled(FlexRow)`
  cursor: default;
  @media (forced-colors: active) {
    color:inherit;
    forced-color-adjust: none;
  };
`;

export const bodyTextStyle = {
    color:"inherit"
}

export const BlockedIconContainer = styled(FlexRow)`
  padding-right: 8px;
`;

export const IconContainer = styled(FlexRowCentered)`
`;
