import styled from 'styled-components';
import { ActionButton } from '@fluentui/react';
import { colors } from 'ci-common-ui';

export const SubTitle = styled.div`
  margin-top: 16px;
  margin-bottom: 20px;
`;

export const UploadCallsButton = styled(ActionButton).attrs({
  iconProps: { iconName: 'PublishContent' },
  styles: {
    root: {
      border: `1px solid ${colors.Gray90}`,
      'border-radius': '2px',
      padding: '0 16px 0 12px',
      height: '32px'
    },
    label: {
      'font-weight': '600'
    }
  }
})`
  margin-bottom: 20px;
`;
