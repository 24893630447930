import React from 'react';
import { ErrorBoundary } from 'ci-common-ui';
import PageError from '../../pages/components/PageError/PageError';
import { trackError } from '../../services/telemetry-service/telemetry-service';
import { TRACK_IDS } from '../../constants/tracking';
import { getTelemetryErrorMsgObj } from '../../services/telemetry-service/utils';

const PageErrorBoundary = ({ children }) => (
  <ErrorBoundary
    fallback={<PageError />}
    onError={async (error, errorInfo) => {
      const message = { error: await getTelemetryErrorMsgObj(error), errorInfo: await getTelemetryErrorMsgObj(errorInfo, 'componentStack') };
      trackError({ actionOn: TRACK_IDS.COMMON.PAGE, message });
    }}
  >
    {children}
  </ErrorBoundary>
);

export default PageErrorBoundary;
