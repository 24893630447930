import React from 'react';
import { Spinner } from '@fluentui/react';
import useLocale from 'hooks/use-locale';
import { TabSectionTitle, TabSectionDescription } from '../../../StyledSettingsModal';
import Chart from '../../../../../components/Chart/Chart';
import { ChartContainer, TextContainer, Root } from './StyledLicenseUsage';
import { chartConfig } from './chart-config';
import { TRACK_IDS } from '../../../../../constants/tracking';

export const calculateHours = ({ callProcessingCapacityInSeconds, callProcessingUsageInSeconds }) => {
  const capacityHours = (callProcessingCapacityInSeconds / 3600).toFixed(0);
  const usageHours = (callProcessingUsageInSeconds / 3600).toFixed(0);
  const availableHours = capacityHours - usageHours;
  return { capacityHours, usageHours, availableHours };
};

const LicenseUsageTab = ({ licenseUsage, isLoading, error }) => {
  const { t, tAbs, isRTL } = useLocale('pages.settings.license_usage');

  const hours = calculateHours(licenseUsage || {});

  if (isLoading) {
    return <Spinner />;
  }
  if (error) {
    return <div>Error</div>;
  }

  return (
    <Root>
      <TextContainer>
        <TabSectionTitle>{t('title', { hours: hours.availableHours })}</TabSectionTitle>
        <TabSectionDescription>{t('description1')}</TabSectionDescription>
        <TabSectionDescription>{t('description2')}</TabSectionDescription>
      </TextContainer>
      <ChartContainer>
        <Chart config={chartConfig(hours, tAbs, isRTL)} trackId={TRACK_IDS.COMMON.LICENSE_USAGE} />
      </ChartContainer>
    </Root>
  );
};

export default LicenseUsageTab;
