import React from "react";
import {   highlightTypes } from './highlightHelper/highlight-helper';
import {
    RowText,
    StyledCurrentSearch,
    StyledKeyword,
    StyledActionItems,
    StyledSelectedActionItems,
    StyledPlayback,
    StyledSearch,
    StyledSelectedKeyword,
    StyledDefaultText,
  } from './StyledTranscriptItem';

export function TranscriptItemContent({sections, rowId}) {
    const getStyledRow = (type) => {
        switch (type) {
          case highlightTypes.PLAYBACK: {
            return StyledPlayback;
          }
          case highlightTypes.CURRENT_SEARCH: {
            return StyledCurrentSearch;
          }
          case highlightTypes.SEARCH: {
            return StyledSearch;
          }
          case highlightTypes.SELECTED_KEYWORD: {
            return StyledSelectedKeyword;
          }
          case highlightTypes.KEYWORD: {
            return StyledKeyword;
          }
          case highlightTypes.SELECTED_ACTION_ITEMS: {
            return StyledSelectedActionItems;
          }
          case highlightTypes.ACTION_ITEMS: {
            return StyledActionItems;
          }
          case highlightTypes.NONE:
          default: {
            return StyledDefaultText;
          }
        }
      };
      

    return (
        <RowText>
        {
            sections.map(({ text, type, start }) => {
            const StyledRow = getStyledRow(type);
            return <StyledRow data-testid={rowId} key={`${rowId}-${start}`} multiline>{text}</StyledRow>;
            })
        }
        </RowText>
    )
}

