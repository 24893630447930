import React from 'react';
import useLocale from 'hooks/use-locale';
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { Form } from 'ci-common-ui';
import { StepContainer, StepFooter, FormWrapper, SubmitNote } from '../StyledWizard';

const StepForm = ({
  onSubmit, initialValues, children, back, nextButtonText, submitNote
}) => {
  const { t } = useLocale('common');

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      autocomplete="off"
      render={({ handleSubmit, invalid }) => (
        <FormWrapper onSubmit={handleSubmit} noValidate>
          <StepContainer>
            {children}
          </StepContainer>

          <StepFooter>
            {back && <DefaultButton text={t('back')} onClick={back} />}
            <PrimaryButton type="submit" disabled={invalid} text={nextButtonText || t('next')} />
            <SubmitNote>{submitNote}</SubmitNote>
          </StepFooter>
        </FormWrapper>
      )}
    />
  );
};

export default StepForm;
