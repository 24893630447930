import { DirectionalHint, TeachingBubble } from '@fluentui/react';
import React, { useState } from 'react';
import { eventAction} from '../../../../services/telemetry-service'
import { useInfra } from '../../../../providers/InfraProvider';
import { CALL_STRINGS } from '../../strings';
import { TRACK_IDS } from '../../tracking';
import { storageService } from '../../../../services/storage-service/storage-service';
import { jsonParse } from '../../../../utils/object';

export const HIDE_EXEC_SUMMARY_FEATURE_TOUR = 'HIDE_EXEC_SUMMARY_FEATURE_TOUR'; // exported for tests only
export const EXEC_SUMMARY_FEATURE_TOUR = 'EXEC_SUMMARY_FEATURE_TOUR'; // exported for tests only

const getShouldHideTour = () => {
    const shouldHideTour = storageService.localStorage.getItem(HIDE_EXEC_SUMMARY_FEATURE_TOUR);
    const defaultValue = true;
    return jsonParse(shouldHideTour, defaultValue);
  };

export const ExecSummaryFeatureTour = ({ executiveSummarySuggestionsLength, goToExecSummaryTab, goToOverviewTab }) => {
    const { t, trackEvent, shouldHideExecSummaryTour } = useInfra();
    const isEmptyExecSummaryHighlightSuggestions = !executiveSummarySuggestionsLength;
    const shouldShowFeatureTour = !shouldHideExecSummaryTour && !getShouldHideTour() && !isEmptyExecSummaryHighlightSuggestions;
    const [execSummaryTourActiveIndex, setExecSummaryTourActiveIndex] = useState(0);
    const [isTourVisible, setIsTourVisible] = useState(shouldShowFeatureTour);

    const tourData = [
        {
          headline: t(CALL_STRINGS.SIDE_PIVOT_EXEC_SUMMARY_FEATURE_TOUR_FIRST_BUBBLE_HEADLINE),
          content: t(CALL_STRINGS.SIDE_PIVOT_EXEC_SUMMARY_FEATURE_TOUR_FIRST_BUBBLE_CONTENT),
          primaryButtonProps: {
            children: t(CALL_STRINGS.NEXT),
            onClick: () => {
            trackTourEvent({ action: eventAction.click, actionOn: TRACK_IDS.EXEC_SUMMARY_FEATURE_TOUR_NEXT });
            goToExecSummaryTab();
            setExecSummaryTourActiveIndex(1);
            }
          }
        },
        {
          headline: t(CALL_STRINGS.SIDE_PIVOT_EXEC_SUMMARY_FEATURE_TOUR_SECOND_BUBBLE_HEADLINE),
          content: t(CALL_STRINGS.SIDE_PIVOT_EXEC_SUMMARY_FEATURE_TOUR_SECOND_BUBBLE_CONTENT),
          primaryButtonProps: {
            children: t(CALL_STRINGS.NEXT),
            onClick: () => {
                trackTourEvent({ action: eventAction.click, actionOn: TRACK_IDS.EXEC_SUMMARY_FEATURE_TOUR_NEXT });
                setExecSummaryTourActiveIndex(2)
            }
          },
          secondaryButtonProps: { 
            children: t(CALL_STRINGS.BACK),
            onClick: () => {
              trackTourEvent({ action: eventAction.click, actionOn: TRACK_IDS.EXEC_SUMMARY_FEATURE_TOUR_BACK });
              goToOverviewTab();
              setExecSummaryTourActiveIndex(0);
            }
          }
        },
        {
          headline: t(CALL_STRINGS.SIDE_PIVOT_EXEC_SUMMARY_FEATURE_TOUR_THIRD_BUBBLE_HEADLINE),
          content: t(CALL_STRINGS.SIDE_PIVOT_EXEC_SUMMARY_FEATURE_TOUR_THIRD_BUBBLE_CONTENT),
          primaryButtonProps: {
            children: t(CALL_STRINGS.DONE),
            onClick: () => {
              storageService.localStorage.setItem(HIDE_EXEC_SUMMARY_FEATURE_TOUR, true);
              trackTourEvent({ action: eventAction.click, actionOn: TRACK_IDS.EXEC_SUMMARY_FEATURE_TOUR_DONE });
              setIsTourVisible(false)
            }
          },
          secondaryButtonProps: { 
              children: t(CALL_STRINGS.BACK),
              onClick: () => {
                  trackTourEvent({ action: eventAction.click, actionOn: TRACK_IDS.EXEC_SUMMARY_FEATURE_TOUR_BACK });
                  setExecSummaryTourActiveIndex(1)
                }
            }
        }
      ]

    const foramttedBubbleNumber = `${execSummaryTourActiveIndex + 1} / ${tourData.length}`;

      const trackTourEvent = ({ action, actionOn }) => trackEvent({
        action,
        actionOn,
        message: {
            tourId: EXEC_SUMMARY_FEATURE_TOUR,
            bubbleNumberBeforeAction: foramttedBubbleNumber,
        }
    });

      const onTryDismissing = (event) => {
        const closingTrigger = event?.target?.dataset?.iconName;
        const shouldCloseTeachingBubble = closingTrigger === 'Cancel';
    
        if (shouldCloseTeachingBubble) {
            trackTourEvent({ action: eventAction.click, actionOn: TRACK_IDS.EXEC_SUMMARY_FEATURE_TOUR_DISMISS });
            storageService.localStorage.setItem(HIDE_EXEC_SUMMARY_FEATURE_TOUR, true);
            setIsTourVisible(false);
        }
      }

      const { primaryButtonProps, secondaryButtonProps, headline, content } = tourData[execSummaryTourActiveIndex];

      return isTourVisible && (
        <TeachingBubble
          calloutProps={{ directionalHint: DirectionalHint.bottomCenter, gapSpace: 5, 'data-testid': EXEC_SUMMARY_FEATURE_TOUR }}
          target={`[data-tour-index='${execSummaryTourActiveIndex}']`}
          primaryButtonProps={primaryButtonProps}
          secondaryButtonProps={secondaryButtonProps}
          footerContent={foramttedBubbleNumber}
          headline={headline}
          hasCloseButton
          onDismiss={onTryDismissing}
        >
          {content}
        </TeachingBubble>
      )
}
