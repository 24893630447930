import { dateUtils } from 'ci-common-ui';

export const {
  Types, dayJS, dateFromServer, dateToServer, EXTENDED_FORMAT_WITH_TIME, EXTENDED_FORMAT, TIME_FORMAT, isTimezoneDateString,
  monthDayFormat, monthDayYearFormat, toLocal, getDateObject, formatDate, formatMonthDayDate, formatMonthDayYearDate,
  formatMonthDayYearDateAndTime, toJSDate, timeAgo, durationToTime, toDuration, toUTC, convertMsToHours,
  toStartOfTheDay, toEndOfTheDay, durationToTimeText
} = dateUtils;
export const fullDateFormat = 'll';
export const urlFormat = 'YYYY-MM-DD';

export const formatFullDate = (date) => dayJS(date).format(fullDateFormat);

export const daysFromToday = (date) => dayJS(date).diff(dayJS(), 'day');

export const getQuarter = (date) => dayJS(date).get('quarter');

export const getTimestamp = (date) => dayJS(date).valueOf();

export const toDateArray = (date, withCurrentMonth = true) => {
  const arr = dayJS(date).toArray();
  if (withCurrentMonth) {
    arr[1] += 1;
  }
  return arr;
};

export const getDaysLeftToQuarter = (date) => {
  const endOfQuarter = dayJS(date).endOf('quarter');
  return endOfQuarter.diff(dayJS(), 'days');
};

export function getTimeOfTheDay(time) {
  const realTime = time || dayJS();
  const morningSplit = 5;
  const afternoonSplit = 12;
  const eveningSplit = 17;
  const hours = realTime.hour();
  if (hours >= morningSplit && hours < afternoonSplit) {
    return 'morning';
  }
  if (hours >= afternoonSplit && hours < eveningSplit) {
    return 'afternoon';
  }
  return 'evening';
}

export const daysFromTodayToDate = (days) => dayJS().add(days, 'day');

export const monthsFromTodayToDate = (months) => dayJS().add(months, 'months');

// example: "00:01:05.94"  -->  65.94
export const fullTimeStringToSec = (fullTime) => {
  const re = /^(\d+):(\d+):(\d+\.?\d*)$/;
  const ms = fullTime.match(re);
  let res;

  if (ms && ms.length) {
    res = parseInt(ms[1], 10) * 60 * 60 + parseInt(ms[2], 10) * 60 + parseFloat(ms[3]);
  } else {
    res = -1;
  }

  return res;
};
