import { isEmpty } from 'lodash';

export const getAriaLabelForDropdown = (options, input, multiSelect = false) => {
  const selectedValues = multiSelect ? input.value : (input.value ? [input.value] : null);
  if (!isEmpty(options) && !isEmpty(selectedValues)) {
    const selectedLabels = options.filter(({ key }) => selectedValues.includes(key)).map(({ text }) => text);
    const ariaLabels = `${selectedLabels.join(' and ')} selected`;
    return ariaLabels;
  }
  return 'nothing selected';
};
