import React from 'react';
import useLocale from 'hooks/use-locale';
import { Panel, PrimaryButton } from '@fluentui/react';
import { errorsMap, defaultErrorCode, DEFAULT_ERROR_CODE_TRANSLATION_KEY } from './errorsMap';
import { panelStyles, Content, Title, Text } from './StyledErrorPanel';
import FixLinks from './components/FixLinks';
import ActivityId from './components/ActivityId';
import Metadata from './components/Metadata';

const ErrorPanel = ({ item, closePanel }) => {
  const { t, tAbs } = useLocale('pages.system_monitoring.error_panel');

  if (!item) {
    return null;
  }

  const { errorCode: itemErrorCode, id, metadata, fileStatus } = item;
  const hasErrorCodeMatch = itemErrorCode && errorsMap[itemErrorCode];
  const errorCode = hasErrorCodeMatch ? itemErrorCode : defaultErrorCode;
  const translationKey = hasErrorCodeMatch ? errorsMap[itemErrorCode].translationKey : DEFAULT_ERROR_CODE_TRANSLATION_KEY;
  const isIgnoredCall = fileStatus === -1;

  const panelHeader = isIgnoredCall ? t('empty_realtime_transcript.title') : `${tAbs('common.error')}: ${t(`${translationKey}.name`)}`;

  return (
    <Panel
      headerText={panelHeader}
      isOpen
      onDismiss={closePanel}
      closeButtonAriaLabel="Close"
      isBlocking={false}
      isFooterAtBottom
      styles={panelStyles}
      onRenderFooterContent={() => (
        <PrimaryButton onClick={closePanel}>{tAbs('common.got_it')}</PrimaryButton>
      )}
    >
      <Content>
        <Title>{isIgnoredCall ? t(`${translationKey}.why`) : t('why')}</Title>
        <Text>{t(`${translationKey}.description`)}</Text>
        {
          fileStatus === 0 && (
            <>
              <Title>{t('what')}</Title>
              <FixLinks errorCode={errorCode} />
            </>
          )
        }
        <ActivityId id={id} />
        <Metadata metadata={metadata} />
      </Content>
    </Panel>
  );
};

export default ErrorPanel;
