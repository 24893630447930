import { loadTheme } from '@fluentui/react';
import { isDirectionRTL } from '../dom/dom';

export const addThemeClassToBody = (theme) => {
  document.body.classList.add(`${theme.name}-theme`);
};

export const loadFabricTheme = ({ name, colors }) => loadTheme({
  name,
  rtl: isDirectionRTL(),
  palette: {
    themePrimary: colors.DYNPrimary,
    themeLighterAlt: colors.DYNTint50,
    themeLighter: colors.DYNTint40,
    themeLight: colors.DYNTint30,
    themeTertiary: colors.DYNTint20,
    themeSecondary: colors.DYNTint10,
    themeDarkAlt: colors.DYNShade10,
    themeDark: colors.DYNShade20,
    themeDarker: colors.DYNShade30,
    neutralLighterAlt: colors.Gray10,
    neutralLighter: colors.Gray20,
    neutralLight: colors.Gray30,
    neutralQuaternaryAlt: colors.Gray40,
    neutralQuaternary: colors.Gray50,
    neutralTertiaryAlt: colors.Gray60,
    neutralTertiary: colors.Gray90,
    neutralSecondary: colors.Gray130,
    neutralPrimaryAlt: colors.Gray150,
    neutralPrimary: colors.Gray160,
    neutralDark: colors.Gray190,
    black: colors.Black,
    white: colors.White,
  },
});
