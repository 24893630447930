import styled from 'styled-components';
import { FlexRowCentered, StyledText } from 'ci-common-ui';

export const Root = styled(FlexRowCentered)` 
  margin-bottom: 10px;
`;

export const Text = styled(StyledText)` 
  width: 400px;
`;
