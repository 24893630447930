import { chartConfigs, colors, hexToRGBA, arrayUtils } from 'ci-common-ui';
import { formatPercentage } from 'utils/numeric';
import { formatMonthDayDate } from 'utils/date/date';
import { STATUS_NAMES } from '../../constants';
import { getOvertimeXAxisInterval } from '../../../../utils/chart';

const { combineConfigs, noLegend, donut, donutKiloTitles, area, click } = chartConfigs;
const { immutableReverse } = arrayUtils;

export const transformTotalData = (data) => {
  if (!data) {
    return null;
  }
  return {
    calls: data.total,
    series: Object.entries(data.status).map(([name, y]) => ({ name, y }))
  };
};

export const createTotalConfig = (data, onClick, tAbs) => combineConfigs(
  {
    data: data.series,
    seriesParams: [
      {
        key: STATUS_NAMES.completed,
        text: tAbs(`common.${STATUS_NAMES.completed}`),
        color: hexToRGBA(colors.JadeGreen, 0.8)
      },
      {
        key: STATUS_NAMES.failed,
        text: tAbs(`common.${STATUS_NAMES.failed}`),
        color: hexToRGBA(colors.ScarletRed, 0.8)
      },
      {
        key: STATUS_NAMES.inProgress,
        text: tAbs(`common.${STATUS_NAMES.inProgress.toLowerCase()}`),
        color: hexToRGBA(colors.AtlanticBlue, 0.8)
      },
      {
        key: STATUS_NAMES.ignored,
        text: tAbs(`common.${STATUS_NAMES.ignored.toLowerCase()}`),
        color: hexToRGBA(colors.Gray130, 0.8)
      }
    ],
    dataLabelsFormatter: ({ percentage }) => formatPercentage(percentage),
    onClick: ({ point: { originalName } }) => onClick(originalName),
    titleText: data.calls.toString(),
    subtitleText: tAbs('common.calls')
  },
  [
    donut,
    donutKiloTitles,
    click,
    {
      plotOptions: {
        pie: {
          center: true
        }
      }
    },
    {
      accessibility: {
        point: {
          descriptionFormatter: ({ index, percentage, name }) => `graph number ${index + 1}, with value of ${percentage}%, ${name}. `
        }
      }
    }
  ]
);

const gap = { completion: 0 };

export const createCompletionConfig = (data, isOnlyOneItem, tAbs) => combineConfigs(
  {
    data: isOnlyOneItem ? [gap, ...data, gap] : immutableReverse(data),
    seriesParams: [{ key: 'completion', text: tAbs('common.completion'), color: hexToRGBA(colors.JadeGreen, 0.2) }],
    categoryField: 'day',
    categoryFieldType: 'date',
    stacking: 'normal',
    allowZero: true,
    yTickAmount: 3,
    xTickInterval: getOvertimeXAxisInterval(data),
    xAxisFormatter: ({ value, isFirst, isLast }) => {
      if (isOnlyOneItem && (isFirst || isLast)) {
        return '';
      }

      return formatMonthDayDate(value);
    },
    yAxisFormatter: ({ value }) => formatPercentage(value),
  },
  [
    noLegend,
    area,
    {
      accessibility: {
        point: {
          descriptionFormatter: ({ day, series: { name }, y }) => `${formatMonthDayDate(day)}, a ${name} graph with a point value of ${y}.`
        }
      }
    }
  ]
);
