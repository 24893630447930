/* eslint-disable no-cond-assign */
import React from 'react';
import { stringUtils } from 'ci-common-ui';
import { renderToString } from 'react-dom/server';
import { ThemeManagerProvider } from '../../providers/ThemeProvider';

export const { joinTrackDelimiter, blobToBase64 } = stringUtils;

export const isValidGuid = (guid) => new RegExp(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/).test(guid);

export const extractTextFromHtmlTags = (component) => (renderToString(<ThemeManagerProvider>{component}</ThemeManagerProvider>).replace(/<[^>]*>?/gm, '').replace(/&#x27;/gm, '`'));
