export const preventSessionClosing = (shouldPrevent) => {
  // eslint-disable-next-line consistent-return
  window.onbeforeunload = (event) => {
    if (shouldPrevent) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    }
  };
};
