import styled from 'styled-components';
import { IconButton } from '@fluentui/react';
import { colors } from '../../constants/colors';
import { FlexRow, FlexRowCentered, StyledLink, StyledTitle } from '../../utils/styles/StyledUtils';
import { rtl } from '../../utils/styles/rtl';
import { AUTOMATION_SELECTORS, dataAutomation } from '../../constants/selectors';
import { IconWrapper, InformationIcon } from '../InfoIcon/InfoIcon';
import { deviceSizes } from '../../utils/styles/device-sizes';

export const ExpandableCardContainer = styled.div.attrs({
  [dataAutomation]: AUTOMATION_SELECTORS.EXPANDABLE_CARD
})`
  background-color: ${colors.White};
  border: 1px solid ${colors.Gray40};
  padding: 14px 24px;
  overflow: auto;
  ${({ isExpanded }) => isExpanded && `
    border: 2px solid ${colors.DYNTint20};
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.108), 0px 1.6px 3.6px rgba(0, 0, 0, 0.132);
    border-radius: 2px;
  `};
  ${deviceSizes.bigMobile`
    padding: 14px 8px;
  `};
`;

export const HeaderRowContainer = styled(FlexRowCentered)`
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
`;

export const HeaderRow = styled(FlexRow)`
  width: 100%;
  ${deviceSizes.bigMobile`
    flex-direction: column;
  `}
`;

export const ChevronIcon = styled(IconButton).attrs({
  iconProps: { iconName: 'ChevronUp' },
})`
  position: relative;
  top: 12px;
  align-self: flex-start;
  font-size: 12px;
  cursor: pointer;
  ${rtl`margin-right: 24px`};
  :hover {
    color: ${colors.DYNPrimary};
    background-color: transparent;
  }
  .ms-Icon {
    transform: ${({ isExpanded }) => isExpanded ? 'rotate(0deg)' : 'rotate(180deg)'};
    transition: transform 0.2s ease-in-out;
  }
  ${deviceSizes.bigMobile`
    top: 0px;
  `}
`;

export const Title = styled(StyledTitle)`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.Gray160};
  margin-bottom: 8px;
  ${InformationIcon}{
    font-size: 12px;
  }
  ${IconWrapper}{
    ${rtl`margin-left: 8px`};
    display: inline-block;
    color: ${colors.Gray130};
    cursor: pointer;
  }
  ${deviceSizes.mobile`
    word-break: break-word;
  `};
`;

export const Subtitle = styled.div`
  font-size: 14px;
  color: ${colors.Gray130};
  ${StyledLink} {
    ${rtl`margin-left: 4px`};
  }
  ${deviceSizes.mobile`
    word-break: break-word;
  `};
`;

export const Body = styled.div`
  display: ${({ hide }) => hide ? 'none' : 'block'};
  padding-top: 24px;
  ${rtl`padding-right: 12px`};
  padding-bottom: 0;
  ${rtl`padding-left: 56px`};
  ${deviceSizes.mobile`
    padding: 0px;
  `}
`;
