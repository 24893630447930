import React from 'react';
import { Shimmer } from '@fluentui/react';
import { ChartLoaderContainer, LineSeparator } from '../../StyledSectionCard';

const SectionCardChartLoader = ({ shimmerHeight }) => (
  <ChartLoaderContainer shimmerHeight={shimmerHeight}>
    <Shimmer width="75%" />
    <LineSeparator />
    <Shimmer className="chart-shimmer" />
  </ChartLoaderContainer>
);
export default SectionCardChartLoader;
