/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import useLocale from 'hooks/use-locale';
import { LICENSE_USAGE_RESPONSE_TYPES, Trans } from 'ci-common-ui';
import { Bold, Text } from '../StyledNotificationBars';
import { getIsAdminUser } from '../../../utils/app-context/app-context';
import DismissibleBar from './DismissibleBar';
import { notificationBarsTypes } from '../notification-bars-types';
import { useAppNavigation } from '../../../hooks/use-app-navigation';
import useNotificationBar from '../../../hooks/use-notification-bar';
import { settingsModalTabs } from '../../../constants/modals';
import { QUERY_PARAMS } from '../../../constants/urls';
import { Link } from '../../Link/Link';

const i18nMapping = {
  [LICENSE_USAGE_RESPONSE_TYPES.Invalid]: 'invalid',
  [LICENSE_USAGE_RESPONSE_TYPES.CapacityUsageExceeded]: 'exceeded',
  [LICENSE_USAGE_RESPONSE_TYPES.CapacityUsageOver70Precent]: 'over70',
  [LICENSE_USAGE_RESPONSE_TYPES.CapacityUsageOver90Precent]: 'over90'
};

const LOCALISATION_PREFIX = 'components.notification_bar.license_usage';

const LicenseUsageBar = ({ data }) => {
  const { removeNotificationBar } = useNotificationBar();
  const { navigateTo } = useAppNavigation();
  const { t } = useLocale(LOCALISATION_PREFIX);
  const onDismiss = () => removeNotificationBar(notificationBarsTypes.LicenseUsage);
  const hasValidStatus = i18nMapping[data.capacityUsageStatus];
  const res = {
    capacityUsageStatus: hasValidStatus ? data.capacityUsageStatus : LICENSE_USAGE_RESPONSE_TYPES.Invalid,
    valid: hasValidStatus ? data.valid : false
  };

  return (
    <DismissibleBar onDismiss={onDismiss}>
      <Text>
        <Trans i18nKey={`${LOCALISATION_PREFIX}.${i18nMapping[res.capacityUsageStatus]}`}>
          <Bold>title</Bold>description<br />
        </Trans>
        { !res.valid ? null : !getIsAdminUser() ? (
          <Trans i18nKey={`${LOCALISATION_PREFIX}.extend_plan`}>
            start <Link onClick={() => navigateTo({ params: { [QUERY_PARAMS.SETTINGS]: settingsModalTabs.licenseusage.link } })}>end</Link>
          </Trans>
        )
          : t('contact_admin')}
      </Text>
    </DismissibleBar>
  );
};
export default LicenseUsageBar;
