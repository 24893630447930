import { useEffect } from 'react';
import { pageScrollerSelector } from 'constants/selectors';
import { getSearchParam } from 'utils/url/url';
import { scrollToPageElement, highlightTemporarily } from 'utils/dom/dom';
import { usePrevious } from 'ci-common-ui';
import { useAppNavigation } from './use-app-navigation';
import { QUERY_PARAMS } from '../constants/urls';

export const useQueryScroll = (isReady, selectors, scrollerSelector = pageScrollerSelector) => {
  const { navigateTo } = useAppNavigation();
  let hasScrolled = usePrevious(false);

  useEffect(() => {
    if (!hasScrolled && isReady) {
      window.requestAnimationFrame(() => {
        const scrollTo = getSearchParam()[QUERY_PARAMS.SCROLL_TO];
        const view = selectors[scrollTo];
        if (view) {
          hasScrolled = true;
          if (view.clickSelector) {
            const clickEl = document.querySelector(view.clickSelector);
            if (clickEl) { clickEl.click(); }
          }
          const contentEl = document.querySelector(view.contentSelector);
          scrollToPageElement(contentEl, scrollerSelector);
          highlightTemporarily(contentEl);
          navigateTo({ params: { [QUERY_PARAMS.SCROLL_TO]: null }, options: { shouldReplace: true } });
        }
      });
    }
  }, [isReady]);
};
