import { dateFromServer } from 'utils/date/date';
import { first, get } from 'lodash';
import { loadSystemMonitoringAnalysis, loadSystemMonitoringFiles } from '../rest-services/system-monitoring-rest-service';
import { transformSystemMonitoringCharts } from '../filters/system-monitoring-filters';

export const getSystemMonitoringAnalysis = async (orgId, params) => {
  const { data } = await loadSystemMonitoringAnalysis(orgId, params);
  return transformSystemMonitoringCharts(data);
};

const getFirstAgentName = (agents) => get(first(agents), 'fullName');
const getFirstAgentAadId = (agents) => get(first(agents), 'aadId');

export const getSystemMonitoringFiles = async (params) => {
  const { data } = await loadSystemMonitoringFiles({
    ...params,
    fileStatus: params.fileStatus || null
  });

  return {
    ...data,
    listOfRelevantCallsDetails: data.listOfRelevantCallsDetails.map((item) => ({
      ...item,
      processingStartTime: dateFromServer(item.processingStartTimeUtc),
      agentFullName: getFirstAgentName(item.agents) || item.agentFullName,
      aadId: getFirstAgentAadId(item.agents)
    }))
  };
};
