import React from 'react';
import { get, size, first, map, find } from 'lodash';
import { PersonaSize } from '@fluentui/react';
import SellerActions from './components/SellerBarActions';
import SellerBarDropdown from './components/SellerBarDropdown';
import KPIs from './components/SellerBarKPIs';
import { Card, Container, SellerAvatar, SellerTitle, SellerDropdownContainerStyled } from './StyledSellerBar';
import SellerBarLoader from './components/SellerBarLoader/SellerBarLoader';
import useLocale from '../../hooks/use-locale';

const translateKpis = (kpis, t) => map(
  kpis,
  (kpiData) => ({
    ...kpiData,
    label: t(`kpis.${kpiData.id}.label`),
    tooltip: t(`kpis.${kpiData.id}.tooltip`)
  })
);

const SellerDropdown = ({ sellerOptions, selectedSellerKey, onChangeSeller }) => {
  const moreThenOneSeller = size(sellerOptions) > 1;

  if (moreThenOneSeller) {
    return (
      <SellerBarDropdown
        selectedKey={selectedSellerKey}
        onChangeSeller={onChangeSeller}
        sellerOptions={sellerOptions}
      />
    );
  }

  return <SellerTitle>{first(sellerOptions).text}</SellerTitle>;
};

const SellerDropdownContainer = ({ sellerOptions, selectedSellerKey, onChangeSeller }) => (
  <SellerDropdownContainerStyled>
    <SellerDropdown
      sellerOptions={sellerOptions}
      selectedSellerKey={selectedSellerKey}
      onChangeSeller={onChangeSeller}
    />
  </SellerDropdownContainerStyled>
);

const SellerBar = ({
  selectedSellerKey,
  sellerOptions,
  kpis,
  onChangeSeller,
  onEmailActionClicked,
  onChatActionClicked,
  isLoading,
}) => {
  const { t } = useLocale('components.seller_bar');

  if (isLoading) {
    return <SellerBarLoader />;
  }

  const translatedKpis = translateKpis(kpis, t);
  const selectedSeller = find(sellerOptions, ({ key }) => key === selectedSellerKey);

  return (
    <Card>
      <Container>
        <SellerAvatar
          aadId={get(selectedSeller, 'key')}
          size={PersonaSize.size72}
          apiImageSize="96"
          onRenderPrimaryText={() => <SellerDropdownContainer sellerOptions={sellerOptions} selectedSellerKey={selectedSellerKey} onChangeSeller={onChangeSeller} />}
          onRenderSecondaryText={() => <SellerActions onEmailClicked={onEmailActionClicked} onChatClicked={onChatActionClicked} />}
          text={get(selectedSeller, 'text')}
        />
        <KPIs data={translatedKpis} />
      </Container>
    </Card>
  );
};
export default SellerBar;
