import styled from 'styled-components';
import { Icon, Calendar } from '@fluentui/react';
import { colors } from '../../constants/colors';
import { StyledText } from '../../utils/styles/StyledUtils';

export const StyledIcon = styled(Icon)`
  font-size: 16px;
  margin-left: 4px;
`;

export const CalendarLabel = styled(StyledText)`
  font-size: 14px;
  color: ${colors.Gray160};
`;

export const StyledCalendar = styled(Calendar)`
  .ms-DatePicker-holder {
    border: 1px solid ${colors.Gray90};
    border-radius: 2px;
    min-height: auto;
    height: auto;
  }

  .ms-DatePicker-dayBackground:active {
    background: ${colors.Gray30};
  }

  .ms-DatePicker-day--highlighted {
    background: ${colors.White};
    &>button {
      background: ${colors.DYNPrimary};
      color: ${colors.White};
    }
    &>button.ms-DatePicker-day-button.ms-DatePicker-day--today {
      background: ${colors.DYNPrimary};
      color: ${colors.White};
    }
    :hover {
      background: ${colors.Gray30};
    }
  }

  .ms-DatePicker-day-button.ms-DatePicker-day--today {
      color: ${colors.Gray160};
      background: ${colors.DYNTint30};
  }
`;

export const CalendarContainer = styled.div`
    margin-top: 10px;
`;
