import { ProdRegions } from "./common-regions";

export const SP_PROD_BASE = 'vivasales.dynamics.com';
export const SP_DEV_BASE = 'salesproductivity.dynamics.com';
export const SP_TEST_BASE = 'vivasales.test.dynamics.com';

export enum SpRegions {
  local = 'local',
  dev = 'dev',
  daily = 'daily',
  ildev1 = 'ildev1',
  ildev2 = 'ildev2',
  ildev3 = 'ildev3',
  ildev4 = 'ildev4',
  tip = 'tip',
  fre = 'fre',
  partnerfre = 'partnerfre',
  research = 'research',
  sg = 'sg',
  prod = 'prod',
};

export const SpRegion = { ...SpRegions, ...ProdRegions };
export type SpRegion = SpRegions | ProdRegions;

export enum SalesCopilotAppId {
  local = '1d17e3f8-d7bd-4ed8-8ab9-7a7db6cfd412',
  dev = 'ab1eb97c-0c0b-42ed-ac34-45d3feaf9353',
  ildev1 = '4a8718c5-35ae-414e-9ba1-cfe2368ea086',
  ildev2 = '71c12c27-41ac-49a9-a3d1-9eed40ab3896',
  ildev3 = 'c15e3e24-558d-421b-a115-345045a557ab',
  ildev4 = '9a13e92a-71f4-404e-8726-b2183280ba01',
  daily = '98a882ae-a7eb-4414-982a-346a766f4a50',
  fre = 'fcadcb5c-59ac-4b8c-85c7-b255a0159c75',
  partnerfre = '7c6b39c0-48cd-4f99-a107-a349dc875727',
  research = 'fdf43993-b35b-458e-ab96-b34d3a8b5fdc',
  tip = 'ec22438e-d098-4075-9139-35f6a15007a0',
  sg = '0157fd3d-f174-4ea9-9b6b-b84baba12551',
  prod = 'c92c289e-ceb4-4755-819d-0d1dffdab6fa',
};

export const SpDevHostnames = [
  'ui-dev',
  'dev.',
  `int.${SP_DEV_BASE}`,
  `ildev1.${SP_DEV_BASE}`,
  `ildev2.${SP_DEV_BASE}`,
  `ildev3.${SP_DEV_BASE}`,
  `ildev4.${SP_DEV_BASE}`,
  `demo.${SP_DEV_BASE}`,
  `perf.${SP_DEV_BASE}`,
];

export const SpDailyHostname = `daily.${SP_TEST_BASE}`;
export const SpTipHostname = `tip.${SP_TEST_BASE}`;
export const SpResearchHostname = `research.${SP_PROD_BASE}`;
export const SpSgHostname = `sg.${SP_PROD_BASE}`;
export const SpPartnerfreHostname = `partnerfre.${SP_DEV_BASE}`;
export const SpFreHostname = `fre.${SP_PROD_BASE}`;
export const SpIntHostnames = [
  'msteamstabservice.crmlivetie.com',
  'msteamstabservice.crm2.crmlivetie.com',
];
export const SpIntOrFreHostNames = [...SpIntHostnames, SpFreHostname];
export const CiTipVivaAppHostNames = [
  ...SpIntOrFreHostNames,
  SpPartnerfreHostname,
  SpResearchHostname,
  SpSgHostname,
];
