const PAGE_SUFFIX = '_PAGE';
const INSIGHTS_PREFIX = 'INSIGHTS_';
const SETTINGS_PREFIX = 'SETTINGS_';
const TAB_SUFFIX = '_TAB';

export const TRACK_SUFFIX = {
  TABLE: {
    SORT: 'SORT',
    PAGING: 'PAGING',
    ROW: 'ROW',
    CELL: 'CELL',
  }
};

export const TRACK_IDS = {
  SOURCES: {
    APP: 'APP',
    HOME_PAGE: `HOME${PAGE_SUFFIX}`,
    TEAM_PAGE: `TEAM${PAGE_SUFFIX}`,
    SELLER_PAGE: `SELLER${PAGE_SUFFIX}`,
    SYSTEM_MONITORING_PAGE: `SYSTEM_MONITORING${PAGE_SUFFIX}`,
    CALL_PAGE: `CALL${PAGE_SUFFIX}`,
    SEARCH_PAGE: `SEARCH${PAGE_SUFFIX}`,
    CUSTOMER_SENTIMENT_INSIGHT_PAGE: `${INSIGHTS_PREFIX}CUSTOMER_SENTIMENT${PAGE_SUFFIX}`,
    TRACKED_KEYWORDS_INSIGHT_PAGE: `${INSIGHTS_PREFIX}TRACKED_KEYWORDS${PAGE_SUFFIX}`,
    COMPETITORS_INSIGHT_PAGE: `${INSIGHTS_PREFIX}COMPETITORS${PAGE_SUFFIX}`,
    BRANDS_INSIGHT_PAGE: `${INSIGHTS_PREFIX}BRANDS${PAGE_SUFFIX}`,
    TOP_SELLERS_INSIGHT_PAGE: `${INSIGHTS_PREFIX}TOP_SELLERS${PAGE_SUFFIX}`,
    SETTINGS_MODAL: 'SETTINGS_MODAL',
    YOUR_TEAM_TAB: `${SETTINGS_PREFIX}YOUR_TEAM${TAB_SUFFIX}`,
    CONVERSATION_CONTENT_TAB: `${SETTINGS_PREFIX}CONVERSATION_CONTENT${TAB_SUFFIX}`,
    DATA_SOURCE_TAB: `${SETTINGS_PREFIX}DATA_SOURCE${TAB_SUFFIX}`,
    PRIVACY_TAB: `${SETTINGS_PREFIX}PRIVACY${TAB_SUFFIX}`,
    COMING_SOON_TAB: `${SETTINGS_PREFIX}COMING_SOON${TAB_SUFFIX}`,
    LICENSE_USAGE_TAB: `${SETTINGS_PREFIX}LICENSE_USAGE${TAB_SUFFIX}`,
    UPLOAD_CALLS_TAB: `${SETTINGS_PREFIX}UPLOAD_CALLS${TAB_SUFFIX}`,
    ONBOARDING_MODAL: 'ONBOARDING',
    TRIAL_SETUP_MODAL: 'TRIAL_SETUP',
    ABOUT_MODAL: 'ABOUT',
    ACTION_ITEMS: 'ACTION_ITEMS',
    PENDING_PROVISION_SCREEN: 'PENDING_PROVISION_SCREEN',
    CI_IFRAME: 'CI_IFRAME',
    CI_WS: 'CI_WS',
  },
  COMMON: { // IDs which are prefixed or suffixed to the IDs below (also can be used as is without concatenation)
    BACK_BUTTON: 'BACK_BUTTON',
    NEXT_BUTTON: 'NEXT_BUTTON',
    SUBMIT_BUTTON: 'SUBMIT_BUTTON',
    FINISH_BUTTON: 'FINISH_BUTTON',
    CLOSE_BUTTON: 'CLOSE_BUTTON',
    CUSTOMER_INSIGHTS: 'CUSTOMER_INSIGHTS',
    SELLER_INSIGHTS: 'SELLER_INSIGHTS',
    TEAM_INSIGHTS: 'TEAM_INSIGHTS',
    CUSTOMER_SENTIMENT: 'CUSTOMER_SENTIMENT',
    CONVERSATION_STYLE: 'CONVERSATION_STYLE',
    LEARN_MORE: 'LEARN_MORE',
    OVERALL: 'OVERALL',
    BY_AGENT: 'BY_AGENT',
    OVER_TIME: 'OVER_TIME',
    AVERAGE: 'AVERAGE',
    TALK_TO_LISTEN_RATIO: 'TALK_TO_LISTEN_RATIO',
    TALKING_SPEED: 'TALKING_SPEED',
    SWITCH_RATE: 'SWITCH_RATE',
    PAUSE_BEFORE_SPEAKING: 'PAUSE_BEFORE_SPEAKING',
    LONGEST_CUSTOMER_MONOLOUGE: 'LONGEST_CUSTOMER_MONOLOUGE',
    MENTIONS: 'MENTIONS',
    CONVERSATIONS: 'CONVERSATIONS',
    LICENSE_USAGE: 'LICENSE_USAGE',
    CARD: 'CARD',
    PAGE: 'PAGE',
    REQUEST: 'REQUEST',
    CHART_ZOOM: 'CHART_ZOOM',
    CHART_RESET_ZOOM: 'CHART_RESET_ZOOM',
    CHART_VISIT: 'CHART_VISIT',
    CAROUSEL_NEXT: 'CAROUSEL_NEXT',
    CAROUSEL_PREV: 'CAROUSEL_PREV',
    EXPORT_TO_CSV: 'EXPORT_TO_CSV',
    TAG_LIST: 'TAG_LIST',
    VIEWED: 'VIEWED',
    AUTHENTICATION: 'AUTHENTICATION',
    DISCONNECT_ACTIVE_ORG: 'DISCONNECT_ACTIVE_ORG',
    DISCONNECT: 'DISCONNECT',
    SET_ACTIVE_ORG: 'SET_ACTIVE_ORG',
    DELETE_CONTACT_DATA: 'DELETE_CONTACT_DATA',
    LINK: 'LINK',
    AUTO_SKIP: 'AUTO_SKIP',
    PAGE_LOAD_TIME_MEASUREMENT_MILLISECONDS: 'PAGE_LOAD_TIME_MEASUREMENT_MILLISECONDS',
    MISSING_ERROR_CODE: 'MISSING_ERROR_CODE',
  },
  RELEVANT_CONVERSATIONS_TABLE: 'RELEVANT_CONVERSATIONS_TABLE',
  CALL_HISTORY_TABLE: 'CALL_HISTORY_TABLE',
  FILES_TABLE: 'FILES_TABLE',
  FILES_TABLE_FILTER: 'FILES_TABLE_FILTER',
  FILES_TABLE_REFRESH: 'FILES_TABLE_REFRESH',
  NPS_FEEDBACK_CLOSED: 'NPS_FEEDBACK_CLOSED',
  NPS_FEEDBACK_SHOWED: 'NPS_FEEDBACK_SHOWED',
  DATE_TIME_FILTER: 'DATE_TIME_FILTER',
  CONVERSATION_STYLE_TAB: 'CONVERSATION_STYLE_TAB',
  SHOW_MORE_COLLAPSED: 'SHOW_MORE_COLLAPSED',
  SHOW_MORE_EXPANDED: 'SHOW_MORE_EXPANDED',
  TOTAL_CALL_STATUS: 'TOTAL_CALL_STATUS',
  COMPLETION_RATE: 'COMPLETION_RATE',
  TRACKED_KEYWORDS: 'TRACKED_KEYWORDS',
  TRACKED_COMPETITORS: 'TRACKED_COMPETITORS',
  BRANDS: 'BRANDS',
  BEST_PRACTICE_KEYWORDS: 'BEST_PRACTICE_KEYWORDS',
  CUSTOMER_ENGAGEMENT: 'CUSTOMER_ENGAGEMENT',
  PROVISION_BANNER_GET_STARTED: 'PROVISION_BANNER_GET_STARTED',
  PROVISION_BANNER_SETUP: 'PROVISION_BANNER_SETUP',
  TRY_AGAIN_PROVISION: 'TRY_AGAIN_PROVISION',
  TRIAL_DATA_UPLOADER_MONITOR_CALLS: 'TRIAL_DATA_UPLOADER_MONITOR_CALLS',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
  CALL_TOPIC: 'CALL_TOPIC',
  SEARCH_TABLE: 'SEARCH_TABLE',
  MATCHES: 'MATCHES',
  USER_FINISHED_PROVISIONING: 'USER_FINISHED_PROVISIONING',
  USER_FAILED_PROVISIONING: 'USER_FAILED_PROVISIONING',
  STARTED_ONBOARIDNG_PROCESS: 'STARTED_ONBOARIDNG_PROCESS',
  USER_AUTO_ONBOARDED: 'USER_AUTO_ONBOARDED',
  USER_ATTEMPTS_TO_AUTO_ONBOARD: 'USER_ATTEMPTS_TO_AUTO_ONBOARDED',
  USER_FAILED_TO_AUTO_ONBOARD: 'USER_FAILED_TO_AUTO_ONBOARD',
  GRAPH_API_AUTHENTICATION_ERROR: 'GRAPH_API_AUTHENTICATION_ERROR',
  MISSING_TRANSLATION_KEY: 'MISSING_TRANSLATION_KEY',
  LOCALES_ERROR: 'LOCALES_ERROR',
  REMOVING_CRM_RELATED_ENTITY: 'REMOVING_CRM_RELATED_ENTITY',
  ADDING_CRM_RELATED_ENTITY: 'ADDING_CRM_RELATED_ENTITY',
  VIEW_AS_ADD_NEW_MAPPING: 'VIEW_AS_ADD_NEW_MAPPING',
  VIEW_AS_EXPAND_ADDING_BAR: 'VIEW_AS_EXPAND_ADDING_BAR',
  VIEW_AS_SEARCH_USER: 'VIEW_AS_SEARCH_USER',
  VIEW_AS_REMOVE_MAPPING: 'VIEW_AS_REMOVE_MAPPING',
  VIEW_AS_UPDATE_EXPIRY_DATE: 'VIEW_AS_UPDATE_EXPIRY_DATE',
  STORAGE_SET_ITEM: 'STORAGE_SET_ITEM',
  PROXY_HOWLER_RESPONSE_POST_MESSAGE: 'PROXY_HOWLER_RESPONSE_POST_MESSAGE',
  WS_LOG: 'WS_LOG',
  PUBLIC_CLIENT_APP_SSO_SILENT_FAILED: 'PUBLIC_CLIENT_APP_SSO_SILENT_FAILED',
  ACQUIRE_TOKEN_FAILED: 'ACQUIRE_TOKEN_FAILED',
  ACQUIRE_TOKEN_FAILED_ON_VALID_ERROR: 'ACQUIRE_TOKEN_FAILED_ON_VALID_ERROR',
  RETRY_ACQUIRE_TOKEN_AFTER_FAILURE: 'RETRY_ACQUIRE_TOKEN_AFTER_FAILURE',
};
