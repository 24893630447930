import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import first from 'lodash/first';
import { useFetchNow, useFetch } from 'ci-common-ui';
import useLocale from 'hooks/use-locale';
import { getSellerList, getSellerData } from 'network/data-services/seller-service';
import { getDateParams } from 'utils/url/url';
import { useQueryDateChange } from 'hooks/use-query-date-change';
import { useParams } from 'react-router';
import { get, find, size } from 'lodash';
import Page from '../components/Page/Page';
import PageError from '../components/PageError/PageError';
import SellerDetails from './components/SellerDetails/SellerDetails';
import { routes } from '../routing';
import { useAppNavigation } from '../../hooks/use-app-navigation';
import { appContext } from '../../utils/app-context/app-context';
import { getSpecificError } from '../../utils/error-state/error-state';
import { TRACK_IDS } from '../../constants/tracking';
import { componentLoadingTimeService } from '../../services/component-loading-time-service/component-loading-time-service';

const SellerPage = () => {
  const { t } = useLocale('pages.seller');
  const { navigateTo } = useAppNavigation();
  const { orgId } = appContext;
  const { id: selectedSellerId } = useParams();

  const { data: sellerList, isLoading: isLoadingSellerList, error: sellerListError } = useFetchNow(() => getSellerList(orgId));

  const { data, isLoading: isLoadingSellerDetails, error: sellerDetailsError, fetchData } = useFetch((newParams) => getSellerData({ ...getDateParams(), ...newParams }), { getSpecificError });

  const selectedSellerEmail = get(find(sellerList, ({ key }) => key === selectedSellerId), 'email');
  const isLoading = isLoadingSellerList || isLoadingSellerDetails;
  const isPageNotReady = isLoading || !data;
  const isSingleSellerAndNoDates = size(sellerList) === 1 && data && isEmpty(data.dateTimeRange); // If we have a single seller and there's no dates => render empty page. otherwise, don't render empty page
  const isUserNotExist = selectedSellerId && !selectedSellerEmail && !isLoading;

  useQueryDateChange((newParams) => {
    if (selectedSellerEmail) {
      fetchData({ agentAadId: selectedSellerId, ...newParams });
    }
  });

  useEffect(() => {
    if (selectedSellerEmail) {
      fetchData({ agentAadId: selectedSellerId });
    }
  }, [selectedSellerEmail]);

  useEffect(() => {
    if (!isEmpty(sellerList) && !selectedSellerId) {
      navigateTo({ path: `${routes.sellers.link}/${first(sellerList).key}`, options: { shouldReplace: true } });
    }
  }, [sellerList]);

  useEffect(() => {
    componentLoadingTimeService.start(TRACK_IDS.SOURCES.SELLER_PAGE);
  }, []);

  if (isUserNotExist) {
    return <PageError title={t('user_not_exist_title')} subtitle={t('user_not_exist_subtitle')} />;
  }

  return (
    <Page title={t('title')} error={sellerListError || sellerDetailsError} fullTimeRange={data && data.dateTimeRange} isLoading={isPageNotReady} isEmptyPageDisabled={!isSingleSellerAndNoDates}>
      <SellerDetails isLoading={isPageNotReady} selectedSellerId={selectedSellerId} data={data} sellerList={sellerList} selectedSellerEmail={selectedSellerEmail} error={sellerDetailsError} />
    </Page>
  );
};

export default SellerPage;
