/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import filter from 'lodash/filter';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import useLocale from 'hooks/use-locale';
import { Trans } from 'ci-common-ui';
import { get } from 'lodash';
import { extractTextFromHtmlTags } from '../../../../utils/string/string';
import { useAppNavigation } from '../../../../hooks/use-app-navigation';
import { routes } from '../../../routing';
import MultiCarousel from '../../../../components/MultiCarousel/MultiCarousel';
import { CarouselCard, InsightsSection, InsightsTitle } from '../../../StyledPages';
import { TRACK_IDS } from '../../../../constants/tracking';
import DelimitedInlineList from '../../../../components/DelimitedInlineList/DelimitedInlineList';
import { getIsCustomerServiceMode } from '../../../../utils/app-context/app-context';
import { Link } from '../../../../components/Link/Link';

const TeamInsights = ({ isLoading, data }) => {
  const { t, appModeAwareTerms, tDefault } = useLocale('pages.team.sections.team_insights');
  const { navigateTo } = useAppNavigation();

  const navigateToSellerPage = (sellerId) => {
    navigateTo({ path: `${routes.sellers.link}/${sellerId}` });
  };

  if (isLoading) return null;

  const {
    insights: {
      topKeywordsMissing,
      highTalkToListenRatio,
      lowTalkToListenRatio,
      slowestTalkingSpeed,
    }
  } = data;

  const extractTalking = (talkToListenRatio) => talkToListenRatio && get(talkToListenRatio.split(':'), '[0]');

  const insights = filter(
    [!isEmpty(topKeywordsMissing) && {
      id: 'topKeywordsMissing',
      text: (
        <Trans i18nKey="pages.team.sections.team_insights.cards.missing_top_keywords" count={size(topKeywordsMissing.keywords)}>
          <DelimitedInlineList boldItems data={map(topKeywordsMissing.keywords, (keyword) => ({ name: keyword }))} />
          are top {{ _keyword_plural: appModeAwareTerms._keyword_plural, _keyword: appModeAwareTerms._keyword }} that arent being mentioned by some of your {{ _representative_plural: appModeAwareTerms._representative_plural }} in their {{ _department: appModeAwareTerms._department }} calls.
        </Trans>
      )
    }, !isEmpty(highTalkToListenRatio) && {
      id: 'highTalkToListenRatio',
      text: (
        <Trans i18nKey="pages.team.sections.team_insights.cards.high_talk_listen_ratio" count={size(highTalkToListenRatio.agents)}>
          <DelimitedInlineList components={map(highTalkToListenRatio.agents, ({ agentName, id }) => getIsCustomerServiceMode()
            ? agentName
            : (
              <Link key={id} onClick={() => navigateToSellerPage(id)}>{agentName}</Link>
            ))}
          />
          appear to talk the most, talking an average of <strong>{{ sellersTalkingAvg: extractTalking(highTalkToListenRatio.sellersAvg) }}percentSign</strong> duringCallsFragemnt.<br /><br />This is <strong>{{ diffPercent: Math.abs(highTalkToListenRatio.diffPercent) }}%</strong> higher than your top {{ _representative_plural: appModeAwareTerms._representative_plural }} who talk an average of <strong>{{ topSellersTalkingAvg: extractTalking(highTalkToListenRatio.topSellersAvg) }}</strong> during calls.
        </Trans>
      )
    }, !isEmpty(lowTalkToListenRatio) && {
      id: 'lowTalkToListenRatio',
      text: (
        <Trans i18nKey="pages.team.sections.team_insights.cards.low_talk_listen_ratio" count={size(lowTalkToListenRatio.agents)}>
          <DelimitedInlineList components={map(lowTalkToListenRatio.agents, ({ agentName, id }) => getIsCustomerServiceMode()
            ? agentName
            : (
              <Link key={id} onClick={() => navigateToSellerPage(id)}>{agentName}</Link>
            ))}
          />
          appear to talk the least, talking an average of <strong>{{ sellersTalkingAvg: extractTalking(lowTalkToListenRatio.sellersAvg) }}percentSign</strong> during calls.<br /><br />This is <strong>{{ diffPercent: Math.abs(lowTalkToListenRatio.diffPercent) }}%</strong> lower than your top {{ _representative_plural: appModeAwareTerms._representative_plural }}, who talk an average of <strong>{{ topSellersTalkingAvg: extractTalking(lowTalkToListenRatio.topSellersAvg) }}%</strong> during calls.
        </Trans>
      )
    }, !isEmpty(slowestTalkingSpeed) && {
      id: 'slowestTalkingSpeed',
      text: (
        <Trans i18nKey="pages.team.sections.team_insights.cards.talking_speed" count={size(slowestTalkingSpeed.agents)}>
          <DelimitedInlineList components={map(slowestTalkingSpeed.agents, ({ agentName, id }) => getIsCustomerServiceMode()
            ? agentName
            : (
              <Link key={id} onClick={() => navigateToSellerPage(id)}>{agentName}</Link>
            ))}
          />
          have the lowest talking speed, with an average talking speed of <strong>{{ wordsPerMinute: slowestTalkingSpeed.sellersAvg }}</strong> words per minute.<br /><br /> Thats <strong>{{ diffPercent: Math.abs(slowestTalkingSpeed.diffPercent) }}%</strong> slower than your top {{ _representative_plural: appModeAwareTerms._representative_plural }} average talking speed, which is <strong>{{ topSellersAvg: slowestTalkingSpeed.topSellersAvg }}</strong> words per minute.<br />Industry benchmarks for sales calls suggest that the sweet spot for talking speed is around <strong>175</strong> words per minute.
        </Trans>
      )
    }]
  );
  if (!insights.length) return null;

  return (
    <InsightsSection isLoading={isLoading}>
      <InsightsTitle>{t('title')}</InsightsTitle>
      <MultiCarousel containerAriaLabel={tDefault('description')} trackId={TRACK_IDS.COMMON.TEAM_INSIGHTS}>
        {insights.map(({ id, text }) => <CarouselCard key={id} ariaLabel={extractTextFromHtmlTags(text)}>{text}</CarouselCard>)}
      </MultiCarousel>
    </InsightsSection>
  );
};
export default TeamInsights;
