import React from 'react';
import useLocale from 'hooks/use-locale';
import { Text } from '../StyledNotificationBars';
import DismissibleBar from './DismissibleBar';
import { notificationBarsTypes } from '../notification-bars-types';
import useNotificationBar from '../../../hooks/use-notification-bar';

const LOCALISATION_PREFIX = 'components.notification_bar.saa_deprication';

const SAADepricatinBar = ({ level }) => {
  const { removeNotificationBar } = useNotificationBar();
  const { t } = useLocale(LOCALISATION_PREFIX);
  const onDismiss = () => removeNotificationBar(notificationBarsTypes.SAADeprication);

  return (
    <DismissibleBar onDismiss={onDismiss} level={level}>
      <Text>{t('text')}</Text>
    </DismissibleBar>
  );
};

export default SAADepricatinBar;
