/* eslint-disable react/destructuring-assignment,react/jsx-props-no-spreading */
import React from 'react';
import omit from 'lodash/omit';
import NoDataError from '../../pages/components/SectionCard/components/NoDataError/NoDataError';
import useLocale from '../../hooks/use-locale';
import { trackEvent } from '../../services/telemetry-service/telemetry-service';
import { eventAction } from '../../services/telemetry-service/consts';

const withEmptyState = (WrappedCard) => {
  const CardComponent = (props) => {
    const { t } = useLocale('errors.ui_component');
    const { emptyStateDetails, isErrorState, cardName, trackId } = props;
    let errorComponent;
    if (isErrorState) {
      errorComponent = (
        <NoDataError
          title={t('title', { cardName })}
          subtitle={t('subtitle')}
        />
      );
    } else if (emptyStateDetails) {
      errorComponent = (
        <NoDataError
          title={emptyStateDetails.title}
          subtitle={emptyStateDetails.subtitle}
        />
      );
      trackEvent({
        action: eventAction.emptyState,
        actionOn: trackId
      });
    }
    return (
      <WrappedCard
        {...omit(props, ['emptyStateDetails', 'isErrorState'])}
        errorComponent={errorComponent}
      />
    );
  };
  CardComponent.displayName = WrappedCard.displayName || WrappedCard.name || 'withEmptyStateComponent';
  return CardComponent;
};

export default withEmptyState;
