import React from 'react';
import { storageService, Trans } from 'ci-common-ui';
import { PrimaryButton } from '@fluentui/react';
import useLocale from 'hooks/use-locale';
import { TRY_BEFORE_BUY_DATA } from 'constants/browser-storage';
import { Link } from '../../../../components/Link/Link';
import { CallToActionContainer, ExplanationContainer, GetStartedCallToAction, Subtitle, Title } from './StyledProvisionBanner';

const LOCALIZATION_PREFIX = 'components.provision_banner';

const StartTrial = ({ onSetupClicked, onGetStartedClicked }) => {
  const { t } = useLocale(LOCALIZATION_PREFIX);

  const isResume = !!storageService.sessionStorage.getItem(TRY_BEFORE_BUY_DATA);

  return (
    <>
      <ExplanationContainer>
        <Title>{t('new_setup_title')}</Title>
        <Subtitle>{t('new_setup_subtitle')}</Subtitle>
      </ExplanationContainer>
      <CallToActionContainer>
        <PrimaryButton text={t(isResume ? 'resume_setup_trial_call_to_action' : 'new_setup_trial_call_to_action')} onClick={onSetupClicked} />
        <GetStartedCallToAction>
          <Trans i18nKey={`${LOCALIZATION_PREFIX}.new_setup_connect_org_call_to_action`}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            fragment1 <Link onClick={onGetStartedClicked} target="_blank">get started</Link>
          </Trans>
        </GetStartedCallToAction>
      </CallToActionContainer>
    </>
  );
};

export default StartTrial;
