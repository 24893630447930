import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { isNil } from 'lodash';
import { Link } from '@fluentui/react';
import { itemsContainerStyles, showMoreLabelStyles, ShowMoreContainer } from './StyledShowMore';
import { useResize } from '../../hooks/use-resize';

export const ShowMore = ({ handleClick, showMoreText, showLessText, CustomShowMoreButton, minLength = 3, labelOnTop = false, children, isHiddenByHeight, minHeight, CustomShowMoreButtonStyle }) => {
  const [isExpanded, setExpansion] = useState(false);
  const innerContainer = useRef();
  const childrenArr = React.Children.toArray(children);
  const getShouldHideButton = (scrollHeight)  => isHiddenByHeight ? (scrollHeight <= minHeight) : (childrenArr.length <= minLength)
  const [shouldHideButton, setShouldHideButton] = useState(getShouldHideButton(innerContainer.current?.scrollHeight));
  useResize();

  useEffect(() => {
    if (isHiddenByHeight && isNil(minHeight)) {
      throw new Error('minHeight must be provided on isHiddenByHeight mode');
    }
  }, [isHiddenByHeight, minHeight]);

  useLayoutEffect(() => {
    const shouldHide = getShouldHideButton(innerContainer.current?.scrollHeight);
    setShouldHideButton(shouldHide);
  })

  const onClick = () => {
    if (handleClick) handleClick(isExpanded);
    setExpansion(!isExpanded);
  };

  function renderButton() {
    if (shouldHideButton) return null;

    if (CustomShowMoreButton) {
      return (
        <CustomShowMoreButton
          onClick={onClick}
          isExpanded={isExpanded}
          visibleItemsCount={isExpanded ? childrenArr.length : minLength}
          totalItemsCount={childrenArr.length}
        />
      );
    }

    const label = isExpanded ? showLessText : showMoreText;
    const Button = CustomShowMoreButtonStyle || Link;
    return (
      <Button
        key="button"
        aria-label={label}
        aria-expanded={isExpanded}
        onClick={onClick}
        data-testid="show-more-button"
        styles={!CustomShowMoreButton && showMoreLabelStyles}
      >
        {label}
      </Button>
    );
  }

  const items = () => {
    if (isHiddenByHeight) {
      return (
        <div style={itemsContainerStyles({ isExpanded, minHeight })} ref={innerContainer}>
          {childrenArr}
        </div>
      )
    }
    return isExpanded ? childrenArr : childrenArr.slice(0, minLength);
  };

  return (
    <ShowMoreContainer>
      {labelOnTop && renderButton()}
      {items()}
      {!labelOnTop && renderButton()}
    </ShowMoreContainer>
  );
};

