import { MessageBar, MessageBarType } from '@fluentui/react';
import styled from 'styled-components';
import { TrackedLink } from "../../../../../../components/TrackedLink";

export const Disclaimer = styled(MessageBar).attrs({
  messageBarType: MessageBarType.info,
  styles: { innerText: { maxWidth: 564 } },
})`
  border: ${({ theme }) => `1px solid ${theme.colors.Gray30}`};
  color: ${({ theme }) => theme.colors.Gray160};
  background-color: ${({ theme }) => theme.colors.Gray20};
  border-radius: 4px;

  .ms-Icon,
  .ms-MessageBar-icon > i {
    color: ${({ theme }) => theme.colors.Gray160};
  }
`;

export const Container = styled.div``;

export const LearnMoreLink = styled(TrackedLink)`
  && {
    color: ${({theme}) => theme.colors.DYNPrimary};
  }
`
