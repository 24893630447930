import React from 'react';
import useLocale from 'hooks/use-locale';
import { ShowMore } from 'ci-common-ui';
import { trackEvent, eventAction } from '../../services/telemetry-service/telemetry-service';
import { TRACK_IDS } from '../../constants/tracking';
import { joinTrackDelimiter } from '../../utils/string/string';

const ShowMoreWrapper = ({ trackId, CustomShowMoreButton, minLength = 3, children }) => {
  const { t } = useLocale('components.show_more');

  const onClick = (isExpanded) => {
    const actionOn = joinTrackDelimiter([trackId, isExpanded ? TRACK_IDS.SHOW_MORE_COLLAPSED : TRACK_IDS.SHOW_MORE_EXPANDED]);
    trackEvent({ action: eventAction.click, actionOn });
  };

  return (
    <ShowMore
      showMoreText={t('show_more')}
      showLessText={t('show_less')}
      onClick={onClick}
      CustomShowMoreButton={CustomShowMoreButton}
      minLength={minLength}
    >
      {children}
    </ShowMore>
  );
};

export default ShowMoreWrapper;
