import React from 'react';
import { PersonaCell, TopSellerCell, DeleteCell, LinkCell } from 'ci-common-ui';
import { CallTopicCell } from './Cells/CallTopicCell/CallTopicCell';
import SentimentBarCell from './Cells/SentimentBarCell/SentimentBarCell';
import PersonaWithTopPerformerCell from './Cells/PersonaWithTopPerformerCell/PersonaWithTopPerformerCell';
import PillsCell from './Cells/PillsCell/PillsCell';
import TagsCell from './Cells/TagsCell/TagsCell';
import CallTypeCell from './Cells/CallTypeCell/CallTypeCell';
import CrmEntitiesLinks from '../CrmEntitiesLinks/CrmEntitiesLinks';
import { DateAndDurationCell } from './Cells/DateAndDurationCell/DateAndDurationCell';
import { CommentsCountCell } from './Cells/CallTopicCell/CommentsCountCell/CommentsCountCell';

export { PersonaCell, TopSellerCell, DeleteCell, LinkCell, CallTopicCell, SentimentBarCell, PillsCell, DateAndDurationCell };

export const commentsCountColumn = {
  key: 'comments_count',
  name: 'common.comments',
  fieldName: 'commentsCount',
  CellComponent: CommentsCountCell,
  minWidth: 60,
  maxWidth: 60
};

export const iconColumn = {
  key: 'icon',
  name: '',
  fieldName: '',
  minWidth: 30,
  maxWidth: 30
};

export const callTopicColumn = {
  key: 'call_topic',
  name: 'common.call_topic',
  fieldName: 'callTopic',
  CellComponent: CallTopicCell,
};

export const ownerColumn = {
  key: 'owner',
  name: 'common.owner',
  fieldName: 'agentName',
  CellComponent: PersonaWithTopPerformerCell,
  minWidth: 160
};

export const sentimentColumn = {
  key: 'customer_sentiment',
  name: 'common.customer_sentiment',
  fieldName: 'customerSentiment',
  CellComponent: SentimentBarCell,
  minWidth: 111,
  maxWidth: 111
};

export const dateAndDurationColumn = {
  key: 'dateAndDuration',
  name: 'common.date_and_duration',
  fieldName: 'dateAndDuration',
  CellComponent: DateAndDurationCell,
  minWidth: 124,
  maxWidth: 124
};

export const keywordsColumn = {
  key: 'keywords',
  name: 'common.keywords',
  fieldName: 'relevantWords',
  CellComponent: PillsCell,
  minWidth: 160
};

export const callTypeColumn = {
  ...iconColumn,
  key: 'call_type',
  name: 'common.call_type',
  fieldName: 'callType',
  CellComponent: CallTypeCell,
  minWidth: 50,
  maxWidth: 50,
};

export const crmEntitiesLinksColumn = {
  key: 'crmEntitiesLinks',
  name: 'common.crm_entities_links',
  fieldName: 'crmEntitiesLinks',
  CellComponent: ({ data: crm }) => <CrmEntitiesLinks data={crm} isNewSchema />,
  maxWidth: 340,
  minWidth: 340
};

export const tagsColumn = {
  key: 'tags',
  name: 'common.tags',
  fieldName: 'tags',
  CellComponent: TagsCell,
  maxWidth: 200,
  minWidth: 160
};
