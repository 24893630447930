import React from 'react';
import { OrgCardContainer, OrgImage } from './HostedOrgStyles';

const OrgCard = ({ org: { friendlyName, url } = {}, translatedAlt }) => {
  const presentedName = friendlyName || url;

  return (
    <OrgCardContainer>
      <OrgImage alt={translatedAlt} />
      <div>
        <div>{presentedName}</div>
        {friendlyName && <div>{url}</div>}
      </div>
    </OrgCardContainer>
  );
};

export default OrgCard;
