/*
This file contains a list of all the string keys used in the CallPage component.
Important: These keys are not translations themselves but are used to reference the actual string values in the translation files.
Ensure that you add the corresponding string values in the translation files across all relevant repositories: ci-crm-ui, client, and viva-sales-ci-teams.
*/

export const CALL_STRINGS = {
  DURATION_MINUTES: 'duration_minutes',
  DURATION_SECONDS: 'duration_seconds',
  REQUIRED: 'required',
  MAX_LENGTH: 'max_length',
  ADD: 'add',
  NEXT: 'next',
  BACK: 'back',
  DONE: 'done',
  ADDED: 'added',
  DELETE: 'delete',
  DELETING: 'deleting',
  DISCARD_CHANGES: 'discard_changes',
  EDIT: 'edit',
  CLOSE: 'close',
  CANCEL: 'cancel',
  COPIED: 'copied',
  SAVE: 'save',
  SEND: 'send',
  SEARCH: 'search',
  PARTICIPANTS: 'participants',
  SELLER_RECORDING_ONLY: 'seller_recording_only',
  CUSTOMER: 'customer',
  RELATED_RECORDS: 'related_records',
  TAGS: 'tags',
  ATTENDEES: 'Attendees',
  TRANSLATE: 'translate',
  KPIS: 'kpis',
  CLEAR: 'clear',
  OVERVIEW: 'overview',
  DETAILS: 'details',
  NOTES: 'notes',
  RECAP: 'recap',
  NEGATIVE: 'negative',
  NEUTRAL: 'neutral',
  POSITIVE: 'positive',
  SENTIMENT: 'sentiment',
  OTHERS: 'others',
  RESULTS: 'results',
  ALL_SELLERS: 'all_sellers',
  UNKNOWN_USER: 'unknown_user',
  UNKNOWN: 'unknown',
  TRANSLATE_TO_ENGLISH: 'translate_to_english',
  BACK_TO_ORIGINAL: 'back_to_original',
  SHOW_LESS: 'show_less',
  LEARN_MORE: 'learn_more',
  MEASUREMENT_UNITS_PERCENT: 'measurement_units_percent',
  MEASUREMENT_UNITS_MS: 'measurement_units_ms',
  MEASUREMENT_UNITS_WPM: 'measurement_units_wpm',
  MEASUREMENT_UNITS_SECS: 'measurement_units_secs',
  MEASUREMENT_UNITS_SEC: 'measurement_units_sec',
  KPIS_TALK_TO_LISTEN_RATIO_TITLE: 'kpis_talk_to_listen_ratio_title',
  KPIS_TALK_TO_LISTEN_RATIO_TOOLTIP: 'kpis_talk_to_listen_ratio_tooltip',
  KPIS_AVG_TALKING_SPEED_TITLE: 'kpis_avg_talking_speed_title',
  KPIS_AVG_TALKING_SPEED_TOOLTIP: 'kpis_avg_talking_speed_tooltip',
  KPIS_SWITCH_COUNT_TITLE: 'kpis_switch_count_title',
  KPIS_SWITCH_COUNT_TOOLTIP: 'kpis_switch_count_tooltip',
  KPIS_AVG_PAUSE_TITLE: 'kpis_avg_pause_title',
  KPIS_AVG_PAUSE_TOOLTIP: 'kpis_avg_pause_tooltip',
  KPIS_LONGEST_CUSTOMER_MONOLOGUE_TITLE: 'kpis_longest_customer_monologue_title',
  KPIS_LONGEST_CUSTOMER_MONOLOGUE_TOOLTIP: 'kpis_longest_customer_monologue_tooltip',
  LONGEST_MONOLOGUE: 'longest_monologue',
  CRM_ENTITY_TYPES_ACCOUNT: 'crm_entity_types_account',
  CRM_ENTITY_TYPES_CONTACT: 'crm_entity_types_contact',
  CRM_ENTITY_TYPES_LEAD: 'crm_entity_types_lead',
  CRM_ENTITY_TYPES_PHONECALL_ACTIVITY: 'crm_entity_types_phonecall_activity',
  CRM_ENTITY_TYPES_APPOINTMENT_ACTIVITY: 'crm_entity_types_appointment_activity',
  CRM_ENTITY_TYPES_OPPORTUNITY: 'crm_entity_types_opportunity',
  CRM_ENTITY_TYPES_CAMPAIGN: 'crm_entity_types_campaign',
  CRM_ENTITY_TYPES_CASE: 'crm_entity_types_case',
  CRM_ENTITY_TYPES_ENTITLEMENT: 'crm_entity_types_entitlement',
  CRM_ENTITY_TYPES_ENTITLEMENT_TEMPLATE: 'crm_entity_types_entitlement_template',
  CRM_ENTITY_TYPES_CONTRACT: 'crm_entity_types_contract',
  CRM_ENTITY_TYPES_CAMPAIGN_ACTIVITY: 'crm_entity_types_campaign_activity',
  CRM_ENTITY_TYPES_INVOICE: 'crm_entity_types_invoice',
  CRM_ENTITY_TYPES_KNOWLEDGE_ARTICLE: 'crm_entity_types_knowledge_article',
  CRM_ENTITY_TYPES_QUOTE: 'crm_entity_types_quote',
  CRM_ENTITY_TYPES_SITE: 'crm_entity_types_site',
  SIDE_PIVOT_HIGHLIGHTS: 'side_pivot_highlights',
  SIDE_PIVOT_HIGHLIGHTS_EMPTY_STATE_TITLE: 'side_pivot_highlights_empty_state_title',
  SIDE_PIVOT_ACTION_ITEMS_TITLE: 'side_pivot_action_items_title',
  SIDE_PIVOT_ACTION_ITEMS_ERRORS_GENERAL_TITLE: 'side_pivot_action_items_errors_general_title',
  SIDE_PIVOT_ACTION_ITEMS_ERRORS_GENERAL_SUBTITLE: 'side_pivot_action_items_errors_general_subtitle',
  SIDE_PIVOT_ACTION_ITEMS_EMPTY_STATE_TITLE: 'side_pivot_action_items_empty_state_title',
  SIDE_PIVOT_ACTION_ITEMS_DIRTY_FORM_TOOLTIP: 'side_pivot_action_items_dirty_form_tooltip',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_SUBJECT: 'side_pivot_action_items_creation_form_subject',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_FROM: 'side_pivot_action_items_creation_form_from',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_TO: 'side_pivot_action_items_creation_form_to',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_OWNER: 'side_pivot_action_items_creation_form_owner',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_DETAILS: 'side_pivot_action_items_creation_form_details',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_SET_DATE: 'side_pivot_action_items_creation_form_set_date',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_DATE_NOT_SET: 'side_pivot_action_items_creation_form_date_not_set',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_ADD_NEW_RECORD: 'side_pivot_action_items_creation_form_add_new_record',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_CREATE: 'side_pivot_action_items_creation_form_create',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_CREATE_AND_EDIT: 'side_pivot_action_items_creation_form_create_and_edit',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_CREATE_TASK: 'side_pivot_action_items_creation_form_create_task',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_CREATE_TASK_AND_EDIT: 'side_pivot_action_items_creation_form_create_task_and_edit',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_OPEN_CALENDAR: 'side_pivot_action_items_creation_form_open_calendar',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_CREATE_APPOINTMENT_ACTIVITY: 'side_pivot_action_items_creation_form_create_appointment_activity',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_OPEN_EMAIL: 'side_pivot_action_items_creation_form_open_email',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_CREATE_EMAIL_ACTIVITY: 'side_pivot_action_items_creation_form_create_email_activity',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_REGARDING: 'side_pivot_action_items_creation_form_regarding',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_API_ERROR: 'side_pivot_action_items_creation_form_api_error',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_DEMO_MESSAGE: 'side_pivot_action_items_creation_form_demo_message',
  SIDE_PIVOT_ACTION_ITEMS_DESCRIPTION_NEW_EMAIL: 'side_pivot_action_items_description_new_email',
  SIDE_PIVOT_ACTION_ITEMS_DESCRIPTION_NEW_CALL: 'side_pivot_action_items_description_new_call',
  SIDE_PIVOT_ACTION_ITEMS_DESCRIPTION_NEW_TASK: 'side_pivot_action_items_description_new_task',
  SIDE_PIVOT_ACTION_ITEMS_DESCRIPTION_NEW_MEETING: 'side_pivot_action_items_description_new_meeting',
  SIDE_PIVOT_ACTION_ITEMS_DESCRIPTION_COMMITTED_EMAIL: 'side_pivot_action_items_description_committed_email',
  SIDE_PIVOT_ACTION_ITEMS_DESCRIPTION_COMMITTED_EMAIL_ACTIVITY: 'side_pivot_action_items_description_committed_email_activity',
  SIDE_PIVOT_ACTION_ITEMS_DESCRIPTION_COMMITTED_CALL: 'side_pivot_action_items_description_committed_call',
  SIDE_PIVOT_ACTION_ITEMS_DESCRIPTION_COMMITTED_TASK: 'side_pivot_action_items_description_committed_task',
  SIDE_PIVOT_ACTION_ITEMS_DESCRIPTION_COMMITTED_MEETING: 'side_pivot_action_items_description_committed_meeting',
  SIDE_PIVOT_ACTION_ITEMS_DESCRIPTION_COMMITTED_MEETING_ACTIVITY: 'side_pivot_action_items_description_committed_meeting_activity',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_CRM_RECORD_LOOKUP_NEW_RECORD_LABEL: 'side_pivot_action_items_creation_form_crm_record_lookup_new_record_label',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_CRM_RECORD_LOOKUP_API_ERROR: 'side_pivot_action_items_creation_form_crm_record_lookup_api_error',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_CRM_RECORD_LOOKUP_EMPTY_RESULT: 'side_pivot_action_items_creation_form_crm_record_lookup_empty_result',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_CRM_RECORD_LOOKUP_LOOK_FOR_RECORD: 'side_pivot_action_items_creation_form_crm_record_lookup_look_for_record',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_CRM_RECORD_LOOKUP_CREATE_NEW_RECORD: 'side_pivot_action_items_creation_form_crm_record_lookup_create_new_record',
  SIDE_PIVOT_ACTION_ITEMS_CREATION_FORM_CRM_RECORD_LOOKUP_CREATE_NEW_RECORD_TOOLTIP: 'side_pivot_action_items_creation_form_crm_record_lookup_create_new_record_tooltip',
  SIDE_PIVOT_ACTION_ITEMS_LOADING_TITLE: 'side_pivot_action_items_loading_title',
  SIDE_PIVOT_ACTION_ITEMS_LOADING_SUBTITLE: 'side_pivot_action_items_loading_subtitle',
  SIDE_PIVOT_EXEC_SUMMARY_FEATURE_TOUR_FIRST_BUBBLE_HEADLINE: "side_pivot_exec_summary_feature_tour_first_bubble_headline",
  SIDE_PIVOT_EXEC_SUMMARY_FEATURE_TOUR_FIRST_BUBBLE_CONTENT: "side_pivot_exec_summary_feature_tour_first_bubble_content",
  SIDE_PIVOT_EXEC_SUMMARY_FEATURE_TOUR_SECOND_BUBBLE_HEADLINE: "side_pivot_exec_summary_feature_tour_second_bubble_headline",
  SIDE_PIVOT_EXEC_SUMMARY_FEATURE_TOUR_SECOND_BUBBLE_CONTENT: "side_pivot_exec_summary_feature_tour_second_bubble_content",
  SIDE_PIVOT_EXEC_SUMMARY_FEATURE_TOUR_THIRD_BUBBLE_HEADLINE: "side_pivot_exec_summary_feature_tour_third_bubble_headline",
  SIDE_PIVOT_EXEC_SUMMARY_FEATURE_TOUR_THIRD_BUBBLE_CONTENT: "side_pivot_exec_summary_feature_tour_third_bubble_content",
  EXEC_SUMMARY_SUGGESTIONS_TOOLTIP: 'exec_summary_suggestions_tooltip',
  EXEC_SUMMARY_SUGGESTIONS_PANEL_TITLE: 'exec_summary_suggestions_panel_title',
  EXEC_SUMMARY_SUGGESTIONS_PANEL_ADD_ALL: 'exec_summary_suggestions_panel_add_all',
  EXEC_SUMMARY_SUGGESTIONS_PANEL_SEE_ALL: 'exec_summary_suggestions_panel_see_all',
  EXEC_SUMMARY_SUGGESTIONS_PANEL_SEE_LESS: 'exec_summary_suggestions_panel_see_less',
  EXEC_SUMMARY_SUGGESTIONS_PANEL_TITLE_TOOLTIP: 'exec_summary_suggestions_panel_title_tooltip',
  EXEC_SUMMARY_SUGGESTIONS_PANEL_ACTION_ITEMS_TITLE_TOOLTIP: 'exec_summary_suggestions_panel_action_items_title_tooltip',
  EXEC_SUMMARY_SUGGESTIONS_PANEL_ADD_ENABLED_TOOLTIP: 'exec_summary_suggestions_panel_add_enabled_tooltip',
  EXEC_SUMMARY_SUGGESTIONS_PANEL_ADD_DISABLED_TOOLTIP: 'exec_summary_suggestions_panel_add_disabled_tooltip',
  EXEC_SUMMARY_SUGGESTIONS_PANEL_TIMESTAMP_TOOLTIP: 'exec_summary_suggestions_panel_timestamp_tooltip',
  EXEC_SUMMARY_SUGGESTIONS_PANEL_HIGHLIGHTS_TITLE: 'exec_summary_suggestions_panel_highlights_title',
  EXEC_SUMMARY_SUGGESTIONS_PANEL_ACTION_ITEMS_TITLE: 'exec_summary_suggestions_panel_action_items_title',
  EXEC_SUMMARY_SUGGESTIONS_PANEL_PARTNER_HIGHLIGHTS_TITLE: 'exec_summary_suggestions_panel_partner_highlights_title',
  EXEC_SUMMARY_SUGGESTIONS_PANEL_PARTNER_HIGHLIGHTS_SOURCE: 'exec_summary_suggestions_panel_partner_highlights_source',
  EXEC_SUMMARY_SUGGESTIONS_PANEL_NO_SUGGESTIONS: 'exec_summary_suggestions_panel_no_suggestions',
  EXEC_SUMMARY_SUGGESTIONS_PANEL_COPY_BUTTON_TOOLTIP: 'exec_summary_suggestions_panel_copy_button_tooltip',
  EXEC_SUMMARY_SUGGESTIONS_PANEL_HEADER: 'exec_summary_suggestions_panel_header',
  EXEC_SUMMARY_SUGGESTIONS_INTERNAL_MEETING_DISCLAIMER: 'exec_summary_suggestions_internal_meeting_disclaimer',
  EXEC_SUMMARY_CALL_DURATION_HIGHLIGHTS_DISCLAIMER: 'exec_summary_call_duration_highlights_disclaimer',
  EXEC_SUMMARY_RECAP_EMPTY_STATE_TITLE: 'exec_summary_recap_empty_state_title',
  EXEC_SUMMARY_RECAP_EMPTY_STATE_SUBTITLE: 'exec_summary_recap_empty_state_subtitle',
  EXEC_SUMMARY_BOARD_SAVE_ERROR_MESSAGE: 'exec_summary_board_save_error_message',
  EXEC_SUMMARY_BOARD_SAVE_PERMISSION_MESSAGE: 'exec_summary_board_permission_error_message',
  EXEC_SUMMARY_BOARD_DISCARD_ERROR_MESSAGE: 'exec_summary_board_discard_error_message',
  EXEC_SUMMARY_BOARD_CALL_DATE_AND_TIME: 'exec_summary_board_call_date_and_time',
  EXEC_SUMMARY_BOARD_TEXT_PLACEHOLDER: 'exec_summary_board_text_placeholder',
  EXEC_SUMMARY_BOARD_READ_WRITE_PLACEHOLDER: 'exec_summary_board_read_write_placeholder',
  EXEC_SUMMARY_BOARD_READ_ONLY_PLACEHOLDER_TITLE: 'exec_summary_board_read_only_placeholder_title',
  EXEC_SUMMARY_BOARD_READ_ONLY_PLACEHOLDER_SUBTITLE: 'exec_summary_board_read_only_placeholder_subtitle',
  EXEC_SUMMARY_BOARD_UNSAVED_CHANGES_TITLE: 'exec_summary_board_unsaved_changes_title',
  EXEC_SUMMARY_BOARD_UNSAVED_CHANGES_SUBTITLE: 'exec_summary_board_unsaved_changes_subtitle',
  EXEC_SUMMARY_EMPTY_STATE_TITLE: 'exec_summary_empty_state_title',
  EXEC_SUMMARY_EMPTY_STATE_SUBTITLE: 'exec_summary_empty_state_subtitle',
  KEYWORDS_PANEL_ACTION_ITEM_TITLE: 'keywords_panel_action_item_title',
  KEYWORDS_PANEL_ACTION_ITEM_TOOLTIP: 'keywords_panel_action_item_tooltip',
  KEYWORDS_PANEL_EMOTIONS_TITLE: 'keywords_panel_emotions_title',
  KEYWORDS_PANEL_EMOTIONS_TOOLTIP: 'keywords_panel_emotions_tooltip',
  KEYWORDS_PANEL_EMOTIONS_KEYWORDS_HAPPY: 'keywords_panel_emotions_keywords_happy',
  KEYWORDS_PANEL_EMOTIONS_KEYWORDS_NEUTRAL: 'keywords_panel_emotions_keywords_neutral',
  KEYWORDS_PANEL_EMOTIONS_KEYWORDS_SAD: 'keywords_panel_emotions_keywords_sad',
  KEYWORDS_PANEL_EMOTIONS_KEYWORDS_ANGER: 'keywords_panel_emotions_keywords_anger',
  KEYWORDS_PANEL_EMOTIONS_KEYWORDS_FEAR: 'keywords_panel_emotions_keywords_fear',
  KEYWORDS_PANEL_COMPETITORS_TITLE: 'keywords_panel_competitors_title',
  KEYWORDS_PANEL_COMPETITORS_TOOLTIP: 'keywords_panel_competitors_tooltip',
  KEYWORDS_PANEL_BRANDS_TITLE: 'keywords_panel_brands_title',
  KEYWORDS_PANEL_BRANDS_TOOLTIP: 'keywords_panel_brands_tooltip',
  KEYWORDS_PANEL_TRACKED_KEYWORDS_TITLE: 'keywords_panel_tracked_keywords_title',
  KEYWORDS_PANEL_TRACKED_KEYWORDS_TOOLTIP: 'keywords_panel_tracked_keywords_tooltip',
  KEYWORDS_PANEL_KEYWORDS_TITLE: 'keywords_panel_keywords_title',
  KEYWORDS_PANEL_KEYWORDS_TOOLTIP: 'keywords_panel_keywords_tooltip',
  KEYWORDS_PANEL_PEOPLE_TITLE: 'keywords_panel_people_title',
  KEYWORDS_PANEL_PEOPLE_TOOLTIP: 'keywords_panel_people_tooltip',
  KEYWORDS_PANEL_PRODUCTS_TITLE: 'keywords_panel_products_title',
  KEYWORDS_PANEL_PRODUCTS_TOOLTIP: 'keywords_panel_products_tooltip',
  KEYWORDS_PANEL_TIMES_TITLE: 'keywords_panel_times_title',
  KEYWORDS_PANEL_TIMES_TOOLTIP: 'keywords_panel_times_tooltip',
  KEYWORDS_PANEL_PRICES_TITLE: 'keywords_panel_prices_title',
  KEYWORDS_PANEL_PRICES_TOOLTIP: 'keywords_panel_prices_tooltip',
  KEYWORDS_PANEL_SELLER_QUESTIONS_TITLE: 'keywords_panel_seller_questions_title',
  KEYWORDS_PANEL_SELLER_QUESTIONS_TOOLTIP: 'keywords_panel_seller_questions_tooltip',
  KEYWORDS_PANEL_CUSTOMER_QUESTIONS_TITLE: 'keywords_panel_customer_questions_title',
  KEYWORDS_PANEL_CUSTOMER_QUESTIONS_TOOLTIP: 'keywords_panel_customer_questions_tooltip',
  TIMELINE_SENTIMENT_TOOLTIP: 'timeline_sentiment_tooltip',
  CALL_SIDEBAR_TRANSCRIPT_TITLE: 'call_sidebar_transcript_title',
  CALL_SIDEBAR_TRANSCRIPT_RESYNC: 'call_sidebar_transcript_resync',
  CALL_SIDEBAR_TRANSCRIPT_COMMENTS_COMMENT: 'call_sidebar_transcript_comments_comment',
  CALL_SIDEBAR_TRANSCRIPT_COMMENTS_COMMENTED: 'call_sidebar_transcript_comments_commented',
  CALL_SIDEBAR_TRANSCRIPT_COMMENTS_MORE_COMMENTS: 'call_sidebar_transcript_comments_more_comments',
  CALL_SIDEBAR_TRANSCRIPT_COMMENTS_MORE_COMMENTS_PLURAL: 'call_sidebar_transcript_comments_more_comments_plural',
  CALL_SIDEBAR_TRANSCRIPT_COMMENTS_DELETE_COMMENT: 'call_sidebar_transcript_comments_delete_comment',
  CALL_SIDEBAR_TRANSCRIPT_COMMENTS_API_ERROR: 'call_sidebar_transcript_comments_api_error',
  CALL_SIDEBAR_TRANSCRIPT_EMPTY_TITLE: 'call_sidebar_transcript_empty_title',
  CALL_SIDEBAR_TRANSCRIPT_EMPTY_SUBTITLE: 'call_sidebar_transcript_empty_subtitle',
  CALL_PROCESSING_BAR: 'call_processing_bar',
  CALL_READ_ONLY: 'call_read_only',
  CALL_PROCESSING_FAILURE: 'call_processing_failure',
  CALL_SEGMENT_TYPES_REFUND: 'call_segment_types_refund',
  CALL_SEGMENT_TYPES_SCHEDULE_MEETING: 'call_segment_types_schedule_meeting',
  CALL_SEGMENT_TYPES_E_LEARNING_PLATFORM: 'call_segment_types_e_learning_platform',
  CALL_SEGMENT_TYPES_DISCUSSION: 'call_segment_types_discussion',
  CALL_SEGMENT_TYPES_UNTITLED: 'call_segment_types_untitled',
  CALL_SEGMENT_TYPES_GREETINGS_AND_INTRODUCTION: 'call_segment_types_greetings_and_introduction',
  CALL_SEGMENT_TYPES_ASSISTANCE_REQUEST_AND_COMPLAINT: 'call_segment_types_assistance_request_and_complaint',
  CALL_SEGMENT_TYPES_CALL_CLOSING_OR_BYE: 'call_segment_types_call_closing_or_bye',
  CALL_SEGMENT_TYPES_CUSTOMER_IDENTIFICATION_DETAILS: 'call_segment_types_customer_identification_details',
  CALL_SEGMENT_TYPES_PRICE_REQUEST: 'call_segment_types_price_request',
  CALL_SEGMENT_TYPES_VOICE_MAIL: 'call_segment_types_voice_mail',
  CALL_ERROR_TITLE: 'call_error_title',
  CALL_ERROR_SUBTITLE: 'call_error_subtitle',
  NO_CALL_ERROR_TITLE: 'no_call_error_title',
  NO_CALL_ERROR_SUBTITLE: 'no_call_error_subtitle',
  SHORT_CALL_ERROR_SUBTITLE: 'short_call_error_subtitle',
  COPY_CALL_TITLE: 'copy_call_title',
  COPY_CALL_TOOLTIP: 'copy_call_tooltip',
  COPIED_CALL_TOOLTIP: 'copied_call_tooltip',
  TAG_LIST_ADD_CALL_TAGS: 'tag_list_add_call_tags',
  TAG_LIST_ERRORS_SERVER_ERROR: 'tag_list_errors_server_error',
  TAG_LIST_ERRORS_ALREADY_EXIST_ERROR: 'tag_list_errors_already_exist_error',
  ADD_NUMBER: 'add_number',
  VIEW_CONTACT: 'view_contact',
  SEE_DETAILS: 'see_details',
  UNLINK_FROM_CONTACT: 'unlink_from_contact',
  LINK_TO_CONTACT: 'link_to_contact',
  LINKING_WITH_CONTACT: 'linking_with_contact',
  UNLINKING_CONTACT: 'unlinking_contact',
  ERROR_UPDATE_CONTACT: 'error_update_contact',
  ADD_NUMBER_TOOLTIP: 'add_number_tooltip',
  LOOK_FOR_CONTACT: 'look_for_contact',
  COMMENTS: 'comments',
  COMMENTS_CHECKBOX_TOOLTIP: 'comments_checkbox_tooltip',
  DELETE_INSIGHTS: 'delete_insights',
  DELETE_INSIGHTS_TITLE: 'delete_insights_title',
  DELETE_INSIGHTS_MESSAGE: 'delete_insights_message',
  DELETE_INSIGHTS_FAILED: 'delete_insights_failed',
  TWO_PARTICIPANTS_SUBJECT: "%identifier1% and %identifier2%",
  MULTIPLE_PARTICIPANTS_SUBJECT: "%identifier% and %number% others",
};
