import React from 'react';
import get from 'lodash/get';
import useLocale from 'hooks/use-locale';
import { scrollViewNames } from 'constants/selectors';
import useEmptyState from 'utils/empty-state/use-empty-state';
import isEmpty from 'lodash/isEmpty';
import PageSection from '../../../components/PageSection/PageSection';
import { createOverallConfig, createOvertimeConfig, createSentimentByAgentConfig } from './chart-configs';
import ChartCardsRow from '../../../components/ChartCardsRow/ChartCardsRow';
import { TRACK_IDS } from '../../../../constants/tracking';
import { joinTrackDelimiter } from '../../../../utils/string/string';
import { getExcelDataFromPie } from '../../../../utils/chart';
import { Link } from '../../../../components/Link/Link';

const LOCALIZATION_PREFIX = 'pages.team.sections.customer_sentiment';

const CustomerSentiment = ({ isLoading, data, error }) => {
  const { t, tAbs } = useLocale(LOCALIZATION_PREFIX);
  const { getEmptyStateDetails } = useEmptyState(LOCALIZATION_PREFIX);
  const overallCustomerSentimentData = get(data, 'overallCustomerSentiment');
  const customerSentimentGeneratedByAgentData = get(data, 'customerSentimentGeneratedByAgent');
  const sentimentOverTimeData = get(data, 'sentimentOverTime');

  const cards = [{
    trackId: joinTrackDelimiter([TRACK_IDS.COMMON.OVERALL, TRACK_IDS.COMMON.CUSTOMER_SENTIMENT]),
    header: t('cards.overall.title'),
    csvData: getExcelDataFromPie(overallCustomerSentimentData),
    getChartConfig: () => createOverallConfig(overallCustomerSentimentData, tAbs),
    emptyStateDetails: !isLoading && isEmpty(get(overallCustomerSentimentData, 'series')) && getEmptyStateDetails('cards.overall'),
    hasError: get(error, 'overallCustomerSentiment'),
  }, {
    trackId: joinTrackDelimiter([TRACK_IDS.COMMON.CUSTOMER_SENTIMENT, TRACK_IDS.COMMON.BY_AGENT]),
    header: t('cards.seller.title'),
    csvData: customerSentimentGeneratedByAgentData,
    getChartConfig: () => createSentimentByAgentConfig(customerSentimentGeneratedByAgentData, tAbs),
    scrollViewName: scrollViewNames.customerSentimentBySeller,
    emptyStateDetails: !isLoading && isEmpty(customerSentimentGeneratedByAgentData) && getEmptyStateDetails('cards.seller'),
    hasError: get(error, 'customerSentimentGeneratedByAgent'),
  }, {
    trackId: joinTrackDelimiter([TRACK_IDS.COMMON.CUSTOMER_SENTIMENT, TRACK_IDS.COMMON.OVER_TIME]),
    header: t('cards.time.title'),
    csvData: sentimentOverTimeData,
    getChartConfig: () => createOvertimeConfig(sentimentOverTimeData, tAbs),
    emptyStateDetails: !isLoading && isEmpty(sentimentOverTimeData) && getEmptyStateDetails('cards.time'),
    hasError: get(error, 'sentimentOverTime'),
  }];

  const title = t('title');
  const subtitle = (
    <>
      {t('subtitle')}
      <Link href="https://go.microsoft.com/fwlink/?linkid=2101248" target="_blank" actionOn={joinTrackDelimiter([TRACK_IDS.COMMON.CUSTOMER_SENTIMENT, TRACK_IDS.COMMON.LEARN_MORE])}>{tAbs('common.learn_more')}</Link>
    </>
  );

  return (
    <PageSection title={title} subtitle={subtitle} isLoading={isLoading}>
      <ChartCardsRow isLoading={isLoading} cards={cards} />
    </PageSection>
  );
};
export default CustomerSentiment;
