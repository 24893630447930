import { useEffect } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { usePrevious } from 'ci-common-ui';
import DataCacheService from 'services/DataCacheService';
import { getUtcStartEndDate } from '../utils/url/url';

export const useQueryDateChange = (runFunc, params = {}) => {
  const [query] = useQueryParams({ startDate: StringParam, endDate: StringParam });
  let startDate = usePrevious(query.startDate);
  let endDate = usePrevious(query.endDate);
  useEffect(() => {
    if (endDate && startDate) {
      const isDateChanged = query.startDate !== startDate || query.endDate !== endDate;
      if (isDateChanged) {
        DataCacheService.removeAll();

        runFunc({
          ...params,
          ...getUtcStartEndDate(query.startDate, query.endDate)
        });
        startDate = query.startDate;
        endDate = query.endDate;
      }
    }
  }, [query]);
};
