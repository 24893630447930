// @flow
/* eslint-disable new-cap */
import { useEffect, useState, useCallback } from 'react';
import { CRM_POST_MESSAGE_TYPES, SOUND_PLAYER_MESSAGE_TYPES } from '../constants/post-messages';
import { SEEK_INTERVAL, soundStatus } from '../constants/howler';
import { isAllowedOrigin } from '../utils/security/security';
import { TRACK_IDS } from '../constants/tracking';
import { useInfra } from '../providers/InfraProvider';

let timerInterval = null;

export const useSoundProxy = (
  url,
  onLoad,
  onLoadError,
  initialCurrentTime,
  requestHowlerDataFromIframe
) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayInLoading, setIsPlayInLoading] = useState(false);
  const [isPauseInLoading, setIsPauseInLoading] = useState(false);
  const [duration, setDuration] = useState(null);
  const [instanceId, setInstanceId] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentVolume, setCurrentVolume] = useState();
  const [status, setStatus] = useState(soundStatus.LOADING);
  const { trackError } = useInfra();

  const handleNewUrlEvents = (event) => {
    if (!isAllowedOrigin(event.origin)) {
      trackError({ actionOn: TRACK_IDS.INVALID_IFRAME_MESSAGE_ORIGIN, message: { origin: event.origin } });
      return;
    }

    const isHowlerResponse = event?.data?.msg === CRM_POST_MESSAGE_TYPES.PROXY_HOWLER_RESPONSE;
    const msgType = event?.data?.type;
    if (isHowlerResponse) {
      switch (msgType) {
        case SOUND_PLAYER_MESSAGE_TYPES.ON_HOWLER_LOAD_SUCCESS: {
          handleLoad(event.data.data);
          break;
        }
        case SOUND_PLAYER_MESSAGE_TYPES.ON_HOWLER_LOAD_ERROR: {
          handleLoadError(event.data.data);
          break;
        }
        case SOUND_PLAYER_MESSAGE_TYPES.ON_HOWLER_END: {
          stopCurrentTimeInterval();
          setIsPlaying(false);
          break;
        }
        case SOUND_PLAYER_MESSAGE_TYPES.ON_HOWLER_PLAY: {
          setIsPlayInLoading(false);
          setIsPlaying(true);
          break;
        }
        case SOUND_PLAYER_MESSAGE_TYPES.ON_HOWLER_PAUSE: {
          setIsPauseInLoading(false);
          setIsPlaying(false);
          break;
        }
        default: // do nothing
      }
    }
  };

  function handleLoad({ id, initialDuration }) {
    if (onLoad) {
      onLoad();
    }
    setInstanceId(id);
    setDuration(initialDuration);
    setStatus(soundStatus.LOADED);
  }

  function handleLoadError({ err }) {
    if (onLoadError) {
      onLoadError(err);
    }

    setStatus(soundStatus.FAILED);
  }

  function stopCurrentTimeInterval() {
    clearInterval(timerInterval);
  }

  const setCurrentTimeInterval = () => {
    timerInterval = setInterval(async () => {
      if (!instanceId) { return; }
      const seek = await requestHowlerDataFromIframe(SOUND_PLAYER_MESSAGE_TYPES.SEEK, undefined, true);
      if (typeof seek === 'object') return;
      setCurrentTime(seek);
    }, SEEK_INTERVAL);
  };

  // lazy load Howler
  useEffect(() => {
    if(!url) {
      setStatus(soundStatus.FAILED);
      return;
    };

    const loadUrl = async () => {
      requestHowlerDataFromIframe(SOUND_PLAYER_MESSAGE_TYPES.LOAD_URL, { url, volume: currentVolume });
      if (instanceId) { // for token expired
        requestHowlerDataFromIframe(SOUND_PLAYER_MESSAGE_TYPES.SEEK, { time: currentTime });
      }
    };

    setStatus(soundStatus.LOADING);
    loadUrl();
  }, [url]);

  useEffect(() => {
    if (instanceId && initialCurrentTime && initialCurrentTime !== currentTime) {
      moveTo(initialCurrentTime);
    }
  }, [instanceId, initialCurrentTime]);

  useEffect(() => {
    window.addEventListener('message', handleNewUrlEvents);
    return () => {
      window.removeEventListener('message', handleNewUrlEvents);
    };
  }, []);

  useEffect(() => destroySound, [instanceId]);

  const destroySound = () => {
    stopCurrentTimeInterval();
    if (instanceId) {
      requestHowlerDataFromIframe(SOUND_PLAYER_MESSAGE_TYPES.OFF);
      requestHowlerDataFromIframe(SOUND_PLAYER_MESSAGE_TYPES.STOP);
    }
  };

  const setVolume = (volume) => {
    if (instanceId) {
      requestHowlerDataFromIframe(SOUND_PLAYER_MESSAGE_TYPES.SET_VOLUME, { volume });
      setCurrentVolume(volume);
    }
  };

  const play = useCallback(async (id) => {
    if (!instanceId) { return; }
    setIsPlayInLoading(true);
    await requestHowlerDataFromIframe(SOUND_PLAYER_MESSAGE_TYPES.PLAY, { id }, true);
    setCurrentTimeInterval();
  },
  [instanceId]);

  const pause = useCallback((id) => {
    if (!instanceId) { return; }
    setIsPauseInLoading(true);
    requestHowlerDataFromIframe(SOUND_PLAYER_MESSAGE_TYPES.PAUSE, { id });
    stopCurrentTimeInterval();
  },
  [instanceId]);

  const stop = useCallback((id) => {
    if (!instanceId) { return; }
    requestHowlerDataFromIframe(SOUND_PLAYER_MESSAGE_TYPES.STOP, { id });
    setIsPlaying(false);
  },
  [instanceId]);

  const togglePlayPause = useCallback(async (id) => {
    if (!instanceId) return;
    const playing = await requestHowlerDataFromIframe(SOUND_PLAYER_MESSAGE_TYPES.IS_PLAYING, undefined, true);
    if (playing) {
      pause(id);
    } else {
      play(id);
    }
  }, [instanceId]);

  const forward = useCallback((seconds) => {
    if (!instanceId) { return; }
    let newCurrentTime = currentTime + seconds;
    if (duration && newCurrentTime >= duration) {
      stop();
      return;
    }
    newCurrentTime = newCurrentTime < 0 ? 0 : newCurrentTime;
    requestHowlerDataFromIframe(SOUND_PLAYER_MESSAGE_TYPES.SEEK, { time: newCurrentTime });
    setCurrentTime(newCurrentTime);
  }, [instanceId, currentTime, duration]);

  const moveTo = useCallback((newTime) => {
    setCurrentTime(newTime); // enable changing the current time in the timeline even if the audio is not loaded

    if (!instanceId) { return; }
    requestHowlerDataFromIframe(SOUND_PLAYER_MESSAGE_TYPES.SEEK, { time: newTime });
  }, [instanceId]);

  const returnedValue = {
    status,
    play,
    togglePlayPause,
    pause,
    forward,
    moveTo,
    isPlaying,
    isPlayInLoading,
    isPauseInLoading,
    duration,
    setVolume,
    currentTime
  };

  return returnedValue;
};
