import styled from 'styled-components';
import { FlexColumnCentered, StyledLink } from '../../../utils/styles/StyledUtils';
import imageProvisionCompleteSrc from '../../../assets/images/provision-complete.png';
import imageProvisionCompleteTeamsSrc from '../../../assets/images/provision-complete-teams.png';
import { rtl } from '../../../utils/styles/rtl';

const welcomeImageBP = '800px';

export const Container = styled(FlexColumnCentered)`
  height: 100%;
  color: ${({ theme }) => theme.colors.Gray160};
`;

export const WelcomeImage = styled.img.attrs(({ theme }) => ({
  alt: 'dynamic image',
  src: theme.name === 'dark' ? imageProvisionCompleteTeamsSrc : imageProvisionCompleteSrc,
}))`
  height: 140px;
  @media (max-height: ${welcomeImageBP}) {
    display: none;
  }
`;

export const Text = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 15px;
  text-align: center;
`;

export const SubText = styled.div`
  width: 500px;
  max-width: 80%;
  font-size: 14px;
  margin-bottom: 15px;
  text-align: center;
`;

export const Link = styled(StyledLink)`
  ${rtl`margin-left`}: 4px;
`;
