import React, { useEffect } from 'react';
import useLocale from 'hooks/use-locale';
import { DefaultButton, Persona, PersonaSize } from '@fluentui/react';
import { Form, AutoCompleteField, useFetchNow, useFetch, callService } from 'ci-common-ui';
import { Container, Content, Title, SubTitle, Details, Loading, Error } from './StyledSearchOpportunity';

const { getSuggestedOpportunity, getOpportunityOptions, getOpportunityDetails } = callService;

const SelectedOpportunityDetails = ({ opportunityId }) => {
  const { t, tAbs } = useLocale('pages.call.sidebar.related_opportunity');
  const { data, fetchData, isLoading, error } = useFetch(getOpportunityDetails);

  useEffect(() => {
    if (opportunityId) {
      fetchData(opportunityId);
    }
  }, [opportunityId]);

  if (!opportunityId) {
    return null;
  }

  if (isLoading || !data) {
    return <Loading />;
  }

  if (error) {
    return <Error>{t('error')}</Error>;
  }

  return (
    (
      <>
        <Details>
          <Persona
            size={PersonaSize.size72}
            text={data.owner}
            secondaryText={data.account && data.account.name}
            tertiaryText={data.contact && data.contact.name}
          />
        </Details>
        <DefaultButton text={tAbs('common.add')} type="submit" />
      </>
    )
  );
};

const SearchOpportunity = ({ addOpportunity }) => {
  const { t, tAbs } = useLocale('pages.call.sidebar.related_opportunity');
  const { data: suggestedOpportunity, isLoading } = useFetchNow(getSuggestedOpportunity);

  if (isLoading) {
    return <Loading />;
  }

  const initialValues = suggestedOpportunity ? { search: suggestedOpportunity } : null;

  const onSubmit = (values) => {
    addOpportunity(values.selectedOpportunity.opportunityId);
  };

  return (
    <Container>
      <Content>
        <Title>{t('title')}</Title>
        <SubTitle>{t('subtitle')}</SubTitle>

        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          autocomplete="off"
          render={({
            handleSubmit, values
          }) => (
            <form onSubmit={handleSubmit} noValidate>

              <AutoCompleteField name="selectedOpportunity" placeholder={t('search_opportunity')} getOptions={getOpportunityOptions} errorMessages={{ required: tAbs('common.required'), search: tAbs('common.fetch_error') }} />

              <SelectedOpportunityDetails opportunityId={values.selectedOpportunity && values.selectedOpportunity.opportunityId} />

            </form>
          )}
        />

      </Content>
    </Container>
  );
};

export default SearchOpportunity;
