import React from 'react';
import { get } from 'lodash';
import { PivotItem } from '@fluentui/react';
import { Overview } from '../../../Overview';
import { CALL_STRINGS } from '../../../../strings';
import { PivotItemContainer } from '../PivotItemContainer/PivotItemContainer';

const OVERVIEW_TAB = 'Overview';

export const OverviewPivotItem = ({
  conversationId,
  data,
  shouldHideMainHeader,
  isViva,
  duration,
  setNewParticipantDetails,
  isOnlyAgentsRecorded,
  selectedSegment,
  clearSelectedSegment,
  onRenderItemLink,
  avatars,
  t,
}) => (
  <PivotItem
    itemKey={OVERVIEW_TAB}
    headerText={t(isViva ? CALL_STRINGS.DETAILS : CALL_STRINGS.OVERVIEW)}
    onRenderItemLink={onRenderItemLink}
    alwaysRender
  >
    <PivotItemContainer
      selectedSegment={selectedSegment}
      clearSelectedSegment={clearSelectedSegment}
    >
      <Overview
        data={get(data, 'summary')}
        id={conversationId}
        participants={get(data, 'participants')}
        shouldHideMainHeader={shouldHideMainHeader}
        isViva={isViva}
        duration={duration}
        avatars={avatars}
        setNewParticipantDetails={setNewParticipantDetails}
        isOnlyAgentsRecorded={isOnlyAgentsRecorded}
      />
    </PivotItemContainer>
  </PivotItem>
);
