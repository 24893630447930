import styled from 'styled-components';
import { colors, FlexColumn, FlexRowCentered, dataAutomation } from 'ci-common-ui';
import { AUTOMATION_SELECTORS } from '../../../constants/selectors';

export const SectionCardContainer = styled(FlexColumn).attrs({
  [dataAutomation]: AUTOMATION_SELECTORS.PAGE_CARD
})`
  flex: 1;
  flex-basis: auto;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.065);
  border-radius: 2px;
  transition: box-shadow 0.5s ease 0s;
  &:hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.065), 0px 4px 4px rgba(0, 0, 0, 0.065);
  }
`;

export const Header = styled(FlexRowCentered)`
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid ${colors.Gray30};
  color: ${colors.Gray160};
  font-size: 14px;
  font-weight: bold;
`;

export const Body = styled.div`
  padding: ${({ hasHeader }) => (hasHeader ? '8px 16px 16px 16px' : '16px')};
  flex: 1;
  flex-basis: auto;
`;

export const ChartLoaderContainer = styled(SectionCardContainer)`
  padding: 16px;
  & .chart-shimmer > .ms-Shimmer-shimmerWrapper {
    height: ${({ shimmerHeight }) => shimmerHeight || 120}px;
  }
`;

export const LineSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.Gray30};
  margin: 20px 0;
`;
