import React from 'react';
import useLocale from 'hooks/use-locale';
import { IconButton } from '@fluentui/react';
import { Actions, PrimaryButtonStyled, TabBody, TabHeader } from '../../../StyledSettingsModal';

const UploadCallsWrapper = ({ children, onCancel, onSave }) => {
  const { t, tAbsDefault } = useLocale('pages.settings.upload_calls');

  return (
    <>
      <TabHeader>
        <span>{t('nav_title')}</span>
        <Actions>
          <PrimaryButtonStyled text={t('save_and_upload')} onClick={onSave} />
          <IconButton iconProps={{ iconName: 'Cancel' }} ariaLabel={`${tAbsDefault('common.close')} ${tAbsDefault('common.settings')}`} onClick={onCancel} />
        </Actions>
      </TabHeader>
      <TabBody>
        {children}
      </TabBody>
    </>
  );
};

export default UploadCallsWrapper;
