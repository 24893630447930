import { css } from 'styled-components';

export const breakPoints = {
  smallMobile: 400,
  mobile: 640,
  bigMobile: 750,
  tablet: 1024
};

export const getIsSmallMobile = (width = window.innerWidth) => width <= breakPoints.smallMobile;
export const getIsMobile = (width = window.innerWidth) => width <= breakPoints.mobile;
export const getIsBigMobile = (width = window.innerWidth) => width <= breakPoints.bigMobile;
export const getIsTablet = (width = window.innerWidth) => width <= breakPoints.tablet;
export const getIsDesktop = (width = window.innerWidth) => width > breakPoints.tablet;

export const deviceSizes = Object.keys(breakPoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
      @media (max-width: ${breakPoints[label]}px) {
        ${css(...args)};
      }
  `;
  return acc;
}, {});
