import toString from 'lodash/toString';
import get from 'lodash/get';
import find from 'lodash/find';
import { PARTICIPANTS_ROLES } from 'ci-common-ui';
import { transformServerDate } from './dateFilters/date-filters';
import { dateFromServer } from '../../utils/date/date';
import { getContactsFromParticipantsObject, transformCrm } from './utils';

const KEYWORDS_BY_AGENT_ID = 'keywordsByAgent';
const CUSTOMER_TRACKERS_ID = 'customerTrackers';
const CUSTOMER_COMPETITORS_ID = 'customerCompetitors';
const CUSTOMER_BRANDS_ID = 'customerBrands';
const KEYWORDS_BY_CUSTOMER_ID = 'keywordsByCustomer';
const AGENT_TRACKERS_ID = 'agentTrackers';
const AGENT_COMPETITORS_ID = 'agentCompetitors';
const AGENT_BRANDS_ID = 'agentBrands';

export const MATCHES_KEYS = {
  SUBJECT: 'subject',
  BRANDS: 'brands',
  COMPETITORS: 'competitors',
  KEYWORDS: 'keywords',
  TAGS: 'tags',
  TRACKERS: 'trackers',
};

const getRelevantKeywords = (data, keys, ids) => get(data, keys, []).filter((i1) => ids.indexOf(i1.type) > -1).map((i2) => i2.actualWord);

export const transformCallsSearchData = (data = {}) => {
  const { pagesCount, currentPage } = data;
  const conversations = data.conversations || [];

  return {
    currentPage,
    pagesCount,
    dateTimeRange: transformServerDate(data),
    conversations: conversations.map((row) => {
      const matches = get(row, 'matches', {});
      return ({
        ...row,
        key: row.id,
        id: toString(row.id),
        callTopic: row.subject,
        commentsCount: row.commentsCount,
        agentName: get(find(get(row, 'participants', []), ({ typeId, role }) => [typeId, role].includes(PARTICIPANTS_ROLES.agent)), 'displayName'),
        tags: get(row, 'tags', []).map((t) => t.tag),
        callType: { callScope: row.scope, callDirection: row.direction },
        customerSentiment: { positive: get(row, 'sentiment.positive'), neutral: get(row, 'sentiment.neutral'), negative: get(row, 'sentiment.negative') },
        dateAndDuration: {
          startTime: dateFromServer(row.startTime),
          duration: row.duration
        },
        crmEntitiesLinks: transformCrm({ ...row.crm, contacts: getContactsFromParticipantsObject(row.participants) }),
        matches: {
          [MATCHES_KEYS.SUBJECT]: [matches.subject || ''],
          [MATCHES_KEYS.BRANDS]: getRelevantKeywords(matches, 'keywords', [AGENT_BRANDS_ID, CUSTOMER_BRANDS_ID]),
          [MATCHES_KEYS.COMPETITORS]: getRelevantKeywords(matches, 'keywords', [AGENT_COMPETITORS_ID, CUSTOMER_COMPETITORS_ID]),
          [MATCHES_KEYS.KEYWORDS]: getRelevantKeywords(matches, 'keywords', [KEYWORDS_BY_AGENT_ID, KEYWORDS_BY_CUSTOMER_ID]),
          [MATCHES_KEYS.TAGS]: get(matches, 'tags', []).map((i2) => i2.tag),
          [MATCHES_KEYS.TRACKERS]: getRelevantKeywords(matches, 'keywords', [AGENT_TRACKERS_ID, CUSTOMER_TRACKERS_ID]),
        }
      });
    })
  };
};
