import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { IconButton } from '@fluentui/react';
import { Table } from '../../Table';

import { OrgActionCellContainer, OrgName } from './HostedOrgStyles';
import OrgTableDialog from './OrgTableDialog';

const OrgActionCell = ({ row: { orgId, orgUrl, clusterRegion, friendlyName, onConnectOrg, onDisconnectOrg, t } }) => {
  const [dialogType, setDialogType] = useState(null);
  const closeDialog = () => setDialogType(null);

  const connectTitle = `${t('connect_title')} ${friendlyName}`;
  const deleteTitle = `${t('disconnect_title')} ${friendlyName}`;

  const connectDialogDetails = {
    isOpen: dialogType === 'connect',
    title: connectTitle,
    subText: t('connect_content'),
    closeDialog,
    onConfirm: () => {
      onConnectOrg({ orgId, orgUrl, clusterRegion });
      closeDialog();
    }
  };
  const deleteDialogDetails = {
    isOpen: dialogType === 'delete',
    title: deleteTitle,
    subText: t('disconnect_content'),
    closeDialog,
    onConfirm: () => {
      onDisconnectOrg({ orgId, clusterRegion });
      closeDialog();
    }
  };

  return (
    <OrgActionCellContainer>
      <OrgTableDialog details={connectDialogDetails} />
      <OrgTableDialog details={deleteDialogDetails} />
      <IconButton iconProps={{ iconName: 'PlugDisconnected' }} ariaLabel={connectTitle} onClick={() => setDialogType('connect')} />
    </OrgActionCellContainer>
  );
};

const columns = [{
  key: 'friendlyName',
  name: 'friendlyName',
  fieldName: 'friendlyName',
  CellComponent: ({ data }) => <OrgName>{data}</OrgName>,
}, {
  key: 'id',
  name: 'id',
  fieldName: 'id',
  minWidth: 70,
  CellComponent: OrgActionCell,
}];

const OrgTable = ({ orgs, isLoading, onConnectOrg, onDisconnectOrg, t }) => {
  if (isEmpty(orgs)) {
    return null;
  }

  const items = orgs.map((org) => ({ ...org, onConnectOrg, onDisconnectOrg, t }));

  return (
    <Table
      items={items}
      columns={columns}
      isLoading={isLoading}
      isHeaderVisible={false}
    />
  );
};

export default OrgTable;
