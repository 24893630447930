import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import useLocale from 'hooks/use-locale';
import { routes } from 'pages/routing';
import { useFetchNow } from 'ci-common-ui';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import useEmptyState from 'utils/empty-state/use-empty-state';
import { useAppLocation, useAppNavigation } from 'hooks/use-app-navigation';
import { map } from 'lodash';
import { trackEvent, eventAction } from '../../../../services/telemetry-service/telemetry-service';
import { ConversationsTable } from './StyledRelevantConversations';
import { SectionTitle } from '../../../components/PageSection/StyledPageSection';
import { PaginatedTable } from '../../../../components/Table';
import { useQueryDateChange } from '../../../../hooks/use-query-date-change';
import { getInsightTable } from '../../../../network/data-services/insight-service';
import { transformInsightTable } from '../../../../network/filters/insight-filters';
import { TRACK_IDS, TRACK_SUFFIX } from '../../../../constants/tracking';
import { joinTrackDelimiter } from '../../../../utils/string/string';
import { QUERY_PARAMS } from '../../../../constants/urls';
import { getPageNumberFromUrlOrDefault } from '../../../../utils/url/url';
import { componentLoadingTimeService } from '../../../../services/component-loading-time-service/component-loading-time-service';

const initialPageNumber = 1;
const LOCALIZATION_PREFIX = 'pages.insight.common.relevant_conversations';

const RelevantConversations = ({ columns, apiParams, isPageDetailsLoading, trackSource }) => {
  const { t, tDefault } = useLocale();
  const { getEmptyStateDetails } = useEmptyState('pages.insight.common');
  const [pageNumber, setPageNumber] = useState(getPageNumberFromUrlOrDefault(initialPageNumber));
  const { navigateTo } = useAppNavigation();
  const { getSearchParam } = useAppLocation();
  const initialFetchFn = (newParams) => getInsightTable({ pageNumber, ...apiParams, ...newParams }, transformInsightTable);
  const { data, isLoading, fetchData, error } = useFetchNow(initialFetchFn);
  const [rowIndex, setRowIndex] = useState();

  const onQueryDateChange = (params) => {
    setPageNumber(initialPageNumber);
    fetchData(params);
  };

  useQueryDateChange(onQueryDateChange, { pageNumber: initialPageNumber });

  const scrollToCallRowIfNeeded = () => {
    const callId = getSearchParam(QUERY_PARAMS.SCROLL_TO);
    if (callId && !isEmpty(data)) {
      setRowIndex(findIndex(get(data, 'listOfRelevantCallsDetails'), ({ conversationId }) => conversationId === callId));
      setTimeout(() => setRowIndex(null), 2000);
      navigateTo({ params: { [QUERY_PARAMS.SCROLL_TO]: null, [QUERY_PARAMS.PAGE_NUMBER]: null }, options: { shouldReplace: true } });
    }
  };

  useEffect(scrollToCallRowIfNeeded, [data]);

  useEffect(() => {
    if (!isPageDetailsLoading && !isLoading) {
      componentLoadingTimeService.end(trackSource);
    }
  }, [isPageDetailsLoading, isLoading]);

  const trackCallTopicClickEvent = (id) => {
    trackEvent({ action: eventAction.click, actionOn: joinTrackDelimiter([TRACK_IDS.RELEVANT_CONVERSATIONS_TABLE, TRACK_SUFFIX.TABLE.CELL, TRACK_IDS.CALL_TOPIC]), message: id });
  };

  const onPageChanged = (newPage) => {
    trackEvent({ action: eventAction.click, actionOn: joinTrackDelimiter([TRACK_IDS.RELEVANT_CONVERSATIONS_TABLE, TRACK_SUFFIX.TABLE.PAGING]), message: newPage });
    fetchData({ pageNumber: newPage });
    setPageNumber(newPage);
  };

  const emptyStateDetails = getEmptyStateDetails('relevant_conversations', null, () => navigateTo({ path: routes.systemMonitoring.link }));

  const items = map(get(data, 'listOfRelevantCallsDetails'), (relevantCall) => ({ ...relevantCall, currentPage: pageNumber, trackCallTopicClickEvent }));

  return (
    <ConversationsTable>
      <SectionTitle>{t(`${LOCALIZATION_PREFIX}.title`)}</SectionTitle>
      <PaginatedTable
        ariaLabel={tDefault(`${LOCALIZATION_PREFIX}.grid_aria_label`)}
        columns={columns}
        items={items}
        isLoading={isLoading}
        currentPage={pageNumber}
        totalPages={get(data, 'pagesCount')}
        onPageChanged={onPageChanged}
        initialFocusedIndex={rowIndex}
        isErrorState={error}
        cardName={t(`${LOCALIZATION_PREFIX}.title`)}
        emptyStateDetails={!isLoading && isEmpty(get(data, 'listOfRelevantCallsDetails')) && emptyStateDetails}
      />
    </ConversationsTable>
  );
};
export default RelevantConversations;
