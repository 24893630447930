import React from 'react';
import useLocale from 'hooks/use-locale';
import {FEATURES} from 'ci-common-ui';
import { Text } from '../StyledNotificationBars';
import DismissibleBar from './DismissibleBar';
import { notificationBarsTypes } from '../notification-bars-types';
import useNotificationBar from '../../../hooks/use-notification-bar';
import { isFeatureEnabled } from '../../../utils/app-context/feature-flighting';
import { Link } from '../../Link/Link';

const LOCALISATION_PREFIX = 'components.notification_bar.compliance_boundary';

const actionItemsDeprecationLearnMoreLink = 'https://go.microsoft.com/fwlink/p/?linkid=2267217';

const ComplianceBoundaryBar = ({ level }) => {
  const { removeNotificationBar } = useNotificationBar();
  const { t, tAbs } = useLocale(LOCALISATION_PREFIX);
  const onDismiss = () => removeNotificationBar(notificationBarsTypes.ComplianceBoundary);
  const isComplianceBoundaryActive = isFeatureEnabled(FEATURES.COMPLIANCE_BOUNDARY);

  return (
    <DismissibleBar onDismiss={onDismiss} level={level}>
      <Text>
          {`${t(isComplianceBoundaryActive ? 'text1': 'text2')} `}
          <Link href={actionItemsDeprecationLearnMoreLink} target="_blank" >
            {tAbs('common.learn_more')}
          </Link>
      </Text>
    </DismissibleBar>
  );
};

export default ComplianceBoundaryBar;
