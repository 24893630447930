import styled from 'styled-components';
import { colors } from '../../../../../../constants/colors';
import { StyledText, FlexColumn } from '../../../../../../utils/styles/StyledUtils';

export const Label = styled(StyledText)`
  font-size: 12px;
  color: ${colors.Gray130};
  margin-bottom: 8px;
`;

export const Container = styled(FlexColumn)`
  height: 100%;
  width: 100%;
`;

export const ErrorMessage = styled(StyledText)`
  color: ${colors.DynamicsRed};
`;

export const ResultsSizeTitle = styled.div`
  padding: 8px 0px;
  line-height: 16px;
  font-size: 12px;
  border-bottom: 1px solid ${colors.Gray30};
  color: ${colors.Gray130};
`;

export const ResultsContainer = styled.div`
  padding: 0px 12px;
`;

export const spinnerStyles = { root: { padding: '12px' } };
