import styled from 'styled-components';
import { FlexRowCentered, StyledPercentageBar } from 'ci-common-ui';

const { PartsContainer, Part, Bullet } = StyledPercentageBar;

export const BarContainer = styled.div`
  ${PartsContainer} {
    height: 12px;
    width: 80px;
  }
  ${Part} {
    :nth-child(1) {
      border-radius: 2px 0 0 2px;
    }
    :nth-child(2) {
      margin-left: 1px;
      margin-right: 1px;
      border-radius: 2px 0 0 2px;
    }
    :nth-child(3) {
      border-radius: 0 2px 2px 0;
    }
  }
  ${Bullet} {
    border-radius: 0px;
    width: 8px;
    height: 8px;
  }
`;

export const OnlyAgent = styled(FlexRowCentered)`
  height: 18px;
  padding: 0 8px;
  background-color: ${({ theme }) => theme.colors.Gray20};
  border-radius: 20px;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.Gray130};
`;
