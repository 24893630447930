import React from 'react';
import useLocale from 'hooks/use-locale';
import { useAppState } from 'providers/AppStateProvider';
import { AddOrgBtn } from './HostedOrgStyles';
import { MODALS } from '../../../constants/modals';

const AddOrg = () => {
  const { setAppState } = useAppState();
  const { t } = useLocale('pages.settings.data_source.hosted_org');

  const onClick = () => {
    setAppState({ modalName: MODALS.Onboarding });
  };

  return (
    <AddOrgBtn text={t('add_env')} iconProps={{ iconName: 'Add' }} onClick={onClick} />
  );
};

export default AddOrg;
