import React from 'react';
import useLocale from 'hooks/use-locale';
import { Text, ViewIcon, ViewingAsWrapper } from '../StyledNotificationBars';
import DismissibleBar from './DismissibleBar';
import { notificationBarsTypes } from '../notification-bars-types';
import useNotificationBar from '../../../hooks/use-notification-bar';

const LOCALISATION_PREFIX = 'components.notification_bar.viewing_as';

const ViewingAsBar = ({ data, level }) => {
  const { removeNotificationBar } = useNotificationBar();
  const { t } = useLocale(LOCALISATION_PREFIX);
  const onDismiss = () => removeNotificationBar(notificationBarsTypes.ViewingAs);

  const fullName = data?.configuredViewAs?.fullName;
  if (!fullName) {
    return null;
  }

  return (
    <DismissibleBar onDismiss={onDismiss} level={level}>
      <ViewingAsWrapper>
        <ViewIcon />
        <Text>{t('text', { viewingAsName: fullName })}</Text>
      </ViewingAsWrapper>
    </DismissibleBar>
  );
};

export default ViewingAsBar;
