export enum CdnProdRegions {
  tip = 'tip',
  jpn = 'jpn',
  can = 'can',
  sam = 'sam',
  zaf = 'zaf',
  che = 'che',
  ger = 'ger',
  norway = 'norway',
  korea = 'korea',
  uae = 'uae',
  ind = 'ind',
  apj = 'apj',
  gbr = 'gbr',
  oce = 'oce',
  fra = 'fra',
  eur = 'eur',
  nam = 'nam',
  nam2 = 'nam2',
}
