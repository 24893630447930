import styled from 'styled-components';
import { FontSizes } from '@uifabric/fluent-theme';
import { rtl, colors, StyledText, FlexColumn, FlexRow, FlexRowCentered, deviceSizes } from 'ci-common-ui';
import { Icon } from '@fluentui/react';
import { ERROR, WARNING, DEFAULT, INFO } from './notification-bars-types';

export const StyledBarsContainer = styled(FlexColumn)`
  animation: enter 0.3s linear;

  @keyframes enter {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0px);
    }
  }

  ${deviceSizes.bigMobile`
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 47px;
  `}
`;

const levelToColorMap = {
  [ERROR]: colors.ScarletRed,
  [WARNING]: colors.SaharaYellow,
  [INFO]: colors.Gray20,
  [DEFAULT]: colors.DYNPrimary
};

const levelToTextColor = {
  [ERROR]: colors.White,
  [WARNING]: colors.White,
  [INFO]: colors.Gray160,
  [DEFAULT]: colors.White
};

export const StyledBar = styled.div`
  background-color: ${({ level }) => levelToColorMap[level] || colors.DYNPrimary};
  color: ${({ level }) => levelToTextColor[level] || colors.White};
  padding: 8px 16px;
  border-bottom: 1px solid ${colors.Gray10};
  .ms-Link {
    color: ${colors.White};
    text-decoration: underline;
  }
  .ms-Spinner {
    ${rtl`margin-right: 10px`};
  }
`;

export const StyledDismissibleBar = styled(FlexRowCentered)`
  justify-content: space-between;
  .ms-Button {
    color: ${({ level }) => levelToTextColor[level] || colors.White};
    background-color: ${({ level }) => levelToColorMap[level] || colors.DYNPrimary};
  }
  ${deviceSizes.bigMobile`
    .ms-Button {
      align-self: start;
    }
  `}
`;

export const Text = styled.span`
  font-size: ${FontSizes.size14};
`;

export const Bold = styled(Text)`
  font-weight: 700;
  ${rtl`margin-right: 6px`};
`;

export const StyledSetUpLink = styled(StyledText)`
  color: ${colors.White};
  cursor: pointer;
  text-decoration: underline;
`;

export const ViewIcon = styled(Icon).attrs({
  iconName: 'View',
  ariaLabel: 'Viewing as'
})`
  ${rtl`margin-right: 8px`};
  font-size: 16px;
`;

export const ViewingAsWrapper = styled(FlexRow)`
  align-items: center;
`;
