import React, { useEffect, useState } from 'react';
import { appContext } from 'utils/app-context/app-context';
import UploadCallsPanel from '../../../../../components/UploadCallsPanel/UploadCallsPanel';
import UploadCallsWrapper from './UploadCallsWrapper';
import { MODALS } from '../../../../../constants/modals';
import { useAppState } from '../../../../../providers/AppStateProvider';
import { componentLoadingTimeService } from '../../../../../services/component-loading-time-service/component-loading-time-service';
import { TRACK_IDS } from '../../../../../constants/tracking';

const UploadCallsTab = ({ closeModal }) => {
  const [settings, setSettings] = useState({});
  const { setAppState } = useAppState();
  const { orgId } = appContext;

  useEffect(() => {
    componentLoadingTimeService.end(TRACK_IDS.SOURCES.SETTINGS_MODAL);
  }, []);

  const onSave = () => {
    setAppState({
      modalName: MODALS.TrialDataUploader,
      isEnabledBannerProcessing: true,
      trialSetupConfig: {
        orgId,
        contactEmail: settings.contactEmail,
        sellers: settings.sellers,
      }
    });
  };

  return (
    <UploadCallsWrapper
      onSave={onSave}
      onCancel={closeModal}
    >
      <UploadCallsPanel
        handleSettings={setSettings}
        settings={settings}
      />
    </UploadCallsWrapper>
  );
};

export default UploadCallsTab;
