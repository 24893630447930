import { serverApi } from '../../../../services/server-api/server-api';
import { BASE_URLS } from '../../../../constants/network';

const getSettingsContextQueryParams = (isOrgContext) => ({ 'settings-context': isOrgContext ? 'org' : 'user' });

export async function loadCall(conversationId, params, forceFetch) {
  return serverApi.GET({ path: `/user/v1.0-preview/conversations/${conversationId}`, queryParams: params, options: { baseUrl: BASE_URLS.NEW_API_SERVER, cacheOptions: { cache: !forceFetch } } });
}

export async function loadCallId(conversationId, params, forceFetch) {
  return serverApi.GET({ path: `/user/v1.0-preview/conversations/${conversationId}/actions/get-call-id-by-conversation-id`, queryParams: params, options: { baseUrl: BASE_URLS.NEW_API_SERVER, cacheOptions: { cache: !forceFetch } } });
}

export async function loadConversationId(callId, params, forceFetch) {
  return serverApi.GET({ path: `/user/v1.0-preview/conversations/${callId}/actions/get-conversation-id-by-call-id`, queryParams: params, options: { baseUrl: BASE_URLS.NEW_API_SERVER, cacheOptions: { cache: !forceFetch } } });
}

export async function loadComments(callId, forceFetch) {
  return serverApi.GET({ path: `/user/v1.0-preview/conversations/${callId}/fragments/comments`, options: { baseUrl: BASE_URLS.NEW_API_SERVER, cacheOptions: { cache: !forceFetch } } });
}

export async function loadCommentsV2(callId, forceFetch) {
  return serverApi.GET({ path: `/user/v1.0-preview/conversations/${callId}/comments/v2`, options: { baseUrl: BASE_URLS.NEW_API_SERVER, cacheOptions: { cache: !forceFetch } } });
}

export async function addComment(callId, fragmentId, commentText) {
  return serverApi.POST({ path: `/user/v1.0-preview/conversations/${callId}/fragments/${fragmentId}/comments`, body: { text: commentText }, options: { baseUrl: BASE_URLS.NEW_API_SERVER } });
}

export async function addCommentV2(callId, fragmentStart, commentText, durationInMs, offsetInMs) {
  return serverApi.POST({ path: `/user/v1.0-preview/conversations/${callId}/comments/v2`,
    body: {
      text: commentText,
      fragmentStart,
      fragmentEnd: fragmentStart, // Currently fragmentStart = fragmentEnd because commenting on multiple fragments is not supported
      durationInMs,
      offsetInMs
    },
    options: { baseUrl: BASE_URLS.NEW_API_SERVER } });
}

export async function updateComment(callId, fragmentId, commentId, commentText) {
  return serverApi.PUT({ path: `/user/v1.0-preview/conversations/${callId}/fragments/${fragmentId}/comments/${commentId}`, body: { text: commentText }, options: { baseUrl: BASE_URLS.NEW_API_SERVER } });
}

export async function updateCommentV2(callId, commentId, commentText) {
  return serverApi.PATCH({ path: `/user/v1.0-preview/conversations/${callId}/comments/v2/${commentId}`, body: { text: commentText }, options: { baseUrl: BASE_URLS.NEW_API_SERVER } });
}

export async function deleteComment(callId, fragmentId, commentId) {
  return serverApi.DELETE({ path: `/user/v1.0-preview/conversations/${callId}/fragments/${fragmentId}/comments/${commentId}`, options: { baseUrl: BASE_URLS.NEW_API_SERVER } });
}

export async function deleteCommentV2(callId, commentId) {
  return serverApi.DELETE({ path: `/user/v1.0-preview/conversations/${callId}/comments/v2/${commentId}`, options: { baseUrl: BASE_URLS.NEW_API_SERVER } });
}

export async function getAllColaEntitiesOptions(nameFilter, types) {
  return serverApi.GET({ path: '/user/v1.0-preview/crm/cola-entities', queryParams: { ...(nameFilter && { 'name-filter': nameFilter }), types }, options: { baseUrl: BASE_URLS.NEW_API_SERVER } });
}

export async function addEntity(conversationId, type, id, name) {
  return serverApi.POST({ path: `/user/v1.0-preview/conversations/${conversationId}/related-entities`, body: { type, name, id }, options: { baseUrl: BASE_URLS.NEW_API_SERVER } });
}

export async function updateParticipant(conversationId, participantId, detailsToUpdate) {
  return serverApi.PATCH({ path: `/user/v1.0-preview/conversations/${conversationId}/participants/${participantId}`, body: detailsToUpdate, options: { baseUrl: BASE_URLS.NEW_API_SERVER } });
}

export async function removeEntity(conversationId, entityId) {
  return serverApi.DELETE({ path: `/user/v1.0-preview/conversations/${conversationId}/related-entities/${entityId}`, options: { baseUrl: BASE_URLS.NEW_API_SERVER } });
}

export async function addTag(conversationId, tag) {
  return serverApi.POST({ path: `/user/v1.0-preview/conversations/${conversationId}/tags`, body: { tag }, options: { baseUrl: BASE_URLS.NEW_API_SERVER } });
}

export async function removeTag(conversationId, tag) {
  return serverApi.DELETE({ path: `/user/v1.0-preview/conversations/${conversationId}/tags`, queryParams: { tag }, options: { baseUrl: BASE_URLS.NEW_API_SERVER } });
}

export async function getUserSummary(conversationId, forceFetch) {
  return serverApi.GET({ path: `/user/v1.0-preview/conversations/${conversationId}/actions/get-executive-summary`, options: { baseUrl: BASE_URLS.NEW_API_SERVER, cacheOptions: { cache: !forceFetch } } });
}

export async function editUserSummary(conversationId, text) {
  return serverApi.PATCH({ path: `/user/v1.0-preview/conversations/${conversationId}/actions/edit-executive-summary`, body: { content: text, contentType: 'text/plain' }, options: { baseUrl: BASE_URLS.NEW_API_SERVER } });
}

export const loadOrg = ({ orgId }, isOrgContext) => serverApi.GET({ path: `/user/v1.0-preview/organizations/${orgId}`, queryParams: getSettingsContextQueryParams(isOrgContext), options: { baseUrl: BASE_URLS.NEW_API_SERVER, cacheOptions: { cache: false }, acceptedErrorCodes: [404] } });
