import React from 'react';
import useLocale from 'hooks/use-locale';
import { useFetch, useFetchNow, EMPTY_GUID, callService } from 'ci-common-ui';
import DataCacheService from 'services/DataCacheService';
import { useAppState } from 'providers/AppStateProvider';
import { IconButton } from '@fluentui/react';
import { Root, Container, Loading, Error, Header } from './StyledRelatedOpportunityModal';
import SearchOpportunity from './components/SearchOpportunity/SearchOpportunity';
import OpportunityDetails from './components/OpportunityDetails/OpportunityDetails';

const { getOpportunityDetails, addOpportunity, removeOpportunity } = callService;
const clearDataCache = () => DataCacheService.removeAll();

const getError = (error, errorAdd, errorRemove) => {
  if (error) {
    return 'error';
  }

  if (errorAdd) {
    return 'add_error';
  }

  if (errorRemove) {
    return 'remove_error';
  }

  return null;
};

const RelatedOpportunity = () => {
  const { t } = useLocale('pages.call.sidebar.related_opportunity');
  const { appState: { modalProps: { id: callId, opportunityId, onChange } } } = useAppState();
  const { data: opportunity, fetchData: fetchOpportunity, isLoading, error } = useFetchNow((newOpportunityId) => getOpportunityDetails(newOpportunityId || opportunityId));
  const { fetchData: add, isLoading: isLoadingAdd, error: errorAdd } = useFetch((id) => addOpportunity(callId, id));
  const { fetchData: remove, isLoading: isLoadingRemove, error: errorRemove } = useFetch(() => removeOpportunity(callId));

  if (isLoading || isLoadingAdd || isLoadingRemove) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  const currentError = getError(error, errorAdd, errorRemove);
  if (currentError) {
    return (
      <Container>
        <Error>{t(currentError)}</Error>
      </Container>
    );
  }

  const onAddOpportunity = async (id) => {
    await add(id);
    fetchOpportunity(id);
    clearDataCache();
    if (onChange) onChange();
  };

  const onRemoveOpportunity = async () => {
    await remove();
    fetchOpportunity(EMPTY_GUID);
    clearDataCache();
    if (onChange) onChange();
  };

  return (
    <Container>
      {opportunity
        ? (
          <OpportunityDetails
            opportunity={opportunity}
            removeOpportunity={onRemoveOpportunity}
          />
        )
        : <SearchOpportunity addOpportunity={onAddOpportunity} />}
    </Container>
  );
};

const RelatedOpportunityModal = ({ closeModal }) => {
  const { t, tAbsDefault } = useLocale('pages.call.sidebar');

  return (
    <Root>
      <Header>
        <div>{t('related_opportunity_tab')}</div>
        <IconButton iconProps={{ iconName: 'Cancel' }} ariaLabel={`${tAbsDefault('common.close')} ${tAbsDefault('common.settings')}`} onClick={closeModal} />
      </Header>

      <RelatedOpportunity />
    </Root>
  );
};
export default RelatedOpportunityModal;
