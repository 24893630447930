import styled from 'styled-components';
import { Icon } from '@fluentui/react';
import { colors } from '../../../../constants/colors';
import { FlexRowCentered } from '../../../../utils/styles/StyledUtils';

export const TopSeller = styled(FlexRowCentered)`
  height: 100%;
  font-size: 14px;
  justify-content: center;
`;

export const StarWrapper = styled.a`
`;

export const Star = styled(Icon)`
  margin: 0 4px;
  color: ${colors.DYNPrimary};
  cursor: pointer;
  font-size: 14px;

  ${({ isDisabled }) => isDisabled && `
    color: ${colors.Gray90};
  `}
`;
