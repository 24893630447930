import styled from 'styled-components';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { FlexRowCentered, StyledLink } from '../../utils/styles/StyledUtils';
import { rtl } from '../../utils/styles/rtl';

const NO_HEIGHT = '0px';
const TAB_ERROR_HEIGHT = '34px';
const TAB_POST_CALL_HEADER_HEIGHT = '112px';
const FOOTER_HEIGHT = '52px';
const PROVISION_HEIGHT = '320px';
const PROVISION_HEIGHT_SMALL = '40px';
const PIVOT_BAR_HEIGHT = '42px';
const PIVOT_BAR_MARGIN = '8px';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.name === 'dark' ? theme.colors.White : theme.colors.Gray10};
  color: ${({ theme }) => theme.colors.Gray130};
  height: 100%;
`;

export const CallSummaryContainer = styled.div`
  height: ${TAB_POST_CALL_HEADER_HEIGHT};
  padding-top: 20px;
  box-sizing: border-box;
`;

export const TabContainer = styled.div`
  height: 100%;
  
  .pivot-container {
    height: calc(100% - ${({ isCallCompleted, hasError, isRecordingButtonHidden, shouldShowProvision, shouldShowProvisioningHeader, recordingSectionHeight }) => isCallCompleted ? `${TAB_POST_CALL_HEADER_HEIGHT} - ${FOOTER_HEIGHT} - ${shouldShowProvision ? shouldShowProvisioningHeader ? PROVISION_HEIGHT : PROVISION_HEIGHT_SMALL : NO_HEIGHT} `
    : `${isRecordingButtonHidden ? NO_HEIGHT : `${recordingSectionHeight || 0}px`} - ${hasError ? TAB_ERROR_HEIGHT : NO_HEIGHT} - ${shouldShowProvision ? shouldShowProvisioningHeader ? PROVISION_HEIGHT : PROVISION_HEIGHT_SMALL : NO_HEIGHT} `});
  }
`;

export const TabHeaderContainer = styled.div``;

export const RecordingContainer = styled.div`
  position: relative;
`;

export const ProvisionedContainer = styled.div`
  height: ${({ shouldShowProvisioningHeader }) => shouldShowProvisioningHeader ? PROVISION_HEIGHT : PROVISION_HEIGHT_SMALL} ;
  overflow: auto;
`;

export const TabHeaderError = styled(MessageBar).attrs({
  messageBarType: MessageBarType.error,
  styles: {
    root: {
      width: 'auto',
      height: 'auto',
      marginBottom: '5px',
    },
    text: {
      marginTop: 'auto',
      marginBottom: 'auto',
      paddingTop: '5px',
      paddingBottom: '5px',
    },
    dismissal: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    iconContainer: {
      marginTop: 'auto',
      marginBottom: 'auto',
    }
  }
})`  
  ${({ theme }) => theme.name === 'dark' && `
    background-color: ${theme.colors.BGError};
    color: ${theme.colors.Error};
    && {
      i {
        color: ${theme.colors.Error};
      }
    }
  `}
`;

export const TabHeaderErrorLink = styled(StyledLink)`
  ${rtl`margin-left: 5px`};
`;

export const TabContent = styled.div`
  overflow-y: auto;
  height: 100%;
`;

export const FooterContainer = styled(FlexRowCentered)`
  height: ${FOOTER_HEIGHT};
  justify-content: flex-end;
  margin: 0 -20px;
  padding: 0 20px;
  background-color: ${({ theme }) => theme.colors.Gray20};
`;

export const pivotStyles = ({ theme }) => ({
  root: {
    height: PIVOT_BAR_HEIGHT,
    display: 'flex',
    position: 'relative',
    backgroundColor: theme.name === 'dark' ? theme.palette.white : theme.palette.neutralLighterAlt
  },
  itemContainer: {
    height: `calc(100% - ${PIVOT_BAR_HEIGHT} - ${PIVOT_BAR_MARGIN})`,
    marginTop: PIVOT_BAR_MARGIN,
    paddingBottom: PIVOT_BAR_MARGIN,
    backgroundColor: theme.name === 'dark' ? theme.palette.white : theme.palette.neutralLighterAlt,
    selectors: {
      '> div': {
        height: '100%'
      }
    },
  },
});
