import React from 'react';
import { Trend } from 'ci-common-ui';
import isNil from 'lodash/isNil';
import transform from 'lodash/transform';
import snakeCase from 'lodash/snakeCase';
import useLocale from 'hooks/use-locale';
import { Shimmer } from '@fluentui/react';
import { MomKpisValueCount, MomKpisBar, MomText, MomTrendContainer } from './StyledMomKpis';
import { numberWithCommas } from '../../../../utils/numeric';

const MomKpis = ({ data, isLoading }) => {
  const { t, tAbs } = useLocale('pages.home.mom_kpis');
  if (isLoading) {
    return <Shimmer />;
  }
  if (!data) return null;

  const transformedMomKpis = transform(data, (result, value, key) => {
    const { count, variance } = value;
    if (!isNil(value) && !isNil(count)) {
      result.push({
        title: t(snakeCase(key)),
        component: (
          <>
            <MomKpisValueCount>{numberWithCommas(count)}</MomKpisValueCount>
            (
            <MomTrendContainer>
              <MomText>{tAbs('common.mom')}</MomText>
              <Trend value={variance} emptyText={tAbs('common.n_a')} />
            </MomTrendContainer>
            )
          </>
        )
      });
    }
  }, []);

  return <MomKpisBar translatedTitlesAndComponents={transformedMomKpis} />;
};

export default MomKpis;
