import styled from 'styled-components';
import { rtl } from '../../../../utils/styles/rtl';
import { StyledLink } from '../../../../utils/styles/StyledUtils';
import { IconWrapper, InformationIcon } from '../../../../components/InfoIcon/InfoIcon';
import { dataAutomationHighlightSection } from '../../../../constants/selectors';

export const Root = styled.div`
  padding: 20px 100px 20px 20px;
`;

export const ErrorContainer = styled.div`
  padding: 20px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const KeywordContainer = styled.div.attrs(({ $type }) => ({
  [dataAutomationHighlightSection]: $type
}))`
  ${InformationIcon} {
    font-size: 12px;
    vertical-align: middle;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.Gray160};
  }
  ${IconWrapper} {
    ${rtl`margin-left: 8px`};
  }
`;

export const keywordsContainerStyles = () => ({
  marginBottom: '16px',
});

export const keywordTitleStyles = ({ theme }) => ({
  marginBottom: '8px',
  fontWeight: '600',
  color: theme.colors.Gray160,
})

export const keywordSubtitleStyles = ({ theme }) => ({
  marginBottom: '4px',
  fontSize: '12px',
  color: theme.colors.Gray130,
});

export const KeywordLink = styled(StyledLink)`
  color: ${({theme, $isTopic}) => $isTopic ? theme.colors.TMSPrimary: theme.colors.Gray160};
  background-color: ${({ theme, $isTopic }) => $isTopic ? theme.colors.TMSTint50 : theme.colors.Gray20};
  padding: 4px 6px;
  height: 24px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 100px;
  margin: 4px 8px 4px 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 463px;

  &:hover,&:focus {
    background-color: ${({ theme, $isTopic }) => $isTopic ? theme.colors.TMSTint40 : theme.colors.Gray30};
    color: ${({theme, $isTopic}) => $isTopic ? theme.colors.TMSPrimary: theme.colors.Gray160};
  }
  
  ${({ $isSelected, theme, $isTopic }) => $isSelected && `
    background-color: ${theme.colors.DYNPrimary};
    color: ${theme.colors.White};

    &:hover,&:focus {
      background-color: ${$isTopic ? theme.colors.TMSPrimary : theme.colors.DYNPrimary};
      color: ${theme.colors.White};
    }
  `}
  
  ${({ $hide }) => $hide && `
     display: none;
  `}
`;

export const ItemLoading = styled.div`
  padding: 20px 10px;
`;

export const TopicKeyword = styled.div`
    display: inline-block;
`; 
