import styled from 'styled-components';
import { FlexColumn, StyledText, FlexRow, colors, rtl, ExportToCSVButtonContainer, deviceSizes, ellipsisCss } from 'ci-common-ui';
import { Text } from '@fluentui/react';
import { FontSizes } from '@uifabric/fluent-theme';
import { SectionCardContainer } from '../../../components/SectionCard/StyledSectionCard';
import InsightCardLoader from './InsightCardLoader/InsightCardLoader';
import { Link } from '../../../../components/Link/Link';

export const StyledInsightCard = styled(SectionCardContainer)`
  height: 220px;
  margin-bottom: 16px;
  padding: 22px 32px;
  
  ${deviceSizes.tablet`
    height: 100%;
    padding: 22px 16px;
  `}
`;

export const InsightCardContainer = styled(FlexRow)`
  height: 100%;
  display: flex;
  overflow: hidden;
  ${deviceSizes.tablet`
    flex-direction: column;
  `}
`;

export const TitleAndChart = styled.div`
  width: 45%;
  ${rtl`margin-right: 32px`};
  ${deviceSizes.tablet`
    width: 100%;
    margin: 0px;
  `}
`;

export const CardNameText = styled.div.attrs({ as: 'h4' })`
  font-size: 14px;
  display: block;
  font-weight: 600;
  ${ellipsisCss}
`;

export const CardName = styled(Text)`
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.Gray30};
  padding-bottom: 8px;
  margin-bottom: 8px;
  ${ExportToCSVButtonContainer} {
    ${rtl`margin-left: 8px`};
  }
`;

export const ChartContainer = styled.div`
  width: 100%;
  height: calc(100% - 36px);
  ${deviceSizes.tablet`
    height: 204px;
  `}
`;

export const InsightDetails = styled(FlexColumn)`
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  min-width: 250px;
  ${deviceSizes.mobile`
    min-width: 0;
  `}
`;

export const Title = styled(StyledText)`
  font-size: ${FontSizes.size16};
  font-weight: 600;
  margin-top: 0px; 
  
  ${deviceSizes.tablet`
    margin-top: 28px;
  `}
`;

export const Description = styled(StyledText)`
  color: ${colors.Gray130};
`;

export const ShowDetailsLink = styled(Link)`
  font-size: ${FontSizes.size16};
  font-weight: 600;
`;

export const StyledInsightCardLoader = styled(InsightCardLoader)`
  margin-bottom: 16px;
`;
