import React, { useState, useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { lightTheme, teamsTheme } from '../constants/colors';
import { getHTMLDirection } from '../utils/dom/dom';

const allThemes = {
  light: lightTheme,
  teams: teamsTheme
};

const ThemeContext = React.createContext();
const { Provider } = ThemeContext;

const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) throw new Error('useTheme must be used within a ThemeManager');

  return context;
};

const ThemeManagerProvider = ({ children }) => {
  const [theme, setTheme] = useState(allThemes.light);

  const changeTheme = (newTheme) => {
    setTheme(newTheme);
  };

  return (
    <Provider value={{ theme, changeTheme, allThemes }}>
      <ThemeProvider theme={{ ...theme, dir: getHTMLDirection() }}>{children}</ThemeProvider>
    </Provider>
  );
};

export { ThemeManagerProvider, useTheme };
