import { TRACK_IDS } from './tracking';

const Settings = 'settings';
const About = 'about';
const Onboarding = 'onboarding';
const TrialSetup = 'trialSetup';
const TrialDataUploader = 'trialDataUploader';
const RelatedOpportunity = 'relatedOpportunity';

export const MODALS = {
  Settings,
  About,
  Onboarding,
  TrialSetup,
  TrialDataUploader,
  RelatedOpportunity,
};

export const settingsModalTabs = {
  yourteam: {
    link: 'yourTeam',
    trackId: TRACK_IDS.SOURCES.YOUR_TEAM_TAB
  },
  conversationcontent: {
    link: 'conversationContent',
    trackId: TRACK_IDS.SOURCES.CONVERSATION_CONTENT_TAB
  },
  datasource: {
    link: 'dataSource',
    trackId: TRACK_IDS.SOURCES.DATA_SOURCE_TAB
  },
  privacy: {
    link: 'privacy',
    trackId: TRACK_IDS.SOURCES.PRIVACY_TAB
  },
  licenseusage: {
    link: 'licenseUsage',
    trackId: TRACK_IDS.SOURCES.LICENSE_USAGE_TAB
  },
  uploadcalls: {
    link: 'uploadCalls',
    trackId: TRACK_IDS.SOURCES.UPLOAD_CALLS_TAB
  }
};
