import React, { useState, useEffect } from 'react';
import useLocale from 'hooks/use-locale';
import { useFetchNow, useFetch, accessibilityUtils } from 'ci-common-ui';
import { getOrganizationList, getOrganizationDetails } from 'network/data-services/settings';
import { FocusZone, FocusZoneDirection, PrimaryButton, SearchBox, List } from '@fluentui/react';
import size from 'lodash/size';
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import { StepRoot, StepContainer, StepFooter, StepLoader, Error } from '../../../../../components/Wizard/StyledWizard';
import { Text, ListContainer, OrgImage, OrgContainer, SerachContainer } from './StyledSelectOrganization';
import { eventAction, trackEvent } from '../../../../../services/telemetry-service/telemetry-service';
import { TRACK_IDS } from '../../../../../constants/tracking';
import { joinTrackDelimiter } from '../../../../../utils/string/string';
import { componentLoadingTimeService } from '../../../../../services/component-loading-time-service/component-loading-time-service';

export const Org = ({ item, item: { friendlyName, url }, selectedOrg, onSelect, translatedAlt }) => (
  <OrgContainer isSelected={selectedOrg && selectedOrg.friendlyName === friendlyName} onClick={() => onSelect && onSelect(item)} data-is-focusable>
    <OrgImage alt={translatedAlt} />
    <div>
      <div>{friendlyName}</div>
      <div>{url}</div>
    </div>
  </OrgContainer>
);

const SelectOrganizationStep = ({ settings: { org: selectedOrg }, handleSettings, next }) => {
  const { t, tAbs } = useLocale('pages.onboarding.organization');
  const { data: orgs, isLoading, error } = useFetchNow(getOrganizationList);
  const { data: resolvedOrgDetails, fetchData: getOrgDetails, isLoading: isCurrentOrgDetailsLoading, error: currentOrgDetailsError } = useFetch(getOrganizationDetails);
  const [filteredOrgs, setFilteredOrgs] = useState();

  const { readOnChange } = accessibilityUtils;

  useEffect(() => {
    if (!isLoading) {
      componentLoadingTimeService.end(TRACK_IDS.SOURCES.ONBOARDING_MODAL);
    }
  }, [isLoading]);

  useEffect(() => {
    if (resolvedOrgDetails) {
      handleSettings({ org: { ...selectedOrg, resolvedOrgDetails } }, true);
      next();
    }
  }, [resolvedOrgDetails]);

  if (isLoading || isCurrentOrgDetailsLoading) {
    return <StepLoader label={t(isLoading ? 'loading' : 'loading_details')} />;
  }

  if (error) {
    return (
      <StepRoot>
        <Error>{t('error')}</Error>
      </StepRoot>
    );
  }
  const onSearch = (searchText) => {
    const filteredValues = searchText && orgs ? orgs.filter(({ friendlyName, url }) => toLower(friendlyName).includes(toLower(searchText)) || toLower(url).includes(toLower(searchText))) : orgs;
    setFilteredOrgs(filteredValues);
    const amountOfItemsToBeRead = size(filteredValues);
    readOnChange(amountOfItemsToBeRead ? `${amountOfItemsToBeRead} ${tAbs('common.new_matches')}` : tAbs('common.no_matches_found'));
  };

  const onSelect = (org) => {
    handleSettings({ org });
  };

  const onNext = async () => {
    await getOrgDetails(selectedOrg);
    trackEvent({ overrideSource: TRACK_IDS.SOURCES.ONBOARDING_MODAL,
      action: eventAction.click,
      actionOn: joinTrackDelimiter([TRACK_IDS.SOURCES.ONBOARDING_MODAL, TRACK_IDS.STARTED_ONBOARIDNG_PROCESS]),
      message: get(selectedOrg, 'id') });
  };

  return (
    <StepRoot>
      <StepContainer>
        <SerachContainer>
          <Text>{t('subtitle')}</Text>
          <SearchBox
            placeholder={t('search')}
            ariaLabel="Search for an organization"
            onChange={(e, v) => onSearch(v)}
          />
        </SerachContainer>
        <ListContainer>
          <FocusZone direction={FocusZoneDirection.vertical}>
            <List items={filteredOrgs || orgs} version={selectedOrg} onShouldVirtualize={() => false} onRenderCell={(item) => <Org item={item} selectedOrg={selectedOrg} onSelect={onSelect} translatedAlt={tAbs('common.organization')} />} />
          </FocusZone>
        </ListContainer>
      </StepContainer>

      <StepFooter>
        {currentOrgDetailsError && <Error>{t('details_error')}</Error>}
        <PrimaryButton text={tAbs('common.next')} disabled={!selectedOrg} onClick={onNext} />
      </StepFooter>
    </StepRoot>
  );
};

export default SelectOrganizationStep;
