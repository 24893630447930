// @flow
import React from 'react';
import { PaginatedTableContainer, StyledPagination } from './StyledTable';
import { Table } from './Table';

const EMPTY_ITEMS = [];

export const PaginatedTable = ({
  items,
  columns,
  onRowClick,
  totalPages,
  currentPage,
  onPageChanged,
  initialFocusedIndex,
  isLoading = false,
  isHeaderVisible = true,
  errorComponent,
  firstLabel,
  previousLabel,
  nextLabel,
  lastLabel,
  outOfLabel,
  ariaLabel
}) => {
  if (!isLoading && errorComponent) {
    return errorComponent;
  }

  return (
    <PaginatedTableContainer>
      <Table
        items={items || EMPTY_ITEMS}
        columns={columns}
        isLoading={isLoading}
        isHeaderVisible={isHeaderVisible}
        onRowClick={onRowClick}
        initialFocusedIndex={initialFocusedIndex}
        ariaLabel={ariaLabel}
      />
      <StyledPagination
        firstLabel={firstLabel}
        previousLabel={previousLabel}
        nextLabel={nextLabel}
        lastLabel={lastLabel}
        outOfLabel={outOfLabel}
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChanged={onPageChanged}
      />
    </PaginatedTableContainer>
  );
};
