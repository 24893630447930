import styled from 'styled-components';
import { PrimaryButton, ActionButton } from '@fluentui/react';
import { FlexColumn, FlexRowCentered } from '../../../../utils/styles/StyledUtils';
import { colors, hexToRGBA } from '../../../../constants/colors';
import { rtl } from '../../../../utils/styles/rtl';
import { deviceSizes } from '../../../../utils/styles/device-sizes';

const ACTIONS_HEIGHT = '44px';

export const Root = styled(FlexColumn)`
  height: 100%;
  position: relative;
`;

export const ActionsContainer = styled(FlexRowCentered)`
  height: ${ACTIONS_HEIGHT};
  ${rtl`padding-left`}: 12px;
  justify-content: space-between;

  ${deviceSizes.smallMobile`
    flex-direction: column;
    margin-bottom: 10px;
  `}
`;

export const Actions = styled(FlexRowCentered)`
  width: 100%;
  ${rtl`padding-left`}: 24px;
`;

export const Translate = styled(FlexRowCentered)`
`;

export const TranslateButton = styled(ActionButton).attrs({
  iconProps: { iconName: 'LocaleLanguage' },
  ariaLabel: 'Translate',
  styles: { root: { marginRight: '10px' }, icon: { fontSize: 20 } }
})`
`;

export const TranscriptContainer = styled(FlexColumn)`
  height: 100%;
  position: relative;
  height: calc(100% - ${ACTIONS_HEIGHT});
  background-color: ${({ theme }) => theme.colors.White};
  border: ${({ theme }) => `1px solid ${theme.colors.Gray30}`};
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.067) 0px 1px 4px;
  border-radius: 2px;
`;

export const Content = styled(FlexColumn)`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 10px 0;
`;

export const ResyncButton = styled(PrimaryButton)`
  position: absolute;
  top: 80px;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 0px 1px ${hexToRGBA(colors.Black, 0.16)}, 0px 4px 8px ${hexToRGBA(colors.Black, 0.12)};
  width: auto;
`;

