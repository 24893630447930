import styled from 'styled-components';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { colors } from '../../../../constants/colors';
import { rtl } from '../../../../utils/styles/rtl';

export const Container = styled.label`
  position: relative;
  display: block;
  .ms-Checkbox-checkbox {
    ${rtl`margin-right`}: 14px;
  }
  .ms-Checkbox-text {
    ${rtl`margin-left`}: 0px;
  }
`;

export const CheckboxTitle = styled.strong`
  font-weight: 600;
  display: block;
  margin-bottom: 4px;
`;

export const Loading = styled(Spinner).attrs({
  size: SpinnerSize.small
})`
  position: absolute;
  top: 2px;
  left: 2px;
`;

export const Description = styled.div`
  margin: 5px 30px;

  a {
    margin: 0 5px;
    color: ${colors.DYNPrimary};
  }

  ${({ disabled }) => disabled && `
    color: ${colors.Gray90};
    a {
      color: ${colors.Gray90};
    }
  `}
`;
