import React from 'react';
import { Container, EmptyMessageIcon, StyledLink, NoDataErrorContainer, NoDataSubtitle, NoDataTitle, StyledIcon } from './StyledNoDataError';

export const NoDataError = ({ title, subtitle }) => (
  <NoDataErrorContainer data-testid="NoChartData">
    <NoDataTitle>{title}</NoDataTitle>
    <NoDataSubtitle>{subtitle}</NoDataSubtitle>
  </NoDataErrorContainer>
);

export const NoDataErrorWithImage = ({ title, subtitle, link }) => (
    <NoDataErrorContainer>
        <Container>
            <StyledIcon><EmptyMessageIcon /></StyledIcon>
            <NoDataTitle>{title}</NoDataTitle>
            {subtitle && <NoDataSubtitle>{subtitle}</NoDataSubtitle>}
            {link && <StyledLink target='_blank' href={link.url} underline color=''>{link.text}</StyledLink>}
        </Container>
    </NoDataErrorContainer>
);
