import { chartConfigs } from 'ci-common-ui';
import { getBarChartMinScrollHeight, series, translateSeries, getOvertimeXAxisInterval } from 'utils/chart';
import { formatPercentage } from 'utils/numeric';
import { formatMonthDayDate } from 'utils/date/date';

const { combineConfigs, horizontalBar, donut, donutKiloTitles, area, scroll, accessibilityConfig } = chartConfigs;

export const createOverallConfig = (data, tAbs) => combineConfigs(
  {
    data: data.series,
    seriesParams: translateSeries(series.sentimentParams, tAbs),
    dataLabelsFormatter: ({ percentage }) => formatPercentage(percentage),
    titleText: data.calls.toString(),
    subtitleText: tAbs('common.calls')
  },
  [
    donut,
    donutKiloTitles, {
      accessibility: {
        keyboardNavigation: {
          order: ['legend', 'series']
        },
      }
    },
    {
      plotOptions: {
        pie: {
          center: true
        }
      },
      chart: {
        size: 160
      }
    },
  ]
);

export const createSentimentByAgentConfig = (data, tAbs) => combineConfigs(
  {
    data,
    seriesParams: translateSeries(series.sentimentParams, tAbs),
    categoryField: 'agentFullName',
    yTickAmount: 3,
    minScrollHeight: getBarChartMinScrollHeight(data),
    yAxisFormatter: ({ value }) => formatPercentage(value)
  },
  [horizontalBar, scroll, {
    xAxis: {
      labels: {
        style: {
          width: '100px',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }
      }
    },
    chart: {
      marginRight: 20
    },
    accessibility: {
      keyboardNavigation: {
        order: ['legend', 'series']
      },
    }
  }]
);

export const createOvertimeConfig = (data, tAbs) => combineConfigs(
  {
    data,
    seriesParams: translateSeries(series.sentimentAreaParams, tAbs),
    categoryField: 'day',
    categoryFieldType: 'date',
    xTickInterval: getOvertimeXAxisInterval(data),
    yTickAmount: 3,
    xAxisFormatter: ({ value }) => formatMonthDayDate(value),
    yAxisFormatter: ({ value }) => formatPercentage(value),
  },
  [area, accessibilityConfig]
);
